<template>
  <span class="ds-date-input">
    <component :is="component">
      <template v-if="shouldShowCalendarButton" #button>
        <ds-button tooltip="Calendário" :icon="['regular', 'calendar']" @click="openDatepicker" />
      </template>
      <ds-input
        ref="input"
        v-model="formattedDate"
        v-bind="$attrs"
        type="text"
        :chevron-icon="chevronIcon"
        :mask-pattern="maskPattern"
        :custom-validations="validations"
        :disabled="disabled"
        :readonly="readonly"
        :required="required"
        :suggestion="formattedSuggestion"
        :on-blur="onBlur"
        :on-change="onChange">
      </ds-input>
    </component>
  </span>
</template>

<script>
import DsInput from '@components/input';
import DsButton from '@components/button';
import DsInputGroup from '@components/input-group';
import { constants, dateService, dateParser, focusMixin } from '@core';
import { Datepicker } from './datepicker';

const VALUE_TYPE = [String, Date];

export default {
  name: 'DsDateInput',
  components: {
    DsInput,
    DsButton,
    DsInputGroup,
  },
  mixins: [focusMixin.focus('input')],
  props: {
    enabledDates: Array,
    value: VALUE_TYPE,
    required: Boolean,
    disabled: Boolean,
    onChange: Function,
    onBlur: Function,
    type: {
      type: String,
      default: 'day',
      validator(type) {
        return ['day', 'month', 'monthFull'].includes(type);
      },
    },
    picker: {
      type: Boolean,
      default: true,
    },
    readonly: Boolean,
    calendarButton: {
      type: Boolean,
      default: true,
    },
    suggestion: VALUE_TYPE,
    customValidations: {
      type: Array,
      default: () => [],
    },
    chevronIcon: Boolean,
    calendarViewDate: VALUE_TYPE,
  },
  data() {
    return {
      formattedDate: null,
      validations: this.buildValidations(),
    };
  },
  computed: {
    maskPattern() {
      return getMaskPattern(this.type);
    },
    dateFormat() {
      return getDateFormat(this.type);
    },
    formattedSuggestion() {
      return this.formatDate(this.suggestion);
    },
    shouldConfigDatepicker() {
      return this.picker && !this.readonly;
    },
    shouldShowCalendarButton() {
      return this.shouldConfigDatepicker && this.calendarButton;
    },
    component() {
      return this.shouldShowCalendarButton ? 'ds-input-group' : 'div';
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(isoDate) {
        this.setFormattedDate(this.formatDate(isoDate));
      },
    },
    formattedDate(formattedDate) {
      if (this.isValidFormattedDate(formattedDate)) {
        this.$emit('input', this.parseDate(formattedDate));
      }
    },
    calendarViewDate(calendarViewDate) {
      this.datepicker.setCalendarViewDate(calendarViewDate);
    },
  },
  mounted() {
    if (this.shouldConfigDatepicker) {
      this.configDatepicker();
    }
  },
  methods: {
    buildValidations() {
      const invalidDateValidation = {
        valid: this.isValidFormattedDate,
        message: constants.INPUT_ERRORS.INVALID_DATE.MESSAGE,
      };
      const customValidations = this.customValidations.map(this.parseCustomValidation);

      return [invalidDateValidation, ...customValidations];
    },
    parseCustomValidation(validation) {
      return {
        message: validation.message,
        valid: formattedDate => validation.valid(this.parseDate(formattedDate)),
      };
    },
    parseDate(formattedDate) {
      return dateParser.formatDate(formattedDate, this.dateFormat, getModelFormat());
    },
    formatDate(isoDate) {
      return dateParser.formatDate(isoDate, getModelFormat(), this.dateFormat);
    },
    isValidFormattedDate(formattedDate) {
      return !formattedDate || dateService.isValid(formattedDate, this.dateFormat);
    },
    setFormattedDate(formattedDate) {
      this.formattedDate = formattedDate;
    },
    getInput() {
      return this.$el.querySelector('input');
    },
    configDatepicker() {
      this.datepicker = new Datepicker(this.getInput(), {
        type: this.type,
        calendarViewDate: this.calendarViewDate,
        enabledDates: this.enabledDates,
      });

      this.$watch('enabledDates', enabledDates => {
        this.datepicker.setOption({ enabledDates });
      });

      this.$watch('formattedDate', formattedDate => {
        if (this.isValidFormattedDate(formattedDate)) {
          this.datepicker.setDate(formattedDate);
        }
      });

      this.$once('hook:beforeDestroy', () => {
        this.datepicker.destroy();
      });
    },
    openDatepicker() {
      this.datepicker.open();
    },
  },
};

function getModelFormat() {
  return 'YYYY-MM-DD';
}

function getMaskPattern(type) {
  return {
    day: '99/99/9999',
    month: '99/9999',
    year: '9999',
  }[type];
}

function getDateFormat(type) {
  return {
    day: 'DD/MM/YYYY',
    month: 'MM/YYYY',
    monthFull: 'MMMM [de] YYYY',
    year: 'YYYY',
  }[type];
}
</script>

<style>
@import './DateInput.css';
</style>
