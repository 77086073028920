import Vue from 'vue';

export function createStore() {
  const store = Vue.observable({
    currentStep: null,
    steps: [],
  });

  function getIndexByKey(key) {
    return store.steps.findIndex(step => step.key === key);
  }

  function getStepByKey(key) {
    return store.steps.find(step => step.key === key);
  }

  function isLast(key) {
    return store.steps.length === getIndexByKey(key) + 1;
  }

  return {
    get isCompleted() {
      return !!store.steps.length && store.steps.every(step => step.completed);
    },
    onStepCompleted(key, completed) {
      Vue.set(getStepByKey(key), 'completed', completed);
    },
    isOpen(key) {
      return store.currentStep === key;
    },
    addStep(key, index) {
      Vue.set(store.steps, index, { key, completed: false });
    },
    removeStep(key) {
      Vue.delete(store.steps, getIndexByKey(key));
    },
    setCurrentStep(key) {
      store.currentStep = key;
    },
    closeAll() {
      store.currentStep = null;
    },
    next(currentStep) {
      if (!isLast(currentStep)) {
        this.setCurrentStep(store.steps[getIndexByKey(currentStep) + 1].key);
      }
    },
  };
}
