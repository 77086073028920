import moment from 'moment';
import 'moment/dist/locale/pt-br';

export function isDateAfterOrEqual(dateA, dateB) {
  return moment(dateA).isSameOrAfter(dateB);
}

export function isDateBeforeOrEqual(dateA, dateB) {
  return moment(dateA).isSameOrBefore(dateB);
}

export function getCurrentDate() {
  return moment().toDate();
}

export function getFirstDayOfTheWeek(referenceDate) {
  return moment(referenceDate).startOf('week').toDate();
}

export function getLastDayOfTheWeek(referenceDate) {
  return moment(referenceDate).endOf('week').toDate();
}

export function getFirstDayOfTheMonth(referenceDate) {
  return moment(referenceDate).startOf('month').toDate();
}

export function getLastDayOfTheMonth(referenceDate) {
  return moment(referenceDate).endOf('month').toDate();
}

export function getFirstDayOfTheYear(referenceDate) {
  return moment(referenceDate).startOf('year').toDate();
}

export function getLastDayOfTheYear(referenceDate) {
  return moment(referenceDate).endOf('year').toDate();
}

function isFirstDayOfTheMonth(date) {
  const firstDayOfTheMonth = getFirstDayOfTheMonth(date);

  return isSameDay(date, firstDayOfTheMonth);
}

function isLastDayOfTheMonth(date) {
  const lastDayOfTheMonth = getLastDayOfTheMonth(date);

  return isSameDay(date, lastDayOfTheMonth);
}

function isFirstDayOfTheYear(date) {
  const firstDayOfTheYear = getFirstDayOfTheYear(date);

  return isSameDay(date, firstDayOfTheYear);
}

function isLastDayOfTheYear(date) {
  const lastDayOfTheYear = getLastDayOfTheYear(date);

  return isSameDay(date, lastDayOfTheYear);
}

export function isPeriodFullMonth(startDate, endDate) {
  return moment(startDate).isSame(endDate, 'month') && isFirstDayOfTheMonth(startDate) && isLastDayOfTheMonth(endDate);
}

export function isPeriodFullYear(startDate, endDate) {
  return moment(startDate).isSame(endDate, 'year') && isFirstDayOfTheYear(startDate) && isLastDayOfTheYear(endDate);
}

export function isSameDay(startDate, endDate) {
  return startDate && moment(startDate).isSame(endDate, 'day');
}

export function addDays(referenceDate, days) {
  return moment(referenceDate).add(days, 'day').toDate();
}

export function addWeeks(referenceDate, weeks) {
  return moment(referenceDate).add(weeks, 'week').toDate();
}

export function addMonths(referenceDate, months) {
  return moment(referenceDate).add(months, 'month').toDate();
}

export function addYears(referenceDate, years) {
  return moment(referenceDate).add(years, 'year').toDate();
}

export function getDiffInDays(dateA, dateB) {
  return Math.abs(moment(dateA).diff(dateB, 'days'));
}

export function isValid(value, format) {
  return moment(value, format, true).isValid();
}

export function isValidSlashDate(slashDate) {
  return isValid(slashDate, 'DD/MM/YYYY');
}

export function isValidDashDate(dashDate) {
  return isValid(dashDate, 'YYYY-MM-DD');
}

export function ptBrMoment(dateValue) {
  return moment(dateValue).locale('pt-br');
}
