<template>
  <font-awesome-layers class="ds-icon-layers fa-layers fa-fw">
    <slot />
  </font-awesome-layers>
</template>

<script>
import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

export default {
  name: 'DsIconLayers',
  components: {
    FontAwesomeLayers,
  },
};
</script>
