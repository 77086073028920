<template>
  <li class="ds-sidebar-menu-item-group" :class="classes">
    <div v-if="isRootGroupOfActiveItem" class="ds-sidebar-menu-item-group-header">
      <div
        class="ds-u-display--contents ds-u-cursor--pointer"
        @keydown.prevent.enter.space="unselectItem"
        @click.prevent="unselectItem">
        <ds-row
          tabindex="0"
          no-wrap
          content-vertical-align="center"
          aria-label="Voltar para menu principal"
          class="ds-u-margin--none">
          <ds-col size="auto">
            <div class="ds-sidebar-menu-group-header-icon">
              <ds-icon icon="chevron-left" size="sm" class="ds-sidebar-menu-group-header-icon__icon" />
            </div>
          </ds-col>
          <ds-col
            size="fill"
            class="ds-u-padding-left--none ds-u-padding-y--none ds-sidebar-menu-group-header-title-col">
            <ds-text class="ds-sidebar-menu-group-header-title">{{ label }}</ds-text>
          </ds-col>
        </ds-row>
      </div>
      <ds-row
        v-if="shouldShowAlternativeSecondLevel && $slots['alternative-second-level-header']"
        class="ds-sidebar-menu-item-group_second-level__header">
        <ds-col class="ds-sidebar-menu-item-group_second-level__header-column">
          <slot name="alternative-second-level-header" />
        </ds-col>
      </ds-row>
    </div>
    <ds-sidebar-menu-item-content
      v-else
      :text="label"
      :icon="icon"
      :is-new="isNew"
      :count-value="formattedCountValue"
      :count-value-tooltip="countValueTooltip"
      :chevron="chevronIcon"
      :badge-value="badgeValue"
      :badge-theme="badgeTheme"
      tabindex="0"
      :class="menuItemContentClasses"
      @click.prevent="selectItem"
      @keydown.prevent.enter.space="selectItem" />
    <ds-animation type="slide-in-left">
      <div v-if="shouldShowAlternativeSecondLevel" class="ds-sidebar-menu-item-group__list ds-u-margin-top--md">
        <ul v-show="isOpened" role="tree">
          <slot name="alternative-second-level" />
        </ul>
      </div>
    </ds-animation>
    <ds-animation
      type="slide-in-right"
      appear
      @after-enter="onAfterEnterSecondLevel"
      @after-leave="onAfterLeaveSecondLevel">
      <div v-show="shouldShowSecondLevel" class="ds-sidebar-menu-item-group__list" :class="secondLevelClasses">
        <ul role="tree">
          <slot />
        </ul>
      </div>
    </ds-animation>
  </li>
</template>

<script>
import DsAnimation from '@components/animation/Animation.vue';
import DsCol from '@components/col';
import DsRow from '@components/row';
import DsIcon from '@components/icon';
import DsText from '@components/text';
import DsSidebarMenuItemContent from './SidebarMenuItemContent.vue';

export default {
  name: 'DsSidebarMenuItemGroup',
  components: {
    DsAnimation,
    DsCol,
    DsRow,
    DsIcon,
    DsText,
    DsSidebarMenuItemContent,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: [Array, String],
    },
    itemLevel: {
      type: Number,
      required: true,
    },
    isActive: {
      type: Boolean,
    },
    isNew: {
      type: Boolean,
    },
    isRootGroupOfActiveItem: {
      type: Boolean,
    },
    shouldShowAlternativeSecondLevel: {
      type: Boolean,
    },
    badgeValue: {
      type: String,
    },
    badgeTheme: {
      type: String,
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    countValue: {
      type: Number,
    },
    countValueTooltip: {
      type: String,
    },
  },
  data() {
    return {
      isOpened: !this.collapsible || this.isActive,
      isSecondLevelOpened: false,
    };
  },
  computed: {
    chevronIcon() {
      if (this.isNoCollapsibleGroup) {
        return null;
      }

      if (this.isOpened) {
        return this.itemLevel === 0 ? 'chevron-left' : 'chevron-up';
      }
      return this.itemLevel === 0 ? 'chevron-right' : 'chevron-down';
    },
    classes() {
      return {
        'ds-sidebar-menu-item-group--opened': this.isOpened,
        'ds-sidebar-menu-item-group--root-group-active': this.isRootGroupOfActiveItem,
        'ds-sidebar-menu-item-group--is-alternative-level-opened': this.shouldShowAlternativeSecondLevel,
      };
    },
    secondLevelClasses() {
      return {
        'ds-sidebar-menu-item-group__list--opened': this.isSecondLevelOpened,
      };
    },
    shouldShowSecondLevel() {
      return !this.shouldShowAlternativeSecondLevel && this.isOpened;
    },
    menuItemContentClasses() {
      return {
        'ds-sidebar-menu-item-group-title': this.itemLevel !== 0,
      };
    },
    isNoCollapsibleGroup() {
      return this.itemLevel !== 0 && !this.collapsible;
    },
    formattedCountValue() {
      return !this.itemLevel ? this.countValue : null;
    },
  },
  watch: {
    isActive() {
      if (this.isNoCollapsibleGroup) {
        return;
      }

      this.isOpened = this.isActive;
    },
    isRootGroupOfActiveItem() {
      this.isOpened = this.isRootGroupOfActiveItem;
    },
  },
  methods: {
    selectItem() {
      if (this.isNoCollapsibleGroup) {
        return;
      }

      if (!this.isRootGroupOfActiveItem) {
        if (this.itemLevel === 0) {
          this.$emit('item-selected');
        }
        this.toggleItem();
      }
    },
    unselectItem() {
      this.$emit('item-unselected');
    },
    toggleItem() {
      this.isOpened = !this.isOpened;
    },
    onAfterEnterSecondLevel() {
      this.isSecondLevelOpened = true;
    },
    onAfterLeaveSecondLevel() {
      this.isSecondLevelOpened = false;
    },
  },
};
</script>

<style scoped>
@import './SidebarMenuItemGroup.css';
</style>
