<script lang="jsx">
import * as CONSTANTS from '@core/constants';

function getTextClassesByContext(props) {
  return {
    'ds-text': true,
    [`ds-u-color--${props.color}`]: props.color,
    [`ds-text__size--${props.size}`]: props.size,
    [`ds-text__weight--${props.weight}`]: props.weight,
    [`ds-text__style--${props.fontStyle}`]: props.fontStyle,
  };
}

export default {
  name: 'DsText',
  functional: true,
  slots: {
    default: '',
  },
  props: {
    fontStyle: {
      type: String,
      validator(value) {
        return ['normal', 'italic'].includes(value);
      },
    },
    size: {
      type: String,
      validator(value) {
        return ['sm', 'lg', 'xl'].includes(value);
      },
    },
    weight: {
      type: String,
      validator(value) {
        return ['regular', 'medium', 'bold'].includes(value);
      },
    },
    color: {
      type: String,
      validator(color) {
        return CONSTANTS.COLOR_CONSTANTS.COLORS.includes(color);
      },
    },
  },
  render(h, { props, data, children }) {
    if (['medium', 'bold'].includes(props.weight)) {
      return h(
        'b',
        {
          class: getTextClassesByContext(props),
          ...data,
        },
        children,
      );
    }
    return h(
      'span',
      {
        class: getTextClassesByContext(props),
        ...data,
      },
      children,
    );
  },
};
</script>

<style scoped>
@import './Text.css';
</style>
