import moment from 'moment';
import KEY_CODES from '@core/constants/keyCodes';
import keyboardSubscriptionService from '@core/services/keyboardSubscription/keyboardSubscriptionService';

const TIME_FORMAT = 'HH:mm';
const HOUR_OFFSET = 2;
export const TIME_LENGTH = {
  minutes: { start: 3, end: 5 },
  hours: { start: 0, end: 2 },
};

const UNIT_OFFSETS = {
  m: {
    selectionStart: TIME_LENGTH.minutes.start,
    selectionEnd: TIME_LENGTH.minutes.end,
  },
  h: {
    selectionStart: TIME_LENGTH.hours.start,
    selectionEnd: TIME_LENGTH.hours.end,
  },
};

export function getTimeUnit(selectionStart) {
  return selectionStart <= HOUR_OFFSET ? 'h' : 'm';
}

export function getUnitOffset(unit) {
  return UNIT_OFFSETS[unit];
}

export function getIncrementedTime(time, action, selectedUnit) {
  const incrementedTime = moment(time, TIME_FORMAT)[action](1, selectedUnit);
  return incrementedTime.format(TIME_FORMAT);
}

function listenArrowUpKeydown(onKeyDown) {
  return keyboardSubscriptionService.subscribe('keydown', KEY_CODES.ARROW_UP, event => onKeyDown(event, 'add'));
}

function listenArrowDownKeydown(onKeydown) {
  return keyboardSubscriptionService.subscribe('keydown', KEY_CODES.ARROW_DOWN, event => onKeydown(event, 'subtract'));
}

export function listenActionArrowsKeydown(onKeyDown) {
  return {
    upArrowId: listenArrowUpKeydown(onKeyDown),
    downArrowId: listenArrowDownKeydown(onKeyDown),
  };
}

function listenArrowLeftKeydown(onKeyDown) {
  return keyboardSubscriptionService.subscribe('keydown', KEY_CODES.ARROW_LEFT, event => onKeyDown(event, 'add'));
}

function listenArrowRightKeydown(onKeydown) {
  return keyboardSubscriptionService.subscribe('keydown', KEY_CODES.ARROW_RIGHT, event => onKeydown(event, 'subtract'));
}

export function listenSideArrowsKeydown(onKeyDown) {
  return {
    leftArrowId: listenArrowLeftKeydown(onKeyDown),
    rightArrowId: listenArrowRightKeydown(onKeyDown),
  };
}

export function unsubscribeKeydownEvents(eventIds) {
  Object.keys(eventIds).forEach(keydownId => {
    keyboardSubscriptionService.unsubscribe(keydownId);
  });
}

export function isValid(time) {
  return time.length === 5 && moment(time, TIME_FORMAT, true).isValid();
}
