<template>
  <portal to="action-bar">
    <nav class="ds-action-bar ds-u-print-hidden" :class="{ 'ds-action-bar--shadow': actionBarHasShadow }">
      <ds-container>
        <div class="ds-action-bar-container" content-vertical-align="center">
          <div class="ds-action-bar__left">
            <slot />
            <div class="ds-action-bar__actions">
              <slot name="actions" />
            </div>
          </div>
          <div
            v-if="shouldRenderFeedbackSlot || shouldRenderPagination || shouldRenderRightActionOnTop"
            class="ds-action-bar__actions ds-action-bar__right">
            <div v-if="shouldRenderFeedbackSlot" :class="{ 'ds-u-margin-right--md': shouldRenderPagination }">
              <slot name="feedback" />
            </div>
            <div v-if="shouldRenderPagination" class="ds-u-margin-left--sm">
              <ds-data-grid-pagination v-bind="getPaginationData" is-dropdown @page-change="onPageChange" />
            </div>
            <div v-if="shouldRenderRightActionOnTop" class="ds-u-margin-left--sm">
              <slot name="right-action-on-top" />
            </div>
          </div>
        </div>
      </ds-container>
    </nav>
  </portal>
</template>

<script>
import { Portal } from 'portal-vue';
import DsContainer from '@components/container';
import { actionBarRightActionOnTopService, debug, feedbackService, scrollService } from '@core';
import DsDataGridPagination from '../../data-grid/DataGridPagination.vue';

export default {
  name: 'DsActionBar',
  components: {
    Portal,
    DsContainer,
    DsDataGridPagination,
  },
  props: {
    showPaginationOnTop: {
      type: Boolean,
    },
  },
  data() {
    return {
      actionBarHasShadow: false,
      shouldRenderFeedbackSlot: false,
      shouldRenderRightActionOnTop: !!this.$slots['right-action-on-top'],
    };
  },

  inject: {
    dataGridTemplate: {
      default: null,
    },
  },

  computed: {
    getPaginationData() {
      return this.dataGridTemplate?.store.getPaginationData();
    },

    renderPagination() {
      return this.dataGridTemplate?.store.getPaginationOnTop();
    },

    shouldRenderPagination() {
      return this.showPaginationOnTop && this.renderPagination && this.getPaginationData;
    },
  },
  async mounted() {
    this.handleScroll();
    this.shouldRenderFeedbackSlot = feedbackService.shouldRenderSlot(this.$slots.feedback);

    await this.$nextTick();
    this.shouldRenderRightActionOnTop = actionBarRightActionOnTopService.shouldRenderSlot(
      this.$slots['right-action-on-top'],
    );
  },
  methods: {
    onPageChange(pagination) {
      this.dataGridTemplate.store.setPaginationData(pagination);
    },
    handleScroll() {
      const scrollableParent = getScrollableParent(this.$el);

      this.$on(
        'hook:beforeDestroy',
        addScrollListener(scrollableParent, () => {
          this.actionBarHasShadow = isScrolled(scrollableParent);
        }),
      );
    },
  },
};

function getScrollableParent(el) {
  let scrollableElement = document.querySelector('[ds-scrollable-body]');

  if (!scrollableElement) {
    scrollableElement = scrollService.getScrollableParentElement(el);
    debug.warning(
      'No "ds-scrollable-body" attribute is informed, the action-bar will be shadowed according to the element:',
      scrollableElement,
    );
  }

  return scrollableElement;
}

function isScrolled(el) {
  return el.scrollTop > 0 || el.scrollY > 0;
}

function addScrollListener(el, onScroll) {
  el.addEventListener('scroll', onScroll);
  return () => el.removeEventListener('scroll', onScroll);
}
</script>

<style scoped>
@import './ActionBar.css';
</style>
