<template>
  <ds-list-filter-item :label="label" :color="color" :selected="selected" :icon-tooltip="iconTooltip">
    <ds-currency-highlight slot="value" :value="value" :color="color" size="small" />
  </ds-list-filter-item>
</template>

<script>
import DsListFilterItem from '@components/list-filter-item';
import DsCurrencyHighlight from '@components/currency-highlight';

const { color, label, iconTooltip, selected } = DsListFilterItem.props;

export default {
  name: 'DsListFilterTotalItem',
  components: {
    DsListFilterItem,
    DsCurrencyHighlight,
  },
  props: {
    iconTooltip,
    color,
    label,
    selected,
    value: [String, Number],
  },
};
</script>
