<template>
  <div class="ds-filter-button-group">
    <ds-button-group :full-width="fullWidth">
      <ds-button
        v-for="(button, index) in buttons"
        :key="index"
        class="ds-filter-button-group__button"
        :class="{ 'ds-filter-button-group__button--selected': isButtonSelected(button) }"
        v-bind="button"
        @click="onClick(button)">
        {{ button.text }}
      </ds-button>
    </ds-button-group>
  </div>
</template>

<script>
import { debug, objectService } from '@core';
import DsButtonGroup from '@components/button-group';
import DsButton from '@components/button';

export default {
  name: 'DsFilterButtonGroup',
  components: {
    DsButtonGroup,
    DsButton,
  },
  props: {
    value: {
      type: [String, Object, Number],
    },
    /**
     * Path to comparable value object property
     */
    compareValueBy: {
      type: String,
    },
    /**
     * Array of objects with ds-button props
     */
    buttons: {
      type: Array,
      default: () => [],
      validator(buttons) {
        const hasValue = buttons.every(button => button.value != null);

        if (!hasValue) {
          debug.error('All buttons must have a defined value property');
        }

        return hasValue;
      },
    },
    fullWidth: {
      type: Boolean,
    },
  },
  data() {
    return {
      localValue: this.value || this.buttons[0]?.value,
    };
  },
  watch: {
    localValue: {
      immediate: true,
      handler(value) {
        this.$emit('input', value);
      },
    },
    value(value) {
      this.localValue = value;
    },
  },
  methods: {
    isButtonSelected(button) {
      return objectService.isEqual(button.value, this.localValue, this.compareValueBy);
    },
    onClick(button) {
      this.localValue = button.value;
    },
  },
};
</script>

<style scoped>
@import './FilterButtonGroup.css';
</style>
