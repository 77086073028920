import * as CreditCard from 'creditcard.js';

export function isValid(cardNumber) {
  return !!cardNumber && CreditCard.isValid(cardNumber);
}

export function isExpirationDateValid({ month, year }) {
  return CreditCard.isExpirationDateValid(`${month}`, `${year}`);
}

export function isSecurityCodeValid({ cardNumber, securityCode }) {
  return CreditCard.isSecurityCodeValid(cardNumber, securityCode);
}

export function getCreditCardNetworkByNumber(cardNumber) {
  return CreditCard.getCreditCardNameByNumber(cardNumber);
}
