import get from 'lodash/get';
import differenceBy from 'lodash/differenceBy';

export function createIntegerArray(start, end) {
  if (end < 0 || start < 0) {
    throw new Error('You should inform a value greater than 0');
  }

  const array = [];

  for (let index = start; index <= end; index++) {
    array.push(index);
  }

  return array;
}

export function getSubtractedArray(arrayA, arrayB, compareBy) {
  return differenceBy(arrayA, arrayB, compareBy);
}

export function getDiffArray(arrayA, arrayB, compareBy) {
  return [...getSubtractedArray(arrayA, arrayB, compareBy), ...getSubtractedArray(arrayB, arrayA, compareBy)];
}

export function includes(array, value, compareBy) {
  if (!compareBy) {
    return array.includes(value);
  }

  return array.some(item => get(item, compareBy) === get(value, compareBy));
}

export function humanizeArray(array, type = 'conjunction') {
  const formatter = new Intl.ListFormat('pt-BR', { style: 'long', type });

  return formatter.format(array);
}
