<template>
  <ds-data-search
    :on-search="onSearch"
    :on-search-success="onSearchSuccess"
    :on-search-error="onSearchError"
    invalid-form-tooltip-text="Para buscar os dados, preencha o CPF no campo ao lado.">
    <ds-cpf-input ref="input" :on-change="onChange" v-bind="$attrs" v-on="$listeners" />
  </ds-data-search>
</template>

<script>
import { focusMixin } from '@core';
import DsCpfInput from '@components/cpf-input';
import DsDataSearch from '@components/data-search';

export default {
  name: 'DsCpfDataSearch',
  components: {
    DsDataSearch,
    DsCpfInput,
  },
  mixins: [focusMixin.focus('input')],
  props: {
    onSearch: DsDataSearch.props.onSearch,
    onSearchSuccess: DsDataSearch.props.onSearchSuccess,
    onSearchError: DsDataSearch.props.onSearchError,
    onChange: DsCpfInput.props.onChange,
  },
};
</script>
