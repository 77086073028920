<template>
  <div class="ds-carousel-container">
    <div class="glide">
      <div class="glide__track" data-glide-el="track">
        <ul class="glide__slides">
          <slot></slot>
        </ul>
      </div>

      <div class="ds-carousel-glide__arrows" data-glide-el="controls">
        <button class="glide__arrow glide__arrow--left" data-glide-dir="<">
          <ds-icon :icon="['solid', 'chevron-left']" size="sm" />
        </button>
        <button class="glide__arrow glide__arrow--right" data-glide-dir=">">
          <ds-icon :icon="['solid', 'chevron-right']" size="sm" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Glide from '@glidejs/glide';
import DsIcon from '@components/icon';

export default {
  name: 'DsCarousel',
  components: {
    DsIcon,
  },
  props: {
    perView: {
      type: Number,
      default: 1,
    },
    rewind: {
      type: Boolean,
      default: true,
    },
    bound: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      carouselInstance: null,
    };
  },
  mounted() {
    this.mountGlide();
  },
  beforeDestroy() {
    this.carouselInstance.destroy();
  },
  methods: {
    buildCarouselConfig() {
      return {
        perView: this.perView,
        rewind: this.rewind,
        bound: this.bound,
      };
    },
    mountGlide() {
      setTimeout(() => {
        if (document.querySelector('.glide')) {
          const carouselOptions = this.buildCarouselConfig();
          this.carouselInstance = new Glide('.glide', carouselOptions);
          this.carouselInstance.mount();
        } else {
          this.mountGlide();
        }
      });
    },
    /**
     * Refresh carousel (called closed item)
     *
     * @public
     */
    refresh() {
      this.carouselInstance.destroy();
      this.mountGlide();
    },
  },
};
</script>

<style>
@import './Carousel.css';
</style>
