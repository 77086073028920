<template>
  <ds-th class="ds-inline-table-header" :size="size" :align="align" :tooltip="tooltip" :class="classes">
    <slot></slot>
  </ds-th>
</template>

<script>
import DsTh from '@components/table-header';

export default {
  name: 'DsInlineTableTh',
  inject: ['inlineTableVm'],
  components: {
    DsTh,
  },
  props: {
    size: DsTh.props.size,
    align: DsTh.props.align,
    tooltip: DsTh.props.tooltip,
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'ds-table-header--required': this.required,
      };
    },
    headerId() {
      return this.$vnode.key;
    },
  },
  watch: {
    required() {
      this.$nextTick(this.inlineTableVm.updateAllFieldsRequiredStatus);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.inlineTableVm.isHeaderRegistered(this.headerId)) {
        this.inlineTableVm.registerHeader();
      }
    });
  },
  beforeDestroy() {
    this.inlineTableVm.unregisterHeader(this.headerId);
  },
};
</script>
