<template>
  <div ref="sortableContainer" :class="classes" class="ds-sortable-container">
    <slot></slot>
  </div>
</template>

<script>
import * as sortableService from './sortableService';

export default {
  name: 'DsSortable',
  provide() {
    return {
      sortableVm: {
        registerItem: this.registerItem,
        unregisterItem: this.unregisterItem,
        getSortableItems: this.getSortableItems,
      },
    };
  },
  inject: {
    sortableVm: {
      default: null,
    },
  },
  props: {
    handle: {
      type: String,
      default: null,
    },
    group: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      sortable: null,
      sortableItems: {},
    };
  },
  computed: {
    classes() {
      return {
        'ds-sortable-handle--no-drag': this.handle,
      };
    },
  },
  mounted() {
    this.initializeSortable();
  },
  methods: {
    initializeSortable() {
      this.sortable = sortableService.initializeSortable({
        container: this.$refs.sortableContainer,
        handle: this.handle,
        group: this.group,
        onEnd: this.onSortUpdate,
      });
    },
    serialize() {
      const itemsList = this.toArray();
      const sortableItems = this.getSortableItems();

      return itemsList.map(id => sortableItems[id].item);
    },
    toArray() {
      return this.sortable.toArray();
    },
    registerItem(sortableItemId, item, vNodeItem) {
      if (this.sortableVm) {
        this.sortableVm.registerItem(sortableItemId, item, vNodeItem);
      } else {
        this.$set(this.sortableItems, sortableItemId, { item, vNodeItem });
      }
    },
    unregisterItem(sortableItemId) {
      if (this.sortableVm) {
        this.sortableVm.unregisterItem(sortableItemId);
      } else {
        this.$delete(this.sortableItems, sortableItemId);
      }
    },
    getSortableItems() {
      return this.sortableVm ? this.sortableVm.getSortableItems() : this.sortableItems;
    },
    onSortUpdate() {
      this.$emit('sort-update', this.serialize());
    },
  },
};
</script>
<style scoped>
@import './Sortable.css';
</style>
