import Vue from 'vue';

const initialSetup = Vue.observable({});
const ENVIRONMENTS = { CA_PRO: 'ca-pro', CA_PLUS: 'ca-plus' };

export function setInitialSetup(setup) {
  if (typeof setup !== 'object') {
    return;
  }
  Object.keys(setup).forEach(setupKey => {
    Vue.set(initialSetup, setupKey, setup[setupKey]);
  });
}

export function getInitialSetup(key) {
  if (typeof key === 'string') {
    return initialSetup[key];
  }
  return initialSetup;
}

export function handleEnvironmentVariable(environment = ENVIRONMENTS.CA_PRO) {
  return Object.values(ENVIRONMENTS).includes(environment) ? environment : ENVIRONMENTS.CA_PRO;
}
