<template>
  <div class="ds-rollover__body" v-on="$listeners">
    <ds-container class="ds-rollover__body-container">
      <slot></slot>
    </ds-container>
  </div>
</template>

<script>
import DsContainer from '@components/container';

export default {
  name: 'DsRolloverBody',
  inject: ['rolloverVm'],
  components: {
    DsContainer,
  },
};
</script>
