<template>
  <div class="ds-pagination-navigation ds-u-print-hidden">
    <ds-pagination-item-nav type="prev" :disabled="isFirstPage" @click="previous()" />
    <ds-pagination-item
      v-for="visiblePage in visiblePages"
      ref="pages"
      :key="visiblePage"
      :disabled="disabled"
      :page="visiblePage + 1 - firstPage"
      :active="visiblePage === page"
      @active="setActivePage"
      @click="onClickPage($event, visiblePage)" />
    <ds-pagination-item-nav type="next" :disabled="isLastPage" @click="next()" />
  </div>
</template>

<script>
import { createPagination } from './paginationFactory';
import DsPaginationItem from './PaginationItem.vue';
import DsPaginationItemNav from './PaginationItemNav.vue';

export default {
  name: 'DsPaginationNavigation',
  components: {
    DsPaginationItem,
    DsPaginationItemNav,
  },
  model: {
    prop: 'page',
    event: 'page-change',
  },
  props: {
    disabled: Boolean,
    totalPages: {
      type: Number,
    },
    page: {
      type: Number,
    },
    firstPage: {
      type: Number,
      default: 0,
      validator(firstPage) {
        return [0, 1].includes(firstPage);
      },
    },
    pagesLimit: {
      type: Number,
      default: 5,
    },
  },
  data() {
    return {
      pages: null,
      pagination: {},
    };
  },
  computed: {
    isFirstPage() {
      return this.page === this.firstPage;
    },
    isLastPage() {
      return this.page + !this.firstPage === this.totalPages;
    },
    visiblePages() {
      if (this.pagesLimit === this.firstPage) {
        return this.pages;
      }
      return this.pagination.getVisiblePages({
        currentPage: this.page || this.firstPage,
        pagesLimit: this.pagesLimit,
      });
    },
  },
  watch: {
    totalPages: {
      handler(totalPages) {
        this.pagination = createPagination(totalPages, this.firstPage);
        this.pages = this.pagination.pages;
      },
      immediate: true,
    },
  },
  methods: {
    setActivePage(page) {
      this.$emit('page-change', page);
    },
    previous() {
      const previousNumber = this.pagination.getPreviousNumber(this.page);
      this.$emit('previous', previousNumber);
    },
    next() {
      const nextNumber = this.pagination.getNextNumber(this.page);
      this.$emit('next', nextNumber);
    },
    isPageActive(page) {
      return page === this.page;
    },
    onClickPage(event, page) {
      if (!this.isPageActive(page)) {
        this.setActivePage(page);
      }
    },
  },
};
</script>

<style scoped>
@import './PaginationNavigation.css';
</style>
