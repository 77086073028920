import { DATA_THEME } from './darkModeServicesConstants';

export default class Darkmode {
  body = document.body;

  constructor(initialMode) {
    this.body.setAttribute('data-theme', initialMode);
  }

  toggle() {
    const isDarkmode = this.isActivated();
    if (isDarkmode) {
      this.body.removeAttribute('data-theme');
      this.body.setAttribute('data-theme', DATA_THEME.LIGHT);
    } else {
      this.body.removeAttribute('data-theme');
      this.body.setAttribute('data-theme', DATA_THEME.DARK);
    }
  }

  isActivated() {
    return this.body.getAttribute('data-theme') === 'dark';
  }
}
