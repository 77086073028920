<template>
  <span class="ds-search-select__load-more-button">
    <ds-request-button full-width theme="link" :request-action="fetchAction">
      <ds-icon icon="chevron-down" size="sm"></ds-icon>
      <span class="ds-search-select__load-more-button__label"> Mostrar mais </span>
    </ds-request-button>
  </span>
</template>

<script>
import DsRequestButton from '@components/request-button';
import DsIcon from '@components/icon';

export default {
  name: 'DsSearchSelectLoadMoreButton',
  components: {
    DsRequestButton,
    DsIcon,
  },
  props: {
    fetchAction: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
