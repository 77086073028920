<template>
  <ds-slimbox
    class="ds-slimbox-check"
    :class="classes"
    :overflow-max-height="overflowMaxHeight"
    :full-height="fullHeight"
    :tabindex="tabindex"
    :aria-checked="ariaChecked"
    :aria-labelledby="ariaLabelledby"
    @click="onSelect"
    @keydown.space.prevent="onSelect">
    <div slot="header" class="ds-slimbox-check__header">
      <div class="ds-u-display--flex ds-u-align-items--center">
        <slot name="check" />
        <ds-heading :id="ariaLabelledby" truncate size="sm">
          <slot name="heading" />
        </ds-heading>
        <div v-if="$slots['header-right']" class="ds-slimbox-check__header-right">
          <slot name="header-right" />
        </div>
      </div>
    </div>
    <div class="ds-slimbox-check__body" @keydown.space.stop>
      <slot />
    </div>
  </ds-slimbox>
</template>

<script>
import DsSlimbox from '@components/slimbox';
import DsHeading from '@components/heading';
import { generateId } from '@core/services/id/idService';

export default {
  name: 'DsSlimboxCheckbox',
  components: {
    DsSlimbox,
    DsHeading,
  },
  inject: {
    checkboxGroupVm: {
      default: null,
    },
  },
  props: {
    disabled: Boolean,
    fullHeight: DsSlimbox.props.fullHeight,
    overflowMaxHeight: DsSlimbox.props.overflowMaxHeight,
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      ariaLabelledby: `slimbox-heading-${generateId()}`,
    };
  },
  computed: {
    classes() {
      return {
        'ds-slimbox-check--disabled': this.disabled,
        'ds-slimbox-check--selected': this.selected,
      };
    },
    tabindex() {
      return this.disabled ? -1 : 0;
    },
    ariaChecked() {
      return this.selected.toString();
    },
  },
  methods: {
    onSelect() {
      if (!this.disabled) {
        this.$emit('select');
      }
    },
  },
};
</script>

<style scoped>
@import './SlimboxCheck.css';
</style>
