import { debug } from '../debug/debugService';

export function createDeprecation(vm) {
  return {
    deprecateProperty: (propertyName, instruction) => {
      debug.error(
        `%c${vm?.$options?.name}::propertyDeprecated - ${propertyName} will be deprecated soon.\n${instruction} `,
        'font-size: 18px;',
        vm,
      );
    },
    deprecateSlot: (slotName, instruction) => {
      debug.error(
        `%c${vm?.$options?.name}::slotDeprecated - ${slotName} will be deprecated soon.\n${instruction} `,
        'font-size: 18px;',
        vm,
      );
    },
  };
}
