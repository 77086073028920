<template>
  <ds-field :label="label" class="ds-datagrid-multiple-select-filter ds-u-margin-bottom--none">
    <ds-multiple-select-filter
      ref="select"
      :value="value"
      :compare-value-by="compareValueBy"
      :load-more="showLoadMore"
      :should-match-term-pattern="shouldMatchTermPattern"
      :on-load-more-button-click="onLoadMoreButtonClick"
      :on-fetch="handleFetch"
      :on-fetch-success="onFetchSuccess"
      :on-query="handleQuery"
      :on-query-success="onQuerySuccess"
      v-on="listeners">
      <ds-option v-for="option in options" :key="buildOptionKey(option)" :value="option" :disabled="option.disabled">
        {{ option.name }}
      </ds-option>
    </ds-multiple-select-filter>
  </ds-field>
</template>

<script>
import DsMultipleSelectFilter from '@components/multiple-select-filter';
import DsOption from '@components/option';
import DsField from '@components/field';

const DEFAULT_PAGE_NUMBER = 1;

export default {
  name: 'DsDataGridMultipleSelectFilter',
  components: {
    DsOption,
    DsMultipleSelectFilter,
    DsField,
  },
  provide() {
    return {
      showAllPlaceholderTerm: this.showAllPlaceholderTerm,
    };
  },
  props: {
    hasEmptyOption: {
      type: Boolean,
      default: false,
    },
    onQuery: {
      type: Function,
      required: true,
    },
    onFetch: {
      type: Function,
      required: true,
    },
    value: {
      type: Array,
    },
    loadMore: {
      type: Boolean,
    },
    shouldMatchTermPattern: {
      type: Boolean,
      default: true,
    },
    compareValueBy: {
      type: String,
      default: 'key',
    },
    label: {
      type: String,
      required: true,
    },
    showAllPlaceholderTerm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options: [],
      page: DEFAULT_PAGE_NUMBER,
      showLoadMore: this.loadMore,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        clear: this.onInput,
        input: this.onInput,
      };
    },
  },
  mounted() {
    if (!this.value || !this.value.length) {
      this.onInput(null);
    }
  },
  methods: {
    handleFetch() {
      return this.onFetch(this.value);
    },
    async onLoadMoreButtonClick(term) {
      this.page += DEFAULT_PAGE_NUMBER;
      const isLoadingMoreItems = true;
      const newOptions = await this.handleQuery(term, isLoadingMoreItems);

      if (newOptions?.length) {
        this.onQuerySuccess([...this.options, ...newOptions]);
      } else {
        setTimeout(() => {
          this.showLoadMore = false;
        });
      }
    },
    handleQuery(term, isLoadingMoreItems) {
      if (!isLoadingMoreItems) {
        this.page = DEFAULT_PAGE_NUMBER;
      }
      return this.onQuery(term, this.page);
    },
    onFetchSuccess(values) {
      // TODO - https://contaazul.atlassian.net/browse/DOP-4435
      const emptyItem = { [this.compareValueBy]: -1 };
      this.options = [...values, emptyItem];
      this.onInput(values);
    },
    onQuerySuccess(options) {
      this.options = options;
      if (this.hasEmptyOption && !this.alreadyHasAnEmptyOption()) {
        this.insertEmptyOption();
      }
    },
    alreadyHasAnEmptyOption() {
      return this.options.some(option => option[this.compareValueBy] === 'empty');
    },
    insertEmptyOption() {
      const emptyOption = {
        [this.compareValueBy]: 'empty',
        value: 'empty',
        name: '(Em branco)',
      };
      this.options.unshift(emptyOption);
    },
    onInput(value = null) {
      this.$emit('input', value);
    },
    buildOptionKey(option) {
      return option[this.compareValueBy];
    },
  },
};
</script>

<style scoped>
@import './DatagridMultipleSelectFilter.css';
</style>
