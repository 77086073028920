<template>
  <div class="ds-file-button">
    <input ref="input" hidden multiple type="file" @change="onInputChange" />
    <ds-button
      v-if="type === 'icon'"
      :icon="['light', 'paperclip']"
      tooltip="Adicionar arquivos"
      theme="link"
      @click="() => $refs.input.click()">
    </ds-button>
    <ds-button v-else :icon="['light', 'paperclip']" icon-position="left" @click="() => $refs.input.click()">
      Adicionar arquivos
    </ds-button>
  </div>
</template>

<script>
import DsButton from '@components/button';

export default {
  name: 'DsAsyncFileUploadButton',
  components: {
    DsButton,
  },
  props: {
    type: {
      type: String,
      default: 'default',
      validator(type) {
        return ['default', 'icon'].includes(type);
      },
    },
    addFiles: {
      type: Function,
      required: true,
    },
  },
  methods: {
    onInputChange(event) {
      this.addFiles(
        Array.from(event.target.files).map(file => ({
          name: file.name,
          type: file.type,
          data: file,
        })),
      );

      // https://github.com/transloadit/uppy/blob/ab82500ff3490971aaa8862b208274f05c5b93a3/packages/%40uppy/file-input/src/index.js#L73-L79
      event.target.value = null;
    },
  },
};
</script>

<style scoped>
input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>
