<template>
  <ds-slimbox-check
    class="ds-slimbox-radio"
    role="radio"
    :selected="localChecked"
    :disabled="isDisabled"
    :overflow-max-height="overflowMaxHeight"
    @select="onSelect">
    <ds-radio slot="check" ref="radio" :value="value" :disabled="isDisabled" tabindex="-1" v-on="$listeners" />
    <slot slot="heading" name="header" />
    <slot slot="header-right" name="header-right" />

    <slot />
  </ds-slimbox-check>
</template>

<script>
import DsSlimboxCheck from '@components/slimbox-check/SlimboxCheck.vue';
import DsRadio from '@components/radio';

export default {
  name: 'DsSlimboxRadio',
  inject: {
    radioGroupVm: {
      default: { disabled: false },
    },
  },
  components: {
    DsSlimboxCheck,
    DsRadio,
  },
  props: {
    value: DsRadio.props.value,
    disabled: DsSlimboxCheck.props.disabled,
    overflowMaxHeight: DsSlimboxCheck.props.overflowMaxHeight,
  },
  computed: {
    localChecked() {
      return this.radioGroupVm.valueModel === this.value;
    },
    isDisabled() {
      return this.disabled || this.radioGroupVm.disabled;
    },
  },
  methods: {
    onSelect() {
      this.$refs.radio.onChange();
    },
  },
};
</script>
