<template>
  <ds-input
    ref="input"
    v-model="localValue"
    class="ds-number-input"
    mask-pattern="integer"
    :required="required"
    v-bind="$attrs"
    v-on="$listeners" />
</template>

<script>
import { isInteger, isNegativeInteger } from '@core/services/numberValidation/numberValidationService';
import DsInput from '@components/input';
import { focusMixin } from '@core';

const isNegativeSignal = value => value === '-';
const isEmptyValue = value => value === '';
const isValidValue = value =>
  isInteger(value) || isNegativeInteger(value) || isEmptyValue(value) || isNegativeSignal(value);

const parseValue = value => {
  const number = parseInt(value);
  return Number.isNaN(number) ? null : number;
};

export default {
  name: 'DsNumberInput',
  components: {
    DsInput,
  },
  mixins: [focusMixin.focus('input')],
  props: {
    value: [Number, String],
    required: Boolean,
  },
  data() {
    return {
      localValue: null,
    };
  },
  watch: {
    localValue(localValue) {
      this.$emit('input', parseValue(localValue));
    },
    value(newValue) {
      this.setLocalValue(newValue);
    },
  },
  created() {
    this.setLocalValue(this.value);
  },
  methods: {
    setLocalValue(value) {
      if (isValidValue(value)) {
        this.localValue = value.toString();
      }
    },
  },
};
</script>
