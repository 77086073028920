<template>
  <ds-rollover
    ref="rollover"
    class="ds-async-file-upload-rollover"
    :title="title"
    :before-close-action="confirmClose"
    @close="onClose">
    <ds-page class="ds-async-file-upload-rollover__page">
      <ds-async-file-upload-status-bar :contexts="store.contexts" />
      <ds-tabs :active="store.activeContext" active-by="key" @update:active="store.activateContext">
        <ds-tab v-for="context in store.contexts" :key="context.id" :label="context.name">
          <ds-async-file-upload-list :context="context">
            <div slot="drag-drop"></div>
            <template #default="{ files, addFiles }">
              <ds-row vertical-offset="1">
                <ds-col>
                  <ds-async-file-upload-button :add-files="addFiles" />
                </ds-col>
              </ds-row>
              <ds-row>
                <ds-col>
                  <ds-data-grid
                    v-if="Object.keys(files).length"
                    show-actions
                    :fetch-action="() => {}"
                    :columns="[{ name: 'Nome do arquivo', sortable: false }]">
                    <ds-data-grid-tr
                      v-for="file in files"
                      :key="file.id"
                      :item="file"
                      not-hoverable
                      :custom-actions="[
                        {
                          tooltip: 'Tentar novamente',
                          icon: ['solid', 'undo'],
                          action: file.retry,
                          isVisible: file.canRetry,
                        },
                        {
                          tooltip: file.canRetry() ? 'Remover' : 'Cancelar',
                          icon: file.canRetry() ? ['solid', 'trash-alt'] : ['solid', 'times'],
                          action: file.cancel,
                          isVisible: file.canCancel,
                        },
                      ]">
                      <ds-td class="ds-async-file-upload-rollover--td">
                        <ds-async-file-upload-progress-bar
                          :percentage="file.percentage"
                          :name="file.name"
                          :is-done="file.isDone"
                          :is-failed="file.isFailed" />
                      </ds-td>
                    </ds-data-grid-tr>
                  </ds-data-grid>
                </ds-col>
              </ds-row>
            </template>
          </ds-async-file-upload-list>
        </ds-tab>
      </ds-tabs>
      <ds-footer justify="space-between">
        <ds-button @click="$refs.rollover.close()"> Cancelar </ds-button>
        <ds-button @click="minimize"> Continuar em segundo plano </ds-button>
      </ds-footer>
    </ds-page>
  </ds-rollover>
</template>

<script>
import DsTab from '@components/tab';
import DsTabs from '@components/tabs';
import DsCol from '@components/col';
import DsRow from '@components/row';
import DsPage from '@components/page';
import DsText from '@components/text';
import DsTr from '@components/table-row';
import DsFooter from '@components/footer';
import DsButton from '@components/button';
import DsTd from '@components/table-column';
import DsRollover from '@components/rollover';
import DsDataGrid from '@components/data-grid';
import DsDataGridTr from '@components/data-grid-tr';
import DsFadeTransition from '@components/fade-transition/FadeTransition.vue';
import { buildOpenDialog } from '../dialog/dialogService';
import DsAsyncFileUploadList from './AsyncFileUploadList.vue';
import DsAsyncFileUploadStatusBar from './AsyncFileUploadStatusBar.vue';
import DsAsyncFileUploadProgressBar from './AsyncFileUploadProgressBar.vue';
import { confirmClose } from './closeConfirmation';
import DsAsyncFileUploadButton from './AsyncFileUploadButton.vue';

const AsyncFileUploadRollover = {
  name: 'DsAsyncFileUploadRollover',
  props: {
    store: Object,
  },
  computed: {
    title() {
      const { activeContext, contexts } = this.store;
      return contexts[activeContext]?.title;
    },
    hasOpenContext() {
      return this.store.hasOpenContext;
    },
  },
  watch: {
    hasOpenContext(hasOpenContext) {
      if (!hasOpenContext) {
        this.$refs.rollover.close();
      }
    },
  },
  methods: {
    minimize() {
      this.minimized = true;
      this.$emit('minimized');
      this.$refs.rollover.close();
    },
    confirmClose() {
      if (this.minimized || !this.store.hasPendingFiles) {
        return Promise.resolve(true);
      }

      return confirmClose();
    },
    onClose() {
      if (!this.minimized) {
        this.$emit('closed');
      }
    },
  },
  components: {
    DsTr,
    DsTd,
    DsCol,
    DsRow,
    DsTab,
    DsTabs,
    DsPage,
    DsText,
    DsFooter,
    DsButton,
    DsRollover,
    DsDataGrid,
    DsDataGridTr,
    DsFadeTransition,
    DsAsyncFileUploadList,
    DsAsyncFileUploadButton,
    DsAsyncFileUploadStatusBar,
    DsAsyncFileUploadProgressBar,
  },
};

AsyncFileUploadRollover.open = buildOpenDialog(AsyncFileUploadRollover);

export default AsyncFileUploadRollover;
</script>

<style scoped>
@import './AsyncFileUploadRollover.css';
</style>
