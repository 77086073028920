<template>
  <ds-input
    ref="input"
    v-bind="$attrs"
    :custom-validations="[emailValidation, ...customValidations]"
    type="email"
    v-on="$listeners" />
</template>

<script>
import { constants, focusMixin } from '@core';
import * as emailService from '@ca-design-system-libs/email';
import DsInput from '@components/input';

export default {
  name: 'DsEmailInput',
  components: {
    DsInput,
  },
  mixins: [focusMixin.focus('input')],
  props: {
    customValidations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      emailValidation: {
        message: constants.INPUT_ERRORS.INVALID_EMAIL.MESSAGE,
        valid: emailService.isValid,
      },
    };
  },
};
</script>
