<template>
  <ds-beta-loader-button
    class="ds-request-button"
    :is-loading="isLoading"
    :disabled="disabled"
    :type="type"
    :theme="theme"
    :size="size"
    :full-width="fullWidth"
    :icon="icon"
    v-bind="$attrs"
    v-on="$listeners"
    @click="request">
    <slot />
  </ds-beta-loader-button>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import { debug } from '@core';
import DsBetaLoaderButton from '../beta-loader-button/BetaLoaderButton.vue';

export default {
  name: 'DsBetaRequestButton',
  provide() {
    return {
      isIconOnly: this.isIconOnly,
    };
  },
  components: {
    DsBetaLoaderButton,
  },
  props: {
    icon: DsBetaLoaderButton.props.icon,
    fullWidth: DsBetaLoaderButton.props.fullWidth,
    disabled: DsBetaLoaderButton.props.disabled,
    theme: DsBetaLoaderButton.props.theme,
    size: DsBetaLoaderButton.props.size,
    type: DsBetaLoaderButton.props.type,
    requestAction: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use request-action props instead
     */
    onRequest: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use @request-success event instead
     */
    onRequestSuccess: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use @request-error event instead
     */
    onRequestError: {
      type: Function,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (!this.onRequest && !this.requestAction) {
      debug.error('"requestAction" or "onRequest" prop must be informed');
    }
    if (this.onRequest) {
      deprecatedDependency.deprecateProperty('onRequest', 'Use request-action instead');
    }
    if (this.onRequestSuccess) {
      deprecatedDependency.deprecateProperty('onRequestSuccess', 'Use @request-success event instead');
    }
    if (this.onRequestError) {
      deprecatedDependency.deprecateProperty('onRequestError', 'Use @request-error event instead');
    }
  },
  methods: {
    async request(event) {
      const requestAction = this.getRequestAction();
      this.setIsLoading(true);
      try {
        const response = await requestAction(event);
        this.onSuccess(response);
      } catch (e) {
        this.onError(e);
      }
    },
    onSuccess(response) {
      this.setIsLoading(false);
      if (this.onRequestSuccess) {
        this.onRequestSuccess(response);
      }
      this.$emit('request-success', response);
    },
    onError(error) {
      this.setIsLoading(false);
      if (this.onRequestError) {
        this.onRequestError(error);
      }
      this.$emit('request-error', error);
    },
    setIsLoading(value) {
      this.isLoading = value;
    },
    getRequestAction() {
      return this.requestAction || this.onRequest;
    },
  },
};
</script>
