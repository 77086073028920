const AUDIO_SPECIFIER = 'audio/*';
const VIDEO_SPECIFIER = 'video/*';
const IMAGE_SPECIFIER = 'image/*';
const PDF_SPECIFIER = 'application/pdf';
const ANY_SPECIFIER = '*';

const EDGE_CASES = [AUDIO_SPECIFIER, VIDEO_SPECIFIER, IMAGE_SPECIFIER, ANY_SPECIFIER];

const FILE_TYPE_SPECIFIERS_EDGE_CASES_RULES = {
  [AUDIO_SPECIFIER]: isTypeAMatchOfAnyAudioType,
  [VIDEO_SPECIFIER]: isTypeAMatchOfAnyVideoType,
  [IMAGE_SPECIFIER]: isTypeAMatchOfAnyImageType,
  [ANY_SPECIFIER]: isTypeAMatchOfAll,
};

function isTypeAMatchOfAll(type) {
  return new RegExp('.*').test(type);
}

function isTypeAMatchOfAnyAudioType({ type }) {
  return new RegExp(AUDIO_SPECIFIER).test(type);
}

export function isTypeAMatchOfAnyImageType({ type }) {
  return new RegExp(IMAGE_SPECIFIER).test(type);
}

function isTypeAMatchOfAnyVideoType({ type }) {
  return new RegExp(VIDEO_SPECIFIER).test(type);
}

function getExtensionRule(specifier) {
  const extensionWithoutPrefix = specifier.replace('.', '');
  const extensionRegex = new RegExp(`${extensionWithoutPrefix}$`, 'i');
  return ({ name }) => extensionRegex.test(name);
}

function getMIMETypeRule(specifier) {
  return ({ type }) => type === specifier;
}

function isSpecifierACornerCase(specifier) {
  return EDGE_CASES.includes(specifier);
}

function isSpecifierAExtensionCase(specifier) {
  return new RegExp(/^\..+/).test(specifier);
}

function getUniqueFileTypesSpecifierRule(specifier) {
  if (isSpecifierACornerCase(specifier)) {
    return FILE_TYPE_SPECIFIERS_EDGE_CASES_RULES[specifier];
  }
  if (isSpecifierAExtensionCase(specifier)) {
    return getExtensionRule(specifier);
  }
  return getMIMETypeRule(specifier);
}

function buildAcceptedFileTypeRules(acceptedFileTypes) {
  const uniqueFileTypeSpecifiers = acceptedFileTypes.split(',');
  const rules = [];
  uniqueFileTypeSpecifiers.forEach(specifier => {
    rules.push(getUniqueFileTypesSpecifierRule(specifier));
  });
  return rules;
}

function isTypeValid(file, acceptedRules) {
  return acceptedRules.some(rule => rule(file));
}

export function isFileMinSizeValid(file, minSize) {
  return file && file.size >= minSize;
}

export function isFileMaxSizeValid(file, maxSize) {
  return file && file.size <= maxSize;
}

export function isFileTypeValid(file, acceptedFileTypes) {
  const acceptedRules = buildAcceptedFileTypeRules(acceptedFileTypes);
  return file && isTypeValid(file, acceptedRules);
}

export function isFileListMinSizeValid(fileList, minSize) {
  return fileList.every(file => isFileMinSizeValid(file, minSize));
}

export function isFileListMaxSizeValid(fileList, maxSize) {
  return fileList.every(file => isFileMaxSizeValid(file, maxSize));
}

export function isFileListTypeValid(fileList, acceptedFileTypes) {
  return fileList.every(file => isFileTypeValid(file, acceptedFileTypes));
}

export function isPdfFile(file) {
  return file && file?.type === PDF_SPECIFIER;
}
