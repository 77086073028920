<template>
  <ds-mini-rollover
    ref="rollover"
    class="ds-async-file-upload-mini-rollover"
    :before-close-action="confirmClose"
    @close="onClose">
    <template #header>
      <ds-async-file-upload-status-bar :contexts="store.contexts" mini />
    </template>
    <template #header-buttons>
      <ds-async-file-upload-button v-if="currentContext" type="icon" :add-files="currentContext.addFiles" />
      <ds-button :icon="['light', 'window-maximize']" tooltip="Maximizar" theme="link" @click="maximize" />
    </template>
    <template #default>
      <ds-select v-model="currentContext" class="ds-u-margin-bottom--sm" required compare-value-by="id">
        <ds-option v-for="context in store.contexts" :key="context.id" :value="context">
          {{ context.name }}
        </ds-option>
      </ds-select>
      <ds-page v-if="currentContext" class="ds-u-padding--none">
        <ds-async-file-upload-list :key="currentContext.id" :context="currentContext">
          <div slot="drag-drop"></div>
          <template #default="{ files }">
            <div v-for="file in files" :key="file.id" class="ds-async-file-upload-mini-rollover__file">
              <ds-async-file-upload-progress-bar
                :percentage="file.percentage"
                :name="file.name"
                :is-done="file.isDone"
                :is-failed="file.isFailed"
                mini />
              <ds-button
                v-if="file.canRetry()"
                class="ds-async-file-upload-mini-rollover__action"
                theme="link"
                size="sm"
                :icon="['solid', 'undo']"
                tooltip="Tentar novamente"
                @click="file.retry">
              </ds-button>
              <ds-button
                v-if="file.canCancel()"
                class="ds-async-file-upload-mini-rollover__action"
                theme="link"
                size="sm"
                :icon="file.canRetry() ? ['solid', 'trash-alt'] : ['solid', 'times']"
                :tooltip="file.canRetry() ? 'Remover' : 'Cancelar'"
                @click="file.cancel">
              </ds-button>
            </div>
          </template>
        </ds-async-file-upload-list>
      </ds-page>
    </template>
  </ds-mini-rollover>
</template>

<script>
import DsIcon from '@components/icon';
import DsPage from '@components/page';
import DsButton from '@components/button';
import DsSelect from '@components/select';
import DsOption from '@components/option';
import DsMiniRollover from '@components/mini-rollover/MiniRollover.vue';
import { buildOpenDialog } from '../dialog/dialogService';
import DsAsyncFileUploadList from './AsyncFileUploadList.vue';
import DsAsyncFileUploadStatusBar from './AsyncFileUploadStatusBar.vue';
import DsAsyncFileUploadButton from './AsyncFileUploadButton.vue';
import DsAsyncFileUploadProgressBar from './AsyncFileUploadProgressBar.vue';
import { confirmClose } from './closeConfirmation';

const AsyncFileUploadMiniRollover = {
  name: 'AsyncFileUploadMiniRollover',
  components: {
    DsIcon,
    DsPage,
    DsButton,
    DsSelect,
    DsOption,
    DsMiniRollover,
    DsAsyncFileUploadList,
    DsAsyncFileUploadButton,
    DsAsyncFileUploadStatusBar,
    DsAsyncFileUploadProgressBar,
  },
  props: {
    store: Object,
  },
  computed: {
    currentContext: {
      get() {
        return this.store.contexts[this.store.activeContext];
      },
      set(context) {
        this.store.activateContext(context.id);
      },
    },
    hasOpenContext() {
      return this.store.hasOpenContext;
    },
  },
  watch: {
    hasOpenContext(hasOpenContext) {
      if (!hasOpenContext) {
        this.$refs.rollover.close();
      }
    },
  },
  methods: {
    confirmClose() {
      if (this.maximized || !this.store.hasPendingFiles) {
        return Promise.resolve(true);
      }

      return confirmClose();
    },
    onClose() {
      if (!this.maximized) {
        this.$emit('closed');
      }
    },
    maximize() {
      this.maximized = true;
      this.$emit('maximized');
      this.$refs.rollover.close();
    },
  },
};

AsyncFileUploadMiniRollover.open = buildOpenDialog(AsyncFileUploadMiniRollover);

export default AsyncFileUploadMiniRollover;
</script>

<style scoped>
@import './AsyncFileUploadMiniRollover.css';
</style>
