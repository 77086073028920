<template>
  <div class="ds-sortable-item" :data-id="sortableItemId">
    <slot></slot>
  </div>
</template>

<script>
import { generateId } from '@core/services/id/idService';

export default {
  name: 'DsSortableItem',
  inject: ['sortableVm'],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sortableItemId: generateId(),
    };
  },
  mounted() {
    this.registerItem();
  },
  beforeDestroy() {
    this.sortableVm.unregisterItem(this.sortableItemId);
  },
  methods: {
    registerItem() {
      this.sortableVm.registerItem(this.sortableItemId, this.item, this);
    },
  },
};
</script>

<style scoped>
@import './SortableItem.css';
</style>
