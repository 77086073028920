<template>
  <component :is="wrapperElement" class="ds-currency-input" add-on-text-left="R$">
    <ds-numeral-input
      ref="input"
      :value="value"
      :placeholder="placeholder"
      :absolute-only="absoluteOnly"
      :required="required"
      :prefix="prefixText"
      :custom-validations="customValidations"
      v-bind="$attrs"
      v-on="listeners"
      @custom-changed="onCustomChanged"
      @custom-input="onCustomInput" />
  </component>
</template>

<script>
import { focusMixin } from '@core';
import DsInputGroup from '@components/input-group';
import DsNumeralInput from '@components/numeral-input/NumeralInput.vue';

export default {
  name: 'DsCurrencyInput',
  components: {
    DsInputGroup,
    DsNumeralInput,
  },
  mixins: [focusMixin.focus('input')],
  inheritAttrs: false,
  props: {
    value: DsNumeralInput.props.value,
    placeholder: String,
    externalSymbol: {
      type: Boolean,
      default: true,
    },
    required: Boolean,
    absoluteOnly: Boolean,
    internalSymbol: Boolean,
    /**
     * [{ valid: Function, message: String }]
     */
    customValidations: DsNumeralInput.props.customValidations,
  },
  computed: {
    prefixText() {
      return this.internalSymbol ? 'R$' : undefined;
    },
    wrapperElement() {
      return this.externalSymbol && !this.internalSymbol ? 'ds-input-group' : 'div';
    },
    listeners() {
      const { input, change, ...remainingListeners } = this.$listeners;

      return {
        ...remainingListeners,
      };
    },
  },
  methods: {
    onCustomInput(value) {
      this.$emit('input', value);
    },
    onCustomChanged(value) {
      this.$emit('change', value);
    },
  },
};
</script>
