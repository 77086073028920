<template>
  <div class="ds-feedback-rate">
    <ds-text v-if="title" class="ds-feedback-rate__title">
      {{ title }}
    </ds-text>
    <div class="ds-feedback-rate__stars">
      <ds-icon
        v-for="rate in 5"
        :key="rate"
        class="ds-feedback-rate__star"
        color="blue"
        size="lg"
        :icon="[value < rate ? 'regular' : 'solid', 'star']"
        @click="$emit('input', rate)" />
    </div>
    <div class="ds-feedback-rate__labels">
      <div class="ds-feedback-rate__label">
        {{ firstText }}
      </div>
      <div class="ds-feedback-rate__label">
        {{ lastText }}
      </div>
    </div>
  </div>
</template>

<script>
import DsIcon from '@components/icon';
import DsText from '@components/text';
import { modelValidationService } from '@core';

export default {
  components: {
    DsIcon,
    DsText,
  },
  inject: {
    formVm: {
      default: null,
    },
  },
  props: {
    value: {
      type: Number,
    },
    required: {
      type: Boolean,
    },
    firstText: {
      type: String,
      default: 'Pouco',
    },
    lastText: {
      type: String,
      default: 'Muito',
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      modelValidator: modelValidationService.buildModelValidation(this),
    };
  },
  created() {
    this.$on('input', rate => {
      this.$emit('change', rate);
    });
  },
};
</script>

<style scoped>
@import './FeedbackRate.css';
</style>
