<template>
  <ds-beta-numeral-input
    ref="numeralInput"
    :value="value"
    :precision="precision"
    v-bind="$attrs"
    @custom-changed="onCustomChanged"
    @custom-input="onCustomInput" />
</template>

<script>
import DsBetaNumeralInput from '../beta-numeral-input/BetaNumeralInput.vue';

export default {
  name: 'DsBetaNumberInput',
  components: {
    DsBetaNumeralInput,
  },
  props: {
    value: Number,
    precision: {
      type: Number,
      default: 0,
    },
    minPrecision: {
      type: Number,
      default: 0,
    },
    integer: {
      type: Number,
      default: 10,
    },
  },
  watch: {
    value() {
      this.update(this.value);
    },
  },
  methods: {
    onCustomInput(value) {
      this.$emit('input', value);
    },
    onCustomChanged(value) {
      this.$emit('change', value);
    },
    update(value) {
      this.$refs.numeralInput.update(value);
    },
  },
};
</script>
