import Sortable from 'sortablejs';

const defaultOptions = {
  animation: 150,
  fallbackOnBody: true,
  swapThreshold: 0.65,
};

export function initializeSortable({ container, handle, group, onEnd = () => {} }) {
  const sortableInstance = Sortable.create(container, {
    ...defaultOptions,
    group,
    handle,
    onEnd,
  });

  function toArray() {
    return sortableInstance.toArray();
  }

  return { toArray };
}
