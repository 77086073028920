<template>
  <div :class="classes" class="ds-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'DsContainer',
  props: {
    /** ['auto', 'sm'] */
    size: {
      type: String,
      default: 'auto',
      validator(type) {
        return type && ['auto', 'sm'].includes(type);
      },
    },
  },
  computed: {
    classes() {
      return {
        [`ds-container--${this.size}`]: this.size,
      };
    },
  },
};
</script>

<style scoped>
@import './Container.css';
</style>
