<template>
  <div class="ds-password-input-validation">
    <ds-text weight="medium">Sua senha deve ter:</ds-text>
    <ds-row v-for="(rule, index) in passwordInputRules" :key="index" content-vertical-align="center">
      <ds-col size="auto">
        <ds-icon v-bind="getIconAndColor(rule)" />
      </ds-col>
      <ds-col size="auto">
        {{ rule.TEXT }}
      </ds-col>
    </ds-row>
  </div>
</template>
<script>
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsIcon from '@components/icon';
import DsText from '@components/text';
import { ICONS, COLORS, INPUT_RULES } from './passwordInputConstants';

export default {
  name: 'DsPasswordInputValidation',
  components: {
    DsRow,
    DsCol,
    DsIcon,
    DsText,
  },
  props: {
    value: [Number, String],
  },
  data() {
    return {
      passwordInputRules: INPUT_RULES,
    };
  },
  methods: {
    getIconAndColor(rule) {
      const isValid = rule.REGEX.test(this.value);

      return {
        icon: isValid ? ICONS.CHECK_CIRCLE : ICONS.EXCLAMATION_CIRCLE,
        color: isValid ? COLORS.GREEN : COLORS.SILVER,
      };
    },
  },
};
</script>

<style scoped>
@import './PasswordInputValidation.css';
</style>
