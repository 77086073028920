<template>
  <div class="ds-tc-box-service-status-issues">
    <ds-illustration :name="illustration" />
    <div class="ds-tc-box-service-status-issues__content">
      <ds-heading :text="title" size="sm" />
      <!-- eslint-disable-next-line vue/no-v-html -->
      <ds-paragraph v-if="message" v-html="sanitizedMessage" />
      <slot />
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';
import DsIllustration from '@components/illustration';
import DsHeading from '@components/heading';
import DsParagraph from '@components/paragraph';

export default {
  name: 'DsTcBoxServiceStatusIssues',
  components: {
    DsIllustration,
    DsHeading,
    DsParagraph,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    illustration: {
      type: String,
      required: true,
    },
    message: String,
  },
  computed: {
    sanitizedMessage() {
      return DOMPurify.sanitize(this.message);
    },
  },
};
</script>

<style scoped>
@import './TcBoxServiceStatusIssues.css';
</style>
