<template>
  <div class="ds-detail-total-bar-item" :style="{ width: `${width}px` }">
    <div>
      <ds-text color="text" class="ds-detail-total-bar-item__label">
        {{ unit ? `${label} (${unit})` : label }}
      </ds-text>
    </div>
    <div>
      <span class="ds-detail-total-bar-item__value">
        <ds-currency-format weight="bold" :precision="precision" :color="color" :value="value" />
      </span>
    </div>
  </div>
</template>

<script>
import DsText from '@components/text';
import DsCurrencyFormat from '@components/currency-format';

export default {
  name: 'DsDetailTotalBarItem',
  components: {
    DsText,
    DsCurrencyFormat,
  },
  props: {
    width: {
      type: Number,
      default: 102,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    precision: {
      type: Number,
      default: 2,
      validator(precision) {
        return [0, 2].includes(precision);
      },
    },
    unit: {
      type: String,
      default: 'R$',
      validator(unit) {
        return !unit || ['R$'].includes(unit);
      },
    },
    /**
     * ['grey-dark', 'blue', 'red', 'green', 'yellow']
     */
    color: {
      type: String,
      default: 'grey-dark',
      validator(value) {
        return ['grey-dark', 'blue', 'red', 'green', 'yellow'].includes(value);
      },
    },
  },
};
</script>

<style scoped>
@import './DetailTotalBarItem.css';
</style>
