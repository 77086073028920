<template>
  <div tabindex="0" :class="classes" class="ds-dropdown-item" @keydown.enter.space="onClick" @click="onClick">
    <slot name="icon">
      <ds-icon v-if="icon" :icon="icon" size="sm"> </ds-icon>
    </slot>
    <span class="ds-dropdown-item-label">
      <slot></slot>
    </span>
    <span v-if="hasValidBadge" class="ds-u-float--right ds-u-margin-left--sm">
      <slot name="badge" />
    </span>
  </div>
</template>

<script>
import DsIcon from '@components/icon';
import { isComponent } from '@core/services/vnode/vnodeService';

export default {
  name: 'DsDropdownItem',
  components: {
    DsIcon,
  },
  inject: {
    dropdownMenuVm: {
      default: null,
    },
    dropdownVm: {
      default: null,
    },
  },
  props: {
    value: [Number, String, Object],
    icon: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    requestAction: Function,
  },
  computed: {
    classes() {
      return {
        'ds-dropdown-item--with-icon': this.$slots.icon || this.icon,
        'ds-dropdown-item--disabled': this.disabled,
      };
    },
    hasValidBadge() {
      return this.$slots.badge?.length === 1 && isComponent(this.$slots?.badge[0], 'DsBadge');
    },
  },
  methods: {
    select() {
      this.dropdownMenuVm.select(this.value);
    },
    onClick(...args) {
      if (!this.disabled) {
        this.select();
        this.$emit('click', ...args);
        this.request(...args);
      }
    },
    async request(event) {
      if (!this.requestAction) {
        return;
      }

      this.setIsLoading(true);
      try {
        const response = await this.requestAction(event);
        this.onSuccess(response);
      } catch (e) {
        this.onError(e);
      }
    },
    onSuccess(response) {
      this.setIsLoading(false);
      this.$emit('success', response);
    },
    onError(error) {
      this.setIsLoading(false);
      this.$emit('error', error);
    },
    setIsLoading(value) {
      this.dropdownVm.setIsRequestLoading(value);
    },
  },
};
</script>

<style scoped>
@import './DropdownItem.css';
</style>
