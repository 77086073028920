<template>
  <ds-sortable ref="sortableContainer" handle=".ds-sortable-item-handle" @sort-update="onSortUpdate">
    <ds-sortable-item v-for="item in items" :key="item.id" :item="item">
      <slot :item="item" :addItem="addItem" :firstLevel="true"></slot>
      <ds-sortable
        v-if="haveChildren(item)"
        class="ds-sortable-item-level--1"
        :group="group"
        @sort-update="onSortUpdate">
        <ds-sortable-item v-for="child in item.children" :key="child.id" :item="child">
          <slot :item="child"></slot>
        </ds-sortable-item>
      </ds-sortable>
    </ds-sortable-item>
  </ds-sortable>
</template>

<script>
import { generateId } from '@core/services/id/idService';
import DsSortableItem from '@components/sortableItem';
import DsSortable from '@components/sortable';
import { getSerialize } from './tcnestedSortableListService';

export default {
  name: 'TcNestedSortableList',
  inject: {
    dreSortableListVm: {
      default: null,
    },
  },
  components: {
    DsSortableItem,
    DsSortable,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      group: {
        name: 'nested',
        put: ['nested'],
      },
    };
  },
  methods: {
    getSerialize() {
      const serializedList = this.$refs.sortableContainer.toArray();
      const items = this.getSortableItems();

      return getSerialize(serializedList, items);
    },
    getSortableItems() {
      return this.$refs.sortableContainer?.getSortableItems();
    },
    onSortUpdate() {
      this.dreSortableListVm.emitUpdateItems();
    },
    addItem(item) {
      const newItem = {
        children: [],
        id: generateId(),
      };
      item.children.push(newItem);
      this.$nextTick(() => {
        this.dreSortableListVm.emitUpdateItems();
      });
    },
    haveChildren(item) {
      return !!item.children?.length;
    },
  },
};
</script>

<style scoped>
@import './TcNestedSortableList.css';
</style>
