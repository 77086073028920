<template>
  <div class="ds-checkbox-group" :class="classes" role="group">
    <slot />
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import { INPUT_ERRORS } from '@core/constants';
import { modelValidationService } from '@core';
import { generateId } from '@core/services/id/idService';

const getCheckedValues = (selectedValues, currentOption) => {
  const newValues = currentOption.checked ? [currentOption.value] : [];
  return [...selectedValues, ...newValues];
};

export default {
  name: 'DsCheckboxGroup',
  provide() {
    return {
      checkboxGroupVm: {
        registerOption: this.registerOption,
        unregisterOption: this.unregisterOption,
        updateOption: this.updateOption,
        isOptionInitialChecked: this.isOptionInitialChecked,
      },
    };
  },
  inject: {
    fieldVm: {
      default: null,
    },
    formVm: {
      default: null,
    },
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    direction: {
      type: String,
      default: 'vertical',
      validator(direction) {
        return ['vertical', 'horizontal'].includes(direction);
      },
    },
    required: Boolean,
    requiredErrorMessage: {
      type: String,
      default: INPUT_ERRORS.REQUIRED_CHECKBOX.MESSAGE,
    },
    customValidations: {
      type: Array,
      default: () => [],
    },
    fullWidth: Boolean,
  },
  data() {
    return {
      modelValidator: modelValidationService.buildModelValidation(this, {
        requiredRule: this.getRequiredRule(),
      }),
      localValue: [...this.value],
      options: {},
    };
  },
  computed: {
    classes() {
      return {
        [`ds-checkbox-group--${this.direction}`]: this.direction,
        'ds-checkbox-group--full-width': this.fullWidth,
      };
    },
  },
  watch: {
    value() {
      if (!isEqual(this.value, this.localValue)) {
        Object.values(this.options).forEach(option => {
          const checked = this.isOptionInitialChecked(option.value);
          option.setChecked(checked);
        });
      }
    },
    options() {
      this.localValue = this.getCheckboxGroupValue();
      this.modelValidator._validateAllRules(this.localValue);
      this.$emit('input', this.localValue);
    },
  },
  methods: {
    getRequiredRule() {
      const rule = {
        valid: selectedOptions => selectedOptions.length > 0,
        message: this.requiredErrorMessage,
      };
      return this.required ? rule : null;
    },
    getCheckboxGroupValue() {
      return Object.values(this.options).reduce(getCheckedValues, []);
    },
    registerOption(option) {
      const optionId = generateId();
      this.$set(this.options, optionId, option);
      return optionId;
    },
    unregisterOption(optionId) {
      this.$delete(this.options, optionId);
    },
    updateOption({ id, option }) {
      this.modelValidator._setHasBeenBlured(true);
      const localOptions = this.options;
      localOptions[id] = option;
      this.options = {
        ...localOptions,
      };
    },
    isOptionInitialChecked(value) {
      return this.value.some(initialValue => isEqual(initialValue, value));
    },
  },
};
</script>

<style scoped>
@import './CheckboxGroup.css';
</style>
