<template>
  <div v-if="tooltip" class="ds-helper-icon ds-u-print-hidden">
    <ds-icon v-ds-tooltip:250="tooltip" :icon="['regular', 'circle-question']" size="md" color="grey-dark" />
  </div>
</template>

<script>
import DsIcon from '@components/icon';
import DsTooltip from '@directives/tooltip';

export default {
  name: 'DsHelperIcon',
  components: {
    DsIcon,
  },
  directives: {
    DsTooltip,
  },
  props: {
    tooltip: String,
  },
};
</script>

<style scoped>
@import './HelperIcon.css';
</style>
