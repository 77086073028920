<template>
  <span class="ds-search-select__loading-options"> <ds-loader theme="inside" /> Carregando... </span>
</template>

<script>
import DsLoader from '@components/loader';

export default {
  name: 'DsSearchSelectLoadingOptions',
  components: {
    DsLoader,
  },
};
</script>
