<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 140 140"
    xml:space="preserve">
    <path
      class="ds-loader-caplus-leaf-1"
      d="M101.831 37.6665C86.1639 37.6665 73.4628 50.4992 73.4615 66.3298V102.163C89.1191 102.163 101.814 89.3465 101.831 73.5292C101.831 73.5185 101.831 73.5085 101.831 73.4978V37.6665Z" />
    <path
      class="ds-loader-caplus-leaf-2"
      d="M38.0002 73.4959C38.0002 89.3273 50.7012 102.161 66.3693 102.161V44.8313C50.7012 44.8313 38.0002 57.6653 38.0002 73.4959Z" />
  </svg>
</template>

<script>
export default {
  name: 'DsLoaderCaPlusSvg',
};
</script>
<style scoped>
@import './LoaderCaPlus.css';
</style>
