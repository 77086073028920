import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';

export function isString(text) {
  return typeof text === 'string';
}

export function toPascalCase(string) {
  const camelCased = camelCase(string);
  return camelCased.charAt(0).toUpperCase() + camelCased.slice(1);
}

export function toCapitalize(text) {
  return capitalize(text);
}

export function removeSpaces(text) {
  return isString(text) ? text.replace(/\s+/g, '') : text;
}
