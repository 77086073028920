<template>
  <li :class="classes" @click="onSelectTab">
    <span>
      {{ tab.label }}
    </span>
    <ds-badge v-if="tab.badgeText" v-ds-tooltip="tab.badgeTooltip" :theme="tab.badgeTheme">
      {{ tab.badgeText }}
    </ds-badge>
  </li>
</template>

<script>
import DsTooltip from '@directives/tooltip';
import DsBadge from '@components/badge';

export default {
  name: 'DsTabNav',
  directives: {
    DsTooltip,
  },
  components: {
    DsBadge,
  },
  props: {
    tab: Object,
    isActive: Boolean,
  },
  computed: {
    classes() {
      return {
        'ds-tabs__title': true,
        'is-active': this.isActive,
      };
    },
  },
  methods: {
    onSelectTab() {
      this.$emit('tab-selected', this.tab);
    },
  },
};
</script>
