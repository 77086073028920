<template>
  <div class="ds-u-align-items--center ds-u-display--flex ds-u-print-hidden">
    <span class="ds-pagination-go-to__label"> Ir para página </span>
    <ds-input-group class="ds-pagination-go-to">
      <ds-number-input
        v-model="localValue"
        class="ds-pagination-go-to__input"
        :disabled="disabled"
        @keydown.enter="submit" />
      <ds-button slot="button" :disabled="disabled" @click="submit"> Ok </ds-button>
    </ds-input-group>
  </div>
</template>

<script>
import DsButton from '@components/button';
import DsInputGroup from '@components/input-group';
import DsNumberInput from '@components/number-input';

export default {
  name: 'DsPaginationGoTo',
  components: {
    DsInputGroup,
    DsNumberInput,
    DsButton,
  },
  props: {
    value: Number,
    disabled: Boolean,
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  methods: {
    submit() {
      this.$emit('input', Number(this.localValue));
    },
  },
};
</script>

<style scoped>
@import './PaginationGoTo.css';
</style>
