import Vue from 'vue';
import { POSITION, DIRECTION } from './constants';

export function createStore() {
  const store = Vue.observable({
    totalColumns: null,
    columns: {},
  });

  function registerColumn(uid, payload) {
    Vue.set(store.columns, uid, payload);
  }

  function getColumn(uid) {
    return store.columns[uid] || {};
  }

  function updateColumnWidth(uid, width, updateSiblingColumnsOffset = false) {
    Vue.set(store.columns[uid], 'width', width);

    if (updateSiblingColumnsOffset) {
      updateFixedSibilingColumnsOffset(uid);
    }
  }

  function updateFixedSibilingColumnsOffset(uid) {
    const column = getColumn(uid);
    const { fixed } = column;
    const siblings = getFixedSiblings(uid);
    const index = siblings.indexOf(uid);
    const siblingsToUpdate = fixed === POSITION.START ? siblings.slice(index + 1) : siblings.slice(0, index);

    siblingsToUpdate.forEach(col => {
      updateColumnOffset(col);
    });
  }

  function getFixedSiblings(uid) {
    const column = getColumn(uid);
    const { fixed } = column;

    return Object.getOwnPropertySymbols(store.columns).filter(col => store.columns[col].fixed === fixed);
  }

  function getColumnUidByIndex(index) {
    return Object.getOwnPropertySymbols(store.columns)[index];
  }

  function updateColumnOffset(uid) {
    const column = getColumn(uid);
    const direction = DIRECTION[column.fixed];

    Vue.set(store.columns[uid], 'offset', getOffset(uid, direction));
  }

  function getSiblings(uid) {
    return Object.getOwnPropertySymbols(store.columns).filter(col => col !== uid);
  }

  function getColumnIndex(uid) {
    return Object.getOwnPropertySymbols(store.columns).indexOf(uid);
  }

  function getOffset(uid, direction) {
    const index = getColumnIndex(uid);
    const siblings = getSiblings(uid);

    let startItem = 0;
    let endItem = index;

    if (direction === DIRECTION[POSITION.END]) {
      startItem = index;
      endItem = siblings.length;
    }

    const items = siblings.slice(startItem, endItem);

    return items.reduce((acc, item) => acc + store.columns[item].width, 0);
  }

  return {
    getTotalColumns() {
      return store.totalColumns;
    },
    setTotalColumns(totalColumns) {
      Vue.set(store, 'totalColumns', totalColumns);
    },
    registerColumn,
    getColumn,
    getColumnByIndex(index) {
      const uid = getColumnUidByIndex(index);
      return getColumn(uid);
    },
    getColumnUidByIndex,
    updateColumnWidth,
    updateColumnOffset,
  };
}
