<template>
  <span class="ds-search-select__error-message">
    Os dados não foram obtidos, <ds-button theme="link" @click="onRetryHandler">tente novamente</ds-button>
  </span>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import DsButton from '@components/button';

export default {
  name: 'DsSearchSelectErrorMessage',
  components: {
    DsButton,
  },
  props: {
    onRetry: {
      type: Function,
    },
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.onRetry) {
      deprecatedDependency.deprecateProperty('onRetry', 'Use @retry event instead');
    }
  },
  methods: {
    onRetryHandler() {
      if (this.onRetry) {
        this.onRetry();
      }

      this.$emit('retry');
    },
  },
};
</script>
