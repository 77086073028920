import { arrayService } from '@core';

export function createPagination(totalPages, firstPage = 0) {
  return {
    pages: arrayService.createIntegerArray(firstPage, totalPages + firstPage - 1),

    getVisiblePages({ currentPage, pagesLimit }) {
      const otherPages = pagesLimit - 1;
      const leftPages = Math.floor(otherPages / 2);
      const rightPages = otherPages - leftPages;

      let start = currentPage - leftPages;
      let end = currentPage + rightPages;

      if (end > totalPages + firstPage - leftPages) {
        start = totalPages + firstPage - pagesLimit;
        end = totalPages;
      }

      if (start < firstPage) {
        end += firstPage - start;
        start = firstPage;
      }

      return this.pages.filter(page => page === currentPage || (page >= start && page <= end));
    },

    getPreviousNumber(currentNumber) {
      const previousNumber = currentNumber - 1;

      if (previousNumber < firstPage) {
        return firstPage;
      }

      return previousNumber;
    },

    getNextNumber(currentNumber) {
      const nextNumber = currentNumber + 1;

      if (nextNumber >= this.pages.length) {
        return this.pages.length - 1 + firstPage;
      }

      return nextNumber;
    },
  };
}
