<template>
  <ds-pagination
    ref="pagination"
    :is-dropdown="isDropdown"
    :page-size.sync="localPageSize"
    :page="localPage"
    :total-items="totalItems"
    :first-page="firstPage"
    @hook:mounted="onPaginationMount"
    @page-change="onPageChange" />
</template>

<script>
import DsPagination from '@components/pagination';

export default {
  name: 'DsDataGridPagination',
  components: {
    DsPagination,
  },
  props: {
    isDropdown: {
      type: Boolean,
      default: false,
    },
    pageSize: DsPagination.props.pageSize,
    page: DsPagination.props.page,
    firstPage: DsPagination.props.firstPage,
    totalItems: DsPagination.props.totalItems,
  },
  data() {
    return {
      localPage: this.page,
      localPageSize: this.pageSize,
    };
  },
  watch: {
    async localPageSize(pageSize, previousPageSize) {
      /** Awaits a possible localPage change */
      await this.$nextTick();

      this.handlePageChange({
        pageSize,
        page: this.localPage,
        totalItems: this.totalItems,
        /**
         * Allows to prevent multiple data fetches when pagination is not defined yet,
         * since its value comes from an async API. Now we consider page change only after
         * the pagination object is already assigned.
         */
        triggerRefresh: previousPageSize !== undefined,
      });
    },
    pageSize(pageSize) {
      this.localPageSize = pageSize;
    },
    page(newPage, previousPage) {
      this.localPage = newPage;

      this.handlePageChange({
        page: newPage,
        pageSize: this.localPageSize,
        totalItems: this.totalItems,
        /**
         * Allows to prevent multiple data fetches when pagination is not defined yet,
         * since its value comes from an async API. Now we consider page change only after
         * the pagination object is already assigned.
         */
        triggerRefresh: previousPage !== undefined,
      });
    },
  },
  methods: {
    onPaginationMount() {
      this.$emit('init-pagination', {
        pageSize: this.$refs.pagination.pageSize,
        page: this.localPage,
      });
    },
    handlePageChange({ page, pageSize, totalItems, triggerRefresh }) {
      this.$emit(
        'page-change',
        {
          page,
          pageSize,
          totalItems,
        },
        triggerRefresh,
      );
    },
    onPageChange(page, { fromPageSize } = {}) {
      /**
       * Allows to prevent multiple data fetches when pagination is not defined yet,
       * since its value comes from an async API. Now we consider page change only after
       * the pagination object is already assigned.
       */
      if (this.localPage !== undefined) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.handlePageChange({
            page,
            pageSize: this.localPageSize,
            totalItems: this.totalItems,
            triggerRefresh: !fromPageSize,
          });
        }, 300);
      }

      this.localPage = page;
    },
  },
};
</script>
