<template>
  <p class="ds-paragraph" :class="classes">
    <slot></slot>
  </p>
</template>

<script>
import * as CONSTANTS from '@core/constants';

const VALID_SIZES = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'];

export default {
  name: 'DsP',
  props: {
    align: {
      type: String,
      validator(value) {
        return ['left', 'center', 'right'].includes(value);
      },
    },
    fontStyle: {
      type: String,
      validator(value) {
        return ['normal', 'italic'].includes(value);
      },
    },
    weight: {
      type: String,
      default: 'regular',
      validator(value) {
        return ['regular', 'medium', 'bold'].includes(value);
      },
    },
    color: {
      type: String,
      validator(color) {
        return CONSTANTS.COLOR_CONSTANTS.COLORS.includes(color);
      },
    },
    size: {
      type: String,
      default: 'xxs',
      validator(size) {
        return VALID_SIZES.includes(size);
      },
    },
  },
  computed: {
    classes() {
      return [
        `ds-paragraph__style--${this.fontStyle}`,
        `ds-paragraph--size-${this.size}`,
        {
          [`ds-u-color--${this.color}`]: this.color,
          [`ds-u-text-align--${this.align}`]: this.align,
          [`ds-paragraph__weight--${this.weight}`]: this.weight,
        },
      ];
    },
  },
};
</script>

<style scoped>
@import './Paragraph.css';
</style>
