import { vnodeService } from '@core';
import { Popover } from './popoverService';

function createPopover(referenceElement, popoverOptions, popoverVm) {
  return new Popover(referenceElement, popoverVm.$el, {
    ...popoverOptions,
    onClose: () => popoverVm.$destroy(),
  });
}

export default {
  install(Vue, options) {
    const componentRenderer = vnodeService.getComponentRenderer(Vue, options);

    Vue.dsPopover = (PopoverComponent, renderOptions) => ({
      open(referenceElement, popoverOptions) {
        const wrapper = componentRenderer(PopoverComponent, renderOptions);
        const popoverVm = vnodeService.getChildComponentInstance(wrapper.$vnode, 'DsPopover');
        const popover = createPopover(referenceElement, popoverOptions, popoverVm);
        popoverVm.$on('hook:beforeDestroy', () => popover.close());
        return popover;
      },
    });

    Vue.prototype.$dsPopover = Vue.dsPopover;
  },
};
