<template>
  <div class="ds-async-content-wrapper">
    <div v-if="shouldShowLoader" class="ds-async-content-wrapper__loader">
      <ds-loader :theme="loaderTheme" />
    </div>
    <ds-box-information
      v-if="shouldShowErrorMessage"
      theme="danger"
      :title="errorTitle"
      :message="errorMessage"
      hide-close-button>
      <ds-box-information-button @click="fetch">
        {{ retryButtonText }}
      </ds-box-information-button>
    </ds-box-information>
    <div v-if="shouldShowContent">
      <slot :response="response"></slot>
    </div>
  </div>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import DsBoxInformation from '@components/box-information';
import DsBoxInformationButton from '@components/box-information-button';
import DsLoader from '@components/loader';
import TEXT_CONSTANTS from './constants';

export default {
  name: 'DsAsyncContentWrapper',
  components: {
    DsBoxInformation,
    DsLoader,
    DsBoxInformationButton,
  },
  props: {
    loaderTheme: DsLoader.props.theme,
    errorMessage: {
      type: String,
      default: TEXT_CONSTANTS.DEFAULT_ERROR_MESSAGE,
    },
    errorTitle: {
      type: String,
      default: TEXT_CONSTANTS.DEFAULT_ERROR_TITLE,
    },
    fetchAction: {
      type: Function,
      default: null,
    },
    /**
     * DEPRECATED PROP | Use fetch-action props instead
     */
    onFetch: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use @fetch-success event instead
     */
    onFetchSuccess: {
      type: Function,
    },
    retryButtonText: {
      type: String,
      default: TEXT_CONSTANTS.DEFAULT_RETRY_TEXT,
    },
  },
  data() {
    return {
      response: null,
      shouldShowContent: null,
      shouldShowErrorMessage: null,
      shouldShowLoader: null,
    };
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.onFetch) {
      deprecatedDependency.deprecateProperty('onFetch', 'Use fetch-action instead');
    }
    if (this.onFetchSuccess) {
      deprecatedDependency.deprecateProperty('onFetchSuccess', 'Use @fetch-success event instead');
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.setContentVisibility(false);
      this.setErrorMessageVisibility(false);
      this.setLoaderVisibility(true);
      const fetchAction = this.getFetchAction();
      fetchAction()
        .then(response => {
          this.response = response;
          this.handleFetchSuccess(response);
          this.setContentVisibility(true);
        }, this.onFetchError)
        .finally(this.onFetchComplete);
    },
    onFetchError() {
      this.setErrorMessageVisibility(true);
    },
    onFetchComplete() {
      this.setLoaderVisibility(false);
    },
    setContentVisibility(shouldShow) {
      this.shouldShowContent = shouldShow;
    },
    setErrorMessageVisibility(shouldShow) {
      this.shouldShowErrorMessage = shouldShow;
    },
    setLoaderVisibility(shouldShow) {
      this.shouldShowLoader = shouldShow;
    },
    getFetchAction() {
      return this.fetchAction || this.onFetch;
    },
    handleFetchSuccess(response) {
      if (this.onFetchSuccess) {
        this.onFetchSuccess(response);
      }
      this.$emit('fetch-success', response);
    },
  },
};
</script>

<style scoped>
@import './AsyncContentWrapper.css';
</style>
