<template>
  <ds-currency-range-popover
    :start-value="startValue"
    :end-value="endValue"
    :on-apply="onApply"
    @hook:destroyed="$destroy()" />
</template>

<script>
import DsCurrencyRangePopover from '@components/currency-range-popover/CurrencyRangePopover.vue';

export default {
  name: 'DsDataGridAdvancedFilterCurrencyPopover',
  components: {
    DsCurrencyRangePopover,
  },
  props: {
    setValue: {
      type: Function,
      required: true,
    },
    value: {
      type: Object,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  computed: {
    startValue() {
      return this.value?.startValue || null;
    },
    endValue() {
      return this.value?.endValue || null;
    },
  },
  beforeDestroy() {
    this.setValue(this.localValue);
  },
  methods: {
    onApply(value) {
      this.localValue = value;
    },
  },
};
</script>
