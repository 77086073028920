export const STATUS = Object.freeze({
  PENDING: 'pending',
  ACTIVE: 'active',
  UPDATING: 'updating',
  INACTIVE: 'inactive',
});

export const TYPES = Object.freeze({
  SELECT: 'select',
  PERIOD: 'period',
  PERIOD_MONTH: 'period-month',
  CURRENCY: 'currency',
  NUMBER: 'number',
});
