<template>
  <div class="ds-total-bar-item" :class="`ds-total-bar-item--${labelOrientation}`">
    <ds-label :text="label" :icon-tooltip="iconTooltip"> </ds-label>
    <ds-currency-highlight :value="value" :size="size" :color="color"> </ds-currency-highlight>
  </div>
</template>

<script>
import DsLabel from '@components/label';
import DsCurrencyHighlight from '@components/currency-highlight';
import { propValidationService } from '@core';

const { iconTooltip } = DsLabel.props;
const { value, size, color } = DsCurrencyHighlight.props;

export default {
  name: 'DsTotalBarItem',
  components: {
    DsLabel,
    DsCurrencyHighlight,
  },
  props: {
    label: String,
    labelOrientation: {
      type: String,
      default: 'column',
      validator: propValidationService.buildValidator({
        validValues: ['column', 'inline'],
        errorMessage: 'You should inform one of the valid align values',
      }),
    },
    iconTooltip,
    value,
    size,
    color,
  },
};
</script>

<style scoped>
@import './TotalBarItem.css';
</style>
