const labelsBackButton = {
  FIRST: 'Cancelar',
  DEFAULT: 'Voltar',
};

const labelsNextButton = {
  DEFAULT: 'Continuar',
  PENULTIMATE: 'Concluir',
};

export default class StepperService {
  constructor(steps) {
    this.steps = steps;
  }

  isValidIndex(index) {
    return index >= 0 && (this.steps ? index < this.steps.length : true);
  }

  getStepOrder(index) {
    const orders = {
      0: 'FIRST',
      [this.steps.length - 2]: 'PENULTIMATE',
      [this.steps.length - 1]: 'LAST',
    };

    return orders[index];
  }

  getLabelBackButton(index) {
    const stepOrder = this.getStepOrder(index);
    return labelsBackButton[stepOrder] || labelsBackButton.DEFAULT;
  }

  getLabelNextButton(index) {
    const stepOrder = this.getStepOrder(index);
    return labelsNextButton[stepOrder] || labelsNextButton.DEFAULT;
  }

  shouldHideButtons(index) {
    const stepOrder = this.getStepOrder(index);
    return stepOrder === 'LAST';
  }
}
