<template>
  <div>
    <div class="ds-steps ds-u-hide--xs" :class="{ 'ds-steps--done': isDone }">
      <div class="ds-steps__content">
        <step
          v-for="(step, index) in steps"
          :key="index"
          :n-steps="nSteps"
          :current-step="currentStep"
          :index="index"
          @end="done => (isDone = done)" />
      </div>
      <labels :steps="steps" :current-step="currentStep" />
    </div>
    <div class="ds-steps--xs ds-u-display--none ds-u-show--xs">
      <h2 class="ds-steps__title">{{ steps[currentStep].title }}</h2>
      <p class="ds-steps__subtitle">Passo {{ printStep(currentStep + 1) }} de {{ printStep(steps.length) }}</p>
    </div>
  </div>
</template>

<script>
import Step from './Step.vue';
import Labels from './Labels.vue';

export default {
  name: 'DsSteps',
  components: {
    Step,
    Labels,
  },
  props: {
    /**
     * [{ title, subtitle }]
     */
    steps: {
      type: Array,
      required: true,
    },
    /**
     * Current step from 0 to n
     */
    currentStep: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isDone: false,
    };
  },
  computed: {
    nSteps() {
      return this.steps.length;
    },
  },
  created() {
    this.isDone = this.currentStep === this.nSteps - 1;
  },
  methods: {
    printStep(step) {
      return step.toString().length === 1 ? `0${step}` : step;
    },
  },
};
</script>

<style scoped>
@import './Steps.css';
</style>
