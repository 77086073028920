<template>
  <ds-tr ref="inlineTableRow" class="ds-inline-table-row" not-hoverable>
    <slot />
    <ds-td v-if="shouldShowCustomActions">
      <ds-dropdown v-if="hasMultipleCustomActions" ref="customAction" title="Ações">
        <ds-dropdown-item
          v-for="action in customActions"
          :key="action.name"
          v-ds-tooltip="handleTooltip(action, entry)"
          :disabled="handleDisabled(action, entry)"
          @click="action.action(entry, index)">
          {{ action.name }}
        </ds-dropdown-item>
      </ds-dropdown>
      <div v-else v-ds-tooltip="handleTooltip(customActions[0], entry)">
        <ds-button
          ref="customAction"
          :disabled="handleDisabled(customActions[0], entry)"
          @click="customActions[0].action(entry, index)">
          {{ customActions[0].name }}
        </ds-button>
      </div>
    </ds-td>
    <ds-td
      v-if="allowRemoveItem"
      class="ds-inline-table-row__delete-button-column"
      :style="`height: ${inlineRowHeight}`">
      <ds-button
        :tooltip="removeItemTooltipText"
        size="lg"
        theme="link"
        :icon="['light', 'times']"
        :disabled="disabled"
        @click="deleteRow" />
    </ds-td>
  </ds-tr>
</template>

<script>
import isEqual from 'lodash/isEqual';
import DsTr from '@components/table-row';
import DsTd from '@components/table-column';
import DsTooltip from '@directives/tooltip';
import DsButton from '@components/button';
import DsDropdown from '@components/dropdown';
import DsDropdownItem from '@components/dropdown-item';
import { getOffsetWidth } from '@core/services/dom/domService';
import { debug } from '@core';

function getEntryDataWithoutId(entry) {
  const localEntry = { ...entry };
  delete localEntry.id;
  return localEntry;
}

export default {
  name: 'DsInlineTableRow',
  components: {
    DsTr,
    DsTd,
    DsButton,
    DsDropdown,
    DsDropdownItem,
  },
  directives: {
    DsTooltip,
  },
  provide() {
    return {
      inlineTableRowVm: {
        getInitialValue: this.getInitialValue,
        registerColumn: this.registerColumn,
        updateProperty: this.updateProperty,
        isDisabled: () => this.disabled,
      },
    };
  },
  props: {
    entry: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    allowRemoveItem: {
      type: Boolean,
      default: true,
    },
    removeItemTooltipText: {
      type: String,
      default: 'Remover',
    },
    customActions: {
      type: Array,
      default: () => [],
    },
    shouldShowCustomActions: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inlineRowHeight: 'auto',
      data: {
        ...getEntryDataWithoutId(this.entry),
      },
      inlineTableColumnsVms: {},
    };
  },
  computed: {
    hasMultipleCustomActions() {
      return this.customActions && this.customActions.length > 1;
    },
  },
  watch: {
    entry: {
      deep: true,
      handler() {
        const entryWithoutId = getEntryDataWithoutId(this.entry);
        if (!isEqual(entryWithoutId, this.data)) {
          this.forceTableColumnsUpdate(entryWithoutId);
        }
      },
    },
    allowRemoveItem() {
      this.setActionsHeaderWidth();
    },
    hasMultipleCustomActions() {
      this.setActionsHeaderWidth();
    },
  },
  mounted() {
    this.setActionsHeaderWidth();
  },
  methods: {
    deleteRow() {
      if (!this.disabled) {
        this.$emit('delete-entry', this.entry.id);
      }
    },
    updateProperty({ key, value }) {
      this.data[key] = value;
      this.$emit('update-entry', {
        id: this.entry.id,
        data: this.data,
      });

      this.$emit('update-entry-property', {
        rowIndex: this.index,
        property: key,
        value,
      });
    },
    getInitialValue() {
      return this.data;
    },
    registerColumn({ key, inlineTableColumnVm }) {
      this.inlineTableColumnsVms[key] = inlineTableColumnVm;
    },
    forceTableColumnsUpdate(entryWithoutId) {
      const properties = Object.keys(entryWithoutId);
      properties.forEach(property => {
        const column = this.inlineTableColumnsVms[property];
        if (column) {
          column.setLocalValue(entryWithoutId[property]);
        }
      });
    },
    deleteProperty(property) {
      delete this.data[property];
      this.$emit('update-entry', {
        id: this.entry.id,
        data: this.data,
      });
    },
    setActionsHeaderWidth() {
      if (this.shouldShowCustomActions && this.$refs.customAction) {
        const padding = this.allowRemoveItem ? 16 : 8;
        const width = getOffsetWidth(this.$refs.customAction.$el);
        this.$emit('update-actions-header-width', { width, padding });
      }
    },
    handleDisabled({ disabled }, rowData) {
      return typeof disabled === 'function' ? disabled(rowData) : disabled;
    },
    handleTooltip(action, rowData) {
      const isDisabled = this.handleDisabled(action, rowData);

      if (isDisabled && !action.disabledTooltip) {
        debug.error('"disabledTooltip" prop must be informed when a custom action is disabled');
      }

      return isDisabled ? action.disabledTooltip : null;
    },
    focus() {
      setTimeout(() => this.$refs.inlineTableRow.$children[0].focus && this.$refs.inlineTableRow.$children[0].focus());
    },
  },
};
</script>

<style scoped>
@import './InlineTableRow.css';
</style>
