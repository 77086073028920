import isNil from 'lodash/isNil';
import 'numeral/locales/pt-br';
import numeral from 'numeral';

numeral.locale('pt-br');

const BACKSPACE_KEY = 8;
const DEL_KEY = 46;

export function isSomeDeletionKey(event) {
  return event.which === BACKSPACE_KEY || event.which === DEL_KEY;
}

export function isCursorAtLastPosition(event, suffix = '') {
  const { value, selectionEnd } = event.target;
  return !value.length || selectionEnd === value.length - suffix.length;
}

export function isEmptyValue(value) {
  return isNil(value) || unformatValue(value) === 0 || value === '';
}

export function unformatValue(value, { absoluteOnly } = {}) {
  if (isNil(value)) return null;
  const numeralValue = numeral._.stringToNumber(String(value));
  return absoluteOnly ? Math.abs(numeralValue) : numeralValue;
}

function getNumeralFormat(precision) {
  return precision ? `0,0.${new Array(precision).fill('0').join('')}` : '0,0';
}

export function formatValue(value, { absoluteOnly, precision = 2, prefix = '', suffix = '' } = {}) {
  if (isNil(value)) return null;
  const numeralValue = absoluteOnly ? Math.abs(numeral(value).value()) : numeral(value).value();
  const formattedValue = numeral(numeralValue).format(getNumeralFormat(precision));
  return `${prefix}${formattedValue}${suffix}`;
}

export function removeNegativeSignal(value) {
  return value.replace('-', '');
}
