<template>
  <div v-if="shouldShowBoxInformation" :class="themeCssClass" class="ds-box-information ds-u-print-hidden">
    <ds-close-button v-if="!hideCloseButton" :color="closeButtonColor" size="sm" @click="onClose" />
    <ds-icon class="ds-box-information__icon" :icon="iconTheme" size="xl" />
    <div class="ds-box-information__content">
      <ds-text weight="bold">
        {{ title }}
      </ds-text>
      <!-- eslint-disable vue/no-v-html -->
      <ds-paragraph class="ds-u-margin-top--none ds-u-margin-bottom--none" v-html="message" />
      <slot />
    </div>
  </div>
</template>

<script>
import DsCloseButton from '@components/close-button';
import DsText from '@components/text';
import DsParagraph from '@components/paragraph';
import DsIcon from '@components/icon';

const themeColorMap = {
  success: 'green-darkest',
  info: 'blue-darkest',
  warning: 'yellow-darkest',
  danger: 'red-darkest',
};

const themeIconMap = {
  success: 'check-circle',
  info: 'info-circle',
  warning: 'exclamation-circle',
  danger: 'times-circle',
};

export default {
  name: 'DsBoxInformation',
  components: {
    DsCloseButton,
    DsText,
    DsParagraph,
    DsIcon,
  },
  props: {
    /**
     * [success, info, warning, danger]
     * */
    theme: {
      type: String,
      required: true,
      validator(value) {
        return ['success', 'info', 'warning', 'danger'].includes(value);
      },
    },
    title: {
      type: String,
      required: true,
    },
    message: String,
    hideCloseButton: Boolean,
  },
  data() {
    return {
      shouldShowBoxInformation: true,
    };
  },
  computed: {
    themeCssClass() {
      return `ds-box-information--${this.theme}`;
    },
    closeButtonColor() {
      return themeColorMap[this.theme];
    },
    iconTheme() {
      return themeIconMap[this.theme];
    },
  },
  methods: {
    onClose() {
      this.closeBoxInformation();
      this.$emit('close');
    },
    closeBoxInformation() {
      this.shouldShowBoxInformation = false;
    },
  },
};
</script>

<style scoped>
@import './BoxInformation.css';
</style>
