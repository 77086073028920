<template>
  <span class="ds-topbar-divider"></span>
</template>

<script>
export default {
  name: 'DsTopbarDivider',
};
</script>

<style scoped>
@import './TopbarDivider.css';
</style>
