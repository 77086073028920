<template>
  <span class="ds-search-select__quick-add-button">
    <ds-button full-width theme="link" @click="handleOnClick()">
      <ds-icon icon="plus-circle" size="sm"></ds-icon>
      <span class="ds-search-select__quick-add-button__label">
        {{ label }}
      </span>
    </ds-button>
  </span>
</template>

<script>
import DsButton from '@components/button';
import DsIcon from '@components/icon';

export default {
  name: 'DsSearchSelectQuickAddButton',
  components: {
    DsButton,
    DsIcon,
  },
  props: {
    label: {
      type: String,
      default: 'Criar novo registro',
    },
  },
  methods: {
    handleOnClick() {
      this.$emit('click');
    },
  },
};
</script>
