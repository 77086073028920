<template>
  <keep-alive>
    <component
      :is="componentName"
      v-show="shouldShowItem"
      :id="id"
      :label="label"
      :icon="icon"
      :is-new="isNew"
      :count-value="countValue"
      :count-value-tooltip="countValueTooltip"
      :is-external="isExternal"
      :is-active="isActive"
      :href="href"
      :item-level="itemLevel"
      :badge-value="badgeValue"
      :badge-theme="badgeTheme"
      :is-root-group-of-active-item="isRootGroupOfActiveItem"
      :should-show-alternative-second-level="shouldShowAlternativeSecondLevel"
      :show-favorite-button="showFavoriteButton"
      :is-favorite="isFavorite"
      :collapsible="collapsible"
      class="ds-sidebar-menu-item"
      :class="classes"
      :style="{ height: contentHeight }"
      :type="type"
      @favorite-change="onFavorite"
      @item-selected="onSelect"
      @item-unselected="onUnselect"
      @mouseenter.native="onMouseEnter"
      @mouseleave.native="onMouseLeave">
      <template #alternative-second-level-header>
        <slot name="alternative-second-level-header" />
      </template>
      <template #alternative-second-level>
        <slot name="alternative-second-level" />
      </template>
      <slot />
    </component>
  </keep-alive>
</template>

<script>
import { getChildrenFromSlot, isComponent } from '@core/services/vnode/vnodeService';

import DsAnimation from '@components/animation/Animation.vue';
import DsSidebarMenuItemSingle from './SidebarMenuItemSingle.vue';
import DsSidebarMenuItemGroup from './SidebarMenuItemGroup.vue';

const SLIDE_UP_TRANSITION_TIME = 400;
const FAVORITE = 'FAVORITE';

export default {
  name: 'DsSidebarMenuItem',
  components: {
    DsSidebarMenuItemSingle,
    DsSidebarMenuItemGroup,
    DsAnimation,
  },
  props: {
    domain: {
      type: String,
    },
    label: {
      type: String,
      required: true,
    },
    icon: DsSidebarMenuItemSingle.props.icon,
    href: {
      type: String,
    },
    isNew: {
      type: Boolean,
    },
    isExternal: {
      type: Boolean,
    },
    shouldShowAlternativeSecondLevel: {
      type: Boolean,
    },
    /**
     * - Array of keys which should match the item
     * - Function that returns true/false given a key as param
     */
    matchWith: {
      type: [Array, Function],
      default: () => [],
    },
    id: {
      type: String,
    },
    type: {
      type: String,
    },
    badgeValue: {
      type: String,
    },
    badgeTheme: {
      type: String,
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    countValue: {
      type: Number,
    },
    countValueTooltip: {
      type: String,
    },
  },
  provide() {
    return {
      sidebarMenuItemVm: {
        getItemLevel: () => this.itemLevel,
        getItemId: () => this.internalId,
        getParentListIds: () => this.parentListIds,
        setChildHoveringState: this.setChildHoveringState,
      },
    };
  },
  inject: {
    sidebarVm: {
      default: null,
      from: 'sidebarVm',
    },
    sidebarMenuVm: {
      default: null,
    },
    parentSidebarMenuItemVm: {
      default: null,
      from: 'sidebarMenuItemVm',
    },
    sidebarMenuSecondLevelVm: {
      default: null,
    },
  },
  data() {
    return {
      parentListIds: [],
      hasSomeChildHovering: false,
      shouldAddSlideUpAnimationClass: false,
      contentHeight: null,
      item: {},
    };
  },
  computed: {
    internalId() {
      return this.id || this.$vnode.key;
    },
    isFavoriteGroup() {
      return this.id === FAVORITE;
    },
    componentName() {
      const children = getChildrenFromSlot(this.$slots.default || []);
      const hasSubitems = children.find(vnode => isComponent(vnode, 'DsSidebarMenuItem'));
      return hasSubitems || this.isFavoriteGroup ? 'DsSidebarMenuItemGroup' : 'DsSidebarMenuItemSingle';
    },
    itemLevel() {
      return this.parentSidebarMenuItemVm ? this.parentSidebarMenuItemVm.getItemLevel() + 1 : 0;
    },
    isActive() {
      return this.sidebarMenuVm.shouldItemBeActive(this.getCurrentItem());
    },
    classes() {
      return {
        [`ds-sidebar-menu-item-${this.getSidebarTheme()}-theme`]: true,
        'ds-sidebar-menu-item--active': this.isActive,
        'ds-sidebar-menu-item--hovering-child': this.hasSomeChildHovering,
        'ds-sidebar-menu-item--favorite': this.isFavorite,
        'ds-slide-up': this.shouldAddSlideUpAnimationClass,
      };
    },
    isRootLevel() {
      return this.itemLevel === 0;
    },
    isRootGroupOfActiveItem() {
      return this.sidebarMenuSecondLevelVm && this.isRootLevel && this.isActive;
    },
    shouldShowItem() {
      return this.itemLevel > 0 || this.isActive || !this.sidebarMenuSecondLevelVm;
    },
    showFavoriteButton() {
      return this.sidebarMenuVm.showFavorites && !this.isRootLevel;
    },
    isFavorite() {
      return this.sidebarMenuVm.isFavorite(this.internalId);
    },
  },
  created() {
    if (this.sidebarMenuVm.showFavorites && !this.id) {
      throw new Error('To use the favorites functionality, all items must contain an id');
    }
    if (this.parentSidebarMenuItemVm) {
      const parentId = this.parentSidebarMenuItemVm.getItemId();
      this.parentListIds = [parentId, ...this.parentSidebarMenuItemVm.getParentListIds()];
    }
    if (!this.sidebarMenuSecondLevelVm && this.shouldShowAlternativeSecondLevel) {
      this.$emit('update:shouldShowAlternativeSecondLevel', false);
    }
    this.item = this.getCurrentItem();
    this.sidebarMenuVm.register(this.item);
  },
  methods: {
    getCurrentItem() {
      return {
        id: this.internalId,
        domain: this.domain,
        label: this.label,
        icon: this.icon,
        href: this.href,
        isNew: this.isNew,
        isExternal: this.isExternal,
        level: this.itemLevel,
        hasSubitems: this.componentName === 'DsSidebarMenuItemGroup',
        isFavorite: this.isFavorite,
        parentListIds: this.parentListIds,
        select: this.onSelect,
        matchWith: this.matchWith,
        showAlternativeSecondLevel: this.shouldShowAlternativeSecondLevel,
      };
    },
    onSelect() {
      this.sidebarMenuVm.setActiveItem(this.getCurrentItem());
    },
    getElementHeight() {
      return `${this.$el.offsetHeight}px`;
    },
    onFavorite() {
      if (this.isFavorite) {
        if (this.type === FAVORITE) {
          this.contentHeight = this.getElementHeight();
          this.handleSlideUpAnimation();
          this.removeItemAfterAnimation();
          return;
        }

        this.sidebarMenuVm.removeFavorite(this.item);

        return;
      }

      this.sidebarMenuVm.addFavorite(this.item);
    },
    handleSlideUpAnimation() {
      setTimeout(() => {
        this.shouldAddSlideUpAnimationClass = true;
      });
    },
    removeItemAfterAnimation() {
      setTimeout(() => {
        this.sidebarMenuVm.removeFavorite(this.item);
      }, SLIDE_UP_TRANSITION_TIME);
    },
    onUnselect() {
      if (this.shouldShowAlternativeSecondLevel) {
        this.$emit('update:shouldShowAlternativeSecondLevel', false);
      } else {
        this.sidebarMenuVm.setActiveItem(null);
      }
      this.$emit('click', this.getCurrentItem());
    },
    onMouseEnter() {
      if (this.parentSidebarMenuItemVm) {
        this.parentSidebarMenuItemVm.setChildHoveringState(true);
      }
    },
    onMouseLeave() {
      if (this.parentSidebarMenuItemVm) {
        this.parentSidebarMenuItemVm.setChildHoveringState(false);
      }
    },
    setChildHoveringState(state) {
      this.hasSomeChildHovering = state;
    },
    getSidebarTheme() {
      return this.sidebarVm.getTheme();
    },
  },
};
</script>
<style scoped>
@import './SidebarMenuItem.css';
</style>
