export function focus(elementRefName) {
  return {
    methods: {
      focus() {
        if (this.$refs[elementRefName] && this.$refs[elementRefName].focus) {
          this.$refs[elementRefName].focus();
        }
      },
    },
  };
}
