const technicalIssuesProps = {
  title: 'Problemas técnicos',
  text: 'Clique para acompanhar',
  styleSuffix: 'technical-issues',
  closeButtonTheme: 'dark',
  color: {
    background: '--ds-color--silver',
  },
  illustrationSize: {
    sm: 'technical-tool-sm',
    md: 'technical-tool-md',
    lg: 'technical-tool-lg',
  },
};

export const SIZE = {
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XS: 'xs',
  XXS: 'xxs',
};

export const TYPE = {
  accountancy: 'ACCOUNTANCY_BANNER',
  'first-steps': 'FIRST_STEPS_BANNER',
  training: 'TRAINING_BANNER',
  'technical-issues': 'TECHNICAL_ISSUES_BANNER',
  'technical-issues-with-badge': 'TECHNICAL_ISSUES_BANNER_WITH_BADGE',
  'open-finance': 'OPEN_FINANCE_BANNER',
  'trial-custom': 'TRIAL_CUSTOM_BANNER',
  charges: 'CHARGES_BANNER',
  'digital-account-invite': 'DIGITAL_ACCOUNT_INVITE',
  referrals: 'REFERRALS',
};

export const BANNER_CONFIG = Object.freeze({
  ACCOUNTANCY_BANNER: {
    title: 'Elimine a burocracia',
    text: 'Conecte-se com seu contador ou BPO',
    styleSuffix: 'accountancy',
    closeButtonTheme: 'dark',
    color: {
      background: '--ds-color--purple-light',
      opacity: '0.6',
    },
    illustrationSize: {
      sm: 'papers-person-sm',
      md: 'papers-person-md',
      lg: 'papers-person-lg',
    },
  },
  FIRST_STEPS_BANNER: {
    title: 'Primeiros passos',
    text: 'Deixe a Conta Azul pronta para sua empresa',
    styleSuffix: 'first-steps',
    closeButtonTheme: 'dark',
    color: {
      background: '--ds-color--green-semi-light',
      opacity: '0.4',
    },
    illustrationSize: {
      sm: 'start-setup-sm',
      md: 'start-setup-md',
      lg: 'start-setup-lg',
    },
  },
  TRAINING_BANNER: {
    title: 'Na prática',
    text: 'Treinamentos gratuitos, online e ao vivo!',
    styleSuffix: 'training',
    closeButtonTheme: 'dark',
    color: {
      background: '--ds-color--blue-light-smb',
      opacity: '0.8',
    },
    illustrationSize: {
      sm: 'desktop-video-sm',
      md: 'desktop-video-md',
      lg: 'desktop-video-lg',
    },
  },
  TECHNICAL_ISSUES_BANNER: technicalIssuesProps,
  TECHNICAL_ISSUES_BANNER_WITH_BADGE: {
    ...technicalIssuesProps,
    badge: {
      description: {
        text: 'Nova atualização',
        theme: 'warning',
      },
    },
  },
  OPEN_FINANCE_BANNER: {
    title: 'Open Finance',
    text: 'Nossa integração evoluiu, conheça agora',
    styleSuffix: 'open-finance',
    closeButtonTheme: 'light',
    color: {
      background: '--ds-color--blue-semi-light-acc',
    },
    illustrationSize: {
      sm: 'open-finance-sm',
      md: 'open-finance-md',
      lg: 'open-finance-lg',
    },
  },
  CHARGES_BANNER: {
    title: 'Cobre com a Conta Azul',
    text: 'E receba mais rápido, com baixa automática',
    styleSuffix: 'charges',
    closeButtonTheme: 'light',
    color: {
      background: '--ds-color--blue-semi-dark-smb',
    },
    illustrationSize: {
      sm: 'computer-doc-export-sm',
      md: 'computer-doc-export-md',
      lg: 'computer-doc-export-lg',
    },
  },
  DIGITAL_ACCOUNT_INVITE: {
    title: 'Conheça a Conta PJ',
    titleSize: {
      lg: 'Conheça a Conta PJ da Conta Azul',
    },
    text: 'Cobre, receba e pague em um só lugar',
    textSize: {
      lg: 'Cobre, receba e pague em um só lugar, de forma integrada ao financeiro',
    },
    styleSuffix: 'digital-account-invite',
    closeButtonTheme: 'light',
    color: {
      background: '--ds-color--blue-semi-light-acc',
    },
    illustrationSize: {
      sm: 'account-invite-sm',
      md: 'account-invite-md',
      lg: 'account-invite-lg',
    },
  },
  REFERRALS: {
    title: 'Indique e ganhe até R$500',
    text: 'Se sua indicação se tornar cliente Conta Azul, você ganha até R$500!',
    textSize: {
      sm: ' ',
    },
    styleSuffix: 'referrals',
    closeButtonTheme: 'light',
    color: {
      background: '--ds-color--gold-semi-dark',
      opacity: 0.6,
    },
    illustrationSize: {
      sm: 'gift-screen-sm',
      md: 'gift-screen-md',
      lg: 'gift-screen-lg',
    },
  },
});
