<template>
  <div class="ds-feedback">
    <ds-feedback-rate
      v-model="localValue.rate"
      required
      :title="title"
      :first-text="firstRateText"
      :last-text="lastRateText" />
    <ds-field v-if="localValue.rate" class="ds-u-margin-top" :label="fieldLabel">
      <ds-textarea v-model="localValue.comment" />
    </ds-field>
  </div>
</template>

<script>
import DsField from '@components/field';
import DsTextarea from '@components/textarea';
import DsFeedbackRate from './FeedbackRate.vue';

export default {
  name: 'DsFeedback',
  components: {
    DsField,
    DsTextarea,
    DsFeedbackRate,
  },
  props: {
    firstRateText: DsFeedbackRate.props.firstText,
    lastRateText: DsFeedbackRate.props.lastText,
    label: DsField.props.label,
    /**
     * { rate, comment }
     */
    value: {
      type: Object,
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      localValue: this.value || {
        rate: null,
        comment: null,
      },
    };
  },
  computed: {
    fieldLabel() {
      return this.label ? `${this.label} (Opcional)` : '';
    },
  },
  watch: {
    value: {
      deep: true,
      handler(value) {
        this.localValue = value;
      },
    },
    localValue: {
      deep: true,
      handler(localValue) {
        /**
         * On input rate or comment
         * @property {Object} value
         */
        this.$emit('input', localValue);
      },
    },
  },
};
</script>
