<template>
  <div class="ds-detail-total-bar-operator">
    <ds-icon :icon="type" color="text" />
  </div>
</template>

<script>
import DsIcon from '@components/icon';

export default {
  name: 'DsDetailTotalBarOperator',
  components: {
    DsIcon,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ['plus', 'minus', 'equals'].includes(value);
      },
    },
  },
};
</script>

<style scoped>
@import './DetailTotalBarOperator.css';
</style>
