<template>
  <div
    class="ds-drop-zone"
    :class="classes"
    @click.stop.prevent="handleClick"
    @dragenter.stop.prevent="handleDragEnter"
    @dragleave.stop.prevent="handleDragLeave"
    @dragover.stop.prevent="handleDragOver"
    @drop.stop.prevent="handleDrop">
    <slot> </slot>
  </div>
</template>

<script>
export default {
  name: 'DsDropZone',
  props: {
    onDragEnter: {
      type: Function,
      default: () => {},
    },
    onDragLeave: {
      type: Function,
      default: () => {},
    },
    onDragOver: {
      type: Function,
      default: () => {},
    },
    onDrop: {
      type: Function,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      isTarget: false,
      isFilled: false,
    };
  },
  computed: {
    classes() {
      return {
        'ds-drop-zone--target': this.isTarget,
        'ds-drop-zone--filled': this.isFilled,
        'ds-drop-zone--disabled': this.disabled,
      };
    },
  },
  methods: {
    setTargetState() {
      this.isTarget = true;
    },
    setNotTargetState() {
      this.isTarget = false;
    },
    setFilledState() {
      this.isFilled = true;
    },
    handleDragEnter(event) {
      if (!this.disabled) {
        this.setTargetState();
        this.onDragEnter(event);
      }
    },
    handleDragLeave(event) {
      if (!this.disabled) {
        this.setNotTargetState();
        this.onDragLeave(event);
      }
    },
    handleDragOver(event) {
      if (!this.disabled) {
        this.setTargetState();
        this.onDragOver(event);
      }
    },
    handleDrop(event) {
      if (!this.disabled) {
        this.setNotTargetState();
        this.setFilledState();
        this.onDrop(event);
      }
    },
    handleClick() {
      if (!this.disabled) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style scoped>
@import './DropZone.css';
</style>
