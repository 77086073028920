import Vue from 'vue';
import DsConfirm from './Confirm.vue';

export function build(
  title,
  message,
  onConfirm,
  { cancelButtonText, confirmButtonText, confirmButtonTheme, onCancel } = {},
) {
  if (!hasRequiredParams(title, message, onConfirm)) {
    return console.error('Ensure you are passing at least Title, Message and onConfirm to Confirm component.');
  }
  return buildConfirm(title, message, onConfirm, {
    cancelButtonText,
    confirmButtonText,
    confirmButtonTheme,
    onCancel,
  });
}

function hasRequiredParams(title, message, onConfirm) {
  return title && message && onConfirm;
}

function buildConfirm(title, message, onConfirm, options) {
  const Confirm = buildConfirmComponentConstructor(title, message, onConfirm, options);
  const component = new Confirm();
  component.$mount();
  return component;
}

function buildConfirmComponentConstructor(title, message, onConfirm, options) {
  return Vue.extend({
    render(h) {
      return h(
        DsConfirm,
        {
          props: {
            title,
            message,
            onConfirm,
            confirmButtonText: options.confirmButtonText,
            confirmButtonTheme: options.confirmButtonTheme,
            cancelButtonText: options.cancelButtonText,
            onCancel: options.onCancel,
          },
        },
        [message],
      );
    },
  });
}
