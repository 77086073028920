<template>
  <ds-dropdown v-ds-tooltip:280="tooltip" title="Mais filtros" :disabled="allFiltersSelected">
    <ds-dropdown-item
      v-for="filter in filtersNotSelected"
      :key="filter.name"
      :disabled="filter.disabled"
      @click="onFilterSelected(filter)">
      {{ filter.name }}
    </ds-dropdown-item>
  </ds-dropdown>
</template>

<script>
import DsDropdown from '@components/dropdown';
import DsDropdownItem from '@components/dropdown-item';
import DsTooltip from '@directives/tooltip';

export default {
  name: 'DsDataGridAdvancedFiltersDropdown',
  components: {
    DsDropdown,
    DsDropdownItem,
  },
  directives: {
    DsTooltip,
  },
  props: {
    store: {
      type: Object,
      required: true,
    },
  },
  computed: {
    filtersNotSelected() {
      return this.store.notFiltered;
    },
    allFiltersSelected() {
      return !Object.keys(this.store.notFiltered).length;
    },
    tooltip() {
      return this.allFiltersSelected
        ? 'Todos os filtros estão selecionados. Clique em um dos filtros abaixo para editá-lo.'
        : null;
    },
  },
  methods: {
    onFilterSelected(filter) {
      this.store.setFilterReady(filter.key);
    },
  },
};
</script>
