<template>
  <ds-range-popover :on-apply="onSubmit" @hook:destroyed="$destroy()">
    <ds-field slot="start-field" :label="startDateLabel" :class="dateFieldClasses">
      <ds-date-input
        ref="startInput"
        v-model="localStartDate"
        :required="isStartDateRequired"
        :enabled-dates="enabledStartDates()"
        :type="type"
        :calendar-view-date="startDateCalendarViewDate" />
    </ds-field>
    <ds-field slot="end-field" :label="endDateLabel" :class="dateFieldClasses">
      <ds-date-input
        ref="endInput"
        v-model="localEndDate"
        :required="isEndDateRequired"
        :enabled-dates="enabledEndDates()"
        :type="type"
        :calendar-view-date="endDateCalendarViewDate" />
    </ds-field>
    <ds-text v-if="shouldShowDateRangeHelperText" slot="helper-text" size="sm" color="grey">
      {{ popoverHelperText }}
    </ds-text>
  </ds-range-popover>
</template>

<script>
import DsRangePopover from '@components/range-popover/RangePopover.vue';
import DsField from '@components/field';
import DsDateInput from '@components/date-input';
import DsText from '@components/text';
import { isStartDateEnabled, isEndDateEnabled, focusInput, isMonthPeriodType } from './datePeriodPopoverService';

export default {
  name: 'DsDatePeriodPopover',
  components: {
    DsRangePopover,
    DsDateInput,
    DsField,
    DsText,
  },
  props: {
    startDate: DsDateInput.props.value,
    endDate: DsDateInput.props.value,
    minDate: [String, Date],
    maxDate: [String, Date],
    maxDateRange: Number,
    helperText: String,
    onApply: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      validator(type) {
        return ['day', 'month'].includes(type);
      },
    },
  },
  data() {
    return {
      localStartDate: this.startDate || null,
      localEndDate: this.endDate || null,
    };
  },
  computed: {
    startDateLabel() {
      return this.isMonthPeriodType ? 'Mês inicial' : 'Data inicial';
    },
    endDateLabel() {
      return this.isMonthPeriodType ? 'Mês final' : 'Data final';
    },
    isMonthPeriodType() {
      return isMonthPeriodType(this.type);
    },
    isStartDateRequired() {
      return this.localEndDate === null || !!this.maxDateRange;
    },
    isEndDateRequired() {
      return this.localStartDate === null || !!this.maxDateRange;
    },
    shouldShowDateRangeHelperText() {
      return !!this.helperText || !!this.maxDateRange;
    },
    dateFieldClasses() {
      return {
        'ds-u-margin-bottom--none': !!this.shouldShowDateRangeHelperText,
      };
    },
    popoverHelperText() {
      return this.helperText ?? `Você pode escolher um intervalo de até ${this.maxDateRange} dias.`;
    },
    startDateCalendarViewDate() {
      return this.maxDateRange && this.localEndDate ? this.localEndDate : undefined;
    },
    endDateCalendarViewDate() {
      return this.maxDateRange && this.localStartDate ? this.localStartDate : undefined;
    },
  },
  watch: {
    localStartDate() {
      this.checkIfEndDateIsStillValid();
    },
    localEndDate() {
      if (this.localEndDate < this.localStartDate) {
        this.localStartDate = null;
        this.setFocusStartDate();
      }
    },
  },
  methods: {
    setFocusEndDate() {
      setTimeout(() => focusInput(this.$refs.endInput.$el));
    },
    setFocusStartDate() {
      setTimeout(() => focusInput(this.$refs.startInput.$el));
    },
    checkIfEndDateIsStillValid() {
      const isEndDateValid = isEndDateEnabled(this.localEndDate, {
        startDate: this.localStartDate,
        minDate: this.minDate,
        maxDate: this.maxDate,
        maxDateRange: this.maxDateRange,
      });

      if (!isEndDateValid || this.localStartDate > this.localEndDate) {
        this.localEndDate = null;
        this.setFocusEndDate();
      }
    },
    enabledStartDates() {
      return [
        date =>
          isStartDateEnabled(date, {
            endDate: this.localEndDate,
            minDate: this.minDate,
            maxDate: this.maxDate,
          }),
      ];
    },
    enabledEndDates() {
      return [
        date =>
          isEndDateEnabled(date, {
            startDate: this.localStartDate,
            minDate: this.minDate,
            maxDate: this.maxDate,
            maxDateRange: this.maxDateRange,
          }),
      ];
    },
    onSubmit() {
      return this.onApply({
        startDate: this.localStartDate,
        endDate: this.localEndDate,
      });
    },
  },
};
</script>
