<template>
  <div :class="`tc-partner-progress-bar--${theme}`">
    <ds-progress-bar :percentage="validPercentage" :title="title" hide-percentage />
  </div>
</template>

<script>
import DsProgressBar from '@components/progress-bar/ProgressBar.vue';

export default {
  name: 'DsTcPartnerProgressBar',
  components: {
    DsProgressBar,
  },
  props: {
    title: {
      type: String,
    },
    percentage: {
      type: Number,
      required: true,
    },
    theme: {
      type: String,
      required: true,
      validator(theme) {
        return ['associate', 'certified', 'expert', 'reference', 'elite'].includes(theme);
      },
    },
  },
  computed: {
    validPercentage() {
      return this.getValidPercentage(this.percentage);
    },
  },
  methods: {
    getValidPercentage(receivedPercentage) {
      if (receivedPercentage < 0) return 0;
      if (receivedPercentage > 100) return 100;
      return Math.floor(receivedPercentage);
    },
  },
};
</script>

<style scoped>
@import './TcPartnerProgressBar.css';
</style>
