<template>
  <ds-sidebar-menu-item id="FAVORITE" label="Favoritos" :icon="['regular', 'star']">
    <transition name="ds-fade">
      <div v-if="!hasFavorite" class="ds-sidebar-menu-item-group-empty-favorite">
        <ds-p align="center" color="white">Adicione agora seus menus favoritos!</ds-p>
        <br />
        <ds-p align="center" color="white"> É só clicar na estrela do lado direito de cada menu.</ds-p>
        <br />
        <ds-p align="center" color="white"> Eles vão aparecer aqui na ordem que você adicionar.</ds-p>
      </div>
    </transition>
    <ds-sidebar-menu-item
      v-for="item in favoriteItems"
      :id="item.id"
      :key="item.id"
      :label="item.label"
      :is-new="item.isNew"
      :href="item.href"
      :icon="item.icon"
      type="FAVORITE" />
  </ds-sidebar-menu-item>
</template>

<script>
import DsSidebarMenuItem from '@components/sidebar-menu-item';
import DsP from '@components/paragraph';

export default {
  name: 'DsSidebarMenuFavorite',
  inject: ['sidebarMenuVm'],
  components: {
    DsSidebarMenuItem,
    DsP,
  },
  data() {
    return {
      initData: false,
    };
  },
  computed: {
    favoriteItems() {
      return this.sidebarMenuVm.getFavorites();
    },
    hasFavorite() {
      return this.sidebarMenuVm.hasFavorite();
    },
  },
  watch: {
    favoriteItems() {
      this.initData = this.sidebarMenuVm.haveFavoritesLoaded();
      this.emitFavoriteChange();
    },
  },
  methods: {
    emitFavoriteChange() {
      if (this.initData) {
        const favoriteIds = this.favoriteItems.map(item => item.id);
        this.$emit('favorites-change', favoriteIds);
      }
    },
  },
};
</script>
<style scoped>
@import './SidebarMenuFavorite.css';
</style>
