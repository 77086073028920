<template>
  <ds-split-button-wrapper v-bind="$props">
    <template #button>
      <ds-button :theme="theme" :disabled="disabled" :full-width="fullWidth" v-on="$listeners">
        {{ title }}
      </ds-button>
    </template>
    <slot />
  </ds-split-button-wrapper>
</template>

<script>
import DsButton from '@components/button';
import DsSplitButtonWrapper from '@components/split-button-wrapper/SplitButtonWrapper.vue';

export default {
  name: 'DsSplitDefaultButton',
  components: {
    DsButton,
    DsSplitButtonWrapper,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    theme: DsButton.props.theme,
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
    },
  },
};
</script>
