<template>
  <ds-list-filter
    v-if="listFilterItems && listFilterItems.length"
    class="ds-data-grid-list-filter"
    :on-filter-select="onFilterSelect"
    with-table>
    <ds-list-filter-item
      v-for="listFiltersItem in listFilterItems"
      :key="listFiltersItem.key"
      v-bind="listFiltersItem"
      :selected.sync="listFiltersItem.selected">
    </ds-list-filter-item>
  </ds-list-filter>
</template>

<script>
import DsListFilter from '@components/list-filter';
import DsListFilterItem from '@components/list-filter-item';

export default {
  name: 'DsDataGridListFilter',
  components: {
    DsListFilter,
    DsListFilterItem,
  },
  props: {
    listFilterItems: {
      type: Array,
    },
  },
  methods: {
    onFilterSelect(...args) {
      this.$emit('filter-select', ...args);
    },
  },
};
</script>
