<template>
  <div v-show="shouldShow" class="ds-option-wrapper" :class="classes" :style="inlineStyle">
    <div class="ds-options" data-select-options>
      <div v-if="$slots.header" class="ds-options__header">
        <slot name="header"></slot>
      </div>
      <div data-select-scrollable-options class="ds-options__scrollable-options" tabindex="-1">
        <slot></slot>
      </div>
      <div v-if="$slots.footer" class="ds-options__footer" tabindex="-1">
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

import { hasConstructor } from '@core/services/browser/browserService';
import { getSlotOptionsAsTree } from '@components/select/selectService';
import {
  getPreviousOption,
  getNextOption,
  handleScroll,
  getOptionVmByValue,
  getScrollableOptionsElement,
  getOptionVmByKey,
  getSlotOptions,
  isOptionEqual,
} from './selectOptionsService';

export default {
  name: 'DsSelectOptions',
  provide() {
    return {
      addOption: this.addOption,
      removeOption: this.removeOption,
    };
  },
  inject: {
    searchSelectVm: {
      default: null,
    },
    getSlotOptions: {
      default() {
        return () => getSlotOptions(this.$slots.default);
      },
    },
    getSlotOptionsAsTree: {
      default() {
        return () => getSlotOptionsAsTree(this.$slots.default);
      },
    },
  },
  props: {
    value: [String, Number, Array, Object],
    selectedOption: [String, Number, Array, Object],
    show: Boolean,
    width: Number,
    isSearchSelect: Boolean,
    hasButton: Boolean,
    compareValueBy: String,
  },
  data() {
    return {
      currentHoveredOptionKey: null,
      optionsPosition: null,
      options: {},
      shouldShow: false,
      observer: null,
    };
  },
  computed: {
    currentOptionKey() {
      return this.value;
    },
    inlineStyle() {
      return {
        width: `${this.width}px`,
      };
    },
    classes() {
      return {
        'ds-option-wrapper--hidden': !this.shouldShow,
      };
    },
    isTree() {
      return this.searchSelectVm?.isTree;
    },
  },
  watch: {
    show(show) {
      this[show ? 'open' : 'close']();
    },
    value(value) {
      this.selectOptionByValue(value);
    },
  },
  created() {
    this.setupKeydownEvents();
  },
  mounted() {
    this.setupTreeStructure();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    setupKeydownEvents() {
      this.$on('keydown.down', this.onKeydownArrowDown);
      this.$on('keydown.up', this.onKeydownArrowUp);
      this.$on('keydown.enter', this.onKeydownEnter);
    },
    setupTreeStructure() {
      if (this.isTree && hasConstructor('MutationObserver')) {
        this.buildNodeTree();

        this.observer = new MutationObserver(this.buildNodeTree);
        this.observer.observe(this.$el, {
          childList: true,
          subtree: true,
        });
      }
    },
    buildNodeTree() {
      const opts = this.getSlotOptionsAsTree();
      this.buildNodeStyles(opts);
    },
    buildNodeStyles(vnodeList) {
      vnodeList.forEach(vnode => {
        this.addParentClassToOption(vnode);
        this.buildNodeStyles(vnode.nodeList);
      });
    },
    getParentClassName(vnode, level = 1) {
      if (vnode?.parentNode) {
        return this.getParentClassName(vnode.parentNode, level + 1);
      }
      return `ds-option-level--${level}`;
    },
    addParentClassToOption(vnode) {
      if (vnode.elm) {
        const className = this.getParentClassName(vnode);
        if (!vnode.elm.classList.contains(className)) {
          vnode.elm.classList.add(className);
        }
      }
    },
    notifyHoveredOption(event, payload) {
      const vm = this.getHoveredOptionVm();

      if (vm) {
        vm.$emit(event, payload);
      }
    },
    getHoveredOptionVm() {
      return this.getOptionVmByKey(this.currentHoveredOptionKey);
    },
    onKeydownArrowDown() {
      if (this.shouldShow) {
        this.hoverOptionByDirection('next');
      } else {
        this.selectOptionByDirection('next');
      }
    },
    onKeydownArrowUp() {
      if (this.shouldShow) {
        this.hoverOptionByDirection('prev');
      } else {
        this.selectOptionByDirection('prev');
      }
    },
    onKeydownEnter() {
      this.$nextTick(() => {
        if (this.shouldShow) {
          this.notifyHoveredOption('keydown.enter');
        }
      });
    },
    createPopper() {
      return createPopper(this.$parent.$el, this.$el, {
        placement: 'bottom-start',
        strategy: 'fixed',
      });
    },
    destroyPopper() {
      if (this.popper) {
        this.popper.destroy();
        this.popper = null;
      }
    },
    async open() {
      this.setShouldShow(true);
      await this.$nextTick();

      this.popper = this.createPopper();
    },
    updatePopper() {
      if (this.popper) {
        this.popper.update();
      }
    },
    close() {
      this.destroyPopper();
      this.setShouldShow(false);
      this.$nextTick(() => {
        this.updateSlotOptions();
      });
    },
    selectOptionByKeyboard(optionKey) {
      const optionVm = this.getOptionVmByKey(optionKey);

      if (optionVm) {
        optionVm.select();
      }
    },
    hoverOptionByKeyboard(optionKey) {
      const optionVm = this.getOptionVmByKey(optionKey);

      if (optionVm) {
        optionVm.hover();
        this.currentHoveredOptionKey = optionKey;
      }
    },
    addOption(option) {
      this.options[option.key] = option;
      this.$emit('option-added', option);
      this.$nextTick(() => {
        this.handleOptionListeners(option.vm);
        this.updateSlotOptions();
      });
    },
    removeOption(option) {
      delete this.options[option.key];
      this.updateSlotOptions();
    },
    handleOptionListeners(optionVm) {
      const listener = this.$listeners['option-selected'];
      if (listener) {
        optionVm.$on('option-selected', listener);
      }
    },
    updateSlotOptions() {
      this.slotOptions = this.getSlotOptions();
    },
    getOptionVmByKey(optionKey) {
      return getOptionVmByKey(this.options, optionKey);
    },
    getOptionVmByValue(value) {
      return getOptionVmByValue(this.options, value, this.compareValueBy);
    },
    selectOptionByValue(value) {
      if (value === null) {
        this.$emit('option-selected', { label: '', value: null });
      } else if (!isOptionEqual(this.selectedOption, value, this.compareValueBy)) {
        this.$nextTick(() => {
          const optionVm = this.getOptionVmByValue(value);

          if (optionVm) {
            optionVm.select();
          }
        });
      }
    },
    getNewOptionKey(direction, key) {
      const getNewOption = direction === 'next' ? getNextOption : getPreviousOption;
      return getNewOption(this.slotOptions, this.options, key);
    },
    hoverOptionByDirection(direction) {
      const newOptionKey = this.getNewOptionKey(direction, this.currentHoveredOptionKey);
      this.handleScroll(newOptionKey);
      this.hoverOptionByKeyboard(newOptionKey);
    },
    selectOptionByDirection(direction) {
      const newOptionKey = this.getNewOptionKey(direction, this.currentOptionKey);
      this.handleScroll(newOptionKey);
      this.selectOptionByKeyboard(newOptionKey);
    },
    handleScroll(optionKey) {
      this.$nextTick(() => {
        if (this.getOptionsVms().length > 1) {
          handleScroll({
            optionsElement: getScrollableOptionsElement(this.$el),
            optionElement: this.getOptionVmByKey(optionKey).$el,
          });
        }
      });
    },
    setShouldShow(value) {
      this.shouldShow = value;
    },
    getOptionsVms() {
      return Object.values(this.options).map(option => option.vm);
    },
  },
};
</script>
