import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';

import { isComponent, isValidComponent } from '@core/services/vnode/vnodeService';
import { debug } from '@core/services/debug/debugService';

function areArraysValuesValidObjects(models, compareValueBy) {
  return compareValueBy && models.every(model => !!model[compareValueBy]);
}

function areAllArrayObjectsValuesCorrespondents(a, b, compareValueBy) {
  return (
    a.every(aItem => b.some(bItem => areObjectModelCorrespondents(aItem, bItem, compareValueBy))) &&
    b.every(bItem => a.some(aItem => areObjectModelCorrespondents(aItem, bItem, compareValueBy)))
  );
}

function areArraysEquivalents(a, b, compareValueBy) {
  if (areArraysValuesValidObjects(a, compareValueBy) && areArraysValuesValidObjects(b, compareValueBy)) {
    return areAllArrayObjectsValuesCorrespondents(a, b, compareValueBy);
  }

  return isEqual(sortBy(a), sortBy(b));
}

function areObjectModelCorrespondents(a, b, compareValueBy) {
  return get(a, compareValueBy) === get(b, compareValueBy);
}

function areOptionsArrays(a, b) {
  return Array.isArray(a) && Array.isArray(b);
}

function isAllowedComponent(currentComponent, allowedComponents) {
  return allowedComponents.some(allowedComponentLocal => isComponent(currentComponent, allowedComponentLocal));
}

function isValidSlot(components, allowedComponents, errorMessages) {
  const isValid = components.every(component => isAllowedComponent(component, allowedComponents));

  if (!isValid) {
    debug.error(errorMessages);
    return false;
  }

  return isValid;
}

export function areOptionsEqualByRules({ a, b, compareValueBy }) {
  if (areOptionsArrays(a, b)) {
    return areArraysEquivalents(a, b, compareValueBy);
  }

  if (compareValueBy) {
    return areObjectModelCorrespondents(a, b, compareValueBy);
  }

  return a === b;
}

export function isEmptyValue(value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  }

  return !value;
}

export function getSlotOptions(vnodes = []) {
  return vnodes.filter(vnode => isComponent(vnode, 'DsOption'));
}

export function getSlotOptionsAsTree(vnodes = []) {
  const slotOpts = getSlotOptions(vnodes);

  const hashTable = {};
  const treeSlotOpts = [];

  for (let i = 0, len = slotOpts.length; i < len; i++) {
    hashTable[slotOpts[i].key] = slotOpts[i];
    hashTable[slotOpts[i].key].nodeList = [];
  }

  Object.keys(hashTable).forEach(key => {
    const vnode = hashTable[key];
    const { parent } = vnode.data.attrs;

    if (parent) {
      vnode.parentNode = hashTable[parent];
      hashTable[parent].nodeList.push(vnode);
    } else {
      treeSlotOpts.push(vnode);
    }
  });

  return treeSlotOpts;
}

export function shouldRenderPrependSlot(prependSlot) {
  const ALLOWED_COMPONENTS = ['DsLogos', 'DsIcon'];
  const ERROR_MESSAGE = 'prepend slot must contain a DsIcon or DsLogo component';
  const validComponents = prependSlot?.filter(isValidComponent);
  return validComponents?.length && isValidSlot(validComponents, ALLOWED_COMPONENTS, ERROR_MESSAGE);
}

export function shouldRenderAppendSlot(appendSlot) {
  const ALLOWED_COMPONENTS = ['DsLogos', 'DsIcon', 'DsBadge'];
  const ERROR_MESSAGE = 'append slot must contain a DsIcon, DsLogo or DsBadge component';
  const validComponents = appendSlot?.filter(isValidComponent);
  return validComponents?.length && isValidSlot(validComponents, ALLOWED_COMPONENTS, ERROR_MESSAGE);
}
