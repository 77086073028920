import { library } from '@fortawesome/fontawesome-svg-core';
import { OVERRIDE_ICONS_FILE_NAME } from './iconOverrides';

const PREFIXES = {
  solid: 'fas',
  regular: 'far',
  light: 'fal',
  duotone: 'fad',
  brands: 'fab',
};

const SIZES = {
  sm: 'sm',
  md: null,
  lg: 'lg',
  xl: '2x',
  xxl: '2x',
};

export function getSize(size) {
  return SIZES[size];
}

export function getAvailableIcons() {
  return Object.keys(library.definitions).reduce((acc, cur) => Object.keys(library.definitions[cur]).concat(acc), []);
}

function getPrefix(type) {
  const prefix = type ? PREFIXES[type] : 'fas';

  if (!prefix) {
    console.error(`The informed type "${type}" is invalid.`);
  }

  return prefix;
}

export function getPrefixedIcon(name, type) {
  const prefix = getPrefix(type);
  return prefix ? [prefix, name] : [];
}

export function isValidIcon(name, type) {
  const prefix = getPrefix(type);
  return prefix ? !!library.definitions[prefix][name] : false;
}

export function isIconToOverride(name) {
  return Object.keys(OVERRIDE_ICONS_FILE_NAME).includes(name);
}

export function getOverrideIconFile(name) {
  return OVERRIDE_ICONS_FILE_NAME[name];
}
