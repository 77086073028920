<template>
  <p class="ds-widget-text" :class="classes">
    <slot />
  </p>
</template>

<script>
import { debug } from '@core';

export default {
  name: 'DsWidgetText',
  inject: {
    widgetGroupVm: {
      default: null,
    },
  },
  props: {
    align: {
      type: String,
      default: 'left',
      validator(align) {
        return ['left', 'center', 'right'].includes(align);
      },
    },
    color: {
      type: String,
      default: 'text',
      validator(color) {
        return ['text', 'blue', 'green-semi-dark', 'red-semi-dark'].includes(color);
      },
    },
    size: {
      type: String,
      default: 'md',
      validator(size) {
        return ['sm', 'md', 'lg'].includes(size);
      },
    },
    weight: {
      type: String,
      default: 'regular',
      validator(weight) {
        return ['regular', 'medium'].includes(weight);
      },
    },
  },
  computed: {
    classes() {
      return {
        [`ds-widget-text--${this.size}`]: this.size,
        [`ds-u-text-align--${this.align}`]: this.align,
        [`ds-u-font-weight--${this.weight}`]: this.weight,
        [`ds-u-color--${this.color}`]: this.color,
      };
    },
  },
  created() {
    if (!this.widgetGroupVm) {
      debug.error('DsWidgetText must be wrapped with DsWidgetGroup');
    }
  },
};
</script>

<style scoped>
@import './WidgetText.css';
</style>
