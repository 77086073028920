<template>
  <ds-dropdown v-if="actions" class="ds-data-grid-action" :title="label" size="sm">
    <ds-dropdown-item
      v-for="action in allActions"
      :key="action.label"
      :disabled="action.disabled"
      @click="action.action">
      {{ action.label }}
    </ds-dropdown-item>
  </ds-dropdown>
  <ds-button v-else-if="allActions.length === 1" class="ds-data-grid-action" size="sm" v-on="$listeners">
    {{ allActions[0].label }}
  </ds-button>
</template>

<script>
import { debug } from '@core';
import DsButton from '@components/button';
import DsDropdown from '@components/dropdown';
import DsDropdownItem from '@components/dropdown-item';

const PRESETS = {
  print: {
    label: 'Imprimir',
  },
  export: {
    label: 'Exportar',
  },
};

export default {
  name: 'DsDataGridAction',
  components: {
    DsButton,
    DsDropdown,
    DsDropdownItem,
  },
  props: {
    preset: {
      type: String,
      validator(preset) {
        return Object.keys(PRESETS).includes(preset);
      },
    },
    label: {
      type: String,
    },
    actions: {
      type: Array,
    },
  },
  computed: {
    customActions() {
      return this.actions || [{ label: this.label }];
    },
    allActions() {
      return this.preset ? [getPresetByName(this.preset)] : this.customActions;
    },
  },
  created() {
    if (this.customActions) {
      validateActions(this.customActions);
    }
  },
};

function getPresetByName(name) {
  return PRESETS[name];
}

function getPresetByLabel(label) {
  const [name, preset] = Object.entries(PRESETS).find(([, value]) => value.label === label) || [];

  return name ? { name, ...preset } : null;
}

function validateActions(actions) {
  actions.forEach(action => {
    const preset = getPresetByLabel(action.label);

    if (preset) {
      debug.error(
        `Already there is a preset with label "${preset.label}". Please use preset="${preset.name}" instead a custom action.`,
      );
    }
  });
}
</script>

<style scoped>
@import './DataGridAction.css';
</style>
