<template>
  <div v-if="shouldShowBanner" class="ds-tc-dashboard-banner" :class="[classSize, bannerClass]" @click="onBannerClick">
    <div :class="backgroundClasses" :style="backgroundStyle"></div>
    <div class="ds-tc-dashboard-banner--content">
      <div>
        <ds-p weight="bold" class="ds-tc-dashboard-banner--heading" :size="headingSize">
          {{ title }}
          <ds-badge v-if="shouldShowTitleBadge" class="ds-u-margin-left--sm" :theme="bannerConfig.badge.title.theme">
            {{ bannerConfig.badge.title.text }}
          </ds-badge>
        </ds-p>
        <ds-p v-if="bannerConfig.text" class="ds-tc-dashboard-banner--description" size="xxs">
          {{ description }}
          <span class="ds-u-margin-right--sm"></span>
          <ds-badge v-if="shouldShowDescriptionBadge" :theme="bannerConfig.badge.description.theme">
            {{ bannerConfig.badge.description.text }}
          </ds-badge>
        </ds-p>
        <ds-link v-if="bannerConfig.textLink">
          {{ bannerConfig.textLink }}
        </ds-link>
      </div>
    </div>
    <ds-illustration class="ds-tc-dashboard-banner__image" :name="illustration" />
    <ds-close-button
      class="ds-tc-dashboard-banner__close-button"
      :variant="closeButtonVariant"
      @click.stop="onCloseButtonClick" />
  </div>
</template>

<script>
import DsP from '@components/paragraph';
import DsLink from '@components/link';
import DsCloseButton from '@components/close-button';
import DsIllustration from '@components/illustration';
import DsBadge from '@components/badge';
import { BANNER_CONFIG, TYPE, SIZE } from './TcDashboardBannerConstants';

export default {
  name: 'DsTcDashboardBanner',
  components: {
    DsP,
    DsLink,
    DsCloseButton,
    DsIllustration,
    DsBadge,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: SIZE.SM,
    },
    customData: {
      type: Object,
    },
  },
  data() {
    return {
      shouldShow: true,
      bannerConfig: null,
    };
  },
  computed: {
    classSize() {
      return `ds-tc-dashboard-banner--${this.size} ${this.bannerClass}--${this.size}`;
    },
    backgroundClasses() {
      const classWithSufix = `ds-tc-dashboard-banner__background--${this.bannerConfig?.styleSuffix}`;
      return ['ds-tc-dashboard-banner__background', classWithSufix];
    },
    headingSize() {
      return this.size === SIZE.SM ? SIZE.XS : SIZE.SM;
    },
    bannerClass() {
      return `ds-tc-dashboard-banner--${this.bannerConfig?.styleSuffix}`;
    },
    backgroundStyle() {
      return {
        'background-color': `var(${this.bannerConfig?.color?.background})`,
        opacity: this.bannerConfig?.color?.opacity,
      };
    },
    closeButtonVariant() {
      return this.bannerConfig?.closeButtonTheme;
    },
    shouldShowBanner() {
      return this.shouldShow && !!this.bannerConfig;
    },
    shouldShowTitleBadge() {
      return this.bannerConfig.badge?.title;
    },
    shouldShowDescriptionBadge() {
      return this.bannerConfig.badge?.description;
    },
    title() {
      return this.getBySize('titleSize', 'title');
    },
    description() {
      return this.getBySize('textSize', 'text');
    },
    illustration() {
      return this.getBySize('illustrationSize', 'illustration');
    },
  },
  created() {
    const bannerType = TYPE[this.type];
    this.bannerConfig = this.customData || BANNER_CONFIG[bannerType] || {};
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close');
      this.setBannerVisibility(false);
    },
    onBannerClick() {
      this.$emit('click');
    },
    setBannerVisibility(shouldShow) {
      this.shouldShow = shouldShow;
    },
    getBySize(elSize, elDefault) {
      const sizeMap = {
        [SIZE.SM]: 'sm',
        [SIZE.MD]: 'md',
        [SIZE.LG]: 'lg',
      };
      const sizeKey = sizeMap[this.size];
      return (this.bannerConfig[elSize] && this.bannerConfig[elSize][sizeKey]) || this.bannerConfig[elDefault];
    },
  },
};
</script>

<style scoped>
@import './TcDashboardBanner.css';
</style>
