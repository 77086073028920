import Uppy from '@uppy/core';
import DragDrop from '@uppy/drag-drop';
import XHRUpload from '@uppy/xhr-upload';
import ptBR from '@uppy/locales/lib/pt_BR';

export function createFileUpload({ el, endpoint, headers, options }) {
  const uppy = new Uppy({
    autoProceed: true,
    locale: ptBR,
    restrictions: options,
  });

  uppy.use(DragDrop, { target: el }).use(XHRUpload, { endpoint, headers });

  function onFileUploaded(handler) {
    uppy.on('upload-success', file => handler(file));
  }

  function onFileRemoved(handler) {
    uppy.on('file-removed', file => handler(file));
  }

  function onFileAdded(handler) {
    uppy.on('file-added', file => handler(file));
  }

  function onUploadProgress(handler) {
    uppy.on('upload-progress', (file, progress) => {
      const updatedFile = uppy.getFile(file.id);
      const percentage = Math.floor((progress.bytesUploaded / progress.bytesTotal) * 100);
      handler(updatedFile, { ...progress, percentage });
    });
  }

  function onUploadError(handler) {
    uppy.on('upload-error', (file, error, response) => {
      handler(file, error, response);
    });
  }

  function onUploadRetry(handler) {
    uppy.on('upload-retry', fileId => handler(fileId));
  }

  function onRestrictionFailed(handler) {
    uppy.on('restriction-failed', (file, error) => handler(file, error));
  }

  return {
    destroy: () => uppy.close(),
    addFiles: files => uppy.addFiles(files),
    removeFile: fileId => uppy.removeFile(fileId),
    retryFile: fileId => uppy.retryUpload(fileId),
    onFileUploaded,
    onFileRemoved,
    onFileAdded,
    onUploadProgress,
    onUploadError,
    onUploadRetry,
    onRestrictionFailed,
    setTargetEl: elem => {
      const instance = uppy.getPlugin('DragDrop');
      uppy.removePlugin(instance);
      uppy.use(DragDrop, { target: elem });
    },
  };
}
