<template>
  <div class="ds-radio" :class="classes">
    <input
      :id="id"
      :name="name"
      :value="value"
      :checked="checked"
      :disabled="isDisabled"
      :tabindex="tabindex"
      type="radio"
      class="ds-radio__input"
      @change="onChange" />
    <span class="ds-radio__button"></span>
    <label class="ds-radio__label" :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
import { generateId } from '@core/services/id/idService';

export default {
  name: 'DsRadio',
  inject: {
    radioGroupVm: {
      default: () => ({ onChange: () => {}, onClick: () => {} }),
    },
  },
  props: {
    value: [Number, String, Boolean],
    disabled: Boolean,
    tabindex: [String, Number],
  },
  data() {
    return {
      id: generateId(),
      name: this.radioGroupVm.id,
      hasLabel: false,
    };
  },
  computed: {
    checked() {
      return this.radioGroupVm.valueModel === this.value;
    },
    isDisabled() {
      return this.disabled || this.radioGroupVm.disabled;
    },
    classes() {
      return {
        'ds-radio--no-label': !this.hasLabel,
        'ds-radio--disabled': this.isDisabled,
      };
    },
  },
  created() {
    this.hasLabel = !!this.$slots.default?.length;
  },
  methods: {
    onChange() {
      this.radioGroupVm.onClick(this.value);
      this.$emit('click', this.value);

      // TO DO: 'change' event is being wrongly triggered as 'click'
      // https://contaazul.atlassian.net/browse/DX-796
      this.radioGroupVm.onChange(this.value);
      this.$emit('change', this.value);
    },
  },
};
</script>

<style scoped>
@import './Radio.css';
</style>
