<template>
  <span class="ds-time-input">
    <ds-input
      ref="input"
      v-model="localValue"
      mask-pattern="99:99"
      :disabled="disabled"
      :required="required"
      :custom-validations="[timeValidations, ...customValidations]"
      @click="handleSelection"
      @focus="onFocus"
      @blur="onBlur"
      v-on="listeners">
    </ds-input>
  </span>
</template>

<script>
import { focusMixin } from '@core';
import DsInput from '@components/input';
import INPUT_ERRORS from '@core/constants/inputErrors';
import {
  isValid,
  listenActionArrowsKeydown,
  getIncrementedTime,
  TIME_LENGTH,
  getUnitOffset,
  listenSideArrowsKeydown,
  getTimeUnit,
  unsubscribeKeydownEvents,
} from './timeInputService';

export default {
  name: 'DsTimeInput',
  components: {
    DsInput,
  },
  mixins: [focusMixin.focus('input')],
  props: {
    required: Boolean,
    disabled: Boolean,
    value: String,
    customValidations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localValue: this.value,
      timeValidations: {
        message: INPUT_ERRORS.INVALID_TIME.MESSAGE,
        valid: isValid,
      },
      selectedUnit: 'm',
      selectionStart: TIME_LENGTH.minutes.start,
      selectionEnd: TIME_LENGTH.minutes.end,
      keydownEventIds: {},
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: () => {},
      };
    },
  },
  watch: {
    localValue(value) {
      this.$emit('input', value);
    },
    value(value) {
      this.localValue = value;
    },
  },
  destroyed() {
    this.unsubscribeKeydownEvents();
  },
  methods: {
    onFocus() {
      this.initEventSubscribers();
    },
    initEventSubscribers() {
      this.listenActionArrowsKeydown();
      this.listenSideArrowsKeydown();
    },
    listenActionArrowsKeydown() {
      const { upArrowId, downArrowId } = listenActionArrowsKeydown(this.handleArrowActions);

      this.setEscapeKeydownEventId(upArrowId);
      this.setEscapeKeydownEventId(downArrowId);
    },
    listenSideArrowsKeydown() {
      const { leftArrowId, rightArrowId } = listenSideArrowsKeydown(this.handleSelection);

      this.setEscapeKeydownEventId(leftArrowId);
      this.setEscapeKeydownEventId(rightArrowId);
    },
    setEscapeKeydownEventId(id) {
      this.keydownEventIds[id] = id;
    },
    handleSelection() {
      const { selectionStart } = document.activeElement;
      const unit = getTimeUnit(selectionStart);
      const unitOffset = getUnitOffset(unit);

      this.selectedUnit = unit;
      this.selectionStart = unitOffset.selectionStart;
      this.selectionEnd = unitOffset.selectionEnd;
    },
    async handleArrowActions(event, action) {
      event.preventDefault();

      this.localValue = this.localValue ? getIncrementedTime(this.localValue, action, this.selectedUnit) : '00:00';

      await this.$nextTick();
      this.setSelectionRange();
    },
    setSelectionRange() {
      this.$refs.input.$el.querySelector('input').focus();
      this.$refs.input.$el.querySelector('input').setSelectionRange(this.selectionStart, this.selectionEnd);
    },
    onBlur() {
      this.unsubscribeKeydownEvents();
      this.clearKeydownEvents();
    },
    unsubscribeKeydownEvents() {
      unsubscribeKeydownEvents(this.keydownEventIds);
    },
    clearKeydownEvents() {
      this.keydownEventIds = {};
    },
  },
};
</script>

<style scoped>
@import './TimeInput.css';
</style>
