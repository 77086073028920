<template>
  <div v-if="wizardStore" class="ds-wizard-step" :class="{ 'ds-wizard-step--is-required': required }">
    <ds-collapse :opened.sync="isOpen" :title="title">
      <template #title-icon>
        <ds-icon class="ds-u-margin-right--sm" icon="check-circle" :color="localCompleted ? 'green' : 'grey-light'" />
      </template>
      <template #custom-description>
        <ds-wizard-step-description :completed="localCompleted" :description="description" @edit="isOpen = true">
          <slot name="custom-description" />
        </ds-wizard-step-description>
      </template>
      <template #default>
        <slot :response="response" :error="error" />
        <ds-footer v-if="!hideFooter" class="ds-wizard-step__footer">
          <slot name="footer">
            <ds-request-button
              :disabled="!isReady"
              theme="primary"
              :request-action="beforeNext"
              @request-success="next"
              @request-error="onBeforeNextError">
              Continuar
            </ds-request-button>
          </slot>
        </ds-footer>
      </template>
    </ds-collapse>
  </div>
</template>

<script>
import { debug } from '@core';
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import DsRequestButton from '@components/request-button';
import DsIcon from '@components/icon';
import DsFooter from '@components/footer';
import DsCollapse from '@components/collapse';
import DsWizardStepDescription from './WizardStepDescription.vue';

/**
 * With this component, some step validations should be made manually.
 * For form use cases, please see [ds-wizard-form-step](/docs/wizard-wizard-form-step).
 */
export default {
  name: 'DsWizardStep',
  inject: {
    wizardStore: {
      default: null,
    },
  },
  components: {
    DsWizardStepDescription,
    DsCollapse,
    DsFooter,
    DsRequestButton,
    DsIcon,
  },
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
    },
    ready: {
      type: Boolean,
      default: undefined,
    },
    completed: {
      type: Boolean,
      default: undefined,
    },
    hideFooter: {
      type: Boolean,
    },
  },
  data() {
    return {
      $_footerClass: 'ds-wizard-step-footer',
      response: null,
      error: null,
      localCompleted: null,
    };
  },
  computed: {
    isOpen: {
      get() {
        return this.wizardStore.isOpen(this.key);
      },
      set(isOpen) {
        if (isOpen) {
          this.wizardStore.setCurrentStep(this.key);
        } else {
          this.wizardStore.closeAll();
        }
      },
    },
    key() {
      return this.$vnode.key;
    },
    isReady() {
      return this.required ? this.ready : this.ready == null || this.ready;
    },
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.$vnode.key == null) {
      debug.error(new Error('You must inform a key to ds-wizard-step'));
    }

    if (this.description) {
      deprecatedDependency.deprecateProperty('description');
    }
    if (this.$slots['custom-description']) {
      deprecatedDependency.deprecateSlot('custom-description');
    }
  },
  mounted() {
    this.wizardStore.addStep(this.key);
    this.$watch('completed', this.setCompleted, { immediate: true });
  },
  beforeDestroy() {
    this.wizardStore.removeStep(this.key);
  },
  methods: {
    setCompleted(completed) {
      this.localCompleted = completed;
      this.wizardStore.onStepCompleted(this.key, completed);
      this.$emit('udpate:completed', completed);
    },
    beforeNext() {
      return new Promise((resolve, reject) => {
        if (this.$listeners['before-next']) {
          this.$emit('before-next', { resolve, reject });
        } else {
          resolve();
        }
      });
    },
    next(response) {
      this.response = response;
      this.wizardStore.next(this.key);
      this.setCompleted(true);
      this.$emit('next');
    },
    onBeforeNextError(error) {
      this.error = error;
    },
  },
};
</script>

<style scoped>
@import './WizardStep.css';
</style>
