<template>
  <span :class="classes" class="ds-loader-button">
    <component
      :is="buttonIs"
      class="ds-loader-button__button"
      v-bind="$attrs"
      :disabled="isButtonDisabled"
      :type="type"
      :theme="theme"
      :size="size"
      :full-width="fullWidth"
      :icon="icon"
      v-on="$listeners">
      <div v-if="!isIconOnly" class="ds-loader-button__content">
        <slot />
      </div>
      <ds-loader v-if="isLoading" class="ds-loader-button__loader" theme="inside" />
    </component>
  </span>
</template>

<script>
import DsLoader from '@components/loader';
import DsBetaButton from '../beta-button/BetaButton.vue';

export default {
  name: 'DsBetaButtonLoader',
  provide() {
    return {
      isIconOnly: this.isIconOnly,
    };
  },
  components: {
    DsBetaButton,
    DsLoader,
  },
  props: {
    icon: DsBetaButton.props.icon,
    fullWidth: DsBetaButton.props.fullWidth,
    size: DsBetaButton.props.size,
    buttonIs: {
      type: [Object, String],
      default: 'ds-beta-button',
    },
    disabled: {
      type: Boolean,
    },
    theme: {
      type: String,
    },
    type: {
      type: String,
      default: 'button',
    },
    isLoading: {
      type: Boolean,
    },
  },
  computed: {
    isIconOnly() {
      return this.icon && !this.$slots.default?.length;
    },
    isButtonDisabled() {
      return this.disabled || this.isLoading;
    },
    classes() {
      return { 'is-loading': this.isLoading };
    },
  },
};
</script>

<style scoped>
@import './BetaLoaderButton.css';
</style>
