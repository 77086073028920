export function buildValidator({ validValues, errorMessage }) {
  return value => {
    const isValid = validValues.includes(value);

    if (!isValid) {
      console.error(`${errorMessage}: ${validValues.join(', ')}`);
    }

    return isValid;
  };
}
