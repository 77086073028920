<template>
  <ds-dropdown-item v-bind="$attrs" v-on="$listeners">
    <slot />
  </ds-dropdown-item>
</template>

<script>
import DsDropdownItem from '@components/dropdown-item';

export default {
  name: 'DsWidgetActionItem',
  components: {
    DsDropdownItem,
  },
};
</script>
