import Vue from 'vue';
import DsIcon from '@components/icon';

function createIcon(name, callback = () => {}) {
  const Ctor = Vue.extend({
    render(h) {
      return (
        <span class="ds-collapse-icon-container" onClick={callback}>
          <DsIcon icon={name} />
        </span>
      );
    },
  });

  return new Ctor().$mount().$el;
}

const getIconElement = (collapsed, callback) => {
  const direction = collapsed ? 'right' : 'down';
  return createIcon(`chevron-${direction}`, callback);
};

export function handleChevronIcon(firstColElement, collapsed, callback) {
  if (firstColElement) {
    const oldIcon = firstColElement.querySelector('.ds-collapse-icon-container');
    const newIcon = getIconElement(collapsed, callback);

    if (oldIcon) {
      oldIcon.replaceWith(newIcon);
    } else {
      firstColElement.insertBefore(newIcon, firstColElement.firstChild);
    }
  }
}
