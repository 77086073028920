<template>
  <div
    ref="rollover"
    role="dialog"
    aria-modal="true"
    aria-labelledby="rollover-heading"
    class="ds-rollover"
    :class="classes">
    <div tabindex="0" aria-hidden="true" class="ds-rollover-focus"></div>
    <div class="ds-rollover-container">
      <ds-rollover-header
        :title="title"
        :subtitle="subtitle"
        :size="size"
        :has-size="hasSize"
        :allow-close="allowClose"
        @close="close">
        <slot slot="title" name="title" />
        <template #badge>
          <slot name="badge" />
        </template>
        <slot slot="feedback" name="feedback" />
        <slot slot="help" name="help" />
      </ds-rollover-header>
      <ds-rollover-body ref="rolloverBody" :ds-container-element="hasSize" :class="bodyClasses" @scroll="onScroll">
        <slot></slot>
      </ds-rollover-body>
      <ds-rollover-footer v-if="hasFooter">
        <slot name="footer" />
        <slot slot="left" name="footer-left" />
        <slot slot="right" name="footer-right" />
      </ds-rollover-footer>
    </div>
    <div v-if="hasSize" class="ds-rollover-overlay" @click="close">&nbsp;</div>
  </div>
</template>

<script>
import { debug, initialSetupService, rolloverService, miniRolloverService } from '@core';
import { getRolloverConstants } from '@core/services/rollover/rolloverService';
import DsRolloverHeader from './RolloverHeader.vue';
import DsRolloverBody from './RolloverBody.vue';
import DsRolloverFooter from './RolloverFooter.vue';

const ROLLOVER_ANIMATION = getRolloverConstants('ROLLOVER_ANIMATION');

const {
  onOpenRollover,
  onCloseRollover,
  processHookGroup,
  setupOpeningHooks,
  setupClosingHooks,
  isCurrentRollover,
  hasOpenModal,
} = rolloverService;

const DEFAULT_SIZE = 'full';

export default {
  name: 'DsRollover',
  provide() {
    return {
      rolloverVm: this,
      setHasDsFooter: this.setHasDsFooter,
    };
  },
  components: {
    DsRolloverHeader,
    DsRolloverBody,
    DsRolloverFooter,
  },
  props: {
    /**
     * A promise that resolves true (close) or false (not close)
     */
    beforeCloseAction: {
      type: Function,
    },
    title: String,
    subtitle: String,
    size: {
      type: String,
      default: DEFAULT_SIZE,
      validator(size) {
        return [DEFAULT_SIZE, 'sm', 'md'].includes(size);
      },
    },
    allowClose: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      $_footerClass: 'ds-rollover-footer',
      dsFooters: {},
      hasSomeMiniRolloverOpened: false,
    };
  },
  computed: {
    hasDsFooter() {
      return Object.getOwnPropertySymbols(this.dsFooters)?.length;
    },
    bodyClasses() {
      return { 'has-footer': this.hasFooter || this.hasDsFooter };
    },
    hasFooter() {
      return this.$slots.footer || this.$slots['footer-left'] || this.$slots['footer-right'];
    },
    classes() {
      return {
        'ds-rollover--extra-margin-bottom': this.hasSomeMiniRolloverOpened,
        'ds-rollover--sized': this.hasSize,
        [`ds-rollover--size-${this.size}`]: !!this.size,
      };
    },
    hasSize() {
      return this.size !== DEFAULT_SIZE;
    },
  },
  mounted() {
    setupOpeningHooks(this.getHooksParameters());

    this.validateDeprecatedFooter();
    this.checkIfHasSomeMiniRolloverOpened();

    window.addEventListener('keyup', this.onKeyup);
    window.addEventListener('popstate', this.onPopstate);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyup);
    window.removeEventListener('popstate', this.onPopstate);
    setupClosingHooks(this.getHooksParameters());
  },
  methods: {
    open() {
      this.runBeforeOpenRolloverHook();
      onOpenRollover(this.$el);

      setTimeout(() => {
        this.$emit('open');
      }, ROLLOVER_ANIMATION.MEDIUM);
    },
    async close() {
      let shouldClose = true;

      if (this.beforeCloseAction) {
        shouldClose = await this.beforeCloseAction();
      }

      if (shouldClose === true) {
        onCloseRollover(this.$el);
        this.$emit('close');
      }
    },
    setHasDsFooter() {
      const key = Symbol('ds-footer');
      this.$set(this.dsFooters, key, true);
      return () => this.$delete(this.dsFooters, key);
    },
    runBeforeOpenRolloverHook() {
      this.$emit('before-open');
      processHookGroup(this.getGlobalHooks(), 'onBeforeOpenRollover', this);
    },
    getLocalHook() {
      return {
        onAllRolloversClosed: this.$listeners['all-rollovers-closed'],
      };
    },
    getGlobalHooks() {
      return initialSetupService.getInitialSetup('rollover')?.globalHooks;
    },
    validateDeprecatedFooter() {
      if (this.$slots['footer-left'] || this.$slots['footer-right']) {
        debug.error('The footer-left and footer-right slots will be deprecated soon. Use ds-footer component instead');
      }
    },
    checkIfHasSomeMiniRolloverOpened() {
      this.hasSomeMiniRolloverOpened = miniRolloverService.hasSomeMiniRolloverOpened();
    },
    getHooksParameters() {
      return {
        vueComponent: this,
        el: this.$el,
        globalHooks: this.getGlobalHooks(),
        localHooks: this.getLocalHook(),
      };
    },
    onScroll(e) {
      this.$emit('scrolled', e);
    },
    onKeyup(e) {
      if (e.key === 'Escape' && isCurrentRollover(this.$el) && !hasOpenModal() && this.allowClose) {
        this.close();
      }
    },
    onPopstate() {
      if (isCurrentRollover(this.$el)) {
        this.close();
      }
    },
  },
};
</script>

<style>
@import './Rollover.css';
</style>
