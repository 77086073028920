<template>
  <section class="ds-accordion-item">
    <div class="ds-accordion-item__header" @click="toggleAccordion">
      <ds-icon class="ds-accordion-item__chevron" size="md" :icon="['solid', chevronIcon]" :color="chevronColor" />
      <ds-heading :text="title" size="sm" />
      <ds-badge v-if="badgeText" :theme="badgeTheme">{{ badgeText }}</ds-badge>
      <ds-icon
        v-if="tooltip"
        v-ds-tooltip:250="tooltip"
        :icon="['regular', 'circle-question']"
        size="md"
        color="grey-dark" />
    </div>
    <ds-height-transition :is-opened="isOpened">
      <div class="ds-accordion-item__content">
        <slot v-if="isOpened"></slot>
      </div>
    </ds-height-transition>
  </section>
</template>

<script>
import { debug } from '@core';
import DsTooltip from '@directives/tooltip';
import DsHeading from '@components/heading';
import DsIcon from '@components/icon';
import DsBadge from '@components/badge';
import DsHeightTransition from '@components/heightTransition';

function getColorByTheme(theme) {
  const THEME_COLOR_MAP = {
    primary: 'green',
    secondary: 'blue',
    warning: 'yellow',
    danger: 'red',
  };
  return THEME_COLOR_MAP[theme];
}

export default {
  name: 'DsAccordionItem',
  inject: ['accordionVm'],
  directives: {
    DsTooltip,
  },
  components: {
    DsHeading,
    DsIcon,
    DsHeightTransition,
    DsBadge,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
    },
    badgeTheme: {
      type: String,
    },
    badgeText: {
      type: String,
    },
  },
  computed: {
    chevronIcon() {
      return this.isOpened ? 'chevron-up' : 'chevron-down';
    },
    chevronColor() {
      return getColorByTheme(this.accordionTheme);
    },
    key() {
      return this.$vnode.key;
    },
    accordionTheme() {
      return this.accordionVm.getAccordionTheme();
    },
    openedItem() {
      return this.accordionVm.getOpenedItem();
    },
    isOpened() {
      return this.key === this.openedItem;
    },
  },
  created() {
    if (this.$vnode.key == null) {
      debug.error(new Error('You must inform a key to ds-accordion-item'));
    }
  },
  methods: {
    openItem() {
      this.accordionVm.openItem(this.key);
      this.$emit('open');
    },
    closeItem() {
      this.accordionVm.closeItem();
    },
    toggleAccordion() {
      if (this.isOpened) {
        this.closeItem();
      } else {
        this.openItem();
      }
    },
  },
};
</script>

<style scoped>
@import './AccordionItem.css';
</style>
