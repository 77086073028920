<template>
  <button type="button" :class="classes" class="ds-slimbox ds-slimbox-button" :disabled="disabled" v-on="$listeners">
    <slot>
      <ds-illustration
        v-if="illustrationName"
        :name="illustrationName"
        :alt="illustrationAlt"
        :width="illustrationWidth"
        :height="illustrationHeight" />
      <ds-heading v-if="text" size="sm" class="ds-slimbox-button-text" :text="text" />
      <ds-text v-if="description" color="blue-semi-dark" class="ds-slimbox-button-description">
        {{ description }}
      </ds-text>
    </slot>
  </button>
</template>

<script>
import DsIllustration from '@components/illustration';
import DsHeading from '@components/heading';
import DsText from '@components/text';

export default {
  name: 'DsSlimboxButton',
  components: {
    DsIllustration,
    DsHeading,
    DsText,
  },
  props: {
    illustrationName: String,
    illustrationAlt: String,
    illustrationWidth: String,
    illustrationHeight: String,
    text: String,
    description: String,
    minHeightAuto: Boolean,
    disabled: Boolean,
    active: Boolean,
  },
  computed: {
    classes() {
      return {
        'ds-slimbox-button--min-height-auto': this.minHeightAuto,
        'ds-slimbox-button--disabled': this.disabled,
      };
    },
  },
};
</script>

<style scoped>
@import '../slimbox/Slimbox.css';
@import './SlimboxButton.css';
</style>
