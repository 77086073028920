<template>
  <div class="ds-footer" :class="parentClass">
    <ds-row-divider />
    <div
      class="ds-footer__content"
      :class="{
        'ds-u-display--flex': justify,
        [`ds-u-justify-content--${justify}`]: justify,
      }">
      <slot></slot>
      <div v-if="$slots['content']" class="ds-u-display--flex ds-u-justify-content--flex-end ds-u-width--full">
        <ds-row justify="flex-end">
          <slot name="content" />
        </ds-row>
      </div>
      <slot name="right" />
    </div>
  </div>
</template>

<script>
import DsRowDivider from '@components/row-divider';

export default {
  name: 'DsFooter',
  inject: {
    setHasDsFooter: {
      default: null,
    },
  },
  components: {
    DsRowDivider,
  },
  props: {
    justify: {
      type: String,
      validator(justify) {
        return ['center', 'flex-end', 'space-around', 'space-between'].includes(justify);
      },
    },
  },
  data() {
    return {
      parentClass: null,
    };
  },
  beforeMount() {
    if (this.setHasDsFooter) {
      this.$on('hook:beforeDestroy', this.setHasDsFooter());
    }
  },
  mounted() {
    this.$nextTick(() => this.setParentClass());
  },
  updated() {
    this.$nextTick(() => this.setParentClass());
  },
  methods: {
    setParentClass() {
      this.parentClass = getParentClass(this);
    },
  },
};

function getParentClass(vm) {
  return vm.$parent ? vm.$parent.$data.$_footerClass || getParentClass(vm.$parent) : '';
}
</script>

<style scoped>
@import './Footer.css';
</style>
