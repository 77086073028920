<template>
  <div class="ds-tabs">
    <ul class="ds-tabs__list">
      <ds-tab-nav
        v-for="tab in tabs"
        :key="tab[activeBy]"
        :tab="tab"
        :is-active="tab[activeBy] === activeTab"
        @tab-selected="onTabSelected" />
    </ul>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import { getChildComponentInstance, getChildrenFromSlot } from '@core/services/vnode/vnodeService';

import DsTabNav from './TabNav.vue';

export default {
  name: 'DsTabs',
  provide() {
    return {
      tabsVm: {
        isTabVisible: this.isTabVisible,
        addTab: this.addTab,
        updateTab: this.updateTab,
        tabs: this.tabs,
      },
    };
  },
  components: {
    DsTabNav,
  },
  props: {
    active: [String, Number],
    activeBy: {
      type: String,
      default: 'index',
      validator(activeBy) {
        return ['index', 'key'].includes(activeBy);
      },
    },
    onChange: {
      type: Function,
    },
  },
  data() {
    return {
      tabs: [],
      activeTab: null,
    };
  },
  watch: {
    active: {
      immediate: true,
      handler(active) {
        this.activeTab = active;
      },
    },
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.$listeners['tab-changed']) {
      deprecatedDependency.deprecateProperty('@tab-changed', 'Use @update:active instead');
    }
    if (this.onChange) {
      deprecatedDependency.deprecateProperty('onChange', 'Use @update:active instead');
    }
  },
  methods: {
    getTabByLabel(slot, label) {
      const getVnodeByLabel = vnode => vnode.componentOptions.propsData.label === label;
      return getChildComponentInstance(slot, 'DsTab', getVnodeByLabel);
    },
    addTab(tab) {
      let tabIndex;
      getChildrenFromSlot(this.$slots.default).forEach((slot, index) => {
        if (this.getTabByLabel(slot, tab.label)) {
          tabIndex = index;
          this.$set(this.tabs, index, { index, ...tab });
        }
      });

      return () => this.$delete(this.tabs, tabIndex);
    },
    updateTab(tab) {
      this.$set(this.tabs, tab.index, { ...this.tabs[tab.index], ...tab });
    },
    isTabVisible(index) {
      return this.activeTab === this.tabs[index]?.[this.activeBy] || false;
    },
    onTabSelected(tab) {
      this.activeTab = tab[this.activeBy];
      this.$emit('tab-changed', this.activeTab);

      /**
       * On change tab
       * @property {Number} active
       */
      this.$emit('update:active', this.activeTab);

      if (this.onChange) {
        this.onChange(this.activeTab);
      }
    },
  },
};
</script>

<style scoped>
@import './Tabs.css';
</style>
