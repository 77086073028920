<template>
  <div v-if="shouldShowModal" ds-container-element :class="[mainClass, modalSizeClass]">
    <div class="ds-modal-container">
      <div class="ds-modal__header">
        <h3 class="ds-heading--subtitle ds-u-margin--none">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-if="title" class="ds-u-font-size--inherit" v-html="sanitizedTitle" />
          <slot v-else name="title" />
        </h3>
        <ds-close-button v-if="allowClose" class="ds-modal-close" color="grey-dark" @click="close" />
      </div>

      <div :class="{ 'has-footer': hasFooter }" class="ds-modal__body">
        <slot name="body"></slot>
        <slot></slot>
        <ds-footer v-if="$slots.footer" class="ds-modal__slot-footer">
          <slot name="footer"></slot>
        </ds-footer>
      </div>
    </div>
  </div>
</template>

<script>
import DOMPurify from 'dompurify';
import KEY_CODES from '@core/constants/keyCodes';
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import { MODAL_CLASSES } from '@core/constants/modal';
import DsCloseButton from '@components/close-button';
import DsFooter from '@components/footer';

const { MAIN_CLASS } = MODAL_CLASSES;

export default {
  name: 'DsModal',
  provide() {
    return {
      setHasDsFooter: this.setHasDsFooter,
    };
  },
  components: {
    DsCloseButton,
    DsFooter,
  },
  props: {
    size: {
      type: String,
    },
    title: {
      type: String,
    },
    allowClose: {
      type: Boolean,
      default: true,
    },
    /**
     * DEPRECATED PROP | Use @close event instead
     */
    onClose: {
      type: Function,
    },
  },
  data() {
    return {
      dsFooters: {},
      $_footerClass: 'ds-modal-footer',
      shouldShowModal: true,
      mainClass: MAIN_CLASS,
    };
  },
  computed: {
    modalSizeClass() {
      const validSizes = ['md', 'sm', 'lg'];
      const size = validSizes.includes(this.size) ? this.size : 'md';
      return `ds-modal--${size}`;
    },
    hasFooter() {
      return Object.getOwnPropertySymbols(this.dsFooters)?.length;
    },
    sanitizedTitle() {
      return DOMPurify.sanitize(this.title);
    },
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.$slots.footer) {
      deprecatedDependency.deprecateProperty('$slots.footer', 'Use ds-footer instead');
    }

    if (this.onClose) {
      deprecatedDependency.deprecateProperty('onClose', 'Use @close event instead');
    }
  },
  mounted() {
    window.addEventListener('keyup', this.onKeyup);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onKeyup);
  },
  methods: {
    setHasDsFooter() {
      const key = Symbol('ds-footer');
      this.$set(this.dsFooters, key, true);
      return () => this.$delete(this.dsFooters, key);
    },
    open() {
      this.shouldShowModal = true;
      this.$emit('open');
    },
    close() {
      this.shouldShowModal = false;
      this.$emit('close');
      if (this.onClose) {
        this.onClose();
      }
    },
    onKeyup(event) {
      if (event.keyCode === KEY_CODES.ESCAPE && this.allowClose) {
        this.close();
      }
    },
  },
};
</script>

<style>
@import './Modal.css';
</style>
