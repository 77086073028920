<template>
  <component :is="logos" v-if="logos" class="ds-logos" :class="`ds-logos--${size || 'md'}`" />
</template>

<script>
import { stringService } from '@core';

export default {
  name: 'DsLogos',
  props: {
    name: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
      validator(size) {
        return ['xs', 'sm', 'md', 'lg'].includes(size);
      },
    },
  },
  data() {
    return {
      logos: null,
    };
  },
  watch: {
    name: {
      immediate: true,
      handler(name) {
        fetchComponent(stringService.toPascalCase(name)).then(logos => {
          this.logos = logos;
        });
      },
    },
  },
};

function fetchComponent(componentName) {
  // eslint-disable-next-line prefer-template
  return import(`./logos/${componentName}Logo.vue`)
    .then(logo => logo.default)
    .catch(() => null);
}
</script>

<style scooped>
@import 'Logos.css';
</style>
