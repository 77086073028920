<template>
  <div v-if="shouldShowSlimbox" ds-container-element class="ds-slimbox" :class="classes" v-on="$listeners">
    <ds-close-button
      v-if="allowClose || onCloseCallback"
      class="ds-slimbox__close-button"
      :variant="getVariant"
      @click="onCloseButtonClick" />
    <header v-if="shouldShowHeader" class="ds-slimbox-header">
      <div class="ds-slimbox-header__container">
        <slot name="header">
          <ds-heading truncate size="sm" :text="localTitle" />
        </slot>
      </div>
    </header>

    <div class="ds-slimbox__content" :class="getContentClass">
      <slot />
    </div>

    <ds-button v-if="onButtonClick" full-width @click="onButtonClick()">
      {{ buttonLabel }}
    </ds-button>
    <div v-if="shouldShowLoadMoreButton" class="ds-slimbox__load-more" :class="loadMoreClasses">
      <ds-button
        theme="secondary"
        class="ds-slimbox__load-more-button"
        @click.stop="toggleLoadMore(false)"
        @keydown.space.stop
        >Ver mais</ds-button
      >
    </div>
  </div>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import DsCloseButton from '@components/close-button';
import DsHeading from '@components/heading';
import DsButton from '@components/button';

export default {
  name: 'DsSlimbox',
  provide() {
    return {
      setHasDsFooter: this.setHasDsFooter,
    };
  },
  components: {
    DsCloseButton,
    DsHeading,
    DsButton,
  },
  props: {
    variant: {
      type: String,
      validator(variant) {
        return ['light', 'dark'].includes(variant);
      },
    },
    /**
     * DEPRECATED PROP | Use title prop instead
     */
    header: String,
    title: String,
    /**
     * DEPRECATED PROP | Use slot default instead
     */
    buttonLabel: String,
    /**
     * DEPRECATED PROP | Use slot default instead
     */
    onButtonClick: Function,
    allowClose: Boolean,
    fullHeight: Boolean,
    /**
     * DEPRECATED PROP | Use allow-close prop and close event instead
     */
    onCloseCallback: Function,
    overflowMaxHeight: {
      type: Number,
      validator: value => value > 0,
    },
    borderColor: {
      type: String,
      validator: value => ['blue', 'green', 'grey', 'red', 'yellow'].includes(value),
    },
  },
  data() {
    return {
      shouldShowSlimbox: true,
      shouldShowLoadMoreButton: false,
      dsFooters: {},
      $_footerClass: 'ds-slimbox-footer',
    };
  },
  computed: {
    localTitle() {
      return this.title || this.header;
    },
    shouldShowHeader() {
      return this.localTitle || this.$slots?.header;
    },
    loadMoreClasses() {
      return [
        'ds-u-display--flex',
        'ds-u-align-items--flex-end',
        'ds-u-justify-content--center',
        'ds-u-padding-bottom--lg',
      ];
    },
    classes() {
      return {
        'ds-slimbox--full-height': this.fullHeight,
        [`ds-slimbox--${this.variant}`]: !!this.variant,
        [`ds-slimbox--border-color-${this.borderColor}`]: !!this.borderColor,
      };
    },
    getContentClass() {
      return {
        'ds-slimbox__content--full-height': this.fullHeight,
        'ds-slimbox__content--with-footer': this.hasFooter,
      };
    },
    getVariant() {
      return this.variant === 'dark' ? 'light' : 'dark';
    },
    hasFooter() {
      return Object.getOwnPropertySymbols(this.dsFooters)?.length;
    },
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.header) {
      deprecatedDependency.deprecateProperty('header', 'Use title instead');
    }
    if (this.onCloseCallback) {
      deprecatedDependency.deprecateProperty('onCloseCallback', 'Use allow-close prop and close event instead');
    }
    if (this.buttonLabel) {
      deprecatedDependency.deprecateProperty('buttonLabel', 'Use slot default instead');
    }
    if (this.onButtonClick) {
      deprecatedDependency.deprecateProperty('onButtonClick', 'Use slot default instead');
    }
  },
  mounted() {
    this.initLoadMore();
  },
  methods: {
    setHasDsFooter() {
      const key = Symbol('ds-footer');
      this.$set(this.dsFooters, key, true);
      return () => this.$delete(this.dsFooters, key);
    },
    onCloseButtonClick() {
      this.setSlimboxVisibility(false);
      this.$emit('close');
      if (this.onCloseCallback) {
        this.onCloseCallback();
      }
    },
    setSlimboxVisibility(shouldShow) {
      this.shouldShowSlimbox = shouldShow;
    },
    initLoadMore() {
      if (!this.overflowMaxHeight) {
        return;
      }
      const slimboxHeight = getElementHeight(this.$el);
      this.toggleLoadMore(slimboxHeight > this.overflowMaxHeight);
    },
    toggleLoadMore(toggle) {
      this.shouldShowLoadMoreButton = toggle;
      this.setHeight(toggle ? this.overflowMaxHeight : 'inherit');
    },
    setHeight(height) {
      setElementHeight(this.$el, height);
    },
  },
};

function getElementHeight(el) {
  return el.offsetHeight;
}

function setElementHeight(el, height) {
  el.style.height = typeof height === 'number' ? `${height}px` : height;
}
</script>

<style scoped>
@import './Slimbox.css';
@import './DsSlimboxDefault.css';
@import './DsSlimboxFullpage.css';
@import './DsSlimboxNotification.css';
@import './DsSlimboxList.css';
@import './DsSlimboxButton.css';
@import './DsSlimboxHeader.css';
@import './DsSlimboxContent.css';
@import './DsSlimboxFooter.css';
</style>
