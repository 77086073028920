export function findDsSortableComponent(component) {
  if (component?.$options?.name === 'DsSortable') {
    return component;
  }

  let foundComponent = null;

  component.$children.forEach(child => {
    const sortableComponent = findDsSortableComponent(child);
    if (sortableComponent) {
      foundComponent = sortableComponent;
    }
  });

  return foundComponent;
}

export function getSerializeChildren(item, items) {
  const sortChildren = findDsSortableComponent(item.vNodeItem);
  const list = [];
  if (sortChildren) {
    sortChildren.toArray().forEach(id => {
      const itemSortable = items[id]?.vNodeItem.item;
      if (itemSortable) {
        list.push(itemSortable);
      }
    });
  }
  return list;
}

export function getSerialize(serializedList, items) {
  const list = [];
  serializedList.forEach(id => {
    const item = items[id];
    const children = getSerializeChildren(item, items);

    const newItem = {
      ...item.vNodeItem.item,
      children,
    };
    list.push(newItem);
  });
  return list;
}
