<template>
  <div
    class="ds-async-file-upload-list"
    :class="{ 'ds-async-file-upload-list--empty': isFileListEmpty }"
    @dragover="onDragOver"
    @mouseover="hideDropzoneWhenFileListIsFilled">
    <div v-show="shouldShowDropzone" class="ds-u-position--relative">
      <div v-if="allowedFileTypes" class="ds-async-file-upload-list__allowed-file-types">
        Arquivos permitidos: {{ allowedFileTypes }}
      </div>
      <div v-if="maxFileSize" class="ds-async-file-upload-list__max-file-size">
        Tamanho máximo suportado: {{ maxFileSize }}
      </div>
      <slot name="drag-drop"></slot>
    </div>
    <div v-show="!shouldShowDropzone">
      <slot :files="sortedFiles" :add-files="context.addFiles" />
    </div>
  </div>
</template>

<script>
import { humanizeArray } from '@core/services/array/arrayService';
import { formatBytes } from '@core/services/formatBytes/formatBytesService';
import { toasterService } from '@components/toaster';
import { getErrorMessage } from './asyncFileUploadRestrictionFailedService';

export default {
  name: 'DsAsyncFileUploadList',
  props: {
    context: Object,
  },
  data() {
    return {
      shouldShowDropzone: true,
      dropzoneElement: null,
    };
  },
  computed: {
    isFileListEmpty() {
      return Object.keys(this.context.files).length === 0;
    },
    dropzoneContainerElement() {
      return this.$slots['drag-drop'][0].elm;
    },
    sortedFiles() {
      return Object.values(this.context.files).slice().reverse();
    },
    allowedFileTypes() {
      return this.context.options?.allowedFileTypes ? humanizeArray(this.context.options.allowedFileTypes) : null;
    },
    maxFileSize() {
      return this.context.options?.maxFileSize ? formatBytes(this.context.options.maxFileSize) : null;
    },
  },
  watch: {
    isFileListEmpty: {
      immediate: true,
      handler(isFileListEmpty) {
        this.setDropzoneVisibility(isFileListEmpty);
      },
    },
  },
  mounted() {
    this.context.createFileUpload({
      el: this.dropzoneContainerElement,
    });

    this.context.subscribe({
      onRestrictionFailedInternal: this.onRestrictionFailedHandler,
    });

    this.initializeDropzoneElement();

    addListener(this.dropzoneElement, 'drop', this.hideDropzoneWhenFileListIsFilled);
    addListener(this.dropzoneElement, 'dragleave', this.hideDropzoneWhenEventIsFromOutside);
  },
  beforeDestroy() {
    removeListener(this.dropzoneElement, 'drop', this.hideDropzoneWhenFileListIsFilled);
    removeListener(this.dropzoneElement, 'dragleave', this.hideDropzoneWhenEventIsFromOutside);
  },
  methods: {
    initializeDropzoneElement() {
      this.dropzoneElement = this.dropzoneContainerElement.querySelector('.uppy-DragDrop-container');
    },
    onDragOver() {
      this.setDropzoneVisibility(true);
    },
    hideDropzoneWhenFileListIsFilled() {
      if (!this.isFileListEmpty) {
        this.setDropzoneVisibility(false);
      }
    },
    setDropzoneVisibility(shouldShow) {
      setTimeout(() => {
        this.shouldShowDropzone = shouldShow;
      });
    },
    hideDropzoneWhenEventIsFromOutside(e) {
      if (!this.dropzoneElement.contains(e.fromElement)) {
        this.hideDropzoneWhenFileListIsFilled();
      }
    },
    onRestrictionFailedHandler(error) {
      const errorMessage = getErrorMessage(error);

      toasterService.error({
        title: errorMessage.title,
        content: errorMessage.description,
      });
    },
  },
};

function addListener(el, event, callback) {
  if (!el) {
    return;
  }
  el.addEventListener(event, callback);
}

function removeListener(el, event, callback) {
  if (!el) {
    return;
  }
  el.removeEventListener(event, callback);
}
</script>

<style>
@import './AsyncFileUploadList.css';
</style>
