<template>
  <ds-topbar-aside class="ds-topbar-profile-aside">
    <template #header>
      <div class="ds-topbar-profile-aside__header">
        <ds-avatar size="sm" :src="avatarImgSrc" :alt="avatarAlt" />
        <div class="ds-topbar-profile-aside__description">
          <ds-tooltipped-overflow v-if="userDescription">
            <ds-p v-ds-tooltip="userDescription" class="ds-u-text-overflow--ellipsis" weight="medium">{{
              userDescription
            }}</ds-p>
          </ds-tooltipped-overflow>
          <ds-tooltipped-overflow>
            <ds-p v-ds-tooltip="userName" class="ds-u-text-overflow--ellipsis">{{ userName }}</ds-p>
          </ds-tooltipped-overflow>
          <slot name="header" :closeAside="closeAside" />
        </div>
      </div>
      <ds-row-divider />
    </template>
    <slot />
  </ds-topbar-aside>
</template>

<script>
import DsAvatar from '@components/avatar';
import DsRowDivider from '@components/row-divider';
import DsTopbarAside from '@components/topbar-aside';
import DsTooltip from '@directives/tooltip';
import DsTooltippedOverflow from '@components/tooltipped-overflow';
import DsP from '@components/paragraph';

export default {
  name: 'DsTopbarProfileAside',
  components: {
    DsAvatar,
    DsRowDivider,
    DsTopbarAside,
    DsTooltippedOverflow,
    DsP,
  },
  directives: {
    DsTooltip,
  },
  inject: ['topbarVm', 'topbarProfileItemVm'],
  computed: {
    profileData() {
      return this.topbarProfileItemVm.getProfileData();
    },
    userName() {
      return this.profileData.name;
    },
    userDescription() {
      return this.profileData.description;
    },
    avatarImgSrc() {
      return this.profileData.avatarImgSrc;
    },
    avatarAlt() {
      return this.profileData.avatarAlt;
    },
  },
  methods: {
    closeAside() {
      this.topbarVm.clearActiveItem();
    },
  },
};
</script>

<style scoped>
@import './TopbarProfileAside';
</style>
