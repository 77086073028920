import { dateService, dateParser } from '@core';
import { DATE_PERIOD_TYPES } from '@components/date-period-dropdown';

const { TODAY, THIS_WEEK, THIS_MONTH, THIS_YEAR, LAST_THIRTY_DAYS, CUSTOM_PERIOD, ALL_PERIOD } = DATE_PERIOD_TYPES;

function getPreviousCustomPeriod({ startDate, endDate }) {
  return startDate && endDate
    ? getPreviousCustomPeriodByRangeDate({ startDate, endDate })
    : getCustomPeriodBySingleDate({ startDate, endDate }, -1);
}

function getNextCustomPeriod({ startDate, endDate }) {
  return startDate && endDate
    ? getNextCustomPeriodByRangeDate({ startDate, endDate })
    : getCustomPeriodBySingleDate({ startDate, endDate }, 1);
}

function getCustomPeriodBySingleDate({ startDate, endDate }, diff) {
  const refDate = startDate || endDate;
  const newDate = dateParser.objectToISODate(dateService.addDays(refDate, diff));
  return startDate ? { startDate: newDate } : { endDate: newDate };
}

function getPreviousCustomPeriodByRangeDate({ startDate, endDate }) {
  const diff = dateService.getDiffInDays(startDate, endDate);
  return {
    startDate: dateParser.objectToISODate(dateService.addDays(startDate, -1 - diff)),
    endDate: dateParser.objectToISODate(dateService.addDays(startDate, -1)),
  };
}

function getNextCustomPeriodByRangeDate({ startDate, endDate }) {
  const diff = dateService.getDiffInDays(startDate, endDate);
  return {
    startDate: dateParser.objectToISODate(dateService.addDays(endDate, 1)),
    endDate: dateParser.objectToISODate(dateService.addDays(endDate, 1 + diff)),
  };
}

export function getPreviousPeriod({ startDate, endDate }, periodType) {
  const getPeriodRange = {
    [TODAY.key]: () => TODAY.getPeriod(dateService.addDays(startDate, -1)),
    [THIS_WEEK.key]: () => THIS_WEEK.getPeriod(dateService.addWeeks(startDate, -1)),
    [THIS_MONTH.key]: () => THIS_MONTH.getPeriod(dateService.addMonths(startDate, -1)),
    [THIS_YEAR.key]: () => THIS_YEAR.getPeriod(dateService.addYears(startDate, -1)),
    [LAST_THIRTY_DAYS.key]: () => LAST_THIRTY_DAYS.getPeriod(dateService.addDays(startDate, -1)),
  }[periodType];

  return getPeriodRange ? getPeriodRange() : getPreviousCustomPeriod({ startDate, endDate });
}

export function getNextPeriod({ startDate, endDate }, periodType) {
  const getPeriodRange = {
    [TODAY.key]: () => TODAY.getPeriod(dateService.addDays(startDate, 1)),
    [THIS_WEEK.key]: () => THIS_WEEK.getPeriod(dateService.addWeeks(startDate, 1)),
    [THIS_MONTH.key]: () => THIS_MONTH.getPeriod(dateService.addMonths(startDate, 1)),
    [THIS_YEAR.key]: () => THIS_YEAR.getPeriod(dateService.addYears(startDate, 1)),
    [LAST_THIRTY_DAYS.key]: () => LAST_THIRTY_DAYS.getPeriod(dateService.addDays(endDate, 31)),
  }[periodType];

  return getPeriodRange ? getPeriodRange() : getNextCustomPeriod({ startDate, endDate });
}

export function getDefaultPeriodType() {
  return DATE_PERIOD_TYPES.TODAY.key;
}

export function getPeriodTypesWithoutNavigation() {
  return [DATE_PERIOD_TYPES.ALL_PERIOD.key];
}

export function getPeriodTypeByValue(type) {
  return (
    {
      month: THIS_MONTH.key,
      year: THIS_YEAR.key,
    }[type] || 'custom-period'
  );
}

export function getPeriodTypeByValues({ startDate, endDate } = {}) {
  if (dateService.isPeriodFullYear(startDate, endDate)) {
    return THIS_YEAR.key;
  }
  if (dateService.isPeriodFullMonth(startDate, endDate)) {
    return THIS_MONTH.key;
  }
  if (startDate === null && endDate === null) {
    return ALL_PERIOD.key;
  }
  return CUSTOM_PERIOD.key;
}
