<template>
  <form class="ds-form" novalidate @submit.prevent="submit">
    <ds-row v-if="isErrorMessageVisible">
      <ds-col>
        <ds-box-information
          :title="errorMessage.title"
          :message="errorMessage.description"
          theme="danger"
          @close="onFormErrorBoxInformationClose">
        </ds-box-information>
      </ds-col>
    </ds-row>
    <ds-row v-if="isFormContentVisible" class="ds-form-content">
      <ds-col>
        <slot></slot>
      </ds-col>
    </ds-row>
    <ds-row v-if="isLoaderVisible">
      <ds-col class="ds-u-text-align--center">
        <ds-loader />
      </ds-col>
    </ds-row>
  </form>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import { generateId } from '@core/services/id/idService';
import { toasterService } from '@components/toaster';
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsLoader from '@components/loader';
import DsBoxInformation from '@components/box-information';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';

export default {
  name: 'DsForm',
  inject: {
    registerFormHooks: {
      default: null,
    },
  },
  components: {
    DsBoxInformation,
    DsLoader,
    DsRow,
    DsCol,
  },
  props: {
    errorMessage: {
      type: Object,
    },
    successMessage: {
      type: Object,
    },
    /**
     * DEPRECATED PROP | Use fetch-action props instead
     */
    onFetch: Function,
    fetchAction: Function,
    /**
     * DEPRECATED PROP | Use submit-action props instead
     */
    onSubmit: Function,
    submitAction: Function,
    /**
     * DEPRECATED PROP | Use submit-success event instead
     */
    onSubmitSuccess: Function,
    /**
     * DEPRECATED PROP | Use submit-error event instead
     */
    onSubmitError: Function,
    /**
     * DEPRECATED PROP | Use fetch-success event instead
     */
    onFetchSuccess: Function,
    /**
     * DEPRECATED PROP | Use fetch-error event instead
     */
    onFetchError: Function,
  },
  provide() {
    return {
      formVm: this,
    };
  },
  data() {
    return {
      isLoaderVisible: null,
      isSubmitting: false,
      isErrorMessageVisible: false,
      isFormContentVisible: false,
      modelValidators: {},
      shouldShowErrors: false,
    };
  },
  computed: {
    isValid() {
      return Object.values(this.modelValidators).every(modelValidator => modelValidator && modelValidator.valid);
    },
    fetch() {
      return this.fetchAction || this.onFetch;
    },
    submitHandler() {
      return this.submitAction || this.onSubmit;
    },
  },
  watch: {
    isValid(isValid) {
      this.emitIsValid(isValid);
    },
    successMessage(successMessage) {
      if (this.isValidMessage(successMessage)) {
        toasterService.success({
          title: successMessage.title,
          content: successMessage.description,
        });
      }
    },
    errorMessage(errorMessage) {
      this.setErrorMessageVisibility(this.isValidMessage(errorMessage));
    },
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.onFetch) {
      deprecatedDependency.deprecateProperty('onFetch', 'Use fetch-action instead');
    }
    if (this.onFetchSuccess) {
      deprecatedDependency.deprecateProperty('onFetchSuccess', 'Use @fetch-success event instead');
    }
    if (this.onFetchError) {
      deprecatedDependency.deprecateProperty('onFetchError', 'Use @fetch-error event instead');
    }
    if (this.onSubmit) {
      deprecatedDependency.deprecateProperty('onSubmit', 'Use submit-action instead');
    }
    if (this.onSubmitSuccess) {
      deprecatedDependency.deprecateProperty('onSubmitSuccess', 'Use @submit-success event instead');
    }
    if (this.onSubmitError) {
      deprecatedDependency.deprecateProperty('onSubmitError', 'Use @submit-error event instead');
    }

    this.setErrorMessageVisibility(this.isValidMessage(this.errorMessage));
    this.handleFetch();
    this.handleInjectedHooks();
  },
  mounted() {
    this.initialEmitIsValidHandler();
  },
  methods: {
    initialEmitIsValidHandler() {
      if (!this.fetch && this.isValid) {
        this.emitIsValid(true);
      }
    },
    emitIsValid(value) {
      this.$emit('update:isValid', value);
    },
    handleInjectedHooks() {
      if (this.registerFormHooks) {
        this.registerFormHooks({
          onSubmitSuccess: cb => this.$on('submit-success', cb),
        });
      }
    },
    addModelValidator(modelValidator) {
      const key = generateId();
      this.$set(this.modelValidators, key, modelValidator);
      modelValidator.onDestroy(() => this.$delete(this.modelValidators, key));
    },
    handleFetch() {
      if (this.fetch) {
        this.fetchForm();
      } else {
        this.setFormContentVisibility(true);
      }
    },
    fetchForm() {
      const promise = this.fetch();
      if (promise && promise.then) {
        this.setLoaderVisibility(true);
        promise.then(this.onHandleFetchSuccess, this.onHandleFetchError).finally(() => {
          if (this.isValid) {
            this.emitIsValid(true);
          }
        });
      } else {
        this.setFormContentVisibility(true);
      }
    },
    onHandleFetchSuccess(response) {
      this.setLoaderVisibility(false);
      this.setFormContentVisibility(true);
      this.$emit('fetch-success', response);
      if (this.onFetchSuccess) {
        this.onFetchSuccess(response);
      }
    },
    onHandleFetchError(error) {
      this.setLoaderVisibility(false);
      this.setErrorMessageVisibility(this.isValidMessage(this.errorMessage));
      this.$emit('fetch-error', error);
      if (this.onFetchError) {
        this.onFetchError(error);
      }
    },
    /*
     * Submits form
     *
     * @public
     */
    submit() {
      if (!this.isValid) {
        this.shouldShowErrors = true;
        this.scrollToFirstInvalidElement();

        return;
      }

      this.submitForm();
    },
    async submitForm() {
      this.setErrorMessageVisibility(false);
      this.isSubmitting = true;

      try {
        const response = await this.submitHandler();
        this.onHandleSubmitSuccess(response);
      } catch (e) {
        this.onHandleSubmitError(e);
      } finally {
        this.isSubmitting = false;
        this.shouldShowErrors = false;
      }
    },
    onHandleSubmitSuccess(response) {
      this.$emit('submit-success', response);

      if (this.onSubmitSuccess) {
        this.onSubmitSuccess(response);
      }
    },
    onHandleSubmitError(error) {
      this.setErrorMessageVisibility(this.isValidMessage(this.errorMessage));
      this.$emit('submit-error', error);
      if (this.onSubmitError) {
        this.onSubmitError(error);
      }
    },
    setSubmitButton(submitButton) {
      this.submitButton = submitButton;
    },
    setErrorMessageVisibility(value) {
      this.isErrorMessageVisible = value;
    },
    setFormContentVisibility(value) {
      this.isFormContentVisible = value;
    },
    setLoaderVisibility(value) {
      this.isLoaderVisible = value;
    },
    isValidMessage(message) {
      return message && message.title && message.description;
    },
    onFormErrorBoxInformationClose() {
      this.setErrorMessageVisibility(false);
    },
    getFirstInvalidElement() {
      const firstInvalidModelValidator = Object.values(this.modelValidators).find(
        modelValidator => modelValidator && !modelValidator.valid,
      );

      return firstInvalidModelValidator.vm.fieldVm
        ? firstInvalidModelValidator.vm.fieldVm.$el
        : firstInvalidModelValidator.vm.$el;
    },
    scrollToFirstInvalidElement() {
      return smoothScrollIntoView(this.getFirstInvalidElement());
    },
    /**
     * Validate all the form rules
     *
     * @public
     */
    validateAllFormRules() {
      const validators = Object.values(this.modelValidators);
      validators.forEach(validator => {
        if (validator.hasValue) {
          validator.validate();
        }
      });
    },
  },
};
</script>

<style>
@import './Form.css';
</style>
