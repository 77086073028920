<template>
  <div
    :style="{ height: contentHeight, overflow: contentOverflow }"
    class="ds-height-transition"
    :class="{ 'ds-height-transition--closed': !isOpened }">
    <div ref="content" class="ds-height-transition__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { TRANSITION } from '@core/constants/transition';

export default {
  name: 'AccordionTransition',
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contentHeight: null,
      contentOverflow: null,
      openingTimeoutId: null,
    };
  },
  watch: {
    isOpened: {
      immediate: true,
      handler() {
        this.$nextTick(this.animationHandler);
      },
    },
  },
  methods: {
    animationHandler() {
      if (this.isOpened) {
        this.updateContentHeightWithCurrentSize();
        this.openingHandler();
      } else {
        this.closingHandler();
      }
    },
    openingHandler() {
      this.openingTimeoutId = setTimeout(this.onOpen, TRANSITION.HEIGHT_ANIMATION);
    },
    closingHandler() {
      clearTimeout(this.openingTimeoutId);
      setTimeout(this.onClose);
    },
    updateContentHeightWithCurrentSize() {
      this.contentHeight = `${this.$refs.content.offsetHeight}px`;
    },
    onOpen() {
      this.contentHeight = 'auto';
      this.contentOverflow = 'visible';
    },
    onClose() {
      this.contentHeight = null;
      this.contentOverflow = null;
    },
  },
};
</script>

<style scoped>
@import './HeightTransition.css';
</style>
