<template>
  <div class="ds-textarea">
    <textarea
      ref="textarea"
      v-bind="$attrs"
      :class="classes"
      :value="value"
      :disabled="disabled"
      :rows="localRows"
      class="ds-input ds-form-control"
      :custom-validations="customValidations"
      v-on="listeners"
      @input="onInput" />
    <ds-input-icons>
      <ds-suggestion-button
        v-if="shouldShowSuggestionButton"
        :is-suggestion-accepted="isSuggestionAccepted"
        @click="onApplySuggestion" />
    </ds-input-icons>
  </div>
</template>

<script>
import { modelValidationService, focusMixin } from '@core';
import DsSuggestionButton from '@components/suggestion-button/SuggestionButton.vue';
import DsInputIcons from '@components/input-icons/InputIcons.vue';
import { hasConstructor } from '@core/services/browser/browserService';
import { getMaxHeight } from './textareaService';

const DEFAULT_ROW_AUTOGROW_SIZE = 1;

export default {
  name: 'DsTextarea',
  components: {
    DsInputIcons,
    DsSuggestionButton,
  },
  mixins: [focusMixin.focus('textarea')],
  inheritAttrs: false,
  inject: {
    fieldVm: {
      default: null,
    },
    formVm: {
      default: null,
    },
  },
  props: {
    value: [String, Number],
    required: Boolean,
    disabled: Boolean,
    autogrow: {
      type: Boolean,
      default: false,
    },
    suggestion: {
      type: [String, Number],
      default: null,
    },
    maxRows: {
      type: [String, Number],
      default: null,
    },
    rows: [String, Number],
    customValidations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      modelValidator: modelValidationService.buildModelValidation(this),
      isScrollBarEnabled: false,
      maxHeight: null,
      localRows: this.getLocalRows(),
    };
  },
  computed: {
    isValidSuggestion() {
      return this.suggestion !== null;
    },
    isSuggestionAccepted() {
      return this.isValidSuggestion && this.suggestion === this.value;
    },
    shouldShowSuggestionButton() {
      return this.isValidSuggestion && !this.disabled;
    },
    isScrollBarEnabledInAutogrow() {
      return this.autogrow && !this.isScrollBarEnabled;
    },
    classes() {
      const { cssClass } = this.modelValidator;
      const suggestionClass = 'ds-textarea--suggestion-accepted';
      const scrollBarEnabledClass = 'ds-textarea--scroll-enabled';
      const removeResize = 'ds-textarea--resizable-none';
      const overflowNone = 'ds-textarea--overflow-none';
      return {
        [suggestionClass]: this.isSuggestionAccepted && this.shouldShowSuggestionButton,
        [scrollBarEnabledClass]: this.isScrollBarEnabled,
        [removeResize]: this.autogrow,
        [overflowNone]: this.isScrollBarEnabledInAutogrow,
        [cssClass]: !!cssClass,
      };
    },
    listeners() {
      return {
        ...this.$listeners,
        input: event => this.$emit('input', event.target.value),
        blur: event => this.$emit('blur', event),
      };
    },
  },
  watch: {
    value(value) {
      this.$emit('change', value);
      this.updateScrollBarStatus();
    },
  },
  mounted() {
    this.initSuggestionValue();
    this.updateScrollBarStatus();
    if (hasConstructor('ResizeObserver') && this.autogrow) {
      this.maxHeight = getMaxHeight(this.$refs.textarea, this.maxRows);
      this.configureMainResizeObserver();
    }
  },
  methods: {
    getLocalRows() {
      if (!this.rows && this.autogrow) {
        return DEFAULT_ROW_AUTOGROW_SIZE;
      }

      return this.rows;
    },
    initSuggestionValue() {
      if (this.isValidSuggestion && !this.value) {
        this.onApplySuggestion();
      }
    },
    onApplySuggestion() {
      this.$emit('input', this.suggestion);
      this.$emit('suggestion-applied', this.suggestion);
    },
    onInput() {
      this.updateScrollBarStatus();
    },
    updateScrollBarStatus() {
      const { scrollHeight, offsetHeight } = this.$refs.textarea;

      this.isScrollBarEnabled = scrollHeight > offsetHeight;

      if (this.autogrow) {
        this.resize();
      }
    },
    resize() {
      if (this.maxRows && this.$refs.textarea.scrollHeight > this.maxHeight) {
        this.$refs.textarea.style.height = `${this.maxHeight}px`;
        return;
      }
      this.$refs.textarea.style.height = 'auto';
      this.$refs.textarea.style.height = `${this.$refs.textarea.scrollHeight + 2}px`;
    },
    configureMainResizeObserver() {
      this.mainResizeObserver = new ResizeObserver(() => {
        setTimeout(() => {
          this.resize();
        });
      });
      this.mainResizeObserver.observe(this.$refs.textarea);

      this.$on('hook:destroyed', () => {
        this.mainResizeObserver.disconnect();
      });
    },
  },
};
</script>

<style>
@import './Textarea.css';
</style>
