<script lang="jsx">
import { debug, dateService } from '@core';
import DsText from '@components/text';

const { ptBrMoment, isValid: isDateValid } = dateService;

const validators = {
  'YYYY-MM-DD': dateService.isValidDashDate,
  date: value => value instanceof Date,
  timestamp: value => typeof value === 'number',
};

function isFormatValid(value, inputFormat) {
  return validators[inputFormat](value);
}

function getValueFormatted(value, outputFormat) {
  return ptBrMoment(value).format(outputFormat);
}

function isValueValid(value, inputFormat) {
  const isValid = isFormatValid(value, inputFormat) && isDateValid(value);

  if (!isValid) {
    debug.error('Wrong format value was received in Date Format component.');
  }

  return isValid;
}

function formattedValue({ value, inputFormat, outputFormat }) {
  return isValueValid(value, inputFormat) ? getValueFormatted(value, outputFormat) : '';
}

function getDsTextClasses(data) {
  const renderCallback = (tag, setup) => setup;
  return DsText.render(renderCallback, { props: data.attrs })?.class;
}

function shownValue(props) {
  return props.value ? formattedValue(props) : '';
}

export default {
  name: 'DsDateFormat',
  functional: true,
  components: {
    DsText,
  },
  props: {
    /**
     * Valid Date
     * */
    value: {
      type: [Number, String, Date],
      default: '',
      validator(value) {
        return !value || isDateValid(value);
      },
    },
    /**
     * ['YYYY-MM-DD', 'date', 'timestamp']
     * */
    inputFormat: {
      type: String,
      default: 'YYYY-MM-DD',
      validator(value) {
        return !!validators[value];
      },
    },
    /**
     * Accepts moment.js display API
     * */
    outputFormat: {
      type: String,
      default: 'DD/MM/YYYY',
    },
  },
  render(h, { data, listeners, props }) {
    return h(
      DsText,
      {
        attrs: data.attrs,
        on: listeners,
        class: {
          'ds-date-format': true,
          ...getDsTextClasses(data),
        },
      },
      shownValue(props),
    );
  },
};
</script>
