<template>
  <div class="ds-detail-total-bar-closed" :class="{ 'ds-detail-total-bar-closed--fixed': disableToggle }">
    <div class="ds-detail-total-bar-closed__content">
      <div class="ds-detail-total-bar-closed__info">
        <div class="ds-detail-total-bar-closed__titles">
          <ds-heading
            class="ds-detail-total-bar-closed__title"
            :class="{ 'ds-detail-total-bar-closed__title--vertical-centered': !subtitle }"
            :text="title"
            size="sm" />
          <div v-if="subtitle" class="ds-detail-total-bar-closed__subtitle">
            <ds-text>{{ subtitle }}</ds-text>
          </div>
        </div>
        <div>
          <div
            v-if="firstInfoLabel"
            :class="{ 'ds-detail-total-bar-closed__info-label--vertical-centered': !secondInfoLabel }"
            class="ds-detail-total-bar-closed__label">
            <ds-text>{{ firstInfoLabel }}</ds-text>
          </div>
          <div v-if="shouldShowSecondInfoLabel" class="ds-detail-total-bar-closed__label">
            <ds-text>{{ secondInfoLabel }}</ds-text>
          </div>
        </div>
      </div>
      <div class="ds-detail-total-bar-closed__totals-wrapper">
        <ds-detail-total-bar-closed-total
          v-for="highlightedItem in highlightedTotals"
          :key="highlightedItem.label"
          :label="highlightedItem.label"
          :value="highlightedItem.value" />
        <ds-detail-total-bar-closed-total v-if="totalLabel" :label="totalLabel" :value="total" />
      </div>
    </div>
    <div v-if="!disableToggle" class="ds-detail-total-bar-closed__button">
      <ds-detail-total-bar-toggle-button @click="onToggle" />
    </div>
  </div>
</template>

<script>
import DsText from '@components/text';
import DsHeading from '@components/heading';
import DsDetailTotalBarClosedTotal from './DetailTotalBarClosedTotal.vue';
import DsDetailTotalBarToggleButton from './DetailTotalBarToggleButton.vue';

export default {
  name: 'DsDetailTotalBarClosed',
  components: {
    DsText,
    DsHeading,
    DsDetailTotalBarClosedTotal,
    DsDetailTotalBarToggleButton,
  },
  props: {
    title: String,
    subtitle: String,
    firstInfoLabel: String,
    secondInfoLabel: String,
    highlightedTotals: {
      type: Array,
      default: () => [],
    },
    totalLabel: String,
    total: Number,
    disableToggle: Boolean,
  },
  computed: {
    shouldShowSecondInfoLabel() {
      return this.firstInfoLabel && this.secondInfoLabel;
    },
  },
  methods: {
    onToggle() {
      this.$emit('toggle');
    },
  },
};
</script>

<style scoped>
@import './DetailTotalBarClosed.css';
</style>
