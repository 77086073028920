<template>
  <span class="ql-formats">
    <button
      v-for="(external, index) in externals"
      :key="index"
      v-ds-tooltip="external.tooltip"
      :class="external.className"></button>
  </span>
</template>

<script>
import DsTooltip from '@directives/tooltip';
import { RICH_TEXT_EXTERNALS } from './richTextConstants';

export default {
  name: 'DsRichTextToolbarExternal',
  directives: {
    DsTooltip,
  },
  data() {
    return {
      externals: RICH_TEXT_EXTERNALS,
    };
  },
};
</script>
