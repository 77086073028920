<template>
  <ds-field :label="label" class="ds-u-margin-bottom--none">
    <ds-filter-button-group :value="value" :buttons="buttons" @input="onInput" />
  </ds-field>
</template>

<script>
import { debug } from '@core';
import DsField from '@components/field';
import DsFilterButtonGroup from '@components/filter-button-group';

export default {
  name: 'DsDataGridFilterButtonGroup',
  components: {
    DsField,
    DsFilterButtonGroup,
  },
  props: {
    value: DsFilterButtonGroup.props.value,
    label: DsField.props.label,
    buttons: DsFilterButtonGroup.props.buttons,
  },
  mounted() {
    this.checkRequiredLabel();
  },
  methods: {
    onInput(value) {
      this.$emit('input', value);
    },
    checkRequiredLabel() {
      if (!this.label) {
        debug.error('Label property must be informed in the filterButtonGroup object');
      }
    },
  },
};
</script>
