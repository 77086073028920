<template>
  <tr class="ds-detail-total-bar-subitem">
    <th scope="row" class="ds-detail-total-bar-subitem__label">
      <ds-text color="grey">{{ unit ? `${label} (${unit})` : label }}:</ds-text>
    </th>
    <td
      v-for="valueModel in valueModels"
      :key="valueModel.id"
      :style="{ width: '102px' }"
      class="ds-detail-total-bar-subitem__value">
      <ds-currency-format :value="valueModel.value" :precision="precision" weight="bold" color="text" />
    </td>
  </tr>
</template>

<script>
import DsText from '@components/text';
import DsCurrencyFormat from '@components/currency-format';
import { generateId } from '@core/services/id/idService';

export default {
  name: 'DsDetailTotalBarSubitem',
  inject: ['registerDetailTotalBarSubitem'],
  components: {
    DsCurrencyFormat,
    DsText,
  },
  props: {
    label: String,
    values: {
      type: Array,
      required: true,
    },
    precision: {
      type: Number,
      default: 2,
      validator(precision) {
        return [0, 2].includes(precision);
      },
    },
    unit: {
      type: String,
      default: 'R$',
      validator(unit) {
        return !unit || ['R$'].includes(unit);
      },
    },
  },
  computed: {
    valueModels() {
      return this.values.map(value => ({
        id: generateId(),
        value,
      }));
    },
  },
  mounted() {
    this.registerDetailTotalBarSubitem();
  },
};
</script>

<style scoped>
@import './DetailTotalBarSubitem.css';
</style>
