<template>
  <ds-base-batch-table
    v-if="store"
    ref="table"
    v-bind="$attrs"
    :fetch-action="fetch"
    :batch-actions="batchActions"
    :batch-table-store="store"
    :batch-type="type"
    :is-collapse="isCollapse"
    :fix-first-columns="fixFirstColumns"
    :fix-last-columns="fixLastColumns"
    v-on="listeners">
    <slot slot="header" name="header"></slot>
    <slot></slot>
    <slot slot="footer" name="footer"></slot>
  </ds-base-batch-table>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import DsBaseBatchTable from './BaseBatchTable.vue';
import { createStore } from './store';

export default {
  name: 'DsBatchTable',
  inject: {
    batchTableStore: {
      default: null,
    },
  },
  components: {
    DsBaseBatchTable,
  },
  props: {
    /**
     * DEPRECATED PROP | Use fetch-action props instead
     */
    onFetch: Function,
    /**
     * DEPRECATED PROP | Use @fetch-success event instead
     */
    onFetchSuccess: DsBaseBatchTable.props.onFetchSuccess,
    /**
     * DEPRECATED PROP | Use @row-selection-change event instead
     */
    onRowSelectionChange: DsBaseBatchTable.props.rowSelectionAction,
    fetchAction: Function,
    /**
     * [{
     *   action: Function,
     *   name: String,
     *   clearCheckOnSuccess: Boolean|Function,
     *   refreshOnSuccess: Boolean|Function,
     * }]
     */
    batchActions: DsBaseBatchTable.props.batchActions,
    type: {
      type: String,
      default: 'checkbox',
      validator(type) {
        return ['checkbox', 'radio'].includes(type);
      },
    },
    isCollapse: Boolean,
    fixFirstColumns: DsBaseBatchTable.props.fixFirstColumns,
    fixLastColumns: DsBaseBatchTable.props.fixLastColumns,
  },
  data() {
    return {
      store: this.batchTableStore || createStore({ type: this.type }),
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        'fetch-success': this.fetchSuccess,
        'fetch-error': this.fetchError,
        'row-selection-change': this.onRowSelection,
      };
    },
    fetch() {
      return this.fetchAction || this.onFetch || (() => {});
    },
  },

  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.onFetch) {
      deprecatedDependency.deprecateProperty('onFetch', 'Use fetch-action instead');
    }
    if (this.onFetchSuccess) {
      deprecatedDependency.deprecateProperty('onFetchSuccess', 'Use @fetch-success event instead');
    }
    if (this.onRowSelectionChange) {
      deprecatedDependency.deprecateProperty('onRowSelectionChange', 'Use @row-selection-change event instead');
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    onRowSelection(...args) {
      if (this.onRowSelectionChange) {
        this.onRowSelectionChange(...args);
      }
      this.$emit('row-selection-change', ...args);
    },
    fetchSuccess(response) {
      if (this.onFetchSuccess) {
        this.onFetchSuccess(response);
      }
      this.$emit('fetch-success', response);
    },
    fetchError(error) {
      this.$emit('fetch-error', error);
    },
  },
};
</script>

<style>
@import './BatchTable.css';
</style>
