<template>
  <tr :class="classes" class="ds-table-row" v-on="listeners">
    <slot></slot>
  </tr>
</template>

<script>
export default {
  name: 'DsTr',
  props: {
    notHoverable: Boolean,
    theme: {
      type: String,
      validator(theme) {
        return ['success', 'info', 'warning', 'danger'].includes(theme);
      },
    },
  },
  provide() {
    return {
      tableRowVm: {
        createColumnIndex: this.createColumnIndex,
      },
    };
  },
  data() {
    return {
      columnIndexCount: 0,
    };
  },
  computed: {
    classes() {
      return {
        'ds-tr-not-hoverable': this.notHoverable,
        [`ds-tr--${this.theme}`]: this.theme,
      };
    },
    listeners() {
      return {
        ...this.$listeners,
        click: this.onClick,
      };
    },
  },
  methods: {
    onClick(event) {
      if (this.$listeners.click && !shouldPreventClick(event, this.$el)) {
        this.$listeners.click(event);
      }
    },
    createColumnIndex() {
      const localColumnIndexCount = this.columnIndexCount;
      this.columnIndexCount += 1;

      return localColumnIndexCount;
    },
  },
};

function shouldPreventClick(event, element) {
  return element.contains(event.target.closest('[prevent-click-row]'));
}
</script>
