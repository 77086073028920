<template>
  <ds-link v-if="action" v-ds-tooltip="tooltip" @click.stop="action">
    <ds-icon :icon="icon" :color="color" />
  </ds-link>
  <ds-icon v-else v-ds-tooltip="tooltip" :icon="icon" :color="color" />
</template>

<script>
import DsIcon from '@components/icon';
import DsLink from '@components/link';
import DsTooltip from '@directives/tooltip';
import { debug } from '@core';

export default {
  name: 'DsDataGridActionIcon',
  components: {
    DsIcon,
    DsLink,
  },
  directives: {
    DsTooltip,
  },
  props: {
    icon: {
      type: DsIcon.props.icon.type,
      required: true,
    },
    tooltip: {
      type: String,
      required: true,
    },
    action: {
      type: Function,
    },
    color: {
      type: String,
      validator(color) {
        return ['blue', 'text', 'red', 'yellow'].includes(color);
      },
    },
  },
  created() {
    if (!this.tooltip) {
      debug.error('data-grid icon custom-actions must have a tooltip property', this);
    }
  },
};
</script>
