function setInitialMaxHeight(containerElement) {
  containerElement.style.maxHeight = '60px';
}

function setOpacity(containerElement, opacity) {
  containerElement.style.opacity = opacity;
}

function setMaxHeightAsScrollHeight(containerElement) {
  containerElement.style.maxHeight = `${containerElement.scrollHeight}px`;
}

async function handleClosingAnimation(config) {
  await config.nextTick();
  setInitialMaxHeight(config.containerElement);
  setTimeout(() => {
    setOpacity(config.containerElement, 0);
  }, 100);

  setTimeout(() => {
    setOpacity(config.containerElement, 1);
    config.setCollapseState(false);
  }, 200);
}

async function handleOpeningAnimation(config) {
  config.setCollapseState(true);
  await config.nextTick();
  setMaxHeightAsScrollHeight(config.containerElement);
}

export async function handleAnimation(config) {
  await config.nextTick();
  if (config.getCollapseState()) {
    handleClosingAnimation(config);
  } else {
    handleOpeningAnimation(config);
  }
}
