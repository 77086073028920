export function parser(str) {
  const domParser = new DOMParser();
  const content = 'text/html';
  const parsed = domParser.parseFromString(str, content);
  return parsed.body.childNodes[0];
}

export function hasClass(el, className) {
  if (el.classList) {
    return el.classList.contains(className);
  }
  return new RegExp(`\\b${className}\\b`).test(el.className);
}

export function addClass(_el, className) {
  const el = _el;
  if (el.classList) el.classList.add(className);
  else if (!hasClass(el, className)) el.className += ` ${className}`;
}

export function removeClass(_el, className) {
  const el = _el;
  if (el.classList) el.classList.remove(className);
  else el.className = el.className.replace(new RegExp(`\\b${className}\\b`, 'g'), '');
}

export function getClosest(_elem, selector) {
  let elem = _elem;
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
}

export function getSiblings(el) {
  return [...el.parentElement.children].filter(ch => ch !== el);
}

export function show(_elem) {
  const elem = _elem;
  elem.style.display = 'block';
}

export function hide(_elem) {
  const elem = _elem;
  elem.style.display = 'none';
}

export function containsClosingHTMLtag(string) {
  return string.includes('</');
}

export function getDistanceFromDocumentTop(element) {
  const { height, top } = element.getBoundingClientRect();
  return top + height;
}

export function getDistanceFromDocumentBottom(element) {
  const { bottom } = element.getBoundingClientRect();
  return window.innerHeight - bottom;
}

export function getBoundingClientRectProp(element, prop) {
  return element.getBoundingClientRect()[prop];
}

export function getOffsetWidth(element) {
  return element.offsetWidth;
}

export function getIndex(element) {
  const allElements = [...element.parentElement.children];
  return allElements.indexOf(element);
}
