<template>
  <div class="ds-accordion">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'DsAccordion',
  props: {
    /*
     * Valid values: ['primary', 'secondary', 'warning', 'danger']
     */
    theme: {
      type: String,
      default: 'secondary',
      validator(value) {
        return ['primary', 'secondary', 'warning', 'danger'].includes(value);
      },
    },
    currentItem: {
      type: String,
      default: null,
    },
  },
  provide() {
    return {
      accordionVm: {
        openItem: this.openItem,
        closeItem: this.closeItem,
        getOpenedItem: () => this.openedItem,
        getAccordionTheme: () => this.theme,
      },
    };
  },
  data() {
    return {
      openedItem: null,
    };
  },
  watch: {
    currentItem: {
      immediate: true,
      handler() {
        this.openedItem = this.currentItem || null;
      },
    },
  },
  methods: {
    openItem(item) {
      this.openedItem = item;
    },
    closeItem() {
      this.openedItem = null;
    },
  },
};
</script>
