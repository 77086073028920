<template>
  <ds-button size="sm" :icon="['regular', 'circle-question']" icon-position="left" v-on="$listeners">Ajuda</ds-button>
</template>

<script>
import DsButton from '@components/button';

export default {
  name: 'DsHelpButton',
  components: {
    DsButton,
  },
};
</script>
