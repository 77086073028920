<template>
  <div v-click-outside="onClickOutside" class="ds-multiple-input" :class="classes" @click="onClick">
    <div class="ds-multiple-input-wrapper">
      <input
        ref="input"
        class="ds-multiple-input__input"
        tabindex="-1"
        :required="required"
        :disabled="localDisabled"
        :readonly="readonly"
        v-bind="$attrs" />
      <ds-input-clear-icon v-if="shouldShowClearButton" class="ds-multiple-input__clear" size="md" @click="clear" />
      <ds-icon
        v-else-if="isBlockedWithBreakline"
        tabindex="0"
        class="ds-multiple-input__chevron ds-u-cursor--pointer"
        :icon="chevronIcon"
        color="text"
        size="sm"
        @click.prevent="toggleFocus"
        @keydown.enter.space.prevent="toggleFocus" />
    </div>
  </div>
</template>

<script>
import ClickOutside from '@directives/click-outside';
import DsInputClearIcon from '@components/input-clear-icon';
import DsIcon from '@components/icon';
import {
  addInvalidClassOn,
  buildModelValidator,
  buildTagify,
  getValuesFromString,
  getTagsEl,
  getTextFromTagEl,
  isContentHeightGreaterThanInput,
  isFirstOccurenceOf,
  isValueDuplicated,
  syncModelWithInput,
  removeInvalidClassFrom,
} from './MultipleInputService';

export default {
  name: 'DsMultipleInput',
  components: {
    DsIcon,
    DsInputClearIcon,
  },
  directives: {
    ClickOutside,
  },
  inject: {
    fieldVm: {
      default: null,
    },
    formVm: {
      default: null,
    },
  },
  props: {
    customValidations: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
    required: {
      type: Boolean,
    },
    value: {
      type: Array,
      default: () => [],
    },
    valueProp: {
      type: String,
    },
    beforeRemove: {
      type: Function,
      default: () => Promise.resolve(true),
    },
    beforeClear: {
      type: Function,
      default: () => Promise.resolve(true),
    },
    userInput: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      input: null,
      invalidValues: [],
      isFocused: false,
      isProgramaticChangingHappening: false,
      modelValidator: buildModelValidator(this),
      hasLineBreak: false,
      tagify: null,
      tagsEl: {},
      localDisabled: false,
    };
  },
  computed: {
    validValuesCounter() {
      return this.value.length - this.invalidValues.length;
    },
    invalidValuesCounter() {
      return this.invalidValues.length;
    },
    shouldShowClearButton() {
      return !!(this.validValuesCounter + this.invalidValuesCounter) && !this.isBlocked;
    },
    shouldShowEllipsis() {
      return this.hasLineBreak;
    },
    isBlockedWithBreakline() {
      return this.hasLineBreak && this.isBlocked;
    },
    isBlocked() {
      return this.localDisabled || this.readonly;
    },
    isInputBlocked() {
      return !this.userInput;
    },
    classes() {
      const { cssClass: invalidClass } = this.modelValidator;

      return {
        'ds-multiple-input--ellipsis': this.shouldShowEllipsis,
        'ds-multiple-input--focus': this.isFocused,
        'ds-multiple-input--input-blocked': this.isInputBlocked,
        [invalidClass]: !!invalidClass,
      };
    },
    chevronIcon() {
      return this.isFocused ? 'chevron-up' : 'chevron-down';
    },
  },
  watch: {
    value() {
      syncModelWithInput({
        valueProp: this.valueProp,
        modelList: this.value,
        tagify: this.tagify,
        inputStringModel: this.input.value,
        removeInvalidItemByValue: this.removeInvalidItemByValue,
        setProgrammaticChangingHappening: this.setProgrammaticChangingHappening,
      });
    },
    validValuesCounter: {
      immediate: true,
      handler(validValuesCounter) {
        if (!this.fieldVm) {
          return;
        }
        this.fieldVm.setItensSelectedCounter(validValuesCounter);
      },
    },
    invalidValuesCounter: {
      immediate: true,
      handler(invalidValuesCounter) {
        if (!this.fieldVm) {
          return;
        }
        this.fieldVm.setItensInvalidCounter(invalidValuesCounter);
      },
    },
    disabled(disabled) {
      this.localDisabled = disabled;
    },
    localDisabled(disabled) {
      this.setTagsDisabled(disabled);
    },
  },
  mounted() {
    this.input = this.$refs.input;
    this.tagify = buildTagify(this);
    this.localDisabled = this.disabled;
    this.setProgrammaticChangingHappening(true);
    this.tagify.addTags(this.value.map(this.buildTagfyTagValue));
    this.setProgrammaticChangingHappening(false);

    this.tagsEl = getTagsEl(this.$el);
    window.addEventListener('resize', this.updateHasLineBreak);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateHasLineBreak);
  },
  methods: {
    emitEvent(eventName, { el, value, oldValue } = {}) {
      if (eventName === 'change') {
        this.updateInvalidStyle();
        this.updateHasLineBreak();
        this.$emit('change', this.value, { el, value, oldValue });
      } else {
        this.$emit(eventName, this.value, { el, value, oldValue });
      }
    },
    addTagOnModel(el, value, index) {
      const addValue = this.valueProp ? { [this.valueProp]: value } : value;
      if (!this.isProgramaticChangingHappening) {
        this.value.push(addValue);
      }

      if (!this.isValid(addValue, index)) {
        addInvalidClassOn(el);
        this.invalidValues.push(value);
      }
    },
    setProgrammaticChangingHappening(value) {
      this.isProgramaticChangingHappening = value;
    },
    beforeRemoveValue(index) {
      this.localDisabled = true;
      return this.beforeRemove(this.value[index]).finally(() => {
        this.localDisabled = false;
      });
    },
    onClick() {
      this.emitEvent('click');
    },
    onClickOutside() {
      this.isFocused = false;
      this.updateHasLineBreak();
      setTimeout(() => {
        this.tagsEl.scrollTop = 0;
      });
    },
    clear() {
      this.localDisabled = true;
      this.beforeClear(this.value)
        .then(() => {
          this.value.splice(0, this.value.length);
          this.invalidValues.splice(0, this.invalidValues.length);
          this.tagify.removeAllTags();
          this.emitEvent('change');
        })
        .catch(error => {
          this.$emit('clear-error', error);
        })
        .finally(() => {
          this.localDisabled = false;
          this.focus();
        });
    },
    async updateHasLineBreak() {
      await this.$nextTick();
      this.hasLineBreak = isContentHeightGreaterThanInput(this.tagsEl);
    },
    replaceValue(index, newValue) {
      const currentValue = this.value[index];
      if (this.valueProp) {
        currentValue[this.valueProp] = newValue;
      } else {
        this.value.splice(index, 1, newValue);
      }
    },
    removeItemByValue(value) {
      const matchValue = this.valueProp ? this.value.find(item => item[this.valueProp] === value) : value;
      const index = this.value.indexOf(matchValue);

      if (this.value.includes(matchValue)) {
        this.value.splice(index, 1);
      }
    },
    removeInvalidItemByValue(value) {
      const index = this.invalidValues.indexOf(value);

      if (this.invalidValues.includes(value)) {
        this.invalidValues.splice(index, 1);
      }
    },
    isValid(value, index) {
      const inputList = getValuesFromString(this.input.value);
      const orderedList = inputList.length ? inputList : this.value;
      const isValidByCustomValidations = this.isValidByCustomValidations(value);
      const isDuplicated = isValueDuplicated(this.value, value, this.valueProp);
      const isFirstOccurenceOfDuplication = isFirstOccurenceOf(value, index, orderedList);

      return isValidByCustomValidations && !(isDuplicated && !isFirstOccurenceOfDuplication);
    },
    isValidByCustomValidations(value) {
      if (!this.customValidations) {
        return true;
      }

      return this.customValidations.every(rule => rule.valid([value]));
    },
    updateInvalidStyle() {
      setTimeout(() => {
        const tagElms = this.tagify.getTagElms();

        tagElms.forEach((tagEl, index) => {
          const value = getTextFromTagEl(tagEl);
          const isValid = this.isValidByCustomValidations(this.valueProp ? this.value[index] : value);
          const inputList = getValuesFromString(this.input.value);
          const isFirstOccurenceOfValue = isFirstOccurenceOf(value, index, inputList);

          if (isValid && isFirstOccurenceOfValue) {
            removeInvalidClassFrom(tagEl);
          } else {
            addInvalidClassOn(tagEl);
          }
        });
      });
    },
    setTagsDisabled(disabled) {
      if (disabled) {
        this.tagsEl.setAttribute('disabled', 'disabled');
      } else {
        this.tagsEl.removeAttribute('disabled');
      }
    },
    buildTagfyTagValue(value) {
      if (this.valueProp) {
        return { ...value, value: value[this.valueProp] };
      }
      return value;
    },
    toggleFocus() {
      this.isFocused = !this.isFocused;
    },
    focus() {
      this.tagify.focus();
    },
  },
};
</script>

<style>
@import '@yaireo/tagify/dist/tagify.css';
@import './MultipleInput.css';
</style>
