import Masonry from 'masonry-layout';

export function createWidgetGroup(elem, options) {
  const masonry = new Masonry(elem, options);

  function refresh() {
    masonry.layout();
  }

  return {
    refresh,
  };
}
