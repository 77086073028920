<template>
  <div class="ds-u-display--contents ds-u-pointer-events--none">
    <tr v-for="row in totalRows" :key="row" :style="{ height: `${rowHeight}px` }" class="ds-table-row">
      <td :colspan="columns" class="ds-table-col ds-table-col--loading">
        <ds-skeleton :lines="1" />
      </td>
    </tr>
  </div>
</template>

<script>
import DsSkeleton from '@components/skeleton';

const MINIMUM_ROWS = 1;

export default {
  name: 'DsTableLoading',
  components: {
    DsSkeleton,
  },
  props: {
    columns: {
      type: Number,
    },
    rows: {
      type: Number,
    },
    rowHeight: {
      type: Number,
    },
  },
  data() {
    const rows = this.rows || MINIMUM_ROWS;

    return {
      totalRows: rows,
    };
  },
};
</script>
