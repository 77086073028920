import { PING_PONG } from '@core/constants/fontMetrics';
import capsize from 'capsize';

export const FONT_TYPES = {
  REGULAR: 'REGULAR',
};

export function capsizeStyle({ fontSize, lineGap = 0, fontType = FONT_TYPES.REGULAR }) {
  return capsize({
    fontSize,
    lineGap,
    fontMetrics: PING_PONG[fontType],
  });
}
