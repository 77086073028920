<template>
  <div v-if="shouldShowPagination">
    <div v-if="!isDropdown" class="ds-pagination">
      <ds-pagination-size :page-sizes="pageSizes" :value="localPageSize" @input="onPageSizeChange" />
      <div class="ds-pagination-navigation-container">
        <ds-pagination-navigation
          :total-pages="totalPages"
          :page="localPage"
          :disabled="shouldDisabledPaginationGoTo"
          :first-page="firstPage"
          :pages-limit="pagesLimit"
          @next="setLocalPage"
          @previous="setLocalPage"
          @page-change="emitPageChange" />
        <ds-pagination-navigation-label
          :page-size="localPageSize"
          :first-page="firstPage"
          :page="localPage"
          :total-items="totalItems" />
      </div>
      <ds-pagination-go-to :disabled="shouldDisabledPaginationGoTo" @input="onGoToPage" />
    </div>

    <div v-else class="ds-pagination-dropdown">
      <ds-dropdown ref="dropdown" align="right">
        <template #trigger>
          <div class="ds-u-display--flex">
            <div class="ds-u-display--contents ds-pagination-dropdown__previous" @click.stop>
              <ds-pagination-item-nav is-dropdown type="prev" :disabled="isFirstPage" @click="previous()" />
            </div>
            <ds-button icon="chevron-down" class="ds-pagination-dropdown-results">
              {{ paginateDropdownData }}
            </ds-button>
            <div class="ds-u-display--contents ds-pagination-dropdown__next" @click.stop>
              <ds-pagination-item-nav is-dropdown type="next" :disabled="isLastPage" @click="next()" />
            </div>
          </div>
        </template>

        <ds-pagination-size :page-sizes="pageSizes" :value="pageSize" @input="onPageSizeChange" />
        <ds-pagination-go-to :value="page" :disabled="shouldDisabledPaginationGoTo" @input="onGoToPage" />
      </ds-dropdown>
    </div>
  </div>
</template>

<script>
import DsDropdown from '@components/dropdown';
import DsButton from '@components/button';
import { debounceService } from '@core';
import { DEBOUNCE_TIME } from '@core/constants/debounce';
import DsPaginationNavigation from './PaginationNavigation.vue';
import DsPaginationNavigationLabel from './PaginationNavigationLabel.vue';
import DsPaginationSize from './PaginationSize.vue';
import DsPaginationGoTo from './PaginationGoTo.vue';
import { formatResultsLabel } from './paginationService';
import DsPaginationItemNav from './PaginationItemNav.vue';
import { createPagination } from './paginationFactory';

const PAGE_SIZES = [10, 25, 50, 100];
const MIN_ITEMS = 10;

export default {
  name: 'DsPagination',
  components: {
    DsPaginationNavigation,
    DsPaginationNavigationLabel,
    DsPaginationSize,
    DsPaginationGoTo,
    DsDropdown,
    DsPaginationItemNav,
    DsButton,
  },
  model: {
    prop: 'page',
    event: 'page-change',
  },
  props: {
    isDropdown: {
      type: Boolean,
      default: false,
    },
    totalItems: {
      type: Number,
    },
    page: {
      type: Number,
    },
    firstPage: {
      type: Number,
      default: 1,
      validator(firstPage) {
        return [0, 1].includes(firstPage);
      },
    },
    pagesLimit: {
      type: Number,
      default: 5,
    },
    pageSize: {
      type: Number,
      default: 10,
      validator(pageSize) {
        return PAGE_SIZES.includes(pageSize);
      },
    },
    debounce: {
      type: Number,
      default: DEBOUNCE_TIME,
      validator: debounceService.debounceValidateTime,
    },
  },

  data() {
    return {
      goToPage: null,
      localPageSize: this.pageSize,
      pageSizes: PAGE_SIZES,
      localPage: this.page,
    };
  },
  computed: {
    paginateDropdownData() {
      const { firstPage, localPage: page, totalItems, pageSize } = this;
      return formatResultsLabel({ firstPage, page, totalItems, pageSize });
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.localPageSize);
    },
    lastPage() {
      return this.totalPages - !this.firstPage;
    },
    isFirstPage() {
      return this.localPage === this.firstPage;
    },
    isLastPage() {
      return this.localPage + !this.firstPage === this.totalPages;
    },
    shouldShowPagination() {
      return this.totalItems > MIN_ITEMS;
    },
    isBiggerThanLimit() {
      return this.pageSize * this.localPage < this.totalItems;
    },
    shouldDisabledPaginationGoTo() {
      return this.totalPages < 2;
    },
  },
  watch: {
    pageSize(pageSize) {
      this.localPageSize = pageSize;
    },
    localPageSize() {
      /**
       * { fromPageSize: true } Allows to prevent trigger two data fetches when page is being
       * mutated by pageSize change side effect.
       */
      if (this.page >= this.totalPages) {
        this.$emit('page-change', this.lastPage, { fromPageSize: true });
      }
    },
    localPage() {
      this.onChangeDebounced(this.localPage);
    },
    page(page) {
      if (page !== this.localPage) {
        this.localPage = page;
      }
    },
  },
  created() {
    if (this.page == null) {
      this.$emit('page-change', this.firstPage);
    }
    this.onChangeDebounced = debounceService.debounce(this.emitPageChange, this.debounce);
  },
  methods: {
    onPageSizeChange(pageSize) {
      this.localPageSize = pageSize;
      this.$emit('update:page-size', pageSize);
    },
    onGoToPage(page) {
      const newPage = page - !this.firstPage;

      if (this.isValidRange(newPage)) {
        this.$emit('page-change', newPage);
      }
    },
    isValidRange(page) {
      return page >= this.firstPage && page <= this.totalPages - !this.firstPage;
    },
    emitPageChange(page) {
      if (page === this.page) {
        return;
      }
      this.$emit('page-change', page);
    },
    previous() {
      this.closeDropdown();
      const previousNumber = createPagination().getPreviousNumber(this.page);
      this.setLocalPage(previousNumber);
    },
    next() {
      this.closeDropdown();
      if (this.isBiggerThanLimit) {
        const nextPage = this.localPage + 1;
        this.setLocalPage(nextPage);
      }
    },
    closeDropdown() {
      this.$refs.dropdown.close();
    },
    setLocalPage(page) {
      this.localPage = page;
    },
  },
};
</script>

<style scoped>
@import './Pagination.css';
</style>
