<template>
  <svg viewBox="0 0 36 36" :class="classSvg">
    <path
      class="ds-progress-circular-circle__line"
      d="M18 2.0845
        a 15.9155 15.9155 0 0 1 0 31.831
        a 15.9155 15.9155 0 0 1 0 -31.831"
      :stroke-dasharray="strokeDasharray" />
  </svg>
</template>

<script>
export default {
  name: 'DsProgressCircularCircle',
  props: {
    type: {
      type: String,
      validator(type) {
        return ['shadow', 'active'].includes(type);
      },
    },
    value: Number,
    maxValue: Number,
  },
  data() {
    return {
      strokeDasharray: this.type !== 'shadow' ? '0, 100' : '100, 100',
    };
  },
  computed: {
    classSvg() {
      return ['ds-progress-circular-circle__bar', 'ds-progress-circular-circle__bar--'.concat(this.type)];
    },
  },
  watch: {
    value() {
      this.animateCircle();
    },
  },
  mounted() {
    this.animateCircle();
  },
  methods: {
    animateCircle() {
      if (this.type !== 'shadow') {
        this.$nextTick(() => {
          const percent = (this.value / this.maxValue) * 100;
          this.strokeDasharray = percent.toString().concat(', 100');
        });
      }
    },
  },
};
</script>

<style scoped>
@import './ProgressCircularCircle.css';
</style>
