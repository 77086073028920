<template>
  <td v-ds-tooltip="tooltipValue" :class="['ds-table-col', classes]">
    <slot></slot>
  </td>
</template>

<script>
import DsTooltip from '@directives/tooltip';

export default {
  name: 'DsTdContent',
  directives: {
    DsTooltip,
  },
  props: {
    align: {
      type: String,
      validator(align) {
        return ['center', 'right'].includes(align);
      },
    },
    withInput: {
      type: String,
      validator(value) {
        return ['input', 'dropdown'].includes(value);
      },
    },
    truncateContent: {
      type: Boolean,
    },
    tooltip: {
      type: String,
    },
  },
  data() {
    return {
      tooltipValue: null,
    };
  },
  computed: {
    classes() {
      return {
        [`ds-u-text-align--${this.align}`]: this.align,
        'ds-table-col--with-input': this.withInput,
        'ds-u-overflow--visible': this.withInput === 'dropdown',
        'ds-table-col--truncate-content': this.truncateContent,
      };
    },
  },
  mounted() {
    if (this.withInput === 'input') {
      this.setSlotWithInput();
    }

    if (this.withInput === 'dropdown') {
      this.setSlotWithDropdown();
    }

    this.tooltipValue = this.tooltip || (this.truncateContent && this.$el.textContent);
  },
  methods: {
    setSlotWithInput() {
      const [input] = this.$el.getElementsByTagName('input');

      if (input) {
        input.classList.add('ds-input');
      }
    },
    setSlotWithDropdown() {
      const [button] = this.$el.getElementsByClassName('ds-dropdown-toggle');

      if (button) {
        button.classList.add('ds-input');
        button.classList.add('ds-u-text-overflow--ellipsis');
      }
    },
  },
};
</script>
