<template>
  <div class="ds-rollover__footer">
    <ds-container>
      <slot>
        <ds-row class="ds-u-justify-content--space-between">
          <ds-col>
            <slot name="left"></slot>
          </ds-col>
          <ds-col>
            <slot name="right"></slot>
          </ds-col>
        </ds-row>
      </slot>
    </ds-container>
  </div>
</template>

<script>
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsContainer from '@components/container';

export default {
  name: 'DsRolloverFooter',
  inject: ['rolloverVm'],
  components: {
    DsRow,
    DsCol,
    DsContainer,
  },
};
</script>
