<template>
  <div class="ds-data-grid-actions">
    <ds-loader v-if="shouldShowLoader" theme="inside" />
    <div v-else class="ds-data-grid-actions__wrapper">
      <div v-if="callToAction" class="ds-data-grid-actions__call-to-action-container">
        <ds-request-button
          :key="callToAction.name"
          theme="primary"
          size="sm"
          :request-action="getActionHandler(callToAction)">
          {{ callToAction.name }}
        </ds-request-button>
      </div>
      <template v-else>
        <div v-if="shouldShowIcons" class="ds-data-grid-actions__icons-container">
          <ds-data-grid-action-icon
            v-for="(iconAction, index) in visibleIconActions"
            :key="index"
            v-bind="iconAction" />
        </div>
        <div v-if="isDropdown" class="ds-data-grid-actions__dropdown-container">
          <ds-data-grid-actions-dropdown
            :item="item"
            :actions="visibleButtonOrDropdownActions"
            :get-action-handler="getActionHandler" />
        </div>
        <div v-else class="ds-data-grid-actions__buttons-container">
          <ds-request-button
            v-for="action in visibleButtonOrDropdownActions"
            :key="action.name"
            v-ds-tooltip="handleTooltip(action)"
            :disabled="handleDisabled(action)"
            size="sm"
            :request-action="getActionHandler(action)">
            {{ action.name }}
          </ds-request-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { debug } from '@core';
import DsRequestButton from '@components/request-button';
import { toasterService } from '@components/toaster';
import DsLoader from '@components/loader';
import { confirmService } from '@components/confirm';
import { getOffsetWidth } from '@core/services/dom/domService';
import dataGridConfirmContentBuilder from '@components/data-grid/dataGridConfirmContentService';
import DsTooltip from '@directives/tooltip';
import DsDataGridActionsDropdown from './DataGridActionsDropdown.vue';
import DsDataGridActionIcon from './DataGridActionIcon.vue';

const DROPDOWN_THRESHOLD = 3;

export default {
  name: 'DsDataGridActions',
  inject: {
    dataGridTableVm: {
      default: {},
    },
    tabVm: {
      default: null,
    },
  },
  components: {
    DsDataGridActionsDropdown,
    DsDataGridActionIcon,
    DsRequestButton,
    DsLoader,
  },
  directives: {
    DsTooltip,
  },
  props: {
    item: {
      type: Object,
    },
    customActions: {
      type: Array,
      default: () => [],
    },
    confirmTitle: {
      type: String,
      default: 'Excluir item',
    },
    confirmButtonLabel: {
      type: String,
      default: 'Excluir',
    },
    confirmQuestion: {
      type: String,
      default: 'Tem certeza que deseja excluir este item?',
    },
    itemAttributes: {
      type: Array,
    },
    editAction: {
      type: Function,
    },
    removeAction: {
      type: Function,
    },
    itemKey: {
      type: [String, Number],
    },
    callToAction: {
      type: Object,
    },
  },
  data() {
    return {
      shouldShowLoader: false,
    };
  },
  computed: {
    shouldShowIcons() {
      return this.visibleIconActions?.length > 0;
    },
    isDataGridActionsVisibleInsideATab() {
      return this.tabVm && this.tabVm.isVisible();
    },
    splittedActions() {
      return splitActions(this.customActions);
    },
    visibleButtonOrDropdownActions() {
      return getVisibleActions(this.buttonOrDropdownActions, this.item);
    },
    visibleIconActions() {
      return getVisibleActions(this.splittedActions.iconActions, this.item);
    },
    buttonOrDropdownActionsLength() {
      return this.buttonOrDropdownActions.reduce((acc, cur) => {
        if (cur.name && cur.action) {
          return acc + 1;
        }

        if (cur.header && cur.actions) {
          return acc + cur.actions.length;
        }

        return acc;
      }, 0);
    },
    buttonOrDropdownActions() {
      const actions = [];

      if (this.editAction) {
        actions.push({ name: 'Editar', action: this.edit });
      }

      if (this.removeAction) {
        actions.push({ name: 'Excluir', action: this.remove });
      }

      return actions.concat(this.splittedActions.buttonOrDropdownActions);
    },
    isDropdown() {
      return this.buttonOrDropdownActionsLength >= DROPDOWN_THRESHOLD;
    },
  },
  watch: {
    isDataGridActionsVisibleInsideATab() {
      this.$nextTick(() => {
        this.setActionsHeaderWidth(getOffsetWidth(this.$el));
      });
    },
  },
  created() {
    if (!this.isDropdown && this.buttonOrDropdownActions.find(x => x.header)) {
      debug.error(`You must have at least ${DROPDOWN_THRESHOLD} actions for using headers`);
    }
  },
  updated() {
    this.setActionsHeaderWidth(getOffsetWidth(this.$el));
  },
  mounted() {
    this.setActionsHeaderWidth(getOffsetWidth(this.$el));
  },
  beforeDestroy() {
    this.setActionsHeaderWidth(null);
  },
  methods: {
    getActionHandler(action) {
      return async event => {
        if (event) {
          event.stopPropagation();
        }
        const response = await action.action(this.item);
        this.dataGridTableVm.$emit('action-success', this.itemKey, action, response);
      };
    },
    setActionsHeaderWidth(width) {
      this.dataGridTableVm.setActionsHeaderWidth({ key: this.$vnode.key, width });
    },
    edit() {
      this.editAction(this.item);
    },
    remove() {
      confirmService.confirm({
        title: this.confirmTitle,
        message: this.buildConfirmMessage(this.itemAttributes),
        confirmButtonText: this.confirmButtonLabel,
        onConfirm: () => this.handleRemoval(),
      });
    },
    buildConfirmMessage(itemAttributes) {
      const message = dataGridConfirmContentBuilder.build(
        itemAttributes,
        this.confirmQuestion,
        this.$slots['confirm-exclusion-content'],
      );
      return message.outerHTML;
    },
    handleRemoval() {
      this.setLoaderVisibility(true);
      return this.removeAction(this.item)
        .then(
          () => {
            toasterService.success('Item excluído com sucesso!');
            this.$emit('remove-success', this.item);
          },
          error => {
            this.$emit('remove-error', error);
          },
        )
        .finally(this.onRemoveComplete);
    },
    setLoaderVisibility(shouldShow) {
      this.shouldShowLoader = shouldShow;
    },
    onRemoveComplete() {
      this.setLoaderVisibility(false);
    },
    handleDisabled({ disabled }) {
      return typeof disabled === 'function' ? disabled(this.item) : disabled;
    },
    handleTooltip(action) {
      const isDisabled = this.handleDisabled(action);

      if (isDisabled && !action.disabledTooltip) {
        debug.error('"disabledTooltip" prop must be informed when a custom action is disabled');
      }
      return isDisabled && action.disabledTooltip;
    },
  },
};

function getVisibleActions(actions, item) {
  return actions.filter(action => !action.isVisible || action.isVisible(item));
}

function splitActions(actions) {
  const splittedActions = {
    buttonOrDropdownActions: [],
    iconActions: [],
  };

  actions.forEach(action => {
    splittedActions[action.icon ? 'iconActions' : 'buttonOrDropdownActions'].push(action);
  });

  return splittedActions;
}
</script>

<style scoped>
@import './DataGridActions.css';
</style>
