<template>
  <ds-multiple-select
    :value="localValue"
    :set-label="setLabel"
    :suggestion="suggestion"
    v-bind="$attrs"
    v-on="listeners"
    @suggestion-applied="onSuggestionApplied">
    <ds-option v-if="hasEmptyOption" key="empty" value="empty"> (Em branco) </ds-option>
    <slot></slot>
    <div slot="footer">
      <ds-select-options-footer-item>
        <ds-button class="ds-multiple-select-filter__apply-button" full-width theme="secondary" @click="onApply">
          Aplicar
        </ds-button>
      </ds-select-options-footer-item>
    </div>
  </ds-multiple-select>
</template>

<script>
import DsButton from '@components/button';
import DsMultipleSelect from '@components/multiple-select';
import DsOption from '@components/option';
import DsSelectOptionsFooterItem from '@components/select-options-footer-item';
import { getChildComponentInstance } from '@core/services/vnode/vnodeService';

export default {
  name: 'DsMultipleSelectFilter',
  components: {
    DsButton,
    DsMultipleSelect,
    DsOption,
    DsSelectOptionsFooterItem,
  },
  props: {
    hasEmptyOption: {
      type: Boolean,
      default: false,
    },
    value: DsMultipleSelect.props.value,
    suggestion: DsMultipleSelect.props.suggestion,
  },
  data() {
    return {
      localValue: this.value,
      selectedValue: this.value,
    };
  },
  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
      };
    },
  },
  watch: {
    value(value) {
      this.localValue = value;
    },
  },
  mounted() {
    this.selectVm = getChildComponentInstance(this.$vnode, 'DsSelect');
  },
  methods: {
    onInput(value) {
      this.selectedValue = value;
    },
    onApply() {
      this.$emit('input', this.selectedValue);
      this.setSelectLabel(this.label);
      this.close();
    },
    close() {
      if (this.selectVm?.close) {
        this.selectVm.close();
      }
    },
    open() {
      if (this.selectVm?.open) {
        this.selectVm.open();
      }
    },
    setLabel(label) {
      if (this.isOpen()) {
        this.setSelectLabel(this.currentLabel);
        this.label = label;
      } else {
        this.setSelectLabel(label);
      }
    },
    isOpen() {
      return this.selectVm?.showOptions;
    },
    setSelectLabel(label) {
      this.currentLabel = label;
      if (this.selectVm?.setSelectedOptionLabel) {
        this.selectVm.setSelectedOptionLabel(label);
      }
    },
    onSuggestionApplied() {
      this.$emit('input', this.suggestion);
    },
  },
};
</script>
