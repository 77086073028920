<template>
  <ds-input
    ref="input"
    v-model.lazy="localValue"
    v-money="maskOptions"
    class="ds-numeral-input"
    :suggestion="formattedSuggestion"
    :placeholder="placeholder"
    :custom-validations="localValidations"
    :required="required"
    v-bind="$attrs"
    v-on="listeners"
    @suggestion-applied="emitCustomInput"
    @keydown.enter="onEnter"
    @keyup="onKeyUp"
    @keydown="onKeyDown" />
</template>

<script>
import { focusMixin } from '@core';
import isNil from 'lodash/isNil';
import * as VMoneyLib from 'v-money';
import DsInput from '@components/input';
import INPUT_ERRORS from '@core/constants/inputErrors';
import { KEY_CODES } from '@core/constants';
import {
  formatValue,
  unformatValue,
  removeNegativeSignal,
  isEmptyValue,
  isSomeDeletionKey,
  isCursorAtLastPosition,
} from './numeralInputService';

const OFFSET = ' ';

export default {
  name: 'DsNumeralInput',
  components: {
    DsInput,
  },
  directives: { money: VMoneyLib.VMoney },
  mixins: [focusMixin.focus('input')],
  props: {
    suggestion: DsInput.props.suggestion,
    value: {
      required: true,
      validator(value) {
        return isNil(value) || typeof value === 'number';
      },
    },
    placeholder: String,
    absoluteOnly: Boolean,
    required: Boolean,
    /**
     * [{ valid: Function, message: String }]
     */
    customValidations: DsInput.props.customValidations,
    prefix: String,
    suffix: String,
    precision: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      localValue: this.formatValue(this.value, { precision: this.precision }),
      isInputDirty: false,
      isInputTypedWithEmptyValue: isNil(this.value),
      isInitialValueValid: typeof this.value === 'number' && !Number.isNaN(this.value),
    };
  },
  computed: {
    formattedSuggestion() {
      return this.formatValue(this.suggestion, { precision: this.precision });
    },
    localValidations() {
      const localValidations = this.mapCustomValidations();
      if (this.required) {
        localValidations.unshift(this.getRequiredValidation());
      }
      return localValidations;
    },
    listeners() {
      return {
        ...this.$listeners,
        blur: this.onBlur,
      };
    },
    isInputValueEmpty() {
      const isEmpty = isEmptyValue(this.localValue);
      const { isInputDirty, isInputTypedWithEmptyValue, isInitialValueValid } = this;
      return (
        (isInputTypedWithEmptyValue || (isEmpty && !isInitialValueValid && !isInputDirty)) &&
        this.formattedSuggestion !== this.localValue
      );
    },
    maskOptions() {
      return {
        decimal: ',',
        thousands: '.',
        prefix: this.prefixWithOffset,
        suffix: this.suffixWithOffset,
        precision: this.precision,
      };
    },
    prefixWithOffset() {
      return this.prefix ? `${this.prefix}${OFFSET}` : undefined;
    },
    suffixWithOffset() {
      return this.suffix ? `${OFFSET}${this.suffix}` : undefined;
    },
  },
  watch: {
    value(value) {
      this.isInputTypedWithEmptyValue = isNil(value);

      this.$nextTick(() => {
        if (typeof value === 'number' && !Number.isNaN(value)) {
          this.localValue = this.formatValue(value);
          this.$emit('custom-changed', value);
        }
      });
    },
  },
  mounted() {
    if (this.isInputTypedWithEmptyValue && this.suggestion === null) {
      this.clearInputMask();
    }
  },
  methods: {
    onEnter(event) {
      event.target.blur();
    },
    formatValue(value) {
      return formatValue(value, {
        precision: this.precision,
        absoluteOnly: this.absoluteOnly,
        prefix: this.prefixWithOffset,
        suffix: this.suffixWithOffset,
      });
    },
    unformatValue(value) {
      return unformatValue(value, { absoluteOnly: this.absoluteOnly });
    },
    mapCustomValidations() {
      return this.customValidations.map(customValidation => ({
        ...customValidation,
        valid: value => customValidation.valid(this.unformatValue(value)),
      }));
    },
    getRequiredValidation() {
      return {
        message: INPUT_ERRORS.REQUIRED.MESSAGE,
        valid: () => !this.isInputValueEmpty,
      };
    },
    clearInputMask() {
      this.$nextTick(() => {
        this.$nextTick(() => {
          const input = this.$el.querySelector('input');
          input.value = null;
        });
      });
    },
    onBlur() {
      if (this.isInputValueEmpty) {
        this.clearInputMask();
      }
      this.$emit('blur');
    },
    onKeyUp() {
      if (this.isInputTypedWithEmptyValue) {
        return;
      }
      this.emitCustomInput();
    },
    emitCustomInput(value) {
      setTimeout(() => {
        if (this.absoluteOnly) {
          this.$nextTick(() => {
            this.localValue = removeNegativeSignal(this.localValue);
            this.$emit('custom-input', this.unformatValue(this.localValue));
          });
        } else {
          this.$emit('custom-input', this.unformatValue(value || this.localValue));
        }
      });
    },
    onKeyDown(event) {
      const isValueEmpty = isEmptyValue(event.target.value);
      if (event.keyCode === KEY_CODES.TAB && isValueEmpty) {
        return;
      }

      this.isInputDirty = true;
      const backspacePressed = isSomeDeletionKey(event);
      const atEndPosition = isCursorAtLastPosition(event, this.suffixWithOffset);
      if (backspacePressed && atEndPosition && isValueEmpty) {
        this.isInputTypedWithEmptyValue = true;
        this.$emit('custom-input', null);
        this.clearInputMask();
      } else {
        this.isInputTypedWithEmptyValue = false;
      }
    },
  },
};
</script>
