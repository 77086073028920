<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 140 140"
    xml:space="preserve">
    <path
      class="ds-loader-capro-leaf-1"
      d="M51.1718 66.3334C51.4462 52.8552 62.7888 42 76.7479 42H102.162C101.887 55.4782 90.5446 66.3334 76.5855 66.3334H51.1718Z" />
    <path
      class="ds-loader-capro-leaf-2"
      d="M63.9189 73.5H38C38 87.4987 49.6879 98.8334 64.0812 98.8334H90C90 84.8347 78.3122 73.5 63.9189 73.5Z" />
  </svg>
</template>

<script>
export default {
  name: 'DsLoaderCaProSvg',
};
</script>
<style scoped>
@import './LoaderCaPro.css';
</style>
