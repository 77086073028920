<template>
  <span class="ds-wizard-step-description">
    <span v-if="description || $slots.default" class="ds-wizard-step-description__content">
      <slot>
        {{ description }}
      </slot>
    </span>
    <ds-link v-if="completed" @click.stop="$emit('edit')">
      <span class="ds-u-font-weight--medium">Editar</span>
    </ds-link>
  </span>
</template>

<script>
import DsLink from '@components/link';

export default {
  name: 'DsWizardStepDescription',
  components: {
    DsLink,
  },
  props: {
    description: String,
    completed: Boolean,
  },
};
</script>
