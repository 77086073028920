<template>
  <div class="ds-popover" role="tooltip">
    <slot></slot>
    <div class="ds-popover__arrow" data-popover-arrow></div>
  </div>
</template>

<script>
export default {
  name: 'DsPopover',
  methods: {
    close() {
      this.$destroy();
    },
  },
};
</script>

<style>
@import './Popover.css';
</style>
