<template>
  <div :class="classes" class="ds-currency-highlight">
    <small v-if="prefix" class="ds-currency-highlight-prefix">{{ prefix }}</small>
    <small class="ds-currency-highlight-symbol">R$ </small>
    <span class="ds-currency-highlight-value">
      <ds-currency-format :value="value || 0" precision="2" :color="color" />
    </span>
    <small v-if="suffix" class="ds-currency-highlight-suffix">{{ suffix }}</small>
  </div>
</template>

<script>
import DsCurrencyFormat from '@components/currency-format';

export default {
  name: 'DsCurrencyHighlight',
  components: {
    DsCurrencyFormat,
  },
  props: {
    color: {
      type: String,
      default: 'text',
      validator(color) {
        return ['blue', 'green', 'red', 'yellow', 'grey', 'grey-dark', 'text'].includes(color);
      },
    },
    size: {
      type: String,
      default: 'medium',
      validator(size) {
        return ['small', 'medium', 'large'].includes(size);
      },
    },
    value: [Number, String],
    prefix: {
      type: String,
    },
    suffix: {
      type: String,
    },
  },
  computed: {
    classes() {
      return {
        [`ds-currency-highlight--${this.size}`]: this.size,
      };
    },
  },
};
</script>

<style scoped>
@import 'CurrencyHighlight.css';
</style>
