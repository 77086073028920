<template>
  <li class="ds-list-filter-item" :class="classes" @click="onClick">
    <ds-label :text="label" :icon-tooltip="iconTooltip" class="ds-u-cursor--pointer ds-u-margin-bottom--none" />
    <slot name="value">
      <span class="ds-list-filter-item-value">
        {{ value }}
      </span>
    </slot>
  </li>
</template>

<script>
import DsLabel from '@components/label';

export default {
  name: 'DsListFilterItem',
  components: {
    DsLabel,
  },
  inject: ['listFilterVm'],
  props: {
    iconTooltip: DsLabel.props.iconTooltip,
    label: DsLabel.props.text,
    color: {
      type: String,
      default: 'text',
      validator(color) {
        return ['blue', 'green', 'red', 'yellow', 'grey', 'text'].includes(color);
      },
    },
    selected: {
      type: Boolean,
      default: false,
    },
    value: [String, Number],
  },
  data() {
    return {
      isSelected: this.selected,
    };
  },
  computed: {
    classes() {
      return {
        'is-selected': this.isSelected,
        [`color-${this.color}`]: this.color,
      };
    },
  },
  watch: {
    selected(value) {
      if (value === this.isSelected) return;
      this.listFilterVm.$emit('item-selected', this);
    },
    isSelected() {
      this.$emit('update:selected', this.isSelected);
    },
  },
  created() {
    this.listFilterVm.addItem(this);
  },
  methods: {
    onClick() {
      this.listFilterVm.$emit('item-selected', this);
      this.$emit('click');
    },
    setSelected(isSelected) {
      this.isSelected = isSelected;
    },
  },
};
</script>

<style scoped>
@import './ListFilterItem.css';
</style>
