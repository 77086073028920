import isNil from 'lodash/isNil';
import get from 'lodash/get';
import { vnodeService, selectScrollService, accentService } from '@core';

function getOrderedOptions(options, slotOptions = []) {
  return slotOptions.map(vnode => options[vnode.key]);
}

function isOptionVisible(option) {
  return option.vm && option.vm.$el && !option.vm.$el.className.includes('ds-u-display--none');
}

export function isOptionEqual(a, b, compareValueBy) {
  const aAttribute = get(a, compareValueBy);
  const bAttribute = get(b, compareValueBy);

  if (compareValueBy && aAttribute && bAttribute) {
    return aAttribute === bAttribute;
  }

  return a === b;
}

export function getSlotOptions(vnodes = []) {
  return vnodes.filter(vnode => vnodeService.isComponent(vnode, 'DsOption'));
}

export function getVisibleOptions(slotOptions, options) {
  return getOrderedOptions(options, slotOptions).filter(isOptionVisible);
}

export function getOptionsElement(parentElement) {
  return parentElement.querySelector('[data-select-options]');
}

export function getScrollableOptionsElement(parentElement) {
  return parentElement.querySelector('[data-select-scrollable-options]');
}

export function getNextOption(slotOptions, options, currentOptionKey) {
  const visibleOptions = getVisibleOptions(slotOptions, options);

  if (isNil(currentOptionKey)) {
    const [firstOption] = visibleOptions;
    return firstOption && firstOption.key;
  }

  const nextOptionIndex = visibleOptions.findIndex(({ key }) => key === currentOptionKey) + 1;
  const option = visibleOptions[nextOptionIndex];
  return option ? option.key : currentOptionKey;
}

export function getPreviousOption(slotOptions, options, currentOptionKey) {
  const visibleOptions = getVisibleOptions(slotOptions, options);

  if (isNil(currentOptionKey)) {
    const lastOption = visibleOptions[visibleOptions.length - 1];
    return lastOption && lastOption.key;
  }

  const previousOptionIndex = visibleOptions.findIndex(({ key }) => key === currentOptionKey) - 1;
  const option = visibleOptions[previousOptionIndex];
  return option ? option.key : currentOptionKey;
}

function getBoundingClientRectProp(el, prop) {
  return el.getBoundingClientRect()[prop];
}

export function handleScroll({ optionsElement, optionElement }) {
  if (selectScrollService.shouldScrollUp(optionsElement, optionElement)) {
    optionsElement.scrollTop -= getBoundingClientRectProp(optionElement, 'height');
  }

  if (selectScrollService.shouldScrollDown(optionsElement, optionElement)) {
    optionsElement.scrollTop += getBoundingClientRectProp(optionElement, 'height');
  }
}

function getOptionsMinimunHeight(selectElement, optionsElement) {
  const MINIMUN_HEIGHT = 320;
  const elementHeight = getBoundingClientRectProp(selectElement, 'height');
  const optionsListHeight = getBoundingClientRectProp(optionsElement, 'height');
  const currentHeight = optionsListHeight + elementHeight;

  return currentHeight < MINIMUN_HEIGHT ? MINIMUN_HEIGHT : currentHeight;
}

export function getOptionsListPosition({ optionsElement, selectElement }) {
  const selectDistanteToTop = getBoundingClientRectProp(selectElement, 'top');
  const selectDistanteToBottom = window.innerHeight - selectDistanteToTop;
  const optionsMinimunHeight = getOptionsMinimunHeight(selectElement, optionsElement);

  if (selectDistanteToBottom < optionsMinimunHeight && selectDistanteToTop > selectDistanteToBottom) {
    return 'top';
  }

  return 'bottom';
}

export function getOptionVmByKey(options, optionKey) {
  return options[optionKey] && options[optionKey].vm;
}

export function getOptionVmByValue(options, value, compareValueBy) {
  const findOptionByValue = option => isOptionEqual(option.value, value, compareValueBy);
  const optionFound = Object.values(options).find(findOptionByValue);
  return optionFound && optionFound.vm;
}

export function optionLabelIncludesTerm(label, term) {
  return accentService.removeAccents(label).toLowerCase().includes(accentService.removeAccents(term).toLowerCase());
}
