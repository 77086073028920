import isNil from 'lodash/isNil';
import 'numeral/locales/pt-br';
import numeral from 'numeral';

numeral.locale('pt-br');

const ZERO_VALUE = 0;
const BACKSPACE_KEY = 8;
const DEL_KEY = 46;

const REGEX = {
  ABSOLUTE_ONLY: /^[0-9,.]+$/,
  DEFAULT: /^[-+,]?[0-9,.]*$/,
};

export function isSomeDeletionKey(event) {
  return event.which === BACKSPACE_KEY || event.which === DEL_KEY;
}

export function isCursorAtLastPosition(event, suffix = '') {
  const { value, selectionEnd } = event.target;
  return !value.length || selectionEnd === value.length - suffix.length;
}

export function isEmptyValue(value) {
  return isNil(value) || unformatValue(value) === 0 || value === '';
}

export function unformatValue(value, { absoluteOnly } = {}) {
  if (isNil(value)) return null;
  let formattedValue = value;

  if (checkInternalSymbol(formattedValue)) {
    formattedValue = removeSymbol(value);
  }

  const { _value: numeralValue } = numeral(formattedValue);

  return absoluteOnly ? Math.abs(numeralValue) : numeralValue;
}

function checkInternalSymbol(value) {
  return value.toString().indexOf('%') !== -1 || value.toString().indexOf('R$') !== -1;
}

function removeSymbol(value) {
  return value.toString().replace(/[R$%]/g, '');
}

function getNumeralFormat(precision) {
  return precision ? `0,0.${new Array(precision).fill('0').join('')}` : '0,0';
}

function isInRange(x, min, max) {
  return x >= min && x <= max;
}

export function hasValue(value) {
  return !!value || value === ZERO_VALUE;
}

function splitValueByComma(value) {
  return value.toString().split(',');
}

export function formatValue(value, { absoluteOnly, minPrecision, precision = 2, prefix = '', suffix = '' } = {}) {
  if (isNil(value)) return null;
  const parsedValue = value ? parseValue(value, precision) : value;
  const numeralValue = unformatValue(parsedValue, { absoluteOnly });

  let precisionLength = 0;
  let formattedValue = value;

  if (hasComma(value)) {
    precisionLength = getDecimals(formattedValue)?.length;
  }

  formattedValue = buildCurrencyValue(numeralValue, precision);

  if (precisionLength < minPrecision) {
    formattedValue = buildCurrencyValue(numeralValue, minPrecision);
    return `${prefix}${formattedValue}${suffix}`;
  }

  if (isInRange(precisionLength, minPrecision, precision)) {
    const [number, decimal] = splitValueByComma(formattedValue);
    const formattedDecimal = decimal.slice(0, precisionLength);
    const formattedVal = `${number},${formattedDecimal}`;

    return `${prefix}${formattedVal}${suffix}`;
  }

  formattedValue = buildCurrencyValue(numeralValue, precision);
  return `${prefix}${formattedValue}${suffix}`;
}

function buildCurrencyValue(value, precision) {
  return numeral(value).format(getNumeralFormat(precision));
}

export function removeNegativeSignal(value) {
  return value.replace('-', '');
}

function hasComma(value) {
  return value.toString().indexOf(',') !== -1;
}

export function parseCurrencyStringToFloat(value) {
  if (value) {
    return Number(value.toString().replace(/[.\s]/g, '').replace(',', '.'));
  }
  return value;
}
export function handleParseFloatToCurrencyString(value) {
  if (value && typeof value !== 'string') {
    return value.toString().replace('.', ',');
  }

  return value;
}

export function buildAbsoluteOnlyRegex(absoluteOnly) {
  return absoluteOnly ? REGEX.ABSOLUTE_ONLY : REGEX.DEFAULT;
}

export function parseValue(value, precision) {
  const isValueWithComma = hasComma(value);

  if (isValueWithComma) {
    const splittedValue = value.split(',');
    const [valuesBeforeComma, decimals] = splittedValue;
    const isDecimalsBiggerThanPrecision = Number(decimals.length) > precision;
    let formattedDecimals = decimals;

    if (isDecimalsBiggerThanPrecision) {
      formattedDecimals = decimals.slice(0, precision);
    }
    return `${valuesBeforeComma},${formattedDecimals}`;
  }

  return value;
}

function getValidationParams({ localValue, event, integerProp, precisionProp, absoluteOnlyProp }) {
  const value = localValue.toString();
  const char = event.key;
  const hasCommaDefined = hasComma(value);
  const valuesBeforeCommaCount = hasCommaDefined ? Number(value.split(',')[0].length) : Number(value.length);
  const regex = handleOnlyNumberValidationRegex(absoluteOnlyProp);
  const isNumberDigit = regex.test(char);
  const integer = handleIntegerProp(value, integerProp, absoluteOnlyProp);
  const precision = precisionProp;
  const commaKey = ',';
  const caretPosition = event.target.selectionStart;
  const commaPosition = hasCommaDefined ? Number(value.indexOf(',')) : undefined;
  const reachedInteger = valuesBeforeCommaCount >= integer;
  const isCaretBeforeComma = caretPosition <= commaPosition;
  const isCaretAfterComma = caretPosition > commaPosition;

  return {
    event,
    value,
    char,
    hasCommaDefined,
    valuesBeforeCommaCount,
    regex,
    isNumberDigit,
    integer,
    precision,
    commaKey,
    caretPosition,
    commaPosition,
    reachedInteger,
    isCaretBeforeComma,
    isCaretAfterComma,
  };
}

export function isValueValid({ localValue, event, integerProp, precisionProp, absoluteOnlyProp }) {
  if (!localValue) {
    return false;
  }
  const validationObj = {
    event,
    localValue,
    integerProp,
    precisionProp,
    absoluteOnlyProp,
  };

  const validationParams = getValidationParams(validationObj);
  return checkValue(validationParams);
}

function isNumberReachedIntegerAndHasComma(validationParams) {
  return validationParams.isNumberDigit && validationParams.reachedInteger && !validationParams.hasCommaDefined;
}

function isCommaKeyDigit(validationParams) {
  return validationParams.event.key === validationParams.commaKey;
}

function isNumberAndReachedIntegerAndCaretIsBeforeComma(validationParams) {
  return validationParams.isNumberDigit && validationParams.reachedInteger && validationParams.isCaretBeforeComma;
}

function isValueReachedMaxPrecision(validationParams, decimals) {
  return Number(decimals && decimals.length) >= validationParams.precision;
}

function isNumberReachedIntegerReachedPrecisionAndCaretIsAfterComma(validationParams, reachedMaxPrecision) {
  return (
    validationParams.isNumberDigit &&
    validationParams.reachedInteger &&
    reachedMaxPrecision &&
    validationParams.isCaretAfterComma
  );
}

function isNumberNotReachedIntegerReachedPrecisionAndCaretIsAfterComma(validationParams, reachedMaxPrecision) {
  return (
    validationParams.isNumberDigit &&
    !validationParams.reachedInteger &&
    reachedMaxPrecision &&
    validationParams.isCaretAfterComma
  );
}

function getDecimals(value) {
  const [, decimals] = value.split(',');
  return decimals;
}

function checkValue(validationParams) {
  if (isNumberReachedIntegerAndHasComma(validationParams)) {
    return false;
  }

  if (validationParams.hasCommaDefined) {
    const decimals = getDecimals(validationParams.value);
    const reachedMaxPrecision = isValueReachedMaxPrecision(validationParams, decimals);

    if (
      isCommaKeyDigit(validationParams) ||
      isNumberAndReachedIntegerAndCaretIsBeforeComma(validationParams) ||
      isNumberReachedIntegerReachedPrecisionAndCaretIsAfterComma(validationParams, reachedMaxPrecision) ||
      isNumberNotReachedIntegerReachedPrecisionAndCaretIsAfterComma(validationParams, reachedMaxPrecision)
    ) {
      return false;
    }
  }
  return true;
}

function handleValidationRegex(absoluteOnlyProp) {
  return absoluteOnlyProp ? REGEX.ABSOLUTE_ONLY : REGEX.DEFAULT;
}

function handleOnlyNumberValidationRegex(absoluteOnlyProp) {
  const ONLY_NUMBER_DIGITS_REGEX = {
    ABSOLUTE_ONLY: /^[0-9]+$/,
    DEFAULT: /^[-+]?[0-9]*$/,
  };
  return absoluteOnlyProp ? ONLY_NUMBER_DIGITS_REGEX.ABSOLUTE_ONLY : ONLY_NUMBER_DIGITS_REGEX.DEFAULT;
}

export function validateAndFormatValue({ value, integerProp, precisionProp, absoluteOnlyProp }) {
  const regex = handleValidationRegex(absoluteOnlyProp);
  const integer = handleIntegerProp(value, integerProp, absoluteOnlyProp);

  if (!value && value !== ZERO_VALUE) {
    return undefined;
  }

  const formattedValue = handleParseFloatToCurrencyString(value);
  const props = {
    formattedValue,
    integer,
    precisionProp,
    absoluteOnlyProp,
  };
  const unformattedValue = parseCurrencyStringToFloat(formattedValue);

  if (!regex.test(unformattedValue)) {
    return null;
  }

  return hasComma(formattedValue) ? buildValueWithComma(props) : buildValueWithoutComma(props);
}

function buildValueWithoutComma({ formattedValue, integer }) {
  let value = formattedValue;
  const number = value.toString().replace(/[.,\s]/g, '');

  if (Number(number.length) > integer) {
    value = value.slice(ZERO_VALUE, integer);
    return value;
  }

  return value;
}

function buildValueWithComma({ formattedValue, integer, precisionProp }) {
  const splittedValue = formattedValue.replace(/[.\s]/g, '').split(',');
  const [valuesBeforeComma, decimals] = splittedValue;
  let formattedValueBeforeComma = valuesBeforeComma;
  let formattedDecimals = decimals;

  if (valuesBeforeComma && Number(valuesBeforeComma.length) > integer) {
    formattedValueBeforeComma = valuesBeforeComma.slice(ZERO_VALUE, integer);
  }

  if (decimals && Number(decimals.length) > precisionProp) {
    formattedDecimals = decimals.slice(ZERO_VALUE, precisionProp);
  }

  return `${formattedValueBeforeComma},${formattedDecimals}`;
}

function handleIntegerProp(value, integerProp, absoluteOnlyProp) {
  if (value) {
    const hasNegativeChar = value.toString().indexOf('-') !== -1;

    if (!absoluteOnlyProp && hasNegativeChar) {
      // increment integer to consider 'negative char before value
      return integerProp + 1;
    }
  }
  return integerProp;
}
