<template>
  <div class="ds-sidebar-menu" :class="classes">
    <keep-alive>
      <component :is="componentName" :active-item="activeItem" @active-item-updated="setActiveItem">
        <ds-sidebar-menu-item id="DASHBOARD" :href="sanitizedHref" label="Início" :icon="['regular', 'house-window']" />
        <ds-sidebar-menu-favorite v-if="showFavorites" @favorites-change="emitFavoriteChange" />
        <!-- @slot Place for sidebar-menu-items -->
        <slot />
      </component>
    </keep-alive>
  </div>
</template>

<script>
import DsSidebarMenuItem from '@components/sidebar-menu-item';
import sanitizeUrlLib from '@contaazul/sanitize-url';
import DsSidebarMenuSecondLevel from './SidebarMenuSecondLevel.vue';
import DsSidebarMenuFavorite from './SidebarMenuFavorite.vue';
import { createStore } from './store';

export default {
  name: 'DsSidebarMenu',
  components: {
    DsSidebarMenuSecondLevel,
    DsSidebarMenuItem,
    DsSidebarMenuFavorite,
  },
  inject: ['sidebarVm'],
  props: {
    /**
     * Key of the active item
     */
    active: {
      type: String,
    },
    /**
     * If "active" is a id or href
     */
    activeBy: {
      type: String,
      default: 'id',
      validator(activeBy) {
        return ['id', 'href'].includes(activeBy);
      },
    },
    favorites: {
      type: Array,
      default: () => [],
    },
    showFavorites: {
      type: Boolean,
    },
    homeHref: {
      type: String,
      default: '/',
    },
  },
  provide() {
    return {
      sidebarMenuVm: {
        register: this.sidebarStore.register,
        setActiveItem: this.setActiveItem,
        activeItem: this.activeItem,
        isActiveItem: this.sidebarStore.isActiveItem,
        isParentOfActiveItem: this.sidebarStore.isParentOfActiveItem,
        shouldItemBeActive: this.sidebarStore.shouldItemBeActive,
        isFavorite: this.sidebarStore.isFavorite,
        addFavorite: this.sidebarStore.addFavorite,
        removeFavorite: this.sidebarStore.removeFavorite,
        getFavorites: this.sidebarStore.getFavorites,
        showFavorites: this.showFavorites,
        hasFavorite: this.sidebarStore.hasFavorite,
        haveFavoritesLoaded: this.sidebarStore.haveFavoritesLoaded,
      },
    };
  },
  data() {
    return {
      sidebarStore: createStore(),
    };
  },
  computed: {
    classes() {
      return {
        [`ds-sidebar-menu-${this.getSidebarTheme()}-theme`]: true,
        [`ds-sidebar-menu--active-level-${this.currentActiveLevel}`]: true,
        'ds-sidebar-menu__group--active': this.activeItemHasSubitems,
      };
    },
    sanitizedHref() {
      return sanitizeUrlLib.sanitizeUrl(this.homeHref);
    },
    activeItem() {
      return this.sidebarStore.getActiveItem();
    },
    currentActiveLevel() {
      return this.activeItem?.level || 0;
    },
    activeItemHasSubitems() {
      return this.activeItem?.hasSubitems;
    },
    isActiveSecondState() {
      return this.activeItem?.hasSubitems || this.activeItem?.level > 0;
    },
    componentName() {
      return this.isActiveSecondState ? 'DsSidebarMenuSecondLevel' : 'ul';
    },
  },
  watch: {
    favorites() {
      this.sidebarStore.addFavorites(this.favorites);
    },
  },
  mounted() {
    this.$watch('active', this.selectItemBy, { immediate: true });

    if (this.showFavorites) {
      this.sidebarStore.addFavorites(this.favorites);
    }
  },
  methods: {
    selectItemBy(value) {
      const item = this.sidebarStore.getItemBy(value, this.activeBy);

      if (!item || this.sidebarStore.getActiveItemRootMenu() === 'FAVORITE') {
        return;
      }

      const isItemDuplicated = this.sidebarStore.isItemDuplicated(item);
      const isAnyItemActiveNow = !!this.sidebarStore.getActiveItem();

      if (!isItemDuplicated || (isItemDuplicated && !isAnyItemActiveNow)) {
        item.select();
      }
    },
    setActiveItem(item) {
      this.sidebarStore.setActiveItem(item);

      /**
       * After an item is activated or deactivated
       * @property {Object} item
       */
      this.$emit('active-item-updated', item);
      this.$emit('update:active', item?.id);
    },
    emitFavoriteChange(favoritesItems) {
      this.$emit('favorites-change', favoritesItems);
    },
    getSidebarTheme() {
      return this.sidebarVm.getTheme();
    },
  },
};
</script>

<style scoped>
@import './SidebarMenu.css';
</style>
