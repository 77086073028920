<template>
  <li class="ds-tc-report-list-item" @click="onClick">
    <div class="ds-tc-report-list-item__content">
      <div v-if="type" class="ds-tc-report-list-item__icon">
        <ds-icon :icon="['light', icon]" color="blue" />
      </div>
      <slot />
    </div>
  </li>
</template>

<script>
import DsIcon from '@components/icon';

const ICONS = {
  chart: 'chart-line',
  grid: 'table',
};

export default {
  name: 'DsTcReportListItem',
  components: {
    DsIcon,
  },
  props: {
    type: {
      type: String,
      validator(type) {
        return ['chart', 'grid'].includes(type);
      },
    },
  },
  computed: {
    icon() {
      return ICONS[this.type];
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
@import './TcReportListItem.css';
</style>
