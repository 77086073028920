<template>
  <ds-progress-bar
    class="ds-async-file-upload-progress-bar ds-u-text-overflow--ellipsis"
    :class="{ 'ds-async-file-upload-progress-bar--failed': isFailed }"
    hide-bar-when-completed
    :icon="icon"
    :icon-color="iconColor"
    :title="name"
    :percentage="percentage">
    <template #header>
      <!-- Recalculate the need of tooltip after complete -->
      <ds-tooltipped-overflow :key="progressCompleted">
        <div v-ds-tooltip="name" class="ds-u-text-overflow--ellipsis">
          <ds-text>{{ name }}</ds-text>
          <ds-text v-if="isFailed" class="ds-async-file-upload-progress-bar__fail-message" color="red" size="sm">
            {{ failMessage }}
          </ds-text>
        </div>
      </ds-tooltipped-overflow>
    </template>
  </ds-progress-bar>
</template>

<script>
import DsTooltip from '@directives/tooltip';
import DsText from '@components/text';
import DsProgressBar from '@components/progress-bar';
import DsTooltippedOverflow from '@components/tooltipped-overflow';

export default {
  name: 'DsAsyncFileUploadProgressBar',
  components: {
    DsText,
    DsProgressBar,
    DsTooltippedOverflow,
  },
  directives: {
    DsTooltip,
  },
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
    name: String,
    isDone: Boolean,
    isFailed: Boolean,
    mini: Boolean,
  },
  computed: {
    progressCompleted() {
      return this.isDone;
    },
    icon() {
      if (this.progressCompleted) {
        return ['special', 'check-draw'];
      }
      if (this.isFailed) {
        return ['light', 'times'];
      }

      return ['solid', 'file-alt'];
    },
    iconColor() {
      if (this.progressCompleted) {
        return 'green';
      }
      if (this.isFailed) {
        return 'red';
      }

      return 'grey-light';
    },
    failMessage() {
      return this.mini
        ? 'Houve um erro, tente novamente'
        : 'Houve um erro ao tentar importar esse documento, por favor, arraste o arquivo ou clique em Ações > Tentar Novamente';
    },
  },
};
</script>

<style scoped>
@import './AsyncFileUploadProgressBar.css';
</style>
