<template>
  <ds-checkbox-group
    class="ds-slimbox-group"
    :class="fullHeight ? 'ds-slimbox-group--full-height' : ''"
    v-bind="$attrs"
    direction="horizontal"
    v-on="$listeners">
    <slot />
  </ds-checkbox-group>
</template>

<script>
import DsCheckboxGroup from '@components/checkbox-group';

export default {
  name: 'DsSlimboxGroup',
  components: {
    DsCheckboxGroup,
  },
  props: {
    fullHeight: Boolean,
  },
};
</script>

<style scoped>
@import 'SlimboxGroup.css';
</style>
