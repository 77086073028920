<template>
  <div class="ds-confirm" data-confirm>
    <ds-modal :title="title" :show.sync="isVisible" size="small" @close="cancel">
      <!-- eslint-disable-next-line vue/no-v-html -->
      <ds-p data-confirm-message v-html="message"></ds-p>
      <ds-footer justify="space-between">
        <ds-button data-cancel-button @click="cancel">
          {{ cancelButtonText }}
        </ds-button>
        <ds-button :theme="confirmButtonTheme" data-confirm-button autofocus @click="confirm">
          {{ confirmButtonText }}
        </ds-button>
      </ds-footer>
    </ds-modal>
  </div>
</template>

<script>
import DsButton from '@components/button';
import DsModal from '@components/modal';
import DsFooter from '@components/footer';
import DsP from '@components/paragraph';

export default {
  name: 'DsConfirm',
  components: {
    DsButton,
    DsFooter,
    DsModal,
    DsP,
  },
  props: {
    title: {
      type: String,
    },
    message: {
      type: String,
    },
    cancelButtonText: {
      type: String,
      default: 'Cancelar',
    },
    confirmButtonText: {
      type: String,
      default: 'Confirmar',
    },
    confirmButtonTheme: {
      type: String,
      default: 'danger',
    },
    onConfirm: {
      type: Function,
    },
    onCancel: {
      type: Function,
    },
  },
  data() {
    return {
      isVisible: true,
    };
  },
  methods: {
    cancel() {
      if (this.onCancel) {
        this.onCancel();
      }
      this.destroy();
    },
    confirm() {
      this.onConfirm();
      this.destroy();
    },
    destroy() {
      this.$el.remove();
      this.$destroy();
    },
  },
};
</script>
