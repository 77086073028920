<template>
  <div>
    <div class="ds-batch-table__top-bar ds-u-print-hidden" :class="classes">
      <span class="ds-batch-table__top-bar__counter">{{ selectedRowsCounter }} registro(s) selecionado(s)</span>
      <template v-if="shouldShowActions">
        <ds-request-button
          v-for="action in fixedVisibleBatchActions"
          :key="action.name"
          v-ds-tooltip="getActionTooltip(action)"
          :request-action="() => onClickAction(action)"
          :disabled="!isActionEnabled(action)"
          class="ds-batch-table__top-bar__button"
          size="sm">
          {{ action.name }}
        </ds-request-button>
        <div v-if="shouldRenderActionsSideBySide">
          <ds-request-button
            v-for="action in visibleBatchActions"
            :key="action.name"
            v-ds-tooltip="getActionTooltip(action)"
            :request-action="() => onClickAction(action)"
            :disabled="!isActionEnabled(action)"
            class="ds-batch-table__top-bar__button"
            size="sm">
            {{ action.name }}
          </ds-request-button>
        </div>
        <div v-else>
          <ds-dropdown title="Ações em lote" size="sm">
            <ds-dropdown-item
              v-for="action in visibleBatchActions"
              :key="action.name"
              v-ds-tooltip="getActionTooltip(action)"
              :request-action="() => onClickAction(action)"
              :disabled="!isActionEnabled(action)">
              {{ action.name }}
            </ds-dropdown-item>
          </ds-dropdown>
        </div>
      </template>
    </div>
    <ds-request-table
      ref="table"
      v-bind="$attrs"
      :fetch-action="fetchAction"
      :is-collapse="isCollapse"
      :fix-first-columns="fixFirstColumns"
      :fix-last-columns="fixLastColumns"
      class="ds-batch-table"
      @fetch-success="onFetchSuccess"
      @fetch-error="fetchError">
      <slot slot="header" name="header"></slot>
      <slot></slot>
      <slot slot="footer" name="footer"></slot>
    </ds-request-table>
  </div>
</template>

<script>
import { isFunction } from '@core/services/type/typeService';
import { debug } from '@core';
import DsDropdown from '@components/dropdown';
import DsDropdownItem from '@components/dropdown-item';
import DsTooltip from '@directives/tooltip';
import DsRequestTable from '@components/request-table';
import DsRequestButton from '@components/request-button';

const DROPDOWN_THRESHOLD = 4;
const FIXED_ACTIONS_LIMIT = 2;

export default {
  name: 'DsBaseBatchTable',
  directives: {
    DsTooltip,
  },
  provide() {
    return {
      batchTableVm: this,
      batchTableStore: this.batchTableStore,
      batchType: this.batchType,
    };
  },
  components: {
    DsDropdown,
    DsDropdownItem,
    DsRequestTable,
    DsRequestButton,
  },
  props: {
    batchType: {
      type: String,
      required: true,
    },
    batchTableStore: {
      type: Object,
      required: true,
    },
    fetchAction: {
      type: Function,
      default: () => {},
    },
    batchActions: {
      type: Array,
      validator(batchActions) {
        return Object.values(batchActions).every(({ name, action }) => name && isFunction(action));
      },
    },
    isCollapse: Boolean,
    fixFirstColumns: DsRequestTable.props.fixFirstColumns,
    fixLastColumns: DsRequestTable.props.fixLastColumns,
  },
  data() {
    return {
      fixedBatchActions: [],
    };
  },
  computed: {
    visibleBatchActions() {
      const notFixedVisibleBatchActions = this.batchActions?.filter(this.isActionVisibleNotFixed) || [];
      return [...notFixedVisibleBatchActions, ...this.fixedBatchActions.slice(FIXED_ACTIONS_LIMIT)];
    },
    fixedVisibleBatchActions() {
      return this.fixedBatchActions.slice(0, FIXED_ACTIONS_LIMIT);
    },
    selectedRowsCounter() {
      return this.selectedKeys.length;
    },
    selectedRows() {
      return this.batchTableStore.selectedRows;
    },
    selectedKeys() {
      return this.batchTableStore.selectedKeys;
    },
    hasSelectedRows() {
      return this.selectedRowsCounter > 0;
    },
    batchActionsTooltip() {
      return this.hasSelectedRows ? '' : 'Selecione ao menos 1 (um) item';
    },
    shouldShowActions() {
      return this.visibleBatchActions?.length > 0;
    },
    shouldRenderActionsSideBySide() {
      return [...this.visibleBatchActions, ...this.fixedVisibleBatchActions].length < DROPDOWN_THRESHOLD;
    },
    classes() {
      return {
        'ds-batch-table__top-bar--selected': this.hasSelectedRows,
      };
    },
  },
  created() {
    this.batchTableStore.onRowSelectionChange(this.onRowSelectionChange);
  },
  mounted() {
    this.fixedBatchActions = this.getBatchActionsFixed();
    if (this.fixedBatchActions.length > FIXED_ACTIONS_LIMIT && !this.shouldRenderActionsSideBySide) {
      debug.error(`Cannot have more than ${FIXED_ACTIONS_LIMIT} fixed actions`);
    }
  },
  methods: {
    refresh() {
      this.$refs.table.refresh();
    },
    isActionVisible(action) {
      return !action.isVisible || action.isVisible(this.selectedRows, this.selectedKeys);
    },
    isActionVisibleNotFixed(action) {
      return !action.fixed && this.isActionVisible(action);
    },
    isActionVisibleAndFixed(action) {
      return !!action.fixed && this.isActionVisible(action);
    },
    isActionEnabled({ validationRules = [] }) {
      return (
        this.hasSelectedRows &&
        validationRules.every(({ validationRule }) => validationRule(this.selectedRows, this.selectedKeys))
      );
    },
    getActionTooltip(action) {
      return this.batchActionsTooltip || this.getActionDisabledMessage(action);
    },
    getActionDisabledMessage({ validationRules = [] }) {
      let message = '';

      validationRules.every(({ validationRule, disabledMessage }) => {
        const isValid = validationRule(this.selectedRows, this.selectedKeys);

        if (!isValid) {
          message = disabledMessage;
        }

        return isValid;
      });

      return message;
    },
    async onClickAction(action) {
      const response = await action.action(this.selectedRows, this.selectedKeys);
      this.onBatchActionSuccess(action, response);
    },
    onBatchActionSuccess(action, response) {
      if (this.clearCheckOnSuccess(action, response)) {
        this.selectedKeys.forEach(key => this.batchTableStore.setRowChecked(key, false));
      }

      if (this.refreshOnSuccess(action, response)) {
        this.refresh();
      }
    },
    onFetchSuccess(response) {
      this.$emit('fetch-success', response);
    },
    onRowSelectionChange(...args) {
      this.$emit('row-selection-change', ...args);
    },
    fetchError(error) {
      this.$emit('fetch-error', error);
    },
    refreshOnSuccess(action, response) {
      return isFunction(action.refreshOnSuccess) ? action.refreshOnSuccess(response) : action.refreshOnSuccess;
    },
    clearCheckOnSuccess(action, response) {
      return isFunction(action.clearCheckOnSuccess) ? action.clearCheckOnSuccess(response) : action.clearCheckOnSuccess;
    },
    getBatchActionsFixed() {
      return this.batchActions?.filter(this.isActionVisibleAndFixed) || [];
    },
  },
};
</script>
