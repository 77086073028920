<template>
  <div class="ds-helper-slimbox-item">
    <ds-icon v-if="iconType" size="sm" class="ds-u-margin-right--sm" :icon="iconType" />
    <span v-if="!type" class="ds-helper-slimbox-item--text">
      <slot />
    </span>
    <ds-link v-if="isVideoType" :href="sanitizedHref" class="ds-u-margin-right--sm" @click="emitClick">
      {{ text }}
    </ds-link>
    <ds-external-link
      v-if="isArticleType"
      with-icon
      :href="sanitizedHref"
      class="ds-u-margin-right--sm"
      @click="emitClick">
      {{ text }}
    </ds-external-link>
    <ds-badge v-if="badgeText" :theme="badgeType">{{ badgeText }}</ds-badge>
  </div>
</template>

<script>
import DsIcon from '@components/icon';
import DsBadge from '@components/badge';
import DsExternalLink from '@components/external-link';
import DsLink from '@components/link';
import sanitizeUrlLib from '@contaazul/sanitize-url';

const TYPES = {
  video: {
    icon: 'video',
  },
  article: {
    icon: 'file-alt',
  },
};

export default {
  name: 'DsHelperSlimboxItem',
  components: {
    DsIcon,
    DsBadge,
    DsExternalLink,
    DsLink,
  },
  props: {
    href: String,
    text: String,
    icon: String,
    badgeText: String,
    type: {
      type: String,
      validator(type) {
        return !type || Object.keys(TYPES).includes(type);
      },
    },
    badgeType: {
      type: String,
      default: 'info',
      validator(badgeType) {
        return ['success', 'warning', 'info', 'danger'].includes(badgeType);
      },
    },
  },
  computed: {
    isVideoType() {
      return this.type === 'video';
    },
    isArticleType() {
      return this.type === 'article';
    },
    iconType() {
      return TYPES[this.type]?.icon || this.icon;
    },
    sanitizedHref() {
      return sanitizeUrlLib.sanitizeUrl(this.href);
    },
  },
  methods: {
    emitClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style scoped>
@import './HelperSlimboxItem.css';
</style>
