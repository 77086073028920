<template>
  <div class="ds-container-navigation">
    <div class="ds-container-navigation__content">
      <slot></slot>
    </div>
    <div class="ds-container-navigation__menu-container">
      <ds-context-menu :value="localValue" :items="Object.values(items)" @activate="clickItemId" />
    </div>
  </div>
</template>

<script>
import DsContextMenu from '@components/context-menu/ContextMenu.vue';
import { getChildComponentInstance, getChildrenFromSlot } from '@core/services/vnode/vnodeService';
import { TRANSITION } from '@core/constants/transition';

export default {
  name: 'DsContainerNavigation',
  components: {
    DsContextMenu,
  },
  provide() {
    return {
      dsContainerNavigation: {
        addStep: this.addStep,
        createItemId: this.createItemId,
        clickItemId: this.clickItemId,
      },
    };
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    allowMultipleOpenCollapses: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      items: {},
      itemId: -1,
      scrollItemCurrent: null,
    };
  },
  mounted() {
    this.goToStartScreenItem();
  },
  methods: {
    getNavigationItemVm(slot, key) {
      const getVnodeByKey = vnode => vnode.componentInstance.$options.key === key;
      return getChildComponentInstance(slot, 'DsContainerNavigationItem', getVnodeByKey);
    },
    addStep(key, item) {
      getChildrenFromSlot(this.$slots.default).forEach((slot, index) => {
        const vm = this.getNavigationItemVm(slot, key);

        if (vm) {
          this.$set(this.items, index, { ...item, index, el: vm.$el });
        }
      });
    },
    activateMenuByIndex(index) {
      this.localValue = index;
    },
    clickItemId(itemId, value) {
      Object.values(this.items).forEach((item, index) => {
        if (item.itemId === itemId) {
          this.scrollItemCurrent = item.scrollTo;
          item.setCollapsed(value);
          this.activateMenuByIndex(index);
        } else if (!this.allowMultipleOpenCollapses) {
          item.setCollapsed(false);
        }
      });
      if (this.scrollItemCurrent) {
        setTimeout(() => {
          this.scrollItemCurrent();
        }, TRANSITION.HEIGHT_ANIMATION);
      }
    },
    createItemId() {
      this.itemId += 1;
      return this.itemId;
    },
    goToStartScreenItem() {
      if (this.value > 0 && this.items[this.value]) {
        this.items[this.value].setCollapsed(true);
        setTimeout(() => {
          this.items[this.value].scrollTo();
        }, TRANSITION.HEIGHT_ANIMATION);
      } else {
        this.items[0].setCollapsed(true);
      }
    },
  },
};
</script>

<style scoped>
@import './ContainerNavigation.css';
</style>
