<template>
  <div class="ds-timeline">
    <slot></slot>
  </div>
</template>

<script>
import { debug } from '@core';
import { isComponent } from '@core/services/vnode/vnodeService';

const MIN_STEPS_LENGTH = 2;
const MAX_STEPS_LENGTH = 10;

export default {
  name: 'DsTimeline',
  provide() {
    return {
      timelineVm: this,
    };
  },
  props: {
    /**
     * Current step from 0 to n
     */
    currentStep: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stepIndex: -1,
    };
  },
  computed: {
    isThereAnySubtitle() {
      return this.steps.some(step => step.subtitle);
    },
    steps() {
      return this.$slots.default
        .filter(vnode => isComponent(vnode, 'DsTimelineStep'))
        .map(({ componentOptions: { propsData } }) => propsData);
    },
  },
  created() {
    if (this.steps.length < MIN_STEPS_LENGTH || this.steps.length > MAX_STEPS_LENGTH) {
      debug.error(`Steps quantity must be >= ${MIN_STEPS_LENGTH} or <= ${MAX_STEPS_LENGTH}.`);
    }
  },
  methods: {
    createStepIndex() {
      this.stepIndex += 1;
      return this.stepIndex;
    },
  },
};
</script>

<style scoped>
@import './Timeline.css';
</style>
