<template>
  <div
    ref="container"
    class="ds-detail-total-bar"
    :class="{ 'ds-detail-total-bar--fixed-opened': isDetailTotalBarFixedToBeOpened }">
    <ds-detail-total-bar-opened
      v-show="isCollapseOpened"
      :title="title"
      :subtitle="subtitle"
      :first-info-label="firstInfoLabel"
      :second-info-label="secondInfoLabel"
      :disable-toggle="isDetailTotalBarFixed"
      @toggle="onToggle">
      <slot />
      <slot slot="highlighted-subtotals" name="highlighted-subtotals" />
      <slot slot="total" name="total" />
    </ds-detail-total-bar-opened>
    <ds-detail-total-bar-closed
      v-show="!isCollapseOpened"
      ref="detailTotalBarClosed"
      :title="title"
      :subtitle="subtitle"
      :first-info-label="firstInfoLabel"
      :second-info-label="secondInfoLabel"
      :highlighted-totals="highlightedTotals"
      :total-label="totalLabel"
      :total="total"
      :disable-toggle="isDetailTotalBarFixed"
      @toggle="onToggle">
    </ds-detail-total-bar-closed>
  </div>
</template>

<script>
import { debug } from '@core';
import DsDetailTotalBarClosed from './DetailTotalBarClosed.vue';
import DsDetailTotalBarOpened from './DetailTotalBarOpened.vue';
import { handleAnimation } from './DetailTotalBarService';

export default {
  name: 'DsDetailTotalBarDeprecated',
  components: {
    DsDetailTotalBarClosed,
    DsDetailTotalBarOpened,
  },
  provide() {
    return {
      detailTotalBarVm: {
        updateDetailTotalBar: this.updateDetailTotalBar,
      },
    };
  },
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    firstInfoLabel: {
      type: String,
    },
    secondInfoLabel: {
      type: String,
    },
    fixedCollapse: {
      type: String,
      validator(value) {
        return !value || ['opened', 'closed'].includes(value);
      },
      default: null,
    },
  },
  data() {
    return {
      isCollapseOpened: false,
      ...this.getTotals(),
    };
  },
  computed: {
    isDetailTotalBarFixed() {
      return !!this.fixedCollapse;
    },
    isDetailTotalBarFixedToBeOpened() {
      return this.fixedCollapse === 'opened';
    },
  },
  mounted() {
    this.isCollapseOpened = this.isDetailTotalBarFixedToBeOpened;
    this.validateComponentRequirements();

    debug.warning(
      '%cThis component is deprecated. Please use DsDetailTotalbar instead.%c ',
      'font-size: 18px;',
      'background-image: url("https://i.giphy.com/media/I79LJnc93nufe/source.gif"); background-size: cover; padding: 20px 40px; margin-top: 20px',
    );
  },
  methods: {
    validateComponentRequirements() {
      if (this.isDetailTotalBarFixed) {
        if (this.isDetailTotalBarFixedToBeOpened) {
          this.totalSlotValidation();
        }
      } else {
        this.highlightedSubtotalsSlotValidation();
        this.totalSlotValidation();
        this.titleValidation();
      }
    },
    onToggle() {
      handleAnimation({
        setCollapseState: this.setCollapseState,
        getCollapseState: this.getCollapseState,
        containerElement: this.$refs.container,
        nextTick: this.$nextTick,
      });
    },
    highlightedSubtotalsSlotValidation() {
      const highlightedSubtotalsSlotLength = this.$slots['highlighted-subtotals']?.length;
      if (highlightedSubtotalsSlotLength > 2) {
        debug.error(
          `Detail Total Bar component accepts max 2 highlighted subtotals slot and now it has ${highlightedSubtotalsSlotLength}.`,
        );
      }
    },
    titleValidation() {
      if (!this.title) {
        debug.error('Detail Total Bar must have 1 title when it is not fixed');
      }
    },
    totalSlotValidation() {
      const totalSlotLength = this.$slots.total?.length;
      if (totalSlotLength !== 1) {
        debug.error(
          `Detail Total Bar component must have 1 and only 1 "total" slot and now it has ${totalSlotLength || 0}.`,
        );
      }
    },
    setCollapseState(value) {
      this.isCollapseOpened = value;
    },
    getCollapseState() {
      return this.isCollapseOpened;
    },
    updateDetailTotalBar() {
      this.refreshTotals();
    },
    getSlotProp(slotName, index, prop) {
      const slot = this.$slots[slotName];
      return slot && slot[index]?.componentOptions?.propsData[prop];
    },
    getTotals() {
      return {
        highlightedTotals: this.getHighlightedTotals(),
        totalLabel: this.getSlotProp('total', 0, 'label'),
        total: this.getSlotProp('total', 0, 'value'),
      };
    },
    getHighlightedTotals() {
      const highlightedTotals = this.$slots['highlighted-subtotals'] || [];
      return highlightedTotals.map((item, index) => ({
        label: this.getSlotProp('highlighted-subtotals', index, 'label'),
        value: this.getSlotProp('highlighted-subtotals', index, 'value'),
      }));
    },
    refreshTotals() {
      const { highlightedTotals, totalLabel, total } = this.getTotals();
      this.highlightedTotals = highlightedTotals;
      this.totalLabel = totalLabel;
      this.total = total;
    },
  },
};
</script>

<style scoped>
@import './DetailTotalBar.css';
</style>
