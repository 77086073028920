import * as confirmBuilder from './confirmBuilder';

function getContainer() {
  return document.querySelector(`[${getContainerSelector()}]`) || buildContainer();
}

function buildContainer() {
  const container = document.createElement('div');
  container.setAttribute(getContainerSelector(), '');
  document.body.appendChild(container);
  return container;
}

function getContainerSelector() {
  return 'data-confirm-container';
}

function appendComponentElementToContainer(component) {
  const { $el } = component;
  getContainer().appendChild($el);
}

export function confirm({
  title,
  message,
  onConfirm,
  cancelButtonText,
  confirmButtonText,
  confirmButtonTheme,
  onCancel,
} = {}) {
  const confirmWrapperComponent = confirmBuilder.build(title, message, onConfirm, {
    cancelButtonText,
    confirmButtonText,
    confirmButtonTheme,
    onCancel,
  });

  if (!confirmWrapperComponent) {
    return {};
  }

  appendComponentElementToContainer(confirmWrapperComponent);
  const [confirmComponent] = confirmWrapperComponent.$children;

  return {
    confirm: () => {
      const { _isDestroyed } = confirmComponent;

      if (!_isDestroyed) {
        confirmComponent.confirm();
      }
    },
  };
}
