import { INPUT_RULES } from './passwordInputConstants';

export function isPasswordValid(value) {
  return (
    INPUT_RULES.reduce(
      (invalidRules, currentRule) => (currentRule.REGEX.test(value) ? invalidRules : [...invalidRules, currentRule]),
      [],
    ).length === 0
  );
}
