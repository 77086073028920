<template>
  <div v-if="$slots.button" :class="alignmentClass">
    <slot name="button"></slot>
  </div>
  <span v-else :class="['ds-input-addon', alignmentClass]">
    <span v-if="text" class="ds-input-addon-text">
      {{ text }}
    </span>
    <span v-if="icon" class="ds-input-addon-icon">
      <ds-icon :icon="icon" />
    </span>
  </span>
</template>

<script>
import DsIcon from '@components/icon';

export default {
  name: 'DsInputAddon',
  components: {
    DsIcon,
  },
  props: {
    icon: {
      type: DsIcon.props.icon.type,
    },
    text: {
      type: String,
    },
    alignment: {
      type: String,
      required: true,
      validator(alignment) {
        return ['left', 'right'].includes(alignment);
      },
    },
  },
  computed: {
    alignmentClass() {
      return `ds-input-addon--align-${this.alignment}`;
    },
  },
};
</script>

<style>
@import './InputAddon.css';
</style>
