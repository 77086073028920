import { formatNumber } from '@core/services/numberFormat/numberFormatService';

export function formatResultsLabel({ firstPage, page, totalItems, pageSize } = {}) {
  const currentPage = firstPage === 0 ? page : page - 1;
  const firstValue = currentPage * pageSize + 1;
  const total = formatNumber(totalItems, { precision: 0, minimumPrecision: 0 });
  const maxValue = firstValue + pageSize - 1;
  const lastValue = maxValue > totalItems ? totalItems : maxValue;

  return `${firstValue} - ${lastValue} de ${total}`;
}
