<template>
  <div class="ds-progress-bar" :class="mainClasses">
    <div v-if="icon" class="ds-progress-bar__aside">
      <ds-icon :icon="icon" size="lg" :color="iconColor" />
    </div>
    <div class="ds-progress-bar__container">
      <slot v-if="title" name="header">
        <div class="ds-progress-bar__header">
          <ds-text :color="titleColor">{{ title }}</ds-text>
        </div>
      </slot>
      <ds-fade-transition>
        <div v-if="shouldShowBarState" class="ds-progress-bar__main">
          <div class="ds-progress-bar__bar">
            <div class="ds-progress-bar__progress" :style="progressStyle"></div>
          </div>
          <div v-if="shouldShowPercentage" class="ds-progress-bar__percentage">
            <ds-text :color="percentageColor">
              {{ maskedPercentage }}
            </ds-text>
          </div>
        </div>
      </ds-fade-transition>
    </div>
  </div>
</template>

<script>
import { debug } from '@core';
import DsIcon from '@components/icon';
import DsText from '@components/text';
import DsFadeTransition from '@components/fade-transition/FadeTransition.vue';

const HIDE_BAR_TIMEOUT = 2000;

export default {
  name: 'DsProgressBar',
  components: {
    DsIcon,
    DsText,
    DsFadeTransition,
  },
  props: {
    title: {
      type: String,
    },
    percentage: {
      type: Number,
      required: true,
    },
    theme: {
      type: String,
      default: 'dark',
      validator(theme) {
        return ['dark', 'light', 'red', 'multiple-bars'].includes(theme);
      },
    },
    size: {
      type: String,
      default: 'md',
      validator(theme) {
        return ['sm', 'md'].includes(theme);
      },
    },
    icon: {
      type: DsIcon.props.icon.type,
    },
    iconColor: {
      type: DsIcon.props.color.type,
      validator: DsIcon.props.color.validator,
      default() {
        return this.theme === 'light' ? 'white' : 'blue';
      },
    },
    hideBarWhenCompleted: {
      type: Boolean,
    },
    hidePercentage: {
      type: Boolean,
    },
  },
  data() {
    return {
      shouldShowBarState: true,
      hideBarTimeoutId: null,
    };
  },
  computed: {
    isLightTheme() {
      return this.theme === 'light';
    },
    isMultipleBarsTheme() {
      return this.theme === 'multiple-bars';
    },
    isSmallSize() {
      return this.size === 'sm';
    },
    validPercentage() {
      return getValidPercentage(this.percentage);
    },
    maskedPercentage() {
      return `${this.validPercentage}%`;
    },
    isProgressCompleted() {
      return this.validPercentage === 100;
    },
    isProgressZero() {
      return this.validPercentage === 0;
    },
    shouldShowBar() {
      return !this.hideBarWhenCompleted || !this.isProgressCompleted;
    },
    themeClass() {
      return `ds-progress-bar--${this.theme}`;
    },
    mainClasses() {
      return {
        [this.themeClass]: true,
        'ds-progress-bar--sm': this.isSmallSize,
        'ds-progress-bar--completed': this.isProgressCompleted && !this.isLightTheme,
        'ds-progress-bar-light--completed': this.isProgressCompleted && this.isLightTheme,
        'ds-progress-bar-multiple-bars--completed': this.isProgressCompleted && this.isMultipleBarsTheme,
        'ds-progress-bar-multiple-bars--zero': this.isProgressZero && this.isMultipleBarsTheme,
        'ds-progress-bar--hide-percentage': !this.shouldShowPercentage,
      };
    },

    progressStyle() {
      return {
        width: this.maskedPercentage,
      };
    },
    percentageColor() {
      if (this.isProgressCompleted && this.isMultipleBarsTheme) {
        return 'grey';
      }

      if (this.isProgressCompleted && !this.isLightTheme) {
        return 'green';
      }

      return this.incompletePercentageColor;
    },
    incompletePercentageColor() {
      return this.isLightTheme ? 'white' : 'blue';
    },
    titleColor() {
      return this.isLightTheme ? 'white' : 'text';
    },
    shouldShowPercentage() {
      return !this.hidePercentage;
    },
  },
  watch: {
    shouldShowBar: {
      immediate: true,
      handler(shouldShowBar) {
        this.barVisibilityHandler(shouldShowBar);
      },
    },
  },
  methods: {
    barVisibilityHandler(shouldShowBar) {
      if (shouldShowBar) {
        this.shouldShowBarState = true;
        clearTimeout(this.hideBarTimeoutId);
      } else {
        this.hideBarTimeoutId = setTimeout(() => {
          this.shouldShowBarState = false;
        }, HIDE_BAR_TIMEOUT);
      }
    },
  },
};

function getValidPercentage(receivedPercentage) {
  if (receivedPercentage < 0) {
    debug.error(`Expected positive percentage value in ds-progress-bar, but received: ${receivedPercentage}`);
    return 0;
  }

  if (receivedPercentage > 100) {
    debug.error(`Expected percentage value less than 100 in ds-progress-bar, but received: ${receivedPercentage}`);
    return 100;
  }

  return Math.floor(receivedPercentage);
}
</script>

<style scoped>
@import './ProgressBar.css';
</style>
