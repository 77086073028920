import './tooltip.css';
import tippy from 'tippy.js';

export default {
  bind(el, { value, arg, modifiers }) {
    if (!value) {
      return;
    }

    create(el, {
      placement: getPlacement(modifiers),
      size: arg || null,
      center: modifiers['text-center'],
    });

    setTooltipContent(el, value);
  },
  componentUpdated(el, { value, oldValue, arg, modifiers }) {
    if (value !== oldValue) {
      if (!value) {
        destroy(el);
        return;
      }

      if (!el._tippy) {
        create(el, {
          placement: getPlacement(modifiers),
          size: arg || null,
          center: modifiers['text-center'],
        });
      }

      setTooltipContent(el, value);
    }
  },
  unbind(el) {
    destroy(el);
  },
  disable(el) {
    if (el?._tippy?.state?.isEnabled) {
      el._tippy.disable();
    }
  },
  enable(el) {
    if (el?._tippy && !el._tippy.state.isEnabled) {
      el._tippy.enable();
    }
  },
  hide(el) {
    if (el?._tippy) {
      el._tippy.hide();
    }
  },
  show(el) {
    if (el?._tippy && el?._tippy?.state?.isEnabled) {
      el._tippy.show();
    }
  },
};

function create(el, { size, placement, center }) {
  tippy(el, {
    allowHTML: true,
    animation: 'shift-away',
    zIndex: 10003,
    arrow: true,
    maxWidth: size && `${size}px`,
    ignoreAttributes: true,
    theme: 'ds',
    placement,
  });

  if (center) {
    const [content] = el._tippy.popper.getElementsByClassName('tippy-content');
    content.classList.add('ds-u-text-align--center');
  }
}

function setTooltipContent(el, value) {
  el.dataset.originalTitle = value;
  // Read more https://github.com/ContaAzul/ca-design-system/issues/1276

  el._tippy.setContent(value);
}

function destroy(el) {
  if (el._tippy) {
    el._tippy.destroy();
  }

  delete el.dataset.originalTitle;
}

const PLACEMENTS = [
  'top',
  'bottom',
  'left',
  'right',
  'top-start',
  'bottom-start',
  'left-start',
  'right-start',
  'top-end',
  'bottom-end',
  'left-end',
  'right-end',
];

function getPlacement(modifiers) {
  return Object.keys(modifiers).filter(modifier => PLACEMENTS.includes(modifier))[0] || 'top';
}
