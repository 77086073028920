<template>
  <div class="ds-detail-total-bar-closed-total">
    <ds-label class="ds-detail-total-bar-closed-total__label" :text="label"> </ds-label>
    <ds-currency-highlight
      class="ds-detail-total-bar-closed-total__value"
      :value="value"
      size="small"
      color="grey-dark">
    </ds-currency-highlight>
  </div>
</template>

<script>
import DsLabel from '@components/label';
import DsCurrencyHighlight from '@components/currency-highlight';

export default {
  name: 'DsDetailTotalBarClosedTotal',
  components: {
    DsLabel,
    DsCurrencyHighlight,
  },
  props: {
    label: String,
    value: Number,
  },
};
</script>

<style scoped>
@import './DetailTotalBarClosedTotal.css';
</style>
