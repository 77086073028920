import Vue from 'vue';

export function createStore() {
  const store = Vue.observable({
    items: {},
    activeItem: null,
    activeFavoriteMenu: false,
    favorites: [],
    favoritesLoaded: false,
  });

  function getActiveItem() {
    return store.activeItem;
  }

  function getActiveItemRootMenu() {
    return store.activeItem?.parentListIds[0];
  }

  function setActiveItem(item) {
    store.activeItem = item;
  }

  function isActiveItem(item) {
    return item.id === store.activeItem?.id;
  }

  function isParentOfActiveItem(item) {
    return !!store.activeItem?.parentListIds?.includes(item.id);
  }

  function shouldItemBeActive(item) {
    return isActiveItem(item) || isParentOfActiveItem(item);
  }

  function matchWith(item, key) {
    return typeof item.matchWith === 'function' ? item.matchWith(key) : item.matchWith.includes(key);
  }

  function getItemById(key) {
    return store.items[key] || Object.values(store.items).find(item => matchWith(item, key));
  }

  function getHrefMatches(key) {
    if (!key || typeof key !== 'string') {
      return [];
    }

    const items = Object.values(store.items);
    const itensMatchedByExactlyValue = [];
    const itensMatchedByPartialValue = [];

    items.forEach(item => {
      /** Ignore query params and microfrontends prefix */
      if (item.href === '/') {
        return;
      }
      if (item.href === key || matchWith(item, key)) {
        itensMatchedByExactlyValue.push(item);
      } else if (key.includes(item.href)) {
        itensMatchedByPartialValue.push(item);
      }
    });

    return [...itensMatchedByExactlyValue, ...itensMatchedByPartialValue];
  }

  function getItemByHref(href) {
    return getHrefMatches(href)[0];
  }

  function getItemBy(key, by = 'id') {
    return by === 'id' ? getItemById(key) : getItemByHref(key);
  }

  function register(item) {
    Vue.set(store.items, item.id, item);
  }

  function isItemDuplicated(item) {
    return getHrefMatches(item.href).length > 1;
  }

  function addFavorites(favorites) {
    store.favorites = favorites
      .map(favorite => {
        const item = Object.values(store.items).find(itemStore => itemStore.id === favorite);
        if (item) {
          item.isFavorite = true;
          return item;
        }
        return undefined;
      })
      .filter(Boolean);
  }

  function addFavorite(item) {
    store.favoritesLoaded = true;
    store.favorites = [...store.favorites, item];
  }

  function removeFavorite(item) {
    store.favoritesLoaded = true;
    store.favorites = store.favorites.filter(itemLocal => itemLocal.id !== item.id);
  }

  function isFavorite(id) {
    return store.favorites.some(item => item.id === id);
  }

  function hasFavorite() {
    return !!store.favorites.length;
  }

  function getFavorites() {
    return store.favorites;
  }

  function haveFavoritesLoaded() {
    return store.favoritesLoaded;
  }

  function getItens() {
    return store.items;
  }

  return {
    getItemBy,
    register,
    getActiveItem,
    setActiveItem,
    getActiveItemRootMenu,
    shouldItemBeActive,
    isParentOfActiveItem,
    isItemDuplicated,
    addFavorites,
    addFavorite,
    removeFavorite,
    isFavorite,
    getFavorites,
    hasFavorite,
    haveFavoritesLoaded,
    getItens,
  };
}
