<template>
  <div>
    <ds-table ref="table" class="ds-inline-table" :empty-state-message="emptyStateMessage">
      <ds-tr v-if="$slots['grouped-header']" slot="header">
        <slot name="grouped-header" />
        <ds-th
          v-if="shouldShowCustomActions"
          :style="{ width: actionHeaderWidth }"
          class="ds-inline-table__actions-column-header" />
        <ds-th v-if="shouldAllowRemoveItem" class="ds-inline-table__delete-column-header" />
      </ds-tr>
      <ds-tr slot="header">
        <slot name="header" />
        <ds-th
          v-if="shouldShowCustomActions"
          :style="{ width: actionHeaderWidth }"
          class="ds-inline-table__actions-column-header" />
        <ds-th v-if="shouldAllowRemoveItem" class="ds-inline-table__delete-column-header" />
      </ds-tr>
      <ds-inline-table-row
        v-for="(entry, index) in entries"
        ref="inlineTableRows"
        :key="entry.id"
        :entry="entry"
        :index="index"
        :custom-actions="customActions"
        :disabled="disabled"
        :allow-remove-item="shouldAllowRemoveItem"
        :remove-item-tooltip-text="removeItemTooltipText"
        :should-show-custom-actions="shouldShowCustomActions"
        @update-entry="onUpdateEntry"
        @delete-entry="onDeleteEntry"
        @update-entry-property="onUpdateEntryProperty"
        @update-actions-header-width="setActionsHeaderWidth">
        <slot :entry="entry" :index="index" />
      </ds-inline-table-row>
    </ds-table>
    <ds-row-divider margin-top="small" margin-bottom="medium" />
    <ds-row v-if="allowAddItems">
      <ds-col v-ds-tooltip="disabledButtonTooltip" :size="buttonColumnSize">
        <ds-button
          size="sm"
          icon="plus"
          icon-position="left"
          :disabled="!allowInsertNewRegistry"
          @click="insertNewRegistry">
          {{ buttonText }}
        </ds-button>
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual';
import DsTooltip from '@directives/tooltip';
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsTh from '@components/table-header';
import DsTr from '@components/table-row';
import DsTable from '@components/table';
import DsRowDivider from '@components/row-divider';
import DsButton from '@components/button';
import { generateId } from '@core/services/id/idService';
import { isComponent, getPropsData } from '@core/services/vnode/vnodeService';
import { modelValidationService } from '@core';
import DsInlineTableRow from './InlineTableRow.vue';

const mapPreviousValues = previousValue =>
  previousValue.map(value => ({
    ...value,
    id: generateId(),
  }));

const removeIdFromEntry = entry => {
  const localEntry = { ...entry };
  delete localEntry.id;
  return localEntry;
};

const removeIdFromEntries = entries => entries.map(removeIdFromEntry);

const isEntriesEmpty = entries => entries.length === 0;

export default {
  name: 'DsInlineTable',
  components: {
    DsRow,
    DsCol,
    DsButton,
    DsInlineTableRow,
    DsRowDivider,
    DsTable,
    DsTh,
    DsTr,
  },
  directives: {
    DsTooltip,
  },
  inject: {
    formVm: {
      default: null,
    },
  },
  props: {
    emptyStateMessage: DsTable.props.emptyStateMessage,
    buttonText: {
      type: String,
      required: true,
    },
    buttonColumnSize: {
      type: String,
      default: 'auto',
    },
    minRequiredLines: {
      type: Number,
      default: 1,
    },
    maxLines: {
      type: Number,
      default: null,
    },
    disabledButtonTooltipText: {
      type: String,
      default: null,
    },
    removeItemTooltipText: {
      type: String,
    },
    value: {
      type: Array,
      default: () => [],
    },
    customActions: {
      type: Array,
      default: () => [],
    },
    beforeRemove: {
      type: Function,
      default: () => Promise.resolve(true),
    },
    allowAddItems: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      inlineTableVm: {
        isFieldRequired: this.isFieldRequired,
        updateAllFieldsRequiredStatus: this.updateAllFieldsRequiredStatus,
        isHeaderRegistered: this.isHeaderRegistered,
        registerHeader: this.registerHeader,
        unregisterHeader: this.unregisterHeader,
      },
    };
  },
  data() {
    const entries = isEntriesEmpty(this.value) ? this.getInlineTableInitialState() : mapPreviousValues(this.value);
    return {
      entries,
      fields: {},
      actionsHeader: {
        width: null,
        padding: null,
      },
      modelValidator: modelValidationService.buildModelValidation(this),
      disabled: false,
    };
  },
  computed: {
    disabledButtonTooltip() {
      const tooltipText = this.disabledButtonTooltipText ?? 'O número máximo de registros foi atingido';
      return this.allowInsertNewRegistry ? null : tooltipText;
    },
    allowInsertNewRegistry() {
      return !this.maxLines || this.entries.length < this.maxLines;
    },
    shouldAllowRemoveItem() {
      return this.entries.length > this.minRequiredLines;
    },
    registeredHeaders() {
      return Object.values(this.fields).map(field => field.headerId);
    },
    customValidations() {
      const rules = [{ valid: value => value.length >= this.minRequiredLines }];

      if (this.maxLines) {
        rules.push(this.getMaxLinesRule());
      }

      return rules;
    },
    shouldShowCustomActions() {
      return !!(this.customActions && this.customActions.length);
    },
    actionHeaderWidth() {
      return this.actionsHeader.width ? `${this.actionsHeader.width + this.actionsHeader.padding}px` : 'auto';
    },
    emptyRows() {
      return !!this.entries?.length;
    },
  },
  watch: {
    entries: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit('input', removeIdFromEntries(this.entries));
        this.$emit('change', removeIdFromEntries(this.entries));
      },
    },
    value: {
      deep: true,
      handler() {
        const entriesWithoutId = removeIdFromEntries(this.entries);
        if (!isEqual(this.value, entriesWithoutId)) {
          const updatedEntries = [];
          for (let index = 0; index < this.value.length; index++) {
            const id = this.entries[index]?.id ?? generateId();
            updatedEntries[index] = {
              id,
              ...this.value[index],
            };
          }
          this.entries = updatedEntries;
        }
      },
    },
    emptyRows() {
      this.updateTableTotalColumns();
    },
  },
  created() {
    this.modelValidator._setRules(this.customValidations);
    this.modelValidator._setHasBeenBlured(true);
  },
  mounted() {
    this.updateFieldsMap();
  },
  methods: {
    getMaxLinesRule() {
      return { valid: value => value.length <= this.maxLines };
    },
    getInlineTableInitialState() {
      return new Array(this.minRequiredLines).fill().map(() => ({ ...this.getSchema() }));
    },
    updateFieldsMap() {
      const fields = {};
      const defaultSlot = this.$slots.default ?? this.$scopedSlots.default();
      const headers = this.$scopedSlots.header().filter(vnode => isComponent(vnode, 'DsInlineTableTh'));
      const columns = defaultSlot.filter(vnode => isComponent(vnode, 'DsInlineTableTd'));
      headers.forEach((header, index) => {
        const { required } = getPropsData(header);
        const headerId = header.key;
        const isRequired = !!required || required === '';
        const { id: fieldId } = getPropsData(columns[index]);
        fields[fieldId] = { required: isRequired, headerId };
      });
      this.fields = fields;
    },
    isFieldRequired(fieldId) {
      return this.fields[fieldId]?.required;
    },
    async insertNewRegistry() {
      if (this.allowInsertNewRegistry) {
        this.entries.push({ ...this.getSchema() });
        await this.$nextTick();
        this.getLastInlineTableRowRef().focus();
      }
    },
    getSchema() {
      return {
        id: generateId(),
      };
    },
    getEntryIndexById(id) {
      return this.entries.findIndex(value => value.id === id);
    },
    onUpdateEntry({ id, data }) {
      const index = this.getEntryIndexById(id);
      const updatedEntries = [...this.entries];
      updatedEntries[index] = {
        id,
        ...data,
      };
      this.entries = [...updatedEntries];
    },
    async onDeleteEntry(id) {
      this.disabled = true;
      try {
        const index = this.getEntryIndexById(id);
        if ((await this.beforeRemove(index)) !== false) {
          const item = this.entries[index];

          this.entries.splice(index, 1);
          this.emitDeleteEntryEvent(item, index);
        }
      } catch (error) {
        this.$emit('remove-error', error);
      } finally {
        this.disabled = false;
      }
    },
    onUpdateEntryProperty(params) {
      this.$nextTick(() => {
        this.$emit('update-entry-property', params);
      });
    },
    updateAllFieldsRequiredStatus() {
      this.updateFieldsMap();
      const { inlineTableRows } = this.$refs;
      inlineTableRows.forEach(this.updateEachColumnRequiredStatus);
    },
    updateEachColumnRequiredStatus(inlineTableRowVm) {
      const columnsVms = Object.values(inlineTableRowVm.inlineTableColumnsVms);
      columnsVms.forEach(columnVm => {
        columnVm.setFieldRequiredStatus();
      });
    },
    unregisterHeader(headerId) {
      if (this.$refs.inlineTableRows) {
        this.$refs.inlineTableRows.forEach(row => {
          row.deleteProperty(this.getFieldIdByHeader(headerId));
        });
      }
    },
    getLastInlineTableRowRef() {
      return this.$refs.inlineTableRows[this.$refs.inlineTableRows.length - 1];
    },
    getFieldIdByHeader(headerId) {
      return Object.keys(this.fields).find(key => this.fields[key].headerId === headerId);
    },
    isHeaderRegistered(id) {
      return !!this.registeredHeaders.includes(id);
    },
    registerHeader() {
      this.updateAllFieldsRequiredStatus();
    },
    setActionsHeaderWidth({ width, padding }) {
      this.actionsHeader = { width, padding };
    },
    emitDeleteEntryEvent(item, itemIndex) {
      this.$emit('delete-entry', {
        row: item,
        rowIndex: itemIndex,
      });
    },
    updateTableTotalColumns() {
      this.$nextTick(() => {
        this.$refs.table.setTotalColumns();
      });
    },
  },
};
</script>

<style scoped>
@import './InlineTable.css';
</style>
