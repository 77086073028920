import Tagify from '@yaireo/tagify';
import { modelValidationService } from '@core';

const TAGS_ELEMENT_CLASS = 'tagify';
const INVALID_CLASS = 'tagify--not-allowed';
const INPUT_HEIGHT = 40;

const VALIDATION_TEXTS = {
  REQUIRED: 'Campo obrigatório',
  DUPLICATED: 'Por favor, remova os valores duplicados',
};

function setHelperTexts(tagify) {
  tagify.TEXTS = {
    empty: 'Vazio',
    exceed: 'Número de valores excedido',
    pattern: 'Valor inválido',
    duplicate: 'Valor já existente',
    notAllowed: 'Valor não permitido',
  };
}

function setFocusInput(tagify) {
  tagify.focus = () => {
    setTimeout(() => {
      tagify.DOM.input.focus();
    });
  };
}

export function addInvalidClassOn(el) {
  el.classList.add(INVALID_CLASS);
}

export function removeInvalidClassFrom(el) {
  el.classList.forEach(() => {
    el.classList.remove(INVALID_CLASS);
  });
}

export function isContentHeightGreaterThanInput(el) {
  return el.scrollHeight > INPUT_HEIGHT;
}

export function getTagsEl(el) {
  return el.querySelector(`.${TAGS_ELEMENT_CLASS}`);
}

export function getTextFromTagEl(el) {
  return el.getAttribute('value');
}

function getRequiredRule(required) {
  const rule = {
    valid: tag => tag.length > 0,
    message: VALIDATION_TEXTS.REQUIRED,
  };
  return required ? rule : null;
}

export function buildModelValidator(vm) {
  return modelValidationService.buildModelValidation(vm, {
    requiredRule: getRequiredRule(vm.required),
    customValidations: [getDuplicatedItensRule(vm.valueProp)],
  });
}

function getDuplicatedItensRule(valueProp) {
  return {
    valueProp,
    message: VALIDATION_TEXTS.DUPLICATED,
    valid: checkDuplicatedItens,
  };
}

function checkDuplicatedItens(list) {
  return list.every(value => !isValueDuplicated(list, value, this.valueProp));
}

export function isValueDuplicated(list, value, valueProp) {
  const ocurrences = list.filter(listItem => {
    if (valueProp) {
      return value[valueProp] === listItem[valueProp];
    }
    return value === listItem;
  });
  return ocurrences.length > 1;
}

export function syncModelWithInput({
  valueProp,
  modelList,
  inputStringModel,
  tagify,
  removeInvalidItemByValue,
  setProgrammaticChangingHappening,
}) {
  setProgrammaticChangingHappening(true);
  const inputValuesList = getValuesFromString(inputStringModel);
  const modelItems = modelList.map(item => (valueProp ? item[valueProp] : item) || item);
  const newValues = getDiffFrom(modelItems, inputValuesList);
  const removedValues = getDiffFrom(inputValuesList, modelItems);

  tagify.removeTags(removedValues);
  tagify.addTags(newValues);

  removedValues.forEach(value => {
    removeInvalidItemByValue(value);
  });

  setProgrammaticChangingHappening(false);
}

export function getValuesFromString(inputValuesString) {
  if (!inputValuesString) {
    return [];
  }

  const inputValues = JSON.parse(inputValuesString);

  return inputValues.map(tag => tag.value);
}

function getDiffFrom(listOne, listTwo) {
  return listOne.reduce((acc, value) => {
    if (!listTwo.includes(value)) {
      acc.push(value);
    }

    return acc;
  }, []);
}

export function isFirstOccurenceOf(value, index, list) {
  return list.indexOf(value) === index;
}

export function buildTagify(vm) {
  const tagify = new Tagify(vm.$refs.input, {
    backspace: true,
    keepInvalidTags: true,
    skipInvalid: false,
    duplicates: true,
    editTags: false,
    userInput: vm.$props.userInput,
    hooks: {
      beforeRemoveTag: ([tag]) => vm.beforeRemoveValue(tag.idx),
    },
    callbacks: {
      add(e) {
        const { data, tag, index } = e.detail;
        const { value: newValue } = data;

        vm.addTagOnModel(tag, newValue, index);

        vm.emitEvent('change', { value: newValue, el: tag });
      },
      remove(e) {
        const { data, tag } = e.detail;
        const value = data ? data.value : getTextFromTagEl(tag);

        if (!this.isProgramaticChangingHappening) {
          vm.removeItemByValue(value);
          vm.removeInvalidItemByValue(value);
        }

        vm.emitEvent('change', { value, el: tag });
      },
      click() {
        vm.isFocused = true;
      },
      input(e) {
        const { value } = e.detail;

        vm.emitEvent('input', { value });
      },
      focus() {
        vm.isFocused = true;
        vm.emitEvent('focus');
      },
      blur() {
        vm.emitEvent('blur');
      },
      // eslint-disable-next-line func-names
      'edit:updated': function (e) {
        const { previousData, data, tag, index } = e.detail;
        const { value: oldValue } = previousData;
        const { value: newValue } = data;

        if (newValue === oldValue) {
          vm.updateInvalidStyle();
          vm.updateHasLineBreak();
          return;
        }

        vm.replaceValue(index, newValue);

        vm.emitEvent('change', { el: tag, value: newValue, oldValue });
      },
    },
  });

  setFocusInput(tagify);
  setHelperTexts(tagify);

  return tagify;
}
