<template>
  <div :class="{ 'is-active': active, 'is-disabled': disabled }" class="ds-pagination-item" @click="onClick">
    <a class="ds-pagination-item-content">
      <slot>
        {{ page }}
      </slot>
    </a>
  </div>
</template>

<script>
export default {
  name: 'DsPaginationItem',
  props: {
    active: Boolean,
    disabled: Boolean,
    page: Number,
  },
  methods: {
    onClick(event) {
      if (!this.disabled) {
        this.$emit('click', event);
      }
    },
    activate() {
      this.$emit('active', this.page);
    },
  },
};
</script>

<style scoped>
@import './PaginationItem.css';
</style>
