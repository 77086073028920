<template>
  <ds-radio-group
    class="ds-slimbox-radio-group"
    :class="fullHeight ? 'ds-slimbox-radio-group--full-height' : ''"
    v-bind="$attrs"
    direction="horizontal"
    v-on="$listeners">
    <slot />
  </ds-radio-group>
</template>

<script>
import DsRadioGroup from '@components/radio-group';

export default {
  name: 'DsSlimboxRadioGroup',
  components: {
    DsRadioGroup,
  },
  props: {
    fullHeight: Boolean,
  },
};
</script>

<style scoped>
@import 'SlimboxRadioGroup.css';
</style>
