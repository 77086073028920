<template>
  <div
    :class="{ 'ds-date-period-dropdown--suggestion-shown': shouldShowSuggestionButton }"
    class="ds-date-period-dropdown">
    <ds-dropdown
      ref="dropdown"
      full-width
      :class="[
        modelValidator.cssClass,
        {
          'ds-date-period-dropdown--suggestion-accepted': isSuggestionAccepted && shouldShowSuggestionButton,
        },
      ]"
      :disabled="disabled"
      :title="title"
      :tooltip="tooltip"
      @blur="$emit('blur')"
      @click="onClick">
      <input ref="datepickerAnchor" class="ds-date-period-dropdown--datepicker-input" />
      <ds-dropdown-item
        v-for="periodTypeOption in periodTypeOptions"
        :key="periodTypeOption.value"
        :value="periodTypeOption.value"
        @click="onSelectPeriodType(periodTypeOption.value)">
        {{ periodTypeOption.label }}
      </ds-dropdown-item>
    </ds-dropdown>
    <ds-input-icons>
      <ds-input-clear-icon v-if="hasValue && !hideClearButton && !disabled" @click="clear" />
      <ds-suggestion-button
        v-if="shouldShowSuggestionButton"
        :is-suggestion-accepted="isSuggestionAccepted"
        @click="onApplySuggestion" />
    </ds-input-icons>
  </div>
</template>

<script>
import { INPUT_ERRORS } from '@core/constants';
import { modelValidationService, focusMixin, dateService, dateParser } from '@core';
import DsInputClearIcon from '@components/input-clear-icon';
import DsDropdown from '@components/dropdown';
import DsDropdownItem from '@components/dropdown-item';
import DsInputIcons from '@components/input-icons/InputIcons.vue';
import DsSuggestionButton from '@components/suggestion-button/SuggestionButton.vue';
import { Datepicker } from '@components/date-input/datepicker';
import DsDatePeriodPopover from './DatePeriodPopover.vue';
import {
  getPeriodRangeByType,
  getPeriodTitle,
  getPeriodTypeOptions,
  getDefaultPeriodTypeKeys,
} from './datePeriodDropdownService';
import { getPeriodTypeByKey, DATE_PERIOD_TYPES } from './datePeriodService';

export default {
  name: 'DsDatePeriodDropdown',
  inject: {
    hideClearButton: {
      default: false,
    },
    fieldVm: {
      default: null,
    },
    formVm: {
      default: null,
    },
  },
  components: {
    DsDropdown,
    DsDropdownItem,
    DsInputIcons,
    DsInputClearIcon,
    DsSuggestionButton,
  },
  mixins: [focusMixin.focus('dropdown')],
  props: {
    minDate: DsDatePeriodPopover.props.minDate,
    maxDate: DsDatePeriodPopover.props.maxDate,
    maxDateRange: DsDatePeriodPopover.props.maxDateRange,
    periodPopoverHelperText: DsDatePeriodPopover.props.periodPopoverHelperText,
    disabled: Boolean,
    required: Boolean,
    /**
     * { startDate, endDate }
     */
    value: {
      type: Object,
    },
    periodTypes: {
      type: Array,
      default: () => getDefaultPeriodTypeKeys(),
    },
    /**
     * @values today, this-week, this-month, this-year, last-thirty-days, custom-month, last-year, all-period, custom-period
     */
    periodType: {
      type: String,
      validator: getPeriodTypeByKey,
    },
    suggestion: {
      type: Object,
      default: null,
    },
    tooltip: {
      type: String,
    },
  },
  data() {
    return {
      startDate: this.value?.startDate,
      endDate: this.value?.endDate,
      selectedPeriodType: null,
      modelValidator: modelValidationService.buildModelValidation(this, {
        requiredRule: {
          valid: () => this.hasValue,
          message: INPUT_ERRORS.REQUIRED.MESSAGE,
        },
      }),
      datepicker: null,
    };
  },
  computed: {
    isValidSuggestion() {
      return (
        this.suggestion &&
        (this.suggestion.startDate || this.suggestion.startDate === null) &&
        (this.suggestion.endDate || this.suggestion.endDate === null)
      );
    },
    isSuggestionAccepted() {
      return (
        this.isValidSuggestion &&
        this.value &&
        this.suggestion.startDate === this.value.startDate &&
        this.suggestion.endDate === this.value.endDate
      );
    },
    shouldShowSuggestionButton() {
      return this.isValidSuggestion && !this.disabled;
    },
    hasValue() {
      return (this.startDate || this.startDate === null) && (this.endDate || this.endDate === null);
    },
    title() {
      return getPeriodTitle(this.startDate, this.endDate);
    },
    periodTypeOptions() {
      const periodTypes = this.periodTypes.includes(this.periodType)
        ? this.periodTypes
        : [...this.periodTypes, this.periodType];

      return getPeriodTypeOptions({
        setPeriodByType: this.setPeriodByType,
        periodTypes,
        openPopover: this.openPopover,
        openDatepicker: this.openDatepicker,
      });
    },
    shouldConfigDatepicker() {
      return this.periodTypes.includes(DATE_PERIOD_TYPES.CUSTOM_MONTH.key);
    },
  },
  watch: {
    periodType: {
      immediate: true,
      handler(periodType) {
        if (periodType) {
          this.onSelectPeriodType(this.periodType);
        }
      },
    },
    'value.startDate': {
      handler(startDate) {
        this.startDate = startDate;
      },
    },
    'value.endDate': {
      handler(endDate) {
        this.endDate = endDate;
      },
    },
    value: {
      immediate: true,
      deep: true,
      handler(value) {
        /**
         * On change value
         * @property {Object} value
         */
        this.$emit('change', value);
      },
    },
  },
  mounted() {
    this.initSuggestionValue();
  },
  methods: {
    openPopover() {
      if (this.$el) {
        this.popover = this.$dsPopover(DsDatePeriodPopover, {
          props: {
            onApply: this.setPeriod,
            startDate: this.startDate,
            endDate: this.endDate,
            minDate: this.minDate,
            maxDate: this.maxDate,
            maxDateRange: this.maxDateRange,
            helperText: this.periodPopoverHelperText,
          },
        }).open(this.$el, {
          placement: 'bottom-start',
        });
      }
    },
    setPeriodByType(periodType) {
      this.setPeriod(getPeriodRangeByType(periodType));
    },
    setPeriod({ startDate, endDate }) {
      this.startDate = startDate;
      this.endDate = endDate;
      /**
       * On input value
       * @property {Object} value
       * @property {String} selectedPeriodType
       */
      this.$emit('input', { startDate, endDate }, this.selectedPeriodType);
    },
    clear() {
      this.setPeriod({
        startDate: '',
        endDate: '',
      });
    },
    onClick() {
      if (this.shouldConfigDatepicker && !this.datepicker) {
        this.$nextTick(() => {
          this.configDatepicker();
        });
      }

      if (this.popover) {
        this.popover.close();
      }
    },
    getPeriodTypeOptionByType(periodType) {
      return this.periodTypeOptions.find(periodTypeOption => periodTypeOption.value === periodType);
    },
    onSelectPeriodType(periodType) {
      this.selectedPeriodType = periodType;
      this.getPeriodTypeOptionByType(periodType).action(periodType);
    },
    initSuggestionValue() {
      if (this.isValidSuggestion && !this.value) {
        this.onApplySuggestion();
      }
    },
    onApplySuggestion() {
      this.setPeriod(this.suggestion);

      /**
       * After apply suggestion
       * @property {Object} suggestion
       */
      this.$emit('suggestion-applied', this.suggestion);
    },
    configDatepicker() {
      this.datepicker = new Datepicker(this.$refs.datepickerAnchor, {
        type: 'monthFull',
        onChange: this.onDatepickerChange,
      });

      this.$once('hook:beforeDestroy', () => {
        this.datepicker.destroy();
      });
    },
    onDatepickerChange([date]) {
      this.setPeriodWithDatepickerMonth(date);
    },
    openDatepicker() {
      this.datepicker.open();
    },
    setPeriodWithDatepickerMonth(date) {
      const DATE_FORMAT = 'YYYY-MM-DD';
      const startOfMonth = dateParser.formatDate(date, undefined, DATE_FORMAT);
      const endOfMonth = dateParser.formatDate(dateService.getLastDayOfTheMonth(startOfMonth), undefined, DATE_FORMAT);

      this.setPeriod({
        startDate: startOfMonth,
        endDate: endOfMonth,
      });
    },
  },
};
</script>

<style>
@import './DatePeriodDropdown.css';
</style>
