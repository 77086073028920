import { library } from '@fortawesome/fontawesome-svg-core';

import {
  faAlicorn as fasAlicorn,
  faAnalytics as fasAnalytics,
  faAngleDown as fasAngleDown,
  faAngleLeft as fasAngleLeft,
  faAngleRight as fasAngleRight,
  faArchive as fasArchive,
  faArrowCircleDown as fasArrowCircleDown,
  faArrowCircleUp as fasArrowCircleUp,
  faArrowLeft as fasArrowLeft,
  faArrowRight as fasArrowRight,
  faArrowRotateRight as fasArrowRotateRight,
  faArrowsFromLine as fasArrowsFromLine,
  faArrowsToLine as fasArrowsToLine,
  faArrowToTop as fasArrowToTop,
  faArrowUp as fasArrowUp,
  faArrowUpFromSquare as fasArrowUpFromSquare,
  faBadgeCheck as fasBadgeCheck,
  faBarcode as fasBarcode,
  faBars as fasBars,
  faBell as fasBell,
  faBolt as fasBolt,
  faBoxesAlt as fasBoxesAlt,
  faCalendar as fasCalendar,
  faCashRegister as fasCashRegister,
  faCatSpace as fasCatSpace,
  faCheck as fasCheck,
  faCheckCircle as fasCheckCircle,
  faChevronCircleDown as fasChevronCircleDown,
  faChevronCircleUp as fasChevronCircleUp,
  faChevronDown as fasChevronDown,
  faChevronLeft as fasChevronLeft,
  faChevronRight as fasChevronRight,
  faChevronUp as fasChevronUp,
  faCircle as fasCircle,
  faClipboardListCheck as fasClipboardListCheck,
  faClone as fasClone,
  faComments as fasComments,
  faCopy as fasCopy,
  faCreditCardFront as fasCreditCardFront,
  faDollarSign as fasDollarSign,
  faDollyFlatbed as fasDollyFlatbed,
  faDoorClosed as fasDoorClosed,
  faDoorOpen as fasDoorOpen,
  faDownload as fasDownload,
  faEdit as fasEdit,
  faEllipsisH as fasEllipsisH,
  faEnvelope as fasEnvelope,
  faEquals as fasEquals,
  faExclamationCircle as fasExclamationCircle,
  faExternalLink as fasExternalLink,
  faExternalLinkAlt as fasExternalLinkAlt,
  faEye as fasEye,
  faEyeSlash as fasEyeSlash,
  faFile as fasFile,
  faFileAlt as fasFileAlt,
  faFileCheck as fasFileCheck,
  faFileExport as fasFileExport,
  faFileInvoice as fasFileInvoice,
  faFrown as fasFrown,
  faGift as fasGift,
  faGripDotsVertical as fasGripDotsVertical,
  faHandHoldingUsd as fasHandHoldingUsd,
  faHandshake as fasHandshake,
  faHandshakeAlt as fasHandshakeAlt,
  faHome as fasHome,
  faHouse as fasHouse,
  faInfoCircle as fasInfoCircle,
  faLink as fasLink,
  faListAlt as fasListAlt,
  faLock as fasLock,
  faLongArrowLeft as fasLongArrowLeft,
  faLongArrowRight as fasLongArrowRight,
  faMaximize as fasMaximize,
  faMeh as fasMeh,
  faMessageDollar as fasMessageDollar,
  faMinimize as fasMinimize,
  faMinus as fasMinus,
  faMoon as fasMoon,
  faMobileRetro as fasMobileRetro,
  faMoneyBillWave as fasMoneyBillWave,
  faMoneyCheck as fasMoneyCheck,
  faPaperclip as fasPaperclip,
  faPaperPlane as fasPaperPlane,
  faPen as fasPen,
  faPencil as fasPencil,
  faPhone as fasPhone,
  faPlay as fasPlay,
  faPlayCircle as fasPlayCircle,
  faPlus as fasPlus,
  faPlusCircle as fasPlusCircle,
  faPrint as fasPrint,
  faQuestionCircle as fasQuestionCircle,
  faRepeat as fasRepeat,
  faRetweet as fasRetweet,
  faRotateLeft as fasRotateLeft,
  faSave as fasSave,
  faSearch as fasSearch,
  faShoppingBasket as fasShoppingBasket,
  faSmile as fasSmile,
  faSort as fasSort,
  faSortAmountDown as fasSortAmountDown,
  faSortAmountUp as fasSortAmountUp,
  faSparkles as fasSparkles,
  faSpinner as fasSpinner,
  faSquare as fasSquare,
  faStar as fasStar,
  faStopwatch as fasStopwatch,
  faSync as fasSync,
  faTags as fasTags,
  faThumbsDown as fasThumbsDown,
  faThumbsUp as fasThumbsUp,
  faTimes as fasTimes,
  faTimesCircle as fasTimesCircle,
  faTrash as fasTrash,
  faTrashAlt as fasTrashAlt,
  faUndo as fasUndo,
  faUnlock as fasUnlock,
  faUsdCircle as fasUsdCircle,
  faUser as fasUser,
  faUserGroup as fasUserGroup,
  faUsers as fasUsers,
  faVideo as fasVideo,
  faWalking as fasWalking,
  faWarehouse as fasWarehouse,
  faWrench as fasWrench,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faAlarmClock as farAlarmClock,
  faAngleLeft as farAngleLeft,
  faAngleRight as farAngleRight,
  faArrowRightFromBracket as farArrowRightFromBracket,
  faCalendar as farCalendar,
  faArrowDown as farArrowDown,
  faArrowDownLeftAndArrowUpRightToCenter as farArrowDownLeftAndArrowUpRightToCenter,
  faArrowsRetweet as farArrowsRetweet,
  faArrowUp as farArrowUp,
  faArrowUpArrowDown as farArrowUpArrowDown,
  faArrowUpFromBracket as farArrowUpFromBracket,
  faArrowUpRightAndArrowDownLeftFromCenter as farArrowUpRightAndArrowDownLeftFromCenter,
  faCartFlatbed as farCartFlatbed,
  faCashRegister as farCashRegister,
  faCatSpace as farCatSpace,
  faChartLine as farChartLine,
  faCheck as farCheck,
  faChevronCircleDown as farChevronCircleDown,
  faChevronCircleUp as farChevronCircleUp,
  faCircleDollar as farCircleDollar,
  faCircleInfo as farCircleInfo,
  faClipboardListCheck as farClipboardListCheck,
  faComment as farComment,
  faEnvelope as farEnvelope,
  faExternalLink as farExternalLink,
  faFileCircleCheck as farFileCircleCheck,
  faGear as farGear,
  faGift as farGift,
  faGrid2 as fasGrid2,
  faHandshake as farHandshake,
  faHouseWindow as farHouseWindow,
  faLink as farLink,
  faMagnifyingGlassMinus as farMagnifyingGlassMinus,
  faMagnifyingGlassPlus as farMagnifyingGlassPlus,
  faMoon as farMoon,
  faPen as farPen,
  faRectangleBarcode as farRectangleBarcode,
  faRepeat as farRepeat,
  faSearch as farSearch,
  faSquare as farSquare,
  faStar as farStar,
  faStore as farStore,
  faThumbsUp as farThumbsUp,
  faTrash as farTrash,
  faUser as farUser,
  faUserGroup as farUserGroup,
  faUserLarge as farUserLarge,
  faXmarkLarge as farXmarkLarge,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faArrowDownToLine as falArrowDownToLine,
  faArrowRightFromBracket as falArrowRightFromBracket,
  faArrowUpRightFromSquare as falArrowUpRightFromSquare,
  faBarcodeAlt as falBarcodeAlt,
  faBoxOpen as falBoxOpen,
  faBriefcase as falBriefcase,
  faCalendar as falCalendar,
  faCashRegister as falCashRegister,
  faChartLine as falChartLine,
  faCheckCircle as falCheckCircle,
  faChevronDown as falChevronDown,
  faChevronUp as falChevronUp,
  faClockRotateLeft as falClockRotateLeft,
  faCommentAltDots as falCommentAltDots,
  faCommentCheck as falCommentCheck,
  faCommentLines as falCommentLines,
  faCreditCard as falCreditCard,
  faDollarSign as falDollarSign,
  faEnvelope as falEnvelope,
  faEnvelopeOpenText as falEnvelopeOpenText,
  faExclamationCircle as falExclamationCircle,
  faFileExcel as falFileExcel,
  faFileExclamation as falFileExclamation,
  faFileInvoiceDollar as falFileInvoiceDollar,
  faFilePdf as falFilePdf,
  faFileTimes as falFileTimes,
  faGear as falGear,
  faGift as falGift,
  faHandshake as falHandshake,
  faImage as falImage,
  faLink as falLink,
  faMoon as falMoon,
  faMessageDollar as falMessageDollar,
  faMobileAndroid as falMobileAndroid,
  faPaperPlane as falPaperPlane,
  faPaperclip as falPaperclip,
  faReceipt as falReceipt,
  faRepeat as falRepeat,
  faRetweet as falRetweet,
  faStore as falStore,
  faTable as falTable,
  faTags as falTags,
  faTimes as falTimes,
  faTrash as falTrash,
  faUniversity as falUniversity,
  faUser as falUser,
  faUserGroup as falUserGroup,
  faWindowMaximize as falWindowMaximize,
} from '@fortawesome/pro-light-svg-icons';

import {
  faCatSpace as fadCatSpace,
  faSortDown as fadSortDown,
  faSortUp as fadSortUp,
  faUser as fadUser,
} from '@fortawesome/pro-duotone-svg-icons';

import {
  faFacebook as fabFacebook,
  faInstagram as fabInstagram,
  faLinkedin as fabLinkedin,
  faPix as fabPix,
  faTwitter as fabTwitter,
  faWhatsapp as fabWhatsapp,
  faYoutube as fabYoutube,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  /** Solid */
  fasAlicorn,
  fasAnalytics,
  fasAngleDown,
  fasAngleLeft,
  fasAngleRight,
  fasArchive,
  fasArrowCircleDown,
  fasArrowCircleUp,
  fasArrowLeft,
  fasArrowRight,
  fasArrowRotateRight,
  fasArrowsFromLine,
  fasArrowsToLine,
  fasArrowToTop,
  fasArrowUp,
  fasArrowUpFromSquare,
  fasBadgeCheck,
  fasBarcode,
  fasBars,
  fasBell,
  fasBolt,
  fasBoxesAlt,
  fasCalendar,
  fasCashRegister,
  fasCatSpace,
  fasCheck,
  fasCheckCircle,
  fasChevronCircleDown,
  fasChevronCircleUp,
  fasChevronDown,
  fasChevronLeft,
  fasChevronRight,
  fasChevronUp,
  fasCircle,
  fasClipboardListCheck,
  fasClone,
  fasComments,
  fasCopy,
  fasCreditCardFront,
  fasDollarSign,
  fasDollyFlatbed,
  fasDoorClosed,
  fasDoorOpen,
  fasDownload,
  fasEdit,
  fasEllipsisH,
  fasEnvelope,
  fasEquals,
  fasExclamationCircle,
  fasExternalLink,
  fasExternalLinkAlt,
  fasEye,
  fasEyeSlash,
  fasFile,
  fasFileAlt,
  fasFileCheck,
  fasFileExport,
  fasFileInvoice,
  fasFrown,
  fasGift,
  fasGripDotsVertical,
  fasHandHoldingUsd,
  fasHandshake,
  fasHandshakeAlt,
  fasHome,
  fasHouse,
  fasInfoCircle,
  fasLink,
  fasListAlt,
  fasLock,
  fasLongArrowLeft,
  fasLongArrowRight,
  fasMaximize,
  fasMeh,
  fasMessageDollar,
  fasMinimize,
  fasMinus,
  fasMoon,
  fasMobileRetro,
  fasMoneyBillWave,
  fasMoneyCheck,
  fasPaperclip,
  fasPaperPlane,
  fasPen,
  fasPencil,
  fasPhone,
  fasPlay,
  fasPlayCircle,
  fasPlus,
  fasPlusCircle,
  fasPrint,
  fasQuestionCircle,
  fasRepeat,
  fasRetweet,
  fasRotateLeft,
  fasSave,
  fasSearch,
  fasShoppingBasket,
  fasSmile,
  fasSort,
  fasSortAmountDown,
  fasSortAmountUp,
  fasSparkles,
  fasSpinner,
  fasSquare,
  fasStar,
  fasStopwatch,
  fasSync,
  fasTags,
  fasThumbsDown,
  fasThumbsUp,
  fasTimes,
  fasTimesCircle,
  fasTrash,
  fasTrashAlt,
  fasUndo,
  fasUnlock,
  fasUsdCircle,
  fasUser,
  fasUserGroup,
  fasUsers,
  fasVideo,
  fasWalking,
  fasWarehouse,
  fasWrench,

  /** Regular */
  farAlarmClock,
  farAngleLeft,
  farAngleRight,
  farArrowRightFromBracket,
  farCalendar,
  farArrowDown,
  farArrowDownLeftAndArrowUpRightToCenter,
  farArrowsRetweet,
  farArrowUp,
  farArrowUpFromBracket,
  farArrowUpRightAndArrowDownLeftFromCenter,
  farArrowUpArrowDown,
  farCartFlatbed,
  farCashRegister,
  farCatSpace,
  farChartLine,
  farCheck,
  farChevronCircleDown,
  farChevronCircleUp,
  farCircleDollar,
  farCircleInfo,
  farClipboardListCheck,
  farComment,
  farEnvelope,
  farExternalLink,
  farFileCircleCheck,
  farGear,
  farGift,
  fasGrid2,
  farHandshake,
  farHouseWindow,
  farLink,
  farMagnifyingGlassMinus,
  farMagnifyingGlassPlus,
  farMoon,
  farPen,
  farRectangleBarcode,
  farRepeat,
  farSearch,
  farSquare,
  farStar,
  farStore,
  farTrash,
  farThumbsUp,
  farUser,
  farUserGroup,
  farUserLarge,
  farXmarkLarge,

  /** Light */
  falArrowDownToLine,
  falArrowRightFromBracket,
  falArrowUpRightFromSquare,
  falBarcodeAlt,
  falBoxOpen,
  falBriefcase,
  falCalendar,
  falCashRegister,
  falChartLine,
  falCheckCircle,
  falChevronDown,
  falChevronUp,
  falClockRotateLeft,
  falCommentAltDots,
  falCommentCheck,
  falCommentLines,
  falCreditCard,
  falDollarSign,
  falEnvelope,
  falEnvelopeOpenText,
  falExclamationCircle,
  falFileExcel,
  falFileExclamation,
  falFileInvoiceDollar,
  falFilePdf,
  falFileTimes,
  falGear,
  falGift,
  falHandshake,
  falImage,
  falLink,
  falMessageDollar,
  falMoon,
  falMobileAndroid,
  falPaperclip,
  falPaperPlane,
  falReceipt,
  falRepeat,
  falRetweet,
  falStore,
  falTable,
  falTags,
  falTimes,
  falTrash,
  falUniversity,
  falUser,
  falUserGroup,
  falWindowMaximize,

  /** Duotone */
  fadCatSpace,
  fadSortDown,
  fadSortUp,
  fadUser,

  /** Brands */
  fabFacebook,
  fabInstagram,
  fabLinkedin,
  fabPix,
  fabTwitter,
  fabWhatsapp,
  fabYoutube,
);
