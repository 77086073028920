const registerPlugins = (Vue, plugins = []) => {
  plugins.forEach(plugin => {
    if (typeof plugin.install === 'function') {
      Vue.use(plugin);
    } else {
      console.warn(`Plugin "${plugin}" inválido`);
    }
  });
};

export default registerPlugins;
