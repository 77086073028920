<template>
  <div class="ds-search-select">
    <ds-select
      ref="select"
      :value="value"
      :suggestion="suggestion"
      :custom-validations="customValidations"
      :disabled="disabled"
      :placeholder="placeholder"
      :required="required"
      :selected-option="selectedOption"
      :on-change="handleChange"
      :on-open="handleOpen"
      :on-close="handleClose"
      :compare-value-by="compareValueBy"
      is-search-select
      v-on="$listeners"
      @input="onSelectValueChange"
      @change="event => this.$emit('change', event)"
      @option-click="focusElemWhenSearchSelect">
      <ds-search-select-input slot="header" :term="term" :on-change="onInputChange" />
      <ds-search-select-empty-message v-show="showNoResultsMessage && !hasQueryScheduled" />
      <ds-search-select-loading-options v-show="showLoader" />
      <ds-search-select-error-message v-show="showErrorMessage" @retry="handleOnQueryErrorRetry" />
      <slot slot="append" name="append" />
      <slot slot="prepend" name="prepend" />
      <div v-show="showContent" data-search-select-options-content class="ds-search-select__options">
        <slot></slot>
      </div>
      <template v-if="showFooter" slot="footer">
        <ds-select-options-footer-item v-if="showLoadMore" no-padding>
          <ds-search-select-load-more-button :fetch-action="() => handleOnLoadMoreButtonClick()" />
        </ds-select-options-footer-item>
        <ds-select-options-footer-item v-if="showQuickAdd" no-padding>
          <ds-search-select-quick-add-button :label="quickAddLabel" @click="handleQuickAdd" />
        </ds-select-options-footer-item>
        <slot name="footer"></slot>
      </template>
    </ds-select>
  </div>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import DsSelect from '@components/select';
import DsSearchSelectEmptyMessage from '@components/search-select-empty-message';
import DsSearchSelectErrorMessage from '@components/search-select-error-message';
import DsSearchSelectInput from '@components/search-select-input';
import DsSearchSelectLoadMoreButton from '@components/search-select-load-more-button';
import DsSearchSelectLoadingOptions from '@components/search-select-loading-options';
import DsSearchSelectQuickAddButton from '@components/search-select-quick-add-button';
import DsSelectOptionsFooterItem from '@components/select-options-footer-item';

import isNil from 'lodash/isNil';
import { debug, focusMixin } from '@core';
import { addClass, removeClass, hasClass } from '@core/services/dom/domService';
import { removeHtmlTag, decodeHtml } from '@core/services/html/htmlService';
import { highlightTerm } from '@core/services/highlightTerm/highlightTermService';

import { getSlotOptionsAsTree } from '@components/select/selectService';
import {
  optionLabelIncludesTerm,
  getOptionVmByValue,
  getSlotOptions,
  getSelectOptionsVm,
  getHiddenCSSClass,
} from './searchSelectService';
import { DEPRECATIONS_PROPS } from './deprecationsConstants';

const REGEX_REMOVE_HTML_TAG = new RegExp(`[a-zA-Z\u00C0-\u017F\\d]+(?![^\\<\\]]*\\>)`, 'gi');

export default {
  name: 'DsSearchSelect',
  provide() {
    return {
      searchSelectVm: this,
      getSlotOptions: this.getSlotOptions,
      getSlotOptionsAsTree: this.getSlotOptionsAsTree,
    };
  },
  inject: {
    selectType: {
      default: 'searchSelect',
    },
  },
  components: {
    DsSelect,
    DsSearchSelectEmptyMessage,
    DsSearchSelectErrorMessage,
    DsSearchSelectInput,
    DsSearchSelectLoadMoreButton,
    DsSearchSelectLoadingOptions,
    DsSearchSelectQuickAddButton,
    DsSelectOptionsFooterItem,
  },
  mixins: [focusMixin.focus('select')],
  props: {
    value: DsSelect.props.value,
    compareValueBy: DsSelect.props.compareValueBy,
    suggestion: DsSelect.props.suggestion,
    customValidations: {
      type: Array,
    },
    disabled: {
      type: Boolean,
    },
    loadMore: {
      type: Boolean,
    },
    placeholder: {
      type: String,
    },
    quickAdd: {
      type: Boolean,
      default: false,
    },
    quickAddLabel: {
      type: String,
    },
    required: {
      type: Boolean,
    },
    selectedOption: {
      type: Object,
    },
    /**
     * DEPRECATED PROP | Use @change event instead
     */
    onChange: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use @open event instead
     */
    onOpen: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use @close event instead
     */
    onClose: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use fetchAction instead
     */
    onFetch: {
      type: Function,
    },
    fetchAction: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use @fetch-error event instead
     */
    onFetchError: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use @fetch-success event instead
     */
    onFetchSuccess: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use queryAction instead
     */
    onQuery: {
      type: Function,
    },
    queryAction: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use @query-error event instead
     */
    onQueryError: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use @query-success event instead
     */
    onQuerySuccess: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use @query-term-change event instead
     */
    onQueryTermChange: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use @load-more-button-click event instead
     */
    onLoadMoreButtonClick: {
      type: Function,
    },
    /**
     * DEPRECATED PROP | Use @quick-add-button-click event instead
     */
    onQuickAddButtonClick: {
      type: Function,
    },
    /**
     * When false, this flags will not hide options that
     * don't match with option label. It MUST be used only with
     * onQuery, WHEN onQuery match options even if the term is not
     * in option label
     */
    shouldMatchTermPattern: {
      type: Boolean,
      default: true,
    },
    isTree: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showContent: true,
      showErrorMessage: false,
      showLoader: false,
      showNoResultsMessage: false,
      term: '',
      hasQueryScheduled: false,
      existParamIdentical: false,
      localFetchId: null,
    };
  },
  computed: {
    showLoadMore() {
      return this.loadMore && !this.showNoResultsMessage;
    },
    showQuickAdd() {
      return this.quickAdd && (this.showNoResultsMessage || !this.existParamIdentical);
    },
    hasFooter() {
      return this.showLoadMore || this.showQuickAdd || this.$slots.footer;
    },
    showFooter() {
      return !this.showErrorMessage && !this.showLoader && this.hasFooter;
    },
    fetch() {
      return this.fetchAction || this.onFetch;
    },
    query() {
      return this.queryAction || this.onQuery;
    },
  },
  watch: {
    value(value) {
      this.$nextTick(() => {
        /*
         * TODO: There is a performance issue here
         * Read more in issue #410
         */
        if (isNewValue(value, this.selectedOption)) {
          this.handleFetchAction(value);
        }
      });
    },
    showLoader: {
      handler(showLoader) {
        if (showLoader === false) {
          this.updatePopper();
        }
      },
    },
  },

  mounted() {
    this.handleFetchAction(this.value);
  },
  created() {
    const deprecatedDependency = createDeprecation(this);
    DEPRECATIONS_PROPS.forEach(prop => {
      if (this[prop.name]) {
        deprecatedDependency.deprecateProperty(prop.name, prop.message);
      }
    });
  },
  methods: {
    getOptions() {
      const { options } = getSelectOptionsVm(this.$vnode);

      if (this.isTree) {
        Object.keys(options).forEach(key => {
          const option = options[key];
          const { parent } = option;
          if (parent) {
            option.parentOption = options[parent];
          }
        });
      }

      return options;
    },
    getOptionVmByValue(value) {
      return getOptionVmByValue(this.getOptions(), value, this.compareValueBy);
    },
    onInputChange(term) {
      this.term = term;
      this.$emit('query-term-change', term);
      if (this.onQueryTermChange) {
        this.onQueryTermChange(term);
      }
      this.setNoResultsMessageVisibility(false);

      if (this.query) {
        this.handleOnQuery(term);
      }

      if (this.shouldMatchTermPattern) {
        this.buildResults(this.getOptions(), term);
      }
    },
    handleClose() {
      this.$emit('close');
      if (this.onClose) {
        this.onClose();
      }
      this.term = '';
      this.updateAvailableOptionsAfterQuery();
    },
    handleOpen() {
      if (this.query) {
        this.queryFirstItems();
      }

      this.$emit('open');
      if (this.onOpen) {
        this.onOpen();
      }

      setTimeout(() => focusSearchInput(this.$el));
    },
    handleFetchAction(value) {
      if (this.fetch && !isNil(this.value)) {
        this.fetch(value).then(this.handleFetchSuccess, this.handleFetchError);
      }
    },
    handleFetchSuccess(value) {
      this.$emit('fetch-success', value);
      if (this.onFetchSuccess) {
        this.onFetchSuccess(value);
      }
    },
    handleFetchError(value) {
      this.$emit('fetch-error', value);
      if (this.onFetchError) {
        this.onFetchError(value);
      }
    },
    handleOnQuery(term) {
      this.setNoResultsMessageVisibility(false);
      this.setErrorMessageVisibility(false);
      this.setContentVisibility(false);
      this.setLoaderVisibility(true);
      this.scheduleOptionsQuery(term, 500);
    },
    handleOnQueryErrorRetry() {
      this.handleOnQuery(this.term);
    },
    handleQueryError(err) {
      this.setErrorMessageVisibility(true);
      this.$emit('query-error', err);
      if (this.onQueryError) {
        this.onQueryError(err);
      }
    },
    queryFirstItems() {
      this.handleOnQuery('');
    },
    scheduleOptionsQuery(term, delay) {
      this.hasQueryScheduled = true;
      clearTimeout(this.optionsQueryTimeoutId);
      this.optionsQueryTimeoutId = setTimeout(() => {
        this.queryOptions(term);
      }, delay);
    },
    updateAvailableOptionsAfterQuery() {
      // TODO - Refactor this peace as soon as possible
      setTimeout(() => {
        this.$nextTick(() => {
          this.buildResults(this.getOptions(), this.term);
          this.setContentVisibility(true);
          this.setLoaderVisibility(false);
        });
      }, 50);
    },
    handleQuerySuccess(response, fetchId) {
      if (this.localFetchId !== fetchId) {
        return;
      }
      this.$emit('query-success', response);
      if (this.onQuerySuccess) {
        this.onQuerySuccess(response);
      }
      this.onRequestComplete();
    },
    async queryOptions(term) {
      try {
        this.localFetchId = Symbol('fetchId');
        const momentFetchId = this.localFetchId;

        const response = await this.query(term);
        this.handleQuerySuccess(response, momentFetchId);
      } catch (error) {
        debug.error(error);
        this.handleQueryError(error);
        this.onRequestComplete();
      }
    },
    onRequestComplete() {
      this.hasQueryScheduled = false;
      this.updateAvailableOptionsAfterQuery();
    },
    getSlotOptions() {
      return getSlotOptions(this.$slots.default);
    },
    getSlotOptionsAsTree() {
      return getSlotOptionsAsTree(this.$slots.default);
    },
    onSelectValueChange(value) {
      this.$nextTick(() => {
        this.$emit('input', value);
      });
    },
    focusElemWhenSearchSelect() {
      const isSearchSelect = this.selectType === 'searchSelect';
      if (this.$el && isSearchSelect) {
        this.$refs.select.focusButton();
      }
    },
    verifyIdentTermExistInOptions(option) {
      if (option?.label.toLowerCase() === this.term?.toLowerCase()) {
        this.setVerifyIdenticalTerm(true);
      }
    },
    setVerifyIdenticalTerm(value) {
      this.existParamIdentical = value;
    },
    resetQuickAdd() {
      this.setVerifyIdenticalTerm(false);
    },
    buildResults(options, term) {
      if (this.shouldMatchTermPattern) {
        this.resetQuickAdd();
        Object.values(options).forEach(option => {
          this.verifyIdentTermExistInOptions(option);
          configOptionVisibilityAccordingSearchedTerm(option, term, this.shouldMatchTermPattern, this.isTree);
        });
      }
      this.setNoResultsMessageVisibility(hasNoResults(options));
    },
    setContentVisibility(visibility) {
      this.showContent = visibility;
    },
    setErrorMessageVisibility(visibility) {
      this.showErrorMessage = visibility;
    },
    setLoaderVisibility(visibility) {
      this.showLoader = visibility;
    },
    setNoResultsMessageVisibility(visibility) {
      this.showNoResultsMessage = visibility;
    },
    handleQuickAdd() {
      /**
       * DEPRECATED EVENT | Use @quick-add-button-click event instead
       */
      this.$emit('on-quick-add-click');
      this.$emit('quick-add-button-click', this.term);

      if (this.onQuickAddButtonClick) {
        this.onQuickAddButtonClick();
      }
    },
    updatePopper() {
      this.$refs.select.updatePopper();
    },
    handleChange(value) {
      if (this.onChange) {
        this.onChange(value);
      }
    },
    handleOnLoadMoreButtonClick() {
      this.$emit('load-more-button-click', this.term);

      if (this.onLoadMoreButtonClick) {
        this.onLoadMoreButtonClick(this.term);
      }
    },
  },
};

function configOptionVisibilityAccordingSearchedTerm(option, term, shouldMatchTermPattern, isTree, hasVisibleChild) {
  const label = getOptionLabel(option);
  const optionElement = getOptionElement(option);
  const optionLabelElement = optionElement.querySelector('[data-option-label-content]');

  if (!shouldMatchTermPattern || optionLabelIncludesTerm(label, term) || hasVisibleChild) {
    const optionSanitized = removeHtmlTag(decodeHtml(optionLabelElement.innerHTML), 'strong');

    removeClass(optionElement, getHiddenCSSClass());

    optionLabelElement.innerHTML = optionSanitized.replace(REGEX_REMOVE_HTML_TAG, match => highlightTerm(match, term));

    if (isTree && option.parentOption) {
      configOptionVisibilityAccordingSearchedTerm(option.parentOption, term, shouldMatchTermPattern, isTree, true);
    }
  } else {
    optionLabelElement.innerHTML = removeHtmlTag(optionLabelElement.innerHTML, 'strong');
    addClass(optionElement, getHiddenCSSClass());
  }
}

function hasNoResults(options) {
  const optionsValues = Object.values(options);
  const isEmpty = optionsValues.length === 0;
  return (
    isEmpty || optionsValues.map(getOptionElement).every(optionElement => hasClass(optionElement, getHiddenCSSClass()))
  );
}

function focusSearchInput(element) {
  getSearchInputElement(element).focus();
}

function getSearchInputElement(element) {
  return element.querySelector('input');
}

function getOptionElement(option) {
  return option.vm && option.vm.$el;
}

function getOptionLabel(option) {
  return option.label;
}

function isNewValue(value, selectedOption) {
  return value !== (selectedOption && selectedOption.value);
}
</script>

<style>
@import './SearchSelect.css';
</style>
