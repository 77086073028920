<template>
  <ds-input
    ref="dsInput"
    v-model="localValue"
    class="ds-numeral-input"
    :suggestion="formattedSuggestion"
    :placeholder="placeholder"
    :custom-validations="localValidations"
    :required="required"
    v-bind="$attrs"
    v-on="listeners"
    @suggestion-applied="emitCustomInput"
    @keydown.enter="onEnter"
    @keypress="onKeyPress"
    @keydown="onKeyDown" />
</template>

<script>
import isNil from 'lodash/isNil';
import DsInput from '@components/input';
import INPUT_ERRORS from '@core/constants/inputErrors';
import { KEY_CODES } from '@core/constants';
import {
  formatValue,
  unformatValue,
  isEmptyValue,
  isSomeDeletionKey,
  isCursorAtLastPosition,
  parseCurrencyStringToFloat,
  buildAbsoluteOnlyRegex,
  isValueValid,
  validateAndFormatValue,
  hasValue,
} from './betaNumeralInputService';

const ZERO_VALUE = 0;
const OFFSET = ' ';
const EVENT_TYPES = {
  CHANGE: 'change',
};

export default {
  name: 'DsBetaNumeralInput',
  components: {
    DsInput,
  },
  props: {
    suggestion: DsInput.props.suggestion,
    value: {
      required: true,
      validator(value) {
        return isNil(value) || typeof value === 'number';
      },
    },
    placeholder: String,
    absoluteOnly: Boolean,
    required: Boolean,
    /**
     * [{ valid: Function, message: String }]
     */
    customValidations: DsInput.props.customValidations,
    prefix: String,
    suffix: String,
    minPrecision: {
      type: Number,
    },
    precision: {
      type: Number,
      default: 2,
    },
    integer: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      localValue: null,
      isInputDirty: false,
      isInputTypedWithEmptyValue: isNil(this.value),
      isInitialValueValid: typeof this.value === 'number' && !Number.isNaN(this.value),
      prefixWithOffset: undefined,
      suffixWithOffset: undefined,
    };
  },
  computed: {
    formattedSuggestion() {
      if (this.suggestion) {
        return this.formatValue(this.suggestion);
      }
      return null;
    },
    localValidations() {
      const localValidations = this.mapCustomValidations();
      if (this.required) {
        localValidations.unshift(this.getRequiredValidation());
      }
      return localValidations;
    },
    listeners() {
      return {
        ...this.$listeners,
        focus: this.onFocus,
        blur: this.onBlur,
        change: this.onChange,
        paste: this.onPaste,
      };
    },
    isInputValueEmpty() {
      const isEmpty = isEmptyValue(this.localValue);
      const { isInputDirty, isInputTypedWithEmptyValue, isInitialValueValid } = this;
      return (
        (isInputTypedWithEmptyValue || (isEmpty && !isInitialValueValid && !isInputDirty)) &&
        this.formattedSuggestion !== this.localValue
      );
    },
  },
  watch: {
    value() {
      this.isInputTypedWithEmptyValue = isNil(this.value);
    },
  },
  beforeMount() {
    if (this.prefix) {
      this.prefixWithOffset = `${this.prefix}${OFFSET}`;
    }
    if (this.suffix) {
      this.suffixWithOffset = `${OFFSET}${this.suffix}`;
    }

    const newValue = validateAndFormatValue({
      value: this.value,
      integerProp: this.integer,
      minPrecision: this.minPrecision,
      precisionProp: this.precision,
      absoluteOnlyProp: this.absoluteOnly,
    });
    this.localValue = this.formatValue(newValue);

    if (hasValue(this.value)) {
      this.emitCustomInput(this.buildValue(this.localValue));
    }
  },
  methods: {
    onPaste(event) {
      const clipboardValue = (event.clipboardData || window.clipboardData).getData('text');
      const regex = buildAbsoluteOnlyRegex(this.absoluteOnly);
      const unformattedValue = parseCurrencyStringToFloat(clipboardValue);

      if (!regex.test(unformattedValue)) {
        event.preventDefault();
      } else {
        const newValue = validateAndFormatValue({
          value: clipboardValue,
          integerProp: this.integer,
          minPrecision: this.minPrecision,
          precisionProp: this.precision,
          absoluteOnlyProp: this.absoluteOnly,
        });
        this.localValue = newValue;
        event.preventDefault();

        this.emitCustomChangedEvent(this.buildValue(this.localValue));
        this.emitCustomInput(this.buildValue(this.localValue));
      }
    },
    onFocus() {
      if (this.localValue) {
        this.localValue = this.unformatValue(this.localValue).toString().replace('.', ',');
      }
    },
    onEnter(event) {
      event.target.blur();
    },
    formatValue(value) {
      return formatValue(value, {
        absoluteOnly: this.absoluteOnly,
        minPrecision: this.minPrecision,
        precision: this.precision,
        prefix: this.prefixWithOffset,
        suffix: this.suffixWithOffset,
      });
    },
    onBlur(e) {
      const formattedTargetValue = parseCurrencyStringToFloat(e.target.value);
      const hasValueChanged = formattedTargetValue !== this.value;

      if (!this.localValue) {
        this.localValue = null;
        this.emitCustomInput(null);
        return;
      }

      this.localValue = this.formatValue(this.localValue);
      if (hasValueChanged) {
        this.emitCustomInput(this.buildValue(this.localValue));
      }
    },
    unformatValue(value) {
      return unformatValue(value, { absoluteOnly: this.absoluteOnly });
    },
    mapCustomValidations() {
      return this.customValidations.map(customValidation => ({
        ...customValidation,
        valid: value => customValidation.valid(this.unformatValue(value)),
      }));
    },
    getRequiredValidation() {
      return {
        message: INPUT_ERRORS.REQUIRED.MESSAGE,
        valid: () => !this.isInputValueEmpty,
      };
    },
    onKeyPress(e) {
      this.isNumber(e);
    },
    emitCustomInput(value) {
      setTimeout(() => {
        this.$emit('custom-input', this.unformatValue(value || this.localValue));
      });
    },
    isNumber(e) {
      const char = String.fromCharCode(e.keyCode);
      const regex = this.absoluteOnly ? /^[0-9,]+$/ : /^[-+,]?\d*$/;
      if (regex.test(char)) {
        return true;
      }
      return e.preventDefault();
    },
    onKeyDown(event) {
      const isValueEmpty = isEmptyValue(event.target.value);
      if (event.keyCode === KEY_CODES.TAB && isValueEmpty) {
        return;
      }

      this.isInputDirty = true;
      const backspacePressed = isSomeDeletionKey(event);
      const atEndPosition = isCursorAtLastPosition(event, this.suffixWithOffset);

      if (backspacePressed && atEndPosition && isValueEmpty) {
        this.isInputTypedWithEmptyValue = true;
        this.$emit('custom-input', null);
      } else {
        this.isInputTypedWithEmptyValue = false;
      }

      if (
        this.localValue &&
        !isValueValid({
          event,
          localValue: this.localValue,
          integerProp: this.integer,
          precisionProp: this.precision,
          absoluteOnlyProp: this.absoluteOnly,
        })
      ) {
        event.preventDefault();
      }
    },
    update(value) {
      if (value || value === ZERO_VALUE) {
        const newValue = validateAndFormatValue({
          value: this.absoluteOnly ? Math.abs(value) : value,
          integerProp: this.integer,
          minPrecision: this.minPrecision,
          precisionProp: this.precision,
          absoluteOnlyProp: this.absoluteOnly,
        });
        this.localValue = this.formatValue(newValue);
        this.emitCustomInput(this.buildValue(this.localValue));
        return undefined;
      }

      this.localValue = null;
      this.emitCustomInput(null);
      return undefined;
    },
    onChange(event) {
      if (event && event.type === EVENT_TYPES.CHANGE) {
        this.emitCustomChangedEvent(this.buildValue(this.localValue));
      }
    },
    emitCustomChangedEvent(value) {
      this.$emit('custom-changed', value);
    },
    buildValue(value) {
      return this.unformatValue(this.formatValue(value));
    },
    validate() {
      this.$refs.dsInput.validate();
    },
  },
};
</script>
