<template>
  <div v-if="shouldShowFilters" class="ds-data-grid-header ds-u-margin-bottom--sm">
    <ds-data-grid-header-summary
      v-if="hasDataGridTemplate"
      :show-period-filter="showPeriodFilter"
      :show-search-term-filter="showSearchTermFilter"
      :disable-period-filter="disablePeriodFilter"
      :search-term-placeholder="searchTermPlaceholder"
      :advanced-filter-container-ref="$refs.advancedFilterContainer"
      :filters="filters"
      @change-period-periodType="setPeriodAndPeriodType"
      @change-search-term="setSearchTerm" />
    <ds-row content-vertical-align="bottom" :class="{ 'ds-u-margin-bottom--none': !shouldShowRemoveAllFilters }">
      <ds-col v-if="showPeriodFilter" size="auto">
        <ds-field :label="periodLabel" class="ds-u-margin-bottom--none">
          <ds-data-grid-date-period-filter
            ref="datePeriodFilter"
            :disabled="disablePeriodFilter"
            :period-type="dataGridFilters.periodType"
            :period-types="filters.periodTypes"
            :value="dataGridFilters.period"
            @input="setPeriodAndPeriodType" />
        </ds-field>
      </ds-col>
      <ds-col v-if="showSearchTermFilter" size="auto" :class="{ 'ds-u-print-hidden': !dataGridFilters.searchTerm }">
        <ds-field :label="searchTermLabel" class="ds-u-margin-bottom--none">
          <ds-search-form
            :value="dataGridFilters.searchTerm"
            :on-search="setSearchTerm"
            :on-clear-search="setSearchTerm"
            :placeholder="searchTermPlaceholder" />
        </ds-field>
      </ds-col>
      <ds-col v-if="$slots['filters']" size="auto">
        <slot name="filters" />
      </ds-col>
      <ds-col v-if="showFilterButtonGroup" size="auto">
        <ds-data-grid-filter-button-group
          :value="dataGridFilters.buttonGroup"
          :buttons="filterButtonGroup.buttons"
          :label="filterButtonGroup.label"
          @input="setFilterButtonGroup" />
      </ds-col>
      <ds-col v-if="showMultipleSelectFilter" size="auto">
        <ds-data-grid-multiple-select-filter
          ref="multipleSelectFilter"
          :value="dataGridFilters.multipleSelect"
          :on-fetch="multipleSelectFilter.onFetch"
          :on-query="multipleSelectFilter.onQuery"
          :compare-value-by="multipleSelectFilter.compareValueBy"
          :has-empty-option="multipleSelectFilter.hasEmptyOption"
          :should-match-term-pattern="multipleSelectFilter.shouldMatchTermPattern"
          :load-more="multipleSelectFilter.showLoadMore"
          :label="multipleSelectFilter.label"
          :show-all-placeholder-term="multipleSelectFilter.showAllPlaceholderTerm"
          @input="setMultipleSelectFilter" />
      </ds-col>
      <ds-col v-if="showAdvancedFilters" class="ds-u-print-hidden" size="auto">
        <ds-data-grid-advanced-filters-dropdown :store="advancedFiltersStore" />
      </ds-col>
      <template v-if="$slots['actions']">
        <div class="ds-data-grid-header-actions-divider"></div>
        <ds-col size="auto">
          <slot name="actions" />
        </ds-col>
      </template>
    </ds-row>
    <div v-if="hasVisibleFilters" class="ds-data-grid-advanced-filters__label">
      <ds-text class="ds-u-margin-right--xs">Mais filtros selecionados</ds-text>
      <ds-icon
        v-if="showAdvancedFiltersTooltip"
        v-ds-tooltip="tooltip"
        :icon="['regular', 'circle-question']"
        size="md"
        color="grey-dark" />
    </div>
    <div
      ref="advancedFilterContainer"
      class="ds-u-display--flex ds-u-align-items--center ds-advanced-filters"
      :class="{ 'ds-u-margin-bottom--md': shouldShowRemoveAllFilters }">
      <ds-data-grid-advanced-filters
        v-if="showAdvancedFilters"
        ref="dataGridAdvancedFilters"
        :filters="filters.advanced"
        :options="advancedFiltersOptions"
        :store="advancedFiltersStore"
        @change="onAdvancedFiltersChange" />
      <ds-link
        v-if="shouldShowRemoveAllFilters"
        :class="{ 'ds-data-grid-header-remove-all--with-space': hasVisibleFilters }"
        class="ds-data-grid-header-remove-all ds-u-print-hidden"
        @click="removeAllFilters">
        <ds-icon :icon="['regular', 'trash']" size="sm" class="ds-data-grid-header-remove-all__icon" />
        <span>Limpar filtros</span>
      </ds-link>
    </div>
  </div>
</template>

<script>
import DsCol from '@components/col';
import DsRow from '@components/row';
import DsSearchForm from '@components/search-form';
import DsField from '@components/field';
import DsText from '@components/text';
import DsIcon from '@components/icon';
import DsLink from '@components/link';
import DsTooltip from '@directives/tooltip';
import isEqual from 'lodash/isEqual';
import DsDataGridFilterButtonGroup from './DataGridFilterButtonGroup.vue';
import DsDataGridDatePeriodFilter from './DataGridDatePeriodFilter.vue';
import DsDataGridMultipleSelectFilter from './DatagridMultipleSelectFilter.vue';
import { createStore } from './advanced-filters/store';
import DsDataGridAdvancedFiltersDropdown from './advanced-filters/DataGridAdvancedFiltersDropdown.vue';
import DsDataGridAdvancedFilters from './advanced-filters/DataGridAdvancedFilters.vue';
import DsDataGridHeaderSummary from './DataGridHeaderSummary.vue';

export default {
  name: 'DsDataGridHeader',
  components: {
    DsCol,
    DsRow,
    DsSearchForm,
    DsField,
    DsText,
    DsIcon,
    DsLink,
    DsDataGridAdvancedFiltersDropdown,
    DsDataGridAdvancedFilters,
    DsDataGridFilterButtonGroup,
    DsDataGridDatePeriodFilter,
    DsDataGridMultipleSelectFilter,
    DsDataGridHeaderSummary,
  },
  directives: {
    DsTooltip,
  },
  inject: {
    dataGrid: {
      default: null,
    },
    dataGridTemplate: {
      default: {},
    },
  },
  props: {
    advancedFiltersOptions: {
      type: Object,
      default: () => ({}),
    },
    disablePeriodFilter: {
      type: Boolean,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    filterButtonGroup: {
      type: Object,
    },
    showPeriodFilter: {
      type: Boolean,
    },
    showSearchTermFilter: {
      type: Boolean,
    },
    searchTermPlaceholder: {
      type: String,
      default: 'Pesquisar',
    },
    showFilterButtonGroup: {
      type: Boolean,
    },
    showMultipleSelectFilter: {
      type: Boolean,
    },
    showAdvancedFiltersTooltip: {
      type: Boolean,
    },
    multipleSelectFilter: {
      type: Object,
    },
  },
  data() {
    return {
      advancedFilters: undefined,
      advancedFiltersStore: createStore(),
      periodLabel: this.filters?.period?.label || 'Período',
      tooltip: 'Todas as opções selecionadas no "Mais filtros" ficam salvas no seu navegador.',
      hasDataGridTemplate: this.dataGridTemplate.store,
    };
  },
  computed: {
    dataGridFilters() {
      return this.dataGrid.store.filters;
    },
    showAdvancedFilters() {
      return Object.keys(this.advancedFiltersOptions).length;
    },
    shouldShowFilters() {
      return (
        this.showPeriodFilter ||
        this.showSearchTermFilter ||
        this.showAdvancedFilters ||
        this.showFilterButtonGroup ||
        this.showMultipleSelectFilter
      );
    },
    searchTermLabel() {
      return this.showPeriodFilter ? 'Pesquisar no período selecionado' : 'Pesquisar';
    },
    shouldShowRemoveAllFilters() {
      return this.dataGrid.store.isFiltersChanged || this.hasVisibleFilters;
    },
    hasVisibleFilters() {
      return !!Object.keys(this.advancedFiltersStore.visibleFilters).length;
    },
  },
  watch: {
    dataGridFilters: {
      immediate: true,
      deep: true,
      handler(newFilters, oldFilters) {
        if (isEqual(newFilters, oldFilters)) {
          return;
        }

        this.handleFiltersChange();
      },
    },
    'filters.periodType': function setPeriodType(value) {
      this.updateFilter('periodType', value);
    },
    'filters.buttonGroup': function setFilterButtonGroup(value) {
      this.setFilterButtonGroup(value);
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.shouldShowFilters) {
        this.$emit('filters-visible', this.shouldShowFilters);
      }
    });
  },
  methods: {
    handleFiltersChange(triggerRefresh) {
      this.$emit(
        'filters-change',
        {
          period: this.showPeriodFilter ? this.dataGridFilters.period : undefined,
          periodType: this.showPeriodFilter ? this.dataGridFilters.periodType : undefined,
          searchTerm: this.dataGridFilters.searchTerm,
          advanced: this.advancedFilters,
          buttonGroup: this.dataGridFilters.buttonGroup,
          multipleSelect: this.dataGridFilters.multipleSelect,
        },
        triggerRefresh,
      );
    },
    updateFilter(filterName, value) {
      this.dataGrid.store.updateFilter(filterName, value);
    },
    onAdvancedFiltersChange(filters, triggerRefresh) {
      this.setAdvancedFilters(filters);
      this.handleFiltersChange(triggerRefresh);
    },
    setPeriodAndPeriodType(period, periodType) {
      this.updateFilter('periodType', periodType);
      this.updateFilter('period', period);
    },
    setSearchTerm(searchTerm = '') {
      this.updateFilter('searchTerm', searchTerm);
    },
    setFilterButtonGroup(buttonGroup) {
      this.updateFilter('buttonGroup', buttonGroup);
    },
    setMultipleSelectFilter(multipleSelect) {
      this.updateFilter('multipleSelect', multipleSelect);
    },
    setAdvancedFilters(filters) {
      this.advancedFilters = filters;
    },
    /**
     * Deprecated
     */
    setPeriodArrowsNavigationBy(type) {
      this.$refs.datePeriodFilter.setPeriodArrowsNavigationBy(type);
    },
    removeAllFilters() {
      if (
        this.showSearchTermFilter ||
        this.showPeriodFilter ||
        this.showFilterButtonGroup ||
        this.showMultipleSelectFilter
      ) {
        this.dataGrid.store.clearFilters();
      }
      if (this.showAdvancedFilters) {
        this.$refs.dataGridAdvancedFilters.removeAllFilters();
      }
      this.$emit('filters-cleared');
    },
  },
};
</script>

<style scoped>
@import './DataGridHeader.css';
</style>

