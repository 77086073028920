<template>
  <ds-input
    ref="input"
    v-model="localValue"
    v-bind="$attrs"
    class="ds-cep-input"
    :custom-validations="customValidations"
    :required="required"
    :disabled="disabled"
    type="text"
    mask-pattern="99999-999"
    @change="onInputChange"
    @blur="onInputBlur" />
</template>

<script>
import DsInput from '@components/input';
import { constants, focusMixin } from '@core';
import * as cepService from '@ca-design-system-libs/cep';

export default {
  name: 'DsCepInput',
  components: {
    DsInput,
  },
  mixins: [focusMixin.focus('input')],
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    onChange: {
      type: Function,
    },
    onBlur: {
      type: Function,
    },
  },
  data() {
    return {
      localValue: this.value,
      customValidations: [
        {
          message: constants.INPUT_ERRORS.INVALID_CEP.MESSAGE,
          valid: cepService.isValid,
        },
      ],
    };
  },
  watch: {
    value(value) {
      if (String(value) !== cepService.removePunctuation(this.localValue)) {
        this.localValue = String(value);
      }
    },
    localValue(localValue) {
      const unmasked = cepService.removePunctuation(localValue);
      this.$emit('input', unmasked);
    },
  },
  methods: {
    onInputChange() {
      if (this.onChange) {
        this.onChange();
      }
    },
    onInputBlur() {
      if (this.onBlur) {
        this.onBlur();
      }
    },
  },
};
</script>
