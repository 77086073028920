<template>
  <div class="ds-row" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
import {
  handleContentVerticalAlignValidation,
  handleVerticalOffsetValidation,
  handleFirstChildElementValidation,
  isVerticalOffsetValid,
  isContentVerticalAlignValid,
} from './rowService';

export default {
  name: 'DsRow',
  props: {
    verticalOffset: {
      type: [String, Number],
    },
    contentVerticalAlign: {
      type: [String],
    },
    noWrap: {
      type: [Boolean],
    },
    justify: {
      type: String,
      validator(justify) {
        return ['center', 'flex-end', 'space-around', 'space-between'].includes(justify);
      },
    },
  },
  computed: {
    classes() {
      return {
        [`ds-u-vertical-offset--${this.verticalOffset}`]:
          this.verticalOffset && isVerticalOffsetValid(this.verticalOffset),
        [`ds-row--content-vertical-align-${this.contentVerticalAlign}`]:
          this.contentVerticalAlign && isContentVerticalAlignValid(this.contentVerticalAlign),
        [`ds-u-justify-content--${this.justify}`]: this.justify,
        'ds-u-flex-wrap--nowrap': this.noWrap,
      };
    },
  },
  mounted() {
    handleVerticalOffsetValidation(this.verticalOffset);
    handleContentVerticalAlignValidation(this.contentVerticalAlign);
    handleFirstChildElementValidation(this.$el.firstElementChild);
  },
};
</script>

<style scoped>
@import './Row.css';
</style>
