import { debug } from '../debug/debugService';

const useCounter = vm => {
  if (!vm.fieldVm) {
    handleDebugErrorMessage();
  }
  buildCounter();

  function buildCounter() {
    if (!vm.fieldVm) {
      return;
    }
    vm.fieldVm.handleShouldShowCounter(vm.counter);
    vm.fieldVm.setMaxCounterText(vm.maxCharacters);
  }

  const setCounterValue = value => {
    if (!vm.fieldVm) {
      return;
    }
    vm.fieldVm.setCounterText(value);
  };

  return {
    setCounterValue,
  };
};

function getRules(vm) {
  const rules = [];

  if (vm.maxCharacters && vm.minCharacters) {
    rules.push(getMinAndMaxCounterRule(vm));
    return rules;
  }

  if (vm.maxCharacters) {
    rules.push(getMaxCounterRule(vm));
  }

  if (vm.minCharacters) {
    rules.push(getMinCounterRule(vm));
  }

  return rules;
}

function handleDebugErrorMessage() {
  debug.error(new Error('To use counter prop the component need to be involved with a "ds-field" component'));
}

function getMinAndMaxCounterRule(vm) {
  return {
    valid: () => vm.maxCharacters >= vm.localCounter && vm.localCounter >= vm.minCharacters,
    message: `Insira um texto contendo entre ${vm.minCharacters} e ${vm.maxCharacters} caracteres`,
  };
}

function getMaxCounterRule(vm) {
  return {
    valid: () => vm.localCounter <= vm.maxCharacters,
    message: `Insira um texto menor ou igual a ${vm.maxCharacters} caracteres`,
  };
}

function getMinCounterRule(vm) {
  return {
    valid: () => vm.localCounter >= vm.minCharacters,
    message: `Insira um texto contendo no mínimo  ${vm.minCharacters} caracteres`,
  };
}

export { useCounter, getRules };
