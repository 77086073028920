<template>
  <ds-file-preview :file="file" :status="status" @delete-file="onDeleteFile" @retry-upload="onRetry" />
</template>

<script>
import DsFilePreview from '@components/file-preview';

export default {
  name: 'DsFileUploader',
  components: {
    DsFilePreview,
  },
  props: {
    file: {
      type: File,
      required: true,
    },
    uploadFile: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      status: 'uploading',
    };
  },
  mounted() {
    this.uploadSelectedFile(this.file);
  },
  methods: {
    async uploadSelectedFile(file) {
      try {
        this.status = 'uploading';
        const response = await this.uploadFile(file);
        this.onUploadFileSuccess(response);
      } catch (e) {
        this.onUploadFileError(e);
      }
    },
    onUploadFileSuccess(response) {
      this.status = 'valid';
      this.$emit('upload-success', response);
    },
    onUploadFileError(error) {
      this.status = 'error';
      this.$emit('upload-error', error);
    },
    onRetry() {
      this.uploadSelectedFile(this.file);
    },
    onDeleteFile() {
      this.$emit('delete-file', this.file);
    },
  },
};
</script>
