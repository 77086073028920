export default {
  REQUIRED: {
    KEY: 'required',
    MESSAGE: 'Campo obrigatório',
  },
  REQUIRED_FILE: {
    KEY: 'required-file',
    MESSAGE: 'Arquivo obrigatório',
  },
  REQUIRED_CHECKBOX: {
    MESSAGE: 'Selecione ao menos uma opção',
  },
  INVALID_EMAIL: {
    MESSAGE: 'Informe um e-mail válido',
  },
  INVALID_NUMBER: {
    MESSAGE: 'Informe um número válido',
  },
  INVALID_CEP: {
    MESSAGE: 'Informe um CEP válido',
  },
  INVALID_CNPJ: {
    MESSAGE: 'Informe um CNPJ válido',
  },
  INVALID_CPF: {
    MESSAGE: 'Informe um CPF válido',
  },
  INVALID_PHONE: {
    MESSAGE: 'Informe um telefone válido',
  },
  INVALID_PERCENT: {
    MESSAGE: 'Informe um valor válido',
  },
  INVALID_DATE: {
    MESSAGE: 'Informe uma data válida',
  },
  INVALID_TIME: {
    MESSAGE: 'Formato de hora inválido',
  },
  INVALID_FILE_MIN_SIZE: {
    getMessage: minSize => `Tamanho do arquivo inválido. O arquivo deve ser maior ou igual a ${minSize} bytes`,
  },
  INVALID_FILE_MAX_SIZE: {
    getMessage: maxSize => `Tamanho do arquivo inválido. O arquivo deve ser menor ou igual a ${maxSize} bytes`,
  },
  INVALID_FILE_EXTENSION: {
    MESSAGE: 'Arquivo não suportado',
  },
  INVALID_SECURITY_CODE: {
    MESSAGE: 'Código não compatível com cartão',
  },
  INVALID_CARD_NUMBER: {
    MESSAGE: 'Informe um número de cartão de crédito válido',
  },
  INVALID_CARD_EXPIRATION: {
    MESSAGE: 'Data não pode ser anterior a atual',
  },
};
