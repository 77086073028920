<template>
  <span
    v-ds-tooltip="'Clique aqui para editar o filtro'"
    class="ds-data-grid-advanced-filter-item"
    :class="classes"
    @click="onClick">
    <ds-text class="ds-data-grid-advanced-filter-item__label">
      {{ label }}
    </ds-text>
    <ds-icon
      v-if="canRemove"
      v-ds-tooltip="'Remover'"
      class="ds-data-grid-advanced-filter-item__remove-icon ds-u-print-hidden"
      :icon="['light', 'times']"
      size="md"
      @mouseenter="onIconMouseEnter"
      @mouseleave="onIconMouseLeave"
      @click="remove" />
  </span>
</template>

<script>
import DsText from '@components/text';
import DsIcon from '@components/icon';
import DsTooltip from '@directives/tooltip';
import { isEquivalentObject } from '@core/services/object/objectService';
import { getTypeService } from './dataGridAdvancedFilterTypeService';
import { STATUS, TYPES } from './dataGridAdvancedFilterConstants';

export default {
  name: 'DsDataGridAdvancedFilter',
  components: {
    DsText,
    DsIcon,
  },
  directives: {
    DsTooltip,
  },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      required: true,
    },
    value: {
      type: [Object, Array],
    },
    status: {
      type: String,
      required: true,
      validator(status) {
        return Object.values(STATUS).includes(status);
      },
    },
    type: {
      type: String,
      required: true,
      validator(type) {
        return Object.values(TYPES).includes(type);
      },
    },
  },
  data() {
    return {
      fullValue: null,
    };
  },
  computed: {
    classes() {
      return {
        [`ds-data-grid-advanced-filter-item--${this.status}`]: this.status,
      };
    },
    canRemove() {
      return this.status !== STATUS.PENDING;
    },
    shouldPopoverBeOpen() {
      return this.status === STATUS.PENDING;
    },
    label() {
      return this.formattedValue ? `${this.name}: ${this.formattedValue}` : this.name;
    },
    formattedValue() {
      return this.fullValue ? this.typeService.getFormattedValue(this.fullValue) : '';
    },
    typeService() {
      return getTypeService(this.type);
    },
  },
  watch: {
    shouldPopoverBeOpen: {
      immediate: true,
      handler(shouldPopoverBeOpen) {
        if (shouldPopoverBeOpen) {
          this.openPopover();
        }
      },
    },
    value: {
      immediate: true,
      handler(value) {
        this.setFullValue(value);
      },
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
      this.openPopover();
    },
    async openPopover() {
      this.$dsPopover(await this.typeService.getPopoverComponent(), {
        props: {
          ...this.$attrs,
          setValue: this.setValue,
          value: this.value,
        },
      }).open(this.$el, {
        placement: 'bottom-start',
      });
    },
    async setFullValue(value) {
      if (!isEquivalentObject(this.fullValue, value)) {
        this.fullValue = this.typeService.isFullValue(value) ? value : await this.fetchFullValue(value);

        if (value !== undefined) {
          this.setValue(this.fullValue);
        }
      }
    },
    setValue(value) {
      this.$emit('change', value);
    },
    remove() {
      this.$emit('remove');
    },
    fetchFullValue(value) {
      if (this.$attrs.onFetch) {
        return {
          [TYPES.SELECT]: () => this.$attrs.onFetch(value).catch(() => value.map(({ key }) => ({ key, name: '' }))),
        }[this.type]();
      }
      return [];
    },
    onIconMouseEnter() {
      DsTooltip.hide(this.$el);
    },
    onIconMouseLeave() {
      DsTooltip.show(this.$el);
    },
  },
};
</script>

<style scoped>
@import './DataGridAdvancedFilter.css';
</style>
