<template>
  <span class="ds-checkbox-icon" v-on="$listeners">
    <ds-fade-transition>
      <ds-icon v-show="checked" color="blue" size="sm" transform="up-3" :icon="['regular', 'check']" />
    </ds-fade-transition>
  </span>
</template>

<script>
import DsFadeTransition from '@components/fade-transition/FadeTransition.vue';
import DsIcon from '@components/icon';

export default {
  name: 'DsCheckboxIcon',
  components: {
    DsFadeTransition,
    DsIcon,
  },
  props: {
    checked: Boolean,
  },
};
</script>
