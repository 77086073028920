import { domService } from '@core';
import Toast from './toast';
/* eslint-disable import/no-unresolved */
import template from './toasterContainer.html?raw';
import './toasterContainer.css';

export class ToasterContainer {
  constructor() {
    this.element = domService.parser(template);
    document.body.appendChild(this.element);
  }

  add({ content, theme, title }) {
    const toastElement = new Toast({ content, theme, title }).get();
    this.element.appendChild(setToasterPosition(toastElement, this.getLastChild()));
  }

  getLastChild() {
    return this.element.lastElementChild;
  }
}

function setToasterPosition(element, lastToast) {
  if (lastToast) {
    const topValue = buildTopValue(lastToast);
    element.setAttribute('style', `top: ${topValue}px`);
  }

  return element;
}

function buildTopValue(element) {
  const MARGIN = 10;
  return element.offsetTop + element.offsetHeight + MARGIN;
}
