<template>
  <div
    v-ds-tooltip="!formVm.isValid && invalidFormTooltipText"
    :class="{ 'ds-u-width--full': fullWidth }"
    class="ds-submit-button ds-u-display--inline-block">
    <ds-request-button
      :theme="theme"
      :disabled="disabled"
      :request-action="submit"
      :full-width="fullWidth"
      v-bind="$attrs"
      type="submit"
      v-on="$listeners">
      <slot></slot>
    </ds-request-button>
  </div>
</template>

<script>
import DsTooltip from '@directives/tooltip';
import DsRequestButton from '@components/request-button';

export default {
  name: 'DsSubmitButton',
  components: {
    DsRequestButton,
  },
  directives: {
    DsTooltip,
  },
  inject: ['formVm'],
  props: {
    disabled: Boolean,
    fullWidth: DsRequestButton.props.fullWidth,
    theme: {
      type: String,
      default: 'primary',
    },
    invalidFormTooltipText: {
      type: String,
      default: 'Para continuar, preencha corretamente<br> os campos pendentes.',
    },
  },
  created() {
    this.formVm.setSubmitButton(this);
  },
  methods: {
    /**
     * @public
     */
    submit(event) {
      /** Since the default event is dispatched only after the click handler,
       * we were not able to resolve the promise only after submit finishes.
       * To do that, we are preventing the request-button default submit
       * event to be dispatched and then dispatch a new one, inside the promise.
       */
      if (event) {
        event.preventDefault();
      }

      if (!this.formVm.isValid) {
        this.formVm.shouldShowErrors = true;
        this.formVm.scrollToFirstInvalidElement();

        return false;
      }

      return new Promise(resolve => {
        /** Dispatch a new submit event at the beginning, and then wait the
         * form finishes to resolve the promise */
        this.formVm.$el.dispatchEvent(new Event('submit', { cancelable: true }));

        const unwatch = this.$watch('formVm.isSubmitting', () => {
          resolve();
          unwatch();
        });
      });
    },
  },
};
</script>
