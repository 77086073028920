export default {
  bind(_el, binding, vnode) {
    const el = _el;
    el.clickOutsideHandler = event => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };

    document.body.addEventListener('click', el.clickOutsideHandler);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideHandler);
  },
};
