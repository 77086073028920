import moment from 'moment';

const SLASH_FORMAT = 'DD/MM/YYYY';
const ISO_FORMAT = 'YYYY-MM-DD';

export function formatDate(date, fromFormat, toFormat) {
  return date ? moment(date, fromFormat, true).format(toFormat) : null;
}

export function toISODate(date) {
  return formatDate(date, SLASH_FORMAT, ISO_FORMAT);
}

export function objectToISODate(date) {
  return formatDate(date, null, ISO_FORMAT);
}

export function toSlashDate(date) {
  return formatDate(date, ISO_FORMAT, SLASH_FORMAT);
}

export function slashToObjectDate(dashDate) {
  const parts = dashDate.split('/').map(part => parseInt(part));
  return new Date(parts[2], parts[1] - 1, parts[0]);
}
