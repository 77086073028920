<template>
  <section class="ds-blankslate">
    <ds-illustration :name="illustration" />
    <ds-heading size="sm" :text="title" />
    <ds-p>
      <slot></slot>
    </ds-p>
    <footer>
      <slot name="actions"></slot>
    </footer>
  </section>
</template>

<script>
import DsIllustration from '@components/illustration';
import DsHeading from '@components/heading';
import DsP from '@components/paragraph';

export default {
  name: 'DsBlankslate',
  components: {
    DsHeading,
    DsIllustration,
    DsP,
  },
  props: {
    illustration: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
@import './Blankslate.css';
</style>
