<template>
  <section class="ds-body">
    <div class="ds-body__data-grid-filter">
      <portal-target name="data-grid-filter"></portal-target>
    </div>
    <div class="ds-action-bar__container">
      <portal-target name="action-bar"></portal-target>
    </div>
    <ds-container>
      <slot />
    </ds-container>
  </section>
</template>

<script>
import { PortalTarget } from 'portal-vue';
import DsContainer from '@components/container';

export default {
  name: 'DsBody',
  components: {
    DsContainer,
    PortalTarget,
  },
};
</script>

<style scoped>
@import './Body.css';
</style>
