<script lang="jsx">
const DEFAULT_WIDTH_SIZE = '100%';

function isNumber(value) {
  return typeof value === 'number';
}

function buildLinesArray(lines) {
  return new Array(lines).fill(DEFAULT_WIDTH_SIZE);
}

function buildLines(lines) {
  return isNumber(lines) ? buildLinesArray(lines) : lines;
}

function buildElement(h, props) {
  const lines = buildLines(props.lines);
  return lines.map((_, index) =>
    h('div', {
      class: ['ds-skeleton-line'],
      style: { width: props.lines[index] },
    }),
  );
}

export default {
  name: 'DsSkeleton',
  functional: true,
  props: {
    lines: {
      type: [Number, Array],
      default: 3,
      validator(value) {
        const val = isNumber(value) ? value : value.length;
        return val > 0 && val <= 10;
      },
    },
  },
  render(h, { props }) {
    return h('div', { class: 'ds-skeleton-container' }, [buildElement(h, props)]);
  },
};
</script>

<style scoped>
@import './Skeleton.css';
</style>
