import { debug } from '@core';
import { disableBodyScroll } from '@core/services/scroll/scrollService';
import Driver from '@contaazul/driver.js';
import './driverTour.css';

let reenableBodyScroll;

function validateFunction(value) {
  return value && typeof value === 'function' ? value : () => {};
}

function validateArray(value) {
  return value && Array.isArray(value) ? value : [];
}

function cleanDOM() {
  const popoverItemElement = document.querySelector('#driver-popover-item');
  if (popoverItemElement) {
    setTimeout(() => {
      popoverItemElement.remove();
    }, 1000);
  }
}

function blockContent(element) {
  if (element._dsPointerEvents === undefined) {
    element._dsPointerEvents = element.style.pointerEvents;
  }

  element.style.pointerEvents = 'none';

  return () => {
    element.style.pointerEvents = element._dsPointerEvents;
    delete element._dsPointerEvents;
  };
}

function getOnCloseHandler(onCloseCallback) {
  return () => {
    validateFunction(onCloseCallback)();
    cleanDOM();
    reenableBodyScroll();
  };
}

function getContainer() {
  const containerList = document.querySelectorAll(
    '.ds-rollover.ds-rollover--is-opened > .ds-rollover__body, .ds-modal > .ds-modal-container > .ds-modal__body',
  );
  return containerList.length ? containerList[containerList.length - 1] : null;
}

export class DriverTour {
  constructor({ onClose, steps }) {
    this._options = {
      container: getContainer(),
      className: 'ds-driver-tour ds-popover',
      animate: true,
      opacity: 0.75,
      padding: 10,
      allowClose: true,
      overlayClickNext: true,
      doneBtnText: 'Pronto!',
      closeBtnText: 'Fechar',
      nextBtnText: 'Próximo',
      prevBtnText: 'Anterior',
      keyboardControl: true,
      onReset: getOnCloseHandler(onClose),
      showCounter: true,
      counterTemplate: '{current} de {total}',
      xCloseButton: true,
      onHighlightStarted: stepElements => {
        stepElements._dsUnblockHighlightedContent = blockContent(stepElements.node);
      },
      onDeselected: stepElements => {
        stepElements._dsUnblockHighlightedContent();
      },
    };

    this._tour = new Driver(this._options);
    this._steps = validateArray(steps);
  }

  startIntroduction() {
    this._tour.defineSteps(this._steps);
    setTimeout(() => {
      reenableBodyScroll = disableBodyScroll();

      try {
        this._tour.start();
      } catch (e) {
        debug.error(e);
        reenableBodyScroll();
      }
    }, 100);
  }
}
