<template>
  <div
    v-click-outside="close"
    v-focus-outside="close"
    class="ds-select"
    @keydown.down.prevent="onKeyDown"
    @keydown.up.prevent="onKeyUp"
    @keydown.enter.prevent="onKeyEnter"
    @keydown.esc.prevent="onEsc">
    <button
      ref="selectButton"
      :class="classes"
      :disabled="disabled"
      :tabindex="0"
      data-select-button
      class="ds-select__button ds-select-toggle ds-input ds-u-text-left"
      type="button"
      @click="onClick"
      @blur="onBlur"
      @focus="onFocus">
      <div v-if="shouldShowPrependSlot" class="ds-select-prepend">
        <slot name="prepend" />
      </div>
      <ds-tooltipped-overflow>
        <span v-ds-tooltip="getPlaceholderTerm" class="ds-select__label">{{ getPlaceholderTerm }}</span>
      </ds-tooltipped-overflow>
      <div v-if="shouldShowAppendSlot" class="ds-select-append">
        <slot name="append" />
      </div>
      <ds-input-icons class="ds-u-print-hidden">
        <ds-input-clear-icon v-if="shouldShowClearButton" @click="clear" @focus="close" />
        <ds-suggestion-button
          v-if="shouldShowSuggestionButton"
          :is-suggestion-accepted="isSuggestionAccepted"
          @click="onApplySuggestion"
          @focus="close" />
        <span class="ds-select__trigger-select-icon">
          <ds-icon icon="chevron-down" color="text" size="sm" />
        </span>
      </ds-input-icons>
    </button>
    <ds-select-options
      ref="selectOptions"
      :compare-value-by="compareValueBy"
      :value="value"
      :show="showOptions"
      :selected-option="selectedOptionValue"
      :width="selectWidth"
      :has-button="hasButton"
      :is-search-select="isSearchSelect"
      @option-added="onOptionAdded"
      @option-selected="onSelectOption"
      @close="close">
      <slot name="search"></slot>
      <slot slot="header" name="header"></slot>
      <slot></slot>
      <slot slot="footer" name="footer"></slot>
    </ds-select-options>
  </div>
</template>

<script>
import DsTooltip from '@directives/tooltip';
import Focus from '@directives/focus';
import ClickOutside from '@directives/click-outside';
import FocusOutside from '@directives/focus-outside';
import DsIcon from '@components/icon';
import DsInputClearIcon from '@components/input-clear-icon';
import DsSelectOptions from '@components/select-options';
import { scrollService, modelValidationService } from '@core';
import DsSuggestionButton from '@components/suggestion-button/SuggestionButton.vue';
import DsInputIcons from '@components/input-icons/InputIcons.vue';
import DsTooltippedOverflow from '@components/tooltipped-overflow';
import { areOptionsEqualByRules, isEmptyValue, shouldRenderPrependSlot, shouldRenderAppendSlot } from './selectService';

export default {
  name: 'DsSelect',
  inject: {
    fieldVm: {
      default: null,
    },
    formVm: {
      default: null,
    },
    showAllPlaceholderTerm: {
      default: false,
    },
  },
  provide() {
    return {
      selectVm: {
        onOptionClick: this.onOptionClick,
      },
    };
  },
  components: {
    DsIcon,
    DsInputIcons,
    DsInputClearIcon,
    DsSelectOptions,
    DsSuggestionButton,
    DsTooltippedOverflow,
  },
  directives: {
    Focus,
    DsTooltip,
    ClickOutside,
    FocusOutside,
  },
  props: {
    value: DsSelectOptions.props.value,
    compareValueBy: DsSelectOptions.props.compareValueBy,
    customValidations: Array,
    placeholder: String,
    disabled: Boolean,
    hasButton: Boolean,
    required: Boolean,
    selectedOption: {
      type: Object,
      default: () => {},
    },
    onChange: Function,
    onOpen: Function,
    onClose: Function,
    isSearchSelect: Boolean,
    suggestion: {
      type: DsSelectOptions.props.value.type,
      default: null,
    },
  },
  data() {
    return {
      modelValidator: modelValidationService.buildModelValidation(this),
      showOptions: false,
      selectedOptionLabel: null,
      scrollListener: null,
      selectWidth: 0,
      hasFocus: false,
      selectedOptionValue: null,
    };
  },
  computed: {
    getPlaceholderTerm() {
      const ALL_PLACEHOLDER_TERM = 'Tudo';
      return this.selectedOptionLabel || (this.showAllPlaceholderTerm && ALL_PLACEHOLDER_TERM) || this.placeholder;
    },
    isValidSuggestion() {
      return this.suggestion !== null;
    },
    isSuggestionAccepted() {
      return this.isValidSuggestion && this.areOptionsEqual(this.suggestion, this.value);
    },
    shouldShowSuggestionButton() {
      return this.isValidSuggestion && !this.disabled;
    },
    shouldShowClearButton() {
      return this.value && !this.required && !this.disabled;
    },
    classes() {
      return [
        this.modelValidator.cssClass,
        {
          'ds-select-filled': this.selectedOptionLabel || this.showAllPlaceholderTerm,
          'is-disabled': this.disabled,
          'ds-select--suggestion-accepted': this.isSuggestionAccepted && this.shouldShowSuggestionButton,
        },
      ];
    },
    shouldShowPrependSlot() {
      return this.value && shouldRenderPrependSlot(this.$slots.prepend);
    },
    shouldShowAppendSlot() {
      return this.value && shouldRenderAppendSlot(this.$slots.append);
    },
  },
  watch: {
    showOptions() {
      this.setSelectWidth(this.$el.offsetWidth);
      handleOpenCloseCallback(this.showOptions, {
        onOpen: this.onOpen,
        onClose: this.onClose,
      });
    },
    selectedOption(selectedOption) {
      const { label, value } = selectedOption;
      this.onSelectOption({ label, value });
    },
    value(value) {
      this.$emit('change', value);
    },
    suggestion(newVal, oldVal) {
      if (this.isValidSuggestion && !this.areOptionsEqual(newVal, oldVal)) {
        this.onApplySuggestion();
      }
    },
  },
  mounted() {
    this.handleScroll();
    this.setSelectWidth(this.$el.offsetWidth);
    this.initSuggestionValue();
  },
  methods: {
    initSuggestionValue() {
      if (this.isValidSuggestion && isEmptyValue(this.value)) {
        this.onApplySuggestion();
      }
    },
    onApplySuggestion(event) {
      if (event && event.stopPropagation) {
        event.stopPropagation();
      }
      this.$emit('input', this.suggestion);
      this.$emit('suggestion-applied', this.suggestion);
    },
    areOptionsEqual(a, b) {
      return areOptionsEqualByRules({ a, b, compareValueBy: this.compareValueBy });
    },
    setSelectedOptionLabel(label) {
      this.selectedOptionLabel = label;
    },
    handleScroll() {
      const scrollableParentElement = scrollService.getScrollableParentElement(this.$el);

      if (scrollableParentElement) {
        scrollableParentElement.addEventListener('scroll', this.close);

        this.$on('hook:destroyed', () => {
          scrollableParentElement.removeEventListener('scroll', this.close);
        });
      }
    },
    onClick() {
      this.toggle();
    },
    onSelectOption({ label, value, shouldCloseOptions }) {
      this.setSelectedOptionLabel(label);
      this.selectedOptionValue = value;

      if (!this.areOptionsEqual(this.value, value)) {
        this.$emit('input', value);

        if (this.onChange) {
          this.onChange(value);
        }
      }

      if (shouldCloseOptions) {
        this.close();
      }
    },
    onOptionAdded(option) {
      if (this.areOptionsEqual(option.value, this.value) && !this.selectedOptionValue) {
        this.onSelectOption(option);
      }
    },
    onKeyDown() {
      this.$refs.selectOptions.$emit('keydown.down');
    },
    onKeyUp() {
      this.$refs.selectOptions.$emit('keydown.up');
    },
    onKeyEnter() {
      this.$refs.selectOptions.$emit('keydown.enter');
    },
    onBlur(event) {
      this.setFocus(false);
      if (!this.showOptions) {
        this.$nextTick(() => {
          this.$emit('blur', event);
        });
      }
    },
    onFocus(event) {
      this.$emit('focus', event);
      this.setFocus(true);
    },
    onEsc() {
      this.close();
    },
    open() {
      this.showOptions = true;
    },
    close() {
      if (this.showOptions) {
        this.showOptions = false;
        this.onBlur();
      }
    },
    clear(event) {
      this.$emit('clear');
      this.$emit('input', null);
      event.stopPropagation();
    },
    toggle() {
      this.showOptions = !this.showOptions;
    },
    setSelectWidth(width) {
      this.selectWidth = width;
    },
    setFocus(value) {
      this.hasFocus = value;
    },
    focusButton() {
      this.$refs.selectButton.focus();
    },
    focus() {
      this.focusButton();
      this.toggle();
    },
    updatePopper() {
      this.$refs.selectOptions.updatePopper();
    },
    onOptionClick() {
      this.$emit('option-click');
    },
  },
};

function handleOpenCloseCallback(hasBeenOpened, { onOpen, onClose }) {
  if (hasBeenOpened) {
    if (onOpen) {
      onOpen();
    }
  } else if (onClose) {
    onClose();
  }
}
</script>

<style>
@import './Select.css';
</style>
