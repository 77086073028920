import uniqBy from 'lodash/uniqBy';
import { isComponent, getChildComponentInstance } from '@core/services/vnode/vnodeService';

export function getSlotOptions(slotOptions = []) {
  return slotOptions.filter(vnode => isComponent(vnode, 'DsOption'));
}

export function getOptionsValues(slotOptions = []) {
  return slotOptions.map(vnode => getOptionVmValue(vnode.componentInstance));
}

export function getOptionVmValue(optionVm) {
  return optionVm?.getData()?.value;
}

export function getSelectOptionsVm(vnode) {
  return getChildComponentInstance(vnode, 'DsSelectOptions');
}

export function getSelectVm(vnode) {
  return getChildComponentInstance(vnode, 'DsSelect');
}

export function getLabelFromOptions(options, compareBy) {
  const path = compareBy ? `value.${compareBy}` : 'value';
  return uniqBy(options, path)
    .map(option => option.label)
    .join(', ');
}

export function getNonDisabledSlotOptions(slotOptions = []) {
  return slotOptions.filter(vnode => isComponent(vnode, 'DsOption') && !vnode.componentInstance.disabled);
}
