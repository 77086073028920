<template>
  <div class="ds-collapse-table">
    <ds-collapse-table-actions
      v-if="!hideActions"
      @open-all-collapses="onOpenAllCollapses"
      @close-all-collapses="onCloseAllCollapses" />
    <ds-table
      v-bind="$attrs"
      :fix-first-columns="fixFirstColumns"
      :fix-last-columns="fixLastColumns"
      class="ds-collapse-table__table">
      <slot slot="header" name="header"></slot>
      <slot></slot>
      <slot slot="footer" name="footer"></slot>
    </ds-table>
  </div>
</template>

<script>
import DsTable from '@components/table';
import { generateId } from '@core/services/id/idService';
import DsCollapseTableActions from './CollapseTableActions.vue';

export default {
  name: 'DsCollapseTable',
  provide() {
    return {
      collapseTableVm: this,
    };
  },
  components: {
    DsTable,
    DsCollapseTableActions,
  },
  props: {
    hideActions: {
      type: Boolean,
    },
    fixFirstColumns: DsTable.props.fixFirstColumns,
    fixLastColumns: DsTable.props.fixLastColumns,
  },
  data() {
    return {
      collapseRows: {},
    };
  },
  methods: {
    onCloseAllCollapses() {
      this.$emit('close-all-collapses');

      Object.values(this.collapseRows).forEach(collapse => {
        collapse.closeCollapse();
      });
    },
    onOpenAllCollapses() {
      this.$emit('open-all-collapses');

      Object.values(this.collapseRows).forEach(collapse => {
        collapse.openCollapse();
      });
    },
    registerCollapseRow(collapse) {
      const collapseId = generateId();
      this.$set(this.collapseRows, collapseId, collapse);
      return collapseId;
    },
    unregisterCollapseRow(collapseId) {
      this.$delete(this.collapseRows, collapseId);
    },
  },
};
</script>

<style>
@import './CollapseTable.css';
</style>
