<template>
  <div class="ds-page" ds-container-element>
    <div v-if="shouldShowHeader" class="ds-page-header">
      <ds-heading :text="localTitle" size="md">
        <slot name="title" />
      </ds-heading>
      <!-- eslint-disable vue/no-v-html -->
      <p v-if="localDescription" class="ds-page-header-description" v-html="localDescription"></p>
      <ds-row-divider margin="medium" theme="dotted" />
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import DsHeading from '@components/heading';
import DsRowDivider from '@components/row-divider';

export default {
  name: 'DsPage',
  components: {
    DsHeading,
    DsRowDivider,
  },
  props: {
    title: String,
    description: String,
    /**
     * DEPRECATED PROP | Use title prop instead
     */
    headerTitle: String,
    /**
     * DEPRECATED PROP | Use description prop instead
     */
    headerDescription: String,
  },
  computed: {
    localTitle() {
      return this.headerTitle || this.title;
    },
    localDescription() {
      return this.headerDescription || this.description;
    },
    shouldShowHeader() {
      return this.localTitle || this.$slots.title;
    },
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.headerTitle) {
      deprecatedDependency.deprecateProperty('headerTitle', 'Use title instead');
    }
    if (this.headerDescription) {
      deprecatedDependency.deprecateProperty('headerDescription', 'Use description instead');
    }
  },
};
</script>

<style scoped>
@import './Page.css';
</style>
