const DEFAULT_SCROLL_PARENT = 'BODY';

function isScrollParent(element) {
  return element && (element.className?.includes('ds-scrollbar') || element.scrollHeight > element.clientHeight);
}

export function getScrollableParentElement(element) {
  if (!element || element.tagName === DEFAULT_SCROLL_PARENT) {
    return window;
  }

  if (isScrollParent(element)) {
    return element;
  }

  return getScrollableParentElement(element.parentNode);
}

export function disableBodyScroll() {
  const { overflow } = document.body.style;
  document.body.style.overflow = 'hidden';
  const onWheel = e => e.preventDefault();
  window.addEventListener('wheel', onWheel, { passive: false });

  return () => {
    document.body.style.overflow = overflow;
    window.removeEventListener('wheel', onWheel);
  };
}

export function isInViewport(el, scrollableParent = getScrollableParentElement(el)) {
  const boundsTop = el.getBoundingClientRect().top;
  const offsetTop = scrollableParent.offsetTop || 0;
  return boundsTop >= offsetTop;
}

export function scrollTop(el) {
  if (el instanceof HTMLElement) {
    el.scroll({
      top: 0,
      behavior: 'smooth',
    });
  } else {
    throw new Error("'el' is not an HTML element.");
  }
}
