<template>
  <ds-dropdown ref="dropdown" :disabled="disabled" :full-width="fullWidth" @request-loading="onRequestLoading">
    <template #trigger>
      <div :class="classes">
        <div class="ds-split-button-wrapper-group__button" @click.stop="closeDropdown">
          <slot name="button" />
        </div>
        <div class="ds-split-button-wrapper-group__trigger">
          <ds-button :theme="theme" :disabled="disabled" icon="chevron-down"></ds-button>
        </div>
      </div>
    </template>
    <slot />
  </ds-dropdown>
</template>

<script>
import DsButton from '@components/button';
import DsDropdown from '@components/dropdown';

export default {
  name: 'DsSplitButtonWrapper',
  components: {
    DsButton,
    DsDropdown,
  },
  props: {
    theme: DsButton.props.theme,
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return [
        'ds-split-button-wrapper-group',
        {
          'ds-split-button-wrapper-group--full-width': this.fullWidth,
        },
      ];
    },
  },
  methods: {
    closeDropdown() {
      this.$refs.dropdown.close();
    },
    onRequestLoading(value) {
      this.$emit('request-loading', value);
    },
  },
};
</script>

<style>
@import './SplitButtonWrapper.css';
</style>
