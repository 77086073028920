import INPUT_ERRORS from '../../constants/inputErrors';
import { getRule } from './rulesService';

class ModelValidationService {
  constructor(vm, { requiredRule, customValidations } = {}) {
    this._setInputVm(vm);
    this._setErrors();
    this._setRequiredRule(requiredRule);
    this._setRules(this._buildVmRules(customValidations));
    this._setValid();
    this._setCssClass();
    this._setHasBeenBlured();
    this._setErrorMessage();
    this._setHasOnFetchOption();
    this._setShouldShowErrors();
    this._setHasValue();

    this.validate = this.validateRules;

    vm.$once('hook:mounted', () => {
      if (vm.fieldVm) {
        vm.fieldVm.setIsRequired(vm.required);
        vm.fieldVm.setModelValidator(this);
      }

      if (vm.formVm) {
        vm.formVm.addModelValidator(this);
        vm.formVm.$watch(
          'shouldShowErrors',
          shouldShowErrors => {
            this._setShouldShowErrors(shouldShowErrors);
            this._validateRulesByType();
          },
          {
            immediate: true,
          },
        );
      }

      this._validateRulesByType();
    });

    vm.$once('hook:beforeDestroy', () => {
      if (this._onDestroy) {
        this._onDestroy();
      }
    });

    vm.$on('change', value => {
      this._validateRulesByType({ value });
    });

    vm.$on('blur', () => {
      this._setHasBeenBlured(true);
      this._validateRulesByType();
      this._setHasValue();
    });

    vm.$watch('required', required => {
      if (vm.fieldVm) {
        vm.fieldVm.setIsRequired(required);
      }

      this.setIsRequired(required);
    });

    vm.$watch('disabled', () => {
      this._validateRulesByType();
    });
  }

  validateRules() {
    this._setRules(this._buildVmRules());
    this._setShouldShowErrors(true);
    this._validateRulesByType();
  }

  onDestroy(callback) {
    this._onDestroy = callback;
  }

  _setHasBeenBlured(value) {
    this.hasBeenBlured = value;
  }

  _setHasOnFetchOption() {
    if (this.vm.formVm) {
      this.hasOnFetchOption = !!this.vm.formVm.onFetch || !!this.vm.formVm.fetchAction;
    }
  }

  _setShouldShowErrors(value) {
    this.shouldShowErrors = value;
  }

  _validateRulesByType({ value } = {}) {
    if (this.vm.disabled) {
      this._removeAllErrorsAndCssClass();

      return;
    }

    if (this.vm.type === 'file') {
      this._validateAllRules(this.vm.files);
    } else {
      this._validateAllRules(value || this.vm.value);
    }
  }

  // TODO - Refactor this method
  // eslint-disable-next-line class-methods-use-this
  _createRequiredRule(requiredRule) {
    return {
      ...requiredRule,
      name: INPUT_ERRORS.REQUIRED.KEY,
    };
  }

  _setRequiredRule(requiredRule) {
    const ruleName = this.vm.type === 'file' ? 'required-file' : 'required';
    this.requiredRule = requiredRule ? this._createRequiredRule(requiredRule) : getRule(ruleName);
  }

  _getFirstErrorMessage() {
    const [firstMessage] = this.errors;
    return firstMessage;
  }

  _setErrorMessage(errorMessage) {
    this.errorMessage = errorMessage;
  }

  _validateAllRules(value) {
    this._removeAllErrors();

    this.rules.forEach(rule => {
      this._validateRule(rule, value);
    });

    if (this.hasBeenBlured || (this.hasValue && this.hasOnFetchOption) || this.shouldShowErrors) {
      this._setCssClass(this._getValidationCssClass());
    }
  }

  _setCssClass(cssClass) {
    this.cssClass = cssClass;
  }

  _setInputVm(vm) {
    this.vm = vm;
  }

  _setValid(valid) {
    this.valid = this.rules.length === 0 || valid;
  }

  _setErrors(errors = []) {
    this.errors = errors;
  }

  _buildVmRules(customValidations = []) {
    const rules = this._getVmRules();
    customValidations.forEach(rule => rules.push(rule));

    return rules;
  }

  _getVmRules() {
    const rules = [...(this.vm.customValidations || [])];

    if (this.vm.required) {
      rules.unshift(this.requiredRule);
    }

    return rules;
  }

  _setRules(rules) {
    this.rules = rules;
  }

  // TODO - Refactor this method
  // eslint-disable-next-line class-methods-use-this
  _isValueValid(value, rule) {
    if (rule.name === INPUT_ERRORS.REQUIRED.KEY || rule.name === INPUT_ERRORS.REQUIRED_FILE.KEY) {
      return rule.valid(value);
    }

    return !value || rule.valid(value);
  }

  _validateRule(rule, value) {
    if (!this._isValueValid(value, rule)) {
      this._addError(rule);
    }
  }

  _getValidationCssClass() {
    return this._hasSomeError() ? 'ds-form-control-danger' : '';
  }

  _hasSomeError() {
    return this.errors && this.errors.length > 0;
  }

  _removeAllErrors() {
    this._setErrors([]);
    this._onRemoveOrAddError();
  }

  _removeAllErrorsAndCssClass() {
    this._removeAllErrors();
    this._setCssClass();
  }

  _addError(rule) {
    this.errors.push(rule.message);
    this._onRemoveOrAddError();
  }

  _onRemoveOrAddError() {
    this._setErrorMessage(this._getFirstErrorMessage());
    this._setValid(!this._hasSomeError());
  }

  setIsRequired(isRequired) {
    const indexOfRequiredRule = this.rules.indexOf(this.requiredRule);
    const isRequiredRuleInRules = indexOfRequiredRule > -1;

    if (isRequired && !isRequiredRuleInRules) {
      this.rules.unshift(this.requiredRule);
    }

    if (!isRequired && isRequiredRuleInRules) {
      this.rules.splice(indexOfRequiredRule, 1);
    }

    this._validateRulesByType();
  }

  _setHasValue() {
    this.hasValue = !!this.vm.value;
  }
}

export function buildModelValidation(vm, options = {}) {
  return new ModelValidationService(vm, options);
}
