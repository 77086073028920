<template>
  <div class="ds-multiple-select-option">
    <ds-checkbox v-model="checkedLocal" :disabled="disabled" tabindex="-1" />
    <span data-option-label-content>
      <slot />
    </span>
  </div>
</template>

<script>
import DsCheckbox from '@components/checkbox';

export default {
  name: 'DsMultipleSelectOption',
  components: {
    DsCheckbox,
  },
  props: {
    type: [Object, String, Number],
    getData: Function,
    disabled: Boolean,
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkedLocal: this.checked,
    };
  },
  watch: {
    checkedLocal() {
      this.onSelect();
    },
    checked: {
      handler(checked) {
        this.checkedLocal = checked;
      },
    },
  },
  methods: {
    toggleCheckbox() {
      this.checkedLocal = !this.checkedLocal;
    },
    select(checkedValue) {
      if ([true, false].includes(checkedValue)) {
        this.checkedLocal = checkedValue;
      } else {
        this.toggleCheckbox();
      }
    },
    onSelect() {
      this.$emit('option-selected', {
        ...this.getData(),
        shouldCloseOptions: false,
        checked: this.checkedLocal,
      });
    },
  },
};
</script>

<style scoped>
@import './MultipleSelectOption.css';
</style>
