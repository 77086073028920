<template>
  <ds-search-select
    ref="select"
    class="ds-multiple-select"
    :value="value"
    :compare-value-by="compareValueBy"
    :on-query-error="handleQueryError"
    :on-query-success="handleQuerySuccess"
    :on-query-term-change="handleQueryTermChange"
    :load-more="loadMore"
    v-bind="$attrs"
    v-on="listeners"
    @clear="selectOptionsByValues([])"
    @suggestion-applied="onSuggestionApplied">
    <ds-option
      v-show="showToggleAll"
      key="ds-option--select-all"
      :checked="allSelected"
      value="ds-option--select-all"
      @option-clicked="onToggleAll">
      Selecionar todas
    </ds-option>
    <hr v-show="showToggleAll" class="ds-option__divider" />
    <slot></slot>
    <slot slot="footer" name="footer"></slot>
  </ds-search-select>
</template>

<script>
import { focusMixin } from '@core';
import DsOption from '@components/option';
import DsSearchSelect from '@components/search-select';
import { getDiffArray, getSubtractedArray, includes } from '@core/services/array/arrayService';

import {
  getSlotOptions,
  getLabelFromOptions,
  getSelectOptionsVm,
  getSelectVm,
  getOptionVmValue,
  getOptionsValues,
  getNonDisabledSlotOptions,
} from './multipleSelectService';

export default {
  name: 'DsMultipleSelect',
  provide() {
    return {
      selectType: 'multiple',
    };
  },
  components: {
    DsOption,
    DsSearchSelect,
  },
  mixins: [focusMixin.focus('select')],
  props: {
    onQueryTermChange: {
      type: Function,
      default: () => {},
    },
    onFetch: {
      type: Function,
    },
    onFetchSuccess: {
      type: Function,
      default: () => {},
    },
    onFetchError: {
      type: Function,
      default: () => {},
    },
    onQueryError: {
      type: Function,
      default: () => {},
    },
    onQuerySuccess: {
      type: Function,
      default: () => {},
    },
    compareValueBy: {
      type: String,
    },
    setLabel: {
      type: Function,
      default(label) {
        getSelectVm(this.$vnode).setSelectedOptionLabel(label);
      },
    },
    onChange: {
      type: Function,
      default: () => {},
    },
    asyncOptions: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
    },
    loadMore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasError: false,
      options: {},
      term: null,
      allSelected: false,
    };
  },
  computed: {
    showToggleAll() {
      return !this.term && !this.hasError && !this.loadMore;
    },
    values() {
      return this.value || [];
    },
    checkedOptions() {
      return Object.values(this.options).filter(option => !!option.checked);
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
      };
    },
  },
  watch: {
    checkedOptions(checkedOptions) {
      const values = checkedOptions.map(option => option.value);
      this.setAllSelected();

      if (getDiffArray(values, this.values, this.compareValueBy).length) {
        this.$emit('input', values && values.length ? values : null);
      }
    },
    values(values) {
      this.$nextTick(() => {
        this.handleAsyncOptions(values);
        this.selectOptionsByValues(values);
        this.onChange(values);
      });
    },
    allSelected(allSelected) {
      this.$emit('all-options-selected', allSelected);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.handleOptionsListeners();
      this.handleAsyncOptions(this.values);
    });
  },
  methods: {
    handleQueryError(...args) {
      this.hasError = true;
      this.onQueryError(...args);
    },
    handleQuerySuccess(...args) {
      this.hasError = false;
      this.onQuerySuccess(...args);
    },
    handleQueryTermChange(term) {
      this.term = term;
      this.onQueryTermChange(term);
    },
    onToggleAll() {
      // TODO: This callback is being called twice
      // Read more in issue #390
      this.getSlotOptions().forEach(vnode => {
        this.setOptionCheckedValue(vnode.componentInstance, !this.allSelected);
      });
    },
    selectOptionsByValues(values = []) {
      this.handleLabel();
      this.getSlotOptions().forEach(({ componentInstance }) => {
        this.setOptionCheckedValue(
          componentInstance,
          values.length ? this.includes(values, getOptionVmValue(componentInstance)) : false,
        );
      });
    },
    includes(values, value) {
      return includes(values, value, this.compareValueBy);
    },
    getUndefinedOptions(values) {
      const optionsValues = getOptionsValues(this.getSlotOptions());
      const asyncOptions = (this.asyncOptions || []).map(option => option.value);
      return getSubtractedArray(values, [...asyncOptions, ...optionsValues], this.compareValueBy);
    },
    handleAsyncOptions(values) {
      const undefinedOptions = this.getUndefinedOptions(values);
      if (undefinedOptions.length) {
        this.handleFetchAction(undefinedOptions);
      }
    },
    handleFetchAction(values) {
      if (this.onFetch) {
        this.onFetch(values).then(this.onFetchSuccess).then(this.handleLabel).catch(this.onFetchError);
      }
    },
    handleOptionsListeners() {
      this.listenToRenderedOptions();
      this.listenToAsyncOptions();
    },
    listenToRenderedOptions() {
      this.getSlotOptions().forEach(({ componentInstance }) => {
        this.listenToOptionSelection(componentInstance);
        this.checkOptionIfSelected(componentInstance);
      });
    },
    listenToAsyncOptions() {
      getSelectOptionsVm(this.$vnode).$on('option-added', ({ vm }) => {
        this.listenToOptionSelection(vm);
        this.checkOptionIfSelected(vm);
      });
    },
    checkOptionIfSelected(optionVm) {
      if (this.isOptionVmSelected(optionVm)) {
        this.setOptionCheckedValue(optionVm, true);
      }
    },
    listenToOptionSelection(optionVm) {
      optionVm.$on('option-selected', this.setCheckedOption);
    },
    setOptionCheckedValue(optionVm, checked) {
      if (optionVm) {
        optionVm.select(checked);
      }
    },
    isOptionVmSelected(optionVm) {
      return this.includes(this.values, getOptionVmValue(optionVm));
    },
    isOptionSelected(option) {
      return this.includes(this.values, option.value);
    },
    getSlotOptions() {
      return getSlotOptions(this.$slots.default);
    },
    getNonDisabledSlotOptions() {
      return getNonDisabledSlotOptions(this.$slots.default);
    },
    setCheckedOption(option) {
      this.$set(this.options, option.key, option);
    },
    onInput() {
      this.handleLabel();
    },
    handleLabel() {
      const asyncSelectedOptions = (this.asyncOptions || []).filter(this.isOptionSelected);
      const selectedOptions = [...this.checkedOptions, ...asyncSelectedOptions];
      this.setLabel(getLabelFromOptions(selectedOptions, this.compareValueBy));
    },
    onSuggestionApplied(valueSuggested) {
      this.$emit('input', valueSuggested);
    },
    setAllSelected() {
      const optionItems = this.getNonDisabledSlotOptions().length;

      this.allSelected = this.checkedOptions.length === optionItems;
    },
  },
};
</script>
