<template>
  <div class="ds-helper-slimbox ds-u-print-hidden" :class="{ 'ds-helper-slimbox--sm': hasOnlyOneItem }">
    <ds-slimbox :allow-close="shouldAllowClose" @close="onCloseButtonClick">
      <div class="ds-helper-slimbox__container" :class="verticalAlignClass">
        <div v-if="shouldShowImagesContent" class="ds-helper-slimbox__images">
          <ds-illustration v-if="shouldShowIllustration" :name="illustration" :width="imgWidth" :height="imgHeight" />
          <img v-if="shouldShowImage" :src="imgUrl" :alt="alt" :style="imgStyle" />
        </div>
        <div class="ds-helper-slimbox__content">
          <ds-heading class="ds-helper-slimbox__title" :text="title" size="sm" />
          <slot></slot>
        </div>
      </div>
    </ds-slimbox>
  </div>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import DsSlimbox from '@components/slimbox';
import DsIllustration from '@components/illustration';
import DsHeading from '@components/heading';

export default {
  name: 'DsHelperSlimbox',
  components: {
    DsSlimbox,
    DsIllustration,
    DsHeading,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    imgUrl: String,
    illustration: String,
    /**
     * DEPRECATED PROP | Use allow-close prop and close event instead
     */
    onClose: Function,
    alt: String,
    imgWidth: String,
    imgHeight: String,
    verticalAlign: String,
    allowClose: Boolean,
  },
  computed: {
    shouldShowImage() {
      return !this.illustration && this.imgUrl;
    },
    shouldShowIllustration() {
      return !!this.illustration;
    },
    shouldShowImagesContent() {
      return !!this.illustration || !!this.shouldShowImage;
    },
    hasOnlyOneItem() {
      return this.$slots?.default?.length === 1;
    },
    verticalAlignClass() {
      return {
        [`ds-helper__container--vertical-align-${this.verticalAlign}`]: !!this.verticalAlign,
      };
    },
    imgStyle() {
      return {
        width: this.imgWidth,
        height: this.imgHeight,
      };
    },
    shouldAllowClose() {
      return !!this.onClose || this.allowClose;
    },
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.onClose) {
      deprecatedDependency.deprecateProperty('onClose', 'Use close event instead');
    }
  },
  methods: {
    onCloseButtonClick() {
      this.$emit('close');
      if (this.onClose) {
        this.onClose();
      }
    },
  },
};
</script>

<style scoped>
@import './HelperSlimbox.css';
</style>
