<template>
  <ds-date-filter class="ds-date-month-filter" :disabled="disabled" @previous="decrementMonth" @next="incrementMonth">
    <div
      v-ds-tooltip="tooltipValue"
      class="ds-date-month-filter__field"
      :class="{ 'ds-date-month-filter__field--disabled': disabled }"
      tabindex="0"
      @click="openDatePicker"
      @keydown.space="openDatePicker">
      <ds-date-input
        ref="input"
        type="monthFull"
        tabindex="-1"
        chevron-icon
        :value="localValue"
        :calendar-button="false"
        :disabled="disabled"
        :on-change="onChange"
        @input="onInput" />
    </div>
  </ds-date-filter>
</template>

<script>
import DsDateInput from '@components/date-input';
import DsDateFilter from '@components/date-filter/DateFilter.vue';
import DsTooltip from '@directives/tooltip';
import { dateService, dateParser, debounceService } from '@core';
import { DEBOUNCE_TIME } from '@core/constants/debounce';

export default {
  name: 'DsDateMonthFilter',
  directives: {
    DsTooltip,
  },
  components: {
    DsDateInput,
    DsDateFilter,
  },
  props: {
    disabled: Boolean,
    value: DsDateInput.props.value,
    debounce: {
      type: Number,
      default: DEBOUNCE_TIME,
      validator: debounceService.debounceValidateTime,
    },
  },

  data() {
    return {
      localValue: null,
      localChange: null,
    };
  },
  computed: {
    tooltipValue() {
      return this.disabled ? '' : 'Clique aqui para selecionar outro mês';
    },
  },
  watch: {
    localValue(localValue) {
      if (localValue !== this.value) {
        this.emitInputDebounced(localValue);
      }
    },
    localChange() {
      this.emitChangeDebounced();
    },
    value(value) {
      if (value !== this.localValue) {
        this.localValue = value;
      }
    },
  },
  mounted() {
    const initialDate = this.value || new Date();
    this.localValue = initialDate;
  },
  created() {
    this.emitInputDebounced = debounceService.debounce(this.emitInput, this.debounce);
    this.emitChangeDebounced = debounceService.debounce(this.emitChange, this.debounce);
  },
  methods: {
    emitInput() {
      this.$emit('input', this.localValue);
    },
    emitChange() {
      this.$emit('change', this.localChange);
    },
    changeMonth(months) {
      const newDate = dateParser.objectToISODate(dateService.addMonths(this.localValue, months));
      this.setLocalValue(newDate);
    },
    incrementMonth() {
      this.changeMonth(1);
    },
    decrementMonth() {
      this.changeMonth(-1);
    },
    setLocalValue(value) {
      this.localValue = value;
    },
    onChange(value) {
      this.localChange = value;
    },
    onInput(value) {
      this.localValue = value;
    },
    openDatePicker() {
      this.$refs.input.openDatepicker();
    },
  },
};
</script>

<style scoped>
@import './DateMonthFilter.css';
</style>
