/**
 * Metrics from Ping Pong ttf file analyzed by fontkit
 * https://github.com/foliojs/fontkit
 * https://github.com/seek-oss/capsize/blob/master/packages/capsize/src/metrics.ts#L43
 */
export const PING_PONG = {
  REGULAR: {
    capHeight: 724,
    ascent: 836,
    descent: -205,
    lineGap: 0,
    unitsPerEm: 1000,
  },
};
