<template>
  <ds-scrollbar-area :height="height" :width="width" class="ds-u-overflow--hidden">
    <iframe class="ds-pdf-preview" title="pdf preview" height="100%" width="100%" :src="localFile"> </iframe>
  </ds-scrollbar-area>
</template>

<script>
import { debug } from '@core';
import { isPdfFile } from '@core/services/fileValidation/fileValidationService';
import DsScrollbarArea from '@components/scrollbar-area';

export default {
  name: 'DsTcPdfPreview',
  components: {
    DsScrollbarArea,
  },
  props: {
    file: {
      type: File,
    },
    height: {
      type: String,
      default: '100%',
    },
    width: {
      type: String,
      default: '100%',
    },
  },
  data() {
    return {
      localFile: null,
    };
  },
  watch: {
    file() {
      this.handleFileUrl();
    },
  },
  created() {
    debug.error('The iframe may fail to display PDFs when DevTools is open.');
    this.handleFileUrl();
  },
  beforeDestroy() {
    this.clearUrl();
  },
  methods: {
    handleFileUrl() {
      this.clearUrl();

      if (isPdfFile(this.file)) {
        this.localFile = URL.createObjectURL(this.file);
      } else if (this.file) {
        this.$emit('unsupported-file-type', this.file);
      }
    },
    clearUrl() {
      if (this.localFile) {
        this.localFile = URL.revokeObjectURL(this.localFile);
      }
    },
  },
};
</script>
<style scoped>
@import './TcPdfPreview.css';
</style>
