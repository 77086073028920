<script lang="jsx">
import DsIcon from '@components/icon';

function buildImage(h, props) {
  if (props.src) {
    return h('img', {
      class: ['ds-avatar__image', `ds-avatar__image--${props.size}`],
      attrs: {
        src: props.src,
        alt: props.alt,
      },
    });
  }
  return h(DsIcon, {
    class: ['ds-avatar__image--blank', `ds-avatar__image--${props.size}`],
    props: { icon: 'user' },
  });
}

export default {
  name: 'DsAvatar',
  functional: true,
  props: {
    src: {
      type: String,
    },
    alt: {
      type: String,
    },
    size: {
      type: String,
      default: 'md',
      validator(size) {
        return ['xs', 'sm', 'md', 'lg', 'xl'].includes(size);
      },
    },
  },
  render(h, { props }) {
    return h(
      'div',
      {
        class: 'ds-avatar',
      },
      [buildImage(h, props)],
    );
  },
};
</script>

<style scoped>
@import './Avatar.css';
</style>
