<template>
  <ds-date-input
    ref="input"
    v-model="dueDate"
    v-bind="$props"
    :picker="false"
    inputmode="numeric"
    :custom-validations="customValidations"
    type="month"
    :on-change="listeners.change"
    :on-blur="listeners.blur" />
</template>
<script>
import { dateService, focusMixin, constants, creditCardService } from '@core';
import DsDateInput from '@components/date-input';

export default {
  name: 'DsCreditCardDueDateInput',
  components: {
    DsDateInput,
  },
  mixins: [focusMixin.focus('input')],
  props: {
    value: {
      type: String,
      default: '',
      validator(date) {
        return !date || dateService.isValidDashDate(date);
      },
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
  },
  data() {
    return {
      customValidations: [
        {
          message: constants.INPUT_ERRORS.INVALID_CARD_EXPIRATION.MESSAGE,
          valid: date => {
            const [year, month] = date.split('-');
            return creditCardService.isExpirationDateValid({ month, year });
          },
        },
      ],
    };
  },
  computed: {
    dueDate: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    listeners() {
      return {
        ...this.$listeners,
        change: event => this.$emit('change', event),
        blur: event => this.$emit('blur', event),
      };
    },
  },
};
</script>
