<template>
  <ds-carousel v-if="shouldShowCarousel" ref="carousel" :per-view="3" :rewind="false" :bound="true">
    <ds-tc-dashboard-banner
      v-for="(banner, index) in carouselBanners"
      :key="buildBannerKey(banner, index)"
      class="ds-tc-dashboard-banner-margin"
      :type="banner.type"
      :size="sizes.type"
      :custom-data="banner.customData"
      @close="onClose(banner, index)"
      @click="banner.click" />
  </ds-carousel>
  <div v-else>
    <ds-row>
      <ds-col v-for="(banner, index) in carouselBanners" :key="buildBannerKey(banner, index)" :size="sizes.columnSize">
        <ds-tc-dashboard-banner
          :type="banner.type"
          :size="sizes.type"
          :custom-data="banner.customData"
          @close="onClose(banner, index)"
          @click="banner.click" />
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import DsCarousel from '@components/carousel';
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsTcDashboardBanner from '../dashboardBanner/TcDashboardBanner.vue';

const BANNERS_PER_VIEW = 3;

export default {
  name: 'DsTcDashboardCarousel',
  components: {
    DsCarousel,
    DsTcDashboardBanner,
    DsRow,
    DsCol,
  },
  props: {
    banners: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      size: 'sm',
      perView: 1,
      carouselBanners: this.banners,
      updateCallback: null,
    };
  },
  computed: {
    shouldShowCarousel() {
      return this.carouselBanners.length > BANNERS_PER_VIEW;
    },
    sizes() {
      if (this.carouselBanners.length > 2) {
        return {
          type: 'sm',
          columnSize: '4',
        };
      }
      if (this.carouselBanners.length > 1) {
        return {
          type: 'md',
          columnSize: '6',
        };
      }
      return {
        type: 'lg',
        columnSize: '12',
      };
    },
  },
  methods: {
    onClose(banner, index) {
      this.carouselBanners.splice(index, 1);
      banner.close();
      if (this.shouldShowCarousel) {
        this.$refs.carousel.refresh();
      }
    },
    buildBannerKey(banner, index) {
      return `${JSON.stringify(banner)}+${index}`;
    },
  },
};
</script>

<style scoped>
@import './TcDashboardCarousel.css';
</style>
