<template>
  <div class="ds-context-menu__container">
    <ul class="ds-context-menu">
      <li
        v-for="(item, index) in items"
        :key="index"
        ref="li"
        class="ds-context-menu-item"
        tabindex="0"
        :active="index === localValue"
        @keydown.enter.space.prevent="onActivate(item.itemId)"
        @click="onActivate(item.itemId)">
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>

<script>
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';

export default {
  name: 'DsContextMenu',
  props: {
    items: Array,
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  watch: {
    value(value) {
      this.localValue = value;
      this.scrollIndexIntoView(value);
    },
    localValue(value) {
      this.$emit('input', value);
    },
  },
  mounted() {
    this.scrollIndexIntoView(this.value);
  },
  methods: {
    onActivate(itemId) {
      this.$emit('activate', itemId, true);
    },
    scrollIndexIntoView(index) {
      setTimeout(() => {
        const elementByIndex = this.$refs.li[index];
        if (index >= 0 && elementByIndex) {
          smoothScrollIntoView(elementByIndex, {
            boundary: this.$el,
          });
        }
      });
    },
  },
};
</script>

<style>
@import './ContextMenu.css';
</style>
