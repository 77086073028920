<template>
  <component :is="component" class="ds-split-button-item">
    <ds-dropdown-item v-bind="$props" v-on="$listeners">
      <slot />
    </ds-dropdown-item>
  </component>
</template>

<script>
import { debug } from '@core';
import DsSplitButton from '@components/split-button';
import DsDropdownItem from '@components/dropdown-item';
import DsSubmitButton from '@components/submit-button';

export default {
  name: 'DsSplitButtonItem',
  components: {
    DsDropdownItem,
    DsSubmitButton,
  },
  inject: {
    splitButtonVm: {
      default: null,
    },
  },
  props: {
    icon: DsDropdownItem.props.icon,
    disabled: DsDropdownItem.props.disabled,
    type: DsSplitButton.props.type,
    requestAction: {
      type: Function,
    },
  },
  computed: {
    component() {
      return this.type === 'submit' ? 'ds-submit-button' : 'div';
    },
  },
  created() {
    if (!this.splitButtonVm) {
      debug.error('DsSplitButtonItem must be wrapped with DsSplitButton');
    }

    const splitButtonType = this.splitButtonVm?.getType();

    if (this.type === 'submit' && splitButtonType !== 'submit') {
      debug.error('DsSplitButtonItem with "submit" type must be wrapped by DsSplitButton with "submit" type');
    }

    if (this.type === 'request' && splitButtonType === 'default') {
      debug.error(
        'DsSplitButtonItem with "request" type must be wrapped by DsSplitButton with "request" or "submit" type',
      );
    }

    if (this.type === 'request' && !this.requestAction) {
      debug.error('"request-action" prop must be informed when type is "request"');
    }
  },
};
</script>

<style scoped>
@import './SplitButtonItem.css';
</style>
