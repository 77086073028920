<script lang="jsx">
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsSteps from '@components/steps';
import StepperService from './stepperService';

export default {
  name: 'DsStepper',
  components: {
    DsSteps,
    DsRow,
    DsCol,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentStep: this.value,
    };
  },
  watch: {
    value(value) {
      this.currentStep = value;
    },
    currentStep(currentStep) {
      this.$emit('input', currentStep);
    },
  },
  methods: {
    buildContentRow() {
      const h = this.$createElement;
      const $step = this.service.steps[this.currentStep];

      $step.componentOptions.listeners = {
        next: this.next,
        back: this.back,
      };

      const col = h('ds-col', [$step]);
      const row = h('ds-row', { key: this.currentStep }, [col]);

      this.setButtons($step);

      return h(
        'transition',
        {
          props: {
            name: 'fade',
            mode: 'out-in',
            duration: 500,
          },
        },
        [row],
      );
    },
    buildStepperRow() {
      const h = this.$createElement;
      const steps = this.service.steps.map(step => step.componentOptions.propsData);

      const stepper = h('ds-steps', {
        props: {
          steps,
          currentStep: this.currentStep,
        },
      });

      return h('ds-row', { class: 'ds-u-margin-bottom--lg ds-u-margin-top--lg' }, [
        h(
          'ds-col',
          {
            props: {
              size: 8,
              offset: 2,
            },
          },
          [stepper],
        ),
      ]);
    },
    toStep(step) {
      if (this.service.isValidIndex(step)) {
        this.currentStep = step;
      }
    },
    setButtons($step) {
      const { propsData } = $step.componentOptions;

      if (!propsData.labelBackButton) {
        propsData.labelBackButton = this.service.getLabelBackButton(this.currentStep);
      }

      if (!propsData.labelNextButton) {
        propsData.labelNextButton = this.service.getLabelNextButton(this.currentStep);
      }

      if (propsData.hideButtons === undefined) {
        propsData.hideButtons = this.service.shouldHideButtons(this.currentStep);
      }
    },
    next() {
      this.toStep(this.currentStep + 1);
    },
    back() {
      this.toStep(this.currentStep - 1);
    },
  },
  render(h) {
    const steps = this.$slots.default.filter(vnode => !!vnode.tag);
    this.service = new StepperService(steps);

    if (this.service.isValidIndex(this.currentStep)) {
      return h('div', [this.buildStepperRow(), this.buildContentRow()]);
    }

    return this.toStep(0, true);
  },
};
</script>

<style scoped>
@import './Stepper.css';
</style>
