<template>
  <ds-input
    ref="input"
    v-model="number"
    v-bind="$props"
    inputmode="numeric"
    :custom-validations="cardNumberValidations"
    mask-pattern="9999 9999 9999 9999"
    v-on="listeners" />
</template>
<script>
import { constants, creditCardService, stringService, focusMixin } from '@core';
import DsInput from '@components/input';

export default {
  name: 'DsCreditCardNumberInput',
  components: {
    DsInput,
  },
  mixins: [focusMixin.focus('input')],
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
  },
  data() {
    return {
      cardNumberValidations: [
        {
          message: constants.INPUT_ERRORS.INVALID_CARD_NUMBER.MESSAGE,
          valid(value) {
            return creditCardService.isValid(value);
          },
        },
      ],
      number: this.value,
    };
  },
  computed: {
    cardNumber: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
    listeners() {
      return {
        ...this.$listeners,
        change: event => this.$emit('change', event),
        blur: event => this.$emit('blur', event),
      };
    },
  },
  watch: {
    number: {
      handler(value) {
        this.cardNumber = stringService.removeSpaces(value) || null;
      },
    },
  },
};
</script>
