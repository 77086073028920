export default {
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  ESCAPE: 27,
  SPACEBAR: 32,
  ARROW_LEFT: 37,
  ARROW_UP: 38,
  ARROW_RIGHT: 39,
  ARROW_DOWN: 40,
  DIGIT_ZERO: 48,
  DIGIT_NINE: 57,
  DIGIT_ZERO_NUMPAD: 96,
  DIGIT_NINE_NUMPAD: 105,
};
