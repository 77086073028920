<template>
  <div class="ds-topbar-profile-aside-item-group">
    <div v-if="title" class="ds-topbar-profile-aside-item-group__header">
      <ds-icon v-if="icon" :icon="['regular', icon]" size="md" />
      <ds-text :class="classes" weight="bold">{{ title }}</ds-text>
    </div>
    <div class="ds-topbar-profile-aside-item-group__body">
      <slot>
        <ds-list>
          <slot name="list" />
        </ds-list>
      </slot>
    </div>
  </div>
</template>

<script>
import DsList from '@components/list';
import DsIcon from '@components/icon';
import DsText from '@components/text';

export default {
  name: 'DsTopbarProfileAsideItemGroup',
  components: {
    DsText,
    DsList,
    DsIcon,
  },
  props: {
    title: String,
    icon: {
      type: [Array, String],
    },
  },
  computed: {
    classes() {
      return this.icon ? 'ds-u-margin-left--sm' : 'ds-u-margin-left--lg';
    },
  },
};
</script>

<style scoped>
@import './TopbarProfileAsideItemGroup.css';
</style>
