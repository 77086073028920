<template>
  <aside class="ds-sidebar" :class="classes" @mouseover="mouseOver(true)" @mouseleave="mouseOver(false)">
    <ds-button :tooltip="tooltip" class="ds-sidebar__toggle" icon="chevron-left" @click="toggle" @mouseover.stop="">
    </ds-button>
    <div class="ds-sidebar__wrapper">
      <div v-if="$slots.header" class="ds-sidebar-menu__header">
        <div class="ds-sidebar-menu__header-content">
          <slot name="header" />
        </div>
      </div>
      <div class="ds-sidebar-menu__content">
        <slot />
      </div>
      <div v-if="withFooter" class="ds-sidebar-menu__footer">
        <div class="ds-sidebar-menu__footer-content">
          <ds-button
            class="ds-sidebar-menu__footer-button"
            :icon="['regular', 'thumbs-up']"
            theme="link"
            icon-position="left"
            full-width
            @click="emitFooterClick"
            >Avalie o nosso design</ds-button
          >
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import DsButton from '@components/button';

const ANIMATION_TRANSITION = 150;
const WITHOUT_TIME = 0;

export default {
  name: 'DsSidebar',
  components: {
    DsButton,
  },
  provide() {
    return {
      sidebarVm: {
        getTheme: () => this.theme,
      },
    };
  },

  props: {
    theme: {
      type: String,
      default: 'blue-light',
      validator(value) {
        return ['blue-dark', 'blue-light'].includes(value);
      },
    },
    opened: {
      type: Boolean,
      default: true,
    },
    withFooter: {
      type: Boolean,
    },
  },
  data() {
    return {
      shouldShowMenu: this.opened,
      mouseOverShowMenu: false,
      hiddenSideBarTimeOut: null,
    };
  },
  computed: {
    classes() {
      return {
        [`ds-sidebar-${this.theme}-theme`]: true,
        'ds-sidebar--hidden': !this.shouldShowMenu && !this.mouseOverShowMenu,
        'ds-sidebar--absolute': !this.shouldShowMenu,
      };
    },
    tooltip() {
      return !this.shouldShowMenu ? 'Exibir menu' : 'Esconder menu';
    },
  },
  watch: {
    opened(opened) {
      this.shouldShowMenu = opened;
    },
    shouldShowMenu() {
      this.$emit('update:opened', this.shouldShowMenu);
    },
  },
  methods: {
    toggle() {
      this.shouldShowMenu = !this.shouldShowMenu;
      this.mouseOverShowMenu = false;
    },
    mouseOver(mouseEnter) {
      if (this.shouldShowMenu) {
        return;
      }
      const timeout = mouseEnter ? ANIMATION_TRANSITION : WITHOUT_TIME;
      clearTimeout(this.hiddenSideBarTimeOut);
      this.hiddenSideBarTimeOut = setTimeout(() => {
        this.mouseOverShowMenu = mouseEnter;
      }, timeout);
    },
    emitFooterClick() {
      this.$emit('on-footer-click');
    },
  },
};
</script>

<style scoped>
@import './Sidebar.css';
</style>
