import * as directives from '../directives';

const registerComponents = async Vue => {
  const componentModules = import.meta.glob('../components/**/index.js', { eager: true });
  const templateModules = import.meta.glob('../components/templates/**/index.js', { eager: true });
  const betaModules = import.meta.glob('../beta/**/index.js', { eager: true });
  const teamComponentsModules = import.meta.glob('../team-components/**/index.js', { eager: true });

  const register = async modules => {
    Object.keys(modules).forEach(path => {
      const module = modules[path];
      const component = module.default;
      if (component && component.name) {
        Vue.component(component.name, component);
      }
    });
  };

  await register(componentModules);
  await register(templateModules);
  await register(betaModules);
  await register(teamComponentsModules);

  Object.keys(directives).forEach(name => {
    const Directive = directives[name];
    Vue.directive(name, Directive);
  });
};

export default registerComponents;
