<template>
  <hr class="ds-container-divider" />
</template>

<script>
export default {
  name: 'DsContainerDivider',
};
</script>

<style scoped>
@import './ContainerDivider.css';
</style>
