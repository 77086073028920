<template>
  <div v-if="shouldShowWidget" class="ds-widget">
    <ds-slimbox :title="title">
      <template #header>
        <ds-heading truncate size="sm">
          {{ title }}
        </ds-heading>
        <div v-if="hasValidBadge" class="ds-u-margin-left--xs">
          <slot name="badge" />
        </div>
        <div class="ds-header-action">
          <div v-if="tooltip" class="ds-widget-icon ds-u-margin-left--xs">
            <ds-icon v-ds-tooltip="tooltip" icon="question-circle" color="blue" />
          </div>
          <div v-if="hasHeaderActionItemsSlot" class="ds-u-margin-left--xs">
            <ds-dropdown align="right">
              <template #trigger>
                <ds-button icon="ellipsis-h" tooltip="Ações" theme="link" />
              </template>
              <slot name="header-action-items" />
            </ds-dropdown>
          </div>
          <div v-if="allowClose">
            <ds-close-button size="lg" @click="close" />
          </div>
        </div>
      </template>

      <div v-if="shouldShowSkeleton" class="ds-widget__skeleton"></div>

      <template v-if="shouldShowErrorMessage">
        <ds-row class="ds-u-margin-bottom">
          <ds-col size="auto">
            <ds-icon icon="times-circle" color="red-semi-dark" size="xl" />
          </ds-col>
          <ds-col size="fill">
            <ds-p weight="bold">Não foi possível obter os dados</ds-p>
            <ds-p>Por favor, tente novamente</ds-p>
          </ds-col>
        </ds-row>
        <ds-button theme="danger" size="sm" @click="fetch"> Tentar novamente </ds-button>
      </template>

      <template v-if="shouldShowContent">
        <div v-if="shouldShowEmptyState" class="ds-widget-empty">
          <div class="ds-widget-empty__illustration-wrapper">
            <ds-illustration :name="emptyIllustration" width="120px" height="120px" />
          </div>

          <div>
            <ds-p class="ds-widget-empty__text">{{ emptyText }}</ds-p>
          </div>

          <div v-if="hasValidEmptyButton" class="ds-u-margin-top">
            <slot name="emptyButton" />
          </div>
        </div>
        <slot v-else :response="response"></slot>
      </template>
    </ds-slimbox>
  </div>
</template>

<script>
import { debug } from '@core';
import DsP from '@components/paragraph';
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsIcon from '@components/icon';
import DsButton from '@components/button';
import DsSlimbox from '@components/slimbox';
import DsHeading from '@components/heading';
import DsDropdown from '@components/dropdown';
import DsIllustration from '@components/illustration';
import DsTooltip from '@directives/tooltip';
import DsCloseButton from '@components/close-button';
import { isComponent, getPropsData } from '@core/services/vnode/vnodeService';

export default {
  name: 'DsWidget',
  directives: {
    DsTooltip,
  },
  components: {
    DsP,
    DsRow,
    DsCol,
    DsIcon,
    DsButton,
    DsSlimbox,
    DsHeading,
    DsDropdown,
    DsIllustration,
    DsCloseButton,
  },
  inject: {
    widgetGroupVm: {
      default: null,
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    tooltip: {
      type: String,
    },
    isEmpty: {
      type: Boolean,
    },
    emptyIllustration: {
      type: String,
    },
    emptyText: {
      type: String,
    },
    fetchAction: {
      type: Function,
      default: null,
    },
    allowClose: {
      type: Boolean,
    },
  },
  data() {
    return {
      response: null,
      shouldShowContent: false,
      shouldShowErrorMessage: false,
      shouldShowSkeleton: false,
      shouldShowWidget: true,
    };
  },
  computed: {
    hasHeaderActionItemsSlot() {
      return !!this.$slots['header-action-items'];
    },
    shouldShowEmptyState() {
      return this.isEmpty && this.emptyIllustration && this.emptyText;
    },
    hasValidBadge() {
      return this.$slots.badge?.length === 1 && isComponent(this.$slots.badge[0], 'DsBadge');
    },
    hasValidEmptyButtonTheme() {
      const VALID_THEMES = ['link', 'secondary'];
      const { theme } = getPropsData(this.$slots.emptyButton[0]);
      return !theme || VALID_THEMES.includes(theme);
    },
    hasValidEmptyButtonComponent() {
      return (
        isComponent(this.$slots.emptyButton[0], 'DsButton') ||
        isComponent(this.$slots.emptyButton[0], 'DsSplitButton') ||
        isComponent(this.$slots.emptyButton[0], 'DsDropdown')
      );
    },
    hasValidEmptyButton() {
      return (
        this.$slots.emptyButton?.length === 1 && this.hasValidEmptyButtonComponent && this.hasValidEmptyButtonTheme
      );
    },
  },
  watch: {
    isEmpty: {
      handler() {
        if (this.shouldShowEmptyError()) {
          debug.error('It is necessary to declare emptyIllustration and emptyText props when using the isEmpty prop');
        }
      },
      immediate: true,
    },
  },
  created() {
    if (!this.widgetGroupVm) {
      debug.error('DsWidget must be wrapped with DsWidgetGroup');
    }
  },
  mounted() {
    if (this.fetchAction) {
      this.fetch();
    } else {
      this.setContentVisibility(true);
      this.refreshWidgetGroup();
    }
  },
  methods: {
    fetch() {
      this.setContentVisibility(false);
      this.setErrorMessageVisibility(false);
      this.setSkeletonVisibility(true);
      this.refreshWidgetGroup();
      this.fetchAction()
        .then(response => {
          this.handleFetchSuccess(response);
        }, this.handleFetchError)
        .finally(this.handleFetchComplete);
    },
    handleFetchSuccess(response) {
      this.response = response;
      this.setContentVisibility(true);
      this.$emit('fetch-success', response);
    },
    handleFetchError(response) {
      this.response = response;
      this.setErrorMessageVisibility(true);
      this.$emit('fetch-error', response);
    },
    async handleFetchComplete() {
      this.setSkeletonVisibility(false);
      await this.$nextTick();
      this.refreshWidgetGroup();
    },
    setContentVisibility(shouldShow) {
      this.shouldShowContent = shouldShow;
    },
    setErrorMessageVisibility(shouldShow) {
      this.shouldShowErrorMessage = shouldShow;
    },
    setSkeletonVisibility(shouldShow) {
      this.shouldShowSkeleton = shouldShow;
    },
    shouldShowEmptyError() {
      return this.isEmpty && !(this.emptyIllustration && this.emptyText);
    },
    refreshWidgetGroup() {
      if (this.widgetGroupVm) {
        this.widgetGroupVm.refresh();
      }
    },
    setWidgetVisibility(shouldShow) {
      this.shouldShowWidget = shouldShow;
    },
    close() {
      this.setWidgetVisibility(false);
      this.$emit('close');
      this.refreshWidgetGroup();
    },
  },
};
</script>

<style>
@import './Widget.css';
</style>
