<template>
  <div>
    <ds-async-content-wrapper
      v-if="fetch"
      :fetch-action="fetch"
      error-title="Desculpe, não foi possível obter os items"
      @fetch-success="fetchSuccess">
      <ul :class="themeCssClasses" class="ds-list">
        <slot></slot>
      </ul>
    </ds-async-content-wrapper>
    <ul v-else :class="themeCssClasses" class="ds-list">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
import DsAsyncContentWrapper from '@components/async-content-wrapper';

export default {
  name: 'DsList',
  components: {
    DsAsyncContentWrapper,
  },
  props: {
    theme: {
      type: String,
      default: '',
      validator(theme) {
        return !theme || ['check', 'check-grey', 'bullet-point', 'number', 'number-circle'].includes(theme);
      },
    },
    fetch: {
      type: Function,
    },
    fetchSuccess: {
      type: Function,
    },
  },
  provide() {
    return {
      theme: this.theme,
    };
  },
  computed: {
    themeCssClasses() {
      const { theme } = this;
      if (!theme) {
        return '';
      }
      return theme === 'check-grey' ? 'ds-list-check ds-list-check-grey' : `ds-list-${theme}`;
    },
  },
};
</script>

<style>
@import './List.css';
</style>
