<template>
  <div>
    <component
      :is="tableComponent"
      ref="table"
      class="ds-data-grid-table"
      :type="batchType"
      :fetch-action="fetchAction"
      :batch-actions="batchActions"
      :is-collapse="isCollapse"
      :fix-first-columns="fixFirstColumns"
      :fix-last-columns="fixLastColumns"
      @row-selection-change="onRowSelectionChange"
      @fetch-success="onFetchSuccess"
      @fetch-error="onFetchError">
      <component :is="tableRowComponent" slot="header">
        <ds-th
          v-for="column in columns"
          :key="column.key"
          :size="column.size"
          :align="column.align"
          :sortable="column.sortable !== false"
          :tooltip="column.tooltip"
          :theme="column.theme"
          @sort="sortAction">
          {{ column.name }}
        </ds-th>
        <ds-th
          v-if="showActions"
          key="data-grid-actions"
          class="ds-u-print-hidden"
          :style="{ width: biggerActionWidth }">
        </ds-th>
      </component>
      <slot></slot>
    </component>
  </div>
</template>

<script>
import { isFunction } from '@core/services/type/typeService';
import { debug, debounceService } from '@core';
import { toasterService } from '@components/toaster';
import { confirmService } from '@components/confirm';
import DsTh from '@components/table-header';
import DsBatchTable from '@components/batch-table';
import DsTr from '@components/table-row';
import DsIllustration from '@components/illustration';
import DsHeading from '@components/heading';
import DsText from '@components/text';
import DsRequestTable from '@components/request-table';
import DsBatchTableTr from '@components/batch-table-tr';
import dataGridConfirmContentService from './dataGridConfirmContentService';
import { createStore } from '../batch-table/store';

const DEBOUNCE_TIME = 50;

export default {
  name: 'DsDataGridTable',
  provide() {
    return {
      dataGridTableVm: this,
      batchTableStore: this.batchTableStore,
    };
  },
  components: {
    DsTh,
    DsBatchTableTr,
    DsBatchTable,
    DsIllustration,
    DsHeading,
    DsText,
  },
  props: {
    batchType: DsBatchTable.props.type,
    showActions: {
      type: Boolean,
    },
    columns: {
      type: Array,
      required: true,
    },
    customBatchActions: DsBatchTable.props.batchActions,
    fetchAction: {
      type: Function,
      default: () => {},
    },
    batchRemoveAction: Function,
    sortAction: {
      type: Function,
      default: () => {},
    },
    isCollapse: Boolean,
    fixFirstColumns: DsRequestTable.props.fixFirstColumns,
    fixLastColumns: DsRequestTable.props.fixLastColumns,
  },
  data() {
    return {
      actionsWidth: {},
      batchTableStore: createStore({
        keepCheck: true,
        type: this.batchType,
      }),
      refreshTableWithDebounce: null,
    };
  },
  computed: {
    biggerActionWidth() {
      const widths = Object.values(this.actionsWidth);
      const padding = 16 * 2;
      return widths.length ? `${Math.max(...widths) + padding}px` : 'auto';
    },
    isBatchTable() {
      return this.batchActions.length || this.customBatchActions;
    },
    tableComponent() {
      return this.isBatchTable ? DsBatchTable : DsRequestTable;
    },
    tableRowComponent() {
      return this.isBatchTable ? DsBatchTableTr : DsTr;
    },
    batchActions() {
      const batchActions = [...(this.customBatchActions || [])];

      if (this.batchRemoveAction) {
        batchActions.push({ name: 'Excluir', action: this.handleBatchRemoval });
      }

      return batchActions;
    },
  },
  created() {
    if (this.tableComponent === DsBatchTable) {
      debug.warning(
        '%cPlease make sure that all ds-data-grid-tr keys are really unique, coming from the API. Otherwise navigation bugs will happen.',
        'font-size: 12px;',
      );
    } else {
      this.batchTableStore = null;
    }

    this.$on('action-success', this.onActionSuccess);
    this.$on('item-removed', this.onItemRemoved);
    this.$on('item-removal-failed', error => {
      this.$emit('remove-error', error);
    });
    this.refreshTableWithDebounce = debounceService.debounce(this.refreshTable, DEBOUNCE_TIME);
  },
  beforeDestroy() {
    this.refreshTableWithDebounce.clearDebounce();
  },
  methods: {
    onActionSuccess(key, action, response) {
      if (this.clearCheckOnSuccess(action, response)) {
        this.batchTableStore.destroy(key, { force: true });
      }

      if (this.refreshOnSuccess(action, response)) {
        this.refresh();
      }
    },
    removeRowFromStore(key) {
      if (this.batchTableStore) {
        this.batchTableStore.destroy(key, { force: true });
      }
    },
    onItemRemoved(key) {
      this.removeRowFromStore(key);
      this.refresh();
    },
    refresh({ clearChecks } = {}) {
      if (this.batchTableStore && clearChecks) {
        this.batchTableStore.destroyAll({ force: true });
      }

      this.refreshTableWithDebounce();
    },
    refreshTable() {
      this.$refs.table.refresh();
    },
    handleBatchRemoval(rows, keys) {
      return new Promise(resolve => {
        confirmService.confirm({
          title: 'Excluir itens',
          message: this.buildConfirmMessage(),
          onCancel: resolve,
          onConfirm: () => this.onBatchRemovalConfirm(rows, keys, resolve),
        });
      });
    },
    async onBatchRemovalConfirm(rows, keys, resolve) {
      try {
        await this.batchRemoveAction(rows, keys);
        this.onBatchRemoveSuccess(keys);
      } catch (error) {
        this.$emit('batch-remove-error', error);
      } finally {
        resolve();
      }
    },
    onBatchRemoveSuccess(keys) {
      keys.forEach(this.removeRowFromStore);
      this.refresh();
      toasterService.success({
        title: 'Itens removidos!',
        content: 'Os itens selecionados foram removidos com successo',
      });
    },
    buildConfirmMessage() {
      const message = 'Deseja realmente excluir todos os itens selecionados?';
      return dataGridConfirmContentService.build(null, message).outerHTML;
    },
    setActionsHeaderWidth({ key, width }) {
      if (width) {
        this.$set(this.actionsWidth, key, width);
      } else {
        this.$delete(this.actionsWidth, key);
      }
    },
    onFetchSuccess(response) {
      this.$emit('fetch-success', response);
    },
    onFetchError(error) {
      this.$emit('fetch-error', error);
    },
    onRowSelectionChange(...args) {
      this.$emit('row-selection-change', ...args);
    },
    clearCheckOnSuccess(action, response) {
      return isFunction(action.clearCheckOnSuccess) ? action.clearCheckOnSuccess(response) : action.clearCheckOnSuccess;
    },
    refreshOnSuccess(action, response) {
      return isFunction(action.refreshOnSuccess) ? action.refreshOnSuccess(response) : action.refreshOnSuccess;
    },
  },
};
</script>
