import QRCode from 'easyqrcodejs';

export function buildQRCode(elem, text, size) {
  const qrcode = new QRCode(elem, {
    text,
    width: size,
    height: size,
    colorDark: '#000000',
    colorLight: '#ffffff',
  });

  return {
    qrcode,
  };
}
