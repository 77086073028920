<template>
  <div class="ds-table-row-collapse">
    <ds-tr-collapsible
      v-if="shouldShowCollapsibleRow() && isVisible()"
      :collapsed="collapsed"
      :collapse-level="currentCollapseLevel"
      :type="type"
      :theme="theme"
      @click="handleToggleCollapse">
      <slot></slot>
    </ds-tr-collapsible>
    <slot name="nested">
      <ds-tr-nested v-if="!collapsed && shouldShowCollapsibleRow()">
        <ds-td :colspan="totalColumns"> Nenhum registro encontrado </ds-td>
      </ds-tr-nested>
    </slot>
  </div>
</template>

<script>
import { debug } from '@core';
import DsTrNested from '@components/table-row-nested';
import DsTd from '@components/table-column';
import DsTrCollapsible from './TableRowCollapsible.vue';

export default {
  name: 'DsTrCollapse',
  inject: {
    tableStore: {
      default: null,
    },
    collapseTableVm: {
      default: null,
    },
    parentTableRowCollapseVm: {
      from: 'tableRowCollapseVm',
      default: null,
    },
    dataGridTableVm: {
      default: null,
    },
    batchTableVm: {
      default: null,
    },
  },
  provide() {
    return {
      tableRowCollapseVm: this,
    };
  },
  components: {
    DsTrCollapsible,
    DsTrNested,
    DsTd,
  },
  props: {
    initialCollapsed: Boolean,
    showEmptyRows: Boolean,
    type: {
      type: String,
      validator(type) {
        return !type || ['group'].includes(type);
      },
    },
    theme: DsTrCollapsible.props.theme,
  },
  data() {
    return {
      collapsed: this.initialCollapsed,
      currentCollapseLevel: this.getCurrentCollapseLevel(),
      collapseId: null,
    };
  },
  computed: {
    totalColumns() {
      return this.tableStore.getTotalColumns();
    },
  },
  watch: {
    collapsed(value) {
      this.$emit('collapse', value);
    },
  },
  created() {
    if (!this.collapseTableVm) {
      debug.error(
        'Please wrap collapsible rows with ds-collapse-table. If you need to hide the actions bar, use ds-collapse-table hide-actions prop.',
      );
    } else {
      this.collapseId = this.collapseTableVm?.registerCollapseRow(this.getCollapseOptions());
    }
  },
  beforeDestroy() {
    if (this.collapseTableVm) {
      this.collapseTableVm.unregisterCollapseRow(this.collapseId);
    }
  },
  methods: {
    isVisible() {
      if (!this.parentTableRowCollapseVm) {
        return true;
      }

      if (this.parentTableRowCollapseVm.collapsed) {
        return false;
      }

      return this.parentTableRowCollapseVm.isVisible();
    },
    shouldShowCollapsibleRow() {
      return this.showEmptyRows || this.hasNestedChildren();
    },
    hasNestedChildren() {
      return this.$slots.nested && this.$slots.nested.length > 0;
    },
    handleToggleCollapse(event) {
      this.$emit('click', event);

      if (this.dataGridTableVm || this.batchTableVm) return;
      this.toggleCollapse();
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed;
    },
    openCollapse() {
      this.collapsed = false;
    },
    closeCollapse() {
      this.collapsed = true;
    },
    getCurrentCollapseLevel() {
      if (this.type) return 0;
      if (!this.parentTableRowCollapseVm) return 1;
      return this.parentTableRowCollapseVm.currentCollapseLevel + 1;
    },
    getCollapseOptions() {
      return {
        openCollapse: this.openCollapse,
        closeCollapse: this.closeCollapse,
      };
    },
  },
};
</script>

<style>
@import './TableRowCollapse.css';
</style>
