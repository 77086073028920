const ROLLOVER_CLASSES = Object.freeze({
  MAIN_CLASS: 'ds-rollover',
  OPENED_CLASS: 'ds-rollover--is-opened',
  /** The _ is for not compute on [class^="ds-"] selectors */
  BODY_CLASS: '_ds-has-rollover-opened',
  FOCUS_ELEMENT_CLASS: 'ds-rollover-focus',
  ACTIVE_CLASS: 'ds-rollover--active',
});

// this values are connected with css animation values in transition.css file.
const ROLLOVER_ANIMATION = Object.freeze({
  SLOW: 200,
  MEDIUM: 400,
  FAST: 800,
});

const ROLLOVER_HOOKS = Object.freeze({
  ON_BEFORE_ROLLOVER_OPENED: 'onBeforeRolloverOpened',
  ON_ROLLOVER_OPENED: 'onRolloverOpened',
  ON_BEFORE_ROLLOVER_CLOSED: 'onBeforeRolloverClosed',
  ON_ROLLOVER_CLOSED: 'onRolloverClosed',
  ON_ALL_ROLLOVERS_CLOSED: 'onAllRolloversClosed',
});

export default Object.freeze({
  ROLLOVER_CLASSES,
  ROLLOVER_HOOKS,
  ROLLOVER_ANIMATION,
});
