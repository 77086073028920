import { dateService } from '@core';

export function isStartDateEnabled(date, { minDate, maxDate }) {
  const isAfterMinDate = !minDate || dateService.isDateAfterOrEqual(date, minDate);
  const isBeforeMaxDate = !maxDate || dateService.isDateBeforeOrEqual(date, maxDate);
  return isAfterMinDate && isBeforeMaxDate;
}

export function isEndDateEnabled(date, { startDate, minDate, maxDate, maxDateRange }) {
  const calculatedMaxDate = maxDate || maxDateRange ? getMaxDate({ startDate, maxDate, maxDateRange }) : null;
  const isAfterStartDate = !startDate || !maxDateRange || dateService.isDateAfterOrEqual(date, startDate);
  const isAfterMinDate = !minDate || dateService.isDateAfterOrEqual(date, minDate);
  const isBeforeMaxDate = !calculatedMaxDate || dateService.isDateBeforeOrEqual(date, calculatedMaxDate);
  return isAfterStartDate && isAfterMinDate && isBeforeMaxDate;
}

export function focusInput(element) {
  const inputDate = getInputElement(element);
  if (inputDate) {
    inputDate.focus();
  }
}

function getInputElement(element) {
  return element.querySelector('input');
}

export function isMonthPeriodType(type) {
  return type === 'month';
}

function getMaxDate({ startDate, maxDate, maxDateRange }) {
  const maxDateByRange = maxDateRange && startDate ? dateService.addDays(startDate, maxDateRange - 1) : null;

  if (maxDateByRange && maxDate) {
    return dateService.isDateBeforeOrEqual(maxDate, maxDateByRange) ? maxDate : maxDateByRange;
  }

  return maxDateByRange ?? maxDate;
}
