<template>
  <ds-date-period-filter
    ref="datePeriodFilter"
    :key="localKey"
    :disabled="disabled"
    :period-type="localPeriodType"
    :period-types="periodTypes"
    :value="value"
    @input="onInput" />
</template>

<script>
import isEmpty from 'lodash/isEmpty';
import DsDatePeriodFilter from '@components/date-period-filter';

export default {
  name: 'DsDataGridDatePeriodFilter',
  components: {
    DsDatePeriodFilter,
  },
  props: {
    value: DsDatePeriodFilter.props.value,
    periodType: DsDatePeriodFilter.props.periodType,
    periodTypes: DsDatePeriodFilter.props.periodTypes,
    disabled: DsDatePeriodFilter.props.disabled,
  },
  data() {
    return {
      localKey: 1,
      localPeriodType: undefined,
    };
  },
  watch: {
    value: {
      handler(value) {
        if (isEmpty(value)) {
          this.localKey += 1;
        }
      },
    },
    periodType: {
      immediate: true,
      handler(value) {
        if (!value && this.localPeriodType) {
          return;
        }

        this.localPeriodType = value;
      },
    },
  },
  methods: {
    onInput(value, periodType) {
      this.$emit('input', value, periodType);
    },
    /**
     * Deprecated
     */
    setPeriodArrowsNavigationBy(type) {
      this.$refs.datePeriodFilter.setArrowsNavigationBy(type);
    },
  },
};
</script>
