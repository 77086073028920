<template>
  <div class="ds-file-upload">
    <ds-file-input
      v-show="!file"
      ref="fileInput"
      v-model="file"
      :accepted-file-types="acceptedFileTypes"
      :on-file-select="onFileSelect"
      :disabled="disabled"
      :required="required"
      :min-size="minSize"
      :max-size="maxSize"
      :custom-validations="customValidations" />
    <ds-file-preview
      v-if="file"
      :file="file"
      :status="status"
      :disabled="disabled"
      @retry-upload="uploadFile(file)"
      @replace-file="replaceFile"
      @delete-file="deleteFile(file)" />
  </div>
</template>

<script>
import { focusMixin } from '@core';
import DsFilePreview from '@components/file-preview';
import DsFileInput from './FileInput.vue';

const UPLOAD_FAILED_ERROR_MESSAGE = 'Houve uma falha no upload do arquivo. Tente novamente por favor';

export default {
  name: 'DsFileUpload',
  inject: {
    fieldVm: {
      default: null,
    },
  },
  components: {
    DsFileInput,
    DsFilePreview,
  },
  mixins: [focusMixin.focus('fileInput')],
  props: {
    value: {
      type: [String, Object],
    },
    onUpload: {
      type: Function,
      required: true,
    },
    onUploadSuccess: {
      type: Function,
      default: () => {},
    },
    onUploadError: {
      type: Function,
      default: () => {},
    },
    onDelete: {
      type: Function,
      default: () => {},
    },
    disabled: DsFileInput.props.disabled,
    required: DsFileInput.props.required,
    /**
     * Any value (in bytes)
     */
    minSize: DsFileInput.props.minSize,
    /**
     * Any value (in bytes)
     */
    maxSize: DsFileInput.props.maxSize,
    /**
     * Any string on Unique file type specifiers format
     */
    acceptedFileTypes: DsFileInput.props.acceptedFileTypes,
    onFileSelect: DsFileInput.props.onFileSelect,
    /**
     * [{ valid: Function, message: String }]
     */
    customValidations: DsFileInput.props.customValidations,
  },
  data() {
    return {
      file: null,
      isUploading: false,
      hasError: false,
    };
  },
  computed: {
    isFileValid() {
      return this.fieldVm?.modelValidator.valid;
    },
    status() {
      if (this.isUploading) {
        return 'uploading';
      }
      if (this.hasError) {
        return 'error';
      }
      if (!this.isFileValid) {
        return 'invalid';
      }
      return 'valid';
    },
  },
  mounted() {
    if (this.value) {
      this.$refs.fileInput.handleFileSelection(this.value);
    }
    this.$watch('file', this.handleFileUpdate);
  },
  methods: {
    handleFileUpdate() {
      if (this.file && this.isFileValid) {
        this.uploadFile(this.file);
      }
    },
    replaceFile() {
      this.$refs.fileInput.openFileSystem();
    },
    deleteFile(file) {
      this.hasError = false;
      this.file = null;
      this.$refs.fileInput.setModelValidationFile([]);
      this.onDelete(file);
      this.$emit('input', null);
    },
    addModelValidationError(errorMessage) {
      if (this.fieldVm) {
        this.fieldVm.modelValidator._addError({ message: errorMessage });
      }
    },
    removeAllErrors() {
      if (this.fieldVm) {
        this.fieldVm.modelValidator._removeAllErrors();
      }
    },
    async handleUploadErrors(hasError) {
      this.hasError = hasError;
      this.removeAllErrors();
      await this.$nextTick();
      this.addModelValidationError(hasError ? UPLOAD_FAILED_ERROR_MESSAGE : null);
    },
    async uploadFile(file) {
      try {
        this.isUploading = true;
        await this.handleUploadErrors(false);
        const response = await this.onUpload(file);
        this.removeAllErrors();
        this.onUploadSuccess(response);
        this.$emit('input', response);
      } catch (e) {
        await this.handleUploadErrors(true);
        this.onUploadError(e);
        this.$emit('input', null);
      } finally {
        this.isUploading = false;
      }
    },
  },
};
</script>
