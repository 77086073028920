<template>
  <div ref="qrcode"></div>
</template>
<script>
import { buildQRCode } from './qrcodeService';

const SIZES = {
  sm: 80,
  md: 120,
};

export default {
  name: 'DsQrcode',
  props: {
    value: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'md',
      validator(size) {
        return !size || Object.keys(SIZES).includes(size);
      },
    },
  },
  computed: {
    localSize() {
      return SIZES[this.size];
    },
  },
  mounted() {
    buildQRCode(this.$refs.qrcode, this.value, this.localSize);
  },
};
</script>
