<template>
  <span :class="classes" class="ds-loader-button">
    <component
      :is="buttonIs"
      ref="buttonLoader"
      class="ds-loader-button__button"
      v-bind="$attrs"
      :disabled="isButtonDisabled"
      :type="type"
      :theme="theme"
      :size="size"
      :full-width="fullWidth"
      :icon="icon"
      v-on="$listeners">
      <div v-if="!isIconOnly" class="ds-loader-button__content">
        <slot />
      </div>
      <ds-loader v-if="isLoading" class="ds-loader-button__loader" theme="inside" />
    </component>
  </span>
</template>

<script>
import { focusMixin } from '@core';
import DsButton from '@components/button';
import DsLoader from '@components/loader';

export default {
  name: 'DsButtonLoader',
  provide() {
    return {
      isIconOnly: this.isIconOnly,
    };
  },
  components: {
    DsButton,
    DsLoader,
  },
  mixins: [focusMixin.focus('buttonLoader')],
  props: {
    icon: DsButton.props.icon,
    fullWidth: DsButton.props.fullWidth,
    buttonIs: {
      type: [Object, String],
      default: 'ds-button',
    },
    disabled: {
      type: Boolean,
    },
    theme: {
      type: String,
    },
    size: {
      type: String,
      validator(size) {
        return ['sm'].includes(size);
      },
    },
    type: {
      type: String,
      default: 'button',
    },
    isLoading: {
      type: Boolean,
    },
  },
  computed: {
    isIconOnly() {
      return this.icon && !this.$slots.default?.length;
    },
    isButtonDisabled() {
      return this.disabled || this.isLoading;
    },
    classes() {
      return { 'is-loading': this.isLoading };
    },
  },
};
</script>

<style scoped>
@import './LoaderButton.css';
</style>
