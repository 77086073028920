<script lang="jsx">
import DsText from '@components/text';
import { formatResultsLabel } from './paginationService';

export default {
  name: 'DsPaginationNavigationLabel',
  functional: true,
  render(h, { props }) {
    return <DsText class="ds-pagination-navigation-label">Mostrando {formatResultsLabel(props)} registros</DsText>;
  },
};
</script>
