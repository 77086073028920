<template>
  <ds-slimbox-check
    class="ds-slimbox-checkbox"
    role="checkbox"
    :selected="localChecked"
    :disabled="disabled"
    :overflow-max-height="overflowMaxHeight"
    :full-height="fullHeight"
    @select="onSelect">
    <ds-checkbox
      slot="check"
      v-model="localChecked"
      :value="value"
      :disabled="disabled"
      tabindex="-1"
      class="ds-u-margin-right--sm" />
    <slot slot="heading" name="header" />
    <slot slot="header-right" name="header-right" />

    <slot />
  </ds-slimbox-check>
</template>

<script>
import DsSlimboxCheck from '@components/slimbox-check/SlimboxCheck.vue';
import DsCheckbox from '@components/checkbox';

export default {
  name: 'DsSlimboxCheckbox',
  components: {
    DsSlimboxCheck,
    DsCheckbox,
  },
  inject: {
    checkboxGroupVm: {
      default: null,
    },
  },
  props: {
    value: DsCheckbox.props.value,
    disabled: DsCheckbox.props.disabled,
    overflowMaxHeight: DsSlimboxCheck.props.overflowMaxHeight,
    checked: Boolean,
    fullHeight: Boolean,
  },
  data() {
    return {
      localChecked: this.checked || this.isInitialCheckedOnGroup(),
      ariaLabelledby: `slimbox-heading-${this.value}`,
    };
  },
  watch: {
    checked() {
      this.localChecked = this.checked;
    },
    localChecked() {
      this.$emit('change', this.localChecked);
    },
  },
  methods: {
    isInitialCheckedOnGroup() {
      return this.checkboxGroupVm?.isOptionInitialChecked(this.value) || false;
    },
    onSelect() {
      this.localChecked = !this.localChecked;
    },
  },
};
</script>
