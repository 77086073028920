<template>
  <ds-button
    tooltip="Limpar"
    class="ds-input-clear-icon"
    icon="times-circle"
    :size="size"
    color="text"
    theme="link"
    @focus="$emit('focus', $event)"
    @click="$emit('click', $event)" />
</template>

<script>
import DsButton from '@components/button';

export default {
  name: 'DsInputClearIcon',
  components: {
    DsButton,
  },
  props: {
    size: {
      type: String,
      default: 'sm',
    },
  },
};
</script>
