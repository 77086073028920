<template>
  <ds-range-popover :on-apply="onSubmit" @hook:destroyed="$destroy()">
    <ds-field slot="start-field" label="Número inicial">
      <ds-number-input ref="startInput" v-model="localStartValue" :required="required" @blur="validateRange" />
    </ds-field>
    <ds-field slot="end-field" label="Número final">
      <ds-number-input ref="endInput" v-model="localEndValue" :custom-validations="customValidations" />
    </ds-field>
  </ds-range-popover>
</template>

<script>
import DsRangePopover from '@components/range-popover/RangePopover.vue';
import DsField from '@components/field';
import DsNumberInput from '@components/number-input';

export default {
  name: 'DsNumberRangePopover',
  components: {
    DsNumberInput,
    DsField,
    DsRangePopover,
  },
  props: {
    startValue: {
      type: Number,
      default: null,
    },
    endValue: {
      type: Number,
      default: null,
    },
    onApply: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      required: true,
      localStartValue: this.startValue,
      localEndValue: this.endValue,
      customValidations: [
        {
          message: 'Digite um número maior ou igual ao inicial',
          valid: this.isValidRange,
        },
      ],
    };
  },
  watch: {
    localEndValue(value) {
      this.required = !value;
    },
  },
  methods: {
    getInputElement(element) {
      return element.querySelector('input');
    },
    isValidRange() {
      if (this.localStartValue && this.localEndValue) {
        return this.localEndValue >= this.localStartValue;
      }
      return true;
    },
    validateRange() {
      if (!this.isValidRange()) {
        const element = this.getInputElement(this.$refs.endInput.$el);
        element.focus();
        element.blur();
      }
    },
    onSubmit() {
      return this.onApply({
        startValue: this.localStartValue,
        endValue: this.localEndValue,
      });
    },
  },
};
</script>
