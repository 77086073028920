<template>
  <ds-popover ref="popover" class="ds-data-grid-advanced-filter-select-popover" @hook:destroyed="$destroy()">
    <ds-multiple-select-filter
      ref="select"
      v-model="localValue"
      v-bind="$attrs"
      compare-value-by="key"
      :load-more="showLoadMore"
      :should-match-term-pattern="shouldMatchTermPattern"
      :on-load-more-button-click="onLoadMoreButtonClick"
      :on-fetch="onFetch"
      :on-fetch-success="onFetchSuccess"
      :async-options="fetchedOptions"
      :on-query="handleQuery"
      :on-query-success="onQuerySuccess"
      :on-close="onCloseSelect">
      <ds-option v-for="option in options" :key="option.key" :value="option" :disabled="option.disabled">
        {{ option.name }}
      </ds-option>
    </ds-multiple-select-filter>
  </ds-popover>
</template>

<script>
import DsPopover from '@components/popover';
import DsMultipleSelectFilter from '@components/multiple-select-filter';
import DsOption from '@components/option';

const DEFAULT_PAGE_NUMBER = 1;

export default {
  name: 'DsDataGridAdvancedFilterSelectPopover',
  components: {
    DsPopover,
    DsOption,
    DsMultipleSelectFilter,
  },
  props: {
    setValue: {
      type: Function,
      required: true,
    },
    hasEmptyOption: {
      type: Boolean,
      default: false,
    },
    onQuery: {
      type: Function,
      required: true,
    },
    onFetch: {
      type: Function,
      required: true,
    },
    value: {
      type: Array,
    },
    loadMore: {
      type: Boolean,
    },
    shouldMatchTermPattern: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      localValue: this.value,
      fetchedOptions: [],
      options: [],
      page: DEFAULT_PAGE_NUMBER,
      showLoadMore: this.loadMore,
    };
  },
  beforeDestroy() {
    this.setValue(this.localValue);
  },
  mounted() {
    setTimeout(() => {
      this.$refs.select.open();
    });
  },
  methods: {
    async onLoadMoreButtonClick(term) {
      this.page += DEFAULT_PAGE_NUMBER;
      const isLoadingMoreItems = true;
      const newOptions = await this.handleQuery(term, isLoadingMoreItems);

      if (newOptions?.length) {
        this.onQuerySuccess([...this.options, ...newOptions]);
      } else {
        setTimeout(() => {
          this.showLoadMore = false;
        });
      }
    },
    onCloseSelect() {
      this.$refs.popover.close();
    },
    handleQuery(term, isLoadingMoreItems) {
      if (!isLoadingMoreItems) {
        this.page = DEFAULT_PAGE_NUMBER;
      }
      return this.onQuery(term, this.page);
    },
    onFetchSuccess(values) {
      this.fetchedOptions = values;
    },
    onQuerySuccess(options) {
      this.options = options;
      if (this.hasEmptyOption && !this.alreadyHasAnEmptyOption()) {
        this.insertEmptyOption();
      }
    },
    alreadyHasAnEmptyOption() {
      return this.options.some(option => option.key === 'empty');
    },
    insertEmptyOption() {
      const emptyOption = {
        key: 'empty',
        value: 'empty',
        name: '(Em branco)',
      };
      this.options.unshift(emptyOption);
    },
  },
};
</script>

<style scoped>
@import './DataGridAdvancedFilterSelectPopover.css';
</style>
