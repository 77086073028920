<template>
  <div class="ds-topbar-aside-item">
    <ds-list-item>
      <ds-row-divider />
      <div class="ds-topbar-aside-item__content">
        <component :is="isComponent" with-icon :href="href" @click="onClick">
          <ds-p color="blue" class="ds-topbar-aside-item__name">
            {{ name }}
          </ds-p>
          <ds-p color="blue" class="ds-topbar-aside-item__description">
            {{ description }}
          </ds-p>
        </component>
      </div>
    </ds-list-item>
  </div>
</template>

<script>
import DsLink from '@components/link';
import DsExternalLink from '@components/external-link';
import DsListItem from '@components/list-item';
import DsP from '@components/paragraph';
import DsRowDivider from '@components/row-divider';

export default {
  name: 'DsTopbarAsideItem',
  components: {
    DsLink,
    DsExternalLink,
    DsListItem,
    DsP,
    DsRowDivider,
  },
  inject: ['topbarVm'],
  props: {
    name: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    description: {
      type: String,
    },
    isInternal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isComponent() {
      return this.isInternal ? DsLink : DsExternalLink;
    },
  },
  methods: {
    onClick() {
      this.topbarVm.clearActiveItem();
    },
  },
};
</script>

<style scoped>
@import './TopbarAsideItem.css';
</style>
