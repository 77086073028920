<script lang="jsx">
import DsTooltip from '@directives/tooltip';
import { hasConstructor } from '@core/services/browser/browserService';

export default {
  name: 'DsTooltippedOverflow',
  mounted() {
    if (hasConstructor('ResizeObserver')) {
      this.configureMainResizeObserver();
    }
    setTimeout(this.onElementFullRender);
  },
  methods: {
    onElementFullRender() {
      this.buildTootippedOverflow();
    },
    buildTootippedOverflow() {
      if (this.$el.offsetWidth >= this.$el.scrollWidth) {
        return this.disableTooltip();
      }
      return this.enableTooltip();
    },
    disableTooltip() {
      if (this.$el) {
        DsTooltip.disable(this.$el);
      }
    },
    enableTooltip() {
      if (this.$el) {
        DsTooltip.enable(this.$el);
      }
    },
    configureMainResizeObserver() {
      this.mainResizeObserver = new ResizeObserver(this.onElementFullRender);
      this.mainResizeObserver.observe(this.$slots.default[0].elm);

      this.$on('hook:destroyed', () => {
        this.mainResizeObserver.disconnect();
      });
    },
  },
  render() {
    return this.$slots.default[0];
  },
};
</script>
