<template>
  <span class="ql-formats">
    <button
      v-for="align in aligns"
      :key="align.value"
      v-ds-tooltip="align.tooltip"
      :value="align.value"
      class="ql-align"></button>
  </span>
</template>

<script>
import DsTooltip from '@directives/tooltip';
import { RICH_TEXT_ALIGNS } from './richTextConstants';

export default {
  name: 'DsRichTextToolbarAlign',
  directives: {
    DsTooltip,
  },
  data() {
    return {
      aligns: RICH_TEXT_ALIGNS,
    };
  },
};
</script>
