const COLORS_MAP = {
  white: '#ffffff',
  'grey-lightest': '#f4f4f4',
  'grey-semi-light': '#E5E5E5',
  'grey-light': '#c6c6c6',
  grey: '#9b9b9b',
  'grey-dark': '#575757',
  'grey-darkest': '#333333',
  overlay: '#395a7a',

  'blue-lightest': '#D3F1FD',
  'blue-light': '#7BC6F8',
  'blue-semi-light': '#5AADF1',
  blue: '#2687E9',
  'blue-semi-dark': '#1B69C8',
  'blue-dark': '#134EA7',
  'blue-darkest': '#07266F',

  'blue-lightest-smb': '#CBFEFD',
  'blue-light-smb': '#64E6FA',
  'blue-semi-light-smb': '#3DD0F6',
  'blue-smb': '#00AFF0',
  'blue-semi-dark-smb': '#0088CE',
  'blue-dark-smb': '#0066AC',
  'blue-darkest-smb': '#003473',

  'blue-lightest-acc': '#D0D6FB',
  'blue-light-acc': '#7080EC',
  'blue-semi-light-acc': '#4B5CDA',
  'blue-acc': '#192BC2',
  'blue-semi-dark-acc': '#1220A6',
  'blue-dark-acc': '#0C178B',
  'blue-darkest-acc': '#040A5D',

  'green-lightest': '#D9FBD3',
  'green-light': '#7AE87E',
  'green-semi-light': '#55D266',
  green: '#26B547',
  'green-semi-dark': '#1B9B45',
  'green-dark': '#138242',
  'green-darkest': '#075638',

  'red-lightest': '#FFE5D6',
  'red-light': '#FF9F83',
  'red-semi-light': '#FF7A65',
  red: '#FF3D32',
  'red-semi-dark': '#DB242A',
  'red-dark': '#B7192B',
  'red-darkest': '#7A0929',

  'orange-dark': '#775738',
  'orange-semi-dark': '#B57948',
  orange: '#D3792F',
  'orange-semi-light': '#F6C39B',
  'orange-light': '#F7E7D9',

  'yellow-lightest': '#FFF6D1',
  'yellow-light': '#FFDE76',
  'yellow-semi-light': '#FFD054',
  yellow: '#FFBA1C',
  'yellow-semi-dark': '#DB9814',
  'yellow-dark': '#B7790E',
  'yellow-darkest': '#7A4805',

  'caplus-dark': '#001959',

  'icon-default': '#385A7A',
  'icon-footer': '#627B97',
  'icon-notification': '#0F4E77',
  'icon-notification-open': '#FFFFFF',
  'icon-blankslate': '#508FCE',
  'icon-pricing-table-details-row': '#798399',

  'bg-header': '#c6c6c6s',
  'bg-default': '#ffffff',
  'bg-default-hover': '#dfe6f1',
  'border-default': '#c9d3dd',

  text: '#333333',
  bg: '#f1f4f9',
  dividers: '#c9d3dd',

  'border-progress-bar': '#CCCCCC',
  'dropdown-active': '#0275D8',

  'bronze-semi-light': '#F28940',
  bronze: '#E07934',
  'bronze-semi-dark': '#AA6C09',

  'silver-semi-light': '#EDEDED',
  silver: '#DADEEF',
  'silver-dark': '#A1A9C6',

  'gold-semi-light': '#F7E13E',
  gold: '#FFD141',
  'gold-semi-dark': '#F4B840',

  'purple-light': '#B29DF4',
  purple: '#8071D2',
  'purple-semi-dark': '#5E4FB7',
  'purple-dark': '#1D1560',
};

const COLORS_MAP_DARK = {
  'white-dark-mode': '#121212',
  'grey-lightest-dark-mode': '#3F3F3F',
  'grey-semi-light-dark-mode': '#3c3c3c',
  'grey-light-dark-mode': '#c6c6c6',
  'grey-dark-mode': '#A0A0A0',
  'grey-dark-dark-mode': '#575757',
  'grey-darkest-dark-mode': '#E6E6E6',
  'overlay-dark-mode': '#ffffff 30%',

  'blue-lightest-dark-mode': '#183766',
  'blue-light-dark-mode': '#7BC6F8',
  'blue-semi-light-dark-mode': '#5AADF1',
  'blue-dark-mode': '#90CAF9',
  'blue-semi-dark-dark-mode': '#90CAF9',
  'blue-dark-dark-mode': '#90CAF9',
  'blue-darkest-dark-mode': '#D3F1FD',

  'blue-lightest-smb-dark-mode': '#00AFF0',
  'blue-light-smb-dark-mode': '#64E6FA',
  'blue-semi-light-smb-dark-mode': '#3DD0F6',
  'blue-smb-dark-mode': '#00AFF0',
  'blue-semi-dark-smb-dark-mode': '#2E2E2E',
  'blue-dark-smb-dark-mode': '#0066AC',
  'blue-darkest-smb-dark-mode': '#121212',

  'blue-lightest-acc-dark-mode': '#D0D6FB',
  'blue-light-acc-dark-mode': '#7080EC',
  'blue-semi-light-acc-dark-mode': '#4B5CDA',
  'blue-acc-dark-mode': '#192BC2',
  'blue-semi-dark-acc-dark-mode': '#1220A6',
  'blue-dark-acc-dark-mode': '#0C178B',
  'blue-darkest-acc-dark-mode': '#040A5D',

  'green-lightest-dark-mode': '#123E12',
  'green-light-dark-mode': '#007422',
  'green-semi-light-dark-mode': '#55D266',
  'green-dark-mode': '#26B547',
  'green-semi-dark-dark-mode': '#99EBAA',
  'green-dark-dark-mode': '#138242',
  'green-darkest-dark-mode': '#D9FBD3',

  'red-lightest-dark-mode': '#500C20',
  'red-light-dark-mode': '#FF9F83',
  'red-semi-light-dark-mode': '#FF7A65',
  'red-dark-mode': '#FF3D32',
  'red-semi-dark-dark-mode': '#F67F94',
  'red-dark-dark-mode': '#FFE5D6',
  'red-darkest-dark-mode': '#7A0929',

  'orange-dark-dark-mode': '#775738',
  'orange-semi-dark-dark-mode': '#B57948',
  'orange-dark-mode': '#D3792F',
  'orange-semi-light-dark-mode': '#F6C39B',
  'orange-light-dark-mode': '#F7E7D9',

  'yellow-lightest-dark-mode': '#532F0B',
  'yellow-light-dark-mode': '#FFDE76',
  'yellow-semi-light-dark-mode': '#FFD054',
  'yellow-dark-mode': '#FFBA1C',
  'yellow-semi-dark-dark-mode': '#FFDE76',
  'yellow-dark-dark-mode': '#B7790E',
  'yellow-darkest-dark-mode': '#F4BC8A',

  'caplus-dark-dark-mode': '#001959',

  'icon-default-dark-mode': '#385A7A',
  'icon-footer-dark-mode': '#627B97',
  'icon-notification-dark-mode': '#0F4E77',
  'icon-notification-open-dark-mode': '#FFFFFF',
  'icon-blankslate-dark-mode': '#508FCE',
  'icon-pricing-table-details-row-dark-mode': '#798399',

  'bg-header-dark-mode': '#c6c6c6s',
  'bg-default-dark-mode': '#121212',
  'bg-default-hover-dark-mode': '#dfe6f1',
  'border-default-dark-mode': '#595959',

  'text-dark-mode': '#E1E2E3',
  'bg-dark-mode': '#1A1E24',
  'dividers-dark-mode': '#595959',

  'border-progress-bar-dark-mode': '#CCCCCC',
  'dropdown-active-dark-mode': '#0275D8',

  'bronze-semi-light-dark-mode': '#F28940',
  'bronze-dark-mode': '#E07934',
  'bronze-semi-dark-dark-mode': '#AA6C09',

  'silver-semi-light-dark-mode': '#EDEDED',
  'silver-dark-mode': '#DADEEF',
  'silver-dark-dark-mode': '#A1A9C6',

  'gold-semi-light-dark-mode': '#F7E13E',
  'gold-dark-mode': '#FFD141',
  'gold-semi-dark-dark-mode': '#F4B840',

  'purple-light-dark-mode': '#B29DF4',
  'purple-dark-mode': '#8071D2',
  'purple-semi-dark-dark-mode': '#5E4FB7',
  'purple-dark-dark-mode': '#1D1560',
};

const COLORS_NAMES = Object.keys(COLORS_MAP);
const COLORS_NAMES_DARK = Object.keys(COLORS_MAP_DARK);

module.exports = {
  COLORS: COLORS_NAMES,
  COLORS_DARK: COLORS_NAMES_DARK,
  COLORS_MAP,
  COLORS_MAP_DARK,
};
