<template>
  <div class="ds-responsive">
    <template v-if="isSmallBreakpoint">
      <slot name="sm" />
    </template>
    <template v-if="!isSmallBreakpoint">
      <slot name="no-sm" />
    </template>
    <slot />
  </div>
</template>

<script>
import { setupResponsiveHandler } from './responsiveService';

export default {
  name: 'DsResponsive',
  data() {
    return {
      breakpoint: null,
      unsubcribeListener: null,
    };
  },
  computed: {
    isSmallBreakpoint() {
      return this.breakpoint === 'sm';
    },
  },
  watch: {
    breakpoint(breakpoint) {
      this.$emit('resized', breakpoint);
    },
  },
  mounted() {
    this.unsubcribeListener = setupResponsiveHandler(this.setBreakpoint);
  },
  beforeDestroy() {
    this.unsubcribeListener();
  },
  methods: {
    setBreakpoint(breakpoint) {
      this.breakpoint = breakpoint;
    },
  },
};
</script>
