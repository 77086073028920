<template>
  <component :is="wrapperElement" class="ds-percent-input" add-on-text-right="%">
    <ds-numeral-input
      ref="input"
      :value="value"
      :placeholder="placeholder"
      :precision="precision"
      :suffix="suffixText"
      :custom-validations="customValidations"
      v-bind="$attrs"
      @custom-changed="onCustomChanged"
      @custom-input="onCustomInput" />
  </component>
</template>

<script>
import { focusMixin } from '@core';
import DsInputGroup from '@components/input-group';
import DsNumeralInput from '@components/numeral-input/NumeralInput.vue';

export default {
  name: 'DsPercentInput',
  components: {
    DsNumeralInput,
    DsInputGroup,
  },
  mixins: [focusMixin.focus('input')],
  inheritAttrs: false,
  props: {
    /**
     * Number or null
     */
    value: DsNumeralInput.props.value,
    /**
     * [{ valid: Function, message: String }]
     */
    customValidations: DsNumeralInput.props.customValidations,
    precision: {
      type: Number,
      default: 2,
    },
    externalSymbol: {
      type: Boolean,
      default: true,
    },
    internalSymbol: Boolean,
    placeholder: String,
  },
  computed: {
    suffixText() {
      return this.internalSymbol ? '%' : undefined;
    },
    wrapperElement() {
      return this.externalSymbol && !this.internalSymbol ? 'ds-input-group' : 'div';
    },
  },
  methods: {
    onCustomInput(value) {
      this.$emit('input', value);
    },
    onCustomChanged(value) {
      this.$emit('change', value);
    },
  },
};
</script>
