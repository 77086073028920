<template>
  <ds-dropdown size="sm" align="right" title="Ações" prevent-click-row>
    <template v-for="action in actions">
      <template v-if="action.header && action.actions">
        <ds-dropdown-header :key="action.header">
          {{ action.header }}
        </ds-dropdown-header>
        <ds-dropdown-item
          v-for="headerAction in action.actions"
          :key="headerAction.name"
          v-ds-tooltip="handleTooltip(headerAction)"
          :disabled="handleDisabled(headerAction)"
          :request-action="getActionHandler(headerAction)">
          {{ headerAction.name }}
        </ds-dropdown-item>
      </template>
      <ds-dropdown-item
        v-else
        :key="action.name"
        v-ds-tooltip="handleTooltip(action)"
        :disabled="handleDisabled(action)"
        :request-action="getActionHandler(action)">
        {{ action.name }}
        <ds-badge v-if="shouldShowBadge(action)" slot="badge" :theme="action.badge.theme">
          {{ action.badge.text }}
          <ds-icon
            v-if="action.badge.icon"
            :icon="action.badge.icon"
            size="sm"
            class="ds-data-grid-actions__dropdown-item-badge-icon"
        /></ds-badge>
      </ds-dropdown-item>
    </template>
  </ds-dropdown>
</template>

<script>
import DsDropdown from '@components/dropdown';
import DsDropdownItem from '@components/dropdown-item';
import DsDropdownHeader from '@components/dropdown-header';
import DsBadge from '@components/badge';
import DsIcon from '@components/icon';
import DsTooltip from '@directives/tooltip';
import { debug } from '@core';

export default {
  components: {
    DsDropdown,
    DsDropdownItem,
    DsDropdownHeader,
    DsBadge,
    DsIcon,
  },
  directives: {
    DsTooltip,
  },
  provide() {
    return {
      getActionHandler: this.getActionHandler,
    };
  },
  props: {
    actions: Array,
    getActionHandler: Function,
    item: Object,
  },
  methods: {
    handleDisabled({ disabled }) {
      return typeof disabled === 'function' ? disabled(this.item) : disabled;
    },
    handleTooltip(action) {
      const isDisabled = this.handleDisabled(action);

      if (isDisabled && !action.disabledTooltip) {
        debug.error('"disabledTooltip" prop must be informed when a custom action is disabled');
      }
      return isDisabled ? action.disabledTooltip : null;
    },
    shouldShowBadge(action) {
      return action?.badge?.text;
    },
  },
};
</script>
