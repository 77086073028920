<template>
  <div class="ds-tc-sortable-list">
    <ds-row class="ds-tc-sortable-header">
      <ds-col class="ds-tc-sortable-header__col-level"></ds-col>
      <ds-col size="fill" class="ds-u-padding-left--none"><ds-text weight="bold">Categoria do DRE</ds-text></ds-col>
      <ds-col size="fill" class="ds-u-padding-left--none"
        ><ds-text weight="bold">Vincule às categorias financeiras</ds-text></ds-col
      >
      <ds-col class="ds-tc-sortable-header__col-actions"></ds-col>
    </ds-row>
    <tc-nested-sortable-list ref="nestedSortableList" :items="localItems">
      <template #default="{ item, addItem, firstLevel }">
        <ds-row v-if="item.isTotalizer" class="ds-tc-sortable-item ds-tc-sortable-totalizer">
          <ds-col class="ds-sortable-item-handle" size="auto"><ds-icon icon="grip-dots-vertical" /></ds-col>
          <ds-col size="fill" class="ds-u-min-width--0">
            <slot :item="item" :addItem="addItem" />
          </ds-col>
          <ds-col size="auto" class="ds-u-padding--none">
            <div class="ds-tc-sortable-actions-item">
              <div class="ds-tc-empty-add" />
              <ds-button
                size="md"
                :icon="['regular', 'xmark-large']"
                :tooltip="getTooltipRemove(item)"
                @click="removeItem" />
            </div>
          </ds-col>
        </ds-row>
        <ds-row v-else class="ds-tc-sortable-item">
          <ds-col class="ds-sortable-item-handle" size="auto"><ds-icon icon="grip-dots-vertical" /></ds-col>
          <ds-col size="fill" class="ds-u-min-width--0">
            <slot :item="item" :addItem="addItem" />
          </ds-col>
          <ds-col size="auto" class="ds-u-padding--none">
            <div class="ds-tc-sortable-actions-item">
              <div v-if="firstLevel" class="ds-u-display--flex">
                <ds-button
                  size="md"
                  :icon="['regular', 'plus-circle']"
                  theme="primary"
                  :tooltip="getTooltipAdd()"
                  @click="add(item, addItem, $event)" />
              </div>
              <div v-else class="ds-tc-empty-add" />
              <ds-button
                size="md"
                :icon="['regular', 'xmark-large']"
                :tooltip="getTooltipRemove(item, firstLevel)"
                @click="removeItem" />
            </div>
          </ds-col>
        </ds-row>
      </template>
    </tc-nested-sortable-list>
    <div class="ds-tc-sortable-actions">
      <ds-button size="sm" icon="plus-circle" icon-position="left" @click="newItem"> Adicionar categoria </ds-button>
      <ds-button class="ds-u-margin-left--sm" size="sm" icon="plus-circle" icon-position="left" @click="newTotalizer">
        Adicionar totalizador
      </ds-button>
    </div>
  </div>
</template>

<script>
import DsRow from '@components/row/Row.vue';
import DsCol from '@components/col/Col.vue';
import DsButton from '@components/button';
import DsIcon from '@components/icon';
import DsText from '@components/text';
import { generateId } from '@core/services/id/idService';
import TcNestedSortableList from '../NestedSortableList/TcNestedSortableList.vue';
import { getTooltipRemove, getTooltipAdd } from './tcDreSortableListService';

const LIST_PROP_CLEAR = ['financeCategories'];

export default {
  name: 'TcDreSortableList',
  provide() {
    return {
      dreSortableListVm: {
        emitUpdateItems: this.emitUpdateItems,
      },
    };
  },
  components: {
    DsRow,
    DsCol,
    DsButton,
    DsIcon,
    DsText,
    TcNestedSortableList,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      localItems: this.items,
    };
  },
  watch: {
    localItems: {
      handler() {
        this.emitDataUpdated();
      },
      deep: true,
    },
  },
  methods: {
    removeItem(event) {
      event.target.closest('.ds-sortable-item').remove();
      this.updateLocalItems();
    },
    getSerialize() {
      return this.$refs.nestedSortableList.getSerialize();
    },
    add(item, addItem) {
      addItem(item);
    },
    newItem() {
      this.localItems.push({
        id: generateId(),
        children: [],
      });

      this.emitDataUpdated();
    },
    newTotalizer() {
      this.localItems.push({
        id: generateId(),
        children: [],
        isTotalizer: true,
      });

      this.emitDataUpdated();
    },
    emitUpdateItems() {
      this.emitDataUpdated();
    },
    updateLocalItems() {
      this.localItems = this.getItems();
    },
    emitDataUpdated() {
      this.$emit('data-updated');
    },
    clearPropValue(items) {
      return items.map(item => {
        const newItem = { ...item };

        if (newItem.children && newItem.children.length > 0) {
          LIST_PROP_CLEAR.forEach(prop => {
            delete newItem[prop];
          });
          newItem.children = this.clearPropValue(newItem.children);
        }

        return newItem;
      });
    },
    getTooltipRemove(item, showAdd) {
      return getTooltipRemove(item, showAdd);
    },
    getTooltipAdd() {
      return getTooltipAdd();
    },
    /**
     * Get items serialized
     *
     * @public
     */
    getItems() {
      const serialized = this.getSerialize();

      return this.clearPropValue(serialized);
    },
  },
};
</script>

<style scoped>
@import './TcDreSortableList.css';
</style>
