<template>
  <div class="ds-switch" :class="classes">
    <input
      :id="id"
      type="checkbox"
      :disabled="disabled"
      :checked="value"
      role="switch"
      class="ds-switch__input"
      @change="onChange" />
    <label :for="id" class="ds-switch__label">
      <ds-icon class="ds-switch__icon" :icon="icon" size="md" />
    </label>
  </div>
</template>

<script>
import { generateId } from '@core/services/id/idService';
import DsIcon from '@components/icon';

export default {
  name: 'DsSwitch',
  components: {
    DsIcon,
  },
  props: {
    disabled: Boolean,
    mini: Boolean,
    value: Boolean,
  },
  data() {
    return {
      id: generateId(),
    };
  },
  computed: {
    classes() {
      return {
        'ds-switch--mini': this.mini,
        'ds-switch--disabled': this.disabled,
      };
    },
    icon() {
      return this.value ? 'check' : 'times';
    },
  },
  methods: {
    onChange() {
      this.$emit('input', !this.value);
    },
  },
};
</script>

<style scoped>
@import './Switch.css';
</style>
