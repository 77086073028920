<template>
  <div v-click-outside="close" :class="dropdownClass">
    <div class="ds-dropdown-toggle-container" @click="onClickButton">
      <slot name="trigger">
        <ds-loader-button
          ref="dropdownButton"
          v-ds-tooltip="tooltip"
          :is-loading="isRequestLoading"
          :disabled="disabled"
          :theme="theme"
          :size="size"
          icon="chevron-down"
          :full-width="fullWidth"
          class="ds-dropdown-toggle"
          @blur="$emit('blur')">
          {{ title }}
        </ds-loader-button>
      </slot>
    </div>
    <ds-dropdown-menu
      :el="$el"
      :opened="opened"
      :placement="placement"
      :full-width="fullWidth"
      :strategy="strategy"
      @select="onSelect">
      <slot></slot>
    </ds-dropdown-menu>
  </div>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import ClickOutside from '@directives/click-outside';
import DsTooltip from '@directives/tooltip';
import DsDropdownMenu from './DropdownMenu.vue';
import DsLoaderButton from '../loader-button/LoaderButton.vue';

export default {
  name: 'DsDropdown',
  components: {
    DsLoaderButton,
    DsDropdownMenu,
  },
  directives: {
    ClickOutside,
    DsTooltip,
  },
  provide() {
    return {
      dropdownVm: {
        setIsRequestLoading: this.setIsRequestLoading,
      },
    };
  },
  props: {
    size: DsLoaderButton.props.size,
    disabled: DsLoaderButton.props.disabled,
    value: [Number, String, Object],
    fullWidth: Boolean,
    align: {
      type: String,
      default: 'left',
      validator(align) {
        return ['left', 'right'].includes(align);
      },
    },
    /**
     * DEPRECATED PROP | The prop align is "left" by default
     */
    left: Boolean,
    /**
     * DEPRECATED PROP | Use align="right" instead
     */
    right: Boolean,
    top: Boolean,
    title: String,
    theme: {
      type: String,
      default: 'default',
      validator(theme) {
        return !theme || ['default', 'primary', 'secondary', 'warning', 'danger', 'link'].includes(theme);
      },
    },
    /**
     * Describes the positioning strategy to use
     */
    strategy: {
      type: String,
      default: 'fixed',
      validator(strategy) {
        return ['fixed', 'absolute'].includes(strategy);
      },
    },
    tooltip: {
      type: String,
    },
  },
  data() {
    return {
      opened: false,
      isRequestLoading: false,
    };
  },
  computed: {
    dropdownClass() {
      return ['ds-dropdown', { 'ds-dropdown--full-width': this.fullWidth }];
    },
    placement() {
      if (this.top) {
        return this.right || this.align === 'right' ? 'top-end' : 'top-start';
      }

      return this.right || this.align === 'right' ? 'bottom-end' : 'bottom-start';
    },
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.left) {
      deprecatedDependency.deprecateProperty('left', 'The prop align is "left" by default');
    }

    if (this.right) {
      deprecatedDependency.deprecateProperty('right', 'Use align="right" instead');
    }
  },
  methods: {
    onSelect(option) {
      this.close();
      this.selectValue(option);
    },
    close() {
      this.setOpened(false);
    },
    toggle() {
      this.setOpened(!this.opened);
    },
    setOpened(opened) {
      this.opened = opened;
    },
    selectValue(option) {
      this.$emit('input', option);
    },
    onClickButton(...args) {
      if (!this.disabled && !this.isRequestLoading) {
        this.toggle();
        this.$emit('click', ...args);
      }
    },
    setIsRequestLoading(value) {
      this.isRequestLoading = value;
      this.$emit('request-loading', value);
    },
    focus() {
      this.onClickButton();
      this.$refs.dropdownButton.focus();
    },
  },
};
</script>

<style scoped>
@import './Dropdown.css';
</style>
