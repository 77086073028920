<template>
  <div class="ds-field ds-form-group" :class="classes">
    <ds-label :text="label" :required="isRequired" :icon-tooltip="iconTooltipText">
      <ds-badge v-if="!!itensSelectedCounter" class="ds-field__selected-counter ds-u-margin-left--sm" theme="info">
        {{ itensSelectedBadgeText }}
      </ds-badge>
      <ds-badge v-if="!!itensInvalidCounter" class="ds-field__invalid-counter ds-u-margin-left--sm" theme="danger">
        {{ itensErrorBadgeText }}
      </ds-badge>
    </ds-label>
    <slot></slot>
    <div v-if="shouldShowMessageContainer" class="ds-field__message-container">
      <div v-if="shouldShowErrorMessage" class="ds-field__error-message">
        <span class="ds-field__error-message--text">
          {{ errorMessage }}
        </span>
      </div>
      <div v-else ref="helperText" :style="messageTextStyle" class="ds-field__helper-text">
        {{ bottomHelperText }}
      </div>
      <div v-if="shouldShowCounter" class="ds-field-counter" :class="counterCssClasses">
        <div>{{ counterText }}</div>
        <div v-if="!!maxCounterText" class="ds-u-display--flex">
          <div class="ds-u-margin-x--xs">/</div>
          <div>{{ maxCounterText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debug, capsizeService } from '@core';
import { pluralize } from '@core/services/pluralize/pluralizeService';
import DsLabel from '@components/label';
import DsBadge from '@components/badge';

export default {
  name: 'DsField',
  provide() {
    return {
      fieldVm: this,
    };
  },
  inject: {
    formVm: {
      default: null,
    },
  },
  components: {
    DsLabel,
    DsBadge,
  },
  props: {
    iconTooltip: DsLabel.props.iconTooltip,
    label: DsLabel.props.text,
    required: DsLabel.props.required,
    helperText: String,
    type: {
      type: String,
      validator(value) {
        return ['text'].includes(value);
      },
    },
  },
  data() {
    return {
      modelValidator: null,
      isRequired: this.required,
      shouldShowHelperTextInTooltip: false,
      messageTextStyle: capsizeService.capsizeStyle({ fontSize: 14 }),
      itensSelectedCounter: 0,
      itensInvalidCounter: 0,
      shouldShowCounter: false,
      counterText: 0,
      maxCounterText: 0,
    };
  },
  computed: {
    itensSelectedBadgeText() {
      return getCounterBadgeText(this.itensSelectedCounter, 'Selecionado');
    },
    itensErrorBadgeText() {
      return getCounterBadgeText(this.itensInvalidCounter, 'Erro');
    },
    errorMessage() {
      return this.modelValidator && this.modelValidator.errorMessage;
    },
    hasBeenBlured() {
      return this.modelValidator && this.modelValidator.hasBeenBlured;
    },
    hasBeenFetched() {
      return this.modelValidator && this.modelValidator.hasOnFetchOption;
    },
    hasValue() {
      return this.modelValidator && this.modelValidator.hasValue;
    },
    shouldFormShowErrors() {
      return this.modelValidator && this.modelValidator.shouldShowErrors;
    },
    shouldShowErrorMessage() {
      return (
        this.errorMessage && (this.hasBeenBlured || (this.hasValue && this.hasBeenFetched) || this.shouldFormShowErrors)
      );
    },
    shouldShowMessageContainer() {
      return this.shouldShowErrorMessage || this.bottomHelperText || this.shouldShowCounter;
    },
    iconTooltipText() {
      return this.shouldShowHelperTextInTooltip ? this.helperText : this.iconTooltip;
    },
    bottomHelperText() {
      return !this.shouldShowHelperTextInTooltip && this.helperText;
    },
    classes() {
      return {
        'ds-field--text': this.type === 'text',
      };
    },
    counterCssClasses() {
      return [this.shouldShowErrorMessage ? 'ds-field__error-message' : ''];
    },
  },
  mounted() {
    this.handleHelperTextLocation();
    this.validateInfoPropsCoexistence();
  },
  methods: {
    setIsRequired(isRequired) {
      this.isRequired = this.required || isRequired;
    },
    setModelValidator(modelValidator) {
      this.modelValidator = modelValidator;
    },
    handleHelperTextLocation() {
      if (!this.helperText) {
        return;
      }

      const containerHeight = getOffsetHeight(this.$refs.helperText);
      const ONE_LINE_TEXT_HEIGHT_LIMIT = 12;

      this.shouldShowHelperTextInTooltip = containerHeight > ONE_LINE_TEXT_HEIGHT_LIMIT;
    },
    validateInfoPropsCoexistence() {
      if (this.iconTooltip && this.helperText) {
        debug.error(
          '%cIt\'s not allowed to use "iconTooltip" and "helperText" props at same time in DsField component. Please, choose just one of them to use.%c ',
          'font-size: 22px;',
          'background-image: url("https://user-images.githubusercontent.com/11657454/88837885-1fba3380-d1af-11ea-8a37-f549717a8f2a.gif"); background-size: cover; padding: 20px; margin-top: 20px',
        );
      }
    },
    setItensSelectedCounter(value) {
      this.itensSelectedCounter = value;
    },
    setItensInvalidCounter(value) {
      this.itensInvalidCounter = value;
    },
    handleShouldShowCounter(value) {
      this.shouldShowCounter = value;
    },
    setCounterText(value) {
      this.counterText = value;
    },
    setMaxCounterText(value) {
      this.maxCounterText = value;
    },
  },
};

function getOffsetHeight(el = {}) {
  return el.offsetHeight;
}

function getCounterBadgeText(quantity, text) {
  return `${quantity} ${pluralize(text, quantity)}`;
}
</script>

<style scoped>
@import './Field.css';
</style>
