<template>
  <div
    class="ds-mini-rollover"
    :class="{
      'ds-mini-rollover--has-footer': shouldConsiderFooter,
      'ds-mini-rollover--is-opened': isOpen,
      'ds-mini-rollover--is-minimized': isMinimized,
    }">
    <div class="ds-mini-rollover__header">
      <div class="ds-mini-rollover__header-container">
        <slot name="header" />
      </div>
      <div class="ds-mini-rollover__header-icons">
        <slot name="header-buttons"></slot>
        <ds-button
          theme="link"
          :icon="isMinimized ? ['light', 'chevron-up'] : ['light', 'chevron-down']"
          :tooltip="isMinimized ? 'Maximizar' : 'Minimizar'"
          @click="isMinimized ? maximize() : minimize()">
        </ds-button>
        <ds-close-button color="blue" @click="close" />
      </div>
    </div>
    <div class="ds-mini-rollover__body">
      <slot />
    </div>
  </div>
</template>

<script>
import { addRolloverHook, isSomeRolloverOpened, getRolloverConstants } from '@core/services/rollover/rolloverService';
import { cleanDomAfterAnimationEnds, runCallbacksList } from '@core/services/miniRollover/miniRolloverService';
import DsButton from '@components/button';
import DsCloseButton from '@components/close-button';

const { ON_ROLLOVER_OPENED, ON_ROLLOVER_CLOSED } = getRolloverConstants('ROLLOVER_HOOKS');

export default {
  name: 'DsMiniRollover',
  components: {
    DsButton,
    DsCloseButton,
  },
  props: {
    /**
     * A promise that resolves true (close) or false (not close)
     */
    beforeCloseAction: {
      type: Function,
    },
  },
  data() {
    return {
      isOpen: false,
      isMinimized: false,
      shouldConsiderFooter: false,
      cleanUpCallbacks: [],
    };
  },
  created() {
    this.$on('open', () => this.setOpen(true));
    this.$on('close', () => this.setOpen(false));

    this.setupRolloverHooks();
  },
  beforeDestroy() {
    runCallbacksList(this.cleanUpCallbacks);
    cleanDomAfterAnimationEnds(this.$el);
  },
  methods: {
    open() {
      this.$emit('open');
    },
    async close() {
      let shouldClose = true;

      if (this.beforeCloseAction) {
        shouldClose = await this.beforeCloseAction();
      }

      if (shouldClose === true) {
        this.$emit('close');
      }
    },
    setOpen(isOpen) {
      this.isOpen = isOpen;
    },
    minimize() {
      this.isMinimized = true;
    },
    maximize() {
      this.isMinimized = false;
    },
    setupRolloverHooks() {
      const clearOnRolloverOpened = addRolloverHook(ON_ROLLOVER_OPENED, this.updateShouldConsiderFooter);
      const clearOnRolloverClosed = addRolloverHook(ON_ROLLOVER_CLOSED, this.updateShouldConsiderFooter);
      this.cleanUpCallbacks.push(clearOnRolloverOpened);
      this.cleanUpCallbacks.push(clearOnRolloverClosed);
    },
    updateShouldConsiderFooter() {
      setTimeout(() => {
        this.shouldConsiderFooter = isSomeRolloverOpened();
      });
    },
  },
};
</script>

<style scoped>
@import './MiniRollover.css';
</style>
