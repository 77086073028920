<template>
  <span class="ql-formats">
    <select class="ql-header ql-picker">
      <option value="1">Título 1</option>
      <option value="2">Título 2</option>
      <option value="3">Título 3</option>
      <option selected>Parágrafo</option>
    </select>
  </span>
</template>

<script>
export default {
  name: 'DsRichTextToolbarHeading',
};
</script>
