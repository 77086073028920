<script lang="jsx">
export default {
  name: 'DsTcReportList',
  functional: true,
  render(h, context) {
    return h(
      'ul',
      {
        class: 'ds-tc-report-list',
      },
      context.children,
    );
  },
};
</script>

<style scoped>
@import './TcReportList.css';
</style>
