<template>
  <ds-date-period-popover
    :min-date="minDate"
    :max-date="maxDate"
    :start-date="startDate"
    :end-date="endDate"
    :on-apply="onApply"
    :type="periodType"
    @hook:destroyed="$destroy()" />
</template>

<script>
import { dateParser, dateService } from '@core';
import DsDatePeriodPopover from '@components/date-period-dropdown/DatePeriodPopover.vue';

export default {
  name: 'DsDataGridAdvancedFilterPeriodPopover',
  components: {
    DsDatePeriodPopover,
  },
  props: {
    minDate: DsDatePeriodPopover.props.minDate,
    maxDate: DsDatePeriodPopover.props.maxDate,
    setValue: {
      type: Function,
      required: true,
    },
    value: {
      type: Object,
    },
    periodType: {
      type: String,
      validator(type) {
        return ['day', 'month'].includes(type);
      },
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  computed: {
    startDate() {
      return this.value?.startDate;
    },
    endDate() {
      return this.value?.endDate;
    },
  },
  beforeDestroy() {
    this.setValue(this.localValue);
  },
  methods: {
    onApply(value) {
      if (this.periodType === 'month' && !!value?.endDate) {
        this.localValue = {
          startDate: value?.startDate,
          endDate: dateParser.formatDate(dateService.getLastDayOfTheMonth(value.endDate), undefined, 'YYYY-MM-DD'),
        };
      } else {
        this.localValue = value;
      }
    },
  },
};
</script>
