import PortalVue from 'portal-vue';
import { dialogPlugin } from '../components/dialog';
import { tourPlugin } from '../components/tour';
import { popoverPlugin } from '../components/popover';
import './registerIcons';
import { debug as DebugRef } from '../core/services/debug/debugService';
import { setInitialSetup, handleEnvironmentVariable } from '../core/services/initialSetup/initialSetupService';
import { version } from '../../package.json';
import registerComponents from './registerComponents.mjs';
import registerPlugins from './registerPlugins.mjs';

function registerRolloverHooks(
  Vue,
  { onBeforeOpenRollover, onRolloverOpened, onRolloverClosed, onAllRolloversClosed } = {},
) {
  setInitialSetup({
    rollover: {
      globalHooks: {
        onBeforeOpenRollover: onBeforeOpenRollover ? [onBeforeOpenRollover] : [],
        onRolloverOpened: onRolloverOpened ? [onRolloverOpened] : [],
        onRolloverClosed: onRolloverClosed ? [onRolloverClosed] : [],
        onAllRolloversClosed: onAllRolloversClosed ? [onAllRolloversClosed] : [],
      },
    },
  });
}

function dsInitPlugin(Vue, { store, router, debug, rollover, environment, plugins } = {}) {
  // eslint-disable-next-line no-underscore-dangle
  window._dsVersion = version;
  Vue.prototype.$dsEnvironment = handleEnvironmentVariable(environment);

  if (debug) {
    DebugRef.enabled = Boolean(debug);
  }

  registerComponents(Vue);
  registerPlugins(Vue, plugins);
  registerRolloverHooks(Vue, rollover);

  Vue.use(dialogPlugin, { store, router });
  Vue.use(tourPlugin);
  Vue.use(popoverPlugin);
  Vue.use(PortalVue);
}

export default dsInitPlugin;
