<template>
  <ds-pagination-item :class="classes" v-bind="$attrs" v-on="$listeners">
    <ds-icon v-if="shouldShowChevronIconLeft" size="sm" icon="chevron-left" />
    {{ label }}
    <ds-icon v-if="shouldShowChevronIconRight" size="sm" icon="chevron-right" />
  </ds-pagination-item>
</template>

<script>
import DsIcon from '@components/icon';
import DsPaginationItem from './PaginationItem.vue';

export default {
  name: 'DsPaginationItemNav',
  components: {
    DsPaginationItem,
    DsIcon,
  },
  props: {
    isDropdown: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
      validator(type) {
        return ['next', 'prev'].includes(type);
      },
    },
  },
  computed: {
    classes() {
      return [
        'ds-pagination-item-nav',
        `ds-pagination-item-nav--${this.type}`,
        { 'ds-pagination-item-nav--is-dropdown': this.isDropdown },
      ];
    },
    label() {
      if (this.isDropdown) {
        return '';
      }
      return {
        prev: 'Anterior',
        next: 'Próximo',
      }[this.type];
    },
    shouldShowChevronIconLeft() {
      return this.type === 'prev';
    },
    shouldShowChevronIconRight() {
      return this.type === 'next';
    },
  },
};
</script>

<style scoped>
@import './PaginationItemNav.css';
</style>
