<template>
  <div class="ds-timeline-step" :class="stepClasses">
    <div class="ds-timeline-step-date">
      <ds-text v-if="isDone">
        {{ formattedDate }}
      </ds-text>
    </div>

    <div class="ds-timeline-step-shape">
      <span class="ds-timeline-step-shape-left">
        <span class="ds-timeline-step-shape-left__bar"></span>
      </span>

      <span class="ds-timeline-step-shape-circle">
        <div class="ds-timeline-step-shape-circle__inner">
          <ds-icon size="sm" class="ds-helper-icon" :icon="stepIcon" />
        </div>
      </span>

      <span class="ds-timeline-step-shape-right">
        <span class="ds-timeline-step-shape-right__bar"></span>
      </span>
    </div>

    <div class="ds-timeline-step-title">
      <ds-p>
        <component :is="titleComponent" target="_blank" :href="link">
          <strong>{{ title }}</strong>
        </component>
        <ds-helper-icon :tooltip="tooltip" class="ds-timeline-step-title__helper-icon" />
      </ds-p>
    </div>

    <div v-if="isThereAnySubtitle" class="ds-timeline-step-subtitle">
      {{ subtitle }}
    </div>
  </div>
</template>

<script>
import DsP from '@components/paragraph';
import DsIcon from '@components/icon';
import DsHelperIcon from '@components/helper-icon/HelperIcon.vue';
import DsLink from '@components/link';
import DsText from '@components/text';
import DsTooltip from '@directives/tooltip';
import { debug, dateParser, dateService } from '@core';

const STEP_STATUS = {
  ERROR: 'error',
  SUCCESS: 'success',
};

const ICON_STATUS = {
  error: 'times',
  success: 'check',
};

const DEFAULT_ICON = 'circle';

export default {
  name: 'DsTimelineStep',
  inject: {
    timelineVm: {
      default: null,
    },
  },
  directives: {
    DsTooltip,
  },
  components: {
    DsP,
    DsIcon,
    DsHelperIcon,
    DsLink,
    DsText,
  },
  props: {
    /**
     * It must not exceed three lines, in order to keep the component structure
     */
    subtitle: String,
    link: String,
    tooltip: String,
    date: {
      type: String,
      default: '',
      validator(date) {
        return !date || dateService.isValid(date, 'YYYY-MM-DD');
      },
    },
    icon: {
      type: String,
      required: true,
      default: DEFAULT_ICON,
      validator(icon) {
        return icon && icon.trim !== '';
      },
    },
    title: {
      type: String,
      required: true,
    },
    /**
     * It must be informed in steps with 'success' or 'error' status, in order to keep the component behavior
     */
    status: {
      type: String,
      default: '',
      validator(status) {
        return !status || Object.values(STEP_STATUS).includes(status);
      },
    },
  },
  data() {
    return {
      index: this.timelineVm.createStepIndex(),
    };
  },
  computed: {
    isFirstStep() {
      return this.index === 0;
    },
    isThereAnySubtitle() {
      return this.timelineVm.isThereAnySubtitle;
    },
    stepIcon() {
      if (this.icon !== DEFAULT_ICON) {
        return this.icon;
      }

      return this.status ? ICON_STATUS[this.status] : this.icon;
    },
    isLastStep() {
      return this.index === this.timelineVm.steps.length - 1;
    },
    formattedDate() {
      return dateParser.formatDate(this.date, 'YYYY-MM-DD', 'DD/MM/YY');
    },
    stepClasses() {
      return [
        {
          'ds-timeline-step--first': this.isFirstStep,
          'ds-timeline-step--last': this.isLastStep,
          'ds-timeline-step--centered': !this.isThereAnySubtitle,
          'ds-timeline-step--current': this.timelineVm.currentStep === this.index,
          'ds-timeline-step--progress': !this.isDone && this.timelineVm.currentStep === this.index,
          'ds-timeline-step--uninitialized-default-icon':
            this.timelineVm.currentStep < this.index && this.icon === 'circle',
          [`ds-timeline-step--${this.status}`]: this.status,
        },
      ];
    },
    isDone() {
      return this.status && (this.status === STEP_STATUS.ERROR || this.status === STEP_STATUS.SUCCESS);
    },
    titleComponent() {
      return this.link ? 'DsLink' : 'span';
    },
  },
  created() {
    if (!this.timelineVm) {
      debug.error('ds-timeline-step must be used as ds-timeline slot.');
    }
  },
};
</script>

<style scoped>
@import './TimelineStep.css';
</style>
