import { DriverTour } from '../driverTour/driverTour';

export default {
  install(Vue) {
    Vue.dsTour = ({ onClose, steps }) => {
      const tour = new DriverTour({ onClose, steps });
      return {
        start() {
          tour.startIntroduction();
        },
      };
    };

    Vue.prototype.$dsTour = Vue.dsTour;
  },
};
