<template>
  <div class="ds-total-bar" :class="{ 'ds-total-bar--with-border': withBorder }">
    <div class="ds-total-bar-content" :class="`ds-total-bar-container--${align} ds-total-bar-heading--${titleAlign}`">
      <ds-heading v-if="title" class="ds-total-bar__title" :text="title" size="sm" />
      <span v-if="shouldShowSubtitle" class="ds-total-bar__subtitle">
        {{ subtitle }}
      </span>
    </div>
    <div class="ds-total-bar-items">
      <slot />
    </div>
  </div>
</template>

<script>
import DsHeading from '@components/heading';
import { propValidationService } from '@core';

export default {
  name: 'DsTotalBar',
  components: {
    DsHeading,
  },
  props: {
    title: String,
    subtitle: String,
    withBorder: Boolean,
    align: {
      type: String,
      default: 'right',
      validator: propValidationService.buildValidator({
        validValues: ['left', 'right'],
        errorMessage: 'You should inform one of the valid align values',
      }),
    },
    titleAlign: {
      type: String,
      default: 'left',
      validator: propValidationService.buildValidator({
        validValues: ['left', 'right'],
        errorMessage: 'You should inform one of the valid align values',
      }),
    },
  },
  computed: {
    shouldShowSubtitle() {
      return this.title && this.subtitle;
    },
  },
};
</script>

<style scoped>
@import './TotalBar.css';
</style>
