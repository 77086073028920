<template>
  <ds-split-button-wrapper v-bind="$props" :disabled="handleDisabled" @request-loading="onItemRequestLoading">
    <template #button>
      <component
        :is="mainButtonComponent"
        :is-loading="isItemRequestLoading"
        :theme="theme"
        :disabled="handleDisabled"
        :request-action="requestActionHandler"
        :full-width="fullWidth"
        v-on="$listeners">
        {{ title }}
      </component>
    </template>
    <slot />
  </ds-split-button-wrapper>
</template>

<script>
import DsButton from '@components/button';
import DsLoaderButton from '@components/loader-button/LoaderButton.vue';
import DsRequestButton from '@components/request-button';
import DsSplitButtonWrapper from '@components/split-button-wrapper/SplitButtonWrapper.vue';

export default {
  name: 'DsSplitRequestButton',
  components: {
    DsRequestButton,
    DsLoaderButton,
    DsSplitButtonWrapper,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    theme: DsButton.props.theme,
    disabled: {
      type: Boolean,
      default: false,
    },
    requestAction: {
      type: Function,
    },
    fullWidth: {
      type: Boolean,
    },
  },
  data() {
    return {
      isRequestLoading: false,
      isItemRequestLoading: false,
    };
  },
  computed: {
    mainButtonComponent() {
      return this.isItemRequestLoading ? `ds-loader-button` : 'ds-request-button';
    },
    isLoading() {
      return this.isRequestLoading || this.isItemRequestLoading;
    },
    handleDisabled() {
      return this.disabled || this.isLoading;
    },
  },
  methods: {
    onItemRequestLoading(value) {
      this.isItemRequestLoading = value;
    },
    setIsRequestLoading(value) {
      this.isRequestLoading = value;
    },
    requestActionHandler() {
      this.setIsRequestLoading(true);

      return this.requestAction().finally(() => {
        this.setIsRequestLoading(false);
      });
    },
  },
};
</script>
