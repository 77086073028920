<template>
  <div ds-container-element tabindex="0" class="ds-collapse" :class="classes" :alt="title" @keyup.tab="onTabKeyUp">
    <div class="ds-collapse-head" :class="{ 'ds-collapse-head--opened': localOpened }" @click="toggle">
      <span class="ds-collapse-head__content">
        <slot name="title-icon" />
        <ds-text class="ds-collapse-title" weight="medium">
          <slot name="title">
            {{ title }}
          </slot>
        </ds-text>
        <ds-collapse-description :description="description">
          <template #custom-description>
            <slot name="custom-description" />
          </template>
        </ds-collapse-description>
        <span v-if="badgeText || !fixedCollapse" :class="{ 'ds-collapse--right': badgeAlignRight }">
          <ds-badge v-if="badgeText" class="ds-badge-align" :theme="badgeTheme">
            {{ badgeText }}
          </ds-badge>
          <ds-icon
            v-if="!fixedCollapse"
            :class="{ 'ds-collapse-chevron--right': !badgeAlignRight }"
            size="sm"
            :icon="chevronIcon"
            color="grey-dark" />
        </span>
      </span>
    </div>
    <ds-height-transition class="ds-collapse-body" :is-opened="localOpened">
      <div class="ds-collapse-content">
        <slot />
      </div>
    </ds-height-transition>
  </div>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import DsBadge from '@components/badge';
import DsText from '@components/text';
import DsIcon from '@components/icon';
import DsHeightTransition from '@components/heightTransition';
import DsCollapseDescription from './CollapseDescription.vue';

export default {
  name: 'DsCollapse',
  inject: {
    fixedCollapse: {
      default: false,
    },
  },
  components: {
    DsCollapseDescription,
    DsBadge,
    DsIcon,
    DsText,
    DsHeightTransition,
  },
  props: {
    opened: {
      type: Boolean,
    },
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    badgeText: {
      type: String,
      default: null,
    },
    badgeTheme: {
      type: String,
      default: null,
    },
    badgeAlign: {
      type: String,
      default: 'right',
      validator(value) {
        return ['right', 'left'].includes(value);
      },
    },
    /**
     * [success, info, warning, danger]
     * */
    theme: {
      type: String,
      default: null,
      validator(value) {
        return ['success', 'info', 'warning', 'danger'].includes(value);
      },
    },
  },
  data() {
    return {
      localOpened: this.opened,
      bodyHeight: null,
    };
  },
  computed: {
    badgeAlignRight() {
      return this.badgeAlign === 'right';
    },
    isOpen() {
      return this.localOpened;
    },
    chevronIcon() {
      return this.isOpen ? 'chevron-up' : 'chevron-down';
    },
    classes() {
      return {
        'ds-collapse--fixed': this.fixedCollapse,
        [`ds-collapse--${this.theme}`]: !!this.theme,
      };
    },
  },
  watch: {
    opened(opened) {
      this.localOpened = opened;
    },
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.description) {
      deprecatedDependency.deprecateProperty('description');
    }
    if (this.$slots['custom-description']) {
      deprecatedDependency.deprecateSlot('custom-description');
    }
  },
  methods: {
    toggle() {
      this.setLocalOpened(!this.localOpened);
    },
    setLocalOpened(localOpened) {
      if (this.fixedCollapse || localOpened === this.localOpened) {
        return;
      }

      this.localOpened = localOpened;
      this.$emit('update:opened', this.localOpened);
    },
    onTabKeyUp() {
      this.setLocalOpened(true);
    },
  },
};
</script>

<style scoped>
@import './Collapse.css';
</style>
