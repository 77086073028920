<template>
  <ds-data-grid-advanced-filter-period-popover
    :min-date="minDate"
    :max-date="maxDate"
    :set-value="setValue"
    :value="value"
    period-type="month"
    @hook:destroyed="$destroy()" />
</template>

<script>
import DsDataGridAdvancedFilterPeriodPopover from './DataGridAdvancedFilterPeriodPopover.vue';

export default {
  name: 'DsDataGridAdvancedFilterPeriodMonthPopover',
  components: {
    DsDataGridAdvancedFilterPeriodPopover,
  },
  props: {
    minDate: DsDataGridAdvancedFilterPeriodPopover.props.minDate,
    maxDate: DsDataGridAdvancedFilterPeriodPopover.props.maxDate,
    setValue: {
      type: Function,
      required: true,
    },
    value: {
      type: Object,
    },
  },
};
</script>
