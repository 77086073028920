export default {
  bind(_el, binding, vnode) {
    const el = _el;
    el.focusOutsideHandler = event => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };

    document.body.addEventListener('focus', el.focusOutsideHandler, true);
  },
  unbind(el) {
    document.body.removeEventListener('focus', el.focusOutsideHandler, true);
  },
};
