import getSpeed from '@uppy/utils/lib/getSpeed';
import prettyETA from '@uppy/utils/lib/prettyETA';
import getBytesRemaining from '@uppy/utils/lib/getBytesRemaining';

function getTotalSpeed(files) {
  return files.reduce((total, file) => total + getSpeed(file.progress), 0);
}

function getTotalBytesRemaining(files) {
  return files.reduce((total, file) => total + getBytesRemaining(file.progress), 0);
}

export function getFiles(contexts) {
  return Object.keys(contexts).reduce((acc, context) => {
    const contextFiles = contexts[context].files;

    Object.keys(contextFiles).forEach(file => {
      acc.push(contextFiles[file]);
    });

    return acc;
  }, []);
}

export function getTotalETA(files) {
  const totalSpeed = getTotalSpeed(files);

  if (totalSpeed === 0) {
    return 0;
  }

  const totalBytesRemaining = getTotalBytesRemaining(files);

  return Math.round((totalBytesRemaining / totalSpeed) * 10) / 10;
}

export function getPrettyETA(ETA) {
  return prettyETA(ETA);
}
