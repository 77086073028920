<template functional>
  <span v-if="props.description || $slots['custom-description']" class="ds-collapse-description">
    <ds-text v-if="props.description">
      {{ props.description }}
    </ds-text>
    <span v-if="$slots['custom-description']" class="ds-collapse-custom-description">
      <slot name="custom-description" />
    </span>
  </span>
</template>

<script>
export default {
  name: 'DsCollapseDescription',
  props: {
    description: String,
  },
};
</script>
