import { vnodeService } from '@core';

function getDialogVm(wrapper) {
  return (
    vnodeService.getChildComponentInstance(wrapper.$vnode, 'DsModal') ||
    vnodeService.getChildComponentInstance(wrapper.$vnode, 'DsRollover') ||
    vnodeService.getChildComponentInstance(wrapper.$vnode, 'DsMiniRollover')
  );
}

function shouldHideBodyScroll(vm) {
  return vnodeService.isComponent(vm.$vnode, 'DsModal') || vnodeService.isComponent(vm.$vnode, 'DsRollover');
}

export default {
  install(Vue, options) {
    const componentRenderer = vnodeService.getComponentRenderer(Vue, options);

    const dialog = (ModalComponent, renderOptions, appendTo) => {
      const wrapper = componentRenderer(ModalComponent, renderOptions, appendTo);
      const vm = getDialogVm(wrapper);

      if (shouldHideBodyScroll(vm)) {
        vm.$on('open', () => {
          document.body.style.overflowY = 'hidden';
        });
      }

      vm.$on('close', () => {
        document.body.style.overflowY = 'initial';
        vm.$nextTick(() => wrapper.$destroy());
      });

      return vm;
    };

    Vue.dsDialog = (Component, props, renderOptions) => {
      const modal = dialog(Component, { props, ...renderOptions });
      setTimeout(() => modal.open());
      return modal;
    };

    Vue.prototype.$dsDialog = Vue.dsDialog;
  },
};
