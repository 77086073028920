<template>
  <ds-input
    ref="input"
    v-model="securityCode"
    v-bind="$props"
    inputmode="numeric"
    :custom-validations="securityCodeValidations"
    mask-pattern="9999"
    v-on="$listeners" />
</template>
<script>
import { constants, creditCardService, focusMixin } from '@core';
import DsInput from '@components/input';

export default {
  name: 'DsCreditCardSecurityCodeInput',
  components: {
    DsInput,
  },
  mixins: [focusMixin.focus('input')],
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    cardNumber: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    readonly: {
      type: Boolean,
    },
  },
  data() {
    return {
      securityCodeValidations: [
        {
          message: constants.INPUT_ERRORS.INVALID_SECURITY_CODE.MESSAGE,
          valid: this.isSecurityCodeValid,
        },
      ],
    };
  },
  computed: {
    securityCode: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  methods: {
    isSecurityCodeValid() {
      const { cardNumber, securityCode } = this;
      return creditCardService.isSecurityCodeValid({ cardNumber, securityCode });
    },
  },
};
</script>
