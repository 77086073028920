const SCROLL_DEFAULT_POSITION = 0;

export function isScrollDown(direction) {
  return direction > SCROLL_DEFAULT_POSITION;
}

export function isScrollUp(direction) {
  return direction < SCROLL_DEFAULT_POSITION;
}

export function isScrollDownLimit(direction, listSize, index) {
  return index === listSize && isScrollDown(direction);
}

export function isScrollUpLimit(direction, index) {
  const FIRST_POSITION = 0;
  return index === FIRST_POSITION && isScrollUp(direction);
}

export function shouldScrollDown(list, option) {
  const listProps = getElementScrollProps(list);
  const optionProps = getElementScrollProps(option);

  return (
    listProps.scrollTop + optionProps.height <= listProps.scrollHeight &&
    listProps.yPosition + listProps.height - optionProps.yPosition < optionProps.height
  );
}

export function shouldScrollUp(list, option) {
  const listProps = getElementScrollProps(list);
  const optionProps = getElementScrollProps(option);

  const INITIAL_HEIGHT = 0;
  return listProps.scrollTop > INITIAL_HEIGHT && optionProps.yPosition < listProps.yPosition + optionProps.height;
}

function getElementScrollProps(element) {
  return {
    scrollHeight: element.scrollHeight,
    scrollTop: element.scrollTop,
    yPosition: element.getBoundingClientRect().y,
    height: element.getBoundingClientRect().height,
  };
}
