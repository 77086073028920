<script lang="jsx">
import BatchTableHeaderTr from './BatchTableHeaderTr.vue';
import BatchTableFooterTr from './BatchTableFooterTr.vue';
import BatchTableBodyTr from './BatchTableBodyTr.vue';

const SLOT_COMPONENTS = {
  header: BatchTableHeaderTr,
  footer: BatchTableFooterTr,
};

export default {
  name: 'DsBatchTableTr',
  functional: true,
  props: {
    isCollapse: {
      type: Boolean,
      default: undefined,
    },
    isNested: Boolean,
  },
  render(h, { data, props, slots }) {
    const component = SLOT_COMPONENTS[data.slot] || BatchTableBodyTr;
    return h(component, { ...data, props }, [slots().default, h('template', { slot: 'nested' }, slots().nested)]);
  },
};
</script>
