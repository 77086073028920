<template>
  <div :class="themeCssClass" class="ds-notification">
    <div class="ds-notification-icon-wrapper">
      <ds-icon icon="exclamation-circle" size="xl" />
    </div>
    <div class="ds-notification-content-wrapper">
      <ds-text weight="bold" data-notification-title>
        {{ title }}
      </ds-text>
      <!-- eslint-disable vue/no-v-html -->
      <p class="ds-notification-description" data-notification-description v-html="description"></p>
      <ds-button
        v-if="primaryButton"
        :disabled="primaryButton.disabled"
        class="ds-notification-button"
        theme="primary"
        data-primary-button
        @click="primaryButton.action()">
        {{ primaryButton.label }}
      </ds-button>
      <ds-button
        v-if="button"
        :disabled="button.disabled"
        class="ds-notification-button"
        data-button
        @click="button.action()">
        {{ button.label }}
      </ds-button>
    </div>
  </div>
</template>

<script>
import { debug } from '@core';
import DsButton from '@components/button';
import DsIcon from '@components/icon';
import DsText from '@components/text';

const VALID_THEMES = ['warning', 'danger'];

export default {
  name: 'DsNotification',
  components: {
    DsButton,
    DsText,
    DsIcon,
  },
  props: {
    theme: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    /**
     * {label, action, disabled}
     */
    primaryButton: {
      type: Object,
    },
    /**
     * {label, action, disabled}
     */
    button: {
      type: Object,
    },
  },
  data() {
    return {
      themeCssClass: '',
    };
  },
  created() {
    this.configThemeCssClass(this.theme);
  },
  methods: {
    configThemeCssClass(theme) {
      if (!this.isThemeValid(theme)) {
        debug.error(this.buildInvalidThemeErrorMessage(theme));
        return;
      }

      this.setThemeCssClass(this.buildThemeCssClass(theme));
    },
    isThemeValid(theme) {
      return VALID_THEMES.includes(theme);
    },
    buildInvalidThemeErrorMessage(theme) {
      return (
        `'${theme}' is not a valid notification theme. ` +
        `Please, use one of the following themes: ${VALID_THEMES.join(', ')}.`
      );
    },
    setThemeCssClass(cssClass) {
      this.themeCssClass = cssClass;
    },
    buildThemeCssClass(theme) {
      return `ds-notification--${theme}`;
    },
  },
};
</script>

<style scoped>
@import 'Notification.css';
</style>
