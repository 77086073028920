<template>
  <component :is="componentName" :class="classes" class="ds-batch-table-body-tr" v-bind="$attrs" v-on="listeners">
    <ds-td class="ds-u-print-hidden" @click.native.stop>
      <template v-if="!disableAllActions">
        <ds-checkbox
          v-if="isCheckbox"
          v-ds-tooltip="batchTooltip"
          :checked="isChecked"
          :disabled="disabled"
          @change="setChecked" />
        <ds-radio-group v-else :value="isChecked ? key : null" :disabled="disabled">
          <ds-radio v-ds-tooltip="batchTooltip" :value="key" @click="setChecked" />
        </ds-radio-group>
      </template>
    </ds-td>
    <slot></slot>
    <template #nested>
      <slot name="nested" :disabled="disabled" />
    </template>
  </component>
</template>

<script>
import DsTr from '@components/table-row';
import DsTableRowCollapse from '@components/table-row-collapse';
import DsTrNested from '@components/table-row-nested';
import DsTd from '@components/table-column';
import DsCheckbox from '@components/checkbox';
import DsRadioGroup from '@components/radio-group';
import DsRadio from '@components/radio';
import DsTooltip from '@directives/tooltip';

export default {
  name: 'DsBatchTableBodyTr',
  inject: {
    batchTableVm: {
      default: null,
    },
    batchTableStore: {
      default: null,
    },
    batchType: {
      default: null,
    },
    batchTableBodyTrVm: {
      default: null,
    },
  },
  provide() {
    return {
      batchTableBodyTrVm: {
        key: this.key,
      },
    };
  },
  components: {
    DsTd,
    DsCheckbox,
    DsRadio,
    DsRadioGroup,
  },
  directives: {
    DsTooltip,
  },
  props: {
    checked: {
      type: Boolean,
    },
    row: {
      type: Object,
    },
    disableAllActions: {
      type: Boolean,
      default: false,
    },
    isCollapse: {
      type: Boolean,
      default: undefined,
    },
    isNested: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledBatchTooltip: String,
  },
  computed: {
    key() {
      return this.$vnode.key;
    },
    isChecked() {
      return this.batchTableStore.isRowChecked(this.key);
    },
    isRowCreated() {
      return this.batchTableStore.isRowChecked(this.key) !== undefined;
    },
    componentName() {
      if (this.isNested) {
        return DsTrNested;
      }
      if (this.batchTableVm.isCollapse && this.isCollapse !== false) {
        return DsTableRowCollapse;
      }
      return DsTr;
    },
    isTypeCheckbox() {
      return this.batchTableVm.batchType === 'checkbox';
    },
    isCollapsibleRow() {
      return !!this.batchTableStore?.getRowChildren?.call(this.key).length;
    },
    isCollapseNestedRow() {
      return !!this.parentKey;
    },
    parentKey() {
      return this.batchTableBodyTrVm?.key;
    },
    listeners() {
      return {
        ...this.$listeners,
        collapse: this.onCollapse,
      };
    },
    isCheckbox() {
      return this.batchType === 'checkbox';
    },
    batchTooltip() {
      return this.disabled ? this.disabledBatchTooltip : undefined;
    },
    classes() {
      return {
        'ds-batch-table-body-tr--disabled': this.disabled,
        'ds-batch-table-body-tr--selected': this.isChecked,
      };
    },
  },
  watch: {
    checked(checked) {
      this.setChecked(checked);
    },
    isChecked(checked) {
      this.$emit('update:checked', checked);
    },
    disabled(value) {
      this.setRowDisabled(value);
    },
  },
  created() {
    if (!this.batchTableVm) {
      throw new Error('DsBatchTableTr must be wrapped with DsBatchTable');
    }

    if (this.key == null) {
      throw new Error('You must inform a unique key to ds-batch-table-tr');
    }

    if (!this.disableAllActions) {
      this.batchTableStore.registerRow(this.key, {
        payload: this.row,
        checked: this.checked,
        parent: this.parentKey,
        disabled: this.disabled,
      });

      const customCheckedValue = this.isRowCreated ? this.isChecked : this.checked;

      this.batchTableStore.setRowChecked(this.key, customCheckedValue, true, true);
    }
  },
  beforeDestroy() {
    this.batchTableStore.destroy(this.key);
  },
  methods: {
    setChecked(checked) {
      if (checked === this.isChecked) return;

      this.batchTableStore.setRowChecked(this.key, checked);

      if (this.isCollapsibleRow && this.isTypeCheckbox) {
        this.collapsibleRowHandler();
      }

      if (this.isCollapseNestedRow && this.isTypeCheckbox) {
        this.collapseNestedRowHandler();
      }
    },
    setRowDisabled(disabled) {
      if (disabled) {
        this.setChecked(false);
      }

      this.batchTableStore.setRowDisabled(this.key, disabled);
    },
    collapsibleRowHandler() {
      const children = this.batchTableStore.getRowChildren(this.key);
      const childrenToCheck = children.filter(row => row.checked !== this.isChecked);

      childrenToCheck.forEach(row => {
        this.batchTableStore.setRowChecked(row.key, this.isChecked);
      });
    },
    collapseNestedRowHandler() {
      const isAllSiblingsChecked = this.isAllSiblingsChecked();

      if (isAllSiblingsChecked) {
        this.batchTableStore.setRowChecked(this.parentKey, true);
      }

      if (!this.isChecked && this.batchTableStore.isRowChecked(this.parentKey)) {
        this.batchTableStore.setRowChecked(this.parentKey, false);
      }
    },
    isAllSiblingsChecked() {
      const rowSiblings = this.batchTableStore.getRowSiblings(this.key);

      return this.isChecked && rowSiblings.every(row => row.checked === true);
    },
    onCollapse(value) {
      this.$emit('collapse', value);
    },
  },
};
</script>
