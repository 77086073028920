<template>
  <span class="ds-badge-indicator" :class="classes" />
</template>

<script>
export default {
  name: 'DsBadgeIndicator',
  props: {
    /**
     * [warning]
     * */
    theme: {
      type: String,
      validator(theme) {
        return ['warning'].includes(theme);
      },
    },
  },
  computed: {
    classes() {
      return {
        [`ds-badge-indicator--${this.theme}`]: !!this.theme,
      };
    },
  },
};
</script>

<style scoped>
@import './BadgeIndicator.css';
</style>
