<template>
  <div class="ds-col-divider"></div>
</template>

<script>
export default {
  name: 'DsColDivider',
};
</script>

<style scoped>
@import './ColDivider.css';
</style>
