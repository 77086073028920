<template>
  <div class="ds-loader">
    <component :is="svg.component" :height="svg.height" />
    <ds-p v-if="text" class="ds-u-text-align--center" weight="bold">{{ text }}</ds-p>
  </div>
</template>

<script>
import DsP from '@components/paragraph';
import LoaderInsideSvg from './LoaderInsideSvg.vue';
import LoaderCaProSvg from './LoaderCaProSvg.vue';
import LoaderCaPlusSvg from './LoaderCaPlusSvg.vue';

const THEMES = {
  default: {
    component: LoaderCaProSvg,
    height: '140px',
  },
  'ca-plus': {
    component: LoaderCaPlusSvg,
    height: '140px',
  },
  inside: {
    component: LoaderInsideSvg,
    height: '32px',
  },
};

export default {
  name: 'DsLoader',
  components: {
    DsP,
    LoaderCaProSvg,
    LoaderCaPlusSvg,
  },
  props: {
    theme: {
      type: String,
      validator(theme) {
        return !theme || ['default', 'ca-plus', 'inside'].includes(theme);
      },
    },
    text: {
      type: String,
    },
  },
  computed: {
    localTheme() {
      return this.theme || this.$dsEnvironment || 'default';
    },
    svg() {
      return (
        {
          inside: THEMES.inside,
          'ca-plus': THEMES['ca-plus'],
          'ca-pro': THEMES.default,
        }[this.localTheme] || THEMES.default
      );
    },
  },
};
</script>

<style scoped>
@import './Loader.css';
</style>
