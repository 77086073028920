<template>
  <ds-col
    class="ds-footer-content ds-u-padding-left--md ds-u-padding-right--md"
    v-bind="$attrs"
    :style="style"
    v-on="$listeners">
    <slot />
  </ds-col>
</template>

<script>
import DsCol from '@components/col';

export default {
  name: 'DsFooterContent',
  components: { DsCol },
  props: {
    maxWidth: {
      type: [Number, String],
    },
  },
  computed: {
    style() {
      const { maxWidth } = this;

      return {
        'max-width': maxWidth ? `${maxWidth}px` : '',
      };
    },
  },
};
</script>
