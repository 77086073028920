import { computePosition, flip, shift, offset, hide, arrow, autoUpdate } from '@floating-ui/dom';
import { getCalendars } from '@components/date-input';
import { debug } from '@core';

const POPOVER_OFFSET = 20;
const ARROW_HEIGHT = '10px';
const ARROW_PADDING = 8;
const BUTTON_CLICK_LEFT = 0;

function createDsPopper(referenceElement, popoverElement, options) {
  return autoUpdate(
    referenceElement,
    popoverElement,
    updatePosition.bind(null, referenceElement, popoverElement, options),
  );
}

function updatePosition(referenceElement, popoverElement, options) {
  const arrowElement = popoverElement.querySelector('[data-popover-arrow]');

  computePosition(referenceElement, popoverElement, {
    placement: 'bottom',
    middleware: [
      flip(),
      shift(),
      offset(POPOVER_OFFSET),
      arrow({ element: arrowElement, padding: ARROW_PADDING }),
      hide(),
    ],
    ...options,
  })
    .then(({ x, y, placement, middlewareData } = { placement: 'right' }) => {
      const { referenceHidden } = middlewareData.hide;

      Object.assign(popoverElement.style, {
        left: `${x}px`,
        top: `${y}px`,
        visibility: referenceHidden ? 'hidden' : 'visible',
      });

      const { x: arrowX, y: arrowY } = middlewareData.arrow;
      const staticSide = {
        top: 'bottom',
        right: 'left',
        bottom: 'top',
        left: 'right',
      }[placement.split('-')[0]];

      popoverElement.dataset.popoverPlacement = placement;

      Object.assign(arrowElement.style, {
        left: arrowX != null ? `${arrowX}px` : '',
        top: arrowY != null ? `${arrowY}px` : '',
        right: '',
        bottom: '',
        [staticSide]: `-${ARROW_HEIGHT}`,
      });
    })
    .catch(e => {
      debug.error(e);
    });
}

function isInDatepickerCalendar(element) {
  const calendars = Array.from(getCalendars());
  return calendars.some(calendar => calendar.contains(element));
}

function isClickOutside(referenceElement, popoverElement, targetElement) {
  const contains = (a, b) => a === b || a.contains(b);
  const isInPopover = contains(popoverElement, targetElement);
  return !isInPopover && !isInDatepickerCalendar(targetElement);
}

function isLeftMouseButtonClick(event) {
  return event.button === BUTTON_CLICK_LEFT;
}

function getClickOutsideHandler(referenceElement, popoverElement, popover) {
  return event => {
    if (isLeftMouseButtonClick(event) && isClickOutside(referenceElement, popoverElement, event.target)) {
      popover.close();
    }
  };
}

export class Popover {
  constructor(referenceElement, popoverElement, options = {}) {
    this.popoverElement = popoverElement;
    this.options = options;
    this.cleanup = createDsPopper(referenceElement, popoverElement, options);
    this.onClickOutside = getClickOutsideHandler(referenceElement, popoverElement, this);
    /**
     * This timeout is for adding the click event listener at the end of event loop,
     * preventing some pending click events from triggering this handler.
     */
    setTimeout(() => document.body.addEventListener('mouseup', this.onClickOutside));
  }

  close() {
    if (this.cleanup) {
      this.popoverElement.remove();
      this.cleanup();
      this.cleanup = null;

      if (this.options.onClose) {
        this.options.onClose();
      }
    }

    document.body.removeEventListener('mouseup', this.onClickOutside);
  }
}
