import { isComponent } from '@core/services/vnode/vnodeService';
import { debug } from '../debug/debugService';

function isValidSlot(feedbackSlot) {
  const isValid = feedbackSlot.length === 1 && isComponent(feedbackSlot[0], 'DsFeedbackButton');

  if (!isValid) {
    debug.error('Feedback slot must contain a DsFeedbackButton component');
    return false;
  }

  return isValid;
}

export function shouldRenderSlot(feedbackSlot) {
  return feedbackSlot && isValidSlot(feedbackSlot);
}
