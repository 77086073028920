import { ToasterContainer } from './toasterContainer';

let container;

function getToasterContainer() {
  return container || new ToasterContainer();
}

function pop(toast) {
  container = getToasterContainer();
  container.add(toast);
}

const buildThemeToast = theme => toast => {
  const options = typeof toast === 'string' ? { content: toast } : toast;

  pop({ ...options, theme });
};

export default {
  pop,
  success: buildThemeToast('success'),
  error: buildThemeToast('error'),
  info: buildThemeToast('info'),
  warning: buildThemeToast('warning'),
};
