// https://blog.logrocket.com/debounce-throttle-vue/

import { DEBOUNCE_TIME } from '@core/constants/debounce';

export function debounce(fn, wait = DEBOUNCE_TIME) {
  let timer;
  const debouncedFunction = (...args) => {
    if (timer) {
      clearTimeout(timer);
    }
    const context = this;
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, wait);
  };
  debouncedFunction.clearDebounce = () => {
    if (timer) {
      clearTimeout(timer);
    }
  };

  return debouncedFunction;
}

export function debounceValidateTime(debounceLocal) {
  return debounceLocal >= DEBOUNCE_TIME;
}
