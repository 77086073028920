<template>
  <div :class="classes">
    <!-- @slot Slot for ds-buttons -->
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'DsButtonGroup',
  props: {
    fullWidth: {
      type: Boolean,
    },
  },
  computed: {
    classes() {
      return [
        'ds-button-group',
        {
          'ds-button-group--full-width': this.fullWidth,
        },
      ];
    },
  },
};
</script>

<style scoped>
@import './ButtonGroup.css';
</style>
