<template>
  <ds-tooltipped-overflow>
    <component :is="heading.tagName" v-ds-tooltip="localText" :class="classes" class="ds-heading">
      <template v-if="text">
        {{ text }}
      </template>
      <template v-else>
        <slot></slot>
      </template>
    </component>
  </ds-tooltipped-overflow>
</template>

<script>
import { propValidationService } from '@core';
import DsTooltippedOverflow from '@components/tooltipped-overflow';
import DsTooltip from '@directives/tooltip';

const VALID_HEADINGS = [
  { size: 'lg', tagName: 'h1' },
  { size: 'md', tagName: 'h2' },
  { size: 'sm', tagName: 'h3' },
];

export default {
  name: 'DsHeading',
  components: {
    DsTooltippedOverflow,
  },
  directives: {
    DsTooltip,
  },
  props: {
    size: {
      default: 'lg',
      type: String,
      validator: propValidationService.buildValidator({
        validValues: VALID_HEADINGS.map(heading => heading.size),
        errorMessage: 'You should inform one of valid sizes',
      }),
    },
    text: String,
    truncate: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      firstElement: (this.$slots?.default ?? [])[0],
    };
  },
  computed: {
    heading() {
      return VALID_HEADINGS.find(heading => heading.size === this.size);
    },
    classes() {
      const overflowEllipsis = 'ds-u-text-overflow--ellipsis';
      const headingSize = `ds-heading--${this.heading.size}`;
      return {
        [headingSize]: this.heading.size,
        [overflowEllipsis]: this.truncate,
      };
    },
    localText() {
      if (this.truncate) {
        return this.text || this.firstElement?.text;
      }
      return null;
    },
  },
};
</script>

<style scoped>
@import './Heading.css';
</style>
