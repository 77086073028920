import Vue from 'vue';

function createCheckboxStore({ keepCheck }) {
  let onRowSelectionChangeCallback = () => {};

  const store = Vue.observable({
    rowsSelection: {},
    selectedRows: {},
    isAllSelected: false,
  });

  function shouldCheckAllCheckbox() {
    const values = getVisibleRows();
    return !!values.length && values.every(value => value.checked);
  }

  function getVisibleRows() {
    return Object.values(store.rowsSelection).filter(row => !!row?.visible && !row?.disabled);
  }

  function getRow(key) {
    return store.rowsSelection[key];
  }

  function updateSelectedRowsStore({ key, payload, checked }) {
    if (checked) {
      Vue.set(store.selectedRows, key, payload);
    } else {
      Vue.delete(store.selectedRows, key);
    }
  }

  function onRowSelectionChange(callback) {
    onRowSelectionChangeCallback = (key, payload, checked) => {
      updateSelectedRowsStore({ key, payload, checked });
      callback(payload, checked, Object.values(store.selectedRows));
    };
  }

  function registerRow(key, { checked, payload, parent, disabled }) {
    const row = getRow(key);

    if (row) {
      row.visible = true;
      row.payload = payload;
    } else {
      Vue.set(store.rowsSelection, key, { key, checked, payload, visible: true, parent, disabled });
    }

    setAllSelectedChecked(shouldCheckAllCheckbox(), false);
  }

  function isRowChecked(key) {
    return getRow(key)?.checked;
  }

  function setRowChecked(key, checked, triggerHeaderCheck = true, allChecked = false) {
    const row = getRow(key);

    if (row) {
      row.checked = checked;
      allCheckedAction(key, row.payload, checked, allChecked);
    }

    if (triggerHeaderCheck) {
      setAllSelectedChecked(shouldCheckAllCheckbox(), false);
    }
  }

  function allCheckedAction(key, payload, checked, allChecked) {
    if (!allChecked) {
      onRowSelectionChangeCallback(key, payload, checked);
      return;
    }

    updateSelectedRowsStore({ key, payload, checked });
  }

  function setAllSelectedChecked(checked, triggerChecks = true) {
    store.isAllSelected = checked;

    if (triggerChecks) {
      getVisibleRows().forEach(row => {
        setRowChecked(row.key, checked, false);
      });
    }
  }

  function getRowChildren(key) {
    return Object.values(store.rowsSelection).filter(row => row.parent === key);
  }

  function getRowSiblings(key) {
    const row = getRow(key);

    return Object.values(store.rowsSelection).filter(r => r.parent === row.parent && r.key !== key);
  }

  function destroy(key, { force } = {}) {
    const row = getRow(key);

    if (keepCheck && !force && row) {
      row.visible = false;
    } else {
      Vue.delete(store.rowsSelection, String(key));
      updateSelectedRowsStore({ key, checked: false });

      if (row?.checked) {
        onRowSelectionChangeCallback(key, row.payload, false);
      }
    }

    setAllSelectedChecked(shouldCheckAllCheckbox(), false);
  }

  function destroyAll({ force } = {}) {
    Object.keys(store.rowsSelection).forEach(key => {
      destroy(key, { force });
    });
  }

  function setRowDisabled(key, disabled) {
    const row = getRow(key);

    row.disabled = disabled;
  }

  return {
    get isAllSelected() {
      return store.isAllSelected;
    },

    get selectedRows() {
      return Object.values(store.selectedRows);
    },

    get selectedKeys() {
      return Object.keys(store.selectedRows);
    },

    onRowSelectionChange,
    registerRow,
    isRowChecked,
    setRowChecked,
    setAllSelectedChecked,
    getRowChildren,
    getRowSiblings,
    destroy,
    destroyAll,
    setRowDisabled,
  };
}

function createRadioStore({ keepCheck }) {
  const store = Vue.observable({
    registeredRows: {},
    selectedRadioKey: null,
  });

  let onRowSelectionChangeCallback = () => {};

  function getRow(key) {
    return store.registeredRows[key];
  }

  function getSelectedRow() {
    return getRow(store.selectedRadioKey);
  }

  function selectRow(key) {
    store.selectedRadioKey = key;
  }

  function onRowSelectionChange(callback) {
    onRowSelectionChangeCallback = callback;
  }

  function registerRow(key, { checked, payload }) {
    Vue.set(store.registeredRows, key, { key, checked, payload });
  }

  function isRowChecked(key) {
    return store.selectedRadioKey === key;
  }

  function setRowChecked(key, checked) {
    if (checked) {
      selectRow(key);
      onRowSelectionChangeCallback(key, getSelectedRow().payload, checked);
    }
  }

  function destroy(key, { force } = {}) {
    if (!keepCheck || force) {
      Vue.delete(store.registeredRows, String(key));

      if (store.selectedRadioKey === key) {
        store.selectedRadioKey = null;
      }
    }
  }

  function destroyAll({ force } = {}) {
    Object.keys(store.registeredRows).forEach(key => {
      destroy(key, { force });
    });
  }

  return {
    get isAllSelected() {
      return false;
    },

    get selectedRows() {
      const selectedRow = getSelectedRow();
      return selectedRow ? [selectedRow.payload] : [];
    },

    get selectedKeys() {
      return store.selectedRadioKey ? [store.selectedRadioKey] : [];
    },

    onRowSelectionChange,
    registerRow,
    isRowChecked,
    setRowChecked,
    destroy,
    destroyAll,
  };
}

export function createStore({ keepCheck = false, type = 'checkbox' } = {}) {
  return type === 'checkbox' ? createCheckboxStore({ keepCheck }) : createRadioStore({ keepCheck });
}
