<script lang="jsx">
export default {
  name: 'DsTab',
  inject: ['tabsVm'],
  props: {
    label: String,
    badgeText: String,
    badgeTheme: String,
    badgeTooltip: String,
    destroyOnChange: Boolean,
  },
  provide() {
    return {
      tabVm: {
        isVisible: () => this.isVisible,
      },
    };
  },
  data() {
    return {
      content: null,
      indexTab: null,
    };
  },
  computed: {
    isVisible() {
      return this.tabsVm.isTabVisible(this.indexTab);
    },
  },
  watch: {
    badgeText() {
      this.updateTab();
    },
    badgeTheme() {
      this.updateTab();
    },
  },
  mounted() {
    this.$on(
      'hook:beforeDestroy',
      this.tabsVm.addTab({
        ...this.$props,
        ...(this.$vnode.key ? { key: this.$vnode.key } : {}),
      }),
    );

    this.indexTab = this.tabsVm.tabs.find(({ label }) => label === this.label).index;
  },
  methods: {
    buildElement(display) {
      return this.$createElement(
        'div',
        {
          class: 'ds-content',
          style: { display },
        },
        this.$slots.default,
      );
    },
    updateTab() {
      this.tabsVm.updateTab({
        ...this.$props,
        index: this.indexTab,
      });
    },
  },
  render() {
    if (!this.destroyOnChange && !this.isVisible) {
      return this.buildElement('none');
    }

    if (this.isVisible) {
      return this.buildElement();
    }

    return null;
  },
};
</script>
