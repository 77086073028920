<template>
  <div class="ds-input--with-addon">
    <ds-input-addon v-if="addOns.left.shouldShow" v-bind="addOns.left" />
    <slot></slot>
    <ds-input-addon v-if="$slots.button" class="ds-u-print-hidden" alignment="right">
      <slot slot="button" name="button"></slot>
    </ds-input-addon>
    <ds-input-addon v-if="addOns.right.shouldShow" v-bind="addOns.right" />
  </div>
</template>

<script>
import DsInputAddon from './InputAddon.vue';

export default {
  name: 'DsInputGroup',
  components: {
    DsInputAddon,
  },
  props: {
    addOnIconLeft: {
      type: DsInputAddon.props.icon.type,
    },
    addOnTextLeft: {
      type: String,
    },
    addOnIconRight: {
      type: DsInputAddon.props.icon.type,
    },
    addOnTextRight: {
      type: String,
    },
  },
  data() {
    return {
      addOns: {
        left: {},
        right: {},
      },
    };
  },
  created() {
    this.setAddOn('left', this.addOnIconLeft, this.addOnTextLeft);
    this.setAddOn('right', this.addOnIconRight, this.addOnTextRight);
  },
  methods: {
    setAddOn(position, icon, text = '') {
      this.addOns[position].icon = icon;
      this.addOns[position].text = text;
      this.addOns[position].alignment = position;
      this.addOns[position].shouldShow = icon || text;
    },
  },
};
</script>
