<template>
  <ds-td :align="align">
    <ds-field>
      <component
        :is="componentName || componentIs"
        ref="component"
        v-model="localValue"
        v-bind="localComponentOptions"
        v-on="localComponentListeners">
        <slot />
      </component>
    </ds-field>
  </ds-td>
</template>

<script>
import { focusMixin } from '@core';
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import DsField from '@components/field';
import DsTd from '@components/table-column';

export default {
  name: 'DsInlineTableTd',
  inject: ['inlineTableVm', 'inlineTableRowVm'],
  components: {
    DsField,
    DsTd,
  },
  mixins: [focusMixin.focus('component')],
  inheritAttrs: false,
  props: {
    componentIs: {
      type: [String, Object],
    },
    /**
     * DEPRECATED PROP | Use component-is instead
     */
    componentName: {
      type: [String, Object],
    },
    componentOptions: {
      type: Object,
    },
    componentListeners: {
      type: Object,
    },
    id: {
      type: String,
      required: true,
    },
    align: DsTd.props.align,
  },
  data() {
    return {
      localValue: this.inlineTableRowVm.getInitialValue()[this.id] || undefined,
      required: false,
    };
  },
  computed: {
    localComponentOptions() {
      return {
        required: this.required,
        ...this.componentOptions,
        disabled: this.componentOptions?.disabled || this.inlineTableRowVm.isDisabled(),
      };
    },
    localComponentListeners() {
      return {
        ...this.$listeners,
        ...this.componentListeners,
      };
    },
  },
  watch: {
    localValue() {
      this.onInput(this.localValue);
    },
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.componentName) {
      deprecatedDependency.deprecateProperty('component-name', 'Use component-is instead');
    }
  },
  mounted() {
    this.inlineTableRowVm.registerColumn({
      key: this.id,
      inlineTableColumnVm: {
        setLocalValue: this.setLocalValue,
        setFieldRequiredStatus: this.setFieldRequiredStatus,
      },
    });
    this.$nextTick(this.setFieldRequiredStatus);
  },
  methods: {
    onInput(value) {
      this.inlineTableRowVm.updateProperty({
        key: this.id,
        value,
      });
    },
    setLocalValue(value) {
      this.localValue = value;
    },
    setFieldRequiredStatus() {
      this.setRequired(this.inlineTableVm.isFieldRequired(this.id));
    },
    setRequired(required) {
      this.required = required;
    },
  },
};
</script>
