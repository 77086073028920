<template>
  <div class="ds-file-preview" :class="classes">
    <ds-row v-if="isUploading">
      <ds-col>
        <ds-loader class="ds-file-preview__loader" theme="inside" />
      </ds-col>
    </ds-row>
    <ds-row v-else no-wrap class="ds-file-preview__content">
      <ds-col size="auto">
        <ds-icon icon="paperclip" />
      </ds-col>
      <ds-col size="fill" class="ds-file-preview__file-info ds-u-padding-left--none">
        <ds-tooltipped-overflow>
          <div v-ds-tooltip="fileInfo" class="ds-u-text-overflow--ellipsis">
            {{ fileInfo }}
          </div>
        </ds-tooltipped-overflow>
      </ds-col>
      <ds-col size="auto">
        <ds-button v-if="isInvalidStatus" size="sm" class="ds-u-margin-right--sm" @click="replaceFile()">
          Substituir
        </ds-button>
        <ds-button
          v-else-if="isErrorStatus"
          v-ds-tooltip.top="'Tentar novamente'"
          icon="undo"
          size="sm"
          class="ds-u-margin-right--sm"
          @click="retryUpload()" />
        <ds-button
          v-ds-tooltip.top="'Excluir'"
          size="sm"
          :icon="['regular', 'trash']"
          :disabled="disabled"
          @click="deleteFile()" />
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import DsTooltip from '@directives/tooltip';
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsIcon from '@components/icon';
import DsButton from '@components/button';
import DsLoader from '@components/loader';
import DsTooltippedOverflow from '@components/tooltipped-overflow';

export default {
  name: 'DsFilePreview',
  directives: {
    DsTooltip,
  },
  components: {
    DsButton,
    DsIcon,
    DsRow,
    DsCol,
    DsLoader,
    DsTooltippedOverflow,
  },
  props: {
    file: {
      type: [File, Object],
      required: true,
      validator(value) {
        return value instanceof File ? true : !!(value.name && value.size);
      },
    },
    status: {
      type: String,
      required: true,
      validator(value) {
        return ['uploading', 'valid', 'invalid', 'error'].includes(value);
      },
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    fileName() {
      return this.file.name;
    },
    fileSize() {
      const K_BYTES_CONSTRAINT = 1024;
      return parseInt(this.file.size / K_BYTES_CONSTRAINT);
    },
    fileInfo() {
      return `${this.fileName} (${this.fileSize}Kb)`;
    },
    isUploading() {
      return this.status === 'uploading';
    },
    isInvalidStatus() {
      return this.status === 'invalid';
    },
    isErrorStatus() {
      return this.status === 'error';
    },
    classes() {
      return {
        'ds-file-preview--invalid': this.isInvalidStatus || this.isErrorStatus,
      };
    },
  },
  methods: {
    deleteFile() {
      this.$emit('delete-file');
    },
    replaceFile() {
      this.$emit('replace-file');
    },
    retryUpload() {
      this.$emit('retry-upload');
    },
  },
};
</script>

<style>
@import './FilePreview.css';
</style>
