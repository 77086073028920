// Grandeza utilizada por quantidade de digitos de um número

const DECIMAL_GREATNESS = {
  4: 'K',
  5: 'K',
  6: 'K',
  7: 'M',
  8: 'M',
  9: 'M',
  10: 'B',
  11: 'B',
  12: 'B',
  13: 'T',
  14: 'T',
  15: 'T',
};

export { DECIMAL_GREATNESS };
