<template>
  <component
    :is="tableComponent"
    v-bind="$attrs"
    class="ds-request-table"
    :loading="loading"
    :hide-actions="true"
    :fix-first-columns="fixFirstColumns"
    :fix-last-columns="fixLastColumns">
    <slot slot="header" name="header"></slot>
    <slot></slot>
    <slot slot="footer" name="footer"></slot>
  </component>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import { debug } from '@core';
import DsTable from '@components/table';
import DsCollapseTable from '@components/collapse-table';

export default {
  name: 'DsRequestTable',
  props: {
    fetchAction: Function,
    /**
     * DEPRECATED PROP | Use fetch-action props instead
     */
    onFetch: Function,
    /**
     * DEPRECATED PROP | Use @fetch-success event instead
     */
    onFetchSuccess: Function,
    /**
     * DEPRECATED PROP | Use @fetch-error event instead
     */
    onFetchError: Function,
    isCollapse: Boolean,
    fixFirstColumns: DsTable.props.fixFirstColumns,
    fixLastColumns: DsTable.props.fixLastColumns,
  },
  data() {
    return {
      loading: false,
      localFetchId: null,
    };
  },
  computed: {
    fetch() {
      return this.fetchAction || this.onFetch;
    },
    tableComponent() {
      return this.isCollapse ? DsCollapseTable : DsTable;
    },
  },
  created() {
    this.handleFetch();

    const deprecatedDependency = createDeprecation(this);

    if (this.onFetch) {
      deprecatedDependency.deprecateProperty('onFetch', 'Use fetch-action instead');
    }
    if (this.onFetchSuccess) {
      deprecatedDependency.deprecateProperty('onFetchSuccess', 'Use @fetch-success event instead');
    }

    if (this.onFetchError) {
      deprecatedDependency.deprecateProperty('onFetchError', 'Use @fetch-error event instead');
    }
  },
  methods: {
    refresh() {
      this.handleFetch();
    },
    handleFetchSuccess(response, fetchId) {
      if (this.localFetchId !== fetchId) {
        return;
      }
      if (this.onFetchSuccess) {
        this.onFetchSuccess(response);
      }

      this.$emit('fetch-success', response);

      this.loading = false;
    },
    handleFetchError(error) {
      if (this.onFetchError) {
        this.onFetchError(error);
      }
      this.$emit('fetch-error', error);
    },
    async handleFetch() {
      try {
        this.loading = true;
        this.localFetchId = Symbol('fetchId');
        const momentFetchId = this.localFetchId;
        const response = await this.fetch();
        this.handleFetchSuccess(response, momentFetchId);
      } catch (error) {
        debug.error(error);
        this.handleFetchError(error);
        this.loading = false;
      }
    },
  },
};
</script>
