<template>
  <ds-input
    ref="input"
    v-model="localValue"
    v-bind="$attrs"
    class="ds-cnpj-input"
    :custom-validations="localCustomValidations"
    :required="required"
    :disabled="disabled"
    type="text"
    mask-pattern="99.999.999/9999-99"
    @change="onInputChange"
    @blur="onInputBlur" />
</template>

<script>
import DsInput from '@components/input';
import { constants, focusMixin } from '@core';
import * as cnpjService from '@ca-design-system-libs/cnpj';

export default {
  name: 'DsCnpjInput',
  components: {
    DsInput,
  },
  mixins: [focusMixin.focus('input')],
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    onChange: {
      type: Function,
    },
    onBlur: {
      type: Function,
    },
    customValidations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localValue: this.value,
      cnpjValidations: {
        message: constants.INPUT_ERRORS.INVALID_CNPJ.MESSAGE,
        valid: cnpjService.isValid,
      },
    };
  },
  computed: {
    localCustomValidations() {
      return [this.cnpjValidations, ...this.customValidations];
    },
  },
  watch: {
    value(value) {
      if (String(value) !== cnpjService.removePunctuation(this.localValue)) {
        this.localValue = String(value);
      }
    },
    localValue(localValue) {
      const unmasked = cnpjService.removePunctuation(localValue);
      this.$emit('input', unmasked);
    },
  },
  methods: {
    onInputChange() {
      if (this.onChange) {
        this.onChange();
      }
    },
    onInputBlur() {
      if (this.onBlur) {
        this.onBlur();
      }
    },
  },
};
</script>
