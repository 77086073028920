<template>
  <div class="ql-toolbar ql-snow">
    <ds-rich-text-toolbar-heading v-if="heading" />
    <ds-rich-text-toolbar-format v-if="format" />
    <ds-rich-text-toolbar-align v-if="align" />
    <ds-rich-text-toolbar-list v-if="list" />
    <ds-rich-text-toolbar-external v-if="external" />
  </div>
</template>

<script>
import DsRichTextToolbarHeading from './RichTextToolbarHeading.vue';
import DsRichTextToolbarFormat from './RichTextToolbarFormat.vue';
import DsRichTextToolbarAlign from './RichTextToolbarAlign.vue';
import DsRichTextToolbarList from './RichTextToolbarList.vue';
import DsRichTextToolbarExternal from './RichTextToolbarExternal.vue';

export default {
  name: 'DsRichTextToolbar',
  components: {
    DsRichTextToolbarHeading,
    DsRichTextToolbarFormat,
    DsRichTextToolbarAlign,
    DsRichTextToolbarList,
    DsRichTextToolbarExternal,
  },
  props: {
    heading: Boolean,
    format: Boolean,
    align: Boolean,
    list: Boolean,
    external: Boolean,
  },
};
</script>
