<template>
  <section class="ds-wizard">
    <slot></slot>
  </section>
</template>

<script>
import { getChildComponentInstance, getChildrenFromSlot } from '@core/services/vnode/vnodeService';
import { createStore } from './store';

export default {
  name: 'DsWizard',
  provide() {
    return {
      wizardStore: {
        ...this.wizardStore,
        addStep: this.addStep,
      },
    };
  },
  props: {
    completed: {
      type: Boolean,
    },
    currentStep: {
      type: String,
    },
  },
  data() {
    return {
      wizardStore: createStore(),
    };
  },
  computed: {
    isCompleted() {
      return this.wizardStore.isCompleted;
    },
  },
  watch: {
    isCompleted: {
      immediate: true,
      handler(isCompleted) {
        this.$emit('update:completed', isCompleted);
      },
    },
    currentStep: {
      immediate: true,
      handler(currentStep) {
        this.wizardStore.setCurrentStep(currentStep);
      },
    },
  },
  beforeDestroy() {
    this.wizardStore = null;
  },
  methods: {
    addStep(key) {
      getChildrenFromSlot(this.$slots.default).forEach((slot, index) => {
        if (getChildComponentInstance(slot, 'DsWizardStep', vnode => vnode.key === key)) {
          this.wizardStore.addStep(key, index);
        }
      });
    },
  },
};
</script>

<style scoped>
@import './Wizard.css';
</style>
