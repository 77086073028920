<template>
  <div class="ds-container-navigation-item" :class="{ 'ds-container-navigation-item--active': isCollapseOpen }">
    <component
      :is="wrapperComponent"
      :opened="isCollapseOpen"
      :title="title"
      :class="collapsable && 'ds-collapse--container-navigation'"
      v-bind="$attrs"
      @update:opened="updateOpened">
      <slot></slot>
    </component>
  </div>
</template>

<script>
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';
import DsPage from '@components/page';
import DsCollapse from '@components/collapse';

export default {
  name: 'DsContainerNavigationItem',
  components: {
    DsPage,
    DsCollapse,
  },
  inject: ['dsContainerNavigation'],
  props: {
    title: {
      type: String,
      required: true,
    },
    collapsable: {
      type: Boolean,
      default: false,
    },
    collapseOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCollapseOpen: this.collapseOpen,
      itemId: this.dsContainerNavigation.createItemId(),
    };
  },
  computed: {
    wrapperComponent() {
      return this.collapsable ? DsCollapse : DsPage;
    },
  },
  beforeCreate() {
    this.$options.key = Symbol('ds-container-navigation-item');
  },
  mounted() {
    this.dsContainerNavigation.addStep(this.$options.key, {
      title: this.title,
      scrollTo: this.scrollTo,
      setCollapsed: this.setCollapsed,
      itemId: this.itemId,
    });
  },
  methods: {
    scrollTo() {
      return smoothScrollIntoView(this.$el);
    },
    setCollapsed(value) {
      this.isCollapseOpen = value;
    },
    updateOpened(value) {
      this.dsContainerNavigation.clickItemId(this.itemId, value);
    },
  },
};
</script>

<style scoped>
@import './ContainerNavigationItem.css';
</style>
