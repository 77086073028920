<template>
  <span class="ds-phone-format">
    {{ shownValue }}
  </span>
</template>

<script>
import { addPunctuation, isValid } from '@ca-design-system-libs/phone';

function isExtraDigitRule(value) {
  return !!value && value.length >= 11;
}

export default {
  name: 'DsPhoneFormat',
  props: {
    value: {
      type: String,
      default: '',
      validator(value) {
        return !value || isValid(value);
      },
    },
  },
  computed: {
    shownValue() {
      return this.value ? this.formattedPhone : '';
    },
    formattedPhone() {
      return isExtraDigitRule(this.value)
        ? addPunctuation(this.value, { withExtraDigit: true })
        : addPunctuation(this.value);
    },
  },
};
</script>
