import { isComponent } from '@core/services/vnode/vnodeService';
import { debug } from '../debug/debugService';

function isValidSlot(helpButtonSlot) {
  const isValid = helpButtonSlot.length === 1 && isComponent(helpButtonSlot[0], 'DsHelpButton');

  if (!isValid) {
    debug.error('help slot must contain a DsHelpButton component');
    return false;
  }

  return isValid;
}

export function shouldRenderSlot(helpButton) {
  return helpButton && isValidSlot(helpButton);
}
