<template>
  <div :class="classes" class="ds-checkbox">
    <input
      :id="id"
      :disabled="disabled"
      :checked="localChecked"
      :tabindex="tabindex"
      v-bind="$attrs"
      type="checkbox"
      class="ds-checkbox__input"
      @change="onChange" />
    <ds-checkbox-icon class="ds-checkbox__icon" :checked="localChecked" />
    <label :for="id" class="ds-checkbox__label">
      <slot>
        {{ label }}
      </slot>
    </label>
  </div>
</template>

<script>
import { generateId } from '@core/services/id/idService';
import DsCheckboxIcon from './CheckboxIcon.vue';

export default {
  name: 'DsCheckbox',
  components: {
    DsCheckboxIcon,
  },
  inject: {
    checkboxGroupVm: {
      default: null,
    },
  },
  model: {
    prop: 'checked',
    event: 'change',
  },
  props: {
    label: String,
    value: {
      type: [Array, Boolean, Date, Function, Number, Object, String, Symbol],
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    tabindex: [String, Number],
  },
  data() {
    return {
      id: generateId(),
      checkboxGroupOptionId: null,
      localChecked: this.checked || this.isInitialCheckedOnGroup(),
    };
  },
  computed: {
    hasLabel() {
      return this.label || this.$slots?.default;
    },
    classes() {
      return {
        'ds-checkbox--no-label': !this.hasLabel,
        'ds-checkbox--disabled': this.disabled,
      };
    },
  },
  watch: {
    checked() {
      this.localChecked = this.checked;
    },
    localChecked() {
      this.$emit('change', this.localChecked);
      if (this.checkboxGroupVm) {
        this.updateOptionOnCheckboxGroup();
      }
    },
  },
  beforeDestroy() {
    if (this.checkboxGroupVm) {
      this.checkboxGroupVm.unregisterOption(this.checkboxGroupOptionId);
    }
  },
  created() {
    if (this.checkboxGroupVm) {
      this.checkboxGroupOptionId = this.checkboxGroupVm.registerOption(this.getOption());
    }
  },
  methods: {
    getOption() {
      return {
        value: this.value,
        checked: this.localChecked,
        setChecked: this.setChecked,
      };
    },
    isInitialCheckedOnGroup() {
      return this.checkboxGroupVm?.isOptionInitialChecked(this.value) || false;
    },
    onChange(event) {
      this.setChecked(event.target.checked);
    },
    setChecked(checked) {
      this.localChecked = checked;
    },
    updateOptionOnCheckboxGroup() {
      this.checkboxGroupVm.updateOption({
        id: this.checkboxGroupOptionId,
        option: this.getOption(),
      });
    },
  },
};
</script>

<style scoped>
@import './Checkbox.css';
</style>
