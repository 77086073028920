<template>
  <div
    v-if="createActions || createAction"
    v-ds-tooltip="createActionTooltip"
    class="ds-data-grid-header-create-actions">
    <ds-dropdown
      v-if="createActions"
      :title="createActionLabel"
      :disabled="createActionDisabled"
      theme="primary"
      size="sm">
      <ds-dropdown-item
        v-for="action in createActions"
        :key="action.name"
        :disabled="action.disabled"
        @click="action.action">
        {{ action.name }}
        <ds-badge v-if="action.badge" slot="badge" :theme="action.badge.theme">{{ action.badge.text }}</ds-badge>
      </ds-dropdown-item>
    </ds-dropdown>
    <ds-button
      v-else-if="createAction"
      theme="primary"
      size="sm"
      data-data-grid-create-button
      :disabled="createActionDisabled"
      @click="createAction">
      {{ createActionLabel }}
    </ds-button>
  </div>
</template>

<script>
import { debug } from '@core';
import DsButton from '@components/button';
import DsTooltip from '@directives/tooltip';
import DsDropdown from '@components/dropdown';
import DsBadge from '@components/badge';
import DsDropdownItem from '@components/dropdown-item';

export default {
  name: 'DsDataGridHeaderCreateActions',
  components: {
    DsButton,
    DsDropdown,
    DsDropdownItem,
    DsBadge,
  },
  directives: {
    DsTooltip,
  },
  props: {
    createAction: {
      type: Function,
    },
    createActions: {
      type: Array,
    },
    createActionDisabled: {
      type: Boolean,
      default: false,
    },
    createActionLabel: {
      type: String,
      default: 'Adicionar',
    },
    createActionTooltip: {
      type: String,
    },
  },
  created() {
    if (this.createAction && this.createActions) {
      debug.error('Only one of the properties "createAction" or "createActions" should be informed');
    }
  },
};
</script>

<style scoped>
@import './DataGridHeaderCreateActions.css';
</style>
