<template>
  <span class="ql-formats">
    <button v-for="format in formats" :key="format.name" v-ds-tooltip="format.name" :class="format.className"></button>
    <div v-ds-tooltip="'Cor do texto'" class="ds-u-display--flex">
      <select class="ql-color">
        <option v-for="color in colors" :key="color" :value="color"></option>
      </select>
    </div>
  </span>
</template>

<script>
import DsTooltip from '@directives/tooltip';
import { RICH_TEXT_FORMATS, RICH_TEXT_COLORS } from './richTextConstants';

export default {
  name: 'DsRichTextToolbarFormat',
  directives: {
    DsTooltip,
  },
  data() {
    return {
      formats: RICH_TEXT_FORMATS,
      colors: RICH_TEXT_COLORS,
    };
  },
};
</script>
