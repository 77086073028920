<template>
  <fieldset class="ds-fieldset">
    <legend v-if="title" class="ds-fieldset-header">
      <span class="ds-fieldset-title">
        <ds-heading :text="title" size="sm" />
        <ds-icon
          v-if="iconTooltip"
          v-ds-tooltip:250="iconTooltip"
          class="ds-fieldset-title__icon"
          :icon="['regular', 'circle-question']"
          size="md"
          color="grey-dark" />
      </span>
      <div v-if="title" class="ds-fieldset-description">
        <!-- @slot DEPRECATED -->
        <slot name="description">
          {{ description }}
        </slot>
      </div>
      <ds-row-divider margin-bottom="medium" />
    </legend>
    <slot></slot>
  </fieldset>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import DsTooltip from '@directives/tooltip';
import DsIcon from '@components/icon';
import DsHeading from '@components/heading';
import DsRowDivider from '@components/row-divider';

export default {
  name: 'DsFieldset',
  directives: {
    DsTooltip,
  },
  components: {
    DsIcon,
    DsHeading,
    DsRowDivider,
  },
  props: {
    title: String,
    /**
     * DEPRECATED
     */
    description: String,
    iconTooltip: String,
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.description) {
      deprecatedDependency.deprecateProperty('description', 'Use slot default');
    }
    if (this.$slots.description) {
      deprecatedDependency.deprecateProperty('description slot', 'Use slot default');
    }
  },
};
</script>

<style scoped>
@import './Fieldset.css';
</style>
