<template>
  <ul :class="['ds-list-filter', classes]">
    <slot></slot>
  </ul>
</template>

<script>
import { vnodeService } from '@core';

export default {
  name: 'DsListFilter',
  provide() {
    return {
      listFilterVm: this,
    };
  },
  props: {
    withTable: {
      type: Boolean,
    },
    onFilterSelect: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    classes() {
      return {
        'ds-list-filter--with-table': this.withTable,
      };
    },
  },
  created() {
    this.$on('item-selected', itemVm => {
      if (!itemVm.isSelected) {
        this.selectItem(itemVm);
      }
    });
  },
  methods: {
    hasSomeItemPreviouslySelected() {
      return this.$slots.default
        .filter(vnode => vnodeService.isComponent(vnode, 'DsListFilterItem'))
        .some(hasSelectedProp);
    },
    deselectAllItems() {
      this.items.forEach(item => item.setSelected(false));
    },
    selectItem(itemVm) {
      this.onFilterSelect(getItemVmProps(itemVm));
      this.deselectAllItems();
      itemVm.setSelected(true);
    },
    shouldSelectFirstItem() {
      return !this.hasSomeItemPreviouslySelected();
    },
    addItem(itemVm) {
      const isFirstItem = !this.items.length;

      if ((isFirstItem && this.shouldSelectFirstItem()) || itemVm.selected) {
        this.selectItem(itemVm);
      }

      this.items.push(itemVm);
    },
  },
};

function hasSelectedProp(vnode) {
  const { selected } = vnode.componentOptions.propsData;
  return selected !== undefined && selected !== false;
}

function getItemVmProps(itemVm) {
  return {
    ...itemVm.$props,
    key: itemVm.$vnode.key,
  };
}
</script>

<style scoped>
@import './ListFilter.css';
</style>
