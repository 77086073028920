<template>
  <div class="ds-rollover-header">
    <ds-container class="ds-rollover-header__container ds-u-display--flex ds-u-align-items--center">
      <ds-button
        v-if="hasSize"
        aria-keyshortcuts="Esc"
        aria-label="Fechar página"
        class="ds-rollover-close ds-u-margin-right--sm"
        theme="link"
        tooltip="Fechar (Esc)"
        size="md"
        icon="arrow-left"
        @click="onClose" />

      <div
        v-if="!hasSize"
        class="ds-rollover-close ds-u-padding-left--sm ds-u-print-hidden"
        :class="{ 'ds-u-margin-left--auto': !hasButtonSlot }">
        <ds-close-button
          v-if="allowClose"
          aria-keyshortcuts="Esc"
          aria-label="Fechar página"
          size="lg"
          color="blue"
          tooltip="Fechar (Esc)"
          @click="onClose" />
      </div>

      <ds-heading v-if="shouldShowHeading" id="rollover-heading" :text="title" truncate>
        <slot name="title" />
      </ds-heading>

      <div v-if="hasValidBadge" class="ds-u-margin-left--sm">
        <slot name="badge" />
      </div>

      <ds-tooltipped-overflow v-if="subtitle">
        <ds-text
          v-ds-tooltip="subtitle"
          class="ds-u-text-overflow--ellipsis ds-u-display--block ds-rollover-subtitle ds-u-margin-left--sm"
          >{{ subtitle }}</ds-text
        >
      </ds-tooltipped-overflow>

      <div
        v-if="hasButtonSlot"
        class="ds-u-margin-left--auto ds-u-padding-left--sm ds-u-display--flex ds-u-print-hidden">
        <div v-if="shouldRenderFeedbackSlot">
          <slot name="feedback" />
        </div>
        <div v-if="shouldRenderHelpButtonSlot" :class="{ 'ds-u-padding-left--md': shouldRenderFeedbackSlot }">
          <slot name="help" />
        </div>
      </div>
    </ds-container>
  </div>
</template>

<script>
import DsButton from '@components/button';
import DsCloseButton from '@components/close-button';
import DsContainer from '@components/container';
import DsHeading from '@components/heading';
import DsText from '@components/text';
import DsTooltippedOverflow from '@components/tooltipped-overflow';
import DsTooltip from '@directives/tooltip';
import { vnodeService, feedbackService, helpButtonService } from '@core';

export default {
  name: 'DsRolloverHeader',
  inject: ['rolloverVm'],
  directives: {
    DsTooltip,
  },
  components: {
    DsButton,
    DsCloseButton,
    DsContainer,
    DsHeading,
    DsText,
    DsTooltippedOverflow,
  },
  props: {
    title: String,
    subtitle: String,
    size: String,
    hasSize: Boolean,
    allowClose: Boolean,
  },
  data() {
    return {
      shouldRenderFeedbackSlot: false,
      shouldRenderHelpButtonSlot: false,
    };
  },
  computed: {
    hasButtonSlot() {
      return this.shouldRenderFeedbackSlot || this.shouldRenderHelpButtonSlot;
    },
    hasValidBadge() {
      return this.$slots.badge?.length === 1 && vnodeService.isComponent(this.$slots.badge[0], 'DsBadge');
    },
    shouldShowHeading() {
      return this.title || this.$slots.title;
    },
  },
  mounted() {
    this.shouldRenderFeedbackSlot = feedbackService.shouldRenderSlot(this.$slots.feedback);
    this.shouldRenderHelpButtonSlot = helpButtonService.shouldRenderSlot(this.$slots.help);
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
  },
};
</script>
