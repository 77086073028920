<template>
  <ds-fade-transition>
    <div v-if="shouldShowProgress" class="ds-async-file-upload-status-bar">
      <ds-p v-if="filesUploadedCount && filesUploadingCount && filesFailedCount">
        <ds-text weight="bold">{{ filesUploadedCount }}</ds-text> {{ importedText }},
        <ds-text weight="bold">{{ filesUploadingCount }}</ds-text> em andamento<br v-if="mini" />
        e
        <ds-text color="red"
          ><ds-text weight="bold">{{ filesFailedCount }}</ds-text> com erro</ds-text
        >
        e restam <ds-text weight="bold">{{ prettyTotalETA }}</ds-text>
      </ds-p>

      <ds-p v-else-if="filesUploadedCount && filesUploadingCount">
        <ds-text weight="bold">{{ filesUploadedCount }}</ds-text> {{ importedText }},
        <ds-text weight="bold">{{ filesUploadingCount }}</ds-text> em andamento<br v-if="mini" />
        e restam <ds-text weight="bold">{{ prettyTotalETA }}</ds-text>
      </ds-p>

      <ds-p v-else-if="filesUploadedCount && filesFailedCount">
        <ds-text weight="bold">{{ filesUploadedCount }}</ds-text> {{ importedText }} e
        <ds-text color="red"
          ><ds-text weight="bold">{{ filesFailedCount }}</ds-text> com erro</ds-text
        >
      </ds-p>

      <ds-p v-else-if="filesUploadingCount && filesFailedCount">
        <ds-text weight="bold">{{ filesUploadingCount }}</ds-text> em andamento,
        <ds-text color="red"
          ><ds-text weight="bold">{{ filesFailedCount }}</ds-text> com erro</ds-text
        ><br v-if="mini" />
        e restam <ds-text weight="bold">{{ prettyTotalETA }}</ds-text>
      </ds-p>

      <ds-p v-else-if="filesUploadedCount">
        <ds-text weight="bold">{{ filesUploadedCount }}</ds-text> {{ importedText }}
      </ds-p>

      <ds-p v-else-if="filesUploadingCount">
        <ds-text weight="bold">{{ filesUploadingCount }}</ds-text> em andamento e restam
        <ds-text weight="bold">{{ prettyTotalETA }}</ds-text>
      </ds-p>

      <ds-p v-else-if="filesFailedCount">
        <ds-text color="red">
          <ds-text weight="bold">{{ filesFailedCount }}</ds-text> com erro
        </ds-text>
      </ds-p>
    </div>
  </ds-fade-transition>
</template>

<script>
import { pluralize } from '@core/services/pluralize/pluralizeService';
import DsP from '@components/paragraph';
import DsText from '@components/text';
import DsFadeTransition from '@components/fade-transition/FadeTransition.vue';
import { getFiles, getTotalETA, getPrettyETA } from './AsyncFileUploadStatusBarService';

export default {
  name: 'DsAsyncFileUploadStatusBar',
  components: {
    DsP,
    DsText,
    DsFadeTransition,
  },
  props: {
    contexts: Object,
    mini: Boolean,
  },
  data() {
    return {
      files: [],
      prettyTotalETA: '',
    };
  },
  computed: {
    filesUploading() {
      return this.files.filter(file => !file.isDone && !file.isFailed);
    },
    filesUploadingCount() {
      return this.filesUploading.length;
    },
    filesUploadedCount() {
      return this.files.filter(file => file.isDone).length;
    },
    importedText() {
      return pluralize('importado', this.filesUploadedCount);
    },
    filesFailedCount() {
      return this.files.filter(file => file.isFailed).length;
    },
    shouldShowProgress() {
      return this.files.length > 0;
    },
  },
  watch: {
    contexts: {
      handler() {
        this.files = getFiles(this.contexts);
        const totalETA = getTotalETA(this.filesUploading);
        this.prettyTotalETA = getPrettyETA(totalETA);
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
