<template>
  <div class="ds-collapse-actions">
    <ds-dropdown title="Expandir/Recolher" size="sm">
      <ds-dropdown-item @click="openAllCollapses()"> Expandir todos </ds-dropdown-item>
      <ds-dropdown-item @click="closeAllCollapses()"> Recolher todos </ds-dropdown-item>
    </ds-dropdown>
  </div>
</template>

<script>
import DsDropdown from '@components/dropdown';
import DsDropdownItem from '@components/dropdown-item';

export default {
  name: 'DsCollapseTableActions',
  components: {
    DsDropdown,
    DsDropdownItem,
  },
  methods: {
    openAllCollapses() {
      this.$emit('open-all-collapses');
    },
    closeAllCollapses() {
      this.$emit('close-all-collapses');
    },
  },
};
</script>

<style scoped>
@import './CollapseTableActions.css';
</style>
