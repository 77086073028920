import generateNanoId from 'nanoid/generate';

export function generateId() {
  const validChars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz$_';
  return generateNanoId(validChars, 12);
}

export function generateIdNotStartingWithNumber() {
  return moveStartingNumbersToTheEndOfString(generateId());
}

function moveStartingNumbersToTheEndOfString(string) {
  return string.replace(/(^\d+)(.*)/, '$2$1');
}
