<template>
  <div class="ds-file-input-multiple">
    <input
      ref="inputFile"
      type="file"
      multiple
      class="ds-u-display--none"
      :accept="acceptedFileTypes"
      @change="onInputFileChange" />
    <ds-row>
      <ds-col>
        <ds-drop-zone :on-drop="onDrop">
          <div class="ds-file-input-multiple__drop-area">
            <ds-row v-if="!$slots.default" align="center">
              <ds-col>
                <ds-illustration :name="illustrationName" :width="illustrationWidth" :height="illustrationHeight" />
              </ds-col>
            </ds-row>
            <div>
              <slot></slot>
            </div>
          </div>
          <!-- TODO remover align center -->
          <ds-row class="ds-file-input-multiple__attachment-trigger" align="center">
            <ds-col>
              <div class="ds-file-input-multiple__attachment-trigger--blur-effect" />
            </ds-col>
            <ds-col>
              <ds-button full-width @click="openFileSystem()">
                <ds-icon class="ds-u-margin-right--sm" icon="paperclip" />
                Anexar arquivo(s)
              </ds-button>
            </ds-col>
            <ds-col class="ds-u-margin-top--sm">
              <span class="ds-u-color--grey-dark"> Ou arraste-os para este espaço </span>
            </ds-col>
          </ds-row>
        </ds-drop-zone>
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import DsIcon from '@components/icon';
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsDropZone from '@components/drop-zone';
import DsIllustration from '@components/illustration';
import DsButton from '@components/button';

function shouldReplaceFile(file, fileList) {
  return file && fileList.length > 0;
}

export default {
  name: 'DsFileInputMultiple',
  components: {
    DsRow,
    DsCol,
    DsDropZone,
    DsIllustration,
    DsButton,
    DsIcon,
  },
  props: {
    acceptedFileTypes: {
      type: String,
      default: '*',
    },
    illustrationName: DsIllustration.props.name,
    illustrationWidth: String,
    illustrationHeight: String,
  },
  data() {
    return {
      selectedFiles: {},
      fileToReplace: null,
    };
  },
  watch: {
    selectedFiles: {
      handler() {
        this.propagateSelectedFilesUpdate();
      },
    },
  },
  methods: {
    propagateSelectedFilesUpdate() {
      const selectedFilesArray = Object.values(this.selectedFiles);
      this.$emit('input', selectedFilesArray);
    },
    isFileAlreadySelected(file) {
      return !!this.selectedFiles[file.name];
    },
    openFileSystem() {
      this.$refs.inputFile.click();
    },
    getNewFilesFromFileList(fileList) {
      const newFilesSelected = {};
      [...fileList].forEach(file => {
        if (!this.isFileAlreadySelected(file)) {
          newFilesSelected[file.name] = file;
        }
      });
      return newFilesSelected;
    },
    handleFileSelection(fileList) {
      const { selectedFiles, fileToReplace } = this;
      if (shouldReplaceFile(fileToReplace, fileList)) {
        delete selectedFiles[fileToReplace.name];
      }
      this.selectedFiles = {
        ...selectedFiles,
        ...this.getNewFilesFromFileList(fileList),
      };
      this.fileToReplace = null;
    },
    onDrop(event) {
      this.handleFileSelection(event.dataTransfer.files);
    },
    onInputFileChange(event) {
      this.handleFileSelection(event.target.files);
    },
    async removeSelectedFile(file) {
      delete this.selectedFiles[file.name];
      if (!this.selectedFiles.length) {
        this.$refs.inputFile.value = '';
      }
      await this.$nextTick();
      this.propagateSelectedFilesUpdate();
    },
    replaceFile(file) {
      this.fileToReplace = file;
      this.openFileSystem();
    },
  },
};
</script>
