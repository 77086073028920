<template>
  <ds-range-popover :on-apply="onSubmit" @hook:destroyed="$destroy()">
    <ds-field slot="start-field" label="Valor inicial">
      <ds-currency-input v-model="localStartValue" required />
    </ds-field>
    <ds-field slot="end-field" label="Valor final">
      <ds-currency-input v-model="localEndValue" required />
    </ds-field>
  </ds-range-popover>
</template>

<script>
import DsRangePopover from '@components/range-popover/RangePopover.vue';
import DsField from '@components/field';
import DsCurrencyInput from '@components/currency-input';

export default {
  name: 'DsCurrencyRangePopover',
  components: {
    DsCurrencyInput,
    DsField,
    DsRangePopover,
  },
  props: {
    startValue: {
      type: Number,
      default: null,
    },
    endValue: {
      type: Number,
      default: null,
    },
    onApply: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      localStartValue: this.startValue,
      localEndValue: this.endValue,
    };
  },
  methods: {
    onSubmit() {
      return this.onApply({
        startValue: this.localStartValue,
        endValue: this.localEndValue,
      });
    },
  },
};
</script>
