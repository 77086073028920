<template>
  <portal to="data-grid-filter">
    <div class="ds-data-grid-header-summary" :class="{ 'ds-data-grid-header-summary--visible': showFilterSummary }">
      <template v-if="showFilterSummary">
        <div v-if="showPeriodFilter">
          <ds-data-grid-date-period-filter
            :disabled="disablePeriodFilter"
            :period-type="dataGridFilters.periodType"
            :period-types="filters.periodTypes"
            :value="dataGridFilters.period"
            @input="emitPeriodAndPeriodType" />
        </div>
        <div
          v-if="showSearchTermFilter"
          class="ds-u-padding-left--md"
          :class="{ 'ds-u-print-hidden': !dataGridFilters.searchTerm }">
          <ds-search-form
            :value="dataGridFilters.searchTerm"
            :on-search="emitSearchTerm"
            :on-clear-search="emitSearchTerm"
            :placeholder="searchTermPlaceholder" />
        </div>
        <div class="ds-u-display--flex ds-u-margin-left--auto">
          <ds-button icon="arrow-up" icon-position="left" theme="link" @click="scrollTop">Voltar ao topo</ds-button>
          <div>
            <ds-data-grid-pagination v-bind="dataGridPaginationData" is-dropdown @page-change="onPageChange" />
          </div>
        </div>
      </template>
    </div>
  </portal>
</template>

<script>
import { Portal } from 'portal-vue';
import { scrollService } from '@core';
import DsSearchForm from '@components/search-form';
import DsButton from '@components/button';
import isEqual from 'lodash/isEqual';
import DsDataGridDatePeriodFilter from './DataGridDatePeriodFilter.vue';
import DsDataGridPagination from './DataGridPagination.vue';

export default {
  name: 'DsDataGridHeaderSummary',
  components: {
    Portal,
    DsSearchForm,
    DsDataGridDatePeriodFilter,
    DsDataGridPagination,
    DsButton,
  },
  inject: {
    dataGrid: {
      default: null,
    },
  },
  props: {
    disablePeriodFilter: {
      type: Boolean,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    showPeriodFilter: Boolean,
    showSearchTermFilter: Boolean,
    searchTermPlaceholder: String,
    advancedFilterContainerRef: HTMLDivElement,
  },
  data() {
    return {
      showFilterSummary: false,
      scrollableArea: null,
    };
  },
  computed: {
    dataGridPaginationData() {
      return this.dataGrid?.store.getPaginationData();
    },
    dataGridFilters() {
      return this.dataGrid.store.filters;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollableArea = getScrollableParent(this.$el);
      this.scrollableArea.addEventListener('scroll', this.checkVisibility);
    });
  },
  beforeDestroy() {
    this.scrollableArea.removeEventListener('scroll', this.checkVisibility);
  },
  methods: {
    checkVisibility() {
      const scrollAwareElement = this.advancedFilterContainerRef;

      const isVisible = scrollService.isInViewport(scrollAwareElement, this.scrollableArea);

      this.showFilterSummary = !isVisible;
    },
    scrollTop() {
      scrollService.scrollTop(this.scrollableArea);
    },
    onPageChange(pagination) {
      this.dataGrid.store.setPaginationData(pagination);
      this.scrollTop();
    },
    emitPeriodAndPeriodType(period, periodType) {
      if (!isEqual(period, this.dataGridFilters.period)) {
        this.scrollTop();
      }
      this.$emit('change-period-periodType', period, periodType);
    },
    emitSearchTerm(searchTerm = '') {
      this.scrollTop();
      this.$emit('change-search-term', searchTerm);
    },
  },
};

function getScrollableParent(el) {
  let scrollableElement = document.querySelector('[ds-scrollable-body]');
  if (!scrollableElement) {
    scrollableElement = scrollService.getScrollableParentElement(el);
  }

  return scrollableElement;
}
</script>

<style scoped>
@import './DataGridHeaderSummary.css';
</style>

