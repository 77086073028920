<template>
  <div class="ds-col" :class="classes" data-col>
    <slot></slot>
  </div>
</template>

<script>
function isValidSize(value) {
  const size = parseInt(value);
  return size >= 1 && size <= 12;
}

function validateColumnsSize(value) {
  return !value || isValidSize(value) || value === 'auto' || value === 'fill';
}

export default {
  name: 'DsCol',
  props: {
    size: {
      type: [String, Number],
      validator: validateColumnsSize,
    },
    offset: {
      type: [String, Number],
      validator: validateColumnsSize,
    },
    xs: {
      type: [String, Number],
      validator: validateColumnsSize,
    },
    sm: {
      type: [String, Number],
      validator: validateColumnsSize,
    },
    lg: {
      type: [String, Number],
      validator: validateColumnsSize,
    },
    offsetXs: {
      type: [String, Number],
      validator: validateColumnsSize,
    },
    offsetSm: {
      type: [String, Number],
      validator: validateColumnsSize,
    },
    offsetLg: {
      type: [String, Number],
      validator: validateColumnsSize,
    },
  },
  computed: {
    sizeClasses() {
      return this.size ? `ds-col-${this.size}` : 'ds-col-12';
    },
    offsetClasses() {
      return this.offset ? `ds-col-offset-${this.offset}` : '';
    },
    sizeClassesXs() {
      return this.xs ? `ds-col-${this.xs}--xs` : 'ds-col-12--xs';
    },
    offsetClassesXs() {
      return this.offsetXs ? `ds-col-offset-${this.offsetXs}--xs` : '';
    },
    sizeClassesSm() {
      return this.sm ? `ds-col-${this.sm}--sm` : 'ds-col-12--sm';
    },
    offsetClassesSm() {
      return this.offsetSm ? `ds-col-offset-${this.offsetSm}--sm` : '';
    },
    sizeClassesLg() {
      return this.lg ? `ds-col-${this.lg}--lg` : 'ds-col-12--lg';
    },
    offsetClassesLg() {
      return this.offsetLg ? `ds-col-offset-${this.offsetLg}--lg` : '';
    },
    isMediaSizeProvided() {
      return this.xs || this.sm || this.lg || this.offsetXs || this.offsetSm || this.offsetLg;
    },
    classes() {
      return this.isMediaSizeProvided
        ? [
            this.sizeClassesXs,
            this.offsetClassesXs,
            this.sizeClassesSm,
            this.sizeClassesLg,
            this.offsetClassesSm,
            this.offsetClassesLg,
          ]
        : [this.sizeClasses, this.offsetClasses];
    },
  },
};
</script>

<style scoped>
@import './Col.css';
</style>
