export const DEPRECATIONS_PROPS = [
  {
    name: 'onFetchError',
    message: 'Use @fetch-error event instead',
  },
  {
    name: 'onFetchSuccess',
    message: 'Use @fetch-success event instead',
  },
  {
    name: 'onQueryError',
    message: 'Use @query-error event instead',
  },
  {
    name: 'onQuerySuccess',
    message: 'Use @query-success event instead',
  },
  {
    name: 'onQuery',
    message: 'Use queryAction instead',
  },
  {
    name: 'onChange',
    message: 'Use @change event instead',
  },
  {
    name: 'onOpen',
    message: 'Use @open event instead',
  },
  {
    name: 'onClose',
    message: 'Use @close event instead',
  },
  {
    name: 'onQuickAddButtonClick',
    message: 'Use @quick-add-button-click event instead',
  },
  {
    name: 'onQueryTermChange',
    message: 'Use @query-term-change event instead',
  },
  { name: 'onFetch', message: 'Use fetchAction instead' },
  {
    name: 'onLoadMoreButtonClick',
    message: 'Use @load-more-button-click event instead',
  },
];
