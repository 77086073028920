<template>
  <ds-tr
    class="ds-table-row-collapsible"
    :theme="theme"
    :class="classes"
    :data-collapsed="collapsed"
    @click="$emit('click', $event)">
    <slot></slot>
  </ds-tr>
</template>

<script>
import DsTr from '@components/table-row';
import { handleChevronIcon } from './tableRowCollapseService';

export default {
  name: 'DsTrCollapsible',
  inject: ['tableRowCollapseVm'],
  components: {
    DsTr,
  },
  props: {
    collapsed: Boolean,
    collapseLevel: Number,
    theme: DsTr.props.theme,
    type: {
      type: String,
      validator(type) {
        return !type || ['group'].includes(type);
      },
    },
  },
  computed: {
    classes() {
      const levelClass = `ds-table-row-collapsible-level-${this.collapseLevel}`;
      const groupClass = 'ds-table-row-collapsible-group-header';
      return this.type === 'group' ? groupClass : levelClass;
    },
  },
  watch: {
    collapsed() {
      this.handleChevronIcon();
    },
  },
  mounted() {
    /** Awaits for ds-td to finish rendering completely */
    setTimeout(() => this.handleChevronIcon());
  },
  methods: {
    handleChevronIcon() {
      const firstCol = this.getFirstCol();
      handleChevronIcon(firstCol, this.collapsed, this.toggleCollapse);
    },
    getFirstCol() {
      return [...this.$el.querySelectorAll('.ds-table-col')].find(
        item => !item.querySelector('.ds-checkbox') && !item.querySelector('.ds-radio'),
      );
    },
    toggleCollapse(event) {
      event.stopPropagation();
      this.tableRowCollapseVm.toggleCollapse();
    },
  },
};
</script>
