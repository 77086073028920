export const RICH_TEXT_FORMATS = Object.freeze([
  { name: 'Negrito', className: 'ql-bold' },
  { name: 'Itálico', className: 'ql-italic' },
  { name: 'Sublinhar', className: 'ql-underline' },
  { name: 'Riscar', className: 'ql-strike' },
]);

export const RICH_TEXT_COLORS = Object.freeze([
  '#E5E5E5',
  '#7BC6F8',
  '#64E6FA',
  '#7080EC',
  '#55D266',
  '#FFD054',
  '#FF7A65',
  '#C6C6C6',
  '#5AADF1',
  '#00AFF0',
  '#192BC2',
  '#26B547',
  '#FFBA1C',
  '#FF3D32',
  '#9B9B9B',
  '#1B69C8',
  '#0088CE',
  '#1220A6',
  '#1B9B45',
  '#DB9814',
  '#DB242A',
  '#595959',
  '#134EA7',
  '#0066AC',
  '#0C178B',
  '#138242',
  '#B7790E',
  '#B7192B',
  '#333333',
  '#07266F',
  '#003473',
  '#040A5D',
  '#075638',
  '#7A4805',
  '#7A0929',
]);

export const RICH_TEXT_ALIGNS = Object.freeze([
  { value: '', tooltip: 'Alinhar a esquerda' },
  { value: 'center', tooltip: 'Alinhar no centro' },
  { value: 'right', tooltip: 'Alinhar a direita' },
  { value: 'justify', tooltip: 'Justificar' },
]);

export const RICH_TEXT_LISTS = Object.freeze([
  { value: 'bullet', tooltip: 'Lista com marcadores' },
  { value: 'ordered', tooltip: 'Lista numerada' },
]);

export const RICH_TEXT_EXTERNALS = Object.freeze([{ className: 'ql-link', tooltip: 'Inserir link' }]);

export const RICH_TEXT_ACCEPTED_FORMATS = Object.freeze([
  'background',
  'bold',
  'color',
  'font',
  'code',
  'italic',
  'link',
  'size',
  'strike',
  'script',
  'underline',
  'blockquote',
  'header',
  'indent',
  'list',
  'align',
  'direction',
  'code-block',
  'formula',
]);
