<template>
  <ds-badge class="ds-status-badge" :class="classes" :theme="theme">
    <slot />
  </ds-badge>
</template>

<script>
import DsBadge from '@components/badge';
import { debug } from '@core';

/* TODO: Remove this component from library
 * https://contaazul.atlassian.net/browse/DX-899
 */

export default {
  name: 'DsStatusBadge',
  components: {
    DsBadge,
  },
  props: {
    /**
     * [success, info, warning, danger]
     * */
    theme: DsBadge.props.theme,
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        'ds-status-badge--full-width': this.fullWidth,
      };
    },
  },
  created() {
    debug.error('The DsStatusBadge is DEPRECATED! Use DsBadge instead.');
  },
};
</script>

<style scoped>
@import './StatusBadge.css';
</style>
