<template>
  <span class="ds-icon" :class="cssClasses" v-on="$listeners">
    <ds-fade-transition>
      <component :is="specialIcon || overrideIcon" v-if="isSpecialOrOverrideIcon" />
      <font-awesome-icon
        v-else-if="!isSpecial && !shouldOverrideIcon"
        :icon="prefixedIcon"
        :size="iconSize"
        :transform="transform"
        fixed-width />
    </ds-fade-transition>
  </span>
</template>

<script>
import * as coreService from '@core';
import DsFadeTransition from '@components/fade-transition/FadeTransition.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

const { debug, iconService, constants, stringService } = coreService;

export default {
  name: 'DsIcon',
  components: {
    FontAwesomeIcon,
    DsFadeTransition,
  },
  props: {
    /**
     * <icon-name> OR [<solid|regular|light|duotone>, <icon-name>]
     */
    icon: {
      type: [String, Array],
      required: true,
    },
    /**
     * ['sm', 'md', 'lg', 'xl']
     */
    size: {
      type: String,
      default: 'md',
      validator(size) {
        return ['sm', 'md', 'lg', 'xl', 'xxl'].includes(size);
      },
    },
    transform: {
      type: String,
    },
    color: {
      type: String,
      validator(color) {
        return constants.COLOR_CONSTANTS.COLORS.includes(color);
      },
    },
    /*
     * Any css valid color; Ex: #fff, white, etc...
     */
  },
  data() {
    return {
      specialIcon: null,
      overrideIcon: null,
    };
  },
  computed: {
    name() {
      return Array.isArray(this.icon) ? this.icon[1] : this.icon;
    },
    type() {
      return Array.isArray(this.icon) ? this.icon[0] : 'solid';
    },
    isSpecial() {
      return this.type === 'special';
    },
    shouldOverrideIcon() {
      return !!iconService.isIconToOverride(this.name);
    },
    prefixedIcon() {
      return iconService.getPrefixedIcon(this.name, this.type);
    },
    iconSize() {
      return iconService.getSize(this.size);
    },
    isSpecialOrOverrideIcon() {
      return (this.specialIcon && this.isSpecial) || (this.overrideIcon && this.shouldOverrideIcon);
    },
    cssClasses() {
      return [{ [`ds-u-color--${this.color}`]: this.color }, `ds-icon--${this.size || 'md'}`];
    },
  },
  watch: {
    name: {
      immediate: true,
      handler(name) {
        if (this.shouldOverrideIcon) {
          this.fetchOverrideIcon(this.type, name);
          return;
        }

        this.fetchSpecialIcon(name);
      },
    },
  },
  created() {
    if (!this.shouldOverrideIcon && !this.isSpecial && !iconService.isValidIcon(this.name, this.type)) {
      debug.error(`The icon ${this.icon} is not in the fontawesome library. Please add it with library.add().`);
    }
  },
  methods: {
    fetchSpecialIcon(name) {
      if (!this.isSpecial) {
        return;
      }
      const pascalCasedName = stringService.toPascalCase(name);

      fetchComponent(pascalCasedName).then(specialIcon => {
        this.specialIcon = specialIcon;
      });
    },
    fetchOverrideIcon(type, name) {
      const iconName = iconService.getOverrideIconFile(name);

      const pascalCasedName = `${stringService.toPascalCase(type)}${stringService.toPascalCase(iconName)}`;
      fetchFontawesomeIcon(pascalCasedName)
        .then(icon => {
          this.overrideIcon = icon;
        })
        .catch(() => {
          debug.error(`The icon ${pascalCasedName} could not be found in our library. Please add it and try again`);
        });
    },
  },
};

function fetchComponent(name) {
  // eslint-disable-next-line prefer-template
  return import(`./special-icons/${name}.vue`).then(component => component.default);
}

function fetchFontawesomeIcon(name) {
  // eslint-disable-next-line prefer-template
  return import(`./fontawesome-overrides/${name}.vue`).then(component => component.default);
}
</script>

<style scoped>
@import './Icon.css';
</style>
