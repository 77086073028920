<template>
  <ds-tooltipped-overflow v-if="shouldTruncateContent">
    <ds-td-content v-bind="$props" truncate-content :class="classes" :style="styles">
      <slot></slot>
    </ds-td-content>
  </ds-tooltipped-overflow>
  <ds-td-content v-else v-bind="$props" :class="classes" :style="styles">
    <slot></slot>
  </ds-td-content>
</template>

<script>
import { debug } from '@core';
import { getFixedColumnClasses, getFixedColumnOffset } from '@components/table/tableService';
import DsTooltippedOverflow from '@components/tooltipped-overflow';
import DsTdContent from './TableColumnContent.vue';

export default {
  name: 'DsTd',
  inject: ['tableStore', 'tableRowVm'],
  components: {
    DsTdContent,
    DsTooltippedOverflow,
  },
  props: {
    align: {
      type: String,
      validator(align) {
        return ['center', 'right'].includes(align);
      },
    },
    withInput: {
      type: String,
      validator(value) {
        return ['input', 'dropdown'].includes(value);
      },
    },
    tooltip: {
      type: String,
    },
    truncate: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      shouldTruncateContent: false,
      index: null,
    };
  },
  computed: {
    column() {
      return this.tableStore.getColumnByIndex(this.index);
    },
    classes() {
      return getFixedColumnClasses(this.column);
    },
    styles() {
      return getFixedColumnOffset(this.column);
    },
  },
  mounted() {
    if (!this.tableStore) {
      throw new Error('DsTd must be wrapped with DsTable');
    }

    if (!this.tableRowVm) {
      throw new Error('DsTd must be wrapped with DsTr');
    }

    this.$nextTick(() => {
      if (this.truncate) {
        this.setShouldTruncateContent();
      }
      this.setIndex();
      this.$nextTick(() => {
        this.fixedColumnWithColspanHandler();
      });
    });
  },
  methods: {
    setShouldTruncateContent() {
      this.shouldTruncateContent =
        (!this.$el ||
          (!this.$el.getElementsByClassName('ds-dropdown').length &&
            !this.$el.getElementsByClassName('ds-select').length &&
            !this.$el.getElementsByClassName('ds-button').length &&
            !this.$el.getElementsByClassName('ds-checkbox').length &&
            !this.$el.getElementsByClassName('ds-radio-group').length &&
            !this.$el.getElementsByClassName('ds-multiple-input').length &&
            !this.withInput)) &&
        !this.tooltip;
    },
    setIndex() {
      this.index = this.tableRowVm.createColumnIndex();
    },
    fixedColumnWithColspanHandler() {
      if (this.column.fixed && this.$el.colSpan > 1) {
        debug.error('A fixed column cannot have colspan defined', this);
      }
    },
  },
};
</script>
