<template>
  <div v-on="$listeners">
    <ds-row content-vertical-align="center" no-wrap class="ds-u-margin-y--none ds-u-margin-right--none">
      <ds-col v-if="icon" size="auto" class="ds-sidebar-menu-item-content-col ds-sidebar-menu-item-content-col--icon">
        <ds-icon :icon="icon" class="ds-u-margin--none ds-sidebar-menu-item-content__main-icon" size="md" />
      </ds-col>
      <ds-col size="auto" class="ds-sidebar-menu-item-content-col ds-u-padding-y--none ds-u-padding-right--none">
        <ds-text class="ds-sidebar-menu-item-content-text">{{ text }}</ds-text>
      </ds-col>
      <ds-col v-if="badgeValue" size="auto" class="ds-sidebar-menu-item-content-col">
        <ds-badge class="ds-u-margin--none" :theme="badgeTheme"> {{ badgeValue }} </ds-badge>
      </ds-col>
      <ds-col
        v-if="shouldShowCountValue"
        size="auto"
        class="ds-sidebar-menu-item-content-col ds-u-padding-right--none ds-u-padding-y--none">
        <ds-badge v-ds-tooltip="countValueTooltip" theme="warning" class="ds-u-padding-left--xs ds-u-padding-right--xs">
          {{ formattedCountValue }}
        </ds-badge>
      </ds-col>
      <ds-col
        v-if="isNew"
        size="auto"
        class="ds-sidebar-menu-item-content-col ds-u-padding-right--none ds-u-padding-y--none">
        <ds-badge class="ds-u-margin--none">Novo</ds-badge>
      </ds-col>
      <ds-col
        v-if="isExternal"
        size="auto"
        class="ds-sidebar-menu-item-content-col ds-u-margin-left--auto ds-u-padding-right--none">
        <ds-icon class="ds-sidebar-menu-item-single__external-link" icon="external-link" size="sm" />
      </ds-col>
      <ds-col
        v-if="chevron"
        size="auto"
        class="ds-sidebar-menu-item-content-col ds-u-margin-left--auto ds-u-padding-right--none">
        <ds-icon class="ds-sidebar-menu-item-group__chevron" size="sm" :icon="chevron" />
      </ds-col>
    </ds-row>
    <div v-if="showFavoriteButton" class="ds-sidebar-menu-item-content-favorite">
      <ds-icon
        v-ds-tooltip="iconObj.tooltip"
        size="sm"
        :icon="iconObj.value"
        class="ds-sidebar-menu-item-content-favorite__icon"
        :class="[iconObj.class, favoriteIconAnimationClass]"
        @click.stop.prevent="onFavorite"
        @animationend="onAnimationEnd" />
    </div>
  </div>
</template>

<script>
import DsIcon from '@components/icon';
import DsCol from '@components/col';
import DsRow from '@components/row';
import DsBadge from '@components/badge';
import DsText from '@components/text';
import DsTooltip from '@directives/tooltip';

const MAX_VALUE = 999;

export default {
  name: 'DsSidebarMenuItemContent',
  components: {
    DsIcon,
    DsCol,
    DsRow,
    DsBadge,
    DsText,
  },
  directives: {
    DsTooltip,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: DsIcon.props.icon.type,
    },
    isNew: {
      type: Boolean,
    },
    isExternal: {
      type: Boolean,
    },
    chevron: {
      type: String,
      validator(chevron) {
        return ['chevron-up', 'chevron-down', 'chevron-right', 'chevron-left'].includes(chevron);
      },
    },
    showFavoriteButton: {
      type: Boolean,
    },
    isFavorite: {
      type: Boolean,
    },
    badgeValue: {
      type: String,
    },
    badgeTheme: {
      type: String,
    },
    countValue: {
      type: Number,
    },
    countValueTooltip: {
      type: String,
      default: null,
    },
    type: {
      type: String,
    },
  },
  data() {
    return {
      favoriteIconAnimationClass: null,
    };
  },
  computed: {
    iconObj() {
      return this.isFavorite
        ? {
            value: ['solid', 'star'],
            tooltip: 'Remover dos favoritos',
            class: 'ds-sidebar-menu-item-content-favorite__icon--favorite',
          }
        : {
            value: ['regular', 'star'],
            tooltip: 'Adicionar ao menu favoritos',
            class: 'ds-sidebar-menu-item-content-favorite__icon--unfavorite',
          };
    },
    shouldShowCountValue() {
      return this.countValue > 0;
    },
    formattedCountValue() {
      return this.countValue > MAX_VALUE ? `${MAX_VALUE}+` : this.countValue;
    },
  },
  methods: {
    onFavorite() {
      this.$emit('favorite-change', !this.isFavorite);
      this.setFavoriteIconAnimationClass();
    },
    setFavoriteIconAnimationClass() {
      const animationClass = this.type === 'FAVORITE' ? 'ds-u-animation--fade-out' : 'ds-u-animation--pulse';
      this.favoriteIconAnimationClass = animationClass;
    },
    onAnimationEnd() {
      this.favoriteIconAnimationClass = '';
    },
  },
};
</script>
<style scoped>
@import './SidebarMenuItemContent.css';
</style>
