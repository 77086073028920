import vanillaTextMask from 'vanilla-text-mask';

const vanillaTextMaskFunc = vanillaTextMask.default || vanillaTextMask;

function isEmpty(value) {
  return value === undefined || value === null || value === '';
}

function isIntegerPattern(pattern) {
  return pattern === 'integer';
}

function getIntegerRegex() {
  return value => {
    const integerCharactersRule = Array.from(value).map(() => /\d/);
    const firstIntegerCharacterRule = /-|\d/;
    integerCharactersRule[0] = firstIntegerCharacterRule;
    return integerCharactersRule;
  };
}

function mapCharacterToRegex(char) {
  const mappings = {
    9: /\d/,
    A: /[a-zA-Z]/,
    S: /\w/,
  };

  return mappings[char] ? mappings[char] : char;
}

function getConfig(element, pattern, updateModel) {
  return {
    inputElement: element,
    mask: getRegexMask(pattern),
    guide: false,
    pipe(conformedValue, config) {
      if (isEmpty(conformedValue) && isEmpty(config.previousConformedValue)) {
        return '';
      }

      if (updateModel) {
        updateModel(conformedValue);
      }

      return conformedValue;
    },
  };
}

export function apply(element, pattern, updateModel) {
  element._mask = vanillaTextMaskFunc(getConfig(element, pattern, updateModel));
}

export function update(element, pattern, value) {
  element._mask.textMaskInputElement.update(value, getConfig(element, pattern));
}

export function destroy(element) {
  element._mask.destroy();
}

export function getRegexMask(pattern) {
  if (!pattern) {
    return value => Array.from(value).map(() => /.*?/);
  }

  if (isIntegerPattern(pattern)) {
    return getIntegerRegex();
  }

  return Array.from(pattern).map(mapCharacterToRegex);
}
