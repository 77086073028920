<template>
  <ds-tr class="ds-batch-table-footer-tr">
    <slot></slot>
  </ds-tr>
</template>

<script>
import DsTr from '@components/table-row';

export default {
  name: 'DsBatchTableFooterTr',
  inject: ['batchTableVm'],
  components: {
    DsTr,
  },
  created() {
    if (!this.batchTableVm) {
      throw new Error('DsBatchTableTr must be wrapped with DsBatchTable');
    }
  },
};
</script>
