<template>
  <div class="ds-file-upload-multiple">
    <ds-row>
      <ds-col>
        <ds-file-input-multiple
          ref="fileInputMultiple"
          v-model="selectedFiles"
          :illustration-name="illustrationName"
          :illustration-width="illustrationWidth"
          :illustration-height="illustrationHeight"
          :accepted-file-types="acceptedFileTypes">
          <ds-row v-if="isSomeFileSelected" class="ds-file-upload-multiple__preview-files">
            <ds-col>
              <ds-file-preview
                v-for="invalidFile in invalidFiles"
                :key="invalidFile.name"
                class="ds-u-margin-bottom--sm"
                :file="invalidFile"
                status="invalid"
                @delete-file="onDeleteFile(invalidFile)"
                @replace-file="onReplaceFile(invalidFile)" />
              <ds-file-uploader
                v-for="validFile in validFiles"
                :key="validFile.name"
                class="ds-u-margin-bottom--sm"
                :file="validFile"
                :upload-file="onUpload"
                @upload-success="response => onFileUploadSuccess(validFile, response)"
                @upload-error="response => onFileUploadError(validFile, response)"
                @delete-file="onDeleteFile" />
            </ds-col>
          </ds-row>
        </ds-file-input-multiple>
      </ds-col>
    </ds-row>
    <ds-row class="ds-file-upload-multiple__files-status-count" align="center">
      <ds-col size="12">
        <!-- eslint-disable vue/no-v-html -->
        <span v-html="uploadedFilesMessage" />
      </ds-col>
      <ds-col class="ds-u-color--red" size="12">
        <span>{{ invalidFilesMessage }}</span>
      </ds-col>
      <ds-col class="ds-u-color--red" size="12">
        <span>{{ errorFilesMessage }}</span>
      </ds-col>
    </ds-row>
  </div>
</template>

<script lang="jsx">
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsFilePreview from '@components/file-preview';
import {
  isFileMinSizeValid,
  isFileMaxSizeValid,
  isFileTypeValid,
} from '@core/services/fileValidation/fileValidationService';
import { getSubtractedArray } from '@core/services/array/arrayService';
import { buildModelValidation } from '@core/services/modelValidation/modelValidationService';
import DsFileInputMultiple from './FileInputMultiple.vue';
import DsFileUploader from './FileUploader.vue';

export default {
  name: 'DsFileUploadMultiple',
  inject: {
    formVm: {
      default: null,
    },
  },
  components: {
    DsFileInputMultiple,
    DsRow,
    DsCol,
    DsFilePreview,
    DsFileUploader,
  },
  props: {
    minFilesSize: Number,
    maxFilesSize: Number,
    /**
     * Any value (in bytes)
     */
    minFilesSelected: Number,
    /**
     * Any value (in bytes)
     */
    maxFilesSelected: Number,
    /**
     * [Function, Function, Function, ..., Function]
     */
    customFileValidations: {
      type: Array,
      default: () => [],
    },
    onFilesSelectedChange: {
      type: Function,
      default: () => {},
    },
    onDelete: {
      type: Function,
      default: () => {},
    },
    onUpload: DsFileUploader.props.uploadFile,
    onUploadSuccess: {
      type: Function,
      default: () => {},
    },
    onUploadError: {
      type: Function,
      default: () => {},
    },
    /**
     * Any string on Unique file type specifiers format
     */
    acceptedFileTypes: DsFileInputMultiple.props.acceptedFileTypes,
    illustrationName: DsFileInputMultiple.props.illustrationName,
    illustrationWidth: DsFileInputMultiple.props.illustrationWidth,
    illustrationHeight: DsFileInputMultiple.props.illustrationHeight,
  },
  data() {
    return {
      modelValidator: buildModelValidation(this),
      selectedFiles: [],
      uploadedFiles: {},
      errorFiles: {},
    };
  },
  computed: {
    isSomeFileSelected() {
      return this.selectedFiles.length > 0;
    },
    minSizeValidation() {
      return file => isFileMinSizeValid(file, this.minFilesSize);
    },
    maxSizeValidation() {
      return file => isFileMaxSizeValid(file, this.maxFilesSize);
    },
    fileExtensionValidations() {
      return file => isFileTypeValid(file, this.acceptedFileTypes);
    },
    customValidations() {
      return [
        ...this.customFileValidations,
        ...(this.minFilesSize ? [this.minSizeValidation] : []),
        ...(this.maxFilesSize ? [this.maxSizeValidation] : []),
        ...(this.acceptedFileTypes ? [this.fileExtensionValidations] : []),
      ];
    },
    validFiles() {
      return this.selectedFiles.filter(file => this.isFileValid(file));
    },
    invalidFiles() {
      return getSubtractedArray(this.selectedFiles, this.validFiles);
    },
    isMinFilesSelectedValid() {
      return !this.minFilesSelected || this.selectedFiles.length >= this.minFilesSelected;
    },
    isMaxFilesSelectedValid() {
      return !this.maxFilesSelected || this.selectedFiles.length <= this.maxFilesSelected;
    },
    isFileUploadMultipleValid() {
      return (
        this.isMaxFilesSelectedValid &&
        this.isMinFilesSelectedValid &&
        this.invalidFiles.length === 0 &&
        this.validFiles.length === this.uploadedFilesCount
      );
    },
    uploadedFilesCount() {
      return Object.keys(this.uploadedFiles).length;
    },
    errorFilesCount() {
      return Object.keys(this.errorFiles).length;
    },
    uploadedFilesMessage() {
      if (this.minFilesSelected) {
        return `<strong>${this.uploadedFilesCount} de ${this.minFilesSelected}</strong> arquivo(s) enviado(s)`;
      }
      return this.uploadedFilesCount ? `<strong>${this.uploadedFilesCount}</strong> arquivo(s) enviado(s)` : '';
    },
    invalidFilesMessage() {
      const invalidFilesLength = this.invalidFiles.length;
      return invalidFilesLength ? `${invalidFilesLength} arquivo(s) inválido(s)` : '';
    },
    errorFilesMessage() {
      return this.errorFilesCount > 0 ? `${this.errorFilesCount} arquivo(s) com erro` : '';
    },
  },
  watch: {
    selectedFiles() {
      this.onFilesSelectedChange(this.selectedFiles);
    },
    isFileUploadMultipleValid() {
      this.modelValidator._setValid(this.isFileUploadMultipleValid);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.modelValidator._setRules(this.customValidations);
      this.modelValidator._setValid(this.isFileUploadMultipleValid);
    });
  },
  methods: {
    isFileValid(file) {
      return this.customValidations.every(rule => rule(file));
    },
    onDeleteFile(file) {
      this.$refs.fileInputMultiple.removeSelectedFile(file);
      this.removeFileFromMap(file, 'uploadedFiles');
      this.removeFileFromMap(file, 'errorFiles');
      this.onDelete(file);
    },
    onReplaceFile(file) {
      this.$refs.fileInputMultiple.replaceFile(file);
    },
    onFileUploadSuccess(file, response) {
      this.setFileToMap(file, 'uploadedFiles');
      this.removeFileFromMap(file, 'errorFiles');
      this.onUploadSuccess(response);
    },
    onFileUploadError(file, error) {
      this.setFileToMap(file, 'errorFiles');
      this.removeFileFromMap(file, 'uploadedFiles');
      this.onUploadError(error);
    },
    setFileToMap(file, map) {
      this.$set(this[map], file.name, file);
    },
    removeFileFromMap(file, map) {
      this.$delete(this[map], file.name);
    },
  },
};
</script>

<style scoped>
@import './FileUploadMultiple.css';
</style>
