<template>
  <ds-split-button-wrapper v-bind="$props" :disabled="handleDisabled" @request-loading="onRequestLoading">
    <template #button>
      <component
        :is="mainButtonComponent"
        :is-loading="isLoading"
        :theme="theme"
        :disabled="handleDisabled"
        :full-width="fullWidth"
        v-on="$listeners">
        {{ title }}
      </component>
    </template>
    <slot />
  </ds-split-button-wrapper>
</template>

<script>
import DsButton from '@components/button';
import DsLoaderButton from '@components/loader-button/LoaderButton.vue';
import DsSubmitButton from '@components/submit-button';
import DsSplitButtonWrapper from '@components/split-button-wrapper/SplitButtonWrapper.vue';

export default {
  name: 'DsSplitSubmitButton',
  inject: {
    formVm: {
      default: null,
    },
  },
  components: {
    DsButton,
    DsLoaderButton,
    DsSubmitButton,
    DsSplitButtonWrapper,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    theme: DsButton.props.theme,
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
    },
  },
  data() {
    return {
      isRequestLoading: false,
    };
  },
  computed: {
    mainButtonComponent() {
      return this.isLoading ? 'ds-loader-button' : 'ds-submit-button';
    },
    handleDisabled() {
      return this.disabled || this.isLoading;
    },
    isLoading() {
      return this.isFormSubmiting || this.isRequestLoading;
    },
    isFormSubmiting() {
      return this.formVm.isSubmitting;
    },
  },
  methods: {
    onRequestLoading(value) {
      this.isRequestLoading = value;
    },
  },
};
</script>
