<template>
  <span class="ds-link">
    <a v-if="href" v-bind="$attrs" :href="href" v-on="$listeners">
      <slot></slot>
    </a>
    <ds-button v-else v-bind="$attrs" v-on="$listeners">
      <slot></slot>
    </ds-button>
  </span>
</template>

<script>
import DsButton from '@components/button';

export default {
  name: 'DsLink',
  components: {
    DsButton,
  },
  props: {
    href: String,
  },
};
</script>

<style scoped>
@import './Link.css';
</style>
