import { dateParser, dateService } from '@core';

const getTodayPeriod = (referenceDate = dateService.getCurrentDate()) => ({
  startDate: dateParser.objectToISODate(referenceDate),
  endDate: dateParser.objectToISODate(referenceDate),
});

const getThisWeekPeriod = (referenceDate = dateService.getCurrentDate()) => ({
  startDate: dateParser.objectToISODate(dateService.getFirstDayOfTheWeek(referenceDate)),
  endDate: dateParser.objectToISODate(dateService.getLastDayOfTheWeek(referenceDate)),
});

const getThisMonthPeriod = (referenceDate = dateService.getCurrentDate()) => ({
  startDate: dateParser.objectToISODate(dateService.getFirstDayOfTheMonth(referenceDate)),
  endDate: dateParser.objectToISODate(dateService.getLastDayOfTheMonth(referenceDate)),
});

const getThisYearPeriod = (referenceDate = dateService.getCurrentDate()) => ({
  startDate: dateParser.objectToISODate(dateService.getFirstDayOfTheYear(referenceDate)),
  endDate: dateParser.objectToISODate(dateService.getLastDayOfTheYear(referenceDate)),
});

const getLastThirtyDaysPeriod = (referenceDate = dateService.getCurrentDate()) => ({
  startDate: dateParser.objectToISODate(dateService.addDays(referenceDate, -30)),
  endDate: dateParser.objectToISODate(referenceDate),
});

const getLastYearPeriod = (referenceDate = dateService.getCurrentDate()) => ({
  startDate: dateParser.objectToISODate(dateService.addDays(referenceDate, -365)),
  endDate: dateParser.objectToISODate(referenceDate),
});

const getAllPeriod = () => ({
  startDate: null,
  endDate: null,
});

export const DATE_PERIOD_TYPES = {
  TODAY: {
    key: 'today',
    getPeriod: getTodayPeriod,
  },
  THIS_WEEK: {
    key: 'this-week',
    getPeriod: getThisWeekPeriod,
  },
  THIS_MONTH: {
    key: 'this-month',
    getPeriod: getThisMonthPeriod,
  },
  THIS_YEAR: {
    key: 'this-year',
    getPeriod: getThisYearPeriod,
  },
  LAST_THIRTY_DAYS: {
    key: 'last-thirty-days',
    getPeriod: getLastThirtyDaysPeriod,
  },
  LAST_YEAR: {
    key: 'last-year',
    getPeriod: getLastYearPeriod,
  },
  CUSTOM_MONTH: {
    key: 'custom-month',
  },
  ALL_PERIOD: {
    key: 'all-period',
    getPeriod: getAllPeriod,
  },
  CUSTOM_PERIOD: {
    key: 'custom-period',
  },
};

export function getPeriodTypeByKey(key) {
  return Object.values(DATE_PERIOD_TYPES).find(periodType => periodType.key === key);
}
