import { DECIMAL_GREATNESS } from '@core/constants';

export function formatNumber(value, { precision = 4, minimumPrecision = 2 } = {}) {
  if (value || value === 0) {
    return Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: minimumPrecision,
      maximumFractionDigits: precision,
    }).format(value);
  }
  return undefined;
}

export function convertTothreeDigits(number) {
  const length = Math.floor(parseInt(number / 1.0e1).toString().length);
  const greatnessLength = length % 3;
  const convertedValue = number / Number('1.0e+'.concat(length - greatnessLength));
  return convertedValue
    .toString()
    .match(/^-?\d+(?:\.\d{0,1})?/)[0]
    .replace('.', ',');
}

export function getGreatnessByNumber(number) {
  return DECIMAL_GREATNESS[parseInt(number).toString().length];
}
