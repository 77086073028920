export const OVERRIDE_ICONS_FILE_NAME = {
  // with alias
  'question-circle': 'question-circle',
  'circle-question': 'question-circle',
  // with alias
  'info-circle': 'info-circle',
  'circle-info': 'info-circle',
  // with alias
  'times-circle': 'times-circle',
  'circle-xmark': 'times-circle',
  // with alias
  'exclamation-circle': 'exclamation-circle',
  'circle-exclamation': 'exclamation-circle',
  // with alias
  'usd-circle': 'usd-circle',
  'circle-dollar': 'usd-circle',
  // with alias
  'check-circle': 'check-circle',
  'circle-check': 'check-circle',
  // with alias
  'plus-circle': 'plus-circle',
  'circle-plus': 'plus-circle',
  // with alias
  'minus-circle': 'minus-circle',
  'circle-minus': 'minus-circle',
  // with alias
  'chevron-circle-up': 'chevron-circle-up',
  'circle-chevron-up': 'chevron-circle-up',
  // with alias
  'chevron-circle-right': 'chevron-circle-right',
  'circle-chevron-right': 'chevron-circle-right',
  // with alias
  'chevron-circle-left': 'chevron-circle-left',
  'circle-chevron-left': 'chevron-circle-left',
  // with alias
  'chevron-circle-down': 'chevron-circle-down',
  'circle-chevron-down': 'chevron-circle-down',
  // with alias
  'arrow-circle-down': 'arrow-circle-down',
  'circle-arrow-down': 'arrow-circle-down',
  // with alias
  'arrow-circle-up': 'arrow-circle-up',
  'circle-arrow-up': 'arrow-circle-up',
  // with alias
  'file-times': 'file-times',
  'file-xmark': 'file-times',
  // without alias
  'file-exclamation': 'file-exclamation',
  'file-invoice-dollar': 'file-invoice-dollar',
  'file-check': 'file-check',
  retweet: 'retweet',
  copy: 'copy',
};
