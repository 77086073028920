<template>
  <span class="ds-close-button" :class="classes">
    <ds-button
      theme="link"
      v-bind="$attrs"
      :tooltip="tooltip"
      :size="size"
      :icon="['light', 'times']"
      :disabled="disabled"
      class="ds-close-button__button"
      :class="classes"
      @click="onClick" />
  </span>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import { propValidationService } from '@core';
import DsButton from '@components/button';

export default {
  name: 'DsCloseButton',
  components: {
    DsButton,
  },
  inheritAttrs: false,
  props: {
    /**
     * DEPRECATED PROP | Use @click event instead
     */
    onClose: {
      type: Function,
    },
    variant: {
      type: String,
      default: 'dark',
      validator(variant) {
        return ['light', 'dark'].includes(variant);
      },
    },
    tooltip: {
      type: String,
      default: 'Fechar',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
      ...propValidationService.buildValidator({
        validValues: ['sm', 'md', 'lg'],
        errorMessage: 'You should inform one of valid sizes',
      }),
    },
  },
  computed: {
    classes() {
      return [`ds-close-button--${this.size}`, `ds-close-button--${this.variant}`];
    },
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.onClose) {
      deprecatedDependency.deprecateProperty('onClose', 'Use @click event instead');
    }
  },
  methods: {
    onClick(event) {
      if (this.onClose) {
        this.onClose();
      }

      this.$emit('click', event);
    },
  },
};
</script>

<style scoped>
@import './CloseButton.css';
</style>
