<template>
  <ds-input
    ref="input"
    v-model="localValue"
    :suggestion="maskedSuggestion"
    class="ds-phone-input"
    :custom-validations="localCustomValidations"
    :readonly="readonly"
    :required="required"
    :disabled="disabled"
    type="text"
    :mask-pattern="maskPattern"
    @change="onInputChange"
    @blur="onInputBlur"
    @suggestion-applied="onSuggestionApplied" />
</template>

<script>
import DsInput from '@components/input';
import { constants, focusMixin } from '@core';

import {
  isValid,
  isExtraDigitRule,
  removeAllSpaces,
  removePunctuation,
  addPunctuation,
  getMaskPattern,
} from '@ca-design-system-libs/phone';

export default {
  name: 'DsPhoneInput',
  components: {
    DsInput,
  },
  mixins: [focusMixin.focus('input')],
  props: {
    value: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    onChange: {
      type: Function,
    },
    onBlur: {
      type: Function,
    },
    suggestion: {
      type: String,
    },
    readonly: {
      type: Boolean,
    },
    customValidations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      listeners: 0,
      shouldShowExtraDigit: isExtraDigitRule(this.value),
      localValue: removeAllSpaces(this.value),
      phoneValidation: {
        message: constants.INPUT_ERRORS.INVALID_PHONE.MESSAGE,
        valid: isValid,
      },
    };
  },
  computed: {
    trimmedValue() {
      return removeAllSpaces(this.value);
    },
    maskedSuggestion() {
      return (
        this.suggestion &&
        addPunctuation(this.suggestion, {
          withExtraDigit: isExtraDigitRule(this.suggestion),
        })
      );
    },
    maskPattern() {
      return getMaskPattern(this.localValue);
    },
    localCustomValidations() {
      return [this.phoneValidation, ...this.customValidations];
    },
  },
  watch: {
    trimmedValue(trimmedValue) {
      const valueWithoutMask = removePunctuation(this.localValue);
      if (trimmedValue !== valueWithoutMask) {
        this.applyPhoneMask(trimmedValue);
      }
    },
    localValue(localValue) {
      const unmasked = removePunctuation(localValue);
      this.$emit('input', unmasked);
    },
  },
  mounted() {
    // more information in https://github.com/ContaAzul/ca-design-system/issues/98
    this.addInputNativeListener();
  },
  beforeDestroy() {
    this.removeInputNativeListener();
  },
  methods: {
    addInputNativeListener() {
      this.getInputElement().addEventListener('input-native', this.onInputNative);
    },
    removeInputNativeListener() {
      this.getInputElement().removeEventListener('input-native', this.onInputNative);
    },
    getInputElement() {
      return this.$el.querySelector('input');
    },
    onInputChange() {
      if (this.onChange) {
        this.onChange();
      }
    },
    onInputBlur() {
      if (this.onBlur) {
        this.onBlur();
      }
    },
    onInputNative({ target }) {
      this.applyPhoneMask(target.value);
    },
    onSuggestionApplied() {
      this.shouldShowExtraDigit = isExtraDigitRule(this.suggestion);
    },
    applyPhoneMask(valueMaskedOrRaw) {
      const rawValue = removePunctuation(valueMaskedOrRaw);

      if (isExtraDigitRule(rawValue)) {
        this.shouldShowExtraDigit = true;
        this.$nextTick(() => {
          this.localValue = addPunctuation(rawValue);
        });
      } else {
        this.shouldShowExtraDigit = false;
        this.localValue = addPunctuation(rawValue);
      }
    },
  },
};
</script>
