<template>
  <ds-page v-show="imageSrc">
    <div :style="containerStyle" class="ds-image-preview ds-u-margin-bottom--sm">
      <img ref="image" :style="style" :src="imageSrc" alt="image preview" />
    </div>
    <ds-row v-if="shouldShowActions">
      <ds-col class="ds-image-controllers">
        <ds-button-group>
          <ds-button tooltip="Diminuir zoom" :icon="['regular', 'magnifying-glass-minus']" @click="onZoomOut" />
          <ds-button tooltip="Redefinir zoom" :disabled="shouldDisabledZoomReset" @click="onZoomReset">
            <ds-icon v-bind="getResetIconAttrs" />
          </ds-button>
          <ds-button tooltip="Aumentar zoom" :icon="['regular', 'magnifying-glass-plus']" @click="onZoomIn" />
        </ds-button-group>
      </ds-col>
    </ds-row>
  </ds-page>
</template>

<script>
import { isTypeAMatchOfAnyImageType } from '@core/services/fileValidation/fileValidationService';
import DsPage from '@components/page';
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsButtonGroup from '@components/button-group';
import DsButton from '@components/button';
import DsIcon from '@components/icon';
import Panzoom from '@panzoom/panzoom';

export default {
  name: 'DsTcImagePreview',
  components: {
    DsPage,
    DsRow,
    DsCol,
    DsButtonGroup,
    DsButton,
    DsIcon,
  },
  props: {
    image: {
      type: [String, File],
    },
    maxScale: {
      type: Number,
      default: 5,
    },
    minScale: {
      type: Number,
      default: 1,
    },
    startScale: {
      type: Number,
      default: null,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    zoomWithWheel: {
      type: Boolean,
      default: true,
    },
    disablePan: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: String,
      default: 'none',
    },
    maxHeight: {
      type: String,
      default: 'none',
    },
    height: {
      type: String,
      default: '100%',
    },
  },
  data() {
    return {
      viewer: null,
      imageSrc: this.image,
      shouldDisabledZoomReset: true,
    };
  },
  computed: {
    getResetIconAttrs() {
      return this.shouldDisabledZoomReset
        ? [{ icon: ['regular', 'arrow-up-right-and-arrow-down-left-from-center'] }, { color: 'text' }]
        : [{ icon: ['regular', 'arrow-down-left-and-arrow-up-right-to-center'] }, { color: 'blue-dark' }];
    },
    shouldShowActions() {
      return this.showActions && this.viewer;
    },
    style() {
      return {
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight,
      };
    },
    containerStyle() {
      return {
        height: this.height,
      };
    },
  },
  watch: {
    image() {
      this.handleImageSrc();
      this.onZoomReset();
    },
  },
  mounted() {
    this.init();
  },
  created() {
    this.handleImageSrc();
  },
  beforeDestroy() {
    this.clearImageSrc();
  },
  methods: {
    init() {
      const img = this.$refs.image;
      img.onload = () => {
        const optimalScale = this.getOptimalScale(img);
        const minScale = Math.min(optimalScale, this.minScale);
        this.viewer = Panzoom(this.$refs.image, {
          maxScale: this.maxScale,
          minScale,
          step: 0.2,
          zoomWithWheel: this.zoomWithWheel,
          cursor: 'pointer',
          bounds: true,
          startScale: this.startScale,
          disablePan: this.disablePan,
        });

        if (this.zoomWithWheel) {
          this.$refs.image.parentElement.addEventListener('wheel', this.viewer.zoomWithWheel);
        }

        this.$refs.image.addEventListener('panzoomchange', this.onZoomChange);
      };
    },
    getOptimalScale(img) {
      const containerWidth = img.parentElement.offsetWidth;
      const containerHeight = img.parentElement.offsetHeight;

      const imgWidth = img.naturalWidth;
      const imgHeight = img.naturalHeight;

      const widthScale = containerWidth / imgWidth || 1;
      const heightScale = containerHeight / imgHeight || 1;

      return this.startScale || Math.min(widthScale, heightScale);
    },
    onZoomIn() {
      const currentZoom = this.viewer.getScale();
      const newZoom = currentZoom + 0.5;
      this.viewer.zoom(newZoom, { animate: true });
    },
    onZoomOut() {
      const currentZoom = this.viewer.getScale();
      const newZoom = currentZoom - 0.5;
      this.viewer.zoom(newZoom, { animate: true });
    },
    onZoomReset() {
      if (this.viewer) {
        this.viewer.reset();
      }
    },
    onZoomChange() {
      const { x, y } = this.viewer?.getPan() || {};
      this.shouldDisabledZoomReset = x === 0 && y === 0 && this.viewer?.getScale() === this.startScale;
    },
    handleImageSrc() {
      this.clearImageSrc();
      if (this.image instanceof File) {
        this.handleImageFileType();
      } else {
        this.imageSrc = this.image;
      }
    },
    handleImageFileType() {
      if (this.image?.type && isTypeAMatchOfAnyImageType(this.image)) {
        this.imageSrc = URL.createObjectURL(this.image);
      } else {
        this.$emit('unsupported-file-type', this.image);
      }
    },
    clearImageSrc() {
      if (this.imageSrc) {
        this.imageSrc = URL.revokeObjectURL(this.imageSrc);
      }
    },
  },
};
</script>

<style scoped>
@import './TcImagePreview.css';
</style>
