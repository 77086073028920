import cloneDeep from 'lodash/cloneDeep';

const ERROR_MESSAGES = [
  {
    title: 'Formato não permitido',
    description: 'Por favor adicione um arquivo $1',
    validate: message => message.includes('Você pode enviar apenas arquivos: '),
    regex: /(\..+)$/g,
  },
  {
    title: 'Arquivo muito grande',
    description: 'O limite deste arquivo excede o limite de $1. Por favor, adicione outro arquivo.',
    validate: message => message.includes('Esse arquivo excedeu o tamanho máximo permitido'),
    regex: /(\d.+)$/g,
  },
  {
    title: 'Arquivo duplicado',
    description: 'Já existe um arquivo com este nome. Por favor, adicione outro arquivo.',
    validate: message => message.includes('Cannot add the duplicate file'),
  },
];

export function getErrorMessage(error) {
  const [errorMessage] = cloneDeep(ERROR_MESSAGES.filter(message => message.validate(error.message)));

  if (errorMessage.regex) {
    errorMessage.description = error.message.replace(errorMessage.regex, errorMessage.description);
  }

  return errorMessage;
}
