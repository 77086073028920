<template>
  <div class="ds-sidebar-menu-second-level">
    <ul class="ds-sidebar-menu-second-level__first-level-icons-list">
      <li
        v-for="item in mappedItems"
        :key="item.id"
        v-ds-tooltip.right="item.label"
        class="ds-sidebar-menu-second-level__first-level-icons-item"
        :class="[classes, { [activeItemClass]: item.isActive }]"
        @animationend="onAnimationEnd"
        @click="selectItem(item)">
        <a
          v-if="item.href"
          class="ds-sidebar-menu-second-level__first-level-icons-link"
          :href="sanitizedHref(item.href)">
          <ds-icon :icon="item.icon" />
        </a>
        <ds-icon v-else :icon="item.icon" />
      </li>
    </ul>
    <ul class="ds-sidebar-menu-second-level__items-list">
      <slot />
    </ul>
  </div>
</template>

<script>
import { isComponent } from '@core/services/vnode/vnodeService';
import DsTooltip from '@directives/tooltip';
import DsIcon from '@components/icon';
import sanitizeUrlLib from '@contaazul/sanitize-url';

export default {
  name: 'DsSidebarMenuSecondState',
  directives: {
    DsTooltip,
  },
  components: {
    DsIcon,
  },
  provide() {
    return {
      sidebarMenuSecondLevelVm: true,
    };
  },
  inject: ['sidebarMenuVm', 'sidebarVm'],
  props: {
    activeItem: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      isAnimationFinished: false,
    };
  },
  computed: {
    classes() {
      return {
        [`ds-sidebar-menu-second-level-${this.getSidebarTheme()}-theme`]: true,
      };
    },
    activeItemClass() {
      return this.isAnimationFinished
        ? 'ds-sidebar-menu-second-level__first-level-icons-item--active'
        : 'ds-sidebar-menu-second-level__active-item--animation';
    },
    mappedItems() {
      return this.items.map(item => ({
        ...item,
        isActive: this.sidebarMenuVm.shouldItemBeActive(item),
      }));
    },
  },
  mounted() {
    this.$nextTick(this.registerFirstLevelItems);
  },
  deactivated() {
    this.isAnimationFinished = false;
  },
  methods: {
    sanitizedHref(href) {
      return sanitizeUrlLib.sanitizeUrl(href);
    },
    onAnimationEnd() {
      this.isAnimationFinished = true;
    },
    selectItem(item) {
      this.$emit('active-item-updated', item);
    },
    registerFirstLevelItems() {
      const firstLevelItems = this.$slots.default.filter(
        vnode => isComponent(vnode, 'DsSidebarMenuItem') || isComponent(vnode, 'DsSidebarMenuFavorite'),
      );
      firstLevelItems.forEach(item => {
        let localItem = item;
        if (isComponent(localItem, 'DsSidebarMenuFavorite')) {
          localItem = { componentInstance: { ...localItem.componentInstance.$children[0] } };
        }

        const currentItem = localItem.componentInstance.getCurrentItem();
        const isActive = this.sidebarMenuVm.shouldItemBeActive({ id: item.key });

        this.items.push({
          ...currentItem,
          isActive,
        });
      });
    },
    getSidebarTheme() {
      return this.sidebarVm.getTheme();
    },
  },
};
</script>
<style scoped>
@import './SidebarMenuSecondLevel.css';
</style>
