<template>
  <component :is="wrapperElement" class="ds-currency-input" add-on-text-left="R$">
    <ds-beta-numeral-input
      ref="numeralInput"
      :value="value"
      :placeholder="placeholder"
      :absolute-only="absoluteOnly"
      :required="required"
      :prefix="prefixText"
      :min-precision="minPrecision"
      :precision="precision"
      :integer="integer"
      :custom-validations="customValidations"
      v-bind="$attrs"
      @custom-changed="onCustomChanged"
      @custom-input="onCustomInput" />
  </component>
</template>

<script>
import DsInputGroup from '@components/input-group';
import DsBetaNumeralInput from '../beta-numeral-input/BetaNumeralInput.vue';

export default {
  name: 'DsBetaCurrencyInput',
  components: {
    DsInputGroup,
    DsBetaNumeralInput,
  },
  inheritAttrs: false,
  props: {
    value: DsBetaNumeralInput.props.value,
    placeholder: String,
    externalSymbol: {
      type: Boolean,
      default: true,
    },
    required: Boolean,
    absoluteOnly: Boolean,
    internalSymbol: Boolean,
    precision: {
      type: Number,
      default: 2,
    },
    minPrecision: {
      type: Number,
      default: 2,
    },
    integer: Number,
    /**
     * [{ valid: Function, message: String }]
     */
    customValidations: DsBetaNumeralInput.props.customValidations,
  },
  computed: {
    prefixText() {
      return this.internalSymbol ? 'R$' : undefined;
    },
    wrapperElement() {
      return this.externalSymbol && !this.internalSymbol ? 'ds-input-group' : 'div';
    },
  },
  watch: {
    value() {
      this.update(this.value);
    },
  },
  methods: {
    onCustomInput(value) {
      this.$emit('input', value);
    },
    onCustomChanged(value) {
      this.$emit('change', value);
    },
    update(value) {
      this.$refs.numeralInput.update(value);
    },
    validate() {
      this.$refs.numeralInput.validate();
    },
  },
};
</script>
