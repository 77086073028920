<template>
  <div class="ds-steps__step">
    <span v-if="index > 0" class="ds-steps__empty-bar">
      <transition name="go" @after-enter="after(index)" @before-leave="after(index - 1)">
        <span v-if="isFull" class="ds-steps__progress-bar" />
      </transition>
    </span>
    <span class="ds-steps__circle">
      <transition name="circle" mode="out-in">
        <div
          :key="isFull ? 'full' : 'empty'"
          class="ds-steps__inner-circle"
          :class="{
            full: isFull,
            last: isLast,
          }">
          <ds-icon v-if="isLast && isAllDone" size="sm" icon="check" color="white" />
          <span v-else>
            {{ index + 1 }}
          </span>
        </div>
      </transition>
    </span>
  </div>
</template>

<script>
import DsIcon from '@components/icon';

export default {
  components: {
    DsIcon,
  },
  props: {
    index: {
      type: Number,
    },
    nSteps: {
      type: Number,
    },
    currentStep: {
      type: Number,
    },
  },
  computed: {
    isFull() {
      return this.currentStep >= this.index;
    },
    isLast() {
      return this.index === this.nSteps - 1;
    },
    isAllDone() {
      return this.currentStep === this.nSteps - 1;
    },
  },
  methods: {
    after(index) {
      this.$emit('end', index === this.nSteps - 1);
    },
  },
};
</script>

<style scoped>
@import './Step.css';
</style>
