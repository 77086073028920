<template>
  <ds-list-item class="ds-topbar-profile-aside-item">
    <ds-icon v-if="icon" color="blue" :icon="['regular', icon]" class="ds-u-padding-right--sm" size="md" />
    <ds-link :href="sanitizedHref" :class="classes" @click="onClick">
      {{ title }}
    </ds-link>
  </ds-list-item>
</template>

<script>
import DsListItem from '@components/list-item';
import DsLink from '@components/link';
import DsIcon from '@components/icon';
import sanitizeUrlLib from '@contaazul/sanitize-url';

export default {
  name: 'DsTopbarProfileAsideItem',
  components: {
    DsLink,
    DsListItem,
    DsIcon,
  },
  inject: ['topbarVm'],
  props: {
    title: {
      type: String,
      required: true,
    },
    href: {
      type: String,
    },
    icon: {
      type: [Array, String],
    },
  },
  computed: {
    classes() {
      return [
        {
          'ds-u-padding-left--lg': !this.icon,
        },
      ];
    },
    sanitizedHref() {
      return this.href ? sanitizeUrlLib.sanitizeUrl(this.href) : '';
    },
  },
  methods: {
    onClick() {
      this.topbarVm.clearActiveItem();
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
@import './TopbarProfileAsideItem.css';
</style>
