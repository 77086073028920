<template>
  <div class="ds-beta-banner">
    <div :style="arrowOffset" class="ds-beta-banner-arrow">
      <div class="ds-beta-banner-arrow__foreground"></div>
    </div>
    <ds-helper-slimbox
      :title="title"
      :img-url="imgUrl"
      :illustration="illustration"
      :alt="alt"
      :img-width="imgWidth"
      :img-height="imgHeight"
      :vertical-align="verticalAlign"
      ><slot></slot
    ></ds-helper-slimbox>
  </div>
</template>

<script>
import DsHelperSlimbox from '@components/helper-slimbox';

export default {
  name: 'DsBetaBanner',
  components: { DsHelperSlimbox },
  props: {
    title: DsHelperSlimbox.props.title,
    imgUrl: DsHelperSlimbox.props.imgUrl,
    illustration: DsHelperSlimbox.props.illustration,
    alt: DsHelperSlimbox.props.alt,
    imgWidth: DsHelperSlimbox.props.imgWidth,
    imgHeight: DsHelperSlimbox.props.imgHeight,
    verticalAlign: DsHelperSlimbox.props.verticalAlign,
    arrowLeftOffset: {
      default: 16,
      type: Number,
    },
  },
  computed: {
    arrowOffset() {
      return `left: ${this.arrowLeftOffset}px`;
    },
  },
};
</script>

<style scoped>
@import './BetaBanner.css';
</style>
