<template>
  <div class="ds-virtual-list-core-container" :style="containerStyle">
    <div class="ds-virtual-list-core-inner-container" :style="innerContainerStyle">
      <ds-virtual-list-item
        v-for="virtualRow in virtualRows"
        :key="virtualRow.key"
        :data-index="virtualRow.index"
        @component-mounted="rowVirtualizer.measureElement">
        <slot :index="virtualRow.index" />
      </ds-virtual-list-item>
    </div>
  </div>
</template>
<script>
import { ref, shallowRef, triggerRef, unref } from '@vue/reactivity';
import { elementScroll, observeElementOffset, observeElementRect, Virtualizer } from '@tanstack/virtual-core';
import { scrollService } from '@core';
import DsVirtualListItem from './VirtualListItem.vue';

const parentRef = ref(null);

export default {
  name: 'DsVirtualListCore',
  components: {
    DsVirtualListItem,
  },
  props: {
    itemCount: {
      type: Number,
      required: true,
    },
    itemHeight: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      rowVirtualizer: null,
    };
  },
  computed: {
    containerStyle() {
      return {
        height: `${this.rowVirtualizer?.getTotalSize() ?? window.innerHeight}px`,
      };
    },
    innerContainerStyle() {
      return {
        transform: `translateY(${this.virtualRows?.[0]?.start ?? 0}px)`,
      };
    },
    options() {
      return {
        count: this.itemCount,
        estimateSize: () => this.itemHeight,
        getScrollElement: () => {
          parentRef.value = scrollService.getScrollableParentElement(this.$el);
          return parentRef.value;
        },
      };
    },
    scrollElement() {
      return this.unwrappedOptions?.getScrollElement();
    },
    unwrappedOptions() {
      return unref(this.options);
    },
    virtualRows() {
      return this.rowVirtualizer?.getVirtualItems();
    },
    elementScrollObserverOptions() {
      return {
        observeElementRect,
        observeElementOffset,
        scrollToFn: elementScroll,
        ...this.unwrappedOptions,
      };
    },
    unwrappedElementScrollObserverOptions() {
      return unref(this.elementScrollObserverOptions);
    },
  },
  mounted() {
    const virtualizer = new Virtualizer(this.unwrappedElementScrollObserverOptions);

    const state = shallowRef(virtualizer);

    this.$watch('scrollElement', virtualizer._willUpdate, {
      immediate: true,
    });

    this.$watch('unwrappedElementScrollObserverOptions', virtualizerOptions => {
      virtualizer.setOptions(virtualizerOptions);

      virtualizer._willUpdate();

      triggerRef(state);
    });

    this.rowVirtualizer = state.value;
  },
};
</script>
<style scoped>
@import './VirtualListCore.css';
</style>
