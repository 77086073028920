<template>
  <img v-if="isVisible" :src="illustrationUrl" class="ds-illustration" :style="styles" :alt="alt" @error="onError" />
</template>

<script>
import { stringService } from '@core';

export default {
  name: 'DsIllustration',
  props: {
    name: {
      type: String,
      required: true,
    },
    width: String,
    height: String,
  },
  data() {
    return {
      hasError: false,
      illustrationUrl: null,
    };
  },
  computed: {
    isVisible() {
      return !this.hasError;
    },
    styles() {
      return {
        width: this.width && this.formatSize(this.width),
        height: this.height && this.formatSize(this.height),
      };
    },
    alt() {
      return stringService.toCapitalize(this.name.replace('-', ' '));
    },
  },
  watch: {
    name: {
      immediate: true,
      handler(name) {
        this.hasError = false;
        this.illustrationUrl = this.buildName(name);
      },
    },
  },
  methods: {
    buildName(name) {
      return `${process.env.VITE_APP_CDN_ILLUSTRATIONS_URL}/${stringService.toPascalCase(name)}.svg`;
    },
    formatSize(value) {
      return value.includes('px') ? value : `${value}px`;
    },
    onError() {
      this.hasError = true;
    },
  },
};
</script>
