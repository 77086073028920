<template>
  <h6 class="ds-dropdown-header">
    <slot></slot>
  </h6>
</template>

<script>
export default {
  name: 'DsDropdownHeader',
};
</script>

<style>
@import './DropdownHeader.css';
</style>
