<template>
  <div class="ds-date-filter">
    <ds-date-filter-button type="prev" :disabled="disabled" @click="onClickPrevious" />

    <div class="ds-date-filter__main">
      <slot />
    </div>

    <ds-date-filter-button type="next" :disabled="disabled" @click="onClickNext" />
  </div>
</template>

<script>
import DsDateFilterButton from './DateFilterButton.vue';

export default {
  name: 'DsDateFilter',
  components: {
    DsDateFilterButton,
  },
  props: {
    disabled: Boolean,
  },
  methods: {
    onClickPrevious() {
      this.$emit('previous');
    },
    onClickNext() {
      this.$emit('next');
    },
  },
};
</script>

<style scoped>
@import './DateFilter.css';
</style>
