<template>
  <div class="ds-data-grid-confirm-content">
    <slot>
      <ds-row>
        <ds-col>
          <p data-data-grid-confirm-content-question>
            {{ question }}<br />
            Ao confirmar, não será possível desfazer esta ação.
          </p>
        </ds-col>
      </ds-row>
      <ds-row>
        <ds-col v-for="attr in itemAttributesWithValue" :key="attr.label" size="6">
          <ds-field :label="attr.label" data-data-grid-confirm-content-field>
            <span>{{ attr.value }}</span>
          </ds-field>
        </ds-col>
      </ds-row>
    </slot>
  </div>
</template>

<script>
import DsCol from '@components/col';
import DsField from '@components/field';
import DsRow from '@components/row';

export default {
  name: 'DsDataGridConfirmContent',
  components: {
    DsRow,
    DsCol,
    DsField,
  },
  props: {
    question: {
      type: String,
      default: 'Deseja realmente excluir o item a seguir?',
    },
    itemAttributes: {
      type: Array,
    },
  },
  computed: {
    itemAttributesWithValue() {
      return this.itemAttributes?.filter(attr => attr.value?.trim());
    },
  },
};
</script>
