<template>
  <div :class="classProgresCircular" :style="styleProgressCircular">
    <div class="ds-progress-circular__circle">
      <progress-circular-circle type="shadow" />
      <progress-circular-circle v-if="showProgressCircle" type="active" :value="value" :max-value="maxValue" />
    </div>
    <div v-ds-tooltip.bottom="completeValueTooltip" class="ds-progress-circular__text">
      <div v-if="type === 'money'" class="ds-progress-circular__type ds-progress-circular__type--money">R$</div>
      <div class="ds-progress-circular__number">
        {{ valueWithThreeDigits }}<span class="ds-progress-circular__number--greatness">{{ greatnessOfValue }}</span>
      </div>
      <div v-if="type === 'percentage'" class="ds-progress-circular__type ds-progress-circular__type--percentage">
        %
      </div>
    </div>
  </div>
</template>

<script>
import { numberFormatService } from '@core';
import DsTooltip from '@directives/tooltip';
import ProgressCircularCircle from './ProgressCircularCircle.vue';

export default {
  name: 'DsProgressCircular',
  components: {
    ProgressCircularCircle,
  },
  directives: {
    DsTooltip,
  },
  props: {
    value: {
      type: Number,
    },
    maxValue: {
      type: Number,
      default: 100,
    },
    /**
     * ['blue', 'green', 'red', 'yellow', 'ca-mais', 'ca-pro']
     */
    color: {
      type: String,
      default: 'blue',
      validator(color) {
        return ['blue', 'green', 'red', 'yellow', 'ca-mais', 'ca-pro'].includes(color);
      },
    },
    /**
     * ['percentage', 'money']
     */
    type: {
      type: String,
      validator(type) {
        return [undefined, 'percentage', 'money'].includes(type);
      },
    },
    /**
     * ['md', 'lg']
     */
    size: {
      type: String,
      default: 'md',
      validator(size) {
        return ['md', 'lg'].includes(size);
      },
    },
  },
  data() {
    return {
      valueNumber: 0,
      finalValueContainsComma: this.value,
      incrementalAnimateValueInterval: undefined,
    };
  },
  computed: {
    styleProgressCircular() {
      let colorRule = this.color;
      let darkColorRule = `${this.color}-dark`;

      if (this.color === 'ca-pro') {
        colorRule = 'blue-semi-dark-smb';
        darkColorRule = 'blue-semi-dark-smb';
      } else if (this.color === 'ca-mais') {
        colorRule = 'blue-acc';
        darkColorRule = 'blue-acc';
      }

      return {
        '--ds-progress-circular-color': `var(--ds-color--${colorRule})`,
        '--ds-progress-circular-color-dark': `var(--ds-color--${darkColorRule})`,
      };
    },
    classProgresCircular() {
      return ['ds-progress-circular', 'ds-progress-circular--'.concat(this.size)];
    },
    greatnessOfValue() {
      return numberFormatService.getGreatnessByNumber(this.valueNumber);
    },
    completeValueTooltip() {
      return this.value > 999
        ? numberFormatService.formatNumber(this.value, { precision: 2, minimumPrecision: 0 })
        : undefined;
    },
    valueWithThreeDigits() {
      const convertedValue = numberFormatService.convertTothreeDigits(this.valueNumber);
      return this.finalValueContainsComma ? convertedValue : parseInt(convertedValue);
    },
    showProgressCircle() {
      return !!this.value;
    },
  },
  watch: {
    value() {
      this.loadValues();
    },
  },
  mounted() {
    this.loadValues();
  },
  methods: {
    loadValues() {
      this.updateFinalValueConverted();
      this.incrementalAnimateValue();
    },
    updateFinalValueConverted() {
      const finalValueConverted = numberFormatService.convertTothreeDigits(this.value);
      this.finalValueContainsComma = finalValueConverted.includes(',') && !finalValueConverted.includes(',0');
    },
    incrementalAnimateValue() {
      clearInterval(this.incrementalAnimateValueInterval);
      const valueParted = Math.abs(this.value - this.valueNumber) / 20;
      this.incrementalAnimateValueInterval = setInterval(() => {
        const newValue =
          this.valueNumber < this.value ? this.valueNumber + valueParted : this.valueNumber - valueParted;
        if (
          (this.valueNumber < this.value && newValue >= this.value) ||
          (this.valueNumber > this.value && newValue <= this.value)
        ) {
          this.valueNumber = this.value;
          clearInterval(this.incrementalAnimateValueInterval);
        } else {
          this.valueNumber = newValue;
        }
      }, 50);
    },
  },
};
</script>

<style scoped>
@import './ProgressCircular.css';
</style>
