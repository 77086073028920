<template>
  <a target="_blank" class="ds-external-link" @click="$emit('click', $event)">
    <slot></slot>
    <ds-icon v-if="withIcon" icon="external-link-alt" />
  </a>
</template>

<script>
import DsIcon from '@components/icon';

export default {
  name: 'DsExternalLink',
  components: {
    DsIcon,
  },
  props: {
    withIcon: Boolean,
  },
};
</script>

<style scoped>
@import './ExternalLink.css';
</style>
