<template>
  <div class="ds-password-input">
    <div class="ds-u-position--relative">
      <ds-input
        ref="input"
        :value="value"
        :required="required"
        :custom-validations="localCustomValidations"
        :type="inputType"
        v-bind="$attrs"
        @input="onInput"
        @change="onChange"
        @blur="onBlur"
        @focus="setPasswordRulesVisibility(true)" />
      <ds-input-icons>
        <ds-icon v-ds-tooltip="tooltip" :icon="inputIcon" color="text" @click="togglePasswordVisibility" />
      </ds-input-icons>
    </div>
    <ds-password-input-validation v-if="shouldShowPasswordInputValidation" :value="value" />
  </div>
</template>

<script>
import { focusMixin } from '@core';
import DsIcon from '@components/icon';
import DsInput from '@components/input';
import DsTooltip from '@directives/tooltip';
import DsInputIcons from '@components/input-icons/InputIcons.vue';
import DsPasswordInputValidation from './PasswordInputValidation.vue';
import { ICONS, INPUT_TYPES, TOOLTIP_TEXTS, INPUT_RULES_DEFAULT_VALIDATION_MESSAGE } from './passwordInputConstants';
import { isPasswordValid } from './passwordInputService';

export default {
  name: 'DsPasswordInput',
  components: {
    DsIcon,
    DsInput,
    DsInputIcons,
    DsPasswordInputValidation,
  },
  directives: {
    DsTooltip,
  },
  mixins: [focusMixin.focus('input')],
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    required: Boolean,
    customValidations: {
      type: Array,
      default: () => [],
    },
    validate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      shouldShowPassword: false,
      shouldShowRules: false,
      inputRulesDefaultValidations: [
        {
          message: INPUT_RULES_DEFAULT_VALIDATION_MESSAGE,
          valid: value => isPasswordValid(value),
        },
      ],
    };
  },
  computed: {
    inputIcon() {
      return this.shouldShowPassword ? ICONS.EYE : ICONS.EYE_SLASH;
    },
    inputType() {
      return this.shouldShowPassword ? INPUT_TYPES.TEXT : INPUT_TYPES.PASSWORD;
    },
    tooltip() {
      return this.shouldShowPassword ? TOOLTIP_TEXTS.HIDE_PASSWORD : TOOLTIP_TEXTS.SHOW_PASSWORD;
    },
    localCustomValidations() {
      return this.validate ? this.inputRulesDefaultValidations : this.customValidations;
    },
    shouldShowPasswordInputValidation() {
      return this.validate && this.shouldShowRules;
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.shouldShowPassword = !this.shouldShowPassword;
    },
    setPasswordRulesVisibility(value) {
      this.shouldShowRules = value;
    },
    onInput(event) {
      this.$emit('input', event);
    },
    onChange(event) {
      this.$emit('change', event);
    },
    onBlur(event) {
      this.$emit('blur', event);

      const isVisible = Boolean(this.value);
      this.setPasswordRulesVisibility(isVisible);
    },
  },
};
</script>

<style scoped>
@import './PasswordInput.css';
</style>
