<template>
  <button class="ds-detail-total-bar-toggle-button" @click="onClick">
    <ds-icon :icon="icon" color="blue" />
  </button>
</template>

<script>
import DsIcon from '@components/icon';

export default {
  name: 'DsDetailTotalBarToggleButton',
  components: {
    DsIcon,
  },
  props: {
    arrowUp: Boolean,
  },
  computed: {
    icon() {
      const sufix = this.arrowUp ? 'up' : 'down';
      return ['regular', `chevron-circle-${sufix}`];
    },
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style scoped>
@import './DetailTotalBarToggleButton.css';
</style>
