import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import Vue from 'vue';
import { getTypeService } from './dataGridAdvancedFilterTypeService';
import { STATUS } from './dataGridAdvancedFilterConstants';

export function createStore() {
  let onChangeCallback = () => {};
  const store = Vue.observable({
    filters: {},
  });

  function createFilter(filterOption) {
    if (filterOption.type) {
      const filter = store.filters[filterOption.key];

      Vue.set(store.filters, filterOption.key, {
        ...cloneDeep(filterOption),
        status: filter?.status || STATUS.INACTIVE,
        value: filter?.value,
      });
    }
  }

  function getFilter(key) {
    return store.filters[key];
  }

  function getVisibleFilters(filters) {
    return Object.values(filters).reduce(
      (acc, cur) => (cur.status === STATUS.INACTIVE ? acc : { ...acc, [cur.key]: cur }),
      {},
    );
  }

  function getNotFiltered(filters) {
    return Object.values(filters).reduce(
      (acc, cur) => (cur.status !== STATUS.INACTIVE ? acc : { ...acc, [cur.key]: cur }),
      {},
    );
  }

  function getFilterValues() {
    return Object.keys(getVisibleFilters(store.filters)).reduce((acc, key) => {
      const { value } = getFilter(key);
      return value ? { ...acc, [key]: value } : acc;
    }, {});
  }

  function setStatus(key, status) {
    Vue.set(getFilter(key), 'status', status);
  }

  function setValue(key, value) {
    Vue.set(getFilter(key), 'value', value);
  }

  function getAllFilters() {
    return store.filters;
  }

  function deleteFilters(filterKeyList) {
    filterKeyList.forEach(filterKey => {
      deleteFilter(filterKey);
    });
  }

  function deleteFilter(filterKey) {
    delete store.filters[filterKey];
  }

  return {
    get allFilters() {
      return getAllFilters();
    },
    get visibleFilters() {
      return getVisibleFilters(store.filters);
    },
    get notFiltered() {
      return getNotFiltered(store.filters);
    },
    onChange(onChange) {
      onChangeCallback = triggerRefresh => onChange(getFilterValues(), triggerRefresh);
    },
    registerFilters(options) {
      options.forEach(createFilter);
    },
    updateFilters(options) {
      const currentFiltersList = Object.keys(store.filters);
      const newFiltersList = [...options.map(option => option.key)];
      const differenceList = difference(currentFiltersList, newFiltersList);

      if (differenceList.length) {
        deleteFilters(differenceList);
      }

      options.forEach(createFilter);
      onChangeCallback(true);
    },
    setFilterReady(key) {
      setStatus(key, getFilter(key)?.value ? STATUS.UPDATING : STATUS.PENDING);
    },
    setValue(key, value) {
      const triggerRefresh = !value || getTypeService(getFilter(key).type).isFullValue(value);
      setValue(key, value);
      setStatus(key, value ? STATUS.ACTIVE : STATUS.INACTIVE);
      onChangeCallback(triggerRefresh);
    },
    removeFilter(key) {
      this.setValue(key, null);
    },
    removeAllFilters() {
      Object.keys(this.visibleFilters).forEach(key => {
        setValue(key, null);
        setStatus(key, STATUS.INACTIVE);
      });

      onChangeCallback(true);
    },
  };
}
