import get from 'lodash/get';
import { isComponent, getChildComponentInstance } from '@core/services/vnode/vnodeService';
import { removeAccents } from '@core/services/accent/accentService';

const HIDDEN_CSS_CLASS = 'ds-u-display--none';

function getOrderedOptions(options, slotOptions = []) {
  return slotOptions.map(vnode => options[vnode.key]);
}

function isOptionVisible(option) {
  return isValidOption(option) && !option.vm.$el.className.includes(HIDDEN_CSS_CLASS);
}

function isValidOption(option) {
  return option.vm && option.vm.$el;
}

export function getHiddenCSSClass() {
  return HIDDEN_CSS_CLASS;
}

export function isOptionEqual(a, b, compareValueBy) {
  if (!compareValueBy) {
    return a === b;
  }

  return get(a, compareValueBy) === get(b, compareValueBy);
}

export function getSlotOptions(vnodes = []) {
  return vnodes.filter(vnode => isComponent(vnode, 'DsOption'));
}

export function getVisibleOptions(slotOptions, options) {
  return getOrderedOptions(options, slotOptions).filter(isOptionVisible);
}

export function getOptionVmByValue(options, value, compareValueBy) {
  const findOptionByValue = option => isOptionEqual(option.value, value, compareValueBy);
  const optionFound = options && Object.values(options).find(findOptionByValue);
  return optionFound && optionFound.vm;
}

export function optionLabelIncludesTerm(label, term) {
  return removeAccents(label).toLowerCase().includes(removeAccents(term).toLowerCase());
}

export function getSelectOptionsVm(vnode) {
  return getChildComponentInstance(vnode, 'DsSelectOptions');
}
