<template>
  <transition name="slide">
    <aside v-if="isOpened" :style="{ top: styleTop, height: styleHeight }" class="ds-topbar-aside">
      <header class="ds-topbar-aside__header">
        <slot name="header">
          <ds-heading size="sm" :text="title" />
        </slot>
      </header>
      <nav class="ds-topbar-aside__nav">
        <slot>
          <ds-list>
            <slot name="list" />
          </ds-list>
        </slot>
      </nav>
    </aside>
  </transition>
</template>

<script>
import DsHeading from '@components/heading';
import DsList from '@components/list';

export default {
  name: 'DsTopbarAside',
  components: {
    DsHeading,
    DsList,
  },
  inject: ['topbarVm', 'topbarItemVm'],
  props: {
    title: String,
  },
  data() {
    return {
      styleTop: '',
      styleHeight: '',
    };
  },
  computed: {
    isOpened() {
      return this.topbarVm.getActiveItem().key === this.topbarItemVm.key;
    },
  },
  watch: {
    isOpened(value) {
      if (value) {
        this.$emit('opened');
        this.$nextTick(() => {
          this.setTopPosition();
        });
      } else {
        this.$emit('closed');
      }
    },
  },
  methods: {
    setTopPosition() {
      const { top, height } = this.topbarVm.getTopbarRect();
      this.styleTop = `${top + height}px`;
      this.styleHeight = `calc(100vh - ${top + height}px)`;
    },
  },
};
</script>

<style scoped>
@import './TopbarAside.css';
</style>
