<template>
  <component :is="componentName" v-bind="$props" v-on="listeners">
    <slot />
  </component>
</template>

<script>
import { debug } from '@core';
import DsSplitDefaultButton from '@components/split-default-button/SplitDefaultButton.vue';
import DsSplitRequestButton from '@components/split-request-button/SplitRequestButton.vue';
import DsSplitSubmitButton from '@components/split-submit-button/SplitSubmitButton.vue';

export default {
  name: 'DsSplitButton',
  components: {
    DsSplitDefaultButton,
    DsSplitRequestButton,
    DsSplitSubmitButton,
  },
  provide() {
    return {
      splitButtonVm: {
        getType: () => this.type,
      },
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    type: {
      type: String,
      default: 'default',
      validator(type) {
        return ['default', 'request', 'submit'].includes(type);
      },
    },
    theme: {
      type: String,
      default: 'default',
      validator(theme) {
        return ['default', 'primary', 'secondary'].includes(theme);
      },
    },
    requestAction: {
      type: Function,
    },
    fullWidth: {
      type: Boolean,
    },
  },
  computed: {
    componentName() {
      return `ds-split-${this.type}-button`;
    },
    listeners() {
      return {
        ...this.$listeners,
        click: event => this.$emit('click', event),
        'request-success': response => this.$emit('request-success', response),
        'request-error': response => this.$emit('request-error', response),
      };
    },
  },
  created() {
    if (this.type === 'request' && !this.requestAction) {
      debug.error('"request-action" prop must be informed when type is "request"');
    }
  },
};
</script>
