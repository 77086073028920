<template>
  <li
    class="ds-sidebar-menu-item-single"
    role="presentation"
    @keydown.stop.space.enter="selectItem"
    @click="selectItem">
    <component
      :is="href ? 'a' : 'div'"
      class="ds-sidebar-menu-item-single__content"
      tabindex="0"
      role="treeitem"
      :href="href"
      v-bind="{ ...(shouldOpenNewTab ? { target: '_blank' } : {}) }">
      <ds-sidebar-menu-item-content
        :text="label"
        :icon="icon"
        :is-new="isNew"
        :count-value="countValue"
        :count-value-tooltip="countValueTooltip"
        :is-external="isExternal"
        :show-favorite-button="showFavoriteButton"
        :is-favorite="isFavorite"
        :badge-value="badgeValue"
        :badge-theme="badgeTheme"
        :type="type"
        @favorite-change="onFavorite" />
    </component>
  </li>
</template>

<script>
import DsSidebarMenuItemContent from './SidebarMenuItemContent.vue';

export default {
  name: 'DsSidebarMenuItemSingle',
  components: {
    DsSidebarMenuItemContent,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    href: {
      type: String,
    },
    /**
     * <icon-name> OR [<solid|regular|light|duotone>, <icon-name>]
     */
    icon: {
      type: [String, Array],
    },
    isNew: {
      type: Boolean,
    },
    isExternal: {
      type: Boolean,
    },
    showFavoriteButton: {
      type: Boolean,
    },
    isFavorite: {
      type: Boolean,
    },
    badgeValue: {
      type: String,
    },
    badgeTheme: {
      type: String,
    },
    countValue: {
      type: Number,
    },
    countValueTooltip: {
      type: String,
    },
    type: {
      type: String,
    },
  },
  computed: {
    shouldOpenNewTab() {
      return this.href && this.isExternal;
    },
  },
  mounted() {
    if (this.countValue && !this.countValueTooltip) {
      throw new Error('Both countValue and countValueTooltip should be defined');
    }
  },
  methods: {
    selectItem() {
      this.$emit('item-selected');
    },
    onFavorite(favorite) {
      this.$emit('favorite-change', favorite);
    },
  },
};
</script>

<style scoped>
@import './SidebarMenuItemSingle.css';
</style>
