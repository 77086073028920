<template>
  <th class="ds-table-header" :style="style" :class="headerClasses" @click="onClick">
    <div class="ds-table-header-content">
      <div class="ds-table-header-content-title" :class="titleClasses">
        <ds-tooltipped-overflow>
          <div v-ds-tooltip="contentText" class="ds-table-header__overflow">
            <slot></slot>
          </div>
        </ds-tooltipped-overflow>
        <span class="ds-table-header-content-title--required" />
        <ds-helper-icon :tooltip="tooltip" />
      </div>

      <div v-if="isSortable" class="ds-table-header-order-icon ds-u-print-hidden">
        <ds-icon v-ds-tooltip="'Ordenar'" :color="orderIconColor" :icon="orderIcon" />
      </div>
    </div>
  </th>
</template>

<script>
import { hasConstructor } from '@core/services/browser/browserService';
import { debug } from '@core';
import { getTextFromSlot } from '@core/services/vnode/vnodeService';
import DsIcon from '@components/icon';
import DsTooltip from '@directives/tooltip';
import DsHelperIcon from '@components/helper-icon/HelperIcon.vue';
import DsTooltippedOverflow from '@components/tooltipped-overflow';
import { getColumnFixedParams, getFixedColumnClasses, getFixedColumnOffset } from '@components/table/tableService';
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';

export default {
  name: 'DsTh',
  inject: ['tableVm', 'tableStore', 'tableRowVm'],
  components: {
    DsIcon,
    DsHelperIcon,
    DsTooltippedOverflow,
  },
  directives: {
    DsTooltip,
  },
  props: {
    size: {
      type: [String, Number],
    },
    /**
     * DEPRECATED PROP | Use @sort event instead
     */
    onSort: {
      type: Function,
    },
    sortable: {
      type: Boolean,
    },
    align: {
      type: String,
      validator(align) {
        return ['center', 'right'].includes(align);
      },
    },
    tooltip: {
      type: String,
      default: null,
    },
    truncate: {
      type: Boolean,
      default: true,
    },
    theme: {
      type: String,
      default: 'default',
      validator(value) {
        return ['default', 'primary', 'secondary', 'warning', 'danger'].includes(value);
      },
    },
  },
  data() {
    return {
      isSortable: false,
      order: 'default',
      index: null,
      uid: null,
    };
  },
  computed: {
    isFixedSize() {
      return String(this.size).includes('px');
    },
    orderIcon() {
      return {
        default: ['regular', 'arrow-up-arrow-down'],
        asc: ['regular', 'arrow-up'],
        desc: ['regular', 'arrow-down'],
      }[this.order];
    },
    orderIconColor() {
      return this.order !== 'default' ? 'blue' : 'grey';
    },
    column() {
      return this.tableStore.getColumn(this.uid);
    },
    style() {
      return {
        width: this.isFixedSize && this.size,
        ...this.fixedColumnOffset,
      };
    },
    headerClasses() {
      return {
        [`ds-table-header-sortable`]: this.isSortable,
        [`ds-table-col-${this.size}`]: !this.isFixedSize && this.size,
        'ds-table-header--no-truncate': !this.truncate,
        [`ds-table-header--${this.theme}`]: true,
        ...this.fixedColumnClasses,
      };
    },
    titleClasses() {
      return {
        [`ds-table-header-content-title-align--${this.align}`]: this.align,
      };
    },
    contentText() {
      return getTextFromSlot(this.$slots.default);
    },
    fixedColumnClasses() {
      return getFixedColumnClasses(this.column);
    },
    fixedColumnOffset() {
      return getFixedColumnOffset(this.column);
    },
  },
  created() {
    this.isSortable = !!this.onSort || this.sortable;
    this.order = this.isSortable ? 'default' : null;

    const deprecatedDependency = createDeprecation(this);

    if (this.isSortable && !this.$vnode.key) {
      debug.error('You need to inform a key to create a sortable ds-th');
    }

    this.tableVm.addHeader(this);

    if (this.onSort) {
      deprecatedDependency.deprecateProperty('onSort', 'Use @sort event instead');
    }
  },
  mounted() {
    if (!this.tableVm) {
      throw new Error('DsTh must be wrapped with DsTable');
    }

    if (!this.tableRowVm) {
      throw new Error('DsTh must be wrapped with DsTr');
    }

    this.setIndex();
    this.$nextTick(() => {
      this.registerColumn();
      this.fixedColumnWithColspanHandler();
      this.updateColumnAndConfigureResizeObserver();
    });
  },
  beforeDestroy() {
    this.tableVm.removeHeader(this);
  },
  methods: {
    setIndex() {
      this.index = this.tableRowVm.createColumnIndex();
    },
    registerColumn() {
      this.uid = this.tableStore.getColumnUidByIndex(this.index) || Symbol('ds-th');

      const totalColumns = this.tableStore.getTotalColumns();
      const fixFirstColumns = this.tableVm.getFixFirstColumns();
      const fixLastColumns = this.tableVm.getFixLastColumns();
      const { fixed, fixedDivider } = getColumnFixedParams({
        index: this.index,
        fixFirstColumns,
        fixLastColumns,
        totalColumns,
      });

      this.tableStore.registerColumn(this.uid, {
        fixed,
        fixedDivider,
      });
    },
    fixedColumnWithColspanHandler() {
      if (this.column.fixed && this.$el.colSpan > 1) {
        debug.error('A fixed column cannot have colspan defined', this);
      }
    },
    handleSort() {
      if (this.isSortable) {
        this.tableVm.setTableHeaderNextOrder(this);
        if (this.onSort) {
          this.onSort({
            sortOrder: this.order,
            sortBy: this.$vnode.key,
          });
          return;
        }
        this.$emit('sort', {
          sortOrder: this.order,
          sortBy: this.$vnode.key,
        });
      }
    },
    onClick(event) {
      this.$emit('click', event);
      this.handleSort();
    },
    setNextOrder() {
      this.setOrder(this.getNextOrder());
    },
    getNextOrder() {
      return {
        default: 'asc',
        asc: 'desc',
        desc: 'default',
      }[this.order];
    },
    setOrder(order) {
      this.order = order;
    },
    updateColumnAndConfigureResizeObserver() {
      if (!this.column.fixed) {
        return;
      }

      this.updateColumnWidth();

      this.$nextTick(() => {
        this.tableStore.updateColumnOffset(this.uid);
        this.configureResizeObserver();
      });
    },
    updateColumnWidth() {
      this.tableStore.updateColumnWidth(this.uid, this.getColumnWidth());
    },
    updateColumnWidthAndSiblingsOffset() {
      this.tableStore.updateColumnWidth(this.uid, this.getColumnWidth(), true);
    },
    getColumnWidth() {
      return this.$el.getBoundingClientRect().width;
    },
    configureResizeObserver() {
      if (!hasConstructor('ResizeObserver')) {
        return;
      }

      const resizeObserver = new ResizeObserver(this.updateColumnWidthAndSiblingsOffset);
      resizeObserver.observe(this.$el);

      this.$on('hook:beforeDestroy', () => {
        resizeObserver.disconnect();
      });
    },
  },
};
</script>

<style scoped>
@import './TableHeader.css';
</style>
