<template>
  <header v-ds-click-outside="clearActiveItem" class="ds-topbar">
    <div class="ds-topbar__left-wrapper">
      <div class="ds-topbar__logo">
        <ds-link :href="logoHref">
          <slot name="logo" />
        </ds-link>
      </div>
      <div class="ds-topbar__title">
        <ds-tooltipped-overflow>
          <ds-heading v-ds-tooltip:224="title" :text="title" />
        </ds-tooltipped-overflow>
      </div>
    </div>
    <nav class="ds-topbar__nav">
      <div v-if="hasQuickAccessItemsSlot" class="ds-topbar__quick-access">
        <ds-dropdown align="right">
          <ds-button slot="trigger" theme="secondary" size="sm" icon="plus" icon-position="left"
            >Novo <ds-icon class="ds-u-margin-left--sm ds-topbar__quick-access-icon" icon="chevron-down"></ds-icon
          ></ds-button>
          <slot name="quick-access" />
        </ds-dropdown>
      </div>
      <ds-topbar-divider v-if="hasQuickAccessItemsSlot && newsHref" />
      <div v-if="newsHref" class="ds-topbar__external-link">
        <ds-external-link with-icon :href="sanitizedHref">Novidades</ds-external-link>
      </div>
      <ds-topbar-divider v-if="hasDefaultSlot && hasQuickAccessItemsSlot" />
      <slot />
      <ds-dark-mode-button v-show="shouldShowDarkModeButton"></ds-dark-mode-button>
    </nav>
  </header>
</template>

<script>
import DsClickOutside from '@directives/click-outside';
import DsHeading from '@components/heading';
import DsDropdown from '@components/dropdown';
import DsButton from '@components/button';
import DsLink from '@components/link';
import DsExternalLink from '@components/external-link';
import DsTooltippedOverflow from '@components/tooltipped-overflow';
import DsTooltip from '@directives/tooltip';
import DsIcon from '@components/icon';
import { domService } from '@core';
import sanitizeUrlLib from '@contaazul/sanitize-url';
import DsDarkModeButton from '../dark-mode-button/DarkModeButton.vue';

export default {
  name: 'DsTopbar',
  components: {
    DsHeading,
    DsDropdown,
    DsButton,
    DsLink,
    DsTooltippedOverflow,
    DsIcon,
    DsExternalLink,
    DsDarkModeButton,
  },
  directives: {
    DsClickOutside,
    DsTooltip,
  },
  provide() {
    return {
      topbarVm: {
        getActiveItem: this.getActiveItem,
        setActiveItem: this.setActiveItem,
        clearActiveItem: this.clearActiveItem,
        getTopbarRect: this.getTopbarRect,
      },
      shouldShowDarkModeButton: false,
    };
  },
  props: {
    title: String,
    newsHref: String,
    logoHref: {
      type: String,
      default: '/',
    },
  },
  data() {
    return {
      activeItem: {
        key: null,
      },
    };
  },
  computed: {
    hasQuickAccessItemsSlot() {
      return !!this.$slots['quick-access'];
    },
    hasDefaultSlot() {
      return !!this.$slots.default;
    },
    sanitizedHref() {
      return sanitizeUrlLib.sanitizeUrl(this.newsHref);
    },
  },
  methods: {
    setActiveItem(key) {
      this.activeItem.key = key;
    },
    getActiveItem() {
      return this.activeItem;
    },
    clearActiveItem() {
      this.setActiveItem(null);
    },
    getTopbarRect() {
      if (this.$el) {
        return {
          top: domService.getBoundingClientRectProp(this.$el, 'top'),
          height: domService.getBoundingClientRectProp(this.$el, 'height'),
        };
      }
      return {};
    },
  },
};
</script>

<style scoped>
@import './Topbar.css';
</style>
