export function isComponent(vnode, name) {
  return vnode.componentOptions && vnode.componentOptions.Ctor.extendOptions.name === name;
}

export function isValidComponent(vnode) {
  return !!vnode.tag;
}

export function getChildrenFromSlot(vnodes, condition = () => true) {
  return vnodes.filter(vnode => (!!vnode.tag || isText(vnode)) && condition(vnode));
}

export function getPropsData(vnode) {
  return vnode.componentOptions && vnode.componentOptions.propsData;
}

export function isText(vnode) {
  return !vnode.tag && vnode.text !== ' ' && vnode?.elm?.nodeName !== '#comment';
}

export function getText(vnode) {
  return isText(vnode) ? vnode.text : '';
}

export function getTextFromSlot(vnodes = []) {
  return vnodes
    .map(node => (node.children ? getTextFromSlot(node.children) : node.text))
    .join(' ')
    .trim();
}

function getParentElement(appendTo) {
  const el = document.createElement('div');

  if (appendTo) {
    appendTo.appendChild(el);
  } else {
    document.body.appendChild(el);
  }

  return el;
}

export function buildValidator({ validValues, errorMessage }) {
  return value => {
    const isValid = validValues.includes(value);

    if (!isValid) {
      console.error(`${errorMessage}: ${validValues.join(', ')}`);
    }

    return isValid;
  };
}

export function getChildComponentInstance(vnode, name, condition = () => true) {
  if (isComponent(vnode, name) && condition(vnode)) {
    return vnode.componentInstance;
  }

  let result;

  if (vnode.componentInstance.$children) {
    vnode.componentInstance.$children.some(child => {
      result = getChildComponentInstance(child.$vnode, name, condition);
      return !!result;
    });
  }

  return result;
}

export function getChildVnode(vnode, name, condition = () => true) {
  if (isComponent(vnode, name) && condition(vnode)) {
    return vnode;
  }

  let result;

  (vnode.componentOptions?.children || []).forEach(child => {
    result = getChildVnode(child, name, condition);
  });

  return result;
}

export function getComponentRenderer(Vue, options) {
  return (Component, renderOptions, appendTo) => {
    const el = getParentElement(appendTo);

    const Ctor = Vue.extend({
      functional: true,
      render(h) {
        return h(Component, renderOptions);
      },
    });

    return new Ctor(options).$mount(el).$children[0];
  };
}
