<template>
  <div ref="main" class="ds-scrollbar-area" :style="style" @scroll.passive="topScroll && onScroll($event, 'main')">
    <div
      v-if="topScroll && hasHorizontalScroll"
      ref="top"
      class="ds-scrollbar-area-top-scroll"
      @scroll="onScroll($event, 'top')">
      <div class="ds-scrollbar-area-top-scroll__content" :style="{ width: slotContentWidth }">&nbsp;</div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import { hasConstructor } from '@core/services/browser/browserService';

export default {
  name: 'DsScrollbarArea',
  props: {
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '100%',
    },
    minWidth: {
      type: String,
      default: '0',
    },
    minHeight: {
      type: String,
      default: '0',
    },
    maxWidth: {
      type: String,
      default: 'none',
    },
    maxHeight: {
      type: String,
      default: 'none',
    },
    topScroll: {
      type: Boolean,
    },
  },
  data() {
    return {
      slotContentWidth: null,
      lastScrollLeft: 0,
      mainResizeObserver: null,
      slotChildrenResizeObserver: null,
      hasHorizontalScroll: false,
    };
  },
  computed: {
    style() {
      return {
        width: this.width,
        height: this.height,
        minWidth: this.minWidth,
        minHeight: this.minHeight,
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight,
      };
    },
  },
  watch: {
    slotContentWidth() {
      setTimeout(() => {
        this.updateHasHorizontalScroll();
      });
    },
  },
  mounted() {
    this.updateSlotContentWidth();

    if (this.topScroll && hasConstructor('ResizeObserver')) {
      this.configureMainResizeObserver();
      this.configureSlotChildrenResizeObserver();
    }
  },
  methods: {
    onScroll(event, ref) {
      const targetRef = this.getTargetRef(ref);
      const { scrollLeft } = event.target;

      if (scrollLeft !== this.lastScrollLeft) {
        this.updateRefScrollLeft(targetRef, scrollLeft);
        this.updateLastScrollLeft(scrollLeft);
      }
    },
    updateRefScrollLeft(ref, value) {
      this.$refs[ref].scrollLeft = value;
    },
    updateLastScrollLeft(value) {
      this.lastScrollLeft = value;
    },
    updateHasHorizontalScroll() {
      this.hasHorizontalScroll = this.$refs.main.scrollWidth > this.$refs.main.clientWidth;
    },
    getTargetRef(ref) {
      return ref === 'main' ? 'top' : 'main';
    },
    updateSlotContentWidth() {
      this.slotContentWidth = `${this.$refs.main.scrollWidth}px`;
    },
    configureMainResizeObserver() {
      this.mainResizeObserver = new ResizeObserver(this.updateHasHorizontalScroll);
      this.mainResizeObserver.observe(this.$refs.main);

      this.$on('hook:destroyed', () => {
        this.mainResizeObserver.disconnect();
      });
    },
    configureSlotChildrenResizeObserver() {
      this.slotChildrenResizeObserver = new ResizeObserver(this.updateSlotContentWidth);
      const children = this.$slots.default || [];

      children.forEach(child => {
        this.slotChildrenResizeObserver.observe(child.elm);
      });

      this.$on('hook:destroyed', () => {
        this.slotChildrenResizeObserver.disconnect();
      });
    },
  },
};
</script>

<style scoped>
@import './ScrollbarArea.css';
</style>
