import DsAsyncFileUploadRollover from './AsyncFileUploadRollover.vue';
import DsAsyncFileUploadMiniRollover from './AsyncFileUploadMiniRollover.vue';
import store from './store';

function openMiniRollover() {
  openDialog(DsAsyncFileUploadMiniRollover, {
    on: {
      maximized: () => openRollover(),
    },
  });
}

function openRollover() {
  openDialog(DsAsyncFileUploadRollover, {
    on: {
      minimized: () => openMiniRollover(),
    },
  });
}

function openDialog(DialogComponent, { on }) {
  store.setCurrentRollover(
    DialogComponent.open(
      {
        store,
      },
      {
        on: {
          ...on,
          closed: store.closeAllContexts,
        },
      },
    ),
  );
}

function validateRequiredFields(requiredFields, args) {
  const notInformed = requiredFields.filter(field => !args[field]);

  if (notInformed.length) {
    throw new Error(
      `The fields [${requiredFields.join(', ')}] are required. Not informed: [${notInformed.join(', ')}]`,
    );
  }
}

export default {
  registerContext({ id, name, endpoint, headers, options, title } = {}) {
    validateRequiredFields(['id', 'name', 'endpoint'], { id, name, endpoint, headers, options, title });

    store.registerContext(id, {
      name,
      endpoint,
      headers,
      options,
      title,
    });
  },
  open({
    context,
    onClose = () => {},
    onFileAdded = () => {},
    onFileRemoved = () => {},
    onFileUploaded = () => {},
    onUploadProgress = () => {},
    onUploadError = () => {},
    onUploadRetry = () => {},
    onRestrictionFailed = () => {},
  }) {
    store.subscribeToContext(context, {
      onClose,
      onFileAdded,
      onFileRemoved,
      onFileUploaded,
      onUploadError,
      onUploadProgress,
      onUploadRetry,
      onRestrictionFailed,
    });

    store.activateContext(context);

    if (!store.currentRollover) {
      openRollover();
    }

    return () => store.closeContext(context);
  },
};
