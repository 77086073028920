import { getChildComponentInstance, getChildrenFromSlot, isComponent } from '@core/services/vnode/vnodeService';
import { POSITION } from './constants';

export function getTotalColumns(vm) {
  const tableRowVm = getChildComponentInstance(vm.$vnode, 'DsTr');

  if (!tableRowVm) {
    return 0;
  }

  if (!tableRowVm.$slots.default) {
    throw new Error('DsTr must have a default slot with at least one child element declared');
  }

  const tableHeaders = getChildrenFromSlot(
    tableRowVm.$slots.default,
    vnode => isComponent(vnode, 'DsTh') || isComponent(vnode, 'DsInlineTableTh'),
  );

  return tableHeaders.length;
}

export function getColumnFixedParams({ index, fixFirstColumns, fixLastColumns, totalColumns }) {
  let fixed;
  const fixedDivider = getIsFixedDivider({ index, fixFirstColumns, fixLastColumns, totalColumns });

  if (fixFirstColumns && isColumnFixedToStart(index, fixFirstColumns)) {
    fixed = POSITION.START;
  }

  if (fixLastColumns && isColumnFixedToEnd(index, fixLastColumns, totalColumns)) {
    fixed = POSITION.END;
  }

  return {
    fixed,
    fixedDivider,
  };
}

function isColumnFixedToStart(index, fixFirstColumns) {
  return index + 1 <= fixFirstColumns;
}

function isColumnFixedToEnd(index, fixLastColumns, totalColumns) {
  return fixLastColumns >= totalColumns - index;
}

function getIsFixedDivider({ index, fixFirstColumns, fixLastColumns, totalColumns }) {
  return (
    isLastFixedToStart(index, fixFirstColumns) || isFirstFixedToEnd(index, fixLastColumns, totalColumns) || undefined
  );
}

function isLastFixedToStart(index, fixFirstColumns) {
  return fixFirstColumns === index + 1;
}

function isFirstFixedToEnd(index, fixLastColumns, totalColumns) {
  return fixLastColumns === totalColumns - index;
}

export function getFixedColumnClasses({ fixed, fixedDivider } = {}) {
  return {
    'ds-table-col-fixed': !!fixed,
    'ds-table-col-fixed--right-divider': fixed === POSITION.START && fixedDivider,
    'ds-table-col-fixed--left-divider': fixed === POSITION.END && fixedDivider,
  };
}

export function getFixedColumnOffset({ fixed, offset } = {}) {
  return {
    left: fixed === POSITION.START && offset >= 0 ? `${offset}px` : null,
    right: fixed === POSITION.END && offset >= 0 ? `${offset}px` : null,
  };
}
