<template>
  <ds-row v-if="hasVisibleFilters" class="ds-data-grid-advanced-filters__container">
    <ds-col>
      <ds-data-grid-advanced-filter
        v-for="filter in visibleFilters"
        :key="filter.key"
        v-bind="filter"
        @click="onFilterClick(filter)"
        @remove="removeFilter(filter)"
        @change="value => setFilterValue(filter, value)" />
    </ds-col>
  </ds-row>
</template>

<script>
import { debug } from '@core';
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsDataGridAdvancedFilter from './DataGridAdvancedFilter.vue';
import { TYPES } from './dataGridAdvancedFilterConstants';

export default {
  name: 'DsDataGridAdvancedFilters',
  components: {
    DsDataGridAdvancedFilter,
    DsRow,
    DsCol,
  },
  props: {
    filters: {
      type: Object,
    },
    options: {
      type: Object,
      required: true,
      validator: validateOptions,
    },
    store: {
      type: Object,
      required: true,
    },
  },
  computed: {
    visibleFilters() {
      return this.store.visibleFilters;
    },
    hasVisibleFilters() {
      return !!Object.keys(this.visibleFilters).length;
    },
  },
  watch: {
    options: {
      deep: true,
      handler() {
        this.updateFilters(this.options);
      },
    },
  },
  created() {
    this.registerFilters(this.options);
    this.$watch('filters', this.setActivatedFilters, { deep: true, immediate: true });
    this.store.onChange((...args) => this.$emit('change', ...args));
  },
  methods: {
    updateFilters(options) {
      this.store.updateFilters(this.buildFiltersList(options));
    },
    registerFilters(options) {
      this.store.registerFilters(this.buildFiltersList(options));
    },
    buildFiltersList(options) {
      return Object.entries(options).map(([key, value]) => ({
        key,
        ...value,
      }));
    },
    setActivatedFilters(filters = {}) {
      Object.entries(filters).forEach(([key, value]) => {
        this.store.setValue(key, value);
      });
    },
    removeFilter(filter) {
      this.store.removeFilter(filter.key);
    },
    removeAllFilters() {
      this.store.removeAllFilters();
    },
    setFilterValue(filter, value) {
      this.store.setValue(filter.key, value);
    },
    onFilterClick(filter) {
      this.store.setFilterReady(filter.key);
    },
  },
};

function isOptionValid(option) {
  return Object.values(TYPES).includes(option.type) && option.name;
}

function validateOption(option) {
  const isValid = isOptionValid(option);

  if (!isValid) {
    debug.error('Advanced filter must have a valid type and name properties', option);
  }

  return isValid;
}

function validateOptions(options) {
  return !Object.values(options).map(validateOption).includes(false);
}
</script>

<style scoped>
@import './DataGridAdvancedFilters.css';
</style>
