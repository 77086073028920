<template>
  <div class="ds-data-grid">
    <ds-row v-if="alert" class="ds-data-grid__box-information-container">
      <ds-col>
        <ds-box-information v-if="alert" theme="danger" v-bind="alert">
          <ds-box-information-button v-if="alert.onRetry" @click="alert.onRetry">
            Tentar novamente
          </ds-box-information-button>
        </ds-box-information>
      </ds-col>
    </ds-row>
    <ds-data-grid-header
      ref="dataGridHeader"
      class="ds-data-header-container"
      :filters="filters"
      :multiple-select-filter="multipleSelectFilter"
      :search-term-placeholder="searchTermPlaceholder"
      :show-period-filter="showPeriodFilter"
      :disable-period-filter="disablePeriodFilter"
      :show-search-term-filter="showSearchTermFilter"
      :show-filter-button-group="showFilterButtonGroup"
      :show-multiple-select-filter="showMultipleSelectFilter"
      :advanced-filters-options="advancedFilters"
      :show-advanced-filters-tooltip="showAdvancedFiltersTooltip"
      :filter-button-group="filterButtonGroup"
      @filters-visible="onFiltersVisible"
      @filters-change="onFiltersChange"
      @filters-cleared="emitFiltersClearedEvent">
      <template v-if="$slots['header-actions']" slot="actions">
        <slot name="header-actions" />
      </template>
      <template v-if="$slots['header-filters']" slot="filters">
        <slot name="header-filters" />
      </template>
    </ds-data-grid-header>
    <ds-row v-if="$slots['box-information']" class="ds-u-print-hidden">
      <ds-col>
        <slot name="box-information"></slot>
      </ds-col>
    </ds-row>
    <ds-row class="ds-data-grid__table-container">
      <ds-col>
        <ds-data-grid-list-filter :list-filter-items="listFilterItems" @filter-select="onListFilterSelect" />
        <ds-data-grid-table
          ref="table"
          :class="dataGridTableClasses"
          :batch-type="batchType"
          :fetch-action="fetchHandler"
          :batch-remove-action="batchRemove"
          :sort-action="sortAction"
          :show-actions="showActions"
          :custom-batch-actions="batchActions"
          :columns="columns"
          :is-collapse="isCollapse"
          :fix-first-columns="fixFirstColumns"
          :fix-last-columns="fixLastColumns"
          @fetch-success="fetchSuccessHandler"
          @fetch-error="fetchErrorHandler"
          @batch-remove-error="onBatchRemoveError"
          @remove-error="onRemoveError"
          @row-selection-change="rowSelectionChangeHandler">
          <slot></slot>
        </ds-data-grid-table>
        <slot name="totals" />
        <ds-data-grid-pagination
          v-if="localFilters.pagination"
          v-bind="localFilters.pagination"
          @page-change="onPageChange"
          @init-pagination="setPagination" />
      </ds-col>
    </ds-row>
  </div>
</template>

<script>
import { debug } from '@core';
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import { isEquivalentObject } from '@core/services/object/objectService';
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsBoxInformation from '@components/box-information';
import DsBoxInformationButton from '@components/box-information-button';
import { createStore } from '@components/data-grid/store';
import isEmpty from 'lodash/isEmpty';
import DsDataGridListFilter from './DataGridListFilter.vue';
import DsDataGridPagination from './DataGridPagination.vue';
import DsDataGridHeader from './DataGridHeader.vue';
import DsDataGridTable from './DataGridTable.vue';

export default {
  name: 'DsDataGrid',
  components: {
    DsRow,
    DsCol,
    DsDataGridHeader,
    DsDataGridListFilter,
    DsDataGridTable,
    DsDataGridPagination,
    DsBoxInformation,
    DsBoxInformationButton,
  },
  props: {
    listFilterItems: DsDataGridListFilter.props.listFilterItems,
    /**
     * DEPRECATED PROP | Use fetch-action props instead
     */
    onFetch: Function,
    fetchAction: Function,
    /**
     * [{ key, name, size, onSort, align }]
     */
    columns: DsDataGridTable.props.columns,
    showActions: DsDataGridTable.props.showActions,
    batchActions: DsDataGridTable.props.customBatchActions,
    /**
     * DEPRECATED PROP | Use batch-remove-action props instead
     */
    onBatchRemove: Function,
    batchRemoveAction: Function,
    /**
     * DEPRECATED PROP | Use @row-selection-change event instead
     */
    onRowSelectionChange: Function,
    batchType: DsDataGridTable.props.batchType,
    showPeriodFilter: DsDataGridHeader.props.showPeriodFilter,
    disablePeriodFilter: Boolean,
    showSearchTermFilter: DsDataGridHeader.props.showSearchTermFilter,
    searchTermPlaceholder: DsDataGridHeader.props.searchTermPlaceholder,
    advancedFilters: DsDataGridHeader.props.advancedFiltersOptions,
    showAdvancedFiltersTooltip: DsDataGridHeader.props.showAdvancedFiltersTooltip,
    filters: DsDataGridHeader.props.filters,
    multipleSelectFilter: Object,
    defaultFilters: DsDataGridHeader.props.filters,
    /**
     * DEPRECATED PROP | Use @fetch-success event instead
     */
    onFetchSuccess: Function,
    pagination: {
      type: Object,
    },
    buildFetchAlert: {
      type: Function,
      default() {
        return {
          title: 'Os dados não puderam ser carregados',
          message: 'Aguarde alguns instantes e tente novamente',
        };
      },
    },
    buildBatchRemoveAlert: {
      type: Function,
      default() {
        return {
          title: 'Os itens selecionados não puderam ser excluídos',
          message: 'Aguarde alguns instantes e tente novamente',
        };
      },
    },
    buildRemoveAlert: {
      type: Function,
      default() {
        return {
          title: 'O item não pôde ser excluído',
          message: 'Aguarde alguns instantes e tente novamente',
        };
      },
    },
    isCollapse: Boolean,
    fixFirstColumns: DsDataGridTable.props.fixFirstColumns,
    fixLastColumns: DsDataGridTable.props.fixLastColumns,
    filterButtonGroup: Object,
  },
  provide() {
    return {
      dataGridVm: this,
      dataGrid: {
        store: this.store,
      },
    };
  },
  inject: {
    dataGridTemplate: {
      default: {},
    },
  },
  data() {
    return {
      tableKey: 0,
      alert: null,
      localFilters: {
        pagination: this.pagination,
      },
      filtersReady: {},
      hasFilters: false,
      store: this.dataGridTemplate.store || createStore(),
    };
  },
  computed: {
    paginationData() {
      return this.store?.getPaginationData();
    },
    hasPagination() {
      return !!this.pagination;
    },
    isAdvancedFilterReady() {
      return (
        !this.showAdvancedFilters ||
        !this.filters.advanced ||
        !Object.keys(this.filters.advanced).length ||
        Object.values(this.filters.advanced).every(filter => !filter) ||
        (this.localFilters.advanced && this.filtersReady.advanced)
      );
    },
    isPeriodFilterReady() {
      return !this.showPeriodFilter || this.filtersReady.period;
    },
    isSearchTermFilterReady() {
      return !this.showSearchTermFilter || this.filtersReady.searchTerm;
    },
    isListFilterReady() {
      return !this.listFilterItems || this.filtersReady.listFilter;
    },
    isFilterButtonGroupReady() {
      return !this.showFilterButtonGroup || this.filtersReady.buttonGroup;
    },
    isMultipleSelectFilterReady() {
      return !this.showMultipleSelectFilter || this.filtersReady.multipleSelect;
    },
    isPaginationReady() {
      return !this.pagination || this.filtersReady.pagination;
    },
    areFiltersReady() {
      return (
        this.isAdvancedFilterReady &&
        this.isPeriodFilterReady &&
        this.isSearchTermFilterReady &&
        this.isListFilterReady &&
        this.isFilterButtonGroupReady &&
        this.isPaginationReady &&
        this.isMultipleSelectFilterReady
      );
    },
    showAdvancedFilters() {
      return Object.keys(this.advancedFilters).length;
    },
    shouldHideDataGridBorderBottom() {
      return !!this.$slots.totals;
    },
    dataGridTableClasses() {
      return { 'ds-data-grid-table--hide-border-bottom': this.shouldHideDataGridBorderBottom };
    },
    fetch() {
      return this.fetchAction || this.onFetch;
    },
    batchRemove() {
      return this.batchRemoveAction || this.onBatchRemove;
    },
    showFilterButtonGroup() {
      return !!this.filterButtonGroup?.buttons?.length;
    },
    showMultipleSelectFilter() {
      return !!this.multipleSelectFilter;
    },
  },
  watch: {
    paginationData(pagination) {
      this.onPageChange(pagination, true);
    },
    pagination: {
      deep: true,
      handler(pagination) {
        if (pagination && Object.keys(pagination).length) {
          this.setPagination(pagination, { triggerRefresh: false });
        }
      },
    },
    filters(filters) {
      this.setFilters(filters);
      this.refreshTable();
    },
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.$attrs['on-create']) {
      debug.error('Use ds-data-grid-template with create-action instead on-create', this);
    }
    if (this.$attrs['create-action']) {
      debug.error('Move create-action ds-data-grid prop to ds-data-grid-template', this);
    }
    if (this.$attrs['create-actions']) {
      debug.error('Move create-actions ds-data-grid prop to ds-data-grid-template', this);
    }
    if (this.$slots.actions) {
      debug.error('Move $slots.actions to ds-data-grid-template', this);
    }
    if (this.onFetch) {
      deprecatedDependency.deprecateProperty('onFetch', 'Use fetch-action instead');
    }
    if (this.onFetchSuccess) {
      deprecatedDependency.deprecateProperty('onFetchSuccess', 'Use @fetch-success event instead');
    }
    if (this.onBatchRemove) {
      deprecatedDependency.deprecateProperty('onBatchRemove', 'Use batch-remove-action instead');
    }
    if (this.onRowSelectionChange) {
      deprecatedDependency.deprecateProperty('onRowSelectionChange', 'Use @row-selection-change event instead');
    }

    this.store.initFilters(this.filters, this.defaultFilters, this.filterButtonGroup);
  },
  methods: {
    fetchHandler() {
      return this.areFiltersReady ? this.fetch(this.localFilters) : new Promise(() => {});
    },
    fetchSuccessHandler(response) {
      this.setAlert(null);
      this.$emit('fetch-success', response);
      if (this.onFetchSuccess) {
        this.onFetchSuccess(response);
      }
    },
    setAlert(alert) {
      this.alert = alert;
    },
    fetchErrorHandler(error) {
      this.setAlert({ ...this.buildFetchAlert(error), onRetry: this.refreshTable });
      this.$emit('fetch-error', error);
    },
    rowSelectionChangeHandler(...args) {
      if (this.onRowSelectionChange) {
        this.onRowSelectionChange(...args);
      }
      this.$emit('row-selection-change', ...args);
    },
    onBatchRemoveError(error) {
      this.setAlert(this.buildBatchRemoveAlert(error));
      this.$emit('batch-remove-error', error);
    },
    onRemoveError(error) {
      this.setAlert(this.buildRemoveAlert(error));
      this.$emit('remove-error', error);
    },
    /**
     * Refreshes table (call fetch)
     *
     * @public
     * @param {Object} options
     * @param {Boolean} options.clearChecks - If should clear checks after refresh
     */
    refreshTable({ clearChecks } = {}) {
      if (this.$refs.table) {
        this.$refs.table.refresh({ clearChecks });
      }
    },
    setFilters(filters) {
      this.localFilters = filters;
    },
    setFilter(filterName, value) {
      this.$set(this.localFilters, filterName, value);
    },
    shouldResetPagination(filterName) {
      return (
        this.hasPagination &&
        ['period', 'searchTerm', 'advanced', 'listFilter', 'buttonGroup', 'multipleSelect'].includes(filterName)
      );
    },
    paginationRenderValidations(filterName, value) {
      if (this.store && filterName === 'pagination') {
        this.store.setPaginationData(value);
      }
    },
    setFilterAndRefreshTable(filterName, value, triggerRefresh = true) {
      this.paginationRenderValidations(filterName, value);

      if (!isEquivalentObject(this.localFilters[filterName], value)) {
        this.setFilter(filterName, value);

        if (triggerRefresh) {
          this.triggerRefreshIfReady(filterName);
        }
      }
    },
    triggerRefreshIfReady(filterName) {
      this.$set(this.filtersReady, filterName, true);

      if (this.areFiltersReady) {
        if (this.shouldResetPagination(filterName)) {
          this.setPagination({ page: 1 }, { triggerRefresh: false });
        }

        this.refreshTable({
          clearChecks: shouldClearChecks(filterName),
        });
      }
    },
    onFiltersChange({ period, periodType, searchTerm, advanced, buttonGroup, multipleSelect }, triggerRefresh) {
      if (this.showPeriodFilter) {
        const shouldTriggerRefresh = !isEmpty(period);

        this.setFilter('periodType', periodType);
        this.setFilterAndRefreshTable('period', period, shouldTriggerRefresh);
      }

      if (this.showSearchTermFilter) {
        this.setFilterAndRefreshTable('searchTerm', searchTerm);
      }

      if (this.showFilterButtonGroup) {
        this.setFilterAndRefreshTable('buttonGroup', buttonGroup);
      }

      if (this.showMultipleSelectFilter) {
        this.setFilterAndRefreshTable('multipleSelect', multipleSelect);
      }

      if (this.showAdvancedFilters) {
        this.setFilterAndRefreshTable('advanced', advanced, triggerRefresh);
      }
    },
    onListFilterSelect(listFilter) {
      this.setFilterAndRefreshTable('listFilter', listFilter);
    },
    onPageChange(pagination, triggerRefresh) {
      this.setFilterAndRefreshTable('pagination', pagination, triggerRefresh);
    },
    sortAction(sort) {
      this.setFilterAndRefreshTable('sort', sort);
    },
    setPagination(pagination, { triggerRefresh } = {}) {
      this.setFilterAndRefreshTable(
        'pagination',
        {
          ...(this.localFilters.pagination || {}),
          ...pagination,
        },
        triggerRefresh,
      );
    },
    /**
     * Updates period
     *
     * @public
     * @param {Object} period
     */
    updatePeriod(period) {
      this.$refs.dataGridHeader.updateFilter('period', period);
    },
    /**
     * Deprecated - Updates period arrows navigation behavior
     *
     * @public
     * @param {"year" | "month" | "custom"} type
     */
    setPeriodArrowsNavigationBy(type) {
      this.$refs.dataGridHeader.setPeriodArrowsNavigationBy(type);
    },
    emitFiltersClearedEvent() {
      this.$emit('filters-cleared');
    },
    onFiltersVisible(value) {
      this.hasFilters = value;
    },
  },
};

function shouldClearChecks(filterName) {
  return !['pagination', 'sort'].includes(filterName);
}
</script>

<style scoped>
@import './DataGrid.css';
</style>
