<template>
  <ds-button :class="classes" :disabled="disabled" :icon="icon" :tooltip="tooltip" size="md" v-on="$listeners">
  </ds-button>
</template>

<script>
import DsButton from '@components/button';

export default {
  name: 'DsDateFilterButton',
  components: {
    DsButton,
  },
  props: {
    type: {
      type: String,
      validator(type) {
        return ['next', 'prev'].includes(type);
      },
    },
    disabled: {
      type: Boolean,
    },
  },
  computed: {
    icon() {
      return {
        prev: 'angle-left',
        next: 'angle-right',
      }[this.type];
    },
    tooltip() {
      return {
        prev: 'Anterior',
        next: 'Próximo',
      }[this.type];
    },
    classes() {
      return ['ds-date-filter__button', `ds-date-filter__button--${this.type}`, 'ds-u-print-hidden'];
    },
  },
};
</script>

<style scoped>
@import './DateFilterButton.css';
</style>
