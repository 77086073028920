<template>
  <ds-button size="sm" :icon="['solid', 'star']" icon-position="left" v-on="$listeners">Dê a sua opinião</ds-button>
</template>

<script>
import DsButton from '@components/button';

export default {
  name: 'DsFeedbackButton',
  components: {
    DsButton,
  },
};
</script>
