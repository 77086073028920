<template>
  <span class="ds-badge" :class="classes" :style="styles">
    <ds-text weight="medium">
      <slot />
    </ds-text>
  </span>
</template>

<script>
import DsText from '@components/text';
import { debug } from '@core';

export default {
  name: 'DsBadge',
  components: {
    DsText,
  },
  props: {
    /**
     * [success, info, warning, danger]
     * */
    theme: {
      type: String,
      validator(theme) {
        return ['success', 'info', 'warning', 'danger', 'light'].includes(theme);
      },
    },
    /**
     * This prop is DEPRECATED
     * */
    size: {
      type: String,
      variation(size) {
        return !size || ['large'].includes(size);
      },
    },
    /**
     * This prop is DEPRECATED
     * */
    variation: {
      type: String,
      variation(variation) {
        return !variation || ['pill'].includes(variation);
      },
    },
    minWidth: {
      type: Number,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        [`ds-badge--${this.theme}`]: !!this.theme,
        [`ds-badge--${this.size}`]: !!this.size,
        [`ds-badge--${this.variation}`]: !!this.variation,
        'ds-badge--min-width': this.minWidth,
        'ds-badge--full-width': this.fullWidth,
      };
    },
    styles() {
      return this.minWidth ? { 'min-width': `${this.minWidth}px` } : {};
    },
  },
  created() {
    if (this.variation) {
      debug.error('The prop "variation" from DsBadge is DEPRECATED! Do NOT use it anymore.');
    }

    if (this.size) {
      debug.error('The prop "size" from DsBadge is DEPRECATED! Do NOT use it anymore.');
    }

    if (this.theme === 'light') {
      debug.error('The "light" theme from DsBadge is DEPRECATED! Use empty theme instead to use the default one.');
    }
  },
};
</script>

<style scoped>
@import './Badge.css';
</style>
