<template>
  <span v-if="$slots.default" class="ds-input-icons">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'DsInputIcons',
};
</script>

<style scoped>
@import './InputIcons.css';
</style>
