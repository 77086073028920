<template>
  <ds-input-group>
    <div class="ds-u-flex-grow" @keydown.enter.prevent="$refs.button.request">
      <slot></slot>
    </div>
    <div slot="button" v-ds-tooltip="buttonTooltipText">
      <ds-request-button ref="button" theme="default" :request-action="handleSearch" :disabled="disabled">
        Buscar dados
      </ds-request-button>
    </div>
  </ds-input-group>
</template>

<script>
import { debug, vnodeService } from '@core';
import DsTooltip from '@directives/tooltip';
import DsRequestButton from '@components/request-button';
import DsInputGroup from '@components/input-group';

export default {
  name: 'DsDataSearch',
  components: {
    DsInputGroup,
    DsRequestButton,
  },
  directives: {
    DsTooltip,
  },
  props: {
    onSearch: {
      type: Function,
      required: true,
    },
    onSearchSuccess: {
      type: Function,
      default: () => {},
    },
    onSearchError: {
      type: Function,
    },
    invalidFormTooltipText: {
      type: String,
      default: 'Para buscar os dados, preencha corretamente o campo ao lado.',
    },
  },
  data() {
    return {
      modelValidator: null,
    };
  },
  computed: {
    buttonTooltipText() {
      return !this.isInputValid && this.invalidFormTooltipText;
    },
    disabled() {
      return this.isInputDisabled || !this.isInputValid;
    },
    isInputDisabled() {
      return this.modelValidator && this.modelValidator.vm.disabled;
    },
    isInputValid() {
      return this.modelValidator && this.modelValidator.valid;
    },
    inputValue() {
      return this.modelValidator && this.modelValidator.vm.value;
    },
  },
  mounted() {
    this.$nextTick(() => {
      validateSlot(this.$slots.default);
      this.modelValidator = this.getModelValidator();
    });
  },
  methods: {
    getModelValidator() {
      return this.getInputComponent().modelValidator;
    },
    getInputComponent() {
      const inputVm = vnodeService.getChildComponentInstance(this.$slots.default[0], 'DsInput');

      if (!inputVm) {
        debug.error(new Error('You have to provide a input based component'));
      }

      return inputVm;
    },
    async handleSearch() {
      if (this.inputValue) {
        try {
          const response = await this.onSearch();
          this.onSearchSuccess(response);
        } catch (e) {
          this.onSearchError(e);
        }
      }
    },
  },
};

function validateSlot(slots) {
  if (!slots) {
    debug.error(new Error('You have to provide an input'));
  }

  if (slots.length > 1) {
    debug.error(new Error('You have to provide only one input'));
  }
}
</script>
