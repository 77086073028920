<template>
  <div>
    <ds-scrollbar-area top-scroll>
      <table :class="getClasses()" class="ds-table">
        <thead>
          <slot name="header"></slot>
        </thead>
        <tbody ref="table-tbody">
          <ds-table-loading v-if="loading" :columns="totalColumns" :rows="totalRows" :row-height="rowHeight" />
          <ds-tr v-else-if="isEmpty()" not-hoverable>
            <ds-td :colspan="totalColumns" class="ds-u-text-align--center">
              <ds-loader v-if="loading" theme="inside" />
              <div v-else-if="isDataGridWithFilters" class="ds-data-grid-empty-message">
                <ds-illustration name="person-tag-search" />
                <div class="ds-u-margin-left--md">
                  <ds-heading size="sm">Nenhum resultado encontrado</ds-heading>
                  <ds-p>Selecione outros filtros para refazer sua busca</ds-p>
                </div>
              </div>
              <span v-else>{{ emptyStateMessage }}</span>
            </ds-td>
          </ds-tr>
          <slot v-else></slot>
        </tbody>
        <tfoot v-if="$slots.footer">
          <slot name="footer"></slot>
        </tfoot>
      </table>
    </ds-scrollbar-area>
  </div>
</template>

<script>
import DsTd from '@components/table-column';
import DsTr from '@components/table-row';
import DsLoader from '@components/loader';
import DsScrollbarArea from '@components/scrollbar-area';
import DsIllustration from '@components/illustration';
import DsHeading from '@components/heading';
import DsP from '@components/paragraph';
import { isValidComponent } from '@core/services/vnode/vnodeService';
import DsTableLoading from './TableLoading.vue';
import { createStore } from './store';
import { getTotalColumns } from './tableService';

export default {
  name: 'DsTable',
  provide() {
    return {
      tableStore: this.store,
      tableVm: {
        addHeader: this.addHeader,
        removeHeader: this.removeHeader,
        setTableHeaderNextOrder: this.setTableHeaderNextOrder,
        getFixFirstColumns: () => this.fixFirstColumns,
        getFixLastColumns: () => this.fixLastColumns,
      },
    };
  },
  inject: {
    dataGridVm: {
      default: {},
    },
  },
  components: {
    DsTd,
    DsTr,
    DsLoader,
    DsScrollbarArea,
    DsIllustration,
    DsHeading,
    DsP,
    DsTableLoading,
  },
  inheritAttrs: false,
  props: {
    loading: Boolean,
    borderCollapse: Boolean,
    theme: {
      type: String,
      default: 'default',
      validator(theme) {
        return ['default', 'simple'].includes(theme);
      },
    },
    emptyStateMessage: {
      type: String,
      default: 'Nenhum registro encontrado',
    },
    fixFirstColumns: {
      type: Number,
      default: 0,
    },
    fixLastColumns: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      tableHeaders: {},
      store: createStore(),
      totalRows: undefined,
      rowHeight: undefined,
    };
  },
  computed: {
    totalColumns() {
      return this.store.getTotalColumns();
    },
    isDataGridWithFilters() {
      return this.dataGridVm?.hasFilters || this.dataGridVm?.filtersReady?.listFilter;
    },
  },
  watch: {
    loading() {
      if (this.loading) {
        this.loadingHandler();
      }
    },
  },
  mounted() {
    this.setTotalColumns();
  },
  methods: {
    setTotalColumns() {
      const totalColumns = getTotalColumns(this);
      if (totalColumns !== this.totalColumns) {
        this.store.setTotalColumns(totalColumns);
      }
    },
    addHeader(tableHeader) {
      this.tableHeaders[tableHeader.$vnode.key] = tableHeader;
    },
    removeHeader(tableHeader) {
      delete this.tableHeaders[tableHeader.$vnode.key];
    },
    getClasses() {
      return [
        `ds-table--${this.theme}`,
        {
          'ds-table--with-border-collapse': this.borderCollapse,
          'ds-table--is-loading': this.loading,
          'ds-table--is-empty': this.isEmpty(),
        },
      ];
    },
    isEmpty() {
      const isEmpty =
        !this.$slots.default || !this.$slots.default.length || !this.$slots.default.find(vnode => vnode.tag);

      if (isEmpty) {
        this.$nextTick(() => {
          this.setTotalColumns();
        });
      }
      return isEmpty;
    },
    setTableHeaderNextOrder(tableHeader) {
      const orderableCols = this.getSortableTableHeaders();

      orderableCols.forEach(col => {
        if (col.$vnode.key !== tableHeader.$vnode.key) {
          col.setOrder('default');
        }
      });

      tableHeader.setNextOrder();
    },
    getTableHeaders() {
      return Object.values(this.tableHeaders);
    },
    getSortableTableHeaders() {
      return this.getTableHeaders().filter(tableHeader => tableHeader.isSortable);
    },
    loadingHandler() {
      this.setTotalRows();
      this.setRowHeight();
    },
    setTotalRows() {
      const slotDefault = this.$slots.default || [];

      this.totalRows = slotDefault.filter(component => isValidComponent(component)).length;
    },
    setRowHeight() {
      const tbodyHeight = this.$refs['table-tbody'].clientHeight;

      this.rowHeight = this.isEmpty() ? undefined : tbodyHeight / this.totalRows;
    },
  },
};
</script>

<style>
@import './Table.css';
</style>
