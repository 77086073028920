<template>
  <span class="ds-search-select__input">
    <ds-input :value="term" @input="onInput" @keydown="handleKeydown" />
  </span>
</template>

<script>
import DsInput from '@components/input';

export default {
  name: 'DsSearchSelectInput',
  components: {
    DsInput,
  },
  props: {
    term: {
      type: String,
      default: '',
    },
    onChange: {
      type: Function,
    },
    onKeydown: {
      type: Function,
    },
  },
  methods: {
    onInput(value) {
      if (this.onChange) {
        this.onChange(value);
      }
    },
    handleKeydown($event) {
      if (this.onKeydown) {
        this.onKeydown($event);
      }
    },
  },
};
</script>
