import Vue from 'vue';
import * as contextService from './context';

function createStore() {
  return Vue.observable({
    openedContexts: {},
    activeContext: null,
  });
}

const registeredContexts = {};

let store = createStore();
let currentRollover;

function closeAllContexts() {
  Object.values(store.openedContexts).forEach(context => {
    closeContext(context.id);
  });

  setCurrentRollover(null);

  store = createStore();
}

function registerContext(id, { name, endpoint, headers, options, title }) {
  if (registeredContexts[id]) {
    throw new Error(`The context ${id} is already registered`);
  }

  registeredContexts[id] = contextService.createContext({
    id,
    name,
    endpoint,
    headers,
    options,
    title,
  });
}

function subscribeToContext(
  id,
  {
    onClose,
    onFileAdded,
    onFileRemoved,
    onFileUploaded,
    onUploadProgress,
    onUploadError,
    onUploadRetry,
    onRestrictionFailed,
  },
) {
  const context = registeredContexts[id];

  if (!context) {
    throw Error(`The context ${id} is not registered`);
  }

  Vue.set(store.openedContexts, id, context);

  context.subscribe({
    onClose,
    onFileAdded,
    onFileRemoved,
    onFileUploaded,
    onUploadProgress,
    onUploadError,
    onUploadRetry,
    onRestrictionFailed,
  });
}

function activateContext(id) {
  store.activeContext = id;
}

function closeContext(id) {
  if (store.openedContexts[id]) {
    store.openedContexts[id].close();
    Vue.delete(store.openedContexts, id);

    if (store.activeContext === id) {
      activateContext(Object.keys(store.openedContexts)?.[0] || null);
    }
  }
}

function setCurrentRollover(rollover) {
  currentRollover = rollover;
}

export default {
  closeAllContexts,
  registerContext,
  subscribeToContext,
  closeContext,
  activateContext,
  setCurrentRollover,
  get hasOpenContext() {
    return Object.keys(store.openedContexts).length;
  },
  get hasPendingFiles() {
    return !!Object.values(store.openedContexts).some(context => context.pendingFiles.length);
  },
  get currentRollover() {
    return currentRollover;
  },
  get contexts() {
    return store.openedContexts;
  },
  get activeContext() {
    return store.activeContext;
  },
};
