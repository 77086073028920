<template>
  <component :is="wrapperElement" class="ds-percent-input" add-on-text-right="%">
    <ds-beta-numeral-input
      ref="numeralInput"
      :value="value"
      :placeholder="placeholder"
      :min-precision="minPrecision"
      :precision="precision"
      :integer="integer"
      :suffix="suffixText"
      :custom-validations="customValidations"
      v-bind="$attrs"
      @custom-changed="onCustomChanged"
      @custom-input="onCustomInput" />
  </component>
</template>

<script>
import DsInputGroup from '@components/input-group';
import DsBetaNumeralInput from '../beta-numeral-input/BetaNumeralInput.vue';

export default {
  name: 'DsBetaPercentInput',
  components: {
    DsBetaNumeralInput,
    DsInputGroup,
  },
  inheritAttrs: false,
  props: {
    /**
     * Number or null
     */
    value: DsBetaNumeralInput.props.value,
    /**
     * [{ valid: Function, message: String }]
     */
    customValidations: DsBetaNumeralInput.props.customValidations,
    precision: {
      type: Number,
      default: 2,
    },
    minPrecision: {
      type: Number,
      default: 2,
    },
    integer: {
      type: Number,
      default: 10,
    },
    externalSymbol: {
      type: Boolean,
      default: true,
    },
    internalSymbol: Boolean,
    placeholder: String,
  },
  computed: {
    suffixText() {
      return this.internalSymbol ? '%' : undefined;
    },
    wrapperElement() {
      return this.externalSymbol && !this.internalSymbol ? 'ds-input-group' : 'div';
    },
  },
  watch: {
    value() {
      this.update(this.value);
    },
  },
  methods: {
    onCustomInput(value) {
      this.$emit('input', value);
    },
    onCustomChanged(value) {
      this.$emit('change', value);
    },
    update(value) {
      this.$refs.numeralInput.update(value);
    },
    validate() {
      this.$refs.numeralInput.validate();
    },
  },
};
</script>
