<template>
  <ds-text class="ds-currency-format" v-bind="$attrs">
    {{ formattedValue }}
  </ds-text>
</template>

<script>
import DsText from '@components/text';
import { numberFormatService } from '@core';

export default {
  name: 'DsCurrencyFormat',
  components: {
    DsText,
  },
  props: {
    value: {
      type: [String, Number],
    },
    precision: {
      type: [String, Number],
      default: 2,
    },
    withSymbol: {
      type: Boolean,
    },
    symbol: {
      type: String,
      default: 'R$',
    },
  },
  computed: {
    formattedValue() {
      const formattedCurrency = numberFormatService.formatNumber(this.value, {
        minimumPrecision: this.precision,
        precision: this.precision,
      });

      return this.withSymbol ? `${this.symbol} ${formattedCurrency}` : formattedCurrency;
    },
  },
};
</script>
