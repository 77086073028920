<template>
  <ds-wizard-step
    ref="dsWizardStep"
    :key="$vnode.key"
    class="ds-wizard-form-step"
    :ready="ready"
    :title="title"
    :description="description"
    :required="required"
    hide-footer>
    <slot :ready="ready" submit-button-text="Continuar" />
  </ds-wizard-step>
</template>

<script>
import DsWizardStep from '@components/wizard-step';

export default {
  name: 'DsWizardFormStep',
  provide() {
    return {
      registerFormHooks: this.registerFormHooks,
    };
  },
  components: {
    DsWizardStep,
  },
  props: {
    title: DsWizardStep.props.title,
    description: DsWizardStep.props.description,
    required: DsWizardStep.props.required,
  },
  data() {
    return {
      isSubmittedWithSuccess: false,
    };
  },
  computed: {
    ready() {
      return !this.required || this.isSubmittedWithSuccess;
    },
  },
  methods: {
    registerFormHooks({ onSubmitSuccess }) {
      onSubmitSuccess(() => {
        this.isSubmittedWithSuccess = true;
        this.$refs.dsWizardStep.next();
      });
    },
  },
};
</script>

<style scoped>
@import './WizardFormStep.css';
</style>
