import { formatCurrency } from '@ca-design-system-libs/currency';
import { dateService, dateParser, stringService } from '@core';
import { getPeriodTitle } from '@components/date-period-dropdown/datePeriodDropdownService';
import { TYPES } from './dataGridAdvancedFilterConstants';

function getComponent(componentName) {
  return import(`./types/${componentName}.vue`).then(popover => popover.default);
}

function getNumberTitle(startValue, endValue) {
  if (startValue && !endValue) {
    return `A partir de ${startValue}`;
  }
  if (!startValue && endValue) {
    return `Tudo até ${endValue}`;
  }
  return `${startValue} até ${endValue}`;
}

function getPeriodMonthTitle(startDate, endDate) {
  if (dateService.isPeriodFullMonth(startDate, endDate)) {
    return stringService.toCapitalize(dateParser.formatDate(startDate, 'YYYY-MM-DD', 'MMMM [de] YYYY'));
  }

  const formattedStartDateLabel = startDate ? dateParser.formatDate(startDate, 'YYYY-MM-DD', 'MM/YYYY') : undefined;
  const formattedEndDateLabel = endDate ? dateParser.formatDate(endDate, 'YYYY-MM-DD', 'MM/YYYY') : undefined;

  if (startDate && !endDate) {
    return `A partir de ${formattedStartDateLabel}`;
  }

  if (!startDate && endDate) {
    return `Todo período até ${formattedEndDateLabel}`;
  }

  return `${formattedStartDateLabel} até ${formattedEndDateLabel}`;
}

const TYPE_SERVICES = {
  [TYPES.SELECT]: {
    getPopoverComponent: () => getComponent('DataGridAdvancedFilterSelectPopover'),
    getFormattedValue: value => {
      if (value.length > 2) {
        return `${value.length} itens selecionados`;
      }

      return value.map(item => item.name || '?').join(', ');
    },
    isFullValue: value => value && value.every(item => item.name != null && item.key != null),
  },
  [TYPES.PERIOD]: {
    getPopoverComponent: () => getComponent('DataGridAdvancedFilterPeriodPopover'),
    getFormattedValue: value => getPeriodTitle(value.startDate, value.endDate),
    isFullValue: () => true,
  },
  [TYPES.PERIOD_MONTH]: {
    getPopoverComponent: () => getComponent('DataGridAdvancedFilterPeriodMonthPopover'),
    getFormattedValue: value => getPeriodMonthTitle(value.startDate, value.endDate),
    isFullValue: () => true,
  },
  [TYPES.CURRENCY]: {
    getPopoverComponent: () => getComponent('DataGridAdvancedFilterCurrencyPopover'),
    getFormattedValue: value => `De R$ ${formatCurrency(value.startValue)} até R$ ${formatCurrency(value.endValue)}`,
    isFullValue: () => true,
  },
  [TYPES.NUMBER]: {
    getPopoverComponent: () => getComponent('DataGridAdvancedFilterNumberPopover'),
    getFormattedValue: value => getNumberTitle(value.startValue, value.endValue),
    isFullValue: () => true,
  },
};

export function getTypeService(type) {
  return TYPE_SERVICES[type];
}
