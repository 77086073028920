<template>
  <div class="ds-option" :class="classes" @click="select">
    <button type="button" class="ds-input ds-u-text-left" tabindex="-1">
      <component
        :is="component"
        ref="option"
        :key="$vnode.key"
        :get-data="getData"
        v-bind="$attrs"
        :disabled="disabled"
        @option-selected="onOptionSelected"
        v-on="$listeners">
        <slot></slot>
      </component>
    </button>
  </div>
</template>

<script>
import isNil from 'lodash/isNil';
import { getSiblings, addClass, removeClass } from '@core/services/dom/domService';
import DsSelectOption from '@components/select-option';
import DsMultipleSelectOption from '@components/multiple-select-option';

const OPTION_SELECT_ALL = 'ds-option--select-all';

export default {
  name: 'DsOption',
  props: {
    disabled: Boolean,
  },
  inject: {
    addOption: {
      default: null,
    },
    removeOption: {
      default: null,
    },
    selectType: {
      default: 'default',
    },
    selectVm: {
      default: null,
    },
  },
  provide() {
    return {
      getData: this.getData,
    };
  },
  computed: {
    component() {
      return {
        default: DsSelectOption,
        multiple: DsMultipleSelectOption,
      }[this.selectType];
    },
    classes() {
      return {
        'ds-option--disabled': this.disabled,
      };
    },
  },
  beforeDestroy() {
    this.removeOption(this.getData());
  },
  created() {
    if (isNil(this.$vnode.key)) {
      throw new Error('You must inform an option unique key');
    }

    this.$on('keydown.enter', this.select);
  },
  mounted() {
    this.$nextTick(() => {
      this.addOption(this.getData());
      this.$forceUpdate();
    });
  },
  methods: {
    getData() {
      return {
        label: this.getLabel(),
        value: this.$attrs.value,
        parent: this.$attrs.parent,
        key: this.$vnode.key,
        vm: this,
      };
    },
    select(...payload) {
      if (this.disabled) {
        return null;
      }

      this.$nextTick(() => {
        this.$emit('option-clicked');

        this.selectVm.onOptionClick();
      });
      return this.$refs.option.select(...payload);
    },
    hover() {
      this.configActivationElementsState();
    },
    getLabel() {
      return this.$el && this.$el.textContent.trim();
    },
    configActivationElementsState() {
      configActivationElementsState(this.$el);
    },
    onOptionSelected(...payload) {
      if (this.$vnode.key === OPTION_SELECT_ALL) {
        return;
      }
      this.configActivationElementsState();
      this.$nextTick(() => {
        this.$emit('option-selected', ...payload);
      });
    },
  },
};

function configActivationElementsState(element) {
  activateElement(element);
  inactivateElementSiblings(element);
}

function activateElement(element) {
  addClass(element, getActiveCSSClass());
}

function inactivateElementSiblings(element) {
  getSiblings(element).forEach(sibling => {
    removeClass(sibling, getActiveCSSClass());
  });
}

function getActiveCSSClass() {
  return 'active';
}
</script>

<style scoped>
@import './Option.css';
</style>
