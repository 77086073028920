<template>
  <div v-if="loading" class="ds-virtual-list-loader">
    <ds-loader />
  </div>
  <div v-else-if="alert">
    <ds-box-information theme="danger" v-bind="alert" hide-close-button>
      <ds-box-information-button @click="alert.onRetry"> Tentar novamente </ds-box-information-button>
    </ds-box-information>
  </div>
  <ds-virtual-list-core v-else :item-count="itemCount" :item-height="itemHeight">
    <template #default="{ index }">
      <div v-if="index > items.length - 1" class="ds-virtual-list-load-more">
        <ds-request-button
          v-if="hasMorePages"
          :request-action="onPageChange"
          @request-success="onPageChangeSuccess"
          @request-error="onPageChangeError"
          >Carregar mais</ds-request-button
        >
      </div>
      <slot v-else-if="items[index]" :item="items[index]" :index="index" />
    </template>
  </ds-virtual-list-core>
</template>
<script>
import { toasterService } from '@components/toaster';
import DsRequestButton from '@components/request-button';
import DsBoxInformation from '@components/box-information';
import DsBoxInformationButton from '@components/box-information-button';
import DsLoader from '@components/loader';
import DsVirtualListCore from './VirtualListCore.vue';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_VALUE = 1;

export default {
  name: 'DsVirtualList',
  components: {
    DsBoxInformation,
    DsLoader,
    DsBoxInformationButton,
    DsVirtualListCore,
    DsRequestButton,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemHeight: DsVirtualListCore.props.itemHeight,
    fetchAction: {
      type: Function,
      required: true,
    },
    buildFetchAlert: {
      type: Function,
      default() {
        return {
          title: 'Os dados não puderam ser carregados',
          message: 'Aguarde alguns instantes e tente novamente',
        };
      },
    },
    pagination: {
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      alert: null,
      filters: this.pagination
        ? {
            pagination: {
              page: this.pagination.page || DEFAULT_PAGE_VALUE,
              pageSize: this.pagination.pageSize || DEFAULT_PAGE_SIZE,
            },
          }
        : {},
    };
  },
  computed: {
    itemCount() {
      const isUsingPagination = Boolean(this.filters.pagination);
      const paginationOffset = Number(isUsingPagination);
      return this.items.length + paginationOffset;
    },
    hasMorePages() {
      const { totalItems, pageSize, page } = this.filters.pagination || {};
      return page < Math.ceil(totalItems / pageSize);
    },
  },
  watch: {
    'pagination.totalItems': {
      handler(totalItems) {
        this.filters.pagination.totalItems = totalItems;
      },
    },
  },
  created() {
    this.handleFetch();
  },
  methods: {
    async handleFetch() {
      try {
        this.loading = true;
        const response = await this.fetchAction(this.filters);
        this.$emit('fetch-success', response);
      } catch (error) {
        this.alert = {
          ...this.buildFetchAlert(error),
          onRetry: this.handleRetry,
        };
        this.$emit('fetch-error', error);
      } finally {
        this.loading = false;
      }
    },
    onPageChange() {
      this.filters.pagination.page += 1;
      return this.fetchAction(this.filters);
    },
    onPageChangeSuccess(data) {
      this.$emit('fetch-success', data);
    },
    onPageChangeError(error) {
      this.filters.pagination.page -= 1;
      const { title, message } = this.buildFetchAlert(error);
      toasterService.error({
        title,
        content: message,
      });
      this.$emit('fetch-error', error);
    },
    handleRetry() {
      this.alert = null;
      this.handleFetch();
    },
  },
};
</script>
<style scoped>
@import './VirtualList.css';
</style>
