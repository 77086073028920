<template>
  <ds-page>
    <ds-action-bar v-if="shouldShowActions" :show-pagination-on-top="showPaginationOnTop">
      <ds-data-grid-create-actions
        :create-action="createAction"
        :create-actions="createActions"
        :create-action-disabled="createActionDisabled"
        :create-action-label="createActionLabel"
        :create-action-tooltip="createActionTooltip" />
      <slot slot="actions" name="actions" />
      <slot slot="feedback" name="feedback" />
      <slot slot="right-action-on-top" name="right-action-on-top" />
    </ds-action-bar>
    <slot />
  </ds-page>
</template>

<script>
import { debug } from '@core';
import DsPage from '@components/page';
import DsActionBar from '@components/layout/action-bar';
import DsDataGridCreateActions from '@components/data-grid/DataGridHeaderCreateActions.vue';
import { getChildrenFromSlot, isComponent, isText } from '@core/services/vnode/vnodeService';
import { createStore } from '@components/data-grid/store';

export default {
  name: 'DsDataGridTemplate',
  components: {
    DsPage,
    DsActionBar,
    DsDataGridCreateActions,
  },
  provide() {
    return {
      dataGridTemplate: {
        store: this.store,
      },
    };
  },
  props: {
    showPaginationOnTop: {
      type: Boolean,
    },
    createAction: DsDataGridCreateActions.props.createAction,
    createActionDisabled: DsDataGridCreateActions.props.createActionDisabled,
    createActionLabel: DsDataGridCreateActions.props.createActionLabel,
    createActionTooltip: DsDataGridCreateActions.props.createActionTooltip,
    createActions: DsDataGridCreateActions.props.createActions,
  },
  data() {
    return {
      store: createStore(),
    };
  },
  computed: {
    shouldShowActions() {
      return (
        this.createActions ||
        this.createAction ||
        this.$slots.actions ||
        this.$slots.feedback ||
        this.$slots['right-action-on-top'] ||
        this.showPaginationOnTop
      );
    },
  },
  watch: {
    showPaginationOnTop: {
      immediate: true,
      handler(value) {
        this.store.setPaginationOnTop(value);
      },
    },
  },
  created() {
    this.validateActions();
  },
  methods: {
    validateActions() {
      getChildrenFromSlot(this.$slots.actions || []).forEach(vnode => {
        if (isText(vnode) || !isComponent(vnode, 'DsDataGridAction')) {
          debug.error('All action must be a ds-data-grid-action');
        }
      });
    },
  },
};
</script>
