<template>
  <component
    :is="buttonComponent"
    class="ds-box-information__button"
    size="sm"
    :request-action="requestAction"
    @click="handleClick"
    @request-success="handleSuccess"
    @request-error="handleError">
    <slot />
  </component>
</template>

<script>
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import DsRequestButton from '@components/request-button';
import DsButton from '@components/button';

export default {
  name: 'DsBoxInformationButton',
  components: {
    DsRequestButton,
    DsButton,
  },
  props: {
    /**
     * DEPRECATED PROP | Use @click event instead
     */
    onClick: {
      type: Function,
    },
    requestAction: {
      type: Function,
    },
  },
  computed: {
    buttonComponent() {
      return this.requestAction ? 'DsRequestButton' : 'DsButton';
    },
  },
  created() {
    const deprecatedDependency = createDeprecation(this);

    if (this.onClick) {
      deprecatedDependency.deprecateProperty('onClick', 'Use @click event instead');
    }
  },
  methods: {
    handleClick(event) {
      if (this.onClick) {
        this.onClick();
      }

      this.$emit('click', event);
    },
    handleSuccess(response) {
      this.$emit('request-success', response);
    },
    handleError(error) {
      this.$emit('request-error', error);
    },
  },
};
</script>
