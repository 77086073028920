<template>
  <div class="ds-input__container">
    <input
      ref="input"
      :class="inputClasses"
      :value="value"
      :required="required"
      :readonly="readonly"
      :disabled="disabled"
      :type="type"
      class="ds-input ds-form-control"
      v-bind="$attrs"
      v-on="listeners" />
    <ds-input-icons>
      <ds-icon v-if="chevronIcon" icon="chevron-down" size="sm" />
      <ds-suggestion-button
        v-if="shouldShowSuggestionButton"
        :is-suggestion-accepted="isSuggestionAccepted"
        @click="onApplySuggestion" />
    </ds-input-icons>
  </div>
</template>

<script>
import { inputMaskService, modelValidationService, focusMixin } from '@core';
import DsSuggestionButton from '@components/suggestion-button/SuggestionButton.vue';
import DsInputIcons from '@components/input-icons/InputIcons.vue';
import DsIcon from '@components/icon';

export default {
  name: 'DsInput',
  components: {
    DsInputIcons,
    DsSuggestionButton,
    DsIcon,
  },
  mixins: [focusMixin.focus('input')],
  inheritAttrs: false,
  inject: {
    fieldVm: {
      default: null,
    },
    formVm: {
      default: null,
    },
  },
  props: {
    onBlur: Function,
    onChange: Function,
    maskPattern: String,
    value: {
      type: [Number, String],
      default: '',
    },
    required: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    suggestion: {
      type: [String, Number],
      default: null,
    },
    customValidations: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'text',
      validator(type) {
        return !type || ['text', 'number', 'email', 'password', 'file'].includes(type);
      },
    },
    chevronIcon: Boolean,
  },
  data() {
    const data = {
      modelValidator: modelValidationService.buildModelValidation(this),
    };
    if (this.type === 'file') {
      data.files = [];
    }
    return data;
  },
  computed: {
    isValidSuggestion() {
      return this.suggestion !== null;
    },
    isSuggestionAccepted() {
      return this.isValidSuggestion && this.suggestion === this.value;
    },
    shouldShowSuggestionButton() {
      return this.isValidSuggestion && !this.disabled;
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInputNative,
        blur: this.onInputBlur,
      };
    },
    inputClasses() {
      const { cssClass } = this.modelValidator;
      const suggestionOfferedClass = 'ds-input--suggestion-offered';
      const suggestionAcceptedClass = 'ds-input--suggestion-accepted';
      return {
        [suggestionOfferedClass]: !!this.suggestion,
        [suggestionAcceptedClass]: this.isSuggestionAccepted && this.shouldShowSuggestionButton,
        [cssClass]: !!cssClass,
      };
    },
  },
  watch: {
    value(value) {
      this.onInputChange(value);
      this.updateMaskElement();
    },
  },
  mounted() {
    this.configMask();
    this.initSuggestionValue();
  },
  methods: {
    configMask() {
      inputMaskService.apply(this.$refs.input, this.maskPattern, this.onInput);
    },
    updateMaskElement() {
      this.$nextTick(() => {
        if (this.$refs.input) {
          inputMaskService.update(this.$refs.input, this.maskPattern, this.value);
          this.onInputNative();
        }
      });
    },
    onInputBlur(event) {
      this.$emit('blur', event);
      if (this.onBlur) this.onBlur(event.target.value);
      this.updateMaskElement();
    },
    onInputChange(value) {
      this.$emit('change', value);
      if (this.onChange) this.onChange(value);
    },
    onInput(value) {
      this.$emit('input', value);
    },
    onInputNative() {
      // more information in https://github.com/ContaAzul/ca-design-system/issues/98
      this.$refs.input.dispatchEvent(new Event('input-native'));
    },
    setFiles(fileList) {
      if (!fileList?.length) {
        this.$refs.input.value = '';
      }

      this.files = fileList;
    },
    initSuggestionValue() {
      if (this.isValidSuggestion && !this.value) {
        this.onApplySuggestion();
      }
    },
    onApplySuggestion() {
      this.onInput(this.suggestion);
      this.$emit('suggestion-applied', this.suggestion);
    },
    validate() {
      this.$nextTick(() => {
        this.modelValidator.validate();
      });
    },
  },
};
</script>

<style scoped>
@import './Input.css';
</style>
