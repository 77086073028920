<template>
  <li class="ds-list-item" :class="themeClass">
    <ds-icon v-if="isCheckedTheme" class="ds-u-margin-right--sm" size="sm" icon="check" />
    <slot></slot>
  </li>
</template>

<script>
import DsIcon from '@components/icon';

export default {
  name: 'DsListItem',
  inject: ['theme'],
  components: {
    DsIcon,
  },
  computed: {
    isCheckedTheme() {
      return this.theme === 'check' || this.theme === 'check-grey';
    },
    themeClass() {
      const itemsTheme = this.theme.includes('check') ? 'check' : this.theme;
      return `ds-list-${itemsTheme}-item`;
    },
  },
};
</script>
