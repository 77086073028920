import Vue from 'vue';
import { createFileUpload } from '@core/services/fileUpload/fileUploadService';

export function createContext(options) {
  const context = Vue.observable({
    id: options.id,
    name: options.name,
    files: {},
    listeners: {},
    options: options.options,
    title: options.title,
  });

  let fileUpload;

  function setFileAttribute(id, attr, value) {
    Vue.set(context.files[id], attr, value);
  }

  function getFileById(id) {
    return context.files[id];
  }

  function dispatchListener(name, ...props) {
    if (context.listeners[name]) {
      context.listeners[name](...props);
    }
  }

  function setFileUploadEventListeners() {
    fileUpload.onFileRemoved(file => {
      Vue.delete(context.files, file.id);
      dispatchListener('onFileRemoved', file);
    });

    fileUpload.onFileAdded(file => {
      Vue.set(context.files, file.id, {
        id: file.id,
        name: file.name,
        percentage: 0,
        progress: file.progress,
        isDone: false,
        isFailed: false,
        canCancel: () => !getFileById(file.id).isDone,
        cancel: () => fileUpload.removeFile(file.id),
        canRetry: () => getFileById(file.id).isFailed,
        retry: () => fileUpload.retryFile(file.id),
      });

      dispatchListener('onFileAdded', file);
    });

    fileUpload.onUploadProgress((file, progress) => {
      setFileAttribute(file.id, 'progress', file.progress);
      setFileAttribute(file.id, 'percentage', progress.percentage);
      dispatchListener('onUploadProgress', file, progress);
    });

    fileUpload.onFileUploaded(file => {
      setFileAttribute(file.id, 'isDone', true);
      dispatchListener('onFileUploaded', file);
    });

    fileUpload.onUploadError((file, error, response) => {
      setFileAttribute(file.id, 'isFailed', true);
      dispatchListener('onUploadError', file, error, response);
    });

    fileUpload.onUploadRetry(fileId => {
      setFileAttribute(fileId, 'isFailed', false);
      dispatchListener('onUploadRetry', fileId);
    });

    fileUpload.onRestrictionFailed((file, error) => {
      dispatchListener('onRestrictionFailed', file, error);
      dispatchListener('onRestrictionFailedInternal', error);
    });
  }

  return {
    get id() {
      return context.id;
    },
    get name() {
      return context.name;
    },
    get title() {
      return context.title;
    },
    get files() {
      return context.files;
    },
    get pendingFiles() {
      return Object.values(context.files).filter(file => !file.isDone);
    },
    get options() {
      return context.options;
    },
    addFiles(files) {
      fileUpload.addFiles(files);
    },
    createFileUpload({ el }) {
      if (!fileUpload) {
        fileUpload = createFileUpload({
          el,
          endpoint: options.endpoint,
          headers: options.headers,
          options: options.options,
        });

        setFileUploadEventListeners();
      } else {
        fileUpload.setTargetEl(el);
      }
    },
    close() {
      if (fileUpload) {
        fileUpload.destroy();
        fileUpload = null;
        dispatchListener('onClose');
        context.files = {};
        context.listeners = {};
      }
    },
    subscribe(listeners = {}) {
      context.listeners = { ...context.listeners, ...listeners };
    },
  };
}
