<template>
  <div class="ds-pagination-size ds-u-print-hidden">
    <ds-select required class="ds-pagination-size__select" :value="value" v-on="$listeners">
      <ds-option v-for="size in pageSizes" :key="size" :value="size">
        {{ size }}
      </ds-option>
    </ds-select>
    <span class="ds-pagination-size__label">Registros por página</span>
  </div>
</template>

<script>
import DsSelect from '@components/select';
import DsOption from '@components/option';

export default {
  name: 'DsPaginationSize',
  components: {
    DsSelect,
    DsOption,
  },
  props: {
    value: {
      type: Number,
    },
    pageSizes: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style scoped>
@import './PaginationSize.css';
</style>
