<template>
  <component
    :is="tableRowComponent"
    :key="$vnode.key"
    class="ds-data-grid-tr"
    :not-hoverable="!isHoverable"
    :disable-all-actions="disableAllActions"
    v-bind="$attrs"
    :row="item"
    :is-collapse="isCollapse"
    :is-nested="isNested"
    :disabled="disabled"
    :disabled-batch-tooltip="disabledBatchTooltip"
    v-on="listeners"
    @click="onRowClick">
    <slot></slot>
    <ds-td v-if="dataGridTableVm.showActions" align="right" class="ds-data-grid-tr__actions-td ds-u-print-hidden">
      <ds-data-grid-actions
        v-if="!disableAllActions"
        :key="$vnode.key"
        :item-attributes="itemAttributes"
        :confirm-title="confirmTitle"
        :confirm-button-label="confirmButtonLabel"
        :confirm-question="confirmQuestion"
        :call-to-action="callToAction"
        :custom-actions="customActions"
        :remove-action="remove"
        :edit-action="edit"
        :item="item"
        :item-key="$vnode.key"
        @remove-success="onRemoveSuccess"
        @remove-error="onRemoveError">
        <template #confirm-exclusion-content>
          <slot name="confirm-exclusion-content" />
        </template>
      </ds-data-grid-actions>
    </ds-td>
    <slot slot="nested" name="nested"></slot>
  </component>
</template>

<script>
import { debug, vnodeService } from '@core';
import { createDeprecation } from '@core/services/deprecateDependency/deprecateDependencyService';
import DsTd from '@components/table-column';
import DsTr from '@components/table-row';
import DsBatchTableTr from '@components/batch-table-tr';
import DsTableRowCollapse from '@components/table-row-collapse';
import DsTrNested from '@components/table-row-nested';
import DsDataGridActions from './DataGridActions.vue';

export default {
  name: 'DsDataGridTr',
  components: {
    DsTd,
    DsBatchTableTr,
    DsDataGridActions,
  },
  inject: {
    batchTableVm: {
      default: null,
    },
    dataGridTableVm: {
      default: {},
    },
  },
  props: {
    item: {
      type: Object,
    },
    confirmTitle: {
      type: String,
    },
    confirmButtonLabel: {
      type: String,
    },
    confirmQuestion: {
      type: String,
    },
    callToAction: {
      type: Object,
    },
    customActions: {
      type: Array,
      default: () => [],
    },
    /**
     * DEPRECATED PROP | Use edit-action props instead
     */
    onEdit: Function,
    editAction: Function,
    /**
     * DEPRECATED PROP | Use remove-action props instead
     */
    onRemove: Function,
    removeAction: Function,
    /**
     * DEPRECATED PROP | Use view-action props instead
     */
    onView: Function,
    viewAction: Function,
    disableAllActions: {
      type: Boolean,
      default: false,
    },
    isCollapse: {
      type: Boolean,
      default: undefined,
    },
    isNested: Boolean,
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledBatchTooltip: String,
  },
  data() {
    return {
      itemAttributes: [],
    };
  },
  computed: {
    isHoverable() {
      return !!this.view;
    },
    tableRowComponent() {
      if (this.batchTableVm) {
        return DsBatchTableTr;
      }

      if (this.isNested) {
        return DsTrNested;
      }

      if (this.dataGridTableVm.isCollapse && this.isCollapse !== false) {
        return DsTableRowCollapse;
      }

      return DsTr;
    },
    hasActions() {
      return this.customActions.length || this.edit || this.remove;
    },
    view() {
      return this.viewAction || this.onView;
    },
    edit() {
      return this.editAction || this.onEdit;
    },
    remove() {
      return this.removeAction || this.onRemove;
    },
    listeners() {
      return {
        ...this.$listeners,
        collapse: this.onCollapse,
      };
    },
  },
  created() {
    if (this.hasActions && !this.dataGridTableVm.showActions) {
      debug.error(
        'You have informed a row action but data-grid is not showing action column. Set show-actions data-grid property to true if you want to show actions column.',
      );
    }

    const deprecatedDependency = createDeprecation(this);

    if (this.onView) {
      deprecatedDependency.deprecateProperty('onView', 'Use view-action instead');
    }
    if (this.onEdit) {
      deprecatedDependency.deprecateProperty('onEdit', 'Use edit-action instead');
    }
    if (this.onRemove) {
      deprecatedDependency.deprecateProperty('onRemove', 'Use remove-action instead');
    }
  },
  mounted() {
    this.validateKey();
    this.validateActions();
    this.setItemAttributes(this.buildItemAttributes(this.dataGridTableVm.columns));
  },
  methods: {
    getColumns() {
      return this.$slots.default.filter(vnode => vnodeService.isComponent(vnode, 'DsTd') && vnode.componentInstance);
    },
    getColumnByIndex(index) {
      return this.getColumns()[index];
    },
    buildItemAttributes(columns) {
      return columns.map((column, index) => ({
        label: column.name,
        value: this.getColumnByIndex(index)?.elm?.textContent,
      }));
    },
    setItemAttributes(itemAttributes) {
      this.itemAttributes = itemAttributes;
    },
    onRowClick({ target }) {
      if (this.view && target.tagName !== 'A' && target.tagName !== 'BUTTON') {
        this.view(this.item);
      }
    },
    onRemoveSuccess() {
      this.dataGridTableVm.$emit('item-removed', this.$vnode.key);
    },
    onRemoveError(error = new Error()) {
      this.dataGridTableVm.$emit('item-removal-failed', error);
    },
    onCollapse(value) {
      this.$emit('collapse', value);
    },
    validateKey() {
      if (this.$vnode.key == null) {
        debug.error(new Error('You must inform a key to data-grid-tr'));
      }
    },
    validateActions() {
      if (this.callToAction && (this.edit || this.remove || this.customActions.length > 0)) {
        debug.error(
          new Error('Edit button, Remove button or Custom actions will not be shown when call to action is passed.'),
        );
      }
    },
  },
};
</script>
