<template>
  <ds-date-filter
    class="ds-date-period-filter"
    :disabled="handleDisabled"
    @previous="onClickPrevious"
    @next="onClickNext">
    <ds-date-period-dropdown
      :tooltip="tooltipValue"
      class="ds-date-period-filter__dropdown"
      :value="localValue"
      :disabled="disabled"
      :period-type="periodType || defaultPeriodType"
      :period-types="periodTypes"
      :min-date="minDate"
      :max-date="maxDate"
      :max-date-range="maxDateRange"
      :period-popover-helper-text="periodPopoverHelperText"
      @input="emitInput" />
  </ds-date-filter>
</template>

<script>
import DsDateFilter from '@components/date-filter/DateFilter.vue';
import DsDatePeriodDropdown from '@components/date-period-dropdown';
import { debounceService } from '@core';
import { DEBOUNCE_TIME } from '@core/constants/debounce';
import {
  getPreviousPeriod,
  getNextPeriod,
  getDefaultPeriodType,
  getPeriodTypesWithoutNavigation,
  getPeriodTypeByValue,
  getPeriodTypeByValues,
} from './datePeriodFilterService';

export default {
  name: 'DsDatePeriodFilter',
  provide: {
    hideClearButton: true,
  },
  components: {
    DsDateFilter,
    DsDatePeriodDropdown,
  },
  props: {
    /**
     * { startDate, endDate }
     */
    disabled: Boolean,
    value: DsDatePeriodDropdown.props.value,
    minDate: DsDatePeriodDropdown.props.minDate,
    maxDate: DsDatePeriodDropdown.props.maxDate,
    maxDateRange: DsDatePeriodDropdown.props.maxDateRange,
    periodPopoverHelperText: DsDatePeriodDropdown.props.periodPopoverHelperText,
    /**
     * @values today, this-week, this-month, this-year, last-thirty-days, last-year, all-period, custom-period
     */
    periodType: DsDatePeriodDropdown.props.periodType,
    periodTypes: DsDatePeriodDropdown.props.periodTypes,
    debounce: {
      type: Number,
      default: DEBOUNCE_TIME,
      validator: debounceService.debounceValidateTime,
    },
  },
  data() {
    return {
      selectedPeriodType: null,
      defaultPeriodType: this.value ? this.periodType : getDefaultPeriodType(),
      localValue: null,
    };
  },
  computed: {
    handleDisabled() {
      return this.disabled || getPeriodTypesWithoutNavigation().includes(this.selectedPeriodType);
    },
    tooltipValue() {
      return this.disabled ? '' : 'Clique aqui para selecionar outro período';
    },
  },
  watch: {
    localValue(localValue) {
      this.handlePeriodType(localValue);
      if (localValue !== this.value) {
        this.emitInputDebounced(localValue);
      }
    },
    value(value) {
      if (value !== this.localValue) {
        this.localValue = value;
      }
    },
  },
  mounted() {
    if (this.value?.startDate || this.value?.endDate) {
      this.localValue = this.value;
    }
  },
  created() {
    this.emitInputDebounced = debounceService.debounce(this.emitInput, this.debounce);
  },
  methods: {
    emitInput(value, periodType) {
      if (periodType) {
        this.setSelectedPeriodType(periodType);
      }
      this.$emit('input', value, periodType);
    },
    onClickPrevious() {
      this.setLocalValue(getPreviousPeriod(this.localValue, this.selectedPeriodType));
    },
    onClickNext() {
      this.setLocalValue(getNextPeriod(this.localValue, this.selectedPeriodType));
    },
    setLocalValue(value, periodType) {
      if (periodType) {
        this.setSelectedPeriodType(periodType);
      }
      this.localValue = value;
    },
    setSelectedPeriodType(periodType) {
      this.selectedPeriodType = periodType;
    },
    handlePeriodType(value) {
      const newPeriodType = getPeriodTypeByValues(value);
      if (newPeriodType) this.setSelectedPeriodType(newPeriodType);
    },
    /**
     * Deprecated - Updates arrows navigation behavior
     *
     * @public
     * @param {"year" | "month" | "custom"} type
     */
    setArrowsNavigationBy(type) {
      const periodType = getPeriodTypeByValue(type);
      this.setSelectedPeriodType(periodType);
    },
  },
};
</script>

<style scoped>
@import './DatePeriodFilter.css';
</style>
