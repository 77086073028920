<template>
  <div class="ds-radio-group" role="radiogroup" :class="classes">
    <slot />
  </div>
</template>

<script>
import { generateId } from '@core/services/id/idService';
import { buildModelValidation } from '@core/services/modelValidation/modelValidationService';

export default {
  name: 'DsRadioGroup',
  provide() {
    return {
      radioGroupVm: this.radioGroupVm,
    };
  },
  inject: {
    fieldVm: {
      default: null,
    },
    formVm: {
      default: null,
    },
  },
  props: {
    required: Boolean,
    value: [String, Number, Boolean],
    onChange: Function,
    disabled: Boolean,
    direction: {
      type: String,
      default: 'vertical',
      validator(direction) {
        return ['vertical', 'horizontal'].includes(direction);
      },
    },
  },
  data() {
    return {
      modelValidator: buildModelValidation(this),
      radioGroupVm: {
        id: generateId(),
        disabled: this.disabled,
        valueModel: this.value,
        onChange: this.onRadioSelected,
        onClick: this.onRadioClicked,
      },
    };
  },
  computed: {
    classes() {
      return {
        [`ds-radio-group--${this.direction}`]: this.direction,
      };
    },
  },
  watch: {
    value(value) {
      this.onChangeValue(value);
      this.radioGroupVm.valueModel = value;
    },
    disabled(disabled) {
      this.radioGroupVm.disabled = disabled;
    },
  },
  methods: {
    onChangeValue(value) {
      this.$emit('change', value);

      if (this.onChange) {
        this.onChange(value);
      }
    },
    onRadioSelected(value) {
      this.$emit('input', value);
    },
    onRadioClicked(value) {
      this.$emit('click', value);
    },
  },
};
</script>

<style scoped>
@import './RadioGroup.css';
</style>
