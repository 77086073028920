<template>
  <div class="ds-step">
    <ds-row class="ds-step__content">
      <ds-col>
        <slot></slot>
      </ds-col>
    </ds-row>
    <ds-footer v-if="!hideButtons" justify="space-between">
      <slot :back="back" name="left-button">
        <ds-request-button v-if="!hideBackButton" :disabled="disableBackButton" :request-action="back" full-width>
          {{ labelBackButton }}
        </ds-request-button>
      </slot>
      <template #content>
        <slot name="footer-content" />
      </template>
      <template #right>
        <slot :next="next" name="right-button">
          <ds-request-button
            v-if="!hideNextButton"
            :disabled="disableNextButton"
            :request-action="next"
            theme="primary"
            full-width>
            {{ labelNextButton }}
          </ds-request-button>
        </slot>
      </template>
    </ds-footer>
  </div>
</template>

<script>
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsFooter from '@components/footer';
import DsRequestButton from '@components/request-button';

export default {
  name: 'DsStep',
  components: {
    DsRequestButton,
    DsRow,
    DsCol,
    DsFooter,
  },
  props: {
    nextAction: {
      type: Function,
      default: () => {},
    },
    backAction: {
      type: Function,
      default: () => {},
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: String,
    labelBackButton: String,
    labelNextButton: String,
    disableNextButton: Boolean,
    disableBackButton: Boolean,
    hideButtons: Boolean,
    hideBackButton: Boolean,
    hideNextButton: Boolean,
  },
  methods: {
    back() {
      return Promise.resolve()
        .then(this.backAction)
        .then(() => this.$emit('back'));
    },
    next() {
      return Promise.resolve()
        .then(this.nextAction)
        .then(() => this.$emit('next'));
    },
  },
};
</script>
