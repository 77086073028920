import ROLLOVER_CONSTANTS from './rolloverConstants';
import { MODAL_CLASSES } from '../../constants/modal';

const { OPENED_CLASS, MAIN_CLASS, BODY_CLASS, ACTIVE_CLASS } = ROLLOVER_CONSTANTS.ROLLOVER_CLASSES;

const {
  ON_BEFORE_ROLLOVER_OPENED,
  ON_ROLLOVER_OPENED,
  ON_BEFORE_ROLLOVER_CLOSED,
  ON_ROLLOVER_CLOSED,
  ON_ALL_ROLLOVERS_CLOSED,
} = ROLLOVER_CONSTANTS.ROLLOVER_HOOKS;

const customHooks = {};
let refocusTarget;

export function getRolloverConstants(key) {
  return key ? ROLLOVER_CONSTANTS[key] : ROLLOVER_CONSTANTS;
}

export function onOpenRollover(el) {
  setRefocusTarget(document.activeElement);
  focusChildrenElement(el);
  setTimeout(() => el.classList.add(OPENED_CLASS));
}

export function onCloseRollover(el) {
  focusElement(refocusTarget, { preventScroll: true });
  el.classList.remove(OPENED_CLASS);
}

function getTransitionDuration(el) {
  return parseFloat(getComputedStyle(el).transitionDuration) * 1000;
}

export function setupOpeningHooks(configs) {
  if (!configs.el) {
    return;
  }

  const transitionDuration = getTransitionDuration(configs.el);

  onBeforeRolloverOpened();

  setTimeout(() => {
    onRolloverOpened(configs);
  }, transitionDuration);
}

export function setupClosingHooks(configs) {
  if (!configs.el) {
    return;
  }

  const transitionDuration = getTransitionDuration(configs.el);

  onBeforeRolloverClosed();

  setTimeout(() => {
    onRolloverClosed(configs);
  }, transitionDuration);
}

function onBeforeRolloverOpened() {
  processHookGroup(customHooks, ON_BEFORE_ROLLOVER_OPENED);
}

function onBeforeRolloverClosed() {
  processHookGroup(customHooks, ON_BEFORE_ROLLOVER_CLOSED);
}

function onRolloverOpened(configs) {
  addBodyClass();
  handleActiveRolloverClass();
  runRolloverHooks(configs, ON_ROLLOVER_OPENED);
}

function handleActiveRolloverClass() {
  document.querySelectorAll(`.${MAIN_CLASS}`).forEach((rolloverElement, index, array) => {
    rolloverElement.classList.remove(ACTIVE_CLASS);

    if (index === array.length - 1) {
      rolloverElement.classList.add(ACTIVE_CLASS);
    }
  });
}

function focusElement(el, options = {}) {
  if (el && el.focus) {
    el.focus(options);
  }
}

function focusChildrenElement(rolloverEl) {
  const childrenElement = rolloverEl.querySelector(`.${ROLLOVER_CONSTANTS.ROLLOVER_CLASSES.FOCUS_ELEMENT_CLASS}`);
  focusElement(childrenElement);
}

function setRefocusTarget(el) {
  refocusTarget = el;
}

function onRolloverClosed(configs) {
  removeGarbageInDom(configs.el);
  removeBodyClass();
  handleActiveRolloverClass();
  runRolloverHooks(configs, ON_ROLLOVER_CLOSED);
  runOnAllRolloversClosedHookWhenItApplies(configs);
}

function addBodyClass() {
  document.body.classList.add(BODY_CLASS);
}

function removeBodyClass() {
  if (!isSomeRolloverOpened()) {
    document.body.classList.remove(BODY_CLASS);
  }
}

function removeGarbageInDom(el) {
  if (typeof el.remove === 'function') {
    el.remove();
  }
}

function runRolloverHooks(configs, hookName) {
  const { globalHooks, localHooks, vueComponent } = configs;
  processHookGroup(globalHooks, hookName, vueComponent);
  processHookGroup(localHooks, hookName, vueComponent);
  processHookGroup(customHooks, hookName, vueComponent);
}

export function addRolloverHook(hookName, callback) {
  if (!customHooks[hookName]) {
    customHooks[hookName] = [];
  }
  const hookList = customHooks[hookName];
  hookList.push(callback);

  return () => {
    removeItemFromList(callback, hookList);
  };
}

function removeItemFromList(item, list) {
  const itemIndex = list.indexOf(item);
  if (itemIndex > -1) {
    list.splice(itemIndex, 1);
  }
}

export function processHookGroup(rolloverHooks, hookName, vueComponent) {
  if (!rolloverHooks || !hookName) {
    return;
  }
  const hookActions = rolloverHooks[hookName];

  if (Array.isArray(hookActions)) {
    hookActions.forEach(hook => runRolloverHook(hook, vueComponent));
  } else {
    runRolloverHook(hookActions, vueComponent);
  }
}

function runRolloverHook(hook, vueComponent) {
  if (typeof hook === 'function') {
    hook(vueComponent);
  }
}

function runOnAllRolloversClosedHookWhenItApplies(configs) {
  setTimeout(() => {
    if (!isSomeRolloverOpened()) {
      const configObj = { ...configs };
      configObj.vueComponent = undefined;
      runRolloverHooks(configObj, ON_ALL_ROLLOVERS_CLOSED);
    }
  });
}

export function isSomeRolloverOpened() {
  return document.getElementsByClassName(MAIN_CLASS).length > 0;
}

export function isCurrentRollover(el) {
  const allRolloversEl = document.getElementsByClassName(MAIN_CLASS);
  const { length } = allRolloversEl;

  return length > 0 && allRolloversEl[length - 1] === el;
}

export function hasOpenModal() {
  return !!document.getElementsByClassName(MODAL_CLASSES.MAIN_CLASS).length;
}
