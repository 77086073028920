<template>
  <div class="ds-options__footer-item" :class="{ 'ds-u-padding--none': noPadding }">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'DsSelectOptionsFooterItem',
  props: {
    noPadding: Boolean,
  },
};
</script>
