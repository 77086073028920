<template>
  <div ref="widgetGroup" class="ds-widget-group">
    <div class="ds-widget-sizer"></div>
    <div class="ds-widget-gutter-sizer"></div>
    <slot />
  </div>
</template>

<script>
import { createWidgetGroup } from './WidgetGroupService';

export default {
  name: 'DsWidgetGroup',
  provide() {
    return {
      widgetGroupVm: {
        refresh: this.refresh,
      },
    };
  },
  data() {
    return {
      widgetGroup: null,
    };
  },
  mounted() {
    this.widgetGroup = createWidgetGroup(this.$refs.widgetGroup, {
      itemSelector: '.ds-widget',
      columnWidth: '.ds-widget-sizer',
      gutter: '.ds-widget-gutter-sizer',
      percentPosition: true,
      horizontalOrder: true,
    });
  },
  methods: {
    async refresh() {
      await this.$nextTick();
      this.widgetGroup.refresh();
    },
  },
};
</script>
