<template>
  <component
    :is="component"
    ref="button"
    v-ds-tooltip="tooltip"
    :role="role"
    :href="href"
    :type="isLink ? null : type"
    :class="classes"
    :disabled="disabled"
    v-on="$listeners">
    <ds-icon v-if="shouldShowLeftIcon" class="ds-button-icon ds-button-icon--left" :size="size" :icon="icon" />
    <slot></slot>
    <ds-icon v-if="shouldShowRightIcon" class="ds-button-icon ds-button-icon--right" :size="size" :icon="icon" />
  </component>
</template>

<script>
import { debug, autofocusService, focusMixin } from '@core';
import DsIcon from '@components/icon';
import DsTooltip from '@directives/tooltip';

export default {
  name: 'DsButton',
  inject: {
    isIconOnly: {
      default: false,
    },
  },
  directives: {
    DsTooltip,
  },
  components: {
    DsIcon,
  },
  mixins: [focusMixin.focus('button')],
  props: {
    tooltip: {
      type: String,
    },
    autofocus: {
      type: Boolean,
    },
    href: {
      type: String,
    },
    icon: {
      type: [Array, String],
    },
    /**
     * @values right, left
     */
    iconPosition: {
      type: String,
      default: 'right',
      validator(size) {
        return ['right', 'left'].includes(size);
      },
    },
    /**
     * @values lg, md, sm
     */
    size: {
      type: String,
      default: 'md',
      validator(size) {
        return ['lg', 'md', 'sm'].includes(size);
      },
    },
    /**
     * @values default, primary, secondary, warning, danger, link
     */
    theme: {
      type: String,
      default: 'default',
      validator(theme) {
        return !theme || ['default', 'primary', 'secondary', 'warning', 'danger', 'link'].includes(theme);
      },
    },
    /**
     * @values button, submit, reset
     */
    type: {
      type: String,
      default: 'button',
      validator(type) {
        return !type || ['button', 'submit', 'reset'].includes(type);
      },
    },
    fullWidth: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        'ds-button',
        {
          [`ds-button-${this.theme}`]: this.theme,
          [`ds-button-${this.size}`]: this.size,
          'ds-u-width--full': this.fullWidth,
          'ds-button--is-square': this.isIconButton,
          'ds-button--with-icon': this.icon && !this.isIconButton,
        },
      ];
    },
    isIconButton() {
      return this.isIconOnly || (this.icon && !this.$slots.default?.length);
    },
    role() {
      return this.isLink ? 'button' : null;
    },
    isLink() {
      return this.href;
    },
    component() {
      return this.isLink ? 'a' : 'button';
    },
    shouldShowLeftIcon() {
      return this.icon && this.iconPosition === 'left';
    },
    shouldShowRightIcon() {
      return this.icon && this.iconPosition === 'right';
    },
  },
  created() {
    if (!this.tooltip && this.isIconButton) {
      debug.error('Tooltip is required on icon buttons', this);
    }
  },
  mounted() {
    this.handleAutofocus();
  },
  methods: {
    handleAutofocus() {
      if (this.autofocus) {
        autofocusService.focus(this.$el);
      }
    },
  },
};
</script>

<style>
@import './Button.css';
</style>
