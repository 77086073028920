<template>
  <div class="ds-topbar-item" :class="{ 'ds-topbar-item--active': isActive }">
    <ds-link :href="href" @click="onClick">
      <slot />
    </ds-link>
    <div class="ds-topbar-item__aside">
      <slot name="topbar-aside" />
    </div>
  </div>
</template>

<script>
import { debug } from '@core';
import DsLink from '@components/link';

export default {
  name: 'DsTopbarItem',
  components: {
    DsLink,
  },
  inject: ['topbarVm'],
  provide() {
    return {
      topbarItemVm: {
        key: this.key,
      },
    };
  },
  props: {
    href: DsLink.props.href,
  },
  computed: {
    isActive() {
      return this.topbarVm.getActiveItem().key === this.key;
    },
    key() {
      return this.$vnode.key;
    },
  },
  mounted() {
    if (!this.key) {
      debug.error('Prop "key" is required in DsTopbarItem.');
    }
  },
  methods: {
    onClick() {
      this.activeItemHandler();
      this.$emit('click');
    },
    activeItemHandler() {
      if (this.isActive) {
        this.clearActiveItem();
      } else {
        this.open();
      }
    },
    clearActiveItem() {
      this.topbarVm.setActiveItem(null);
    },
    /**
     * Opens item aside
     *
     * @public
     */
    open() {
      this.topbarVm.setActiveItem(this.key);
    },
  },
};
</script>

<style scoped>
@import './TopbarItem.css';
</style>
