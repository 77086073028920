import Vue from 'vue';
import DsDataGridConfirmContent from './DataGridConfirmContent.vue';

function build(itemAttributes, question, slotContent) {
  const DataGridConfirmContent = buildDataGridConfirmContentConstructor(itemAttributes, question, slotContent);
  const component = new DataGridConfirmContent();
  component.$mount();
  return component.$el;
}

function buildDataGridConfirmContentConstructor(itemAttributes, question, slotContent) {
  return Vue.extend({
    render(h) {
      return h(DsDataGridConfirmContent, {
        props: {
          itemAttributes,
          question,
        },
        scopedSlots: {
          default: () => (slotContent ? h('div', slotContent) : null),
        },
      });
    },
  });
}

export default {
  build,
};
