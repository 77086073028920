<script lang="jsx">
export default {
  name: 'DsFadeTransition',
  functional: true,
  render(h, props) {
    return h(
      'transition',
      {
        ...props.data,
        props: {
          name: 'ds-fade',
          mode: 'out-in',
        },
      },
      props.children,
    );
  },
};
</script>

<style>
@import '../../core/css/variables/transitions.css';

.ds-fade-enter-active,
.ds-fade-leave-active {
  transition: var(--ds-transition--opacity);
}

.ds-fade-enter,
.ds-fade-leave-to {
  opacity: 0;
}
</style>
