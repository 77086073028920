<template>
  <div class="ds-tc-file-upload-multiple" :class="`ds-tc-file-upload-multiple--${size}`">
    <ds-row v-if="shouldShowLoader">
      <ds-col>
        <ds-loader class="ds-tc-file-upload-multiple__loader" theme="inside" />
      </ds-col>
    </ds-row>
    <ds-row v-else>
      <ds-col>
        <ds-drop-zone :on-drop="onDrop" @click="openFileSystem()" :disabled="disabled">
          <ds-icon class="ds-u-margin-right--sm" :icon="['regular', 'arrow-up-from-bracket']" />
          <span> Clique aqui ou arraste arquivos para importar </span>
        </ds-drop-zone>
      </ds-col>
    </ds-row>
    <ds-input
      :disabled="disabled"
      ref="inputFile"
      type="file"
      multiple
      class="ds-u-display--none"
      :accept="acceptedFileTypes"
      @change="onInputFileChange" />
  </div>
</template>

<script>
import DsIcon from '@components/icon';
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsDropZone from '@components/drop-zone';
import DsLoader from '@components/loader';
import DsInput from '@components/input';

export default {
  name: 'DsTcFileUploadMultiple',
  components: {
    DsRow,
    DsCol,
    DsDropZone,
    DsIcon,
    DsLoader,
    DsInput,
  },
  props: {
    acceptedFileTypes: {
      type: String,
      default: '*',
    },
    onUploadFile: {
      type: Function,
      default: () => {},
    },
    size: {
      type: String,
      default: 'md',
      validator(size) {
        return ['sm', 'md'].includes(size);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedFiles: {},
      shouldShowLoader: false,
    };
  },
  watch: {
    selectedFiles: {
      handler(files) {
        if (Object.keys(files).length) {
          this.handleUpload();
        }
      },
    },
  },
  methods: {
    async handleUpload() {
      const selectedFilesArray = Object.values(this.selectedFiles);
      try {
        this.shouldShowLoader = true;
        await this.handleUploadFile(selectedFilesArray);
      } catch (error) {
        this.handleError(error);
      } finally {
        this.clear();
        this.shouldShowLoader = false;
      }
    },
    openFileSystem() {
      this.$refs.inputFile.$el.querySelector('input').click();
    },
    handleFileSelection(fileList) {
      this.selectedFiles = { ...fileList };
    },
    onDrop(event) {
      this.handleFileSelection(event.dataTransfer.files);
    },
    onInputFileChange(event) {
      this.handleFileSelection(event.target.files);
    },
    clear() {
      this.selectedFiles = {};
      this.$refs.inputFile.value = '';
    },
    handleError(error) {
      this.$emit('upload-error', error);
    },
    async handleUploadFile(selectedFiles) {
      await this.onUploadFile(selectedFiles).then(() => this.handleUploadSuccess());
    },
    handleUploadSuccess() {
      this.$emit('upload-success');
    },
  },
};
</script>

<style scoped>
@import './TcFileUploadMultiple.css';
</style>
