<template>
  <div>
    <span data-option-label-content>
      <slot />
    </span>
  </div>
</template>

<script>
export default {
  name: 'DsSelectOption',
  props: {
    getData: Function,
    value: {
      type: [Object, String, Number],
      required: true,
    },
  },
  methods: {
    select() {
      this.$emit('option-selected', {
        ...this.getData(),
        shouldCloseOptions: true,
      });
    },
  },
};
</script>
