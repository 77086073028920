const tooltip = {
  ADD_CATEGORY: 'Adicionar subcategoria',
  DELETE_CATEGORY: 'Remover categoria',
  DELETE_CATEGORY_SUBCATEGORY: 'Remover categoria e subcategorias',
  DELETE_SUBCATEGORY: 'Remover subcategoria',
  DELETE_TOTALIZER: 'Remover totalizador',
};

export function getTooltipRemove(item, firstLevel) {
  const { isTotalizer, children } = item;

  if (isTotalizer) {
    return tooltip.DELETE_TOTALIZER;
  }
  if (firstLevel) {
    if (Array.isArray(children) && children.length === 0) {
      return tooltip.DELETE_CATEGORY;
    }
    if (Array.isArray(children) && children.length > 0) {
      return tooltip.DELETE_CATEGORY_SUBCATEGORY;
    }
  }
  return tooltip.DELETE_SUBCATEGORY;
}

export function getTooltipAdd() {
  return tooltip.ADD_CATEGORY;
}
