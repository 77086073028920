<template>
  <ds-multiple-input
    ref="multipleInput"
    v-model="value"
    :custom-validations="[...emailValidations, ...customValidations]"
    :disabled="disabled"
    :readonly="readonly"
    :required="required"
    v-bind="$attrs"
    v-on="$listeners" />
</template>

<script>
import * as emailService from '@ca-design-system-libs/email';
import DsMultipleInput from '@components/multiple-input';

const { customValidations, disabled, readonly, required, value } = DsMultipleInput.props;

export default {
  name: 'DsMultipleEmailInput',
  components: {
    DsMultipleInput,
  },
  props: {
    customValidations,
    disabled,
    readonly,
    required,
    value,
  },
  data() {
    return {
      emailValidations: [
        {
          message: 'Os e-mails devem estar no formato nome@exemplo.com',
          valid: this.multipleInvalidRule,
        },
        {
          message: 'Por favor, insira um e-mail no formato nome@exemplo.com',
          valid: this.singleInvalidRule,
        },
      ],
    };
  },
  methods: {
    singleInvalidRule(list) {
      const invalidList = this.getInvalidSubset(list);

      return !list.length || !invalidList.length || invalidList.length > 1;
    },
    multipleInvalidRule(list) {
      const invalidList = this.getInvalidSubset(list);

      return !list.length || invalidList.length <= 1;
    },
    getInvalidSubset(list) {
      return list.reduce((acc, email) => {
        if (!emailService.isValid(email)) {
          acc.push(email);
        }
        return acc;
      }, []);
    },
    focus() {
      this.$refs.multipleInput.focus();
    },
  },
};
</script>
