import { generateId } from '../id/idService';

const keyboardSubscriptionService = {};

const subscribers = [];

keyboardSubscriptionService.subscribe = (eventName, keyCode, callback) => {
  const subscriber = buildSubscriber(eventName, keyCode, callback);
  subscribers.push(subscriber);
  return subscriber.id;
};

keyboardSubscriptionService.unsubscribe = subscriberId => {
  const subscriberIndex = findSubscriberIndexById(subscriberId);
  if (subscriberIndex < 0) {
    console.error('[Keyboard Subscriptions Service] Subscriber not found!');
  } else {
    const { eventName, listener } = subscribers[subscriberIndex];
    handleDocumentListener('remove', eventName, listener);
    removeSubscriber(subscriberIndex);
  }
};

function buildSubscriber(eventName, keyCode, callback) {
  const id = generateId();
  const listener = buildSubscriberListener(eventName, keyCode, callback);
  return { id, listener, eventName };
}

function buildSubscriberListener(eventName, keyCode, callback) {
  const listener = buildListener(keyCode, callback);
  handleDocumentListener('add', eventName, listener);
  return listener;
}

function buildListener(keyCode, callback) {
  return function listener(evt) {
    if (evt.keyCode === keyCode) {
      callback(evt);
    }
  };
}

function handleDocumentListener(action, eventName, listener) {
  if (action === 'add') {
    document.addEventListener(eventName, listener);
  } else {
    document.removeEventListener(eventName, listener);
  }
}

function findSubscriberIndexById(subscriberId) {
  return subscribers.findIndex(subscriber => subscriber.id === subscriberId);
}

function removeSubscriber(subscriberIndex) {
  subscribers.splice(subscriberIndex, 1);
}

export default keyboardSubscriptionService;
