<template>
  <ds-fade-transition>
    <div v-if="shouldRender" v-show="opened" :style="menuStyle" class="ds-dropdown-menu">
      <slot></slot>
    </div>
  </ds-fade-transition>
</template>

<script>
import DsFadeTransition from '@components/fade-transition/FadeTransition.vue';
import { getOffsetWidth } from '@core/services/dom/domService';
import { createPopper } from '@popperjs/core';

export default {
  name: 'DsDropdownMenu',
  components: {
    DsFadeTransition,
  },
  provide() {
    return {
      dropdownMenuVm: {
        select: this.select,
      },
    };
  },
  props: {
    el: {
      type: HTMLElement,
    },
    fullWidth: {
      type: Boolean,
    },
    opened: {
      type: Boolean,
    },
    placement: {
      type: String,
      default: 'bottom-start',
    },
    strategy: {
      type: String,
    },
  },
  data() {
    return {
      menuStyle: {},
      popper: null,
    };
  },
  computed: {
    shouldRender() {
      return this.opened || this.popper;
    },
  },
  watch: {
    opened(value) {
      this.openHandler(value);
    },
  },
  beforeDestroy() {
    this.destroyPopper();
  },
  methods: {
    createPopper() {
      return createPopper(this.el, this.$el, {
        placement: this.placement,
        strategy: this.strategy,
      });
    },
    destroyPopper() {
      if (this.popper) {
        this.popper.destroy();
        this.popper = null;
      }
    },
    setMenuFullWidth() {
      this.$set(this.menuStyle, 'minWidth', `${getOffsetWidth(this?.el)}px`);
    },
    select(value) {
      this.$emit('select', value);
    },
    async openHandler(shouldOpen) {
      if (!shouldOpen) {
        return;
      }

      if (!this.popper) {
        await this.$nextTick();
        if (this.fullWidth) {
          this.setMenuFullWidth();
        }
        this.popper = this.createPopper();
      } else {
        this.popper.update();
      }
    },
  },
};
</script>

<style scoped>
@import './DropdownMenu.css';
</style>
