<template>
  <div class="ds-file-input">
    <ds-input
      ref="inputFile"
      type="file"
      class="ds-u-display--none"
      :disabled="disabled"
      :required="required"
      :accept="acceptedFileTypes"
      :custom-validations="fileUploadCustomValidations"
      @change="onInputFileChange" />

    <ds-drop-zone :on-drop="onDropFile" :disabled="disabled">
      <div class="ds-u-display--flex ds-u-align-items--center">
        <ds-tooltipped-overflow>
          <ds-button
            ref="fileInputButton"
            v-ds-tooltip="'Escolha um arquivo'"
            icon="paperclip"
            icon-position="left"
            size="sm"
            class="ds-file-input__button"
            :disabled="disabled"
            @click="openFileSystem()">
            Escolha um arquivo
          </ds-button>
        </ds-tooltipped-overflow>
        <ds-tooltipped-overflow v-if="!disabled">
          <span
            v-ds-tooltip="'Ou arraste-o para este espaço'"
            class="ds-file-input__drop-zone-helper-text ds-u-text-overflow--ellipsis">
            Ou arraste-o para este espaço
          </span>
        </ds-tooltipped-overflow>
      </div>
    </ds-drop-zone>
  </div>
</template>

<script>
import { focusMixin } from '@core';
import {
  isFileListMinSizeValid,
  isFileListMaxSizeValid,
  isFileListTypeValid,
} from '@core/services/fileValidation/fileValidationService';
import INPUT_ERRORS from '@core/constants/inputErrors';
import DsDropZone from '@components/drop-zone';
import DsButton from '@components/button';
import DsInput from '@components/input';
import DsTooltippedOverflow from '@components/tooltipped-overflow';
import DsTooltip from '@directives/tooltip';

export default {
  name: 'DsFileInput',
  directives: {
    DsTooltip,
  },
  components: {
    DsDropZone,
    DsButton,
    DsInput,
    DsTooltippedOverflow,
  },
  mixins: [focusMixin.focus('fileInputButton')],
  props: {
    disabled: DsInput.props.disabled,
    required: DsInput.props.required,
    customValidations: DsInput.props.customValidations,
    value: {
      type: [File, Object],
    },
    minSize: {
      type: Number,
      default: null,
    },
    maxSize: {
      type: Number,
      default: null,
    },
    acceptedFileTypes: {
      type: String,
      default: '*',
    },
    onFileSelect: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    minSizeValidation() {
      return {
        message: INPUT_ERRORS.INVALID_FILE_MIN_SIZE.getMessage(this.minSize),
        valid: fileList => isFileListMinSizeValid(fileList, this.minSize),
      };
    },
    maxSizeValidation() {
      return {
        message: INPUT_ERRORS.INVALID_FILE_MAX_SIZE.getMessage(this.maxSize),
        valid: fileList => isFileListMaxSizeValid(fileList, this.maxSize),
      };
    },
    fileExtensionValidations() {
      return {
        message: INPUT_ERRORS.INVALID_FILE_EXTENSION.MESSAGE,
        valid: fileList => isFileListTypeValid(fileList, this.acceptedFileTypes),
      };
    },
    fileUploadCustomValidations() {
      return [
        ...this.customValidations,
        ...(this.minSize ? [this.minSizeValidation] : []),
        ...(this.maxSize ? [this.maxSizeValidation] : []),
        ...(this.acceptedFileTypes ? [this.fileExtensionValidations] : []),
      ];
    },
  },
  methods: {
    openFileSystem() {
      this.$refs.inputFile.$el.querySelector('input').click();
    },
    setFile(file) {
      this.$emit('input', file);
    },
    handleFileSelection(file) {
      this.setModelValidationFile([file]);
      if (file) {
        this.setFile(file);
      }
      this.onFileSelect(file);
    },
    onDropFile(event) {
      this.handleFileSelection(event.dataTransfer.files.item(0));
    },
    onInputFileChange(event) {
      this.handleFileSelection(event.target.files.item(0));
    },
    setModelValidationFile(fileList) {
      this.$refs.inputFile.setFiles(fileList);
      this.$refs.inputFile.$emit('blur');
    },
  },
};
</script>

<style scoped>
@import './FileInput.css';
</style>
