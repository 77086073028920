import isObjectEqual from 'lodash/isEqual';
import isObjectLike from 'lodash/isObjectLike';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';

export function isEqual(a, b, compareValueBy) {
  return compareValueBy ? get(a, compareValueBy) === get(b, compareValueBy) : a === b;
}

export function isEquivalentObject(a, b) {
  return isObjectLike(a) && isObjectLike(b) && !Array.isArray(a) && !Array.isArray(b)
    ? isObjectEqual(omitBy(a, isUndefined), omitBy(b, isUndefined))
    : isObjectEqual(a, b);
}
