import { BREAKPOINTS_MAP } from '@core/constants/breakpoints';

const listeners = new Set();

function getBreakpoint() {
  const bodyEl = document.querySelector('body');
  const bodyWidth = bodyEl.offsetWidth;

  if (bodyWidth <= BREAKPOINTS_MAP.sm) {
    return 'sm';
  }
  if (bodyWidth <= BREAKPOINTS_MAP.md) {
    return 'md';
  }
  if (bodyWidth <= BREAKPOINTS_MAP.lg) {
    return 'lg';
  }
  if (bodyWidth <= BREAKPOINTS_MAP.xl) {
    return 'xl';
  }
  return 'xxl';
}

const eventListenerCallback = () => {
  const breakpoint = getBreakpoint();
  listeners.forEach(cb => cb(breakpoint));
};

export function setupResponsiveHandler(setBreakpoint) {
  const breakpoint = getBreakpoint();
  setBreakpoint(breakpoint);

  if (!listeners.size) {
    window.addEventListener('resize', eventListenerCallback);
  }

  listeners.add(setBreakpoint);

  return () => {
    listeners.delete(setBreakpoint);
    window.removeEventListener('resize', eventListenerCallback);
  };
}
