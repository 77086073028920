<template>
  <ds-data-search
    :on-search="onSearch"
    :on-search-success="onSearchSuccess"
    :on-search-error="onSearchError"
    invalid-form-tooltip-text="Para buscar os dados, preencha o CNPJ no campo ao lado.">
    <ds-cnpj-input ref="input" :on-change="onChange" v-bind="$attrs" v-on="$listeners" />
  </ds-data-search>
</template>

<script>
import DsCnpjInput from '@components/cnpj-input';
import DsDataSearch from '@components/data-search';
import { focusMixin } from '@core';

export default {
  name: 'DsCnpjDataSearch',
  components: {
    DsDataSearch,
    DsCnpjInput,
  },
  mixins: [focusMixin.focus('input')],
  props: {
    onSearch: DsDataSearch.props.onSearch,
    onSearchSuccess: DsDataSearch.props.onSearchSuccess,
    onSearchError: DsDataSearch.props.onSearchError,
    onChange: DsCnpjInput.props.onChange,
  },
};
</script>
