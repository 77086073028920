import { scrollService } from '@core';
import flatpickr from 'flatpickr';
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import moment from 'moment';
import flatpickrl10n from 'flatpickr/dist/l10n/pt';

export class Datepicker {
  constructor(element, { type = 'day', calendarViewDate, enabledDates, onChange } = {}) {
    validateElementTag(element);
    this.setType(type);
    this.lib = createFlatpickr(element, {
      type,
      calendarViewDate,
      enabledDates,
      onChange,
    });
    this.handleScroll(element);
  }

  setOption({ enabledDates }) {
    this.lib.set('enable', enabledDates);
  }

  clear() {
    this.lib.clear();
  }

  destroy() {
    this.lib.calendarContainer.remove();
    this.lib.destroy();
  }

  close() {
    this.lib.close();
  }

  open() {
    this.lib.open();
  }

  setType(type) {
    this.type = type;
  }

  setCalendarViewDate(calendarViewDate) {
    this.lib.config.calendarViewDate = calendarViewDate;
  }

  setDate(date, triggerChange = true, dateStrFormat = getDateFormat(this.type)) {
    this.lib.setDate(date, triggerChange, dateStrFormat);
  }

  handleScroll(element) {
    const scrollableParentElement = scrollService.getScrollableParentElement(element);
    const close = this.close.bind(this);

    if (scrollableParentElement) {
      scrollableParentElement.addEventListener('scroll', close);

      this.lib.config.onDestroy.push(() => {
        scrollableParentElement.removeEventListener('scroll', close);
      });
    }
  }
}

export function getCalendars() {
  return document.getElementsByClassName('ds-datepicker__calendar');
}

function createFlatpickr(element, { type, calendarViewDate, onChange, ...options }) {
  const typeOptions = {
    ...getTypeOptions(type, options),
    onChange: buildOnChangeCallback(onChange),
    onOpen,
    calendarViewDate,
  };
  const lib = flatpickr(element, typeOptions);

  if (lib.calendarContainer) {
    lib.calendarContainer.classList.add('ds-datepicker__calendar');
  }

  return lib;
}

function buildOnChangeCallback(onChange = () => {}) {
  return function onChangeCallback(selectedDates, dateStr, instance) {
    onChange(selectedDates, dateStr);
    instance.close();
  };
}

function onOpen(selectedDates, dateStr, instance) {
  if (instance.config.calendarViewDate) {
    instance.jumpToDate(parseDate(instance.config.calendarViewDate), false);
  } else {
    instance.jumpToDate();
  }
}

function parseDate(datestr, format) {
  return moment(datestr, format, true).toDate();
}

function formatDate(date, format) {
  return moment(date).format(format);
}

function getTypeOptions(type, options) {
  return {
    day: getDayOptions,
    month: getMonthOptions,
    monthFull: getMonthFullOptions,
  }[type](options);
}

function getDateFormat(type) {
  return {
    day: 'DD/MM/YYYY',
    month: 'MM/YYYY',
    monthFull: 'MMMM [de] YYYY',
  }[type];
}

function getDayOptions({ enabledDates }) {
  return {
    ...(enabledDates && { enable: enabledDates }),
    dateFormat: getDateFormat('day'),
    locale: flatpickrl10n.pt,
    allowInput: true,
    parseDate,
    formatDate,
  };
}

function getMonthOptions({ enabledDates }) {
  return getMonthOptionsByType({ enabledDates });
}

function getMonthFullOptions({ enabledDates }) {
  return getMonthOptionsByType({ enabledDates, type: 'monthFull' });
}

function getMonthOptionsByType({ enabledDates, type = 'month' }) {
  return {
    ...(enabledDates && { enable: enabledDates }),
    locale: flatpickrl10n.pt,
    allowInput: true,
    plugins: [
      new MonthSelectPlugin({
        dateFormat: getDateFormat(type),
        shorthand: true,
      }),
    ],
    parseDate,
    formatDate,
  };
}

function validateElementTag(element) {
  if (!isInput(element)) {
    throw new Error('The element should be an input');
  }
}

function isInput(element) {
  return element.tagName === 'INPUT';
}
