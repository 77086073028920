<template>
  <hr :class="[themeClasses, marginClasses]" class="ds-row-divider" />
</template>

<script>
const SIZES = ['small', 'medium', 'large'];

export default {
  name: 'DsRowDivider',
  props: {
    /**
     * [small, medium, large]
     */
    margin: {
      type: String,
      validator(margin) {
        return [...SIZES, 'none'].includes(margin);
      },
    },
    /**
     * [small, medium, large]
     */
    marginTop: {
      type: String,
      validator(margin) {
        return SIZES.includes(margin);
      },
    },
    /**
     * [small, medium, large]
     */
    marginBottom: {
      type: String,
      validator(margin) {
        return SIZES.includes(margin);
      },
    },
    /**
     * [dotted]
     */
    theme: {
      type: String,
      validator(theme) {
        return ['dotted'].includes(theme);
      },
    },
  },
  computed: {
    marginClasses() {
      if (this.margin === 'none') {
        return null;
      }

      if (!this.margin && !this.marginTop && !this.marginBottom) {
        return this.getDefaultMarginClass();
      }

      if (this.margin) {
        return [this.getMarginTopClass(this.margin), this.getMarginBottomClass(this.margin)];
      }

      return this.getTopBottomMarginClass();
    },
    themeClasses() {
      return {
        [`ds-row-divider--${this.theme}`]: this.theme,
      };
    },
  },
  methods: {
    getDefaultMarginClass() {
      return this.getMarginBottomClass('small');
    },
    getTopBottomMarginClass() {
      const marginClass = [];

      if (this.marginTop) {
        marginClass.push(this.getMarginTopClass(this.marginTop));
      }

      if (this.marginBottom) {
        marginClass.push(this.getMarginBottomClass(this.marginBottom));
      }

      return marginClass;
    },
    getMarginTopClass(size) {
      return `ds-row-divider--${size}-top-margin`;
    },
    getMarginBottomClass(size) {
      return `ds-row-divider--${size}-bottom-margin`;
    },
  },
};
</script>

<style scoped>
@import './RowDivider.css';
</style>
