<template>
  <tr class="ds-detail-total-bar-item">
    <th class="ds-detail-total-bar-item__header" scope="row">
      <ds-text color="grey" class="ds-detail-total-bar-item__label">{{ labelWithSufix }}</ds-text>
    </th>
    <td>
      <span class="ds-detail-total-bar-item__total">
        <ds-currency-format :class="{ [`ds-u-color--${color}`]: color }" :value="value" />
      </span>
    </td>
  </tr>
</template>

<script>
import * as CONSTANTS from '@core/constants';
import DsText from '@components/text';
import DsCurrencyFormat from '@components/currency-format';

export default {
  name: 'DsDetailTotalBarItemDeprecated',
  inject: ['detailTotalBarVm'],
  components: {
    DsText,
    DsCurrencyFormat,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      default: 'grey-dark',
      validator(value) {
        return CONSTANTS.COLOR_CONSTANTS.COLORS.includes(value);
      },
    },
  },
  computed: {
    labelWithSufix() {
      return `${this.label} (R$)`;
    },
  },
  watch: {
    value() {
      this.$nextTick(this.detailTotalBarVm.updateDetailTotalBar);
    },
    label() {
      this.$nextTick(this.detailTotalBarVm.updateDetailTotalBar);
    },
  },
};
</script>

<style scoped>
@import './DetailTotalBarItem.css';
</style>
