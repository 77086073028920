import Vue from 'vue';
import isEqual from 'lodash/isEqual';
import { DATE_PERIOD_TYPES } from '@components/date-period-dropdown';

function getInitSearchTerm(searchTerm, defaultSearchTerm) {
  return searchTerm === undefined ? defaultSearchTerm : searchTerm;
}

function getDefaultPeriodType({ period, periodType }) {
  return period ? undefined : periodType || DATE_PERIOD_TYPES.THIS_MONTH.key;
}

function getInitPeriodAndPeriodType(filters, defaultFilters) {
  const period =
    filters.periodType && !hasStartDateOrEndDate(filters.period) ? {} : filters.period || defaultFilters.period;
  const periodType = hasStartDateOrEndDate(period) ? undefined : filters.periodType || defaultFilters.periodType;

  return {
    period,
    periodType,
  };
}

function hasStartDateOrEndDate(period) {
  return !!period?.startDate || !!period?.endDate;
}

function getDefaultButtonGroup(defaultFilters, filterButtonGroup) {
  return defaultFilters?.buttonGroup || filterButtonGroup?.buttons[0]?.value;
}

export function createStore() {
  const store = Vue.observable({
    pagination: {
      data: null,
      paginationOnTop: false,
    },
    filters: {
      default: {},
      active: {
        searchTerm: '',
      },
    },
  });

  function getPaginationData() {
    return store.pagination.data;
  }

  function getPaginationOnTop() {
    return store.pagination.paginationOnTop;
  }

  function setPaginationData(value) {
    store.pagination.data = value;
  }

  function setPaginationOnTop(value) {
    store.pagination.paginationOnTop = value;
  }

  function initFilters(filters, defaultFilters, filterButtonGroup) {
    setDefaultFilters(defaultFilters, filterButtonGroup);

    const searchTerm = getInitSearchTerm(filters.searchTerm, store.filters.default.searchTerm);
    const { period, periodType } = getInitPeriodAndPeriodType(filters, store.filters.default);

    const newFilters = {
      searchTerm,
      period,
      periodType,
      buttonGroup: filters.buttonGroup || store.filters.default.buttonGroup,
      multipleSelect: filters.multipleSelect || store.filters.default.multipleSelect,
    };

    store.filters.active = newFilters;
  }

  function setDefaultFilters(defaultFilters, filterButtonGroup) {
    const newDefaultFilters = {
      searchTerm: defaultFilters.searchTerm || '',
      period: defaultFilters.period || {},
      periodType: getDefaultPeriodType(defaultFilters),
      buttonGroup: getDefaultButtonGroup(defaultFilters, filterButtonGroup),
      multipleSelect: defaultFilters.multipleSelect || null,
    };

    store.filters.default = newDefaultFilters;
  }

  function isFiltersChanged() {
    const activeFilters = { ...store.filters.active };
    if (activeFilters.periodType) {
      activeFilters.period = {};
    }

    return !isEqual(activeFilters, store.filters.default);
  }

  function clearFilters() {
    store.filters.active = { ...store.filters.default };
  }

  return {
    getPaginationData,
    getPaginationOnTop,
    setPaginationData,
    setPaginationOnTop,
    initFilters,
    get filters() {
      return store.filters.active;
    },
    get isFiltersChanged() {
      return isFiltersChanged();
    },
    updateFilter(filterName, value) {
      const newActive = {
        ...store.filters.active,
        [filterName]: value,
      };
      store.filters.active = newActive;
    },
    clearFilters,
  };
}
