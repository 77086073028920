<template>
  <div class="ds-detail-total-bar-opened" :class="{ 'ds-detail-total-bar-opened--fixed': disableToggle }">
    <div class="ds-detail-total-bar-opened__content">
      <div class="ds-detail-total-bar-opened__info">
        <div class="ds-detail-total-bar-opened__titles">
          <ds-heading class="ds-detail-total-bar-opened__title" :text="title" size="md" />
          <div v-if="subtitle" class="ds-detail-total-bar-opened__subtitle">
            <ds-text>{{ subtitle }}</ds-text>
          </div>
        </div>
        <div>
          <div v-if="firstInfoLabel" class="ds-detail-total-bar-opened__label">
            <ds-text>{{ firstInfoLabel }}</ds-text>
          </div>
          <div v-if="secondInfoLabel" class="ds-detail-total-bar-opened__label">
            <ds-text>{{ secondInfoLabel }}</ds-text>
          </div>
        </div>
      </div>
      <table class="ds-detail-total-bar-opened__totals-table">
        <caption class="ds-u-display--none">
          {{
            title
          }}
        </caption>
        <slot />
        <ds-detail-total-bar-item-group>
          <slot name="highlighted-subtotals" />
        </ds-detail-total-bar-item-group>
        <slot name="total" />
      </table>
    </div>
    <div v-if="!disableToggle" class="ds-detail-total-bar-opened__button">
      <ds-detail-total-bar-toggle-button arrow-up @click="onToggle" />
    </div>
  </div>
</template>

<script>
import DsHeading from '@components/heading';
import DsText from '@components/text';
import DsDetailTotalBarItemGroup from '@components/detail-total-bar-item-group-deprecated';
import DsDetailTotalBarToggleButton from './DetailTotalBarToggleButton.vue';

export default {
  name: 'DsDetailTotalBarOpened',
  components: {
    DsText,
    DsHeading,
    DsDetailTotalBarItemGroup,
    DsDetailTotalBarToggleButton,
  },
  props: {
    title: String,
    subtitle: String,
    firstInfoLabel: String,
    secondInfoLabel: String,
    disableToggle: Boolean,
  },
  methods: {
    onToggle() {
      this.$emit('toggle');
    },
  },
};
</script>

<style scoped>
@import './DetailTotalBarOpened.css';
</style>
