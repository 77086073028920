<template>
  <div class="ds-rich-text" :class="classes">
    <ds-rich-text-toolbar ref="toolbar" v-bind="$props" />
    <div ref="editor"></div>
  </div>
</template>

<script>
import Quill from 'quill';
import { modelValidationService, counterService } from '@core';
import DsRichTextToolbar from './RichTextToolbar.vue';
import { RICH_TEXT_ACCEPTED_FORMATS } from './richTextConstants';

const align = Quill.import('attributors/style/align');
const ZERO_VALUE = 0;
const EMPTY_VALUE = '';

Quill.register(align, true);

export default {
  name: 'DsRichText',
  components: { DsRichTextToolbar },
  props: {
    value: {
      type: String,
      default: '',
    },
    heading: {
      type: Boolean,
      default: true,
    },
    format: {
      type: Boolean,
      default: true,
    },
    align: {
      type: Boolean,
      default: true,
    },
    list: {
      type: Boolean,
      default: true,
    },
    external: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
    },
    maxCharacters: {
      type: Number,
    },
    minCharacters: {
      type: Number,
    },
    counter: {
      type: Boolean,
    },
  },
  data() {
    return {
      modelValidator: modelValidationService.buildModelValidation(this, {
        customValidations: counterService.getRules(this),
      }),
      editor: null,
      text: null,
      counterHook: null,
      localCounter: 0,
    };
  },
  inject: {
    fieldVm: {
      default: null,
    },
    formVm: {
      default: null,
    },
  },
  computed: {
    classes() {
      const { cssClass } = this.modelValidator;
      const disabledClass = 'ds-rich-text--disabled';
      return {
        [disabledClass]: this.disabled,
        [cssClass]: !!cssClass,
      };
    },
  },
  watch: {
    disabled() {
      this.handleDisable();
    },
    value() {
      this.handleEditorContent();
    },
  },
  async mounted() {
    this.init();
    this.handleCounterHook();

    await this.$nextTick();

    this.handleDisable();
    this.handleQuillEditorEvents();
  },
  methods: {
    build(editor, toolbar) {
      const quill = new Quill(editor, {
        modules: {
          toolbar,
        },
        theme: 'snow',
        formats: RICH_TEXT_ACCEPTED_FORMATS,
      });

      return quill;
    },
    init() {
      this.editor = this.build(this.$refs.editor, this.$refs.toolbar.$el);
      this.editor.root.innerHTML = this.value;

      this.editor.on('text-change', () => this.update());
    },
    update() {
      const editorContentLength = this.getEditorContentLength();
      const isEditorContentEmpty = editorContentLength === ZERO_VALUE;
      const value = isEditorContentEmpty ? EMPTY_VALUE : this.editor.root.innerHTML;

      this.setLocalCounterValue(editorContentLength || ZERO_VALUE);
      this.handleCounterValue();
      this.emitEvents(value);
    },
    handleCounterValue() {
      if (this.counter) {
        this.counterHook.setCounterValue(this.localCounter);
      }
    },
    setLocalCounterValue(value) {
      this.localCounter = value;
    },
    getEditorContentLength() {
      // quill editor always insert a new line when field is empty, because of it the length is always 1;
      return this.editor.getText().length - 1;
    },
    emitEvents(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    },
    onInputBlur(event) {
      this.$emit('blur', event);
    },
    handleDisable() {
      if (this.disabled) {
        this.editor.enable(!this.disabled);
      }
    },
    handleQuillEditorEvents() {
      this.$refs.editor.firstChild.onblur = this.onInputBlur;
    },
    handleCounterHook() {
      if (this.counter) {
        this.counterHook = counterService.useCounter(this);
      }
    },
    handleEditorContent() {
      if (this.value !== this.editor.root.innerHTML) {
        const delta = this.editor.clipboard.convert(this.value);
        this.editor.setContents(delta, 'silent');
        this.update();
      }
    },
  },
};
</script>

<style>
@import './RichText.css';
</style>
