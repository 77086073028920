<template>
  <transition
    class="ds-animation"
    :enter-active-class="enterActiveClass"
    leave-active-class="ds-u-display--none"
    :appear="appear"
    @after-enter="onAfterEnter"
    @after-leave="onAfterLeave">
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'DsAnimation',
  props: {
    type: {
      type: String,
      validator(type) {
        return ['slide-in-left', 'slide-in-right'].includes(type);
      },
      required: true,
    },
    appear: Boolean,
  },
  computed: {
    enterActiveClass() {
      return `ds-animation-base ds-animation--${this.type}`;
    },
  },
  methods: {
    onAfterEnter() {
      this.$emit('after-enter');
    },
    onAfterLeave() {
      this.$emit('after-leave');
    },
  },
};
</script>

<style>
@import 'Animation.css';
</style>
