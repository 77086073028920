<template>
  <div class="ds-detail-total-bar" :class="classes">
    <ds-collapse :opened="opened" @update:opened="collapseToggleAction">
      <template #custom-description>
        <div class="ds-detail-total-bar__header">
          <div class="ds-detail-total-bar__title-container">
            <div class="ds-detail-total-bar__title">
              <ds-text color="text" weight="bold">{{ title }}</ds-text>
            </div>
            <div class="ds-detail-total-bar__subtitle">
              <ds-text color="grey">{{ subtitle }}</ds-text>
            </div>
          </div>
          <div class="ds-detail-total-bar__totals-container">
            <slot />
          </div>
        </div>
      </template>
      <div class="ds-detail-total-bar__details-container">
        <table class="ds-detail-total-bar__details-table">
          <caption class="ds-u-display--none">
            {{
              title
            }}
          </caption>
          <slot name="details" />
        </table>
      </div>
    </ds-collapse>
  </div>
</template>

<script>
import { getChildrenFromSlot, isComponent } from '@core/services/vnode/vnodeService';
import DsCollapse from '@components/collapse';
import DsText from '@components/text';

export default {
  name: 'DsDetailTotalBar',
  provide() {
    return {
      fixedCollapse: this.fixedCollapse,
      registerDetailTotalBarSubitem: this.registerDetailTotalBarSubitem,
    };
  },
  components: {
    DsCollapse,
    DsText,
  },
  props: {
    title: String,
    subtitle: String,
    fixedCollapse: Boolean,
    opened: Boolean,
  },
  data() {
    return {
      isAnyOperatorShown: false,
    };
  },
  computed: {
    classes() {
      return {
        'ds-detail-total-bar--fixed-collapse': this.fixedCollapse,
        'ds-detail-total-bar--operator-shown': this.isAnyOperatorShown,
      };
    },
  },
  mounted() {
    this.updateOperatorsState();
  },
  methods: {
    updateOperatorsState() {
      const OPERATOR_TAG = 'ds-detail-total-bar-operator';
      this.isAnyOperatorShown = !!this.$slots.default?.find(child => child.componentOptions?.tag === OPERATOR_TAG);
    },
    collapseToggleAction(isOpened) {
      if (isOpened) {
        this.$emit('opened');
      } else {
        this.$emit('closed');
      }
    },
    registerDetailTotalBarSubitem() {
      setTimeout(() => {
        const items = getChildrenFromSlot(this.$slots.default, vnode => isComponent(vnode, 'DsDetailTotalBarItem'));

        this.$el.querySelectorAll('.ds-detail-total-bar-subitem').forEach(el => {
          el.querySelectorAll('.ds-detail-total-bar-subitem__value').forEach((subitemEl, index) => {
            setWidthFromItem(subitemEl, items[index]);
          });
        });
      });
    },
  },
};

function setWidthFromItem(subitemEl, itemVnode) {
  const { width } = itemVnode?.componentInstance || {};

  if (width) {
    subitemEl.style.width = `${width}px`;
  }
}
</script>

<style scoped>
@import './DetailTotalBar.css';
</style>
