<template>
  <ds-tr
    v-if="isVisible()"
    class="ds-table-row-nested"
    :theme="theme"
    :not-hoverable="notHoverable"
    :class="classes"
    @click="$emit('click', $event)">
    <slot></slot>
  </ds-tr>
</template>

<script>
import DsTr from '@components/table-row';

export default {
  name: 'DsTrNested',
  inject: ['tableRowCollapseVm'],
  components: {
    DsTr,
  },
  props: {
    notHoverable: DsTr.props.notHoverable,
    theme: DsTr.props.theme,
  },
  computed: {
    currentCollapseLevel() {
      return this.tableRowCollapseVm.currentCollapseLevel + 1;
    },
    classes() {
      return {
        [`ds-table-row-nested-level-${this.currentCollapseLevel}`]: this.currentCollapseLevel > 0,
      };
    },
  },
  methods: {
    isVisible() {
      return !this.tableRowCollapseVm.collapsed && this.tableRowCollapseVm.isVisible();
    },
  },
};
</script>
