import INPUT_ERRORS from '../../constants/inputErrors';

const RULES = [
  {
    name: INPUT_ERRORS.REQUIRED.KEY,
    message: INPUT_ERRORS.REQUIRED.MESSAGE,
    valid: value => !!value,
  },
  {
    name: INPUT_ERRORS.REQUIRED_FILE.KEY,
    message: INPUT_ERRORS.REQUIRED_FILE.MESSAGE,
    valid: fileList => {
      if (Array.isArray(fileList)) {
        return fileList.length > 0;
      }

      return !!(fileList.name && fileList.size);
    },
  },
];

export function getRule(name) {
  return RULES.find(rule => rule.name === name);
}
