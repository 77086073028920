const OFFSETS = {
  VERTICAL: {
    MIN: 1,
    MAX: 11,
  },
  HORIZONTAL: {
    MIN: 1,
    MAX: 11,
  },
};

const ALIGNMENTS = ['bottom', 'center', 'stretch'];

const TEXTS = {
  INVALID_CONTENT_VERTICAL_ALIGN_ERROR_MESSAGE: `Invalid content vertical align value given. Valid values are: ${ALIGNMENTS.join(
    ', ',
  )}.`,
  INVALID_FIRST_CHILD_ERROR_MESSAGE: 'The first child element of a <ds-row> should be a <ds-col />',
  INVALID_VERTICAL_OFFSET_ERROR_MESSAGE:
    'Invalid vertical offset value given. ' +
    `Valid values go from ${OFFSETS.VERTICAL.MIN} to ${OFFSETS.VERTICAL.MAX}.`,
};

function isFirstChildElementValid(firstChildElement) {
  return !firstChildElement || firstChildElement.getAttribute('data-col') !== null;
}

export function isContentVerticalAlignValid(value) {
  return ALIGNMENTS.includes(value);
}

export function isVerticalOffsetValid(value) {
  const integerValue = parseInt(value);
  return integerValue >= OFFSETS.VERTICAL.MIN && integerValue <= OFFSETS.VERTICAL.MAX;
}

export function handleVerticalOffsetValidation(verticalOffset) {
  if (verticalOffset && !isVerticalOffsetValid(verticalOffset)) {
    throw new Error(TEXTS.INVALID_VERTICAL_OFFSET_ERROR_MESSAGE);
  }
}

export function handleContentVerticalAlignValidation(contentVerticalAlign) {
  if (contentVerticalAlign && !isContentVerticalAlignValid(contentVerticalAlign)) {
    throw new Error(TEXTS.INVALID_CONTENT_VERTICAL_ALIGN_ERROR_MESSAGE);
  }
}

export function handleFirstChildElementValidation(firstChildElement) {
  if (!isFirstChildElementValid(firstChildElement)) {
    throw new Error(TEXTS.INVALID_FIRST_CHILD_ERROR_MESSAGE);
  }
}
