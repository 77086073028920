<template>
  <span class="ql-formats">
    <button
      v-for="list in lists"
      :key="list.value"
      v-ds-tooltip="list.tooltip"
      class="ql-list"
      :value="list.value"></button>
  </span>
</template>

<script>
import DsTooltip from '@directives/tooltip';
import { RICH_TEXT_LISTS } from './richTextConstants';

export default {
  name: 'DsRichTextToolbarList',
  directives: {
    DsTooltip,
  },
  data() {
    return {
      lists: RICH_TEXT_LISTS,
    };
  },
};
</script>
