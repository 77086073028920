export const ICONS = Object.freeze({
  EYE: 'eye',
  EYE_SLASH: 'eye-slash',
  CHECK_CIRCLE: 'check-circle',
  EXCLAMATION_CIRCLE: 'exclamation-circle',
});

export const INPUT_TYPES = Object.freeze({
  TEXT: 'text',
  PASSWORD: 'password',
});

export const TOOLTIP_TEXTS = Object.freeze({
  SHOW_PASSWORD: 'Exibir senha',
  HIDE_PASSWORD: 'Ocultar senha',
});

export const COLORS = Object.freeze({
  GREEN: 'green',
  SILVER: 'silver',
});

export const INPUT_RULES = Object.freeze([
  {
    TEXT: '8 caracteres ou mais',
    REGEX: /(^.{8,}$)/,
  },
  {
    TEXT: 'Letras maiúsculas e minúsculas',
    REGEX: /^(?=.*[a-z])(?=.*[A-Z]).+$/,
  },
  {
    TEXT: 'Pelo menos um número',
    REGEX: /\d/,
  },
  {
    TEXT: 'Pelo menos um caractere especial',
    REGEX: /(?=.*?)[#?!@$%^&*+-._]/,
  },
]);

export const INPUT_RULES_DEFAULT_VALIDATION_MESSAGE = 'Esta senha ainda não é forte';
