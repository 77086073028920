<template>
  <div :data-index="dataIndex">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'DsVirtualListItem',
  props: {
    dataIndex: Number,
  },
  mounted() {
    this.$emit('component-mounted', this.$el);
  },
};
</script>
