<template>
  <span :class="classes" class="ds-icon-notification"></span>
</template>

<script>
import * as CONSTANTS from '@core/constants';

export default {
  name: 'DsIconNotification',
  props: {
    color: {
      type: String,
      default: 'yellow',
      validator(color) {
        return CONSTANTS.COLOR_CONSTANTS.COLORS.includes(color);
      },
    },
  },
  computed: {
    classes() {
      return [`ds-u-background-color--${this.color}`];
    },
  },
};
</script>

<style scoped>
@import './IconNotification.css';
</style>
