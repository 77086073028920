<template>
  <ds-button :icon="[iconType, 'moon']" size="sm" :tooltip="tooltip" @click="toggleDark"></ds-button>
</template>
<script>
import DsButton from '@components/button';
import Darkmode from '@core/services/darkMode/darkModeService';
import { ICON_TYPE, DATA_THEME } from '@core/services/darkMode/darkModeServicesConstants';

export default {
  name: 'DsDarkModeButton',
  components: {
    DsButton,
  },
  props: {
    initialMode: {
      type: String,
      default: DATA_THEME.LIGHT,
      validator(mode) {
        return [DATA_THEME.LIGHT, DATA_THEME.DARK].includes(mode);
      },
    },
  },
  data() {
    return {
      iconType: null,
      tooltip: null,
      darkmode: null,
    };
  },
  created() {
    this.darkmode = new Darkmode(this.initialMode);
    this.iconType = this.getIconType();
    this.tooltip = this.getTooltip();
  },
  methods: {
    toggleDark() {
      this.darkmode.toggle();
      this.iconType = this.getIconType();
      this.tooltip = this.getTooltip();
      this.$emit('is-active', this.isDarkModeActivated());
    },
    isDarkModeActivated() {
      return this.darkmode?.isActivated();
    },
    getIconType() {
      return this.isDarkModeActivated() ? ICON_TYPE.SOLID : ICON_TYPE.REGULAR;
    },
    getTooltip() {
      return this.isDarkModeActivated() ? 'Desativar modo escuro' : 'Ativar modo escuro';
    },
  },
};
</script>
