<template>
  <ds-tr class="ds-batch-table-header-tr">
    <ds-th key="batch-table-checkboxes" :truncate="false" class="ds-batch-table__header-checkbox ds-u-print-hidden">
      <ds-checkbox v-if="batchType === 'checkbox'" :checked="checked" @click.native="setChecked" />
    </ds-th>
    <slot></slot>
  </ds-tr>
</template>

<script>
import DsTr from '@components/table-row';
import DsTh from '@components/table-header';
import DsCheckbox from '@components/checkbox';

export default {
  name: 'DsBatchTableHeaderTr',
  components: {
    DsTr,
    DsTh,
    DsCheckbox,
  },
  inject: ['batchTableVm', 'batchTableStore', 'batchType'],
  data() {
    return {
      checked: this.isAllSelected,
    };
  },
  computed: {
    isAllSelected() {
      return this.batchTableStore.isAllSelected;
    },
  },
  watch: {
    isAllSelected(checked) {
      this.checked = checked;
    },
  },
  methods: {
    setChecked() {
      this.batchTableStore.setAllSelectedChecked(!this.checked);
    },
  },
};
</script>
