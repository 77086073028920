<template>
  <div :key="renderKey" class="ds-tc-autocomplete">
    <ds-input
      ref="input"
      v-model="inputTerm"
      :placeholder="placeholder"
      :disabled="disabled"
      @keydown.up.prevent="onKeyUp"
      @keydown.down.prevent="onKeyDown"
      @keydown.enter.prevent="onKeyEnter"
      @keydown.esc.prevent="onEsc"
      @click.stop="onInputClick"
      @input="onInput">
    </ds-input>
    <ds-search-select
      ref="searchSelect"
      :should-match-term-pattern="false"
      :selected-option="selectedOption"
      :query-action="onQuery"
      :load-more="loadMoreVisibility"
      @query-success="onQuerySuccess"
      @load-more-button-click="onLoadMore"
      @input="onChange">
      <ds-option v-for="(option, index) in options" :key="['option', index].join('-')" :value="option">
        <span class="ds-u-display--flex ds-u-justify-content--space-between">
          {{ option[optionLabelKey] }}
          <span>
            <ds-badge
              v-if="optionAdditionalBadgeLabelKey && option[optionAdditionalBadgeLabelKey]"
              :theme="optionAdditionalBadgeTheme">
              {{ option[optionAdditionalBadgeLabelKey] }}
            </ds-badge>
            <ds-badge
              v-if="optionBadgeLabelKey && option[optionBadgeLabelKey]"
              theme="info"
              class="ds-u-margin-left--sm">
              {{ optionBadgePrefix }} {{ option[optionBadgeLabelKey] }}
            </ds-badge>
          </span>
        </span>
      </ds-option>
    </ds-search-select>
  </div>
</template>

<script lang="jsx">
import DsInput from '@components/input';
import DsSearchSelect from '@components/search-select';
import DsOption from '@components/option';
import DsBadge from '@components/badge';

export default {
  name: 'DsTcAutocomplete',
  components: {
    DsInput,
    DsSearchSelect,
    DsOption,
    DsBadge,
  },
  props: {
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    searchAction: {
      type: Function,
      required: true,
    },
    autoSelectByKey: {
      type: String,
    },
    optionLabelKey: {
      type: String,
      required: true,
    },
    optionBadgePrefix: {
      type: String,
    },
    optionBadgeLabelKey: {
      type: String,
    },
    optionAdditionalBadgeLabelKey: {
      type: String,
    },
    optionAdditionalBadgeTheme: {
      type: String,
      validator(theme) {
        return ['success', 'info', 'warning', 'danger', 'light'].includes(theme);
      },
    },
    maxResultsPerPage: {
      type: Number,
      default: 20,
    },
  },
  data() {
    return {
      renderKey: 0,
      options: [],
      currentPage: 0,
      inputTerm: '',
      searchTerm: '',
      loadMoreVisibility: false,
      selectedOption: {},
    };
  },
  methods: {
    getInput() {
      return this.$refs.input;
    },
    getSearch() {
      return this.$refs.searchSelect;
    },
    getSelect() {
      return this.$refs.searchSelect.$refs.select;
    },
    getSelectOptions() {
      return this.getSelect().$refs.selectOptions;
    },
    focus() {
      this.getInput().focus();
    },
    onEsc() {
      this.close();
    },
    onKeyUp() {
      if (this.isOpen()) {
        this.getSelectOptions().onKeydownArrowUp();
      }
    },
    onKeyDown() {
      if (this.isOpen()) {
        this.getSelectOptions().onKeydownArrowDown();
      }
    },
    onKeyEnter() {
      if (this.isOpen()) {
        this.getSelectOptions().onKeydownEnter();
      }
    },
    isOpen() {
      return this.getSelect().showOptions;
    },
    open() {
      if (!this.isOpen()) {
        this.getSearch().focus();
      }
    },
    reRender() {
      this.renderKey += 1;
      this.$nextTick(() => {
        this.focus();
      });
    },
    clear() {
      this.inputTerm = null;
      this.selectedOption = {};
    },
    close() {
      if (this.isOpen()) {
        this.getSelectOptions().close();
        this.reRender();
      }
      this.clear();
    },
    onInput(term) {
      if (term) {
        this.open();
        if (term !== this.$refs.searchSelect.term) {
          this.$refs.searchSelect.onInputChange(term);
        }
      } else {
        this.close();
      }
    },
    onInputClick() {
      this.onInput(this.inputTerm);
    },
    search() {
      return this.searchAction(this.searchTerm, this.currentPage, this.maxResultsPerPage);
    },
    onQuery() {
      this.setCurrentPage(0);
      this.setSearchTerm(this.inputTerm);
      return this.search();
    },
    onQuerySuccess({ items, totalItems }) {
      if (this.isAutoSelectSingleItem(items)) {
        const [item] = items;
        this.onChange(item);
      } else {
        this.setOptions(items);
        this.shouldShowLoadMore(totalItems);
        if (items.length) {
          this.getSelectOptions().hoverOptionByKeyboard('option-0');
        }
      }
    },
    isAutoSelectSingleItem(items) {
      return items.length === 1 && items[0][this.autoSelectByKey] === this.searchTerm;
    },
    shouldShowLoadMore(totalItems) {
      this.setLoadMoreVisibility(this.options?.length < totalItems);
    },
    onLoadMore() {
      this.setCurrentPage(this.currentPage + 1);
      return this.search().then(this.onLoadMoreSuccess);
    },
    onLoadMoreSuccess({ items, totalItems }) {
      this.setOptions(this.options.concat(items));
      this.shouldShowLoadMore(totalItems);
    },
    onChange(value) {
      this.$emit('change', value);
      this.close();
    },
    setSearchTerm(searchTerm) {
      this.searchTerm = searchTerm;
    },
    setOptions(options) {
      this.options = options;
    },
    setCurrentPage(currentPage) {
      this.currentPage = currentPage;
    },
    setLoadMoreVisibility(loadMoreVisibility) {
      this.loadMoreVisibility = loadMoreVisibility;
    },
    /* Checks if an event has occurred within the autocomplete
     *
     * @public
     * @param {Object} event
     */
    isRelatedEvent(event) {
      return event.target.closest('.ds-tc-autocomplete');
    },
  },
};
</script>
<style scoped>
@import './TcAutocomplete.css';
</style>
