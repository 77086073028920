<template>
  <div class="ds-steps__labels" :style="style">
    <div v-for="(step, index) in steps" :key="index" class="ds-steps__label">
      <div class="ds-steps__title">
        <ds-p weight="bold" :color="getColor(index)">
          {{ step.title }}
        </ds-p>
      </div>
      <div class="ds-steps__subtitle">
        {{ step.subtitle }}
      </div>
    </div>
  </div>
</template>

<script>
import DsP from '@components/paragraph';

export default {
  components: {
    DsP,
  },
  props: {
    steps: {
      type: Array,
    },
    currentStep: {
      type: Number,
    },
  },
  computed: {
    style() {
      return {
        width: `calc(var(--ds-steps--bar-width) * ${this.nSteps} + var(--ds-steps--circle-size) * ${this.nSteps})`,
        'grid-template-columns': `repeat(${this.nSteps}, 1fr)`,
      };
    },
    nSteps() {
      return this.steps.length;
    },
    isLast() {
      return this.currentStep === this.nSteps - 1;
    },
  },
  methods: {
    getColor(index) {
      return this.currentStep < index ? 'grey' : 'text';
    },
  },
};
</script>

<style scoped>
@import './Labels.css';
</style>
