const MINI_ROLLOVER_MAIN_CLASS = 'ds-mini-rollover';

export function hasSomeMiniRolloverOpened() {
  return document.getElementsByClassName(MINI_ROLLOVER_MAIN_CLASS).length > 0;
}

function getTransitionDuration(el) {
  return parseFloat(getComputedStyle(el).transitionDuration) * 1000;
}

export function cleanDomAfterAnimationEnds(el) {
  setTimeout(() => el.remove(), getTransitionDuration(el));
}

export function runCallbacksList(clearCallbacks) {
  if (!Array.isArray(clearCallbacks)) {
    return;
  }

  clearCallbacks.forEach(clearCallback => {
    if (typeof clearCallback === 'function') {
      clearCallback();
    }
  });
}
