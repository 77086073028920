<template>
  <label class="ds-label">
    <span class="ds-label__text">{{ text }}</span>
    <span v-if="shouldShowRequiredMark" class="ds-label-required-mark"></span>
    <ds-helper-icon :tooltip="iconTooltip" class="ds-label__helper-icon" />
    <slot />
  </label>
</template>

<script>
import DsHelperIcon from '@components/helper-icon/HelperIcon.vue';

export default {
  name: 'DsLabel',
  components: {
    DsHelperIcon,
  },
  props: {
    text: String,
    required: Boolean,
    iconTooltip: String,
  },
  computed: {
    shouldShowRequiredMark() {
      return this.required && this.text;
    },
  },
};
</script>

<style scoped>
@import 'Label.css';
</style>
