import { domService } from '@core';
/* eslint-disable import/no-unresolved */
import template from './toast.html?raw';
import './toast.css';

export default class Toast {
  constructor(options = {}) {
    this.element = buildElement(options);
    this.closeButton = this.element.querySelector('[data-ds-toast-close-button]');
    this.removeEventListeners = null;
    this.show();
    this.handleListeners();
    this.configureAutoRemoval();
    this.setHasMouseover(false);
  }

  handleListeners() {
    const closeButtonHandler = () => this.remove();
    const mouseleaveHandler = () => this.remove();
    const mouseoverHandler = () => this.setHasMouseover(true);

    this.closeButton.addEventListener('click', closeButtonHandler);
    this.element.addEventListener('mouseleave', mouseleaveHandler);
    this.element.addEventListener('mouseover', mouseoverHandler);

    this.removeEventListeners = () => {
      this.closeButton.removeEventListener('click', closeButtonHandler);
      this.element.removeEventListener('mouseleave', mouseleaveHandler);
      this.element.removeEventListener('mouseover', mouseoverHandler);
    };
  }

  configureAutoRemoval() {
    setTimeout(() => this.removeByTimeout(), 5000);
  }

  removeByTimeout() {
    if (!this.hasMouseOver) {
      this.remove();
    }
  }

  show() {
    setTimeout(() => {
      domService.addClass(this.element, 'is-visible');
    });
  }

  get() {
    return this.element;
  }

  remove() {
    domService.removeClass(this.element, 'is-visible');
    this.removeEventListeners();

    setTimeout(() => {
      this.element.remove();
      this.element = null;
    }, 200);
  }

  setHasMouseover(value) {
    this.hasMouseOver = value;
  }
}

function buildElement(options) {
  const { content, title, theme } = options;
  const element = domService.parser(template);

  addContent(element, content);
  addTitle(element, title);
  addTheme(element, theme);

  return element;
}

function addContent(element, content) {
  if (!content) {
    throw new Error('The content should be provided');
  }

  element.querySelector('[data-ds-toast-content]').textContent = content;
}

function addTitle(element, title) {
  const titleElement = element.querySelector('[data-ds-toast-title]');

  if (title) {
    titleElement.textContent = title;
  } else {
    titleElement.remove();
  }
}

function addTheme(element, theme = 'success') {
  if (!isValidTheme(theme)) {
    throw new Error('The theme options should be warning, success, error or info');
  }

  domService.addClass(element, `ds-toast--${theme}`);
}

function isValidTheme(theme) {
  return ['warning', 'success', 'error', 'info'].includes(theme);
}
