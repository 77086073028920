<template>
  <div class="ds-application-layout">
    <div class="ds-application-layout__topbar">
      <slot name="topbar" />
    </div>
    <div class="ds-application-layout__body">
      <div class="ds-application-layout__sidebar">
        <slot name="sidebar" />
      </div>
      <div class="ds-application-layout__content" ds-scrollable-body>
        <ds-body>
          <slot />
        </ds-body>
      </div>
    </div>
  </div>
</template>

<script>
import DsBody from '@components/layout/body';

export default {
  name: 'DsApplicationLayout',
  components: {
    DsBody,
  },
};
</script>

<style scoped>
@import './ApplicationLayout.css';
</style>
