import { isComponent, getPropsData } from '@core/services/vnode/vnodeService';
import { debug } from '../debug/debugService';

function shouldAcceptOneLevelAbstraction(rightActionOnTop) {
  return (
    !rightActionOnTop[0]?.tag.match(/^Ds.*/i) &&
    rightActionOnTop[0]?.componentInstance?.$children &&
    childrenValidation(rightActionOnTop[0]?.componentInstance?.$children)
  );
}

function childrenValidation(rightActionOnTop) {
  if (rightActionOnTop?.length === 1) {
    const childToValidate = rightActionOnTop[0]?.$vnode || rightActionOnTop[0];

    return (
      isComponent(childToValidate, 'DsButton') &&
      getPropsData(childToValidate)?.theme === 'secondary' &&
      getPropsData(childToValidate)?.size === 'sm'
    );
  }

  return false;
}

function isValidSlot(rightActionOnTop) {
  const isValid = shouldAcceptOneLevelAbstraction(rightActionOnTop) || childrenValidation(rightActionOnTop);

  if (!isValid) {
    debug.error('right-action-on-top slot must contain a DsButton component with secondary theme and sm size');
    return false;
  }

  return isValid;
}

export function shouldRenderSlot(rightActionOnTop) {
  return rightActionOnTop && isValidSlot(rightActionOnTop);
}
