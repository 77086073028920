<template>
  <ds-popover ref="popover" class="ds-range-popover" @hook:destroyed="$destroy()">
    <ds-form :submit-action="submitAction">
      <ds-row>
        <ds-col class="ds-range-popover__content">
          <slot name="start-field"></slot>
          <span class="ds-range-popover__label">até</span>
          <slot name="end-field"></slot>
        </ds-col>
      </ds-row>
      <ds-row v-if="$slots['helper-text']" class="ds-range-popover__helper-text">
        <ds-col>
          <slot name="helper-text"></slot>
        </ds-col>
      </ds-row>
      <footer class="ds-range-popover__footer">
        <ds-button @click="close()"> Cancelar </ds-button>
        <ds-submit-button theme="secondary"> Aplicar filtro </ds-submit-button>
      </footer>
    </ds-form>
  </ds-popover>
</template>

<script>
import DsButton from '@components/button';
import DsForm from '@components/form';
import DsSubmitButton from '@components/submit-button';
import DsRow from '@components/row';
import DsCol from '@components/col';
import DsPopover from '@components/popover';

export default {
  name: 'DsRangePopover',
  components: {
    DsPopover,
    DsForm,
    DsButton,
    DsSubmitButton,
    DsRow,
    DsCol,
  },
  props: {
    onApply: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      localStartValue: this.startValue,
      localEndValue: this.endValue,
    };
  },
  methods: {
    async submitAction() {
      await this.onApply();
      this.close();
    },
    close() {
      this.$refs.popover.close();
    },
  },
};
</script>

<style scoped>
@import './RangePopover.css';
</style>
