import { confirm } from '../confirm/confirmService';

const confirmClose = () =>
  new Promise(resolve => {
    confirm({
      title: 'Interromper a importação dos arquivos',
      message:
        'Ao confirmar, a importação dos arquivos que ainda estão em carregamento serão interrrompidas e será necessário importa-los novamente.  Você tem certeza que deseja cancelar a importação destes arquivos?',
      confirmButtonText: 'Sim, interromper',
      cancelButtonText: 'Cancelar',
      onConfirm: () => resolve(true),
      onCancel: () => resolve(false),
    });
  });

export { confirmClose };
