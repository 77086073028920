<template>
  <ds-data-search
    :on-search="onSearch"
    :on-search-success="onSearchSuccess"
    :on-search-error="onSearchError"
    invalid-form-tooltip-text="Para buscar os dados, preencha o CEP no campo ao lado.">
    <ds-cep-input ref="input" v-bind="$attrs" v-on="$listeners" />
  </ds-data-search>
</template>

<script>
import { focusMixin } from '@core';
import DsCepInput from '@components/cep-input';
import DsDataSearch from '@components/data-search';

export default {
  name: 'DsCepDataSearch',
  components: {
    DsDataSearch,
    DsCepInput,
  },
  mixins: [focusMixin.focus('input')],
  props: {
    onSearch: DsDataSearch.props.onSearch,
    onSearchSuccess: DsDataSearch.props.onSearchSuccess,
    onSearchError: DsDataSearch.props.onSearchError,
  },
};
</script>
