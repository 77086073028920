<template>
  <ds-button
    class="ds-suggestion-button"
    :tooltip="suggestionTooltipMessage"
    :icon="isSuggestionAccepted ? 'bolt' : 'undo'"
    theme="link"
    size="sm"
    @keydown.space.enter="$emit('click', $event)"
    @focus="$emit('focus', $event)"
    @click="$emit('click', $event)">
  </ds-button>
</template>

<script>
import DsButton from '@components/button';
import DsTooltip from '@directives/tooltip';

export default {
  name: 'DsSuggestionButton',
  directives: {
    DsTooltip,
  },
  components: {
    DsButton,
  },
  props: {
    isSuggestionAccepted: Boolean,
  },
  computed: {
    suggestionTooltipMessage() {
      return this.isSuggestionAccepted
        ? 'Este ícone de raio significa que o campo foi preenchido automaticamente com uma sugestão da Conta Azul.'
        : 'O campo foi editado por você. Se quiser voltar para o valor sugerido pela Conta Azul, é só clicar neste ícone de seta.';
    },
  },
};
</script>

<style scoped>
@import './SuggestionButton.css';
</style>
