<template>
  <ds-topbar-item key="ds-topbar-profile-item-key" ref="topbarItem" class="ds-topbar-profile-item">
    <div class="ds-topbar-profile-item__button-content">
      <div class="ds-topbar-profile-item__avatar">
        <ds-avatar size="xs" :src="avatarImg" :alt="avatarAlt" />
      </div>
      <div class="ds-topbar-profile-item__heading">
        <ds-p size="xxs" class="ds-topbar-profile-item__name" weight="bold">
          {{ shortDescription }}
        </ds-p>
        <ds-p size="xxs" class="ds-topbar-profile-item__description" color="blue"> Configurações e plano </ds-p>
      </div>
    </div>
    <template #topbar-aside>
      <slot />
    </template>
  </ds-topbar-item>
</template>

<script>
import DsAvatar from '@components/avatar';
import DsP from '@components/paragraph';
import DsTopbarItem from '@components/topbar-item';

export default {
  name: 'DsTopbarProfileItem',
  components: {
    DsAvatar,
    DsP,
    DsTopbarItem,
  },
  provide() {
    return {
      topbarProfileItemVm: {
        getProfileData: () => this.profileData,
      },
    };
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    avatarImg: DsAvatar.props.src,
    avatarAlt: DsAvatar.props.alt,
  },
  data() {
    return {
      profileData: {
        name: this.name,
        description: this.description,
        avatarImgSrc: this.avatarImg,
        avatarAlt: this.avatarAlt,
      },
    };
  },
  computed: {
    shortDescription() {
      return truncateAtSecondSpace(this.description);
    },
  },
  watch: {
    name(name) {
      this.profileData.name = name;
    },
    description(description) {
      this.profileData.description = description;
    },
    avatarImg(avatarImg) {
      this.profileData.avatarImgSrc = avatarImg;
    },
    avatarAlt(avatarAlt) {
      this.profileData.avatarAlt = avatarAlt;
    },
  },
  methods: {
    /**
     * Opens profile item aside
     *
     * @public
     */
    open() {
      this.$refs.topbarItem.open();
    },
  },
};

function truncateAtSecondSpace(string) {
  return (
    string &&
    string
      .split(' ')
      .filter((item, index) => index < 2)
      .join(' ')
  );
}
</script>

<style scoped>
@import './TopbarProfileItem.css';
</style>
