<template>
  <ds-logo-conta-azul :class="classes" class="ds-logo" />
</template>

<script>
import DsLogoContaAzul from './LogoContaAzul.vue';

export default {
  name: 'DsLogo',
  components: {
    DsLogoContaAzul,
  },
  props: {
    /**
     * [white, blue, grey]
     */
    theme: {
      type: String,
      default: 'white',
      validator(theme) {
        return !theme || ['white', 'blue', 'grey'].includes(theme);
      },
    },
  },
  computed: {
    classes() {
      return {
        [`ds-logo--${this.theme}`]: this.theme,
      };
    },
  },
};
</script>

<style scoped>
@import 'Logo.css';
</style>
