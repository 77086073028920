<template>
  <div class="ds-search-form">
    <ds-form :submit-action="search">
      <ds-input-group>
        <ds-input v-model="localValue" :placeholder="placeholder" :aria-label="placeholder" />
        <ds-input-icons class="ds-u-print-hidden">
          <ds-input-clear-icon v-if="isFormFilled" @click="clearSearchForm" />
        </ds-input-icons>
        <ds-submit-button slot="button" tooltip="Buscar" theme="default" :icon="['regular', 'search']">
        </ds-submit-button>
      </ds-input-group>
    </ds-form>
  </div>
</template>

<script>
import DsForm from '@components/form';
import DsInputIcons from '@components/input-icons/InputIcons.vue';
import DsInputClearIcon from '@components/input-clear-icon';
import DsInput from '@components/input';
import DsInputGroup from '@components/input-group';
import DsSubmitButton from '@components/submit-button';
import { toasterService } from '@components/toaster';

export default {
  name: 'DsSearchForm',
  components: {
    DsForm,
    DsInput,
    DsInputIcons,
    DsInputGroup,
    DsSubmitButton,
    DsInputClearIcon,
  },
  props: {
    value: {
      type: String,
    },
    onSearch: {
      type: Function,
      required: true,
    },
    onSearchSuccess: {
      type: Function,
      default: () => {},
    },
    onSearchError: {
      type: Function,
      default: () => {},
    },
    onClearSearch: {
      type: Function,
      default: () => {},
    },
    placeholder: {
      type: String,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      localValue: this.value,
    };
  },
  computed: {
    isFormFilled() {
      return !!this.localValue;
    },
  },
  watch: {
    value(value) {
      this.localValue = value;
    },
    localValue(localValue) {
      this.onChange(localValue);
    },
  },
  methods: {
    async search() {
      try {
        const response = await this.onSearch(this.localValue);
        this.onSearchSuccess(response);
      } catch (e) {
        this.toastError();
        this.onSearchError(e);
      }
    },
    toastError() {
      toasterService.error({
        title: 'Não foi possível obter os dados',
        content: 'Por favor, tente novamente.',
      });
    },
    clearSearchForm() {
      this.localValue = '';
      this.onClearSearch();
    },
  },
};
</script>

<style scoped>
@import './SearchForm.css';
</style>
