import Vue from 'vue';
import { getRolloverConstants } from '@core/services/rollover/rolloverService';

const ROLLOVER_ANIMATION = getRolloverConstants('ROLLOVER_ANIMATION');

let shouldBlockDuplicatedTrigger = false;
const BLOCKING_TIME = ROLLOVER_ANIMATION.MEDIUM;

export function buildOpenDialog(Component) {
  return (props, renderOptions) => {
    if (shouldBlockDuplicatedTrigger) {
      return {};
    }

    shouldBlockDuplicatedTrigger = true;
    setTimeout(() => {
      shouldBlockDuplicatedTrigger = false;
    }, BLOCKING_TIME);

    return Vue.dsDialog(Component, props, renderOptions);
  };
}
