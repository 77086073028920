<template>
  <fragment>
    <slot />
    <tr v-if="shouldShowRowDivider">
      <td colspan="2">
        <ds-row-divider margin="medium" />
      </td>
    </tr>
  </fragment>
</template>

<script>
import { Fragment } from '@contaazul/vue-fragment';
import DsRowDivider from '@components/row-divider';

export default {
  name: 'DsDetailTotalBarItemGroupDeprecated',
  components: {
    Fragment,
    DsRowDivider,
  },
  computed: {
    shouldShowRowDivider() {
      return this.$slots?.default;
    },
  },
};
</script>
