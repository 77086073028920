import { dateService, dateParser, stringService } from '@core';
import { getPeriodTypeByKey, DATE_PERIOD_TYPES } from './datePeriodService';

export function getPeriodRangeByType(periodType) {
  const period = getPeriodTypeByKey(periodType).getPeriod();

  return {
    startDate: dateParser.objectToISODate(period.startDate),
    endDate: dateParser.objectToISODate(period.endDate),
  };
}

export function getPeriodTypeOptions({ setPeriodByType, openPopover, openDatepicker, periodTypes }) {
  return [
    { value: DATE_PERIOD_TYPES.TODAY.key, label: 'Hoje', action: setPeriodByType },
    { value: DATE_PERIOD_TYPES.THIS_WEEK.key, label: 'Esta semana', action: setPeriodByType },
    { value: DATE_PERIOD_TYPES.THIS_MONTH.key, label: 'Este mês', action: setPeriodByType },
    { value: DATE_PERIOD_TYPES.THIS_YEAR.key, label: 'Este ano', action: setPeriodByType },
    { value: DATE_PERIOD_TYPES.LAST_THIRTY_DAYS.key, label: 'Últimos 30 dias', action: setPeriodByType },
    { value: DATE_PERIOD_TYPES.CUSTOM_MONTH.key, label: 'Escolher mês', action: openDatepicker },
    { value: DATE_PERIOD_TYPES.LAST_YEAR.key, label: 'Últimos 12 meses', action: setPeriodByType },
    { value: DATE_PERIOD_TYPES.ALL_PERIOD.key, label: 'Todo o período', action: setPeriodByType },
    { value: DATE_PERIOD_TYPES.CUSTOM_PERIOD.key, label: 'Período personalizado', action: openPopover },
  ].filter(period => periodTypes.includes(period.value));
}

export function getPeriodTypeKeys() {
  return Object.values(DATE_PERIOD_TYPES).map(type => type.key);
}

export function getDefaultPeriodTypeKeys() {
  return getPeriodTypeKeys().filter(period => period !== 'all-period');
}

export function getPeriodTitle(startDate, endDate) {
  if (dateService.isPeriodFullYear(startDate, endDate)) {
    return dateParser.formatDate(startDate, 'YYYY-MM-DD', 'YYYY');
  }

  if (dateService.isPeriodFullMonth(startDate, endDate)) {
    return stringService.toCapitalize(dateParser.formatDate(startDate, 'YYYY-MM-DD', 'MMMM [de] YYYY'));
  }

  if (dateService.isSameDay(startDate, endDate)) {
    return `${dateParser.toSlashDate(startDate)}`;
  }

  if (startDate && endDate) {
    return `${dateParser.toSlashDate(startDate)} até ${dateParser.toSlashDate(endDate)}`;
  }

  if (startDate) {
    return `A partir de ${dateParser.toSlashDate(startDate)}`;
  }

  if (endDate) {
    return `Todo período até ${dateParser.toSlashDate(endDate)}`;
  }

  if (startDate === null && endDate === null) {
    return 'Todo o período';
  }

  return '';
}
